<template>
  <div v-loading="loading">
    <section class="searchForm">
      <el-form inline> 
        <el-from-item>
          <el-button type="primary" @click="dialog = true;goods = {}">添加</el-button>
        </el-from-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="list">
        <el-table-column label="id" prop="id" width="100px"></el-table-column>
        <el-table-column label="产品名" prop="title" width="200px"></el-table-column>
        <el-table-column label="简述" prop="subtitle" width="200px"></el-table-column>
        <el-table-column label="描述" prop="description">
          <template #default="scope">
            <div v-html="scope.row.description"></div>
          </template>
        </el-table-column>
        <el-table-column label="价格" prop="price" width="100px"></el-table-column>
        <el-table-column label="pid" prop="pid" width="150px"></el-table-column>
        
        <el-table-column label="操作" width="200px">
          <template #default="scope">
            <el-button type="primary" size="small" @click="removeBrandAuthProduct(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="cunPage"
        :page-size="10"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="getList"
      />
    </section>

    <el-dialog v-model="dialog" title="添加">
      <selecegoods @select="select"/>
      <template #footer>
        <el-button @click="dialog = false">取消</el-button>
        <el-button type="primary" @click="addBrandAuthProduct">确定</el-button>
      </template>
    </el-dialog>

    
  </div>
</template>
<script>
import {queryBrandAuthProductListByBrandAuthId,addBrandAuthProduct,removeBrandAuthProduct} from '../../config/Api'
import selecegoods from '@/components/selectGoods.vue'
import { ElMessage } from 'element-plus'
export default {
  data() {
    return {
      cunPage: 1,
      total: 0,
      list: [],
      loading: false,
      accountId: null,

      dialog: false,
      goods: {},
    }
  },
  components: {selecegoods},
  mounted() {
    let {accountId} = this.$route.query;
    this.accountId = accountId;
    this.cunPage = 1;
    this.getList();
  },
  methods: {
    // 获取产品列表
    getList() {
      this.loading = true;
      let param = {
        brandAuthAdminId: this.accountId,
        cunPage: this.cunPage,
        pageSize: 10,
      }
      queryBrandAuthProductListByBrandAuthId(param).then(res => {
        this.loading = false;
        let list = res.result;
        for(let i in list){
          list[i].price = list[i].price / 100;
        }
        this.list = list;
        this.total = res.data.totalCount;
      },err => {
        this.loading = false;
        this.list = [];
      })
    },
    // 增加产品
    select(e){
      console.log(e);
      this.goods = e;
    },
    addBrandAuthProduct() {
      this.loading = true;
      let param = {
        brandAuthAdminId: this.accountId,
        productId: this.goods.id,
      }
      addBrandAuthProduct(param).then(res => {
        this.loading = false;
        ElMessage.success('添加成功');
        this.dialog = false;
        this.getList();
      },err => { 
        this.loading = false;
        ElMessage.error('添加失败')
      })
    },
    // 删除产品
    removeBrandAuthProduct(row) {
      this.loading = true;
      let param = {
        brandAuthAdminId: this.accountId,
        productId: row.id,
      }
      removeBrandAuthProduct(param).then(res => {
        this.loading = false;
        this.getList();
        ElMessage.success('删除成功')
      },err => {
        this.loading = false;
        ElMessage.error('删除失败')
      })
    }
  }
}
</script>