<template>
  <div class="topicList" v-loading="loading">
    <section class="searchForm">
      <el-form inline>
        <el-form-item label="用户昵称">
          <el-input v-model="form.userName" placeholder="用户昵称"></el-input>
        </el-form-item>
        <el-form-item label="用户ID">
          <el-input v-model="form.userId" placeholder="用户ID"></el-input>
        </el-form-item>
        <el-form-item label="动态标题">
          <el-input v-model="form.titleName" placeholder="动态标题"></el-input>
        </el-form-item>
        <el-form-item label="话题ID">
          <el-select v-model="form.topicId">
            <el-option v-for="item in huatiList" :key="item.id" :label="item.title" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="分类">
            <el-select v-model="form.type">
                <el-option label="专属推荐" value="0" />
                <el-option label="晒心愿盒" value="1" />
                <el-option label="好物种草" value="2" />
                <el-option label="话题讨论" value="3" />
                <el-option label="吃货联盟" value="5" />
                <el-option label="妆容作战" value="6" />
                <el-option label="美好生活" value="7" />
            </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.status">
            <el-option label="正常" value="0"/>
            <el-option label="审核中" value="1"/>
            <el-option label="审核未通过" value="2"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
          <el-button type="danger" @click="clearSearch">清除</el-button>
          <el-button type="primary" @click="downFile">导出</el-button>
          <el-button type="primary" @click="openExamineP" v-if="examineId_arr.length">批量审核</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="tableData" ref="TableRef" @selection-change="handleSelectionChange">
        <el-table-column prop="id" label="ID" width="60"></el-table-column>
        <el-table-column prop="user.id" label="用户ID" width="75"></el-table-column>
        <el-table-column prop="user.nickname" label="昵称" width="80"></el-table-column>
        <el-table-column prop="topicId" label="话题" width="150">
          <template #default="scope">
            <block v-for="item in huatiList">
              <p v-if="scope.row.topicIds.split(',').indexOf(item.id+'')!==-1">{{item.title}}；</p>
            </block>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题" width="120"></el-table-column>
        <el-table-column prop="contentCopy" label="内容">
          <template #default="scope">
            {{scope.row.contentCopy}}
            <el-tag class="ml-2" v-if="scope.row.lookMore" @click="lookMore = true;contentCopy = scope.row.content">查看</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="图片/视频" width="180">
          <template #default="scope">
            <div class="tableImgs">
              <img v-for="(item,index) in scope.row.userMomentResources" :key="item.id" :src="item.url" alt="" @click="this.show_imgs(item)">
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="user.id" label="关联产品" width="100">
          <template #default="scope">
            {{scope.row.productIds?scope.row.productIds.split(',').length:0}}个产品
            <el-tag class="ml-2" @click="gotoGoods(scope.row)">查看</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="likeCount" label="点赞" width="60"></el-table-column>
        <el-table-column prop="commentCount" label="评论" width="120">
          <template #default="scope">
            {{scope.row.commentCount}}
            <el-tag class="ml-2" @click="gotoComment(scope.row)">查看</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="shareCount" label="收藏" width="60"></el-table-column>
        <el-table-column prop="browseCount" label="点击" width="60"></el-table-column>
        <el-table-column prop="type" label="分类" width="60">
          <template #default="scope">
            {{(scope.row.type==1&&'晒心愿盒')||(scope.row.type==2&&'好物种草')||(scope.row.type==3&&'话题讨论')||(scope.row.type==5&&'吃货联盟')||(scope.row.type==6&&'妆容作战')||(scope.row.type==7&&'美好生活')}}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="100"></el-table-column>
        <el-table-column prop="shareCount" label="专属推荐" width="80">
          <template #default="scope">
            {{scope.row.isRecommend==0?'不是推荐':'专属推荐'}}
          </template>
        </el-table-column>
        <el-table-column prop="isTop" label="置顶" width="80">
          <template #default="scope">
            {{scope.row.isTop==0?'不置顶':'置顶'}}
          </template>
        </el-table-column>
       <el-table-column prop="shareCount" label="批量审核" width="80" type="selection">
         <template #default="scope">
           <el-checkbox v-if="scope.row.status==1" :key="scope.row.id" v-model="scope.row.isCheck" size="large" @change="this.examineId_arr_change(scope.row,scope.$index)"/>
         </template>
       </el-table-column>
        <el-table-column prop="status" label="审核状态" width="80">
          <template #default="scope">
            {{scope.row.status==0?'正常':(scope.row.status==1?'审核中':'未通过')}}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template #default="scope">
            <el-tag class="ml-2" type="danger" @click="openExamine(scope.row)" v-if="scope.row.status==1">审核</el-tag>
            <el-tag class="ml-2" type="danger" @click="ReviewFallback(scope.row)" v-else>回退</el-tag>
            <el-tag class="ml-2" @click="openModify(scope.row)">编辑</el-tag>
            <el-tag class="ml-2" type="success" @click="remove(scope.row)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="pageCurrent"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </section>

    <!-- 动态内容 -->
    <el-dialog v-model="lookMore" title="内容" draggable width="400px">
      {{contentCopy}}
      <template #footer>
        <el-button type="primary" @click="lookMore = false">确定</el-button>
      </template>
    </el-dialog>
    <!-- 审核 -->
    <el-dialog v-model="examineFlag" :title="examineP?'批量审核':'审核'" draggable width="400px">
      <el-form>
        <el-form-item label="额外奖励心愿币">
          <el-input-number v-model="coinNum" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button v-if="examineP" type="primary" @click="PiExamine(2)">批量审核不通过</el-button>
        <el-button v-else type="danger" @click="examine(2)">审核不通过</el-button>
        <el-button v-if="examineP" type="primary" @click="PiExamine(0)">批量审核通过</el-button>
        <el-button v-else type="primary" @click="examine(0)">审核通过</el-button>
      </template>
    </el-dialog>
    <!-- 修改弹窗 -->
    <el-dialog v-model="modifyFlag" title="修改" draggable width="500px">
      <el-form label-width="100px">
        <el-form-item label="标题">
          <el-input v-model="formModify.title"/>
        </el-form-item>
        <el-form-item label="内容">
          <el-input type="textarea" autosize v-model="formModify.content"/>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="formModify.sort"/>
        </el-form-item>
        <el-form-item label="话题">
          <el-select v-model="formModify.topicIds" multiple multiple-limit="3">
            <el-option v-for="item in huatiList" :key="item.id" :label="item.title" :value="item.id+''"/>
          </el-select>
        </el-form-item>
        <el-form-item label="分类">
            <el-select v-model="formModify.type">
                <el-option label="晒心愿盒" :value="1" disabled />
                <el-option label="好物种草" :value="2" disabled />
                <el-option label="话题讨论" :value="3" disabled />
                <el-option label="吃货联盟" :value="5" />
                <el-option label="妆容作战" :value="6" />
                <el-option label="美好生活" :value="7" />
            </el-select>
        </el-form-item>
        <el-form-item label="专属推荐">
          <el-select v-model="formModify.isRecommend">
            <el-option label="不是推荐" :value="0"/>
            <el-option label="专属推荐" :value="1"/>
          </el-select>
        </el-form-item>
        <el-form-item label="置顶">
          <el-select v-model="formModify.isTop">
            <el-option label="置顶" :value="1"/>
            <el-option label="不置顶" :value="0"/>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="modifyFlag = false">取消</el-button>
        <el-button type="primary" @click="submitModify">修改</el-button>
      </template>
    </el-dialog>

    <div class="show_imgs" v-show="this.big_imgs_flag">
      <img :src="this.big_imgs" class="big_imgs">
      <img src="../../assets/cur_icon_close_white.png" class="close" @click="close">
    </div>

  </div>
</template>

<script>
import {ElMessage,ElMessageBox } from 'element-plus';
import {deleteUserMomentDynamicInfo,QueryUserMomentListByUserMomentSearchVo,GetTopicList,ModifyUserMomentStatusByUserMomentId,ModifyUserMomentByUserMomentId,ModifyUserMomentStatusByUserMomentIds,downloadUserMomentExcelByUserMomentSearchVo} from '../../config/Api';
export default {
  data() {
    return {
      pageCurrent: 1,
      pageSize: 20,
      total: 1,
      loading: false,
      tableData: [],
      form: {},
      huatiList: [],
      lookMore: false,
      contentCopy: '',
      coinNum: 0,
      examineFlag: false,
      examineP: false,
      examineId: '',
      modifyFlag: false,
      formModify: {},
      big_imgs:'',
      big_imgs_flag:false,
      examineId_arr:[]
    }
  },
  mounted() {
    let query = this.$route.query;
    this.pageCurrent = Number(query.page || 1);
    this.pageSize = Number(query.size || 20);
    this.getList();
    this.GetTopicList();
  },
  methods: {
    examineId_arr_change(row,i){
      console.log(i)
      let examineId_arr = this.examineId_arr
      if(row.id&&examineId_arr.indexOf(row.id) == -1){
        examineId_arr.push(row.id)
        this.tableData[i].isCheck = true;
      } else {
        examineId_arr.splice(examineId_arr.indexOf(row.id),1);
        this.tableData[i].isCheck = false;
      }
      this.examineId_arr = examineId_arr;
    },

    show_imgs(item){     //图片放大
      this.big_imgs_flag = true
      this.big_imgs = item.url
       // console.log(item.url)
    },

    close(){                 //关闭
      this.big_imgs_flag = false
    },
    // 上一页下一页
    handleCurrentChange(){
      this.$router.push({
        path: '/topic_list',
        query: {page: this.pageCurrent, size: this.pageSize}
      })
      this.getList();
    },
    // 获取数据
    getList() {
      let param = {
        cunPage: this.pageCurrent,
        pageSize: this.pageSize,
        ...this.form
      }
      this.loading = true;
      QueryUserMomentListByUserMomentSearchVo(param).then(res => {
        this.loading = false;
        if(res.result.length){
          res.result.map(item => {
            if(item.content.length>=30) {
              item.contentCopy = item.content.substring(0,30) + '...';
              item.lookMore = true;
            } else {
              item.contentCopy = item.content;
            }
          })
        }
        this.tableData = res.result;
        this.total = res.data.totalCount;
        this.examineId_arr = [];
      }, err => {
        this.loading = false;
      })
    },
    // 获取话题
    GetTopicList() {
      let param = {
        pageNo: 1,
        pageSize: 999
      }
      GetTopicList({params: param}).then(res => {
        this.huatiList = res.result;
      })
    },
    // 查询
    search() {
      this.pageCurrent = 1;
      this.getList();
    },
    // 清除
    clearSearch() {
      this.form = {};
      this.pageCurrent = 1;
      this.getList();
    },
    // 打开审核弹窗
    openExamine(row) {
      this.examineFlag = true;
      this.examineP = false;
      this.examineId = row.id;
      this.coinNum = 0;
    },
    // 打开批量审核
    openExamineP(){
      this.examineFlag = true;
      this.examineP = true;
      this.coinNum = 0;
    },
    // 审核动态
    examine(status) {
      let param = {
        number: this.coinNum,
        status,
        userMomentId: this.examineId
      }
      this.loading = true;
      ModifyUserMomentStatusByUserMomentId(param).then(res => {
        this.loading = false;
        this.examineFlag = false;
        this.getList();
      },err => {
        this.loading = false;
        ElMessage.error(`审核失败：${err.msg}`)
      })
    },
    PiExamine(status){
      let param = {
        number:this.coinNum,
        status,
        userMomentIds:this.examineId_arr.join(',')
      }
      this.loading = true;
      ModifyUserMomentStatusByUserMomentIds(param).then(res=>{
        this.loading = false;
        this.examineFlag = false;
        this.getList();
      },err=>{
        this.loading = false;
        ElMessage.error(`审核失败：${err.msg}`)
      })
    },  
    // 删除动态
    remove(row) {
      ElMessageBox.confirm(
        '是否删除?',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(()=>{
        deleteUserMomentDynamicInfo({dynamicId:row.id}).then(res=>{
          console.log(res);
          this.getList();
        })
      }).catch(()=>{

      })
    },
    // 打开修改
    openModify(row) {
      //console.log(this.checked1)
      this.modifyFlag = true;
      this.formModify = {
        title: row.title,
        content: row.content,
        sort: row.sort,
        topicIds: row.topicIds.split(','),
        type: row.type,
        isRecommend:row.isRecommend,
        userMomentId: row.id,
        isTop: row.isTop
      }
    },
    // 修改动态
    submitModify(){
      let param = {
        ...this.formModify
      };
      param.topicIds = param.topicIds.join(',');
      console.log(param)
      this.loading = true;
      ModifyUserMomentByUserMomentId(param).then(res => {
        this.loading = false;
        this.modifyFlag = false;
        this.getList();
      },err => {
        this.loading = false;
        ElMessage.error(`修改动态失败：${err.msg}`)
      })
    },
    // 查看动态关联产品
    gotoGoods(row){
      this.$router.push({
        path: '/topic_list/goods',
        query:{id:row.id,b:`${this.pageCurrent}_${this.pageSize}`}
      })
    },
    // 查看动态评论
    gotoComment(row){
      this.$router.push({
        path: '/topic_list/comment',
        query:{id:row.id,b:`${this.pageCurrent}_${this.pageSize}`}
      })
    },
    // 审核回退
    ReviewFallback(row){
      ElMessageBox.confirm(
          '是否取消审核?',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(()=>{
        let param = {
          number: 0,
          status: 1,
          userMomentId: row.id
        };
        this.loading = true;
        ModifyUserMomentStatusByUserMomentId(param).then(res => {
          this.loading = false;
          this.getList();
          ElMessage.success('回退成功')
        },err => {
          this.loading = false;
          ElMessage.error('回退失败')
        })
      })
    },
    // 全选
    handleSelectionChange(e){
      console.log(e)
      let examineId_arr = [];
      if(e.length){
        for(let i in e){
          if(e[i].status===1){
            examineId_arr.push(e[i].id)
            this.tableData[i].isCheck = true;
          }
        }
        this.examineId_arr = examineId_arr;
      }else{
        for(let i in this.tableData){
          this.tableData[i].isCheck = false;
        }
        this.examineId_arr = examineId_arr;
      }
    },
    // 导出
    downFile(){
      ElMessageBox.confirm(
        '是否导出?',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(()=>{
        let param = {...this.form};
        this.loading = true;
        downloadUserMomentExcelByUserMomentSearchVo(param).then(res=>{
          this.loading = false;
          let oA = document.createElement('a');
          oA.href = res.result;
          oA.download = '用户发帖导出';
          oA.click();
        },err=>{
          this.loading = false;
          ElMessage.error(err.msg)
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tableImgs {
  img {
    width: 40px;
    float: left;
    margin: 0 10px 10px 0;
  }
}
.show_imgs{
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0,0,0,0.7);
  top:0;
  left:0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.big_imgs{
  width: 50%;
  height: 50%;
  object-fit:contain;
}
.close{
  position: fixed;
  right: 30px;
  top:30px;
  cursor: pointer;
}
</style>