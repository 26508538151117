<template>
  <div class="feedback_message" v-loading="loading">
    <!-- <section class="searchForm">
      <el-form inline>
        <el-form-item label="ID">
          <el-input ></el-input>
        </el-form-item>
        <el-form-item label="用户昵称">
          <el-input ></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :icon="Search">查询</el-button>
        </el-form-item>
      </el-form>
    </section> -->
    <section class="tableList">
      <el-table :data="tableData">
        <el-table-column  prop="id" label="ID" width="120"/>
        <el-table-column  prop="userId" label="用户ID" width="120"/>
        <el-table-column  prop="content" label="内容" />
        <el-table-column  prop="createTime" label="时间" width="200" />
        <el-table-column label="操作" width="250" >
          <template #default="scope">
            <el-button type="primary" size="small" @click="openDialog(scope.row)">回复</el-button>
            <el-button type="success" size="small" @click="openMsgDialog(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="pageCurrent"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </section>

    <!-- 回复弹窗 -->
    <el-dialog v-model="dialog" title="反馈回复" width="600px">
      <el-form>
        <el-form-item label="标题">
          <el-input  v-model="formData.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="图标">
          <el-upload
              :action="getUrl+'admin/file/uploadFile'"
              :headers="headers"
              :on-success="handleIconSuccess"
              :show-file-list="false"
          >
            <img class="iconFileUpload" v-if="formData.icon" :src="formData.icon" alt="">
            <img class="iconFileUpload" v-else src="../../assets/bannerDefualt.png"/>
          </el-upload>
        </el-form-item>
        <el-form-item label="内容">
          <el-input type="textarea" v-model="formData.content"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialog = false">取消</el-button>
        <el-button type="primary" @click="replyFeedBack">确定</el-button>
      </template>
    </el-dialog>

    <!-- 查看回复 -->
    <el-dialog v-model="msgDialog" title="查看回复" width="800px">
      <el-table :data="msgData">
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="icon" prop="icon">
          <template #default="scope">
            <img style="width: 70px" :src="scope.row.icon" alt="">
          </template>
        </el-table-column>
        <el-table-column label="内容" prop="content"></el-table-column>
        <el-table-column label="时间" prop="createTime"></el-table-column>
      </el-table>
      <template #footer>
        <el-button @click="msgDialog = false">取消</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>

import {Search} from '@element-plus/icons-vue';
import { ElMessage } from 'element-plus';
import {QueryFeedBackListByFeedBackSearchVo, queryFeedBackReplyList, replyFeedBack} from '../../config/Api';
import {Get_url} from "../../config/Get_url";
export default {
  data() {
    return {
      Search:Search,    //图标
      pageCurrent: 1,
      pageSize: 15,
      total: 1,
      tableData:[],
      dialog: false,
      formData: {},
      getUrl: Get_url(),
      headers:{
        id:localStorage.getItem('id'),
        token: localStorage.getItem('token')
      },
      msgDialog: false,
      msgData: [],
      loading: false,
    }
  },
  mounted() {
    let query = this.$route.query;
    this.pageCurrent = Number(query.page || 1);
    this.pageSize = Number(query.size || 15);
    this.getList();
  },
  methods: {
    // 上一页下一页
    handleCurrentChange(){
      this.$router.push({
        path: '/Feedback_message',
        query: {page: this.pageCurrent, size: this.pageSize}
      })
      this.getList();
    },
    // 获取数据
    getList() {
      let param = {
        cunPage: this.pageCurrent,
        pageSize: this.pageSize
      }
      this.loading = true;
      QueryFeedBackListByFeedBackSearchVo(param).then(res => {
        this.loading = false;
        this.tableData = res.result;
        this.total = res.data.totalCount;
      },err => {
        this.loading = false;
        ElMessage.error(`获取反馈留言失败：${err.msg}`)
      })
    },
    // 打开回复弹窗
    openDialog(row){
      console.log(row);
      this.formData = {
        type: 4,
        isRead: 0,
        userid: row.userId,
        value: row.id
      };
      this.dialog = true;
    },
    // 上床icon图标前

    // 上传ICON图标
    handleIconSuccess(e){
      this.formData.icon = e.msg
    },
    //
    replyFeedBack(){
      let param = {
        ...this.formData,
      }
      replyFeedBack(param).then(res => {
        this.dialog = false;
        ElMessage.success('回复成功')
      },err=>{
        this.dialog = false;
        ElMessage.error('回复失败')
      })
    },
    // 查看回复
    openMsgDialog(row){
      this.loading = true;
      queryFeedBackReplyList({feedBackId:row.id}).then(res=>{
        this.msgData = res.result;
        this.msgDialog = true;
        this.loading = false;
      },err=>{
        ElMessage.error('获取反馈回复失败');
        this.loading = false;
      })
    }

  },
}
</script>
<style scoped lang="scss">
.iconFileUpload{
  width: 100px;
  height: 100px;
  border-radius: 6px;
}
</style>