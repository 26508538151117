<template>
  <div v-loading.fullscreen.lock="loading">
    <section class="searchForm">
      <el-form inline>
        <el-form-item><el-button type="primary" @click="openDialog">添加</el-button></el-form-item>
        <el-form-item label="名称">
          <el-input v-model="searchData.name"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="searchData.description"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="searchData.type">
            <el-option label="下载" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item><el-button type="primary" @click="search">搜索</el-button></el-form-item>
        <el-form-item><el-button type="primary" @click="clearSearch">清除</el-button></el-form-item>
      </el-form>
    </section>
    <section>
      <el-table :data="List">
        <el-table-column label="id" prop="id" width="80"></el-table-column>
        <el-table-column label="名称" prop="name" width="120"></el-table-column>
        <el-table-column label="排序" prop="isRecommend" width="120"></el-table-column>
        <el-table-column label="描述" prop="description"></el-table-column>
        <el-table-column label="分类" width="200">
          <template #default="scope">
            <div v-if="tagObj[scope.row.id]">
              <el-tag style="margin:2px" v-for="(item,index) in tagObj[scope.row.id]" :key="item.id" closable @close="handleClose(item,scope.row.id)">{{item.name}}</el-tag>
            </div>
            
            <el-button type="primary" size="small" @click="openTag(scope.row)">添加</el-button>
            <!-- <el-button type="primary" size="small" @click="openDialog1(scope.row)">编辑</el-button> -->
          </template>
        </el-table-column>
        <el-table-column label="类型" prop="type" width="120">
          <template #default="scope">
            {{(scope.row.type==1&&'下载')}}
          </template>
        </el-table-column>
        <el-table-column label="链接" prop="url" width="200"></el-table-column>
        <el-table-column label="操作" width="200">
          <template #default="scope">
            <el-button type="primary" @click="openDialog(scope.row)">修改</el-button>
            <el-button type="danger" @click="removeResearchReportByResearchReportId(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section>
      <el-pagination
        v-model:currentPage="cunPage"
        page-size="10"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="getList"
      />
    </section>
    <tag-class ref="tag" @setTag="setTagClass"/>
    <!-- 添加 -->
    <el-dialog v-model="dialog" title="编辑CEM公告">
      <el-form label-width="80px" label-suffix=":">
        <el-form-item label="名称">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="formData.isRecommend"></el-input-number>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="formData.description"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="formData.type">
            <el-option label="下载" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="url">
          <el-upload
            :action="Get_url+'admin/file/uploadCEMFile'"
            :headers="{token:this.token,id:this.id}"
            :show-file-list="false"
            :on-success="AvatarSuccess"
          >
            <el-button>上传文件</el-button>
          </el-upload>
          {{fileName}}
        </el-form-item>
        <el-form-item label="url链接">
          {{formData.url}}
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialog=false">取消</el-button>
        <el-button v-if="formData.id" type="primary" @click="modifyResearchReportByResearchReportId">修改</el-button>
        <el-button v-else type="primary" @click="addResearchReport">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import tagClass from '@/components/tagClass.vue'
import { ElMessage } from 'element-plus';
import {Get_url} from "../../config/Get_url";
import {deleteResearchReportCategoryById,queryResearchReportCategoryByReportId,addResearchReport,queryResearchReportListByResearchReportSearchVo,modifyResearchReportByResearchReportId,removeResearchReportByResearchReportId} from '../../config/Api';
import {addResearchReportCategory} from '@/config/jsonapi';
export default {
  data(){
    return{
      token:localStorage.getItem('token'),
      id:localStorage.getItem('id'),
      Get_url:Get_url(),
      dialog: false,
      formData:{},
      fileName: '',

      searchData:{},
      List:[],

      cunPage: 1,
      total:0,

      dialog1: false,
      editData: {},
      tagList: [],
      tagObj:{},

    }
  },
  components:{
    tagClass
  },
  mounted(){
    this.cunPage = 1;
    this.searchData = {};
    this.getList();
  },
  methods:{
    // 获取列表
    getList(){
      let param = {
        cunPage: this.cunPage,
        pageSize: 10,
        ...this.searchData,
      }
      this.loading = true;
      queryResearchReportListByResearchReportSearchVo(param).then(res=>{
        this.loading = false;
        this.List = res.result;
        this.total = res.data.totalCount;
        for(let i in this.List){
          this.queryResearchReportCategoryByReportId(this.List[i].id);
        }
      },err=>{
        this.loading = false;
        this.List = [];
      })
    },
    // 获取类别
    queryResearchReportCategoryByReportId(id){
      queryResearchReportCategoryByReportId({reportId:id}).then(res=>{
        let list = res.result;
        this.tagObj[id] = [];
        let obj = this.tagObj;
        for(let i in list){
          list[i].tagCategory.reportCategoryId = list[i].id;
          if(obj[id]){
            obj[id].push(list[i].tagCategory);
          }else{
            obj[id] = [list[i].tagCategory];
          }
        }
        console.log(obj);
        this.tagObj = obj;
      })
    },

    // 打开编辑CEM公告弹窗
    openDialog(data){
      if(data){
        this.formData = {...data}
      }else{
        this.formData = {};
      }
      this.fileName = ''
      this.dialog = true;
    },
    // 添加
    addResearchReport(){
      if(!this.formData.name) return ElMessage.error('请填写名称');
      if(!this.formData.description) return ElMessage.error('请填写描述');
      if(!this.formData.type) return ElMessage.error('请选择类型');
      if(!this.formData.url) return ElMessage.error('请上传文件');
      this.loading = true;
      let param = {...this.formData};
      addResearchReport(param).then(res=>{
        this.loading = false;
        this.getList();
        this.dialog = false;
      },err=>{
        this.loading = false;
        ElMessage.error('添加失败')
      })
    },
    // 修改
    modifyResearchReportByResearchReportId(){
      if(!this.formData.name) return ElMessage.error('请填写名称');
      if(!this.formData.description) return ElMessage.error('请填写描述');
      if(!this.formData.type) return ElMessage.error('请选择类型');
      if(!this.formData.url) return ElMessage.error('请上传文件');
      this.loading = true;
      let param = {
        ...this.formData
      };
      param.researchReportId = this.formData.id;
      modifyResearchReportByResearchReportId(param).then(res=>{
        this.loading = false;
        this.getList();
        this.dialog = false;
      },err=>{
        this.loading = false;
        ElMessage.error("修改失败")
      })
    },
    // 删除
    removeResearchReportByResearchReportId(data){
      this.loading = true;
      let param = {
        researchReportId: data.id
      }
      removeResearchReportByResearchReportId(param).then(res=>{
        this.loading = false;
        this.getList();
      },err=>{
        this.loading = false;
        ElMessage.error('删除失败')
      })
    },
    search(){
      this.cunPage = 1;
      this.getList();
    },
    // 清除
    clearSearch(){
      this.cunPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 上传文件
    AvatarSuccess(response,uploadFile){
      console.log(uploadFile.response.msg)
      this.formData.url = uploadFile.response.msg;
      this.fileName = uploadFile.name;
    },

    // 打开添加
    openTag(row){
      this.editDataId = row.id;
      this.$refs.tag.openTag();
    },
    // 选择标签
    setTagClass(tag){
      console.log(tag);
      let param = {
        researchReportCategories: [{
          categoryId: tag.id,
          reportId: this.editDataId
        }]
      }
      addResearchReportCategory(param).then(res=>{
        ElMessage.success('添加成功');
        this.tagObj[this.editDataId] = [];
        this.queryResearchReportCategoryByReportId(this.editDataId)
      },err=>{
        ElMessage.error(err.msg);
      })
    },
    // 删除标签
    handleClose(item,id){
      deleteResearchReportCategoryById({reportCategoryId:item.reportCategoryId}).then(res=>{
        ElMessage.success('删除成功');
        
        this.queryResearchReportCategoryByReportId(id)
      },err=>{
        ElMessage.error(err.msg)
      })

    }
  }
}
</script>