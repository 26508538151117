<template>
  <div>
    <section class="searchForm">
      <el-button type="primary" @click="back">返回</el-button>
    </section>
    <section class="tableList">
      <el-table :data="tableData">
        <el-table-column prop="id" label="id" width="120"></el-table-column>
        <el-table-column label="商品图片" width="120">
          <template #default="scope">
            <img style="width:50px" :src="scope.row.imgUrl" alt="">
          </template>
        </el-table-column>
        <el-table-column label="商品名称">
          <template #default="scope">
            【{{scope.row.title}}】-【{{scope.row.subtitle}}】
          </template>
        </el-table-column>
        
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="pageCurrent"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </section>
  </div>
</template>
<script>
import {QueryUserMomentProductList} from '../../config/Api';
export default {
  data() {
    return {
      pageCurrent: 1,
      pageSize: 15,
      total:1,
      tableData: [],
    }
  },
  mounted(){
    let query = this.$route.query;
    this.id = query.id;
    this.b = query.b;
    this.pageCurrent = Number(query.page || 1);
    this.pageSize = Number(query.size || 10);
    this.getList();
  },
  methods:{
    // 上一页下一页
    handleCurrentChange(){
      this.$router.push({
        path: '/topic_list/goods',
        query: {page: this.pageCurrent, size: this.pageSize, id:this.id,b:this.b}
      })
      this.getList();
    },
    // 获取数据
    getList() {
      let param = {
        cunPage: this.pageCurrent,
        pageSize: this.pageSize,
        userMomentId: this.id,
      }
      this.loading = true;
      QueryUserMomentProductList(param).then(res => {
        this.loading = false;
        for(let i in res.result){
          for(let n in res.result[i].productResources){
            if(res.result[i].productResources[n].type == 0){
              res.result[i].imgUrl = res.result[i].productResources[n].url;
              break;
            }
          }
        }
        this.tableData = res.result;
        this.total = res.data.totalCount;
      }, err => {
        this.loading = false;
      })
    },
    back(){
      let b = this.b.split('_');
      this.$router.push({
        path: '/topic_list',
        query: {page:b[0],size:b[1]}
      })
    }
  }
}
</script>