export function formatDate(date, fmt) {
    date = new Date(date);
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substring(4 - RegExp.$1.length))
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    }
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
        }
    }
    return fmt;
}

function padLeftZero(str) {
    return ('00' + str).substring(str.length)
}


export function parseDate(input) {
    // 如果是数字类型的输入，可能是Unix时间戳或Excel序列号
    if (typeof input === 'number') {
        // 如果数字很大（通常大于8位数），可能是毫秒级Unix时间戳
        if (input > 1e12) {
            return new Date(input);
        }
        // 如果数字在一定范围内，可能是秒级Unix时间戳
        else if (input > 1e9) {
            return new Date(input * 1000);
        }
        // 如果是Excel序列号
        else {
            return serialDateToJSDate(input);
        }
    }
    // 如果是字符串类型的输入
    else if (typeof input === 'string') {
        // 先尝试直接转换为日期对象
        let date = new Date(input);
        if (!isNaN(date)) {
            return date;
        }
        // 如果字符串无法直接转换，再尝试其他格式
        // 这里可以根据需要扩展更多格式的处理逻辑
        // 例如解析特定的字符串格式
    }
    // 默认返回null，表示无法解析
    return null;
}

export function serialDateToJSDate(serial) {
    var days = Math.floor(serial - 25569);
    var ms = (serial - days - 25569) * 86400 * 1000;
    var date = new Date(days * 86400 * 1000 + ms);
    return date;
}
