<template>
  <div class="Slide_show_edit">
    <div class="main">
      <section class="search_btn">
        <div class="selected_items">
          <span class="title"><span class="red">*</span>排序：</span>
          <div>
            <el-input
                v-model="this.item_obj.sort"
                class="w-50 m-2"
                size="large"
                placeholder="请输入排序数字"
            ></el-input>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>跳转类型：</span>
          <div>
            <el-select v-model="item_obj.pathType">
              <el-option label="不跳转" value="-1"></el-option>
              <el-option label="产品" value="0"></el-option>
              <el-option label="公众号/外联" value="1"></el-option>
              <el-option label="小程序" value="2"/>
            </el-select>
          </div>
        </div>
        <div class="selected_items" v-if="item_obj.pathType === '1'">
          <span class="title"><span class="red">*</span>链接：</span>
          <div>
            <el-input
                v-model="this.item_obj.pathContent"
                class="w-50 m-2"
                size="large"
                placeholder="请输入排序数字"
            ></el-input>
          </div>
        </div>
        <div class="selected_items" v-if="item_obj.pathType === '0'">
          <span class="title">跳转产品：</span>
          <div>
            <el-select v-model="this.item_obj.pathContent" filterable placeholder="请选择" size="large">
              <el-option
                  v-for="item in this.options"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="selected_items" v-if="item_obj.pathType === '2'">
          <span class="title"><span class="red">*</span>小程序ID：</span>
          <div>
            <el-input
                v-model="this.item_obj.appid"
                class="w-50 m-2"
                size="large"
                placeholder="请输入小程序ID"
            ></el-input>
          </div>
        </div>
        <div class="selected_items" v-if="item_obj.pathType === '2'">
          <span class="title"><span class="red">*</span>小程序页面：</span>
          <div>
            <el-input
                v-model="this.item_obj.appPath"
                class="w-50 m-2"
                size="large"
                placeholder="请输入小程序路径"
            ></el-input>
          </div>
        </div>

        <div class="start_items">
          <span class="title"><span class="red">*</span>图片上传：</span>
          <div>
            <el-upload
                class="avatar-uploader"
                :action="Get_url+'admin/file/uploadFile'"
                :headers="{token:this.token,id:this.id}"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
            >
              <img v-if="this.item_obj.image" :src="this.item_obj.image" class="avatar" />
              <img v-else src="../../assets/bannerDefualt.png" class="avatar"/>
            </el-upload>
            <div class="sub">{{'图片尺寸：2.5:1 大小<=1M'}}</div>
          </div>
        </div>
      </section>
      <section>
        <div class="selected_items">
          <span class="title"></span>
          <div>
            <el-button type="primary" @click="cancel">取消</el-button>
            <el-button type="danger" @click="confirm">确认</el-button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>

import {Search,Plus} from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import global_api from "@/config/Api";
import {Get_url} from "../../config/Get_url";
export default {
  data() {
    return {
      Search:Search,    //图标
      Plus:Plus,
      options:[],
      Get_url:Get_url(),
      item_obj:{},
      token:localStorage.getItem('token'),
      id:localStorage.getItem('id')
    }
  },
  mounted() {
     this.GetProductList()
     this.Init_date()
  },
  methods: {
    Init_date(){    //初始化数据
      if(this.$route.query.type=='edit'){
        this.item_obj = JSON.parse(sessionStorage.getItem('slide_item'))
        this.item_obj.pathType = String(this.item_obj.pathType);
        if(this.item_obj.pathType === '0'){
          this.item_obj.pathContent = this.item_obj.pathContent==''?this.item_obj.pathContent:Number(this.item_obj.pathContent)
        }
        if(this.item_obj.pathType === '2'){
          this.item_obj.appid = this.item_obj.pathContent.split('#')[0];
          this.item_obj.appPath = this.item_obj.pathContent.split('#')[1];
        }
      }else{
        this.item_obj = {
          id:'-1',
          associatedId:0,
          image:'',
          pathContent:'',
          pathType:'',      //跳转类型（-1 不跳转 0产品 1 公众号 2小程序）
          sort:'',
          content: '',
        }
      }
    },

    GetProductList(){          //获取产品列表(下拉框)
      global_api.GetProductList().then((res)=>{
        if(res.code!=0){
          ElMessage.error('获取产品列表错误')
        }else{
          this.options = res.result
        }
        console.log(res)
      })
    },

    handleAvatarSuccess(response,uploadFile){
      this.item_obj.image = uploadFile.response.msg
    },
    beforeAvatarUpload(rawFile){
      if (rawFile.size / 1024 / 1024 > 1) {
        ElMessage.error('上传的图片超过1MB了！')
        return false
      }
      return true
    },
    cancel(){
      this.$router.push('/Slide_show_setting')
    },
    confirm(){
      let obj = {
        bannerId:this.item_obj.id,
        id:1,
        image:this.item_obj.image,
        associatedId:this.item_obj.associatedId,
        pathContent:String(this.item_obj.pathContent),
        pathType:String(this.item_obj.pathType),
        sort:this.item_obj.sort,
        type:0,
        content: this.item_obj.content,
      }
      if(this.item_obj.pathType==='2'){
        obj.pathContent = `${this.item_obj.appid}#${this.item_obj.appPath}`;
      }
      if(!this.validate(obj))  return false
      global_api.InsertOrUpdateBanner(obj).then((res)=>{
        if(res.code!=0){
          ElMessage.error('新增/修改轮播图错误')
        }else{
          ElMessage({
            message: '保存成功',
            type: 'success',
          })
          this.$router.push('/Slide_show_setting')
        }
      })
    },

    validate(obj){
      if(!obj.sort){
        ElMessage.error('排序必须填写')
        return false
      }
      if(!obj.image){
        ElMessage.error('图片必须上传')
        return false
      }
      return true
    }
  },
}
</script>
<style scoped lang="scss">
.Slide_show_edit{
  margin-top: 20px;
  .start_items{
    img{
      width: 200px;
      height: 200px;
      border-radius: 10px;
    }
    .sub{
      margin-top: 10px;
      font-size: 12px;
      color: red;
    }
  }
}

.el-input__inner,.el-input-number,.el-textarea{
  width: 400px !important;
}

</style>