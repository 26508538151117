<!-- @format -->

<template>
	<div class="userTags" v-loading.fullscreen.lock="loading">
		<section class="searchForm">
			<el-form>
				<el-form-item>
					<el-button type="primary" @click="openEdit('add')">添加</el-button>
					<el-button type="primary" @click="openOut">导出</el-button>
					<el-button type="primary" @click="openFile">批量添加</el-button>
					<el-button type="primary" @click="openFile1">删除用户冲突标签</el-button>
          <el-button type="primary" @click="flashUserMutexTags">刷新用户标签</el-button>
				</el-form-item>
			</el-form>
		</section>
		<section class="tableList">
			<el-table :data="tableData">
				<el-table-column label="ID" prop="id"></el-table-column>
				<el-table-column label="userId" prop="userId"></el-table-column>
				<el-table-column label="level" prop="level"></el-table-column>
				<el-table-column label="source" prop="source"></el-table-column>
				<el-table-column label="tagId" prop="tagId">
					<template #default="scope">
						{{ scope.row.tagName ? scope.row.tagName : scope.row.tagId }}
					</template>
				</el-table-column>
				<el-table-column label="创建时间" prop="createTime"></el-table-column>
				<el-table-column label="操作">
					<template #default="scope">
						<el-button type="primary" size="small" @click="openEdit('edit', scope.row)">修改</el-button>
						<el-button size="small" @click="removeTag(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</section>
		<section class="pagination">
			<el-pagination
				v-model:currentPage="pageCurrent"
				v-model:page-size="pageSize"
				background
				layout="prev, pager, next, jumper"
				:total="total"
				@current-change="handleCurrentChange" />
		</section>

		<!-- 编辑用户标签页 -->
		<el-dialog v-model="editDialog" title="编辑用户标签页" width="600px">
			<el-form>
				<el-form-item label="用户ID">
					<el-input v-model="formData.userId"></el-input>
				</el-form-item>
				<el-form-item label="标签">
					{{ formData.tagObj.tagName }}&nbsp;
					<el-button type="primary" @click="$refs.tag.openTag()">选择标签</el-button>
					<tag ref="tag" @setTag="getTag"></tag>
				</el-form-item>
			</el-form>
			<template #footer>
				<el-button @click="editDialog = false">取消</el-button>
				<el-button type="primary" @click="submit">确定</el-button>
			</template>
		</el-dialog>
		<!-- 导出 -->
		<el-dialog v-model="outDialog" title="导出用户标签页" width="600px">
			<el-form class="out" label-width="120px">
				<el-form-item label="导出范围">
					<el-radio-group v-model="type">
						<el-radio :label="1" size="large">全部用户</el-radio>
						<el-radio :label="2" size="large">非全部用户</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="选择标签范围">
					<template v-for="(item, index) in tagIds" :key="index">
						<el-tag class="ml-2" closable @close="handleClose(index)" type="primary">{{ item.tagName }}</el-tag>
					</template>
					<el-tag class="ml-2" type="primary" @click="$refs.tag2.openTag()">选择标签</el-tag>
					<tag ref="tag2" @setTag="getTag2"></tag>
				</el-form-item>
				<el-form-item label="模板">
					<el-radio-group v-model="downLoadType">
						<el-radio label="1">模板1</el-radio>
						<el-radio label="2">模板2</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="用户Id获取方式" v-if="type == 2">
					<el-radio-group v-model="select">
						<el-radio label="1">根据心愿id</el-radio>
						<el-radio label="2">根据调研id</el-radio>
						<el-radio label="3">输入/文件导入</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="上传用户ID" v-if="select == 3 && type == 2">
					<fileTemplate sign="1" @fileData="getdata"/>
				</el-form-item>
				<el-form-item label="获取用户ID" v-if="select != 3 && type == 2">
					<div style="width: 100%; display: flex">
						<el-input v-model="id" style="width: 200px"></el-input>
						<el-button v-if="select == 1" type="primary" @click="queryUserListByWishId">搜索</el-button>
						<el-button v-if="select == 2" type="primary" @click="queryUserListBySpecialEventId">搜索</el-button>
					</div>
				</el-form-item>
				<el-form-item label="输入用户ID">
					<el-input type="textarea" v-model="userIds" style="width: 300px"></el-input>
				</el-form-item>

				<el-form-item label="邮箱">
					<el-input v-model="email"></el-input>
				</el-form-item>
			</el-form>
			<template #footer>
				<el-button @click="outDialog = false">取消</el-button>
				<el-button type="primary" @click="downloadUserTagExcelByUserIds">确定</el-button>
			</template>
		</el-dialog>
		<!-- 批量导入用户标签 -->
		<el-dialog v-model="fileDialog" title="批量添加用户标签" width="600px">
			<el-form>
				<el-form-item label="标签">
					{{ formData.tagObj.tagName }}&nbsp;
					<el-button type="primary" @click="$refs.tag.openTag()">选择标签</el-button>
					<tag ref="tag" @setTag="getTag"></tag>
				</el-form-item>
				<el-form-item label="文件">
					<fileTemplate sign="1" @fileData="getdata"/>
				</el-form-item>
			</el-form>

			<template #footer>
				<el-button @click="fileDialog = false">取消</el-button>
				<el-button type="primary" @click="addUserTagByFile">确定</el-button>
			</template>
		</el-dialog>
		<!-- 批量删除用户冲突标签 -->
		<el-dialog v-model="fileDialog1" title="删除用户冲突标签" width="600px">
			<el-form>
				<el-form-item label="标签">
					{{ formData.tagObj.tagName }}&nbsp;
					<el-button type="primary" @click="$refs.tag.openTag()">选择标签</el-button>
					<tag ref="tag" @setTag="getTag"></tag>
				</el-form-item>
				<el-form-item label="文件">
					<fileTemplate sign="1" @fileData="getdata"/>
				</el-form-item>
			</el-form>

			<template #footer>
				<el-button @click="fileDialog1 = false">取消</el-button>
				<el-button type="primary" @click="removeChongTags">确定</el-button>
			</template>
		</el-dialog>
	</div>
</template>
<script>
	import {
		queryUserTagListByUserTagSearchVo,
		addUserTag,
		modifyUserTagByUserTagId,
		removeUserTagByUserTagId,
		queryAllTagListByTagSearchVo,
		queryUserListByWishId,
		queryUserListBySpecialEventId,
		downloadUserTagExcelByUserIds,
		addUserTagBatch,
		removeInconsistentUserTags,
    flashUserMutexTags,
	} from "@/config/Api";
	import { downloadUserTagExcelByUserIdsPost } from "../../config/jsonapi.js";
	import { ElMessage } from "element-plus";
	import tag from "@/components/tag";
	import fileTemplate from "@/components/fileTemplate.vue";
	export default {
		data() {
			return {
				pageCurrent: 1,
				pageSize: 10,
				total: 1,
				loading: false,
				tableData: [],
				editDialog: false,
				formData: { tagObj: {} },

				outDialog: false,
				tagIds: [],
				select: "1",
				id: "",
				userIds: "",
				email: "",
				type: 2,
				downLoadType: "1",
				fileDialog: false,

				fileDialog1: false,
			};
		},
		components: {
			tag,
			fileTemplate
		},
		mounted() {
			let query = this.$route.query;
			this.pageCurrent = Number(query.page || 1);
			this.pageSize = Number(query.size || 10);
			this.getList();
		},
		methods: {
			// 上下页
			handleCurrentChange() {
				this.$router.push({
					path: "/Member_detail/usertags",
					query: { page: this.pageCurrent, size: this.pageSize },
				});
				this.getList();
			},
			// 获取标签
			getList() {
				this.loading = true;
				let param = {
					cunPage: this.pageCurrent,
					pageSize: this.pageSize,
				};
				queryUserTagListByUserTagSearchVo(param).then(
					(res) => {
						this.loading = false;
						this.tableData = res.result;
						this.total = res.data.totalCount;
						this.QueryTagListByTagSearchVo();
					},
					(err) => {
						this.loading = false;
						ElMessage.error("获取用户标签列表失败");
					}
				);
			},
			// 获取标签
			QueryTagListByTagSearchVo() {
				queryAllTagListByTagSearchVo({ cunPage: 0, pageSize: 0 }).then(
					(res) => {
						//获取标签列表
						this.loading = false;
						let list = res.result;
						let tags = getTags(list);
						let tableData = JSON.parse(JSON.stringify(this.tableData));
						this.tags = tags;
						for (let i in tableData) {
							for (let n in tags) {
								if (tableData[i].tagId === tags[n].id) {
									tableData[i].tagName = tags[n].value;
								}
							}
						}
						this.tableData = tableData;
						function getTags(list) {
							let tags = [];
							for (let i in list) {
								tags.push(...list[i].tags);
								if (list[i].tagRespDTOS.length) {
									tags.push(...getTags(list[i].tagRespDTOS));
								}
							}
							return tags;
						}
					},
					(err) => {
						this.loading = false;
						ElMessage.error("获取标签失败");
					}
				);
			},
			// 打开编辑页
			openEdit(type, row) {
				this.editDialog = true;
				if (type === "edit") {
					this.formData = {
						userTagId: row.id,
						level: row.level,
						source: row.source,
						sourceId: row.sourceId,
						tagId: row.tagId,
						userId: row.userId,
						tagObj: {
							tagId: row.tagId,
							tagName: row.tagName ? row.tagName : row.tagId,
						},
					};
				} else {
					this.formData = { tagObj: {} };
				}
			},
			// 获取标签
			getTag(obj) {
				console.log(obj);
				this.formData.tagId = obj.tagId;
				this.formData.tagObj = obj;
			},
			// 增加用户标签
			submit() {
				if (!this.formData.tagId) return ElMessage.error("请选择标签");
				if (!this.formData.userId) return ElMessage.error("请输入用户id");
				let param = {
					level: 0,
					source: 7,
					sourceId: 0,
					tagId: this.formData.tagId,
					userId: this.formData.userId,
				};
				if (this.formData.userTagId) {
					param.userTagId = this.formData.userTagId;
					this.loading = true;
					modifyUserTagByUserTagId(param).then(
						(res) => {
							this.loading = false;
							this.editDialog = false;
							ElMessage.success("修改用户标签成功");
							this.getList();
						},
						(err) => {
							this.loading = false;
							ElMessage.error("修改用户标签失败");
						}
					);
				} else {
					this.loading = true;
					addUserTag(param).then(
						(res) => {
							this.loading = false;
							this.editDialog = false;
							ElMessage.success("添加用户标签成功");
							this.getList();
						},
						(err) => {
							this.loading = false;
							ElMessage.error("添加用户标签失败");
						}
					);
				}
			},
			// 删除标签
			removeTag(row) {
				this.loading = true;
				removeUserTagByUserTagId({ userTagId: row.id }).then(
					(res) => {
						this.loading = false;
						ElMessage.success("删除用户标签成功");
						this.getList();
					},
					(err) => {
						this.loading = false;
						ElMessage.error("删除用户标签失败");
					}
				);
			},
			// 打开导出弹窗
			openOut() {
				this.outDialog = true;
				this.select = "1";
				this.id = "";
				this.userIds = "";
				this.tagIds = [];
			},
			getTag2(obj) {
				console.log(obj);
				this.tagIds.push(obj);
			},
			handleClose(index) {
				this.tagIds.splice(index, 1);
			},
			// 根据心愿id获取用户
			queryUserListByWishId() {
				if (!this.id) return ElMessage.error("请输入心愿id");
				this.loading = true;
				queryUserListByWishId({ wishId: this.id }).then(
					(res) => {
						let list = res.result;
						let arr = [];
						for (let i in list) {
							arr.push(list[i].id);
						}
						this.userIds = arr.join(",");
						this.loading = false;
						ElMessage.success("获取用户id成功");
					},
					(err) => {
						this.userIds = "";
						this.loading = false;
						ElMessage.error("获取用户id失败");
					}
				);
			},
			// 根据心愿id获取用户
			queryUserListBySpecialEventId() {
				if (!this.id) return ElMessage.error("请输入调研id");
				this.loading = true;
				queryUserListBySpecialEventId({ specialEventId: this.id }).then(
					(res) => {
						let list = res.result;
						let arr = [];
						for (let i in list) {
							arr.push(list[i].id);
						}
						this.userIds = arr.join(",");
						this.loading = false;
						ElMessage.success("获取用户id成功");
					},
					(err) => {
						this.userIds = "";
						this.loading = false;
						ElMessage.error("获取用户id失败");
					}
				);
			},
			// 导出用户标签
			downloadUserTagExcelByUserIds() {
				if (this.type == 2 && !this.userIds) return ElMessage.error("没有用户id");
				if (!this.email) return ElMessage.error("请填写邮箱");
				// if(!/\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/.test(this.email)) return ElMessage.error('邮箱格式不对 ');
				let tagIds = [];
				if (this.tagIds.length) {
					for (let i in this.tagIds) {
						tagIds.push(this.tagIds[i].tagId);
					}
				} else {
					tagIds = [0];
				}
				let userIds = this.userIds.split(",");
				for (let i in userIds) {
					userIds[i] = userIds[i] - 0;
				}
				let param = {
					email: this.email,
					type: this.type,
					userIds: this.type == 2 ? userIds : "",
					downLoadType: this.downLoadType,
					tagIds: tagIds,
				};
				downloadUserTagExcelByUserIdsPost(param).then(
					(res) => {
						let url = res.result;
						let oA = document.createElement("a");
						oA.href = url;
						oA.download = "用户标签";
						oA.click();
					},
					(err) => {
						ElMessage.error(err.msg);
					}
				);
				this.outDialog = false;
			},

			// 获取用户ID
			getdata(data){
				let userid = [];
				for(let i in data){
					if(data[i].用户ID){
						userid.push(data[i].用户ID)
					}
				}
				this.userIds = userid.join(",");
			},

			// 打开批量添加用户标签
			openFile() {
				this.formData = { tagObj: {} };
				this.fileDialog = true;
				this.userIds = "";
			},
			// 批量添加用户标签
			addUserTagByFile() {
				if (!this.formData.tagObj.tagId) return ElMessage.error("请选择标签");
				if (!this.userIds) return ElMessage.error("缺少用户ID");
				let param = {
					tagId: this.formData.tagObj.tagId,
					userIds: this.userIds,
				};
				this.loading = true;
				addUserTagBatch(param).then(
					(res) => {
						this.loading = false;
						this.fileDialog = false;
						this.getList();
					},
					(err) => {
						this.loading = false;
						ElMessage.error(err.msg);
					}
				);
			},
			// 打开
			openFile1() {
				this.formData = { tagObj: {} };
				this.fileDialog1 = true;
				this.userIds = "";
			},
			// 删除冲突标签
			removeChongTags() {
				if (!this.formData.tagObj.tagId) return ElMessage.error("请选择标签");
				if (!this.userIds) return ElMessage.error("缺少用户ID");
				console.log(this.userIds);
				this.loading = true;
				let param = {
					tagId: this.formData.tagObj.tagId,
					userIds: this.userIds,
				};
				removeInconsistentUserTags(param).then(
					(res) => {
						this.loading = false;
						this.fileDialog1 = false;
						this.getList();
					},
					(err) => {
						this.loading = false;
						ElMessage.error(err.msg);
					}
				);
			},
      // 刷新用户标签唯一性
      flashUserMutexTags(){
        this.loading = true;
        flashUserMutexTags().then(
          res => {
            this.loading = false;
            ElMessage.success('刷新成功')
            console.log(res);
          },
          err => {
            this.loading = false;
            ElMessage.error('刷洗失败')
            console.log(err);
          }
        )
      }
		},
	};
</script>
<style lang="scss">
	.out .el-input-group {
		width: auto !important;
	}
	.out .el-textarea {
		width: 100% !important;
		height: 150px;
		.el-textarea__inner {
			height: 150px;
		}
	}
</style>
