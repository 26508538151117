<template>
  <div class="Subject_management" v-loading="loading">
    <section class="search_btn">
      <div class="search_items">
        <span class="title">题目名称：</span>
        <div>
          <el-input
              v-model="this.questionName"
              class="w-50 m-2"
              size="large"
              placeholder="请输入题目名称"
          ></el-input>
        </div>
      </div>
      <div class="search_items">
        <span class="title">题目类型：</span>
        <div>
          <el-select v-model="this.questionType" placeholder="请选择题目类型" size="large">
            <el-option
                v-for="item in questionType_options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 标签类别 -->
      <div class="search_items">
        <span class="title">标签类别：</span>
        <div class="main">
          <el-cascader
              v-model="this.categoryIds"
              :show-all-levels="false"
              :options="groupList"
              :props="{ checkStrictly: true,children:'tagRespDTOS',label:'name',value:'id' }"
              clearable
          ></el-cascader>
        </div>
      </div>
      <div class="search_items">
        <span class="title">是否模板：</span>
        <div>
          <el-select v-model="this.isTemplate" placeholder="请选择" size="large">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </div>
      </div>

      <div class="search_items">
        <el-button type="primary" :icon="Search" @click="GetQuestionList">查询</el-button>
        <el-button type="danger" @click="edit_project('add')">添加题目</el-button>
        <el-upload
          style="margin-left:10px"
          v-loading.fullscreen="loadingfile"
          ref="upload"
          :headers="headers"
          multiple
          :limit="1"
          action="https://admin-beta.xinhekeji.net/api/admin/question/importQuestionCategoryAndQuestionOptionByExcel"
          :show-file-list="false"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        >
          <el-button type="primary">选择文件</el-button>
        </el-upload>
      </div>
    </section>
    <section class="user_info_list">
      <el-table :data="tableData">
        <el-table-column prop="question.id" label="ID" width="120" />
        <el-table-column prop="question.name" label="题目名称" />
        <el-table-column prop="optionCount" label="选项数量" width="120" />
        <el-table-column prop="questionType.name" label="题目类型" width="100" />
        <el-table-column prop="question.isActive" label="上架/下架" width="100">
          <template #default="scope">
            {{scope.row.question.isActive==1?'上架':'下架'}}
          </template>
        </el-table-column>
        <el-table-column prop="question.isRequired" label="题目必回答" width="100">
          <template #default="scope">
            {{scope.row.question.isRequired==1?'是':'否'}}
          </template>
        </el-table-column>
        <el-table-column prop="question.createTime" label="添加时间" width="200" />
        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-tag class="ml-2" @click="edit_project('edit',scope)">编辑</el-tag>
            <el-tag class="ml-2" type="success" @click="Is_delete(scope)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination :page-size="this.pageSize" :currentPage="this.pageNo" :total="this.totalCount" @current-change="Current_change" pager-count="10" background layout="prev, pager, next"></el-pagination>
    </section>

  </div>
</template>

<script>
import {MessageBox, Search} from '@element-plus/icons-vue'
import {ElMessage, ElMessageBox} from 'element-plus'
import global_api from "@/config/Api";

export default {
  name: 'Subject_management',
  data(){
    return {
      Search:Search,    //图标
      MessageBox:MessageBox,//图标
      questionName:'',//题目名称
      questionType:'',//题目类型
      questionType_options:[],
      createTime:'',  //创建时间
      pageNo:1,       //页码
      pageSize:10,      //每页数量
      totalCount:0,         //总数
      loading: false,
      tableData:[],         //列表
      headers:{
        id: localStorage.getItem(`id`),
        token: localStorage.getItem(`token`),
      },
      groupList:[],         //标签组列表
      categoryIds:[],       //标签组
      isTemplate:'',        //是否模板
    }
  },
  mounted() {
    this.GetQuestionTypeList()
    this.GetQuestionList();
    this.getTargetAudienceInfo();
  },
  methods: {
    // 获取标签组列表
    getTargetAudienceInfo(){                    // 获取标签接口
      this.loading = true;
      global_api.QueryTagListByTagSearchVo({cunPage:0,pageSize:0}).then((res)=>{     //获取标签列表
        this.loading = false;
        this.groupList = res.result;
      },err=>{
        this.loading = false;
        ElMessage.error('获取标签失败')
      })
    },

    Is_reveal(scope){                           // 是否上架
      let isActive = scope.row.question.isActive==1?0:1
      ElMessageBox.confirm(
          scope.row.question.isActive==1?'是否下架？':'是否上架？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        this.UpdateQuestionShelfOrDelete(scope.row.question.id,isActive,'reveal',scope.row.question.surveyId)
      })
    },

    Is_delete(scope){           //是否删除
      ElMessageBox.confirm(
          '是否删除?',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        console.log(scope.row.question.surveyId)
        this.UpdateQuestionShelfOrDelete(scope.row.question.id,2,'delete',scope.row.question.surveyId)
      })
    },

    UpdateQuestionShelfOrDelete(questionId,type,string,surveyId){     //题目上下架/删除接口  上架 = 1 / 下架 = 0 / 删除 = 2
      global_api.UpdateQuestionShelfOrDelete({questionId,type,surveyId}).then((res)=>{
        if(res.code!=0){
          ElMessage.error('题目上下架/删除接口错误')
        }
        if(string=='reveal'){
          ElMessage({
            type: 'success',
            message: type==0?'已下架':'已上架',
          })
        }
        if(string=='delete'){
          ElMessage({
            type: 'success',
            message: '已删除',
          })
        }
        this.GetQuestionList()
      })
    },

    edit_project(string ,scope){       //编辑 新增 题目
      if(string=='edit'){
        sessionStorage.setItem('subject_item',JSON.stringify(scope.row))
      }
      this.$router.push({
        path: "/Subject_management/edit",
        query: { type: string, },
      });
    },

    GetQuestionTypeList(){                 //获取题目类型列表
      global_api.GetQuestionTypeList().then((res)=>{
        if(res.code!=0){
          ElMessage.error('获取题目类型列表错误')
        }
        this.questionType_options = res.result
        console.log(res)
      })
    },

    Current_change(number){
      this.pageNo = number
      this.GetQuestionList()
    },

    GetQuestionList(){   //获取题目列表
      let object={
        brandId:'',
        categoryId:this.categoryIds[this.categoryIds.length-1],
        pageNo:this.pageNo,
        pageSize:this.pageSize,
        questionName:this.questionName,
        questionType:this.questionType,
        surveyId:0,
        isTemplate:this.isTemplate,
      }
      this.loading = true;
      global_api.GetQuestionList(object).then((res)=>{
        if(res.code!=0){
          ElMessage.error('获取品牌列表错误')
        }
        this.totalCount = res.data.totalCount
        this.tableData = res.result
        this.loading = false
        console.log(res)
      })
    },

  },
}
</script>
<style scoped lang="scss">
.Subject_management{
  margin-top: 20px;
}
.search_btn{
  //border-radius: 5px;
}
.user_info_list{
  margin-top: 20px;
  min-width: 800px;
  .btn{
    margin-top: 5px !important;
  }
}
.pagination{
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.no_float{
  float: none !important;
}

</style>
