<template>
  <div v-loading.fullscreen.lock='loading'>
    <section class="searchForm">
      <el-form inline>
        <el-form-item label="公司名">
          <el-input v-model="fromdata.company"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="() => {cunPage=1;getList()}">搜索</el-button>
          <el-button type="success" @click="() => {cunPage=1;fromdata={};getList()}">清除</el-button>
          <el-button type="primary" @click="addObj = {}">新建账户</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="list">
        <el-table-column label="id" prop="id" width="80px"></el-table-column>
        <el-table-column label="用户名" prop="firstName" width="150px"></el-table-column>
        <el-table-column label="公司名称" prop="company" width="150px"></el-table-column>
        <el-table-column label="部门" prop="department" width="150px"></el-table-column>
        <el-table-column label="头像" prop="avatar" width="80px">
          <template #default="scope">
            <el-image
              style="width: 50px; height: 50px"
              :src="scope.row.avatar"
              :zoom-rate="1.2"
              :preview-src-list="[scope.row.avatar]"
              :initial-index="0"
              fit="cover"
              preview-teleported
            />
          </template>
        </el-table-column>
        <el-table-column label="phone" prop="phone" width="150px"></el-table-column>
        <el-table-column label="quota" prop="quota" width="100"></el-table-column>
        <el-table-column label="超级用户" prop="isSuperuser" width="100"></el-table-column>
        <el-table-column label="权限" prop="role" width="100"></el-table-column>
        <el-table-column label="状态" prop="status">

        </el-table-column>
        <el-table-column label="邀请码" width="200px">
          <template #default='scope'>
            {{scope.row.inviteCode}}
            <el-button type="primary" size="small" @click="editObj = scope.row">修改</el-button>
          </template>
        </el-table-column>
        <el-table-column label="产品" width="100px">
          <template #default='scope'>
            <el-button type="primary" size="small" @click="gotoAccountProduct(scope.row)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="问卷" width="100px">
          <template #default='scope'>
            <el-button type="primary" size="small" @click="gotoAccountSurvey(scope.row)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="primary" size="small" @click="openEditAdmin(scope.row)">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="cunPage"
        :page-size="10"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="getList"
      />
    </section>

    <!--  修改邀请码弹窗  -->
    <el-dialog title="修改邀请码" v-model="editObj">
      <el-form label-suffix=":" label-width="100px">
        <el-form-item label="邀请码">{{editObj.inviteCode}}</el-form-item>
        <el-form-item label="新邀请码">
          <el-input v-model="editObj.newInviteCode"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="editObj = null">取消</el-button>
        <el-button type="primary" @click="updateInvitationCodeByBrandAuthAdminId">确定</el-button>
      </template>
    </el-dialog>
    <!-- 新建主账户  -->
    <el-dialog title="新建主账户" v-model="addObj">
      <el-form label-width="100px" label-suffix=":">
        <el-form-item label="用户名">
          <el-input v-model="addObj.firstName"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="addObj.phone"></el-input>
        </el-form-item>
        <el-form-item label="公司名">
          <el-input v-model="addObj.company"></el-input>
        </el-form-item>
        <el-form-item label="部门">
          <el-input v-model="addObj.department"></el-input>
        </el-form-item>
        <el-form-item label="头像">
          <el-upload
              :action="Get_url+'admin/file/uploadFile'"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :headers="headers"
          >
            <img style="width:300px" v-if="addObj.avatar" :src="addObj.avatar" class="avatar" />
            <img style="width:300px" v-else src="../../assets/bannerDefualt.png" class="avatar"/>
          </el-upload>
          {{addObj.avatar}}
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="addObj = null">取消</el-button>
        <el-button type="primary" @click="addMasterInvitationCode">确定</el-button>
      </template>
    </el-dialog>
    <!-- 修改主账户 -->
    <el-dialog title="修改主账户" v-model="editAdmin">
      <el-form>
        <el-form-item label="用户名">
          <el-input v-model="editAdmin.firstName"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="editAdmin.phone"></el-input>
        </el-form-item>
        <el-form-item label="公司名">
          <el-input v-model="editAdmin.company"></el-input>
        </el-form-item>
        <el-form-item label="部门">
          <el-input v-model="editAdmin.department"></el-input>
        </el-form-item>
        <el-form-item label="头像">
          <el-upload
              :action="Get_url+'admin/file/uploadFile'"
              :show-file-list="false"
              :on-success="(e)=>{editAdmin.avatar = e.msg}"
              :headers="headers"
          >
            <img style="width:300px" v-if="editAdmin.avatar" :src="editAdmin.avatar" class="avatar" />
            <img style="width:300px" v-else src="../../assets/bannerDefualt.png" class="avatar"/>
          </el-upload>
        </el-form-item>
        <el-form-item label="quota">
          <el-input-number v-model="editAdmin.quota"></el-input-number>
        </el-form-item>
        <el-form-item label="超级用户">
          <el-radio-group v-model="editAdmin.isSuperuser">
            <el-radio :label="0">0</el-radio>
            <el-radio :label="1">1</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="editAdmin = null">取消</el-button>
        <el-button type="primary" @click="updateBrandAuthAdmin">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {queryIBrandAuthAdminByBrandAuthAdminId,addMasterInvitationCode,updateInvitationCodeByBrandAuthAdminId,updateBrandAuthAdmin} from '@/config/Api'
import { ElMessage } from 'element-plus'
import {Get_url} from "@/config/Get_url";
export default {
  data() {
    return {
      cunPage: 1,
      total: 0,
      fromdata: {},
      list: [],
      loading: false,
      editObj: null,
      addObj: null,
      headers:{
        id:localStorage.getItem('id'),
        token: localStorage.getItem('token')
      },
      Get_url:Get_url(),
      editAdmin: null,
    }
  },
  mounted() {
    this.cunPage = 1;
    this.getList();
  },
  methods: {
    // 获取账户列表
    getList() {
      this.loading = true;
      let param = {
        cunPage: this.cunPage,
        pageSize: 10,
        ...this.fromdata,
      }
      queryIBrandAuthAdminByBrandAuthAdminId(param).then(res => {
        this.loading = false;
        this.list = res.result;
        this.total = res.data.totalCount;

      },err => {
        this.loading = false;
        ElMessage.error(err.msg)
      })
    },
    // 修改邀请码
    updateInvitationCodeByBrandAuthAdminId(){
      this.loading = true;
      let param = {
        brandAuthAdminId: this.editObj.id,
        inviteCode: this.editObj.newInviteCode
      }
      updateInvitationCodeByBrandAuthAdminId(param).then(res => {
        this.loading = false;
        this.getList();
        this.editObj = null;
      },err => {
        this.loading = false;
        ElMessage.error(err.msg);
      })
    },
    // 新建主账户
    addMasterInvitationCode() {
      if(!this.addObj.phone) return ElMessage.error('请输入手机号');
      let pattern = /(13\d|14[579]|15[^4\D]|17[^49\D]|18\d)\d{8}/g;
      console.log(pattern.test(this.addObj.phone));
      if(pattern.test(this.addObj.phone)) return ElMessage.error('请输入正确的手机号')
      this.loading = true;
      let param = {...this.addObj,phone:'+86'+this.addObj.phone};
      addMasterInvitationCode(param).then(res => {
        this.loading = false;
        this.getList();
        this.addObj = null;
      },err => {
        this.loading = false;
        ElMessage.error(err.msg);
      })
    },
    handleAvatarSuccess(e){
      this.addObj.avatar = e.msg
    },
    // 查看账户产品
    gotoAccountProduct(row){
      this.$router.push({path:'/cemAccount/product',query:{accountId:row.id}})
    },
    // 查看账户调研
    gotoAccountSurvey(row){
      this.$router.push({path:'/cemAccount/survey',query:{accountId:row.id}})
    },
    // 打开修改主账户弹窗
    openEditAdmin(row){
      this.editAdmin = JSON.parse(JSON.stringify(row));
      console.log(row);
    },
    // 修改主账户
    updateBrandAuthAdmin(){
      this.loading = true;
      let param = {...this.editAdmin};
      updateBrandAuthAdmin(param).then(
        res => {
          this.loading = true;
          this.editAdmin = null;
          ElMessage.success('修改成功');
          this.getList();
        },
        err => {
          this.loading = false;
          ElMessage.error('修改失败')
        }
      )
    }
  }
}
</script>
