<template>
<el-dialog v-model="flag" title="选项关联设置" width="1000px">
  <el-tabs type="border-card" v-model="activeName">
    <el-tab-pane v-for="(item,index) in tabList" :label="`关联题目${index + 1}`" :name="`n${index + 1}`">
      <el-select v-model="item.id">
        <el-option v-for="(item,index) in questionList" :key="index" :label="item.question.name" :value="item.question.id"></el-option>
      </el-select>
      <el-select v-model="item.type">
        <el-option label="选中显示" :value=1></el-option>
        <el-option label="未选中显示" :value=2></el-option>
        <el-option label="选中不显示" :value=3></el-option>
        <el-option label="未选中不显示" :value=4></el-option>
      </el-select>
      <div class="table">
        
      </div>
    </el-tab-pane>
    <el-tab-pane>
      <template #label>
        <span @click="addNewTab">新增</span>
      </template>
    </el-tab-pane>
  </el-tabs>
  <!-- <el-select v-model="questionIndex" @change="selectChange">
    <el-option v-for="(item,index) in questionList" :key="index" :label="item.question.name" :value="index"></el-option>
  </el-select>
  <div class="table">
    <div class="table_left">
      <div class="item" v-for="(item,index) in optionList" :key="index">{{item.name}}</div>
    </div>
    <div class="table_right">
      <div class="item" v-for="(item,index) in optionListRight" :key="index">{{item?.name}}</div>
    </div>
  </div> -->
  <el-button type="primary" @click="closeDialog">同步</el-button>
</el-dialog>
</template>

<script>
export default {
  name: "optionLink",
  props:["questionList","optionData"],
  data() {
    return {
      flag: false,
      tabList: [],
      activeName: ''
      // questionIndex: '',
      // optionList: [],
      // optionListRight: [],
      // id: '',
    }
  },
  methods: {
    // 新增关联题目
    addNewTab(){
      let tabList = this.tabList;
      tabList.push({id:'',type:1});
      this.tabList = tabList;
      setTimeout(()=>{
        this.activeName = `n${tabList.length}`;
      })
    },




    // 打开
    open() {
      this.flag = true;
    },
    // 确定
    selectChange(data,e) {
      console.log(data,e);
      let question = this.questionList[value];
      let optionList = question.optionList;
      let tabList = this.tabList;
      
      return ;
      console.log(question)
      this.id = question.question.id;
      this.optionList = optionList;
      let optionData = JSON.parse(JSON.stringify(this.optionData));
      let list = [];
      console.log(optionList)
      for(let i in optionList) {
        let f = true;
        for(let n in optionData) {
          if(optionList[i].name === optionData[n].name) {
            list[i] ={
              name:optionList[i].name,
              iconDefault:optionList[i].iconDefault,
              iconSelect:optionList[i].iconSelect,
              img:optionList[i].img,
              content:optionList[i].content,
              optionPosition:optionList[i].optionPosition,
              isActive: true,
              id:optionData[n].id,
              sort: Number(i) + 1,
            } ;
            f = false;
            break;
          }
        }
        if(f){
          list[i] = {
            name:optionList[i].name,
            iconDefault:optionList[i].iconDefault,
            iconSelect:optionList[i].iconSelect,
            img:optionList[i].img,
            content:optionList[i].content,
            optionPosition:optionList[i].optionPosition,
            isActive: true,
            sort: Number(i) + 1,
          };
        }
      }
      for(let i in optionData){
        let f = true;
        for(let n = 0;n < list.length; n++){
          if(optionData[i].name === list[n]?.name){
            f = false;
            break;
          }
        }
        if(f){
          list.push({
            ...optionData[i],
            sort: list.length + 1,
          })
        }
      }
      console.log(list)
      this.optionListRight = list;
    },
    // 同步
    closeDialog(){
      this.$emit('option',{data:this.tabList});
      this.flag = false;
    }
  }

}
</script>

<style scoped>
.table {
  width: 100%;
  display: flex;
  justify-content: center;
  border: 1px solid #999999;
  margin: 10px 0;
}
.table .table_left {
  width: 50%;
  border-right: 1px solid #999999 ;
}
.table .table_right {
  width: 50%;
}
.table .item {
  height: 20px;
  padding: 5px 10px;
  font-size: 18px;
  border-bottom: 1px solid #999999;

}
</style>