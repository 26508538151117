<template>
  <div class="Prompt_message" v-loading="loading">
    <section class="user_info_list">
      <el-table :data="tableData">
        <el-table-column prop="kvKey" label="类型" width="200" />
        <el-table-column prop="kvValue" label="内容">
          <template #default="scope">
            <div>
              <el-input
                  v-model="scope.row.kvValue"
                  class="w-50 m-2 width500"
                  size="large"
                  placeholder="请输入内容"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template #default="scope">
            <el-tag class="ml-2" type="danger" @click="modifyKvMapByKvMapId(scope)">保存</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </section>

  </div>
</template>

<script>
import {Search,MessageBox} from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import global_api from "@/config/Api";
export default {
  name: 'Prompt_message',
  data(){
    return {
      Search:Search,    //图标
      MessageBox:MessageBox,//图标
      loading:false,
      tableData:[]
    }
  },
  mounted() {
    this.QueryKvMapListByType()
  },
  methods: {
    QueryKvMapListByType(){              //根据type获取数据字典列表
      let obj = {
        cunPage:1,
        pageSize:100,
        type:0,
        id:1
      }
      this.loading = true
      global_api.QueryKvMapListByType(obj).then((res)=>{
        if(res.code!=0){
          ElMessage.error('根据type获取数据字典列表接口错误')
        }else{
          this.tableData = res.result
          console.log(this.tableData)
        }
        this.loading = false

      })
    },

    modifyKvMapByKvMapId(scope){   //通过数据字典id修改数据字典
      if(scope.row.id === 35){
        console.log(/^\d:\d$/.test(scope.row.kvValue))
        if(/^\d:\d$/.test(scope.row.kvValue)){
          if(Number(scope.row.kvValue.split(':')[0]) + Number(scope.row.kvValue.split(':')[1]) !== 8){
            return ElMessage.error('比例设置不正确')
          }
        }else {
          return ElMessage.error('比例设置不正确')
        }
      }
      let obj={
        id:1,
        kvKey:scope.row.kvKey,
        kvValue:scope.row.kvValue,
        type:0,
        kvMapId:scope.row.id
      }
      global_api.modifyKvMapByKvMapId(obj).then((res)=>{
        if(res.code!=0){
          ElMessage.error('添加数据字典内容接口错误')
        }else{
          this.editor_dialog = false
          ElMessage({
            message: '保存成功',
            type: 'success',
          })
          this.QueryKvMapListByType()
        }
      })
    },

  },
}
</script>
<style scoped lang="scss">
.Prompt_message{
  margin-top: 20px;
}
.search_btn{
  //border-radius: 5px;
}
.user_info_list{
  margin-top: 20px;
  min-width: 800px;
  .btn{
    margin-top: 5px !important;
  }
}
.pagination{
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.no_float{
  float: none !important;
}
</style>
