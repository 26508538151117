<template>
  <div>
    <el-form>
      <el-form-item label="标签名称">
        <el-input style="width:210px" v-model="name"></el-input>
      </el-form-item>
      <el-form-item label="标签时间">
        <el-select v-model="isLongTerm">
          <el-option label="短期标签" :value="0"></el-option>
          <el-option label="长期标签" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标签状态">
        <el-select v-model="isObvious">
          <el-option label="显性标签" :value="0"></el-option>
          <el-option label="隐形标签" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="唯一性">
        <el-select v-model="isMutex">
          <el-option label="唯一标签" :value="0"></el-option>
          <el-option label="非唯一标签" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="extras">
        <el-input style="width:210px" v-model="extras"></el-input>
      </el-form-item>
    </el-form>
    <div class="foot">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" v-if="type=='add'" @click="addTag">确定</el-button>
      <el-button type="primary" v-if="type=='edit'" @click="modifyTag">修改</el-button>
    </div>
  </div>
</template>
<script>
import { ElMessage } from 'element-plus';
import global_api from '@/config/Api'
export default {
  props:['type','value'],
  data(){
    return {
      type: this.type,
      name: this.value.value,
      isLongTerm: this.value.isLongTerm,
      isObvious: this.value.isObvious,
      parentId: this.value.parentId,
      tagId: this.value.tagId,
      isMutex: this.value.isMutex,
      extras: this.value.extras
    }
  },
  watch: {
    type(newV,oldV){
      this.type = newV;
    },
    value:{
      handler(newV,oldV){
        this.name = newV.value;
        this.isLongTerm = newV.isLongTerm;
        this.isObvious = newV.isObvious;
        this.parentId = newV.parentId;
        this.tagId = newV.tagId;
        this.isMutex = newV.isMutex;
        this.extras = newV.extras;
      },
      deep: true
    }
  },
  methods:{
    addTag(){
      if(!this.name) return ElMessage.error('请输入标签名称');
      if(!this.isLongTerm&&this.isLongTerm!=0) return ElMessage.error('请选择标签时间');
      if(!this.isObvious&&this.isObvious!=0) return ElMessage.error('请选择标签状态');
      if(!this.parentId) return ElMessage.error('信息缺失！');
      let param = {
        isLongTerm: this.isLongTerm,
        isObvious: this.isObvious,
        value: this.name,
        parentId: this.parentId,
        isMutex: this.isMutex,
        extras: this.extras
      }
      global_api.AddTag(param).then(res=>{
        ElMessage.success('添加成功');
        this.$emit('updata')
      },err=>{
        ElMessage.error('添加失败')
      })
    },
    modifyTag(){
      if(!this.name) return ElMessage.error('请输入标签名称');
      if(!this.isLongTerm&&this.isLongTerm!=0) return ElMessage.error('请选择标签时间');
      if(!this.isObvious&&this.isObvious!=0) return ElMessage.error('请选择标签状态');
      if(!this.parentId||!this.tagId) return ElMessage.error('信息缺失！');
      let param = {
        isLongTerm: this.isLongTerm,
        isObvious: this.isObvious,
        value: this.name,
        parentId: this.parentId,
        tagId: this.tagId,
        isMutex: this.isMutex,
        extras: this.extras
      }
      global_api.ModifyTagByTagId(param).then(res=>{
        ElMessage.success('修改成功');
        this.$emit('updata')
      },err=>{
        ElMessage.error('修改失败')
      })
    },
    close(){
      this.$emit('close')
    }
  }
  
}
</script>
<style lang="scss" scoped>
.foot{
  display: flex;
  justify-content: end;
  padding: 10px 0;
}
</style>