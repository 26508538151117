<template>
  <div class="formwork">
    <p>模板</p>
    <section style="display:flex;justify-content:space-between">
      <el-table :data="templateList">
        <el-table-column label="name" prop="title"></el-table-column>
        <el-table-column label="模板id" prop="priTmplId"></el-table-column>
        <el-table-column label="例子" prop="example"></el-table-column>
        <el-table-column label="操作" width="100px">
          <template #default="scope">
            <el-button type="primary" @click="editTemplate(scope.row)">发送</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section>
      <p>发送中</p>
      <el-table :data="sendArr">
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="name" prop="name"></el-table-column>
        <el-table-column label="发送人数" prop="sentCount"></el-table-column>
        <el-table-column label="成功人数" prop="successCount"></el-table-column>
        <el-table-column label="失败人数" prop="failCount"></el-table-column>
        <el-table-column label="状态" prop="status">
          <template #default="scope">{{(scope.row.status===0&&'发送中')||(scope.row.status===1&&'失败')||(scope.row.status===2&&'成功')}}</template>
        </el-table-column>
        <el-table-column label="操作人" prop="creator"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="延迟时间" prop="firstTime"></el-table-column>
        <el-table-column label="操作" width="200">
          <template #default="scope">
            <el-button v-if="scope.row.state==1" type="primary" @click="stopOnGoingSend(scope.row)">结束</el-button>
            <el-button v-if="scope.row.state==1" type="success" @click="suspendOnGoingSend(scope.row)">暂停</el-button>
            <el-button v-if="scope.row.state==2" type="success" @click="restartOnGoingSend(scope.row)">继续</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section>
      <p>已完成</p>
      <el-table :data="finishArr">
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="name" prop="name"></el-table-column>
        <el-table-column label="使用模板" prop="priTmplId">
          <template #default="scope">
            {{temObj[scope.row.priTmplId] || scope.row.priTmplId}}
          </template>
        </el-table-column>
        <el-table-column label="发送人数" prop="sentCount"></el-table-column>
        <el-table-column label="成功人数" prop="successCount"></el-table-column>
        <el-table-column label="失败人数" prop="failCount"></el-table-column>
        <el-table-column label="状态" prop="status">
          <template #default="scope">{{(scope.row.status===0&&'发送中')||(scope.row.status===1&&'失败')||(scope.row.status===2&&'成功')}}</template>
        </el-table-column>
        <el-table-column label="操作人" prop="creator"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="primary" @click="lookDetail(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          v-model:page-size="pageSize"
          v-model:currentPage="cunPage"
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="queryFinishSend"
      />
    </section>
    <el-dialog v-model="dialogFlag" width="800px">
      <el-table :data="dialogList">
        <el-table-column label="时间" prop="createTime"></el-table-column>
        <el-table-column label="用户id" prop="userId"></el-table-column>
        <el-table-column label="结果" prop="userId">
          <template #default="scope">
            {{(scope.row.status===0&&'成功')||(scope.row.status===1&&'失败')}}
          </template>
        </el-table-column>
        <el-table-column label="结果描述" prop="remark"></el-table-column>
      </el-table>
      <el-pagination
          v-model:page-size="pageSize"
          v-model:currentPage="cunPage1"
          background
          layout="prev, pager, next"
          :total="total1"
          @current-change="queryNotificationRecordDetailById"
      />
      <template #footer>
        <el-button type="primary" @click="outFile">导出</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {restartOnGoingSend,stopOnGoingSend,suspendOnGoingSend,querySuspendSend,queryFinishSend, queryNotificationRecordDetailById, queryOnGoingSend, queryTemplateList,downloadNotificationRecordDetailIdExcelByNotificationRecordId} from '../../config/Api';
let tem = null;
export default {
  name: "formwork",
  data(){
    return {
      cunPage:1 ,
      pageSize: 10,
      total: 0,
      cunPage1:1,
      total1:0,
      finishArr: [],
      sendArr: [],
      templateList:[],
      dialogFlag: false,
      dialogList: [],
      id:'',
      temObj:{},

      stopSend: [],
    }
  },
  mounted() {
    this.queryTemplateList();
    this.queryFinishSend();
    this.queryOnGoingSend();
    this.querySuspendSend();
  },
  beforeRouteLeave(to,from,next) {
    clearTimeout(tem);
    console.log('清除计时器');
    next();
  },
  methods: {
    // 查询已完成的消息
    queryFinishSend(){
      let param = {
        cunPage: this.cunPage,
        pageSize: this.pageSize,
      }
      queryFinishSend(param).then(res=>{
        this.finishArr = res.result;
        this.total = res.data.totalCount;
      },err=>{
        this.finishArr = [];
      })
    },
    // 查询发送中的消息
    queryOnGoingSend(){
      queryOnGoingSend({cunPage:1,pageSize:100}).then(res=>{
        let list = res.result;
        for(let i in list){
          list[i].state = 0;   //  默认值，不显示操作按钮
          if(this.stopSend.indexOf(list[i].id+'') != -1){
            list[i].state = 2;  //  暂停中
          }else{
            list[i].state = 1;  //  无暂停
          }
        }
        this.sendArr = list;
        this.setTime();
      },err=>{
        this.setTime();
      })
    },
    setTime(){
      clearTimeout(tem);
      tem = setTimeout(()=>{
        this.queryOnGoingSend();
      },10000)
    },
    // 查看模板详情
    lookDetail(row){
      this.dialogFlag = true;
      this.cunPage1 = 1;
      this.id = row.id;
      this.queryNotificationRecordDetailById();
    },
    // 获取详情
    queryNotificationRecordDetailById(){
      let param = {
        cunPage: this.cunPage1,
        pageSize:10,
        notificationRecordId:this.id,
      }
      queryNotificationRecordDetailById(param).then(res=>{
        this.dialogList = res.result;
        this.total1 = res.data.totalCount;
      })
    },
    // 获取官方模板
    queryTemplateList(){
      queryTemplateList().then(res=>{
        let data = JSON.parse(res.result);
        console.log(data)
        this.templateList = data;
        let obj = {};
        data.forEach(item=>{
          obj[item.priTmplId] = item.title;
        })
        this.temObj = obj;
        console.log(obj);
      })
    },
    // 编辑发送模板
    editTemplate(row){
      sessionStorage.setItem('template',JSON.stringify(row))
      this.$router.push({path:'/editformwork'})
    },
    // 导出模板发送结果
    outFile(){
      this.loading = true;
      downloadNotificationRecordDetailIdExcelByNotificationRecordId({notificationRecordId:this.id}).then(res=>{
        let aEle = document.createElement('a');
        aEle.document = '模板发送结果';
        aEle.href = res.result;
        aEle.click();
      },err=>{

      })
    },
    // 查询暂停中的发送
    querySuspendSend(){
      querySuspendSend().then(res=>{
        this.stopSend = res.result;
        let list = this.sendArr;
        for(let i in list){
          list[i].state = 0;   //  默认值，不显示操作按钮
          if(this.stopSend.indexOf(list[i].id+'') != -1){
            list[i].state = 2;  //  暂停中
          }else{
            list[i].state = 1;  //  无暂停
          }
        }
        this.sendArr = list;
      },err=>{
        let list = this.sendArr;
        for(let i in list){
          list[i].state = 0;   //  默认值，不显示操作按钮
        }
        this.sendArr = list;
      })
    },
    // 暂停发送
    suspendOnGoingSend(row){
      let param = {
        notificationRecordId: row.id
      }
      this.loading = true;
      suspendOnGoingSend(param).then(res=>{
        this.loading = false;
        this.stopSend = [];
        this.querySuspendSend();
        this.queryOnGoingSend();
      },err=>{
        this.loading = false;
      })
    },
    // 继续暂停的发送
    restartOnGoingSend(row){
      let param = {
        notificationRecordId: row.id,
      }
      this.loading = true;
      restartOnGoingSend(param).then(res=>{
        this.loading = false;
        this.stopSend = [];
        this.querySuspendSend();
        this.queryOnGoingSend();
      },err=>{
        this.loading = false;
      })
    },
    // 结束发送
    stopOnGoingSend(row){
      this.loading = true;
      let param = {
        notificationRecordId: row.id
      }
      stopOnGoingSend(param).then(res=>{
        this.loading = false;
        this.stopSend = [];
        this.querySuspendSend();
        this.queryOnGoingSend();
      },err=>{
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>