<template>
  <div class="Image_setting" v-loading="loading">
    <div v-for="(item,index) in this.tableData">
      <div class="upload_img" v-show="item.id==15">
        <el-upload
            class="avatar-uploader"
            :action="Get_url+'admin/file/uploadFile'"
            :headers="{token:this.token,id:this.id}"
            :show-file-list="false"
            :on-success="AvatarSuccess"
            :before-upload="beforeAvatarUpload"
            @click="upload(index)"
        >
          <img v-if="item.kvValue" :src="item.kvValue" class="avatar" />
          <img v-else src="../../assets/bannerDefualt.png" class="avatar"/>
        </el-upload>
        <div class="upload_img_txt">{{item.kvKey}}<span class="red">(尺寸750*1580)</span></div>
      </div>

      <div class="upload_img" v-show="item.id==21">
        <el-upload
            class="avatar-uploader"
            :action="Get_url+'admin/file/uploadFile'"
            :headers="{token:this.token,id:this.id}"
            :show-file-list="false"
            :on-success="AvatarSuccess"
            :before-upload="beforeAvatarUpload"
            @click="upload(index)"
        >
          <img v-if="item.kvValue" :src="item.kvValue" class="avatar" />
          <img v-else src="../../assets/bannerDefualt.png" class="avatar"/>
        </el-upload>
        <div class="upload_img_txt">{{item.kvKey}}<span class="red">(尺寸1:1)</span></div>
      </div>

      <div class="upload_img" v-show="item.id==16">
        <el-upload
            class="avatar-uploader"
            :action="Get_url+'admin/file/uploadFile'"
            :headers="{token:this.token,id:this.id}"
            :show-file-list="false"
            :on-success="AvatarSuccess"
            :before-upload="beforeAvatarUpload"
            @click="upload(index)"
        >
          <img v-if="item.kvValue" :src="item.kvValue" class="avatar" />
          <img v-else src="../../assets/bannerDefualt.png" class="avatar"/>
        </el-upload>
        <div class="upload_img_txt">{{item.kvKey}}<span class="red">(尺寸5:4)</span></div>
      </div>

      <div class="upload_img" v-show="item.id==22">
        <el-upload
            class="avatar-uploader"
            :action="Get_url+'admin/file/uploadFile'"
            :headers="{token:this.token,id:this.id}"
            :show-file-list="false"
            :on-success="AvatarSuccess"
            :before-upload="beforeAvatarUpload"
            @click="upload(index)"
        >
          <img v-if="item.kvValue" :src="item.kvValue" class="avatar" />
          <img v-else src="../../assets/bannerDefualt.png" class="avatar"/>
        </el-upload>
        <div class="upload_img_txt">/pages/activity/imgSrcLink/imgSrcLink</div>
        <div class="upload_img_txt">{{item.kvKey}}<span class="red">(尺寸750*X)</span></div>
      </div>

      <div class="upload_img" v-show="item.id==36">
        <el-upload
            class="avatar-uploader"
            :action="Get_url+'admin/file/uploadFile'"
            :headers="{token:this.token,id:this.id}"
            :show-file-list="false"
            :on-success="AvatarSuccess"
            :before-upload="beforeAvatarUpload"
            @click="upload(index)"
        >
          <img v-if="item.kvValue" :src="item.kvValue" class="avatar" />
          <img v-else src="../../assets/bannerDefualt.png" class="avatar"/>
        </el-upload>
        <div class="upload_img_txt">{{item.kvKey}}<span class="red">(推荐尺寸750*378)</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import {Search,MessageBox} from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import global_api from "@/config/Api";
import {Get_url} from "../../config/Get_url";
export default {
  name: 'Image_setting',
  data(){
    return {
      Search:Search,    //图标
      MessageBox:MessageBox,//图标
      loading:false,
      tableData:[],
      Get_url:Get_url(),
      index:0,
      token:localStorage.getItem('token'),
      id:localStorage.getItem('id')
    }
  },
  mounted() {
    this.QueryKvMapListByType()
  },
  methods: {
    QueryKvMapListByType(){              //根据type获取数据字典列表
      let obj = {
        cunPage:1,
        pageSize:100,
        type:1,
        id:1
      }
      this.loading = true
      global_api.QueryKvMapListByType(obj).then((res)=>{
        if(res.code!=0){
          ElMessage.error('根据type获取数据字典列表接口错误')
        }else{
          this.tableData = res.result
          console.log(this.tableData)
        }
        this.loading = false
      })
    },

    upload(index){
      this.index = index
    },
    AvatarSuccess(response,uploadFile){
      this.tableData[this.index].kvValue = uploadFile.response.msg
      console.log(uploadFile)
      this.modifyKvMapByKvMapId()
    },
    beforeAvatarUpload(rawFile){
      if (rawFile.size / 1024 / 1024 > 1) {
        ElMessage.error('上传的图片超过1MB了！')
        return false
      }
      return true
    },

    modifyKvMapByKvMapId(){   //通过数据字典id修改数据字典
      let obj={
        id:1,
        kvKey:this.tableData[this.index].kvKey,
        kvValue:this.tableData[this.index].kvValue,
        type:1,
        kvMapId:this.tableData[this.index].id
      }
      global_api.modifyKvMapByKvMapId(obj).then((res)=>{
        if(res.code!=0){
          ElMessage.error('添加数据字典内容接口错误')
        }else{
          this.editor_dialog = false
          ElMessage({
            message: '保存成功',
            type: 'success',
          })
          this.QueryKvMapListByType()
        }
      })
    },

  },
}
</script>
<style scoped lang="scss">
.Image_setting{
  margin-top: 20px;
  display: flex;
  justify-content: start;
  align-items: start;
  .upload_img{
    padding: 10px;
    margin: 10px;
    border: 1px solid #eee;
  }
  .upload_img_txt{
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
  }
  .avatar{
    width: 200px;
  }
}

</style>
