<template>
  <div id="level">
    <section>
      <el-button type="primary" :icon="Plus" @click="dialogVisible=true">添加</el-button>
    </section>
    <section class="tableList">
      <el-table :data="tableData">
        <el-table-column fixed prop="id" label="ID" width="80"/>
        <el-table-column fixed prop="name" label="等级名称" width="150"/>
        <el-table-column prop="number" label="等级编号" width="100"/>
        <el-table-column prop="levelIcon" label="等级图标" width="100">
          <template #default="scope">
            <div class="icon">
              <img :src="scope.row.levelIcon" alt="">
            </div>
          </template>
        </el-table-column>
        <el-table-column label="奖励图标" width="100">
          <template #default="scope">
            <div class="icon">
              <img :src="scope.row.prizeIcon" alt="">
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="needCoin" label="所需积分" width="100"/>
        <el-table-column prop="levelExplain" label="等级说明" min-width="300"/>
        <el-table-column prop="createTime" label="创建时间" width="180"/>
        <el-table-column fixed="right" label="操作" width="140">
          <template #default>
            <el-button size="small" type="primary" @click="dialogVisible=true">编辑</el-button>
            <el-button size="small" type="danger" @click="deleteRow">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="pageCurrent"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </section>

    <!-- 添加编辑弹窗 -->
    <el-dialog v-model="dialogVisible" title="添加" width="500px" draggable>
      <el-form>
        <el-form-item label="等级名称">
          <el-input type="text"></el-input>
        </el-form-item>
        <el-form-item label="等级编号">
          <el-input type="text"></el-input>
        </el-form-item>
        <el-form-item label="所需积分">
          <el-input type="text"></el-input>
        </el-form-item>
        <el-form-item label="等级说明">
          <el-input type="text"></el-input>
        </el-form-item>
        <el-form-item label="等级图标">
          <el-upload action="#" list-type="picture-card" :auto-upload="false">
            <template #default>
              <el-icon><svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ba633cb8=""><path fill="currentColor" d="M480 480V128a32 32 0 0 1 64 0v352h352a32 32 0 1 1 0 64H544v352a32 32 0 1 1-64 0V544H128a32 32 0 0 1 0-64h352z"></path></svg></el-icon>
            </template>
            <template #file="{ file }">
              <div>
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
              </div>
            </template>
          </el-upload>
        </el-form-item>
        <el-form-item label="奖励图标">
          <el-upload action="#" list-type="picture-card" :auto-upload="false">
            <template #default >
              <el-icon><svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ba633cb8=""><path fill="currentColor" d="M480 480V128a32 32 0 0 1 64 0v352h352a32 32 0 1 1 0 64H544v352a32 32 0 1 1-64 0V544H128a32 32 0 0 1 0-64h352z"></path></svg></el-icon>
            </template>
            <template #file="{ file }">
              <div>
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
              </div>
            </template>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible=false">取消</el-button>
          <el-button type="primary" @click="dialogVisible=false">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { Plus } from '@element-plus/icons-vue'
import { ElMessageBox } from 'element-plus'
export default {
  data() {
    return {
      Plus: Plus,
      // 当前页码
      pageCurrent: 1,
      // 页面数据量
      pageSize: 10,
      // 总数据条数
      total: 1000,
      // 表格数据
      tableData: [
        {id:1,name: '心愿收集者', number: 12, levelIcon: './images/autor.jpeg', prizeIcon: './images/autor.jpeg', needCoin: 100, levelExplain: '心愿收集者是一级称谓', createTime: '1254631636'},
        {id:12548,name: '心愿达人', number: 15, levelIcon: './images/autor.jpeg', prizeIcon: './images/autor.jpeg', needCoin: 140, levelExplain: '心愿达人是二级称谓', createTime: '1254631636'},
        {id:551,name: '心愿小天使', number: 16, levelIcon: './images/autor.jpeg', prizeIcon: './images/autor.jpeg', needCoin: 180, levelExplain: '心愿小天使是三级称谓', createTime: '1254631636'},
        {id:9211,name: '心愿使者', number: 19, levelIcon: './images/autor.jpeg', prizeIcon: './images/autor.jpeg', needCoin: 220, levelExplain: '心愿使者是四级称谓', createTime: '1254631636'},
        {id:4386,name: '心愿大使', number: 25, levelIcon: './images/autor.jpeg', prizeIcon: './images/autor.jpeg', needCoin: 260, levelExplain: '心愿大使是五级称谓', createTime: '1254631636'},
      ],
      // 添加编辑弹窗
      dialogVisible: false,
    }
  },
  mounted() {
    let query = this.$route.query;
    this.pageCurrent = Number(query.page || 1);
    this.pageSize = Number(query.size || 10);
    this.getCommentList();
  },
  methods: {
    // 页码改变触发
    handleCurrentChange() {
      this.$router.push({
        path: "/level",
        query: { page: this.pageCurrent, size: this.pageSize },
      });
      this.getCommentList();
    },
    // 获取数据
    getCommentList() {},
    // 删除
    deleteRow() {
      ElMessageBox.alert('确认删除','提示',)
    }
  },
}
</script>
<style scoped lang="scss">
.icon {
  width: 40px;
  height: 40px;
  img {
    width: 40px;
    height: 40px;
  }
}
</style>