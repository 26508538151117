<template>
  <div class="admin" v-loading.fullscreen.lock="loading">
    <section class="searchForm">
      <el-form inline>
        <el-form-item>
          <el-button @click="openModify">添加</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="roleList" row-key="id" border default-expand-all :tree-props="{ children: 'roles'}">
        <el-table-column label="name" prop="roleName"></el-table-column>
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="权限">
          <template #default="scope">
            <el-button size="small" type="primary" @click="openRole(scope.row)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column label="createTime" prop="createTime"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="primary" @click="openModify(scope.row)">修改</el-button>
            <el-button type="danger" @click="removeAdmin(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>

    <!-- 修改用户信息弹窗 -->
    <el-dialog title="编辑用户信息" v-model="modifyDialog" width="500px">
      <el-form label-width="80px">
        <el-form-item label="name">
          <el-input v-model="formData.roleName"></el-input>
        </el-form-item>
        <el-form-item label="父级">
          <el-cascader
            v-model="formData.parentId"
            :options="options"
            :props="props"
            @change="handleChange"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button>取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </template>
    </el-dialog>
    <!-- 查看权限 -->
    <el-dialog title="查看权限" v-model="roleDialog" width="900px">
      <el-form inline size="small">
        <el-form-item label="权限名">
          <el-input v-model="name" style="width:150px" @keyup.enter="search"></el-input>
        </el-form-item>
        <el-form-item label="是否拥有">
          <el-select v-model="own">
            <el-option label="全部" value="1"></el-option>
            <el-option label="拥有" value="2"></el-option>
            <el-option label="未拥有" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="权限路径">
          <el-cascader
            v-model="url"
            :options="urlTree"
            :props="props1"
          />
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="search">搜索</el-button>
          <el-button size="small" @click="clearSearch">清除</el-button>
        </el-form-item>
      </el-form>
      <el-table height="500px" border :data="tableList">
        <el-table-column label="权限名字" prop="apiName"></el-table-column>
        <el-table-column label="权限路径" prop="path"></el-table-column>
        <el-table-column label="拥有" prop="own" width="80px">
          <template #header>
            <el-switch v-model="allOwn" @change="allChange"></el-switch>
          </template>
          <template #default="scope">
            <el-switch v-model="scope.row.own" :key="scope.row.id" @change="beforeChange(scope.$index)" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"></el-switch>
          </template>
        </el-table-column>
        
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import { ElMessageBox, ElMessage } from 'element-plus';
import {queryRoleList, removeRoleByRoleId, modifyRoleByRoleId, addRole,queryApiListByRoleId,modifyApiLogByRoleId} from '../../config/Api'
import exportExcel from '@/utils/excel';
let tempList = [];
export default {
  data() {
    return {
      roleList: [],
      loading: false,
      formData: {},
      modifyDialog: false,
      props: {
        clearable: true,
        expandTrigger: 'hover',
        value: 'id',
        label: 'roleName',
        children: 'roles',
        checkStrictly: true,
      },
      options: [],
      roleDialog: false,
      tableList: [],
      loading1: false,
      name: '',
      own: '1',
      urlTree: [],
      url: [],
      props1: {
        checkStrictly: true
      },
      allOwn: false
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取数据
    getList() {
      this.loading = true;
      queryRoleList({}).then(res=>{
        let roleList = res.result;
        roleList = d(roleList);
        console.log(roleList)
        this.roleList = roleList;
        this.loading = false;
      },err=>{
        this.roleList = [];
        this.loading = false;
      })
      function d (list){
        for(let i in list){
          if(!list[i].roles.length){
            delete list[i].roles
          }
        }
        for(let i in list){
          if(list[i].roles?.length){
            list[i].roles = d(list[i].roles);
          }
        }
        return list;
      }
    },
    // 删除管理员
    removeAdmin(row){
      ElMessageBox.confirm(
        '是否删除',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
      .then(() => {
        this.loading = true;
        removeRoleByRoleId({roleId: row.id}).then(res => {
          this.loading = false;
          this.getList();
          ElMessage.success('删除成功');
        }, err => {
          this.loading = false;
          ElMessage.error('删除失败');
        })
      })
      .catch(() => {
        ElMessage.success('取消删除');
      })
    },
    // 打开编辑弹窗
    openModify(row){
      if(row){
        this.formData = {
          roleName: row.roleName,
          id: row.id,
          parentId: [row.parentId],
        }
      } else {
        this.formData = {
          roleName: '',
          id: '',
          parentId: '0'
        }
      }
      this.options = [{id:0,roleName:'无'},...this.roleList]
      this.modifyDialog = true;
    },
    // 编辑确定按钮
    submit() {
      if(this.formData.id){
        this.modifyRoleByRoleId();
      } else {
        this.addRole();
      }
    },
    // 编辑管理员
    modifyRoleByRoleId(){
      if(!this.formData.roleName) return ElMessage.error('请输入名字');
      this.loading = true;
      let param = {
        roleId: this.formData.id,
        roleName: this.formData.roleName,
        parentId: this.formData.parentId[this.formData.parentId.length-1]
      }
      modifyRoleByRoleId(param).then(res => {
        this.loading = false;
        this.getList();
        this.modifyDialog = false;
      },err => {
        this.loading = false;
        ElMessage.error('修改失败')
      })
    },
    // 添加管理员
    addRole(){
      if(!this.formData.roleName) return ElMessage.error('请输入名字');
      this.loading = true;
      let param = {
        roleName: this.formData.roleName,
        parentId: this.formData.parentId[this.formData.parentId.length-1]
      }
      addRole(param).then(res => {
        this.loading = false;
        this.getList();
        this.modifyDialog = false;
      },err => {
        this.loading = false;
        ElMessage.error('添加管理员失败')
      })
    },
    // 打开查看权限
    openRole(row){
      this.tableList = [];
      
      this.roleId = row.id;
      this.queryApiListByRoleId(row.id)
    },
    // 获取权限列表
    queryApiListByRoleId(id){
      this.loading = true;
      queryApiListByRoleId({roleId:id}).then(res=>{
        tempList = res.result;
        this.tableList = tempList;
        this.loading = false;
        this.roleDialog = true;
        this.buildSelectTree();
        let allOwn = true;
        for(let i in tempList){
          if(!tempList[i].own){ 
            allOwn = false;
            break;
          }
        }
        this.allOwn = allOwn;
      },err=>{
        this.loading = false;
        tempList = [];
        this.tableList = [];
      })
    },
    // 修改权限
    beforeChange(index){
      this.loading = true;
      let apiIds = [];
      for(let i in this.tableList){
        if(this.tableList[i].own){
          apiIds.push(this.tableList[i].id)
        }
      }
      modifyApiLogByRoleId({apiIds:apiIds.join(','),roleId:this.roleId}).then(res=>{
        this.loading = false;
      },err=>{
        this.loading = false;
        this.tableList[index].own = !this.tableList[index].own;
      })

    },
    // 搜索
    search(){
      let list = JSON.parse(JSON.stringify(tempList));
      let a = [];
      for(let i in list){
        if(this.name&&list[i].apiName.indexOf(this.name)==-1){
          continue;
        }
        if((!list[i].own&&this.own==='2')||(list[i].own&&this.own==='3')){
          continue;
        }
        if(this.url.length){
          let surl = '^\/' + this.url.join('/')
          let reg = new RegExp(surl)
          if(!reg.test(list[i].path)){
            continue;
          }
        }
        
        a.push(list[i])
      }
      this.tableList = a;
    },
    // 清除
    clearSearch(){
      this.name = '';
      this.own = '1';
      this.url = [];
      this.search();
    },
    // 生成选择树
    buildSelectTree(){
      let list = tempList;
      let selectTree = [];
      for(let i in list){
        let path = list[i].path.split('/');
        let parent = '';
        let node = '';
        for(let n in path){
          if(path[n]){
            let obj = {
              value: path[n],
              name: path[n],
              children: []
            }
            if(parent){
              parent.children.push(obj)
              parent= obj;
            }else{
              node = obj;
              parent = obj;
            }
          }
        }
        selectTree.push(node)
      }

      this.urlTree = d(selectTree);

      function d (selectTree) {
        let treeObj = {};

        for(let i in selectTree){
          if(treeObj[selectTree[i].name]){
            treeObj[selectTree[i].name].push(...selectTree[i].children)
          }else{
            treeObj[selectTree[i].name] = [...selectTree[i].children]
          }
        }
        let tree = [];
        for(let k in treeObj){
          tree.push({
            label: k,
            value: k,
            children: d(treeObj[k])
          })
          if(!tree[tree.length - 1].children.length){
            delete tree[tree.length - 1].children;
          }
        }
        return tree;
      }
    },
    // 输出
    allChange(){
      this.loading = true;
      let apiIds = [];
      for(let i in this.tableList){
        apiIds.push(this.tableList[i].id)
      }
      modifyApiLogByRoleId({apiIds:apiIds.join(','),roleId:this.roleId}).then(res=>{
        this.loading = false;
      },err=>{
        this.loading = false;
        this.tableList[index].own = !this.tableList[index].own;
      })
    }
  }
}
</script>