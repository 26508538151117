<template>
  <div class="goodsQuestion" v-loading='loading'>
    <section class="searchForm">
      <el-row>
        <el-col :span="8">
          <el-button type="primary" @click="addNew">新建题目</el-button>
          <el-button type="primary" @click="leading_in_btn">导入题目</el-button>
          <AnswerVisible :survey="goods" />
          <AnswerDel style="margin-left: 10px" :survey="goods" />
        </el-col>
        <el-col :span="6">
          <el-form>
            <el-form-item label="问卷显示样式">
              <el-radio-group v-model="goods.showStyleType" @change="modifyGradeTypeBySurveyId">
                <el-radio-button :label="1">卡片</el-radio-button>
                <el-radio-button :label="2">列表</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="10">
          产品名称：{{ goods.originId }}-{{ goods.name }}
        </el-col>
      </el-row>
    </section>
    <section class="tableList">
      <el-table :data="tableList">
        <el-table-column prop="question.id" label="ID" width="100"></el-table-column>
        <el-table-column prop="question.sort" label="题目编号" width="100">
          <template #default="scope">
            <span style="margin-right: 10px">{{ scope.row.question.sort }}</span>
            <el-tag class="ml-2" @click="code_update(scope)">修改</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="question.name" label="题目名称" width="200"></el-table-column>
        <el-table-column prop="optionCount" label="选项数量"></el-table-column>
        <el-table-column prop="questionType.name" label="题目类型"></el-table-column>
        <el-table-column prop="id" label="上架/下架" width="100">
          <template #default="scope">{{ scope.row.question.isActive ? '上架' : '下架' }}</template>
        </el-table-column>
        <el-table-column prop="id" label="题目必回答" width="100">
          <template #default="scope">{{ scope.row.question.isRequired ? '是' : '否' }}</template>
        </el-table-column>
        <el-table-column label="显示设置" width="100">
          <template #default="scope">
            <el-button type="primary" plain size="small" @click="queryQuestionShowByQuestionId(scope.row)">设置</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="questionSkipCount" label="选项跳题设置" width="120">
          <template #default="scope">
            <el-button size="small" type="primary" @click="this.question_edit(scope)">编辑</el-button>
          </template>
        </el-table-column>
        <el-table-column label="用户回答" width="100">
          <template #default="scope">
            <el-button size="small" type="success" @click="gotoUserAnswer(scope.row)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="question.createTime" label="添加时间" width="180"></el-table-column>
        <el-table-column prop="id" label="操作" width="200">
          <template #default="scope">
            <el-button size="small" type="primary"
              @click="changeLine(scope.row)">{{ scope.row.question.isActive ? '下架' : '上架' }}</el-button>
            <el-button size="small" type="success" @click="modifyQuestion(scope.row)">编辑</el-button>
            <el-button size="small" type="danger" @click="remove(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>

    <el-dialog v-model="this.question_dialog" title="选项设置" draggable width="50%">
      <div class="question_content">
        <el-button type="primary" @click="add_rule">新增规则</el-button>
        <div class="project_title">题目标题</div>
        <div class="group" v-for="(item, index) in this.items_array">
          <div class="">
            <span class="project_txt">逻辑</span>
            <el-select v-model="item.logic" placeholder="请选择">
              <el-option v-for="item in this.logic_options" :key="item.type" :label="item.name" :value="item.type">
              </el-option>
            </el-select>
          </div>
          <div class="magintop20">
            <span class="project_txt">跳题</span>
            <el-select v-model="item.nextQuestionUseId" placeholder="请选择">
              <el-option v-for="item in this.project_options" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="magintop20">
            <el-checkbox v-model="item2.flag" :label="item2.name" size="large" v-for="(item2, index2) in item.option_list">
              <div style="height: 80px;display: flex;align-items: center;vertical-align: -10px">
                <span v-show="item2.name">{{ item2.name }}</span><img :src="item2.img" style="width: 80px;height:80px;"
                  v-show="item2.img">
              </div>
            </el-checkbox>
          </div>
          <div class="magintop20">
            <el-button type="primary" @click="this.RemoveQuestionSkipByQuestionSkipId(item)">删除</el-button>
            <el-button type="primary" @click="this.conserve(item, index)">保存</el-button>
          </div>
        </div>
      </div>
      <template #footer>
        <el-button type="primary" @click="confirm">确定</el-button>
      </template>
    </el-dialog>

    <el-dialog v-model="this.leading_in_dialog" title="导入题目" width="40%" draggable>
      <div style="height: 180px">
        <div class="search_items no_float">
          <span class="title">大类：</span>
          <div>
            <el-select v-model="this.leading_in_categoryId_one" placeholder="请选择大类" size="large"
              @change="category_change">
              <el-option v-for="item in leading_in_category_one_options" :key="item.id" :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="search_items no_float">
          <span class="title">小类：</span>
          <div>
            <el-select v-model="this.leading_in_categoryId_two" placeholder="请选择小类" size="large">
              <el-option v-for="item in leading_in_category_two_options" :key="item.id" :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="search_items no_float">
          <span class="title">品牌：</span>
          <div>
            <el-select v-model="this.leading_in_brandId" placeholder="请选择品牌" size="large">
              <el-option v-for="item in leading_in_brand_options" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div>
        <el-button type="primary" @click="this.serach_leading_in_question">查询题目</el-button>
      </div>
      <div style="margin-top: 20px;height: 50px">
        <div class="search_items no_float">
          <span class="title">选择题目：</span>
          <div>
            <el-select v-model="this.leading_in_question" placeholder="请选择题目" size="large">
              <el-option v-for="item in leading_in_question_options" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="this.question_canel">取消</el-button>
          <el-button type="primary" @click="this.question_confirm">确认</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="this.code_update_dialog" title="题目编号修改" width="40%" draggable>
      <div style="margin-top: 20px;height: 50px">
        <div class="search_items no_float">
          <span class="title">题目编号：</span>
          <div>
            <el-input v-model="this.code_sort" class="w-50 m-2" size="large" placeholder="请输入题目编号"></el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="this.code_canel">取消</el-button>
          <el-button type="primary" @click="this.code_confirm">确认</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="dialog" title="显示规则" width="500px">
      <p>选择题目</p>
      <el-select v-model="value" @change="selectChange" placeholder="显示题目" class="init" style="width:100%">
        <el-option v-for="item in questionList" :key="item.question.id" :label="item.question.name"
          :value="item.question.id" />
      </el-select>
      <p>绑定选项</p>
      <el-checkbox-group v-model="selectId">
        <el-checkbox v-for="item in selectList" :key="item.id" :label="item.id">{{ item.name }}</el-checkbox>
      </el-checkbox-group>
      <p>逻辑类型</p>
      <el-select v-model="type1">
        <el-option :value="0" label="或" />
        <el-option :value="1" label="与" />
      </el-select>

      <template #footer>
        <el-button type="primary" plain @click="dialog = false">取消</el-button>
        <el-button type="primary" v-if="!questionShowId" @click="addQuestionShowByQuestionId">确定</el-button>
        <el-button type="primary" v-if="questionShowId" @click="modifyQuestionShowByShowId">修改</el-button>
        <el-button type="danger" v-if="questionShowId" @click="removeQuestionShowByShowId">删除</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { addQuestionShowByQuestionId, modifyQuestionShowByShowId, removeQuestionShowByShowId, queryQuestionShowByQuestionId, UpdateQuestionShelfOrDelete, QuerySurveyInfoBySurveyTypeAndOriginId, modifyGradeTypeBySurveyId } from '../../config/Api';
import global_api from "@/config/Api";
import { ElMessage, ElMessageBox } from 'element-plus'
import AnswerVisible from "@/components/answerVisible.vue"
import AnswerDel from "@/components/answerDel.vue";
export default {
  data() {
    return {
      id: '',
      projectId: '',
      b: '',
      name: '',
      loading: false,
      tableList: [],
      goods: {},

      question_dialog: false,
      logic_options: [{ name: '且', type: 0 }, { name: '或', type: 1 }],
      project_options: [],
      option_list: [],
      items_array: [],
      questionId: '',
      surveyId: '',

      leading_in_dialog: false,    //导入
      leading_in_categoryId_one: '',  //大类id
      leading_in_categoryId_two: '',  //小类id
      leading_in_brandId: '',         //品牌id
      leading_in_question: '',         // 导入的题目id
      leading_in_category_one_options: [],
      leading_in_category_two_options: [],
      leading_in_brand_options: [],          //品牌
      leading_in_question_options: [],      //题目

      code_update_dialog: false,     //改题目编号用
      code_sort: 0,
      code_questionId: '',
      code_surveyId: '',

      dialog: false,
      questionList: [],
      selectId: '',
      type1: '',
      questionShowId: '',
      value: '',
      selectList: [],
      type: '',
    }
  },
  components: {
    AnswerVisible,
    AnswerDel
  },
  mounted() {
    let quety = this.$route.query;
    this.id = quety.id;
    this.projectId = quety.projectId;
    this.b = quety.b;
    this.name = quety.name
    this.getData();
    this.GetBrandList()
    this.GetCategoryList_one()
  },
  methods: {
    code_update(scope) {            //修改题目编号按钮
      this.code_sort = scope.row.question.sort
      this.code_questionId = scope.row.question.id
      this.code_surveyId = scope.row.question.surveyId
      this.code_update_dialog = true
      console.log(scope)
    },

    code_canel() {
      this.code_update_dialog = false
    },

    code_confirm() {
      let param = {
        questionId: this.code_questionId,
        sort: this.code_sort,
        surveyId: this.code_surveyId
      }
      console.log(param)
      global_api.ModifyQuestionSortByQuestionId(param).then((res) => {
        if (res.code != 0) {
          ElMessage.error('通过题目id修改序号错误')
        }
        this.code_update_dialog = false
        ElMessage({
          type: 'success',
          message: '修改成功',
        })
        this.getData();
      })
    },

    question_canel() {
      this.leading_in_dialog = false
      this.leading_in_categoryId_one = ''
      this.leading_in_categoryId_two = ''
      this.leading_in_brandId = ''
      this.leading_in_question = ''
    },

    question_confirm() {
      let param = {
        questionId: this.leading_in_question,
        surveyId: this.goods.id
      }
      global_api.ImportQuestionInfo(param).then((res) => {
        if (res.code != 0) {
          ElMessage.error('查询导入题目库列表错误')
        }
        this.leading_in_dialog = false
        ElMessage({
          type: 'success',
          message: '导入成功',
        })
        this.getData();
        // console.log(res)
      })
    },

    leading_in_btn() {
      this.leading_in_dialog = true
      this.leading_in_categoryId_one = ''
      this.leading_in_categoryId_two = ''
      this.leading_in_brandId = ''
      this.leading_in_question = ''
    },

    serach_leading_in_question() {     //查询题目
      console.log(this.surveyId)
      let param = {
        brandId: this.leading_in_brandId,
        surveyId: this.goods.id
      }
      if (!this.leading_in_categoryId_two) {
        if (this.leading_in_category_two_options.length > 0) {
          let leading_in_categoryId_two = []
          for (let i = 0; i < this.leading_in_category_two_options.length; i++) {
            leading_in_categoryId_two.push(this.leading_in_category_two_options[i].id)
          }
          param.classIds = leading_in_categoryId_two.join(',')
        } else {
          param.classIds = this.leading_in_categoryId_one
        }
      } else {
        param.classIds = this.leading_in_categoryId_two
      }
      global_api.GetImportQuestionList(param).then((res) => {
        if (res.code != 0) {
          ElMessage.error('查询导入题目库列表错误')
        }
        let leading_in_question_options = res.result
        let arr = ['', '单选', '多选', '简答', '排序', '打分', '比重', '滑条', '矩形单选', '矩形多选', '图片', 'NPS', '种草', '购买意愿']
        for (let i = 0; i < leading_in_question_options.length; i++) {
          leading_in_question_options[i].name = leading_in_question_options[i].name + '(' + arr[leading_in_question_options[i].type] + ')'
        }
        this.leading_in_question_options = leading_in_question_options
      })
    },

    GetBrandList() {                   //获取品牌列表
      global_api.GetBrandList().then((res) => {
        if (res.code != 0) {
          ElMessage.error('获取品牌列表错误')
        }
        this.leading_in_brand_options = res.result
        this.leading_in_brand_options.unshift({ id: 0, name: '不选择' })
      })
    },

    category_change() {
      this.leading_in_categoryId_two = ''
      this.GetCategoryList_two(this.leading_in_categoryId_one, 2)
    },

    GetCategoryList_one() {               //获取大类列表
      global_api.GetCategoryList_one().then((res) => {
        if (res.code != 0) {
          ElMessage.error('获取大类列表错误')
        }
        this.leading_in_category_one_options = res.result
      })
    },

    GetCategoryList_two(pid, level) {                //获取小类列表
      global_api.GetCategoryList_two({ pid, level: level }).then((res) => {
        if (res.code != 0) {
          ElMessage.error('获取小类列表错误')
        }
        this.leading_in_category_two_options = res.result
        console.log(res)
      })
    },

    confirm() {
      this.question_dialog = false
      this.getData();
    },

    RemoveQuestionSkipByQuestionSkipId(item) {
      // console.log(item)
      if (item.id) {
        global_api.RemoveQuestionSkipByQuestionSkipId({ questionSkipId: item.id }).then(res => {
          ElMessage({
            type: 'success',
            message: '删除成功',
          });
          this.question_dialog = false
          this.getData()
        }, err => {
          ElMessage.error(`获取失败:${err.msg}`)
        })
      } else {
        ElMessage({
          type: 'success',
          message: '删除成功',
        });
        this.question_dialog = false
        this.getData()
      }
    },

    conserve(item, index) {        //保存
      console.log(item)
      if (item.cur_status == '新增') {
        this.AddQuestionSkipByQuestionId(item, index)
      } else {
        this.ModifyQuestionSkipByShowId(item, index)
      }
    },

    ModifyQuestionSkipByShowId(item, index) {   //根据跳题规则id修改
      let flag = false
      console.log(item)
      let param = {
        logic: item.logic,
        nextQuestionUseId: item.nextQuestionUseId,
        questionSkipId: item.id,
        surveyId: this.surveyId,
        type: 1
      }
      let surveyQuestionSkipOption = []
      for (let i = 0; i < item.option_list.length; i++) {
        if (item.option_list[i].flag) {
          flag = true
          surveyQuestionSkipOption.push({ optionId: item.option_list[i].id, value: '', condition: 1 })
        }
      }
      param.surveyQuestionSkipOption = JSON.stringify(surveyQuestionSkipOption)
      if (!flag) {
        ElMessage.error(`选项必须选择`)
        return false
      }
      global_api.ModifyQuestionSkipByShowId(param).then(res => {
        ElMessage({
          type: 'success',
          message: '修改成功',
        });
        this.question_dialog = false
        this.getData()
      }, err => {
        ElMessage.error(`获取失败:${err.msg}`)
      })
    },

    AddQuestionSkipByQuestionId(item, index) {      //根据题目id添加跳题规则
      let flag = false
      let param = {
        logic: item.logic,
        nextQuestionUseId: item.nextQuestionUseId,
        questionId: this.questionId,
        surveyId: this.surveyId,
        // surveyQuestionSkipOption:JSON.stringify([{optionId:2065,value:'',condition:1},{optionId:2239,value:'',condition:1}]),
        type: 1
      }
      let surveyQuestionSkipOption = []
      for (let i = 0; i < item.option_list.length; i++) {
        if (item.option_list[i].flag) {
          flag = true
          surveyQuestionSkipOption.push({ optionId: item.option_list[i].id, value: '', condition: 1 })
        }
      }
      param.surveyQuestionSkipOption = JSON.stringify(surveyQuestionSkipOption)
      if (!flag) {
        ElMessage.error(`选项必须选择`)
        return false
      }
      global_api.AddQuestionSkipByQuestionId(param).then(res => {
        //this.items_array[index]['cur_status'] = '修改'
        ElMessage({
          type: 'success',
          message: '新增成功',
        });
        this.question_dialog = false
        this.getData()
        // console.log(res)
      }, err => {
        ElMessage.error(`获取失败:${err.msg}`)
      })
    },

    add_rule() {      //新增规则
      let option_list = JSON.parse(JSON.stringify(this.option_list))
      for (let i = 0; i < option_list.length; i++) {
        option_list[i]['flag'] = false
      }
      let items_array = this.items_array
      let param = {
        logic: '',
        nextQuestionUseId: '',
        option_list,
        cur_status: '新增'
      }
      items_array.push(param)
      this.items_array = items_array
      console.log(this.items_array)
    },

    //编辑题目
    question_edit(scope) {
      console.log(scope.row)
      sessionStorage.setItem('question_item', JSON.stringify(scope.row))
      this.$router.push('/goods/question/jump')
    },

    // 获取问卷信息
    getData() {
      let param = {
        originId: this.id,
        surveyName: this.name,
        surveyType: 2,
        projectId: this.projectId,
      }
      this.loading = true;
      QuerySurveyInfoBySurveyTypeAndOriginId(param).then(res => {
        this.loading = false;
        this.goods = res.result;
        this.getList()
      })
    },

    // 获取题目
    getList() {
      let param = {
        surveyId: this.goods.id,
        pageNo: 1,
        pageSize: 500,
      }
      this.loading = true;
      global_api.GetQuestionList(param).then(res => {
        this.loading = false;
        this.tableList = res.result;
        console.log(this.tableList)
      }, err => {
        this.loading = false;
        ElMessage.error(`获取失败:${err.msg}`)
      })
    },

    // 删除
    remove(row) {
      let param = {
        questionId: row.question.id,
        type: 2,
        surveyId: row.question.surveyId
      }
      console.log(row)
      this.loading = true;
      UpdateQuestionShelfOrDelete(param).then(res => {
        this.loading = false;
        ElMessage({
          type: 'success',
          message: '删除成功',
        })
        this.getList();
      }, err => {
        this.loading = false;
        ElMessage.error(`删除失败:${err.msg}`)
      })
    },

    // 上下架
    changeLine(row) {
      let param = {
        questionId: row.question.id,
        type: row.question.isActive ? 0 : 1,
        surveyId: row.question.surveyId
      }
      this.loading = true;
      UpdateQuestionShelfOrDelete(param).then(res => {
        this.loading = false;
        this.getList();
      }, err => {
        this.loading = false;
        ElMessage.error(`上下架失败:${err.msg}`)
      })
    },

    // 新建题目
    addNew() {
      this.$router.push({
        path: '/edit/question',
        query: { type: 'add', surveyId: this.goods.id, bt: 1, bid: this.id, b: this.b, name: this.name }
      })
    },

    // 编辑题目
    modifyQuestion(row) {
      sessionStorage.setItem('subject_item', JSON.stringify(row))
      this.$router.push({
        path: '/edit/question',
        query: { type: 'edit', surveyId: this.goods.id, bt: 1, bid: this.id, b: this.b, name: this.name }
      })
    },

    // 查看显示规则
    queryQuestionShowByQuestionId(row) {
      let param = {
        questionId: row.question.id
      }
      this.loading = true;
      queryQuestionShowByQuestionId(param).then(res => {
        this.loading = false;
        if (res.result) {
          let selectId = res.result.selectId.split(',')
          for (let i in selectId) {
            selectId[i] = Number(selectId[i])
          }
          let questionList = [];
          for (let i in this.tableList) {
            for (let n in this.tableList[i].optionList) {
              if (this.tableList[i].optionList[n].id == selectId[0]) {
                this.value = this.tableList[i].question.id;
                this.selectList = this.tableList[i].optionList;
              }
            }
            if (this.tableList[i].question.sort < row.question.sort) {
              questionList.push(this.tableList[i]);
            }
          }

          this.questionList = questionList;
          this.selectId = selectId;
          this.type1 = res.result.type;
          this.questionShowId = res.result.id;
          this.dialog = true;

        } else {
          this.selectId = [];
          this.selectList = [];
          this.type1 = 0;
          this.value = '';
          this.questionShowId = '';

          this.questionId = row.question.id;

          let questionList = [];
          for (let i in this.tableList) {
            if (this.tableList[i].question.sort < row.question.sort) {
              questionList.push(this.tableList[i]);
            }
          }
          this.questionList = questionList;
          this.dialog = true;
        }
      }, err => {
        this.loading = false;
        ElMessage.error('获取显示规则失败')
      })
    },
    // 添加显示逻辑
    addQuestionShowByQuestionId() {
      if (!this.selectId?.length) {
        ElMessage.error('请选择选项')
        return;
      }
      if (!this.type1 && this.type1 != 0) {
        ElMessage.error('请选择逻辑泪类型')
        return;
      }
      let param = {
        questionId: this.questionId,
        selectId: this.selectId.join(','),
        type: this.type1
      }
      console.log(param);
      this.fullscreenLoading = true;
      addQuestionShowByQuestionId(param).then(res => {
        this.fullscreenLoading = false;
        this.dialog = false;
        this.getList();
      }, err => {
        this.fullscreenLoading = false;
        ElMessage.error('添加失败')
      })
    },
    // 修改
    modifyQuestionShowByShowId() {
      if (!this.selectId?.length) {
        ElMessage.error('请选择选项')
        return;
      }
      if (!this.type1 && this.type1 != 0) {
        ElMessage.error('请选择逻辑泪类型')
        return;
      }
      let param = {
        questionShowId: this.questionShowId,
        selectId: this.selectId.join(','),
        type: this.type1
      }
      this.fullscreenLoading = true;
      modifyQuestionShowByShowId(param).then(res => {
        this.fullscreenLoading = false;
        this.dialog = false;
        this.getList();
      }, err => {
        this.fullscreenLoading = false;
        ElMessage.error('修改失败')
      })
    },
    // 删除
    removeQuestionShowByShowId() {
      let param = {
        questionShowId: this.questionShowId
      }
      this.fullscreenLoading = true;
      removeQuestionShowByShowId(param).then(res => {
        this.fullscreenLoading = false;
        this.dialog = false;
        this.getList();
      }, err => {
        this.fullscreenLoading = false;
        ElMessage.error('删除失败')
      })
    },
    // 选择题目
    selectChange(v) {
      console.log(v)
      for (let i in this.tableList) {
        if (this.tableList[i].question.id == v) {
          this.selectList = this.tableList[i].optionList;
          return;
        }
      }
    },
    // 查看用户回答
    gotoUserAnswer(row) {
      console.log(row);
      localStorage.setItem('question', JSON.stringify(row));
      this.$router.push({ path: '/useranswer' })
    },
    // 修改问卷显示样式
    modifyGradeTypeBySurveyId(type) {
      console.log(type);
      this.loading = true;
      let param = {
        surveyId: this.goods.id,
        type: 1,
        showStyleType: type
      };
      modifyGradeTypeBySurveyId(param).then(
        res => {
          this.loading = false;
          this.getData();
        },
        err => {
          this.loading = false;
          ElMessage.error(err.msg);
        }
      );
    },
  }
}
</script>
<style lang="scss" scoped>
.question_content {
  height: 400px;
  overflow: auto;
}

.group {
  margin-bottom: 20px;
  border: 1px dotted #555;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.project_title {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
}

.project_txt {
  margin-right: 10px;
}

.magintop20 {
  margin-top: 20px;
}
</style>