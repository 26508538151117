<template>
  <div class="Tag_classification_management" v-loading="loading">
    <section class="search_btn">
      <div class="search_items">
        <span class="title">分类搜索：</span>
        <div>
          <el-input
              v-model="this.tagCategoryName"
              class="w-50 m-2"
              size="large"
              placeholder="请输入内容"
          ></el-input>
        </div>
      </div>
      <div class="search_items">
        <el-button type="primary" :icon="Search" @click="this.QueryTagCategoryListByTagCategorySearchVo">查询</el-button>
        <el-button type="primary" @click="edit_tag('add')">添加分类</el-button>
      </div>
    </section>
    <section class="tableList">
      <el-table :data="tableList">
        <el-table-column prop="id" label="分类ID"></el-table-column>
        <el-table-column prop="level" label="分类等级"></el-table-column>
        <el-table-column prop="name" label="分类名称"></el-table-column>
        <el-table-column prop="tagCategory.id" label="上级分类ID"></el-table-column>
        <el-table-column prop="tagCategory.level" label="上级分类等级"></el-table-column>
        <el-table-column prop="tagCategory.name" label="上级分类名称"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="id" label="操作" width="200">
          <template #default="scope">
            <el-tag class="ml-2" @click="edit_tag('edit',scope)">编辑</el-tag>
            <el-tag class="ml-2" type="success" @click="this.Is_delete(scope.row)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </section>

    <section class="pagination">
      <el-pagination :page-size="this.pageSize" :currentPage="this.cunPage" :total="this.totalCount" @current-change="Current_change" pager-count="10" background layout="prev, pager, next"></el-pagination>
    </section>

    <el-dialog v-model="this.tag_dialog" title="编辑" width="40%" draggable>
      <div class="search_items no_float">
        <span class="title"><span class="red">*</span>分类名称：</span>
        <div>
          <el-input
              v-model="this.item_obj.name"
              class="w-50 m-2"
              size="large"
              placeholder="请输入分类名称"
          ></el-input>
        </div>
      </div>
      <div class="search_items no_float">
        <span class="title"><span class="red">*</span>分类等级：</span>
        <div>
          <el-select v-model="this.item_obj.level" placeholder="请输入分类等级" size="large">
            <el-option
                v-for="item in this.level_options"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search_items no_float" v-show="this.item_obj.level>0">
        <span class="title"><span class="red">*</span>上级分类：</span>
        <div>
          <el-select v-model="this.level0" placeholder="请选择上级分类" size="large" @change="get_tag_options_change('level1')">
            <el-option
                v-for="item in this.level0_options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search_items no_float" v-show="this.item_obj.level>1">
        <span class="title"></span>
        <div>
          <el-select v-model="this.level1" placeholder="请选择上级分类" size="large" @change="get_tag_options_change('level2')">
            <el-option
                v-for="item in this.level1_options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
<!--      <div class="search_items no_float">-->
<!--        <span class="title"><span class="red">*</span>零级标签：</span>-->
<!--        <div>-->
<!--          <el-select v-model="this.item_obj.level0" placeholder="请选择零级标签" size="large" @change="get_tag_options_change('level1')">-->
<!--            <el-option-->
<!--                v-for="item in this.level0_options"-->
<!--                :key="item.id"-->
<!--                :label="item.name"-->
<!--                :value="item.id">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </div>-->
<!--      </div>-->
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="this.tag_canel">取消</el-button>
        <el-button type="primary" @click="this.tag_confirm">确认</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {Search,MessageBox} from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import global_api from "@/config/Api";
export default {
  name: 'Tag_classification_management',
  data(){
    return {
      Search:Search,    //图标
      MessageBox:MessageBox,//图标
      cunPage:1,       //页码
      pageSize:10,      //每页数量
      totalCount:0,         //总数
      loading: false,

      tableList:[],
      item_obj:{},
      tag_dialog:false,
      cur_status:'',     //当前状态

      level_options:[{nam:0,value:0},{nam:1,value:1},{nam:2,value:2}],

      level0_options:[],
      level1_options:[],
      level2_options:[],
      level0:'',
      level1:'',
      level2:'',

      tagCategoryName:''
    }
  },
  mounted() {
    this.QueryTagCategoryListByTagCategorySearchVo()
    this.QueryTagCategoryList(0,0)
  },
  methods: {
    get_tag_options_change(string){                     //选择标签 下拉框 的change事件
      if(string=='level1'){
        this.QueryTagCategoryList(1,this.level0)
      }
      if(string=='level2'){
        this.QueryTagCategoryList(2,this.level1)
      }
    },

    QueryTagCategoryList(level,parentId){    //下拉框：获取标签分类列表
      let param = {
        level,
        parentId
      }
      global_api.QueryTagCategoryList(param).then((res)=>{
        if(res.code!=0){
          ElMessage.error('获取标签列表错误')
        }
        if(level==0){
          this.level0_options = res.result
        }
        if(level==1){
          this.level1_options = res.result
        }
        if(level==2){
          this.level2_options = res.result
        }
        console.log(res)
      })
    },

    QueryTagCategoryListByTagCategorySearchVo(){     //获取标签列表
      let param = {
        cunPage:this.cunPage,
        pageSize:this.pageSize,
        tagCategoryName:this.tagCategoryName
      }
      global_api.QueryTagCategoryListByTagCategorySearchVo(param).then((res)=>{
        if(res.code!=0){
          ElMessage.error('获取标签列表错误')
        }
        console.log(res)
        this.totalCount = res.data.totalCount
        this.tableList = res.result
      })
    },

    edit_tag(string,scope){           //新增 修改 tag
      this.cur_status = string
      this.tag_dialog = true
      if(string=='edit'){
        this.item_obj = scope.row
      }else{
        this.item_obj = {
          level: '',
          name: '',
          parentId: 0
        }
      }
    },

    tag_canel(){                      //取消
      this.tag_dialog = false
    },

    tag_confirm(){                    //确定
      if(this.cur_status == 'edit'){
        this.ModifyTagCategoryByTagCategoryId()
      }else{
        this.AddTagCategory()
      }
    },

    AddTagCategory(){                        //添加标签
      console.log(this.item_obj)
      let param = {
        level: this.item_obj.level,
        name: this.item_obj.name,
        parentId:0
      }
      if(this.item_obj.level==2 && this.level1){
        param.parentId = this.level1
      }
      if(this.item_obj.level==1 && this.level0){
        param.parentId = this.level0
      }
      global_api.AddTagCategory(param).then((res)=>{
        if(res.code!=0){
          ElMessage.error('添加标签分类错误')
        }
        this.tag_dialog = false
        ElMessage({
          type: 'success',
          message: '添加成功',
        })
        this.item_obj = {
          level: '',
          name: '',
          parentId: 0
        }
        this.QueryTagCategoryListByTagCategorySearchVo()
      })
    },

    ModifyTagCategoryByTagCategoryId(){              //根据标签id修改标签
      let param = {
        level: this.item_obj.level,
        name: this.item_obj.name,
        parentId:this.item_obj.parentId,
        tagCategoryId:this.item_obj.id
      }
      if(this.item_obj.level==2 && this.level1){
        param.parentId = this.level1
      }
      if(this.item_obj.level==1 && this.level0){
        param.parentId = this.level0
      }
      if(this.item_obj.level==0){
        param.parentId = 0
      }
      global_api.ModifyTagCategoryByTagCategoryId(param).then((res)=>{
        if(res.code!=0){
          ElMessage.error('根据标签分类id修改标签分类错误')
        }
        this.tag_dialog = false
        ElMessage({
          type: 'success',
          message: '修改成功',
        })
        this.item_obj = {
          level: '',
          name: '',
          parentId: 0
        }
        this.QueryTagCategoryListByTagCategorySearchVo()
        // console.log(res)
      })
    },


    Is_delete(row){                          //通过标签id删除标签
      ElMessageBox.confirm(
          '是否删除?',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        let param = {
          tagCategoryId:row.id
        }
        global_api.RemoveTagCategoryByTagCategoryId(param).then((res)=>{
          if(res.code!=0){
            ElMessage.error('通过标签分类id删除标签分类')
          }
          ElMessage({
            type: 'success',
            message: '删除成功',
          })
          this.QueryTagCategoryListByTagCategorySearchVo()
          // console.log(res)
        })
      })
    },


    Current_change(number){
      this.cunPage = number
      this.QueryTagCategoryListByTagCategorySearchVo()
    },

  },
}
</script>
<style scoped lang="scss">
.Tag_classification_management{
  margin-top: 20px;
}
.search_btn{
  //border-radius: 5px;
}
.tableList{
  margin-top: 20px;
  min-width: 800px;
  .btn{
    margin-top: 5px !important;
  }
}
.pagination{
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.no_float{
  float: none !important;
}
.title{
  width: 80px;
}
</style>
