<template>
  <div v-loading.fullscreen.lock="loading">
    <section class="searchForm">
      <el-form>
        <el-form-item>
          <el-button type="primary" @click="download">导出新年愿望</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="list">
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="用户id" prop="userId"></el-table-column>
        <el-table-column label="用户昵称" prop="user.nickname"></el-table-column>
        <el-table-column label="愿望" prop="content"></el-table-column>
        <el-table-column label="奖励" prop="coins"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="cunPage"
        :page-size="20"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="getList"
      />
    </section>
  </div>
</template>
<script>
import {queryNewYearResolutionListBySearchVo} from '@/config/jsonapi'
import { ElMessage } from 'element-plus';
import exportExcel from '@/utils/excel';
export default {
  data(){
    return {
      list: [],
      loading: false,
      cunPage: 1,
      total: 1,
      data: [],
    }
  },
  mounted(){
    this.getList();
  },
  methods:{
    // 获取列表
    getList(){
      this.loading = true;
      let param = {
        cunPage: this.cunPage,
        pageSize: 20
      }
      queryNewYearResolutionListBySearchVo(param).then(res=>{
        this.loading = false;
        this.list = res.result;
        this.total = res.data.totalCount;
      },err=>{
        this.loading = false;
        ElMessage.error(err.msg)
      })
    },
    // 导出新年愿望
    download(){
      this.data = [];
      this.recursionGetData(1)
    },
    // 递归获取数据
    recursionGetData(page){
      this.loading = true;
      let param = {
        cunPage: page,
        pageSize: 200
      }
      queryNewYearResolutionListBySearchVo(param).then(res=>{
        this.data.push(...res.result)
        if(res.data.cunPage<res.data.totalPage){
          this.recursionGetData(++page)
        }else{
          this.loading = false;
          let data = this.data;
          let list = [];
          for(let i in data){
            list.push({
              用户id: data[i].userId,
              用户昵称: data[i].user.nickname,
              愿望: data[i].content,
              奖励心愿币: data[i].coins
            })
          }
          console.log(list);
          exportExcel.exportExcel(list,'新年愿望');
        }
      },err=>{
        this.loading = false;
        ElMessage.error('导出失败')
      })
    }
  }
}
</script>