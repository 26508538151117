export function Get_url(){
   // console.log(location.href.includes('admin-dev.xinhekeji.net'))
    if(location.href.includes('admin-beta.xinhekeji.net')){             // 测试服
        return 'https://admin-beta.xinhekeji.net/api/'
    }else if(location.href.includes('admin.xinhekeji.net')){            // 正式服
        return 'https://admin.xinhekeji.net/api/'
    }else if(location.href.includes('admin-alpha.xinhekeji.net')){      // alpha服
        return 'https://admin-alpha.xinhekeji.net/api/'
    }else{                                                              // 本地
        return '/api/'
    }
    // if(process.env.NODE_ENV=='development'){
    //     return 'https://dev.xinhekeji.net:9005/'
    // }else if(process.env.NODE_ENV=='development'){
    //     return 'https://dev.xinhekeji.net:9005/'
    // }else{
    //     return 'https://xuyuan.xinhekeji.net/'
    // }
}
