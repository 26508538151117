<template>
  <div class="coinRule" v-loading="loading">
    <section>
      <el-form :inline="true">
        <el-form-item class="search_items">
          <el-button type="danger" :icon="Plus" @click="openAdd">添加</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="tableData">
        <el-table-column fixed prop="id" label="ID" width="80"/>
        <el-table-column prop="name" label="规则名" width="180"/>
        <el-table-column prop="rewardNumber" label="奖励数量" width="80"/>
        <el-table-column prop="reawrdMonthMax" label="每月/每日上限" width="120"/>
        <el-table-column prop="rewardType" label="收支" width="120">
          <template #default="scope">
            {{scope.row.rewardType?'支出':'收入'}}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="奖励类型" width="120">
          <template #default="scope">
            {{scope.row.type?'数量奖励':'即时奖励'}}
          </template>
        </el-table-column>
        <el-table-column prop="rule" label="规则说明" width="200"/>
        <el-table-column prop="description" label="功能说明" width="200"/>
        <el-table-column prop="createTime" label="创建时间" width="170"/>
        <el-table-column prop="isActive" label="状态" width="120">
          <template #default="scope">
            {{scope.row.isActive?'上架':'下架'}}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-tag class="ml-2" type="danger" v-loading.fullscreen.lock="fullscreenLoading" @click="Is_reveal(scope.row)">{{scope.row.isActive?'下架':'上架'}}</el-tag>
            <el-tag class="ml-2" @click="openModify(scope.row)">编辑</el-tag>
            <el-tag class="ml-2" type="success" @click="Is_delete(scope.row)">{{scope.row.isDeleted?'恢复':'删除'}}</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="pageCurrent"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </section>

    <!-- 添加编辑弹窗 -->
    <el-dialog v-model="dialogVisible" :title="isAdd?'添加':'编辑'" width="500px" draggable>
      <el-form :label-width="100" v-model="formData">
        <el-form-item label="规则名">
          <el-input type="text" v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="奖励数量">
          <el-input-number v-model="formData.rewardNumber"></el-input-number>
        </el-form-item>
        <el-form-item label="每月/每日上限">
          <el-input-number v-model="formData.reawrdMonthMax"></el-input-number>
        </el-form-item>
        <el-form-item label="收支">
          <el-select v-model="formData.rewardType">
            <el-option label="收入" :value="0"/>
            <el-option label="支出" :value="1"/>
          </el-select>
        </el-form-item>
        <el-form-item label="奖励类型">
          <el-select v-model="formData.type">
            <el-option label="即使奖励" :value="0"/>
            <el-option label="数量奖励" :value="1"/>
          </el-select>
        </el-form-item>
        <el-form-item label="规则说明">
          <el-input type="textarea" :rows="3" v-model="formData.rule"></el-input>
        </el-form-item>
        <el-form-item label="功能说明">
          <el-input type="textarea" :rows="3" v-model="formData.description"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible=false">取消</el-button>
          <el-button v-if="isAdd" type="primary" @click="AddTask">确定</el-button>
          <el-button v-else type="primary" @click="modifyTask">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { Plus } from '@element-plus/icons-vue'
import {ElMessage, ElMessageBox} from 'element-plus'
import {QueryTaskList, ModifyTaskActiveByTaskId, ModifyTaskByTaskId,AddTaskByTaskId} from '../../config/Api';

export default {
  data() {
    return {
      Plus,
      // 当前页码
      pageCurrent: 1,
      // 页面数据量
      pageSize: 15,
      // 总数据条数
      total: 1,
      // 表格数据
      tableData: [],
      loading: false,
      fullscreenLoading: false,
      // 添加编辑弹窗
      dialogVisible: false,
      formData: {
        name:'',
        rewardNumber: 0,
        reawrdMonthMax: 0,
        rewardType: null,
        type: null,
        rule: '',
        description: '',
      },
      isAdd: false,
    }
  },
  mounted() {
    let query = this.$route.query;
    this.pageCurrent = Number(query.page || 1);
    this.pageSize = Number(query.size || 15);
    this.getCommentList();
  },
  methods: {
    // 获取数据
    getCommentList() {
      let param = {
        cunPage: this.pageCurrent,
        pageSize: this.pageSize
      };
      this.loading = true;
      QueryTaskList(param).then(res => {
        this.loading = false;
        this.tableData = res.result;
        this.total = res.data.totalCount;
      },err => {
        this.loading = false;
        ElMessage.error(`获取任务列表失败：${err.msg}`)
      })
    },
    // 页码改变触发
    handleCurrentChange() {
      this.$router.push({
        path: "coin_rule",
        query: { page: this.pageCurrent, size: this.pageSize },
      });
      this.getCommentList();
    },
    // 上下架
    Is_reveal(row){
      ElMessageBox.confirm(
        row.isActive?'是否下架？':'是否上架？',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        this.fullscreenLoading = true;
        let param = {
          taskId: row.id
        }
        ModifyTaskActiveByTaskId(param).then(res => {
          this.fullscreenLoading = false;
          this.getCommentList();
        },err => {
          this.fullscreenLoading = false;
          ElMessage.error(`操作失败：${err.msg}`)
        })
      })
    },
    // 是否删除
    Is_delete(row) {
      ElMessageBox.confirm(
        row.isDeleted?'是否恢复?':'是否删除?',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        this.fullscreenLoading = true;
        let param = {
          taskId: row.id,
          isDeleted: row.isDeleted?0:1,
        };
        ModifyTaskByTaskId(param).then(res => {
          this.fullscreenLoading = false;
          this.getCommentList();
          ElMessage({
            type: 'success',
            message: row.isDeleted?'已恢复':'已删除',
          })
        },err => {
          this.fullscreenLoading = false;
          ElMessage.error(`操作失败：${err.msg}`)
        })
      })
    },
    // 添加任务
    openAdd(){
      // ElMessage.success('开发中')
      this.formData = {};
      this.dialogVisible = true;
      this.isAdd = true;
    },
    // 打开修改弹窗
    openModify(row){
      this.formData = {...row};
      this.dialogVisible = true;
      this.isAdd = false;
    },
    // 修改任务
    modifyTask(){
      this.fullscreenLoading = true;
      let param = {
        taskId: this.formData.id,
        name: this.formData.name,
        rewardNumber: this.formData.rewardNumber,
        reawrdMonthMax: this.formData.reawrdMonthMax,
        rewardType: this.formData.rewardType,
        type: this.formData.type,
        rule: this.formData.rule,
        description: this.formData.description,
      };
      this.fullscreenLoading = true;
      ModifyTaskByTaskId(param).then(res => {
        this.fullscreenLoading = false;
        this.dialogVisible = false;
        this.getCommentList();
      }, err => {
        this.fullscreenLoading = false;
        ElMessage.error(`修改失败：${err.msg}`)
      })
    },
    // 添加任务
    AddTask(){
      this.fullscreenLoading = true;
      let param = {
        name: this.formData.name,
        rewardNumber: this.formData.rewardNumber,
        reawrdMonthMax: this.formData.reawrdMonthMax,
        rewardType: this.formData.rewardType,
        type: this.formData.type,
        rule: this.formData.rule,
        description: this.formData.description,
      };
      this.fullscreenLoading = true;
      AddTaskByTaskId(param).then(res => {
        this.fullscreenLoading = false;
        this.dialogVisible = false;
        this.getCommentList();
      }, err => {
        this.fullscreenLoading = false;
        ElMessage.error(`添加失败：${err.msg}`)
      })
    }
  },
}
</script>
<style scoped lang="scss">
  .coinRule{
    margin-top: 20px;
  }
</style>