<template>
  <div class="topicComment" v-loading.fullscreen.lock="fullscreenLoading">
    <section class="searchForm">
      <el-button type="primary" @click="gotoBack">返回</el-button>
      <el-button type="primary" @click="openComment(null)">评论</el-button>
    </section>
    <section class="tableList">
      <el-table :data="tableData" row-key="id">
        <el-table-column label="用户ID" prop="userId" />
        <el-table-column label="用户昵称" prop="userName" />
        <el-table-column label="用户头像" prop="userImage">
          <template #default="scope">
            <div class="imgBox"><img :src="scope.row.userImage" alt=""></div>
          </template>
        </el-table-column>
        <el-table-column label="评论内容" prop="content" />
        <el-table-column label="评论时间" prop="createTime" />
        <el-table-column label="操作">
          <template #default="scope">
            <el-button v-if="scope.row.children" type="primary" size="small" @click="openComment(scope.row)">回复</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="pageCurrent"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </section>

    <el-dialog v-model="commentDialog" title="回复" width="500px">
      <el-form>
        <el-form-item label="选择身份">
          <el-select v-model="userType" @change="getUserList">
            <el-option label="官方用户" :value="2"></el-option>
            <el-option label="品牌方" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择用户">
          <el-select v-model="userId">
            <el-option v-for="item in userList" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="回复内容">
          <el-input v-model="content" :rows="5" type="textarea" placeholder="Please input"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="commentDialog = false">取消</el-button>
        <el-button type="primary" @click="userCommentDynamic">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ElMessage } from 'element-plus';
import {queryDynamicCommentListByDynamicId,userCommentDynamic,queryUserListByiIdentityType} from '../../config/Api';
export default {
  data(){
    return {
      pageCurrent: 1,
      pageSize: 10,
      total: 1,
      fullscreenLoading: false,
      tableData: [],
      commentDialog: false,
      userType: '',
      userId: '',
      userList: [],
      commentId: '',
      content: ''
    }
  },
  mounted(){
    let query = this.$route.query;
    this.dynamicId = query.id;
    this.backUrl = query.b;
    this.pageCurrent = Number(query.page || 1);
    this.pageSize = Number(query.size || 10);
    this.getList();
  },
  methods:{
    // 获取列表
    getList(){
      let param = {
        cunPage: this.pageCurrent,
        pageSize: this.pageSize,
        dynamicId: this.dynamicId,
      }
      this.fullscreenLoading = true;
      queryDynamicCommentListByDynamicId(param).then(res=>{
        this.fullscreenLoading = false;
        let list = [];
        if(res.result?.length){
          for(let i in res.result){
            list.push({
              ...res.result[i].userMomentCommentDTO,
              children: res.result[i].userMomentCommentItemList
            })
          }
        }
        this.tableData = list;
        this.total = res.data.totalCount
      },err=>{
        this.fullscreenLoading = false;
        ElMessage.error('获取评论失败');
        console.log(err)
      })
    },
    //
    handleCurrentChange(){
      this.$router.push({
        path: '/topic_list/comment',
        query: {page: this.pageCurrent, size: this.pageSize}
      })
      this.getList();
    },
    // 返回
    gotoBack(){
      if(this.backUrl){
        this.$router.push({
          path: '/topic_list',
          query:{page:this.backUrl.split('_')[0],size:this.backUrl.split('_')[1]}
        })
      }else{
        this.$router.push({
          path: '/topic_list'
        })
      }
    },
    // 打开回复评论
    openComment(row){
      console.log(row)
      this.userType = '';
      this.userList = [];
      this.commentId = row?row.id:0;
      this.commentDialog = true;
      this.userId = '';
      this.content = '';
    },
    // 获取用户列表
    getUserList(){
      this.fullscreenLoading = true;
      this.userId = '';
      let param = {
        identityType: this.userType,
      }
      queryUserListByiIdentityType(param).then(res=>{
        this.fullscreenLoading = false;
        this.userList = res.result;
      },err=>{
        this.fullscreenLoading = false;
        ElMessage.error('获取用户失败');
        this.userList = [];
      })
    },
    // 评论回复
    userCommentDynamic(){
      let param = {
        dynamicId: this.dynamicId,
        commentId: this.commentId,
        content: this.content,
        userId: this.userId,
        type: this.commentId?1:0,
        imgUrls: '',
        parentId: this.commentId
      }
      this.fullscreenLoading = true;
      userCommentDynamic(param).then(res=>{
        this.fullscreenLoading = false;
        this.commentDialog = false;
        this.getList();
      },err=>{
        this.fullscreenLoading = false;
        ElMessage.error('回复评论失败')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.imgBox img{
  width: 50px;
  height: 50px;
}
.subcomment{
  padding: 10px;
  text-align: center;
}
</style>