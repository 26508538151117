<template>
  <div id="setappImage">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card>
          <img class="cardImg" src="/images/autor.jpeg" alt="">
          <div style="margin: 20px 0;text-align: right">
            <el-upload 
              :action="Get_url+'admin/file/uploadFile'"
              :limit="1"
              :show-file-list="false"
              on-success=""
              :headers="headers"
            >
              <el-button type="primary">修改图片</el-button>
            </el-upload>
          </div>
          <el-tag>地址：</el-tag>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card>
          <img class="cardImg" src="/images/autor.jpeg" alt="">
          <div style="margin: 20px 0;text-align: right">
            <el-button type="primary">修改图片</el-button>
          </div>
          <el-tag>地址：</el-tag>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {Get_url} from "../../config/Get_url";
export default {
  data() {
    return {
      Get_url:Get_url(),
      headers:{
        id:localStorage.getItem('id'),
        token: localStorage.getItem('token')
      },
    }
  },
  mounted() {

  },
  methods: {
    // 修改图片
    
  }
}
</script>
<style lang="scss" scoped>
#setappImage {
  margin-top: 40px;
  .cardImg {
    width: 80%;
    margin:  0 10%;
  }
}
</style>