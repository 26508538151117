<template>
  <div class="app_contain">
    <section>
      <Side_bar></Side_bar>
    </section>
    <section>
      <header class="contain_title">
        <el-breadcrumb :separator-icon="ArrowRight">
          <el-breadcrumb-item :to="{ path: item.path }" v-for="(item) in route" :key="item.name">
            {{item.name}}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </header>
      <div class="main">
        <router-view/>
      </div>
    </section>
  </div>
</template>
<script>
import Side_bar from './components/Side_bar/Side_bar.vue'
import { ArrowRight } from '@element-plus/icons-vue'
import { ElConfigProvider } from 'element-plus';
import {formatDate} from './utils/date';
import lang from 'element-plus/dist/locale/zh-cn.mjs';
const packageInfo = require('../package.json');
export default {
  data(){
    return{
      ArrowRight:ArrowRight,
      route:[]
    }
  },
  components:{Side_bar,ArrowRight,[ElConfigProvider.name]: ElConfigProvider},
  setup() {
    return {
      locale: lang,
    };
  },
  mounted() {
    window.set_title = ()=>{
      setTimeout(()=>{
        // console.log(this.$route);
        this.route = this.$route.meta.barArr
      })
    };
    (function(info){
      const projectNameStyle = 'font-size: 20px;font-weight: 600;color: rgb(244,167,89);';
      const versionStyle = 'color: rgb(30,152,255);padding:8px 0 2px;';
      const dateTimeStyle = 'color: rgb(30,152,255);padding:0 0 5px;';
 
      /* 内容代码 */
      let projectName = '上海心盒科技';
      const version = `版 本 号：${info.version}`;
      const dateTime = `编译日期：${formatDate(new Date,'yyyy-MM-dd hh:mm:ss')}`;
 
      console.log(`%c${projectName}
%c${version}
%c${dateTime}`, projectNameStyle, versionStyle, dateTimeStyle);
    })(packageInfo)
  },
}
</script>

<style lang="scss">
html,body{
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
body{
  padding: 0;
  margin: 0;
}
.app_contain{
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  &>section:nth-child(1){
    width: 200px;
    position: fixed;
    left: 0;
    top:0;
    height: 100%;
    overflow-y: auto;
    background: #545c64;
    flex: 0 0 100%;
    padding-right: 1px;
    z-index: 999;
  }
  &>section:nth-child(2){
    width: 100%;
    //flex:1;
    padding-left: 200px;
    box-sizing: border-box;
    &>.contain_title{
      background: #f5f5f5;
      width: 100%;
      text-align: left;
      border-bottom: 1px solid #909399;
      padding: 0 24px;
      height: 50px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      position: fixed;
      z-index: 999;
      //min-width: 800px;

    }
    &>.main{
      padding: 50px 24px 20px;
      //position: fixed;
      box-sizing: border-box;
      text-align: left;
      width:100%;
      min-width: 800px;
      box-sizing: border-box;
      height: 100%;

    }
  }
}

//登录专属 勿删！！！！！
.login_info{
  .el-input__inner,.el-input-number{
    width: 100% !important;
    height: 50px !important;
  }
  .el-button--danger{
    width: 100% !important;
    height: 50px !important;
  }
}
.H5_page_setting{
  .opcaity0{
    opacity: 0;
  }
  .opcaity1{
    opacity: 1;
  }
}

.search{
  background: #f5f5f5;
  padding: 20px 20px 0;
  box-sizing: border-box;
  &:after{
    display: block;
    content: '.';
    visibility: hidden;
    clear: both;
    height: 0;
  }
}
.search_items{
  display: flex;
  align-items: center;
  margin-right: 20px;
  float: left;
  height: 40px !important;
  margin-bottom: 20px;
  &>.title{
    font-size: 14px;
  }
  &>.main{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &>.area{
      padding: 0 10px;
    }
  }
  &>.el-button{
    height: 100%;
  }
  .el-input__inner,.el-input-number,.el-button{
    height: 40px !important;
  }
}
// .el-form-item{
//   //height: 40px !important;
  
// }
.el-select {
  width: 100% !important;
}
.el-popper .el-input__inner{
  width: 100% !important;
   //height: 40px !important;
}
.width500 .el-input__inner{
  width: 100% !important;
}

.el-form-item__content {
  min-width: 160px;
}

.selected_items{
  display: flex;
  align-items: center;
  min-height: 40px;
  margin-bottom: 20px;
  &>.title{
    font-size: 14px;
    width: 120px;
    text-align: right;
    margin-right: 20px;
  }
}
.start_items{
  display: flex;
  align-items: start;
  //height: 220px;
  margin-bottom: 20px;
  &>.title{
    font-size: 14px;
    width: 120px;
    text-align: right;
    margin-right: 20px;
  }
  .el-textarea{
    height: 220px !important;
  }
  .textarea{
    height: 220px !important;
  }
}
//.search_items:after{
//  display: block;
//  height: 0;
//  clear: both;
//  visibility: hidden;
//  content: '.';
//}

.ml-2{
  margin-left: 5px;
  cursor: pointer;
  &:nth-child(1){
    margin-left: 0;
  }
}
.red{
  color: red;
}
.search_btn:after{
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  content: '.';
}
.cursor{
  cursor: pointer;
}
.isHide{
  display: none !important;
}

//nav {
//  padding: 30px;
//
//  a {
//    font-weight: bold;
//    color: #2c3e50;
//
//    &.router-link-exact-active {
//      color: #42b983;
//    }
//  }
//}

// 内容区结构 公用样式 不能删
.searchForm{
  margin-top: 20px;
}
// 表格区
.tableList {
  margin: 20px 0;
}


// 分页
.pagination{
  display: flex;
  justify-content: center;
}
// 内容区结构 公用样式 结束

.cs{
  margin-top: 10px;
  width: 450px;
  display: flex;
  justify-items: start;
  .el-input__inner,.el-input-number,.el-textarea{
    width: 200px !important;
  }
}

.el-form--inline {
  .el-form-item {
    & > .el-input, .el-cascader, .el-select, .el-date-editor, .el-autocomplete {
      width: 200px !important;
    }
  }
}
</style>
