<template>
  <div class="WX_code" v-loading="loading">
    <p>总次数{{tokenInfo.daily_limit}}&nbsp;已用次数：{{tokenInfo.used}}&nbsp;可用次数：{{tokenInfo.remain}}
      <el-button type="primary" v-if="tokenNum>10" @click="resetAccessToken">重置</el-button>
    </p>
    <el-tabs v-model="activeName">
      <el-tab-pane label="调研问卷" name="first">
        <section class="search_btn">
          <el-form>
            <el-form-item label="调研问卷id">
              <el-input v-model="id1" placeholder="调研问卷id">
                <template #prepend>pages/secQuestion/secQuestion?option=qr_1-</template>
              </el-input>
            </el-form-item>
            <el-form-item label="类型">
              <el-radio-group v-model="originType">
                <el-radio :label="1">用户邀请</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="邀请人">
              <el-input v-model="origin"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getSurveyCode">生成二维码</el-button>
            </el-form-item>
          </el-form>
        </section>
        <section style="display: flex;justify-content: space-between;width: 1000px">
          <div>
            <img class="img_code" :src="img1Url || './images/bannerDefualt.png'">
            <div class="sub">生成的小程序码不在后台保存，请及时下载好好保存！</div>
          </div>
          <div style="display: flex;flex-direction: column">
            <img style="width: 500px" src="/images/code1.png" alt="">
            <img style="width: 500px" src="/images/code2.png" alt="">
            <img style="width: 500px" src="/images/code3.png" alt="">
            <img style="width: 500px" src="/images/code4.png" alt="">
          </div>
        </section>
      </el-tab-pane>
      <el-tab-pane label="心愿盒列表" name="second">
        <section class="search_btn">
          <el-form inline>
            <el-form-item class="search_items">
              <el-button type="primary" @click="getWishBoxListCode">生成二维码</el-button>
            </el-form-item>
          </el-form>
        </section>
        <section style="display: flex;justify-content: space-between;width: 1000px">
          <div>
            <img class="img_code" :src="img2Url || './images/bannerDefualt.png'">
            <div class="sub">生成的小程序码不在后台保存，请及时下载好好保存！</div>
          </div>
        </section>
      </el-tab-pane>
      <el-tab-pane label="首页">
        <section class="search_btn">
          <el-form inline>
            <el-form-item label="邀请人" class="search_items" style="margin-right: 0">
              <el-input v-model="inviteId" placeholder="用户id">
                <template #prepend>pages/home/home?inviteId=</template>
              </el-input>
            </el-form-item>
            <el-form-item class="search_items">
              <el-button type="primary" @click="getWishHomeCode">生成二维码</el-button>
            </el-form-item>
          </el-form>
        </section>
        <section style="display: flex;justify-content: space-between;width: 1000px">
          <div>
            <img class="img_code" :src="img3Url || './images/bannerDefualt.png'">
            <div class="sub">生成的小程序码不在后台保存，请及时下载好好保存！</div>
          </div>
        </section>
      </el-tab-pane>
      <el-tab-pane label="签到">
        <section class="search_btn">
          <el-form inline>
            <el-form-item label="" class="search_items" style="margin-right: 0">
              <el-input v-model="signName" placeholder="">
                <template #prepend>pages/signIn/signIn?name=</template>
              </el-input>
            </el-form-item>
            <el-form-item class="search_items">
              <el-button type="primary" @click="getSignCode">生成二维码</el-button>
            </el-form-item>
          </el-form>
        </section>
        <section style="display: flex;justify-content: space-between;width: 1000px">
          <div>
            <img class="img_code" :src="img4Url || './images/bannerDefualt.png'">
            <div class="sub">生成的小程序码不在后台保存，请及时下载好好保存！</div>
          </div>
        </section>
      </el-tab-pane>
    </el-tabs>
    
  </div>
</template>
<script>
import {queryQuotaByPath, QueySpecialEventQrCodeByPath, resetAccessToken} from "../../config/Api";
import {ElMessage, ElMessageBox} from "element-plus";
export default {
  data() {
    return {
      id1: '',
      originType: 1,
      origin: 0,
      inviteId: '',
      signName: '',
      img1Url: '',
      img2Url:'',
      img3Url: '',
      img4Url: '',
      loading: false,
      tokenInfo: {},
      tokenNum: 0,
      activeName: 'first'
    }
  },
  mounted() {
    this.queryQuotaByPath();
    this.tokenNum = localStorage.getItem('tokenNum') || 0;
  },
  methods: {
    // 获取调研问卷二维码
    getSurveyCode(){
      if(!this.id1){
        ElMessage.error('请输入调研id');
        return ;
      }
      this.inviteId = this.inviteId?this.inviteId:0;
      let param = {
        path: `pages/secQuestion/secQuestion?option=qr_1-${this.id1}&inviteId=${this.inviteId}&originType=${this.originType}&origin=${this.origin}`
      };
      this.loading = true;
      QueySpecialEventQrCodeByPath(param).then(res => {
        this.tokenNum += 1;
        localStorage.setItem("tokenNum",this.tokenNum)
        this.loading = false;
        this.img1Url = res.result;
      },err => {
        this.loading = false;
        ElMessage.error(`获取二维码失败：${err.msg}`);
      })
    },
    // 获取调研问卷二维码
    getWishBoxListCode(){
      let param = {
        path: 'pages/wishRecord/wishRecord'
      };
      this.loading = true;
      QueySpecialEventQrCodeByPath(param).then(res => {
        this.tokenNum += 1;
        localStorage.setItem("tokenNum",this.tokenNum)
        this.loading = false;
        this.img2Url = res.result;
      },err => {
        this.loading = false;
        ElMessage.error(`获取二维码失败：${err.msg}`);
      })
    },
    // 获取首页二维码
    getWishHomeCode(){
      let path = '';
      if(this.inviteId){
        path = `pages/home/home?inviteId=${this.inviteId}`;
      }else{
        path = 'pages/home/home';
      }
      let param = {
        path: path
      };
      this.loading = true;
      QueySpecialEventQrCodeByPath(param).then(res => {
        this.tokenNum += 1;
        localStorage.setItem("tokenNum",this.tokenNum)
        this.loading = false;
        this.img3Url = res.result;
      },err => {
        this.loading = false;
        ElMessage.error(`获取二维码失败：${err.msg}`);
      })
    },
    getSignCode(){
      let path = '';
      if(this.signName){
        path = `pages/signIn/signIn?name=${this.signName}`;
      }else{
        return ;
      }
      let param = {
        path: path
      };
      this.loading = true;
      QueySpecialEventQrCodeByPath(param).then(res => {
        this.tokenNum += 1;
        localStorage.setItem("tokenNum",this.tokenNum)
        this.loading = false;
        this.img4Url = res.result;
      },err => {
        this.loading = false;
        ElMessage.error(`获取二维码失败：${err.msg}`);
      })
    },


    // 获取token次数
    queryQuotaByPath(){
      queryQuotaByPath({path:'/cgi-bin/token'}).then(res => {
        this.tokenInfo = JSON.parse(res.result)
      },err => {
        this.queryQuotaByPath();
      })
    },
    // 重置AccessToken
    resetAccessToken(){
      resetAccessToken().then(res=>{
        this.tokenNum = 0;
        localStorage.setItem('tokenNum',this.tokenNum)
      })
    }

  },
}
</script>
<style scoped lang="scss">
.WX_code{
  margin-top: 20px;
  .img_code{
    width: 400px;
  }
  .sub{
    font-size: 14px;
    color: red;
    margin-top: 20px;
  }
}

</style>