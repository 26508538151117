<!-- @format -->

<template>
	<div class="jump" v-loading.fullscreen.lock="loading">
		<div class="header">
			<div class="title">{{ question.sort }}:({{ typeName }}){{ question.name }}</div>
			<el-button type="primary" @click="addSkip">新增</el-button>
		</div>
		<div class="content">
			<div v-for="(item, index) in skip" :key="index" class="item">
				<el-card>
					<!-- 组 -->
					<el-form inline>
						<el-form-item label="逻辑关系">
							<el-select v-model="item.logic">
								<el-option label="全部满足" :value="0"></el-option>
								<el-option label="满足任意一条" :value="1"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button v-if="item.id" type="danger" @click="delSkipItem(item)">删除</el-button>
							<el-button v-else type="danger" @click="delSkip(index)">删除</el-button>
							<el-button type="primary" @click="addRule(index)">新增条件</el-button>
							<el-button type="success" @click="addResult(index)">新增结果</el-button>
						</el-form-item>
					</el-form>
					<el-divider style="margin-top: 0"></el-divider>
					<!-- 条件 -->
					<template v-for="(rule, ruleIndex) in item.rules" :key="ruleIndex">
						<!-- 单选题、多选题、图片题、种草题、购买意愿题 -->
						<el-form inline v-if="type === 1 || type === 2 || type === 10 || type === 12 || type === 13" class="form">
							<el-form-item>条件{{ ruleIndex + 1 }}:</el-form-item>
							<!-- 类型 -->
							<el-form-item>
								<el-select v-model="rule.condition">
									<el-option label="选择" :value="1"></el-option>
									<el-option label="未选泽" :value="2"></el-option>
									<el-option label="与人群显示冲突" :value="8"></el-option>
								</el-select>
							</el-form-item>
							<!-- 选项 -->
							<el-form-item v-if="rule.condition != 8">
								<el-select v-model="rule.optionId">
									<el-option
										v-for="(option, optionIndex) in optionList"
										:key="optionIndex"
										:label="option.name"
										:value="option.id"></el-option>
								</el-select>
							</el-form-item>
							<!-- 删除 -->
							<el-form-item>
								<el-button class="btn" type="danger" @click="delRule(index, ruleIndex)">删除</el-button>
							</el-form-item>
						</el-form>
						<!-- 简答题 -->
						<el-form inline v-if="type === 3" class="form">
							<el-form-item>条件{{ ruleIndex + 1 }}:</el-form-item>
							<!-- 选项 -->
							<el-form-item v-if="rule.condition != 8">
								<el-select v-model="rule.optionId">
									<el-option
										v-for="(option, optionIndex) in optionList"
										:key="optionIndex"
										:label="option.name"
										:value="option.id"></el-option>
								</el-select>
							</el-form-item>
							<!-- 类型 -->
							<el-form-item>
								<el-select v-model="rule.condition" @change="rule.value = ''">
									<el-option label="大于" :value="3"></el-option>
									<el-option label="小于" :value="4"></el-option>
									<el-option label="等于" :value="5"></el-option>
									<el-option label="包含" :value="6"></el-option>
									<el-option label="不包含" :value="7"></el-option>
									<el-option label="与人群显示冲突" :value="8"></el-option>
								</el-select>
							</el-form-item>
							<!-- 文本 -->
							<el-form-item v-if="(rule.condition === 6 || rule.condition === 7) && rule.condition != 8">
								<el-input style="width: 200px" v-model="rule.value" />
							</el-form-item>
							<!-- 数量 -->
							<el-form-item v-if="(rule.condition === 3 || rule.condition === 4 || rule.condition === 5) && rule.condition != 8">
								<el-input-number style="width: 200px" v-model="rule.value" :min="0" />
							</el-form-item>
							<!-- 删除 -->
							<el-form-item>
								<el-button class="btn" type="danger" @click="delRule(index, ruleIndex)">删除</el-button>
							</el-form-item>
						</el-form>
						<!-- 排序题、打分题、比重题、滑条题、nps、矩阵打分题、矩阵滑条题、例举题 -->
						<el-form
							inline
							v-if="
								type === 4 ||
								type === 5 ||
								type === 6 ||
								type === 7 ||
								type === 11 ||
								type === 14 ||
								type === 15 ||
								type === 18
							"
							class="form">
							<el-form-item>条件{{ ruleIndex + 1 }}:</el-form-item>
							<!-- 选项 -->
							<el-form-item v-if="rule.condition != 8">
								<el-select v-model="rule.optionId">
									<el-option
										v-for="(option, optionIndex) in optionList"
										:key="optionIndex"
										:label="option.name"
										:value="option.id"></el-option>
								</el-select>
							</el-form-item>
							<!-- 类型 -->
							<el-form-item>
								<el-select v-model="rule.condition">
									<el-option label="大于" :value="3"></el-option>
									<el-option label="小于" :value="4"></el-option>
									<el-option label="等于" :value="5"></el-option>
									<el-option label="与人群显示冲突" :value="8"></el-option>
								</el-select>
							</el-form-item>
							<!-- 数值 -->
							<el-form-item v-if="rule.condition != 8">
								<el-input-number style="width: 200px" v-model="rule.value" :min="1" />
							</el-form-item>
							<!-- 删除 -->
							<el-form-item>
								<el-button class="btn" type="danger" @click="delRule(index, ruleIndex)">删除</el-button>
							</el-form-item>
						</el-form>
						<!-- 矩阵单选题、矩阵多选题 -->
						<el-form inline v-if="type === 8 || type === 9 || type === 22" class="form">
							<el-form-item>条件{{ ruleIndex + 1 }}:</el-form-item>
							<!-- 选项 -->
							<el-form-item v-if="rule.condition != 8">
								<el-select v-model="rule.optionId">
									<el-option
										v-for="(option, optionIndex) in optionList"
										:disabled="option.optionPosition === 'up'"
										:key="optionIndex"
										:label="option.name"
										:value="option.id"></el-option>
								</el-select>
							</el-form-item>
							<!-- 类型 -->
							<el-form-item>
								<el-select v-model="rule.condition">
									<el-option label="选择" :value="1"></el-option>
									<el-option label="未选泽" :value="2"></el-option>
									<el-option label="与人群显示冲突" :value="8"></el-option>
								</el-select>
							</el-form-item>
							<!-- 选项2 -->
							<el-form-item v-if="rule.condition != 8">
								<el-select v-model="rule.value">
									<el-option
										v-for="(option, optionIndex) in optionList"
										:disabled="option.optionPosition === 'left'"
										:key="optionIndex"
										:label="option.name"
										:value="String(option.id)"></el-option>
								</el-select>
							</el-form-item>
							<!-- 删除 -->
							<el-form-item>
								<el-button class="btn" type="danger" @click="delRule(index, ruleIndex)">删除</el-button>
							</el-form-item>
						</el-form>
						<!-- 开放题 -->
						<el-form inline v-if="type === 16" class="form">
							<el-form-item>条件{{ ruleIndex + 1 }}:</el-form-item>
							<!-- 选项 -->
							<el-form-item v-if="rule.condition != 8">
								<el-select v-model="rule.optionId2">
									<el-option
										v-for="(option, optionIndex) in optionList"
										:disabled="option.optionPosition === 'up'"
										:key="optionIndex"
										:label="option.name"
										:value="option.id"></el-option>
								</el-select>
							</el-form-item>
							<!-- 选项2 -->
							<el-form-item v-if="rule.condition != 8">
								<el-select v-model="rule.optionId">
									<el-option
										v-for="(option, optionIndex) in optionList"
										:disabled="option.optionPosition === 'left'"
										:key="optionIndex"
										:label="option.name"
										:value="option.id"></el-option>
								</el-select>
							</el-form-item>
							<!-- 类型 -->
							<el-form-item>
								<el-select v-model="rule.condition">
									<el-option label="大于" :value="3"></el-option>
									<el-option label="小于" :value="4"></el-option>
									<el-option label="等于" :value="5"></el-option>
									<el-option label="包含" :value="6"></el-option>
									<el-option label="不包含" :value="7"></el-option>
									<el-option label="与人群显示冲突" :value="8"></el-option>
								</el-select>
							</el-form-item>
							<!-- 文本 -->
							<el-form-item v-if="(rule.condition === 6 || rule.condition === 7) && rule.condition != 8">
								<el-input style="width: 200px" v-model="rule.value" />
							</el-form-item>
							<!-- 数量 -->
							<el-form-item v-if="(rule.condition === 3 || rule.condition === 4 || rule.condition === 5 && rule.condition != 8)">
								<el-input-number style="width: 200px" v-model="rule.value" :min="0" />
							</el-form-item>
							<!-- 删除 -->
							<el-form-item>
								<el-button class="btn" type="danger" @click="delRule(index, ruleIndex)">删除</el-button>
							</el-form-item>
						</el-form>
						<!-- MaxDiff -->
						<el-form inline v-if="type === 19" class="form">
							<el-form-item>条件{{ ruleIndex + 1 }}:</el-form-item>
							<!-- 选项 -->
							<el-form-item v-if="rule.condition != 8">
								<el-select v-model="rule.optionId">
									<el-option
										v-for="(option, optionIndex) in optionList"
										:key="optionIndex"
										:label="option.name"
										:value="option.id"></el-option>
								</el-select>
							</el-form-item>
							<!-- 分类 -->
							<el-form-item v-if="rule.condition != 8">
								<el-select v-model="rule.optionId2">
									<el-option label="分数" value="0"></el-option>
									<el-option label="正面描述" value="1"></el-option>
									<el-option label="负面描述" value="2"></el-option>
								</el-select>
							</el-form-item>
							<!-- 分类 -->
							<el-form-item>
								<el-select v-model="rule.condition">
									<el-option label="大于" :value="3"></el-option>
									<el-option label="小于" :value="4"></el-option>
									<el-option label="等于" :value="5"></el-option>
									<el-option v-if="rule.optionId2!=0" label="排序前" :value="9"></el-option>
									<el-option v-if="rule.optionId2!=0" label="排序后" :value="10"></el-option>
									<el-option v-if="rule.optionId2!=0" label="排序" :value="11"></el-option>
									<el-option label="与人群显示冲突" :value="8"></el-option>
								</el-select>
							</el-form-item>
							<!-- 数量 -->
							<el-form-item v-if="rule.condition != 8">
								<el-input-number style="width: 200px" v-model="rule.value" :step="0.01"/>
							</el-form-item>

						</el-form>
						<!-- heatmap -->
						<el-form inline v-if="type === 20" class="form">
							<el-form-item>条件{{ ruleIndex + 1 }}:</el-form-item>
							<!-- 选项 -->
							<el-form-item v-if="rule.condition != 8">
								<el-select v-model="rule.optionId">
									<el-option
										v-for="(option, optionIndex) in optionList"
										:key="optionIndex"
										:label="option.name"
										:value="option.id"></el-option>
								</el-select>
							</el-form-item>
							<!-- 类型 -->
							<el-form-item>
								<el-select v-model="rule.condition">
									<el-option label="选择" :value="1"></el-option>
									<el-option label="未选泽" :value="2"></el-option>
									<el-option label="大于" :value="3"></el-option>
									<el-option label="小于" :value="4"></el-option>
									<el-option label="等于" :value="5"></el-option>
									<el-option label="与人群显示冲突" :value="8"></el-option>
								</el-select>
							</el-form-item>
							<!-- 数值 -->
							<el-form-item v-if="rule.condition != 8 && rule.condition != 1 && rule.condition != 2 && rule.condition">
								<el-input-number style="width: 200px" v-model="rule.value" :min="1" />
							</el-form-item>
							<!-- 删除 -->
							<el-form-item>
								<el-button class="btn" type="danger" @click="delRule(index, ruleIndex)">删除</el-button>
							</el-form-item>
						</el-form>
					</template>
					<el-divider v-if="item.rules.length" style="margin-top: 0"></el-divider>
					<!-- 結果 -->
					<template v-for="(res, resIndex) in item.result" :key="resIndex">
						<el-form inline class="form">
							<el-form-item>結果{{ resIndex + 1 }}:</el-form-item>
							<el-form-item>
								<el-select
									v-model="res.type"
									@change="
										() => {
											res.value = '';
										}
									">
									<el-option label="跳转" :value="1" :disabled="optionDisabled(item.result, 1)" />
									<el-option label="加" :value="2" :disabled="optionDisabled(item.result, 2)" />
									<el-option label="减" :value="3" :disabled="optionDisabled(item.result, 2)" />
									<el-option label="乘" :value="4" :disabled="optionDisabled(item.result, 2)" />
									<el-option label="除" :value="5" :disabled="optionDisabled(item.result, 2)" />
									<el-option label="显示" :value="6" />
									<el-option label="先下顺延" :value="7" :disabled="optionDisabled(item.result, 3)" />
									<el-option label="转移跳转" :value="8" :disabled="optionDisabled(item.result, 3)" />
									<el-option label="必不显示" :value="9" />
								</el-select>
							</el-form-item>
							<!-- 选择题目 -->
							<el-form-item v-if="[1, 6, 8, 9].indexOf(res.type) != -1">
								<el-select v-model="res.value" style="width: 400px">
									<el-option v-for="(q, qIndex) in questionList" :key="qIndex" :label="q.name" :value="String(q.id)" />
								</el-select>
							</el-form-item>
							<!-- 输入数字 -->
							<el-form-item v-if="[2, 3, 4, 5].indexOf(res.type) != -1">
								<el-input-number v-model="res.value" :min="0" style="width: 400px" />
							</el-form-item>
							<el-form-item>
								<el-button class="btn" type="danger" @click="delResult(index, resIndex)">删除</el-button>
							</el-form-item>
						</el-form>
					</template>
				</el-card>
			</div>
		</div>
		<div class="foot">
			<el-button type="primary" @click="saveSkip">保存</el-button>
		</div>
	</div>
</template>

<script>
	import { ElMessage } from "element-plus";
	import global_api from "@/config/Api";
	export default {
		name: "jump",
		data() {
			return {
				loading: false,
				question: {},
				optionList: [],
				questionList: [],
				type: null,
				typeName: null,
				skip: [],
				isEdit: false,
			};
		},
		mounted() {
			let data = JSON.parse(sessionStorage.getItem("question_item"));
			if (!data || !data.question || !data.optionList) {
				ElMessage.error("数据缺失");
			}
			data.question.name = data.question.name.replace(/<.+?>/g, "");
			this.question = data.question;
			this.type = data.question.type;
			this.typeName = data.questionType.name;
			this.optionList = data.optionList;
			console.log(data);
			this.getQuestionList();
			this.getSkip();
		},
		methods: {
			// 新增一条规则组
			addSkip() {
				this.skip.push({
					id: -1,
					questionId: this.question.id,
					logic: 0,
					surveyId: this.question.surveyId,
					rules: [],
					result: [],
				});
			},
			// 本地规则组数据删除
			delSkip(index) {
				this.skip.splice(index, 1);
				ElMessage.success("本地数据删除成功");
			},
			// 删除规则组数据
			delSkipItem(item) {
				let param = {
					questionSkipId: item.id,
				};
				this.loading = true;
				global_api.RemoveQuestionSkipByQuestionSkipId(param).then(
					(res) => {
						this.loading = false;
						console.log(res);
						ElMessage.success("数据删除成功");
						this.getSkip();
					},
					(err) => {
						this.loading = false;
						console.log(err);
						ElMessage.error("数据删除失败");
						this.getSkip();
					}
				);
			},
			// 添加一条规则条件
			addRule(index) {
				this.skip[index].rules.push({
					optionId: "",
					value: "",
					condition: "",
				});
			},
			// 删除一条规则条件
			delRule(index, ruleIndex) {
				this.skip[index].rules.splice(ruleIndex, 1);
			},
			// 添加一条规则结果
			addResult(index) {
				this.skip[index].result.push({
					value: "",
					type: "",
				});
			},
			// 删除一条规则结果
			delResult(index, resIndex) {
				this.skip[index].result.splice(resIndex, 1);
			},
			// 保存
			saveSkip() {
				let skip = this.skip;
				let type = this.type;
				if (!skip.length) return ElMessage.error("没有数据");
				for (let i in skip) {
					if (!skip[i].rules.length) return ElMessage.error("请设置规则条件");
					if (!skip[i].result.length) return ElMessage.error("请设置规矩结果");
					// 验证规则
					for (let n in skip[i].rules) {
						
						// 单选题、多选题
						if (
							(type === 1 || type === 2 || type === 10 || type === 12 || type === 13) &&
							(!skip[i].rules[n].optionId || !skip[i].rules[n].condition) && (skip[i].rules[n].condition != 8)
						) {
							return ElMessage.error("请正确设置规则条件");
						}
						// 简答题、排序题、打分题、比重题、滑条题、矩阵单选题、矩阵多选题
						if (
							(type === 3 ||
								type === 4 ||
								type === 5 ||
								type === 6 ||
								type === 7 ||
								type === 8 ||
								type === 9 ||
								type === 11 ||
								type === 14 ||
								type === 15 ||
								type === 18 ||
								type === 22
								) &&
							(!skip[i].rules[n].optionId || !skip[i].rules[n].condition || !skip[i].rules[n].value)
						) {
							return ElMessage.error("请正确设置规则条件");
						}
						// 开放题
						if (
							(type === 16 || type === 19) &&
							(!skip[i].rules[n].optionId ||
								!skip[i].rules[n].condition ||
								!skip[i].rules[n].value ||
								!skip[i].rules[n].optionId2)
						) {
							return ElMessage.error("请正确设置规则条件");
						}
						if ((type === 16 || type === 19) && skip[i].rules[n].condition != 8) {
							skip[i].rules[n].value = JSON.stringify({
								optionId2: skip[i].rules[n].optionId2,
								value: skip[i].rules[n].value,
							});
						}
						if(skip[i].rules[n].condition == 8){
							skip[i].rules[n].optionId = this.optionList[0].id;
						}
					}
					// 验证结果
					for (let n in skip[i].result) {
						if (!skip[i].result[n].type || (!skip[i].result[n].value && skip[i].result[n].type !== 7)) {
							return ElMessage.error("请正确设置规则结果");
						}
					}
					skip[i].questionSkipId = skip[i].id;
					skip[i].surveyQuestionSkipOptions = skip[i].rules;
					skip[i].surveyQuestionSkipResults = skip[i].result;
				}
				if (this.isEdit) {
					this.loading = true;
					global_api.ModifyQuestionSkipByShowIdBatch({ surveyQuestionSkip: JSON.stringify(skip) }).then(
						(res) => {
							this.loading = false;
							console.log(res);
							ElMessage.success("修改成功");
							this.getSkip();
						},
						(err) => {
							this.loading = false;
							console.log(err.msg);
							ElMessage.error(err.msg);
						}
					);
				} else {
					this.loading = true;
					global_api.AddQuestionSkipByQuestionIdBatch({ surveyQuestionSkip: JSON.stringify(skip) }).then(
						(res) => {
							this.loading = false;
							console.log(res);
							ElMessage.success("新建成功");
							this.getSkip();
						},
						(err) => {
							this.loading = false;
							console.log(err);
							ElMessage.error(err.msg);
						}
					);
				}
			},
			// 获取题目规则
			getSkip() {
				let param = {
					questionId: this.question.id,
					surveyId: this.question.surveyId,
				};
				this.loading = true;
				global_api.queryQuestionSkipByQuestionId(param).then(
					(res) => {
						this.loading = false;
						let resSkip = res.result;
						if (resSkip) {
							for (let i in resSkip) {
								resSkip[i].rules = resSkip[i].surveyQuestionSkipOptions;
								resSkip[i].result = resSkip[i].surveyQuestionSkipResults;
								if (this.type === 16 || this.type === 19) {
									for (let n in resSkip[i].rules) {
										let d = JSON.parse(resSkip[i].rules[n].value);
										resSkip[i].rules[n].value = d.value;
										resSkip[i].rules[n].optionId2 = d.optionId2;
									}
								}
							}
							this.skip = resSkip;
							this.isEdit = true;
						} else {
							this.skip = [];
							this.isEdit = false;
						}
					},
					(err) => {
						this.loading = false;
						this.isEdit = false;
					}
				);
			},
			// 获取题目列表
			getQuestionList() {
				let param = {
					sort: this.question.sort,
					surveyId: this.question.surveyId,
				};
				this.loading = true;
				global_api.QueryQuestionListBySurveyIdAndGreaterThanOrEqualToSort(param).then(
					(res) => {
						this.loading = false;
						let questionList = res.result;
						for (let i in questionList) {
							questionList[i].name = questionList[i].name.replace(/<.+?>/g, "");
						}
						this.questionList = questionList;
					},
					(err) => {
						this.loading = false;
						ElMessage.error(`获取失败:${err.msg}`);
					}
				);
			},
			// 结果类型是否可用
			optionDisabled(list, type) {
				switch (type) {
					case 1:
						for (let i in list) {
							if (list[i].type === 1) return true;
						}
						break;
					case 2:
						for (let i in list) {
							if ([2, 3, 4, 5].indexOf(list[i].type) != -1) return true;
						}
						break;
					case 3:
						for (let i in list) {
							if ([7, 8].indexOf(list[i].type) != -1) return true;
						}
						break;
				}
				return false;
			},
		},
	};
</script>

<style scoped>
	.jump {
		width: 100%;
		height: 100%;
	}
	.jump .header {
		height: 50px;
		display: flex;
		align-items: center;
	}
	.jump .header .title {
		font-size: 18px;
		color: #000000;
		font-weight: bold;
		margin-right: 20px;
	}
	.jump .content {
		width: 100%;
		height: calc(100% - 100px);
		overflow-y: auto;
	}
	.jump .content .item {
		margin-bottom: 20px;
	}
	.jump .foot {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.btn {
		display: none;
	}
	.form:hover .btn {
		display: block;
	}
</style>
