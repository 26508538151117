<template>
  <div class="prize" v-loading="loading">
    <div class="prizeItem" v-if="prizeData">
      <el-card class="card" v-for="(item, index) in prizeData">
        <template #header>
          <div class="itemHeader">
            <span class="itemName">{{ item.name }}</span>
            <el-button type="primary" @click="gotoEdit(item)">修改</el-button>
            <el-button type="danger" @click="removePrize(item)">删除</el-button>
          </div>
        </template>
        <el-form inline>
          <el-form-item label="标题：">
            <el-input v-model="item.title" disabled></el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input v-model="item.remark" disabled></el-input>
          </el-form-item>
          <el-form-item label="优先级：">
            <el-input v-model="item.priority" disabled></el-input>
          </el-form-item>
          <el-form-item label="领奖上线：">
            <el-input v-model="item.maxRewardNo" disabled></el-input>
          </el-form-item>
          <el-form-item label="实际中奖：">
            <el-input v-model="item.rewardCount" disabled></el-input>
          </el-form-item>
          <el-form-item label="图片：">
            <el-image style="height: 32px" :preview-src-list="[item.giftImage]" :src="item.giftImage" fit="fill" />
          </el-form-item>
          <el-form-item label="奖励类型：">
            <el-select v-model="item.type" disabled>
              <el-option label="无奖励" :value="-1" />
              <el-option label="心愿币" :value="0" />
              <el-option label="优惠券" :value="1" />
              <el-option label="实物" :value="2" />
              <el-option label="红包" :value="3" />
              <el-option label="自定义(表格)" :value="4" />
              <el-option label="情感分析" :value="5" />
              <el-option label="现金" :value="6" />
            </el-select>
          </el-form-item>
          <el-form-item label="心愿币：" v-if="item.type === 0">
            <el-input v-model="item.valueJson.coin" disabled></el-input>
          </el-form-item>
          <el-form-item label="优惠券类型：" v-if="item.type === 1">
            <el-select v-model="item.valueJson.type" disabled>
              <el-option label="淘口令" :value="1" />
              <el-option label="二维码" :value="2" />
              <el-option label="公众号" :value="3" />
              <el-option label="小程序" :value="4" />
            </el-select>
          </el-form-item>
          <el-form-item label="小程序id：" v-if="item.type === 1 && item.valueJson === 4">
            <el-input v-model="item.valueJson.appId" disabled></el-input>
          </el-form-item>
          <el-form-item label="链接：" v-if="item.type === 1">
            <el-input v-model="item.valueJson.value" disabled></el-input>
          </el-form-item>
          <el-form-item label="红包：" v-if="item.type === 3">
            <el-input v-model="item.valueJson.money" disabled></el-input>
          </el-form-item>
          <el-form-item label="红包：" v-if="item.type === 3">
            <el-input v-model="item.valueJson.money" disabled></el-input>
          </el-form-item>
          <el-form-item label="找谁领：" v-if="item.type === 2 || item.type === 3">
            <el-select v-model="item.valueJson.lingUrl" disabled>
              <el-option label="小星" value="https://mp.weixin.qq.com/s/80wQidjJRSnY4pe6Npw1TQ" />
              <el-option label="小月" value="https://mp.weixin.qq.com/s/cMBOP43vOHXbUKyqCfA7nQ" />
            </el-select>
          </el-form-item>
        </el-form>
        <el-divider />
        <el-form inline v-if="tagsList[index] || scoreList[index] || phaseList[index]">
          <el-form-item label="打卡次数:" v-if="phaseList[index]">
            <el-tag class="ml-2" v-for="phase in phaseList[index]">第{{ phase.value }}次打卡</el-tag>
          </el-form-item>
          <el-form-item label="获奖人群:" v-if="tagsList[index]">
            <el-tag class="ml-2" v-for="tag in tagsList[index]">{{ tag.name }}</el-tag>
          </el-form-item>
          <el-form-item label="分数区间" v-if="scoreList[index]">
            <el-select v-model="scoreList[index].type" disabled>
              <el-option label="区间内" :value="0" />
              <el-option label="区间外" :value="1" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="scoreList[index]">
            <el-input v-model="scoreList[index].rewardConditionDetails[0].value" disabled></el-input>
          </el-form-item>
          <el-form-item v-if="scoreList[index]">
            <el-input v-model="scoreList[index].rewardConditionDetails[1].value" disabled></el-input>
          </el-form-item>
          <el-form-item label="答题时间" v-if="timeList[index]">
            <el-select v-model="timeList[index].timeType" disabled>
              <el-option label="大于" :value="1"></el-option>
              <el-option label="小于" :value="2"></el-option>
              <el-option label="区间内" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="timeList[index]">
            <el-input v-model="timeList[index].rewardConditionDetails[0].value" disabled></el-input>
          </el-form-item>
          <el-form-item v-if="timeList[index]?.rewardConditionDetails[1]">
            <el-input v-model="timeList[index].rewardConditionDetails[1].value" disabled></el-input>
          </el-form-item>
        </el-form>
        <p v-if="questionData[index] && questionData[index].length">题目选项</p>
        <div style="padding: 10px;margin-bottom:10px;border: 1px solid #ccc;border-radius: 4px"
          v-for="(ques, quesIndex) in questionData[index]" :key="ques.id">
          <el-form inline>
            <el-form-item label="题目:">
              <el-select v-model="ques.value" disabled>
                <el-option v-for="opt in question" :value="opt.question.id + ''" :label="opt.question.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="逻辑关系:">
              <el-select v-model="ques.type" disabled>
                <el-option :value="0" label="与逻辑"></el-option>
                <el-option :value="1" label="或逻辑"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="题目类型:">
              <el-select v-model="ques.questionType" disabled>
                <el-option :value="1" label="单选题"></el-option>
                <el-option :value="2" label="多选题"></el-option>
                <el-option :value="3" label="简答题"></el-option>
                <el-option :value="4" label="排序题"></el-option>
                <el-option :value="5" label="打分题"></el-option>
                <el-option :value="6" label="比重题"></el-option>
                <el-option :value="7" label="滑条题"></el-option>
                <el-option :value="8" label="矩阵单选"></el-option>
                <el-option :value="9" label="矩阵多选"></el-option>
                <el-option :value="10" label="图片题"></el-option>
                <el-option :value="11" label="NPS"></el-option>
                <el-option :value="12" label="种草题"></el-option>
                <el-option :value="13" label="购买意愿"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div v-for="(detail, detailIndex) in ques.rewardConditionDetails">
            <el-form inline>
              <section v-if="ques.questionType === 1 || ques.questionType === 12 || ques.questionType === 13">
                <el-form-item label="选项设置:">
                  <el-select v-model="detail.condition" disabled>
                    <el-option label="选中" :value="1"></el-option>
                    <el-option label="不选中" :value="2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-select v-model="detail.optionId" disabled>
                    <el-option :label="opt.name" :value="opt.id" v-for="opt in optionList"></el-option>
                  </el-select>
                </el-form-item>
              </section>
              <section v-if="ques.questionType === 2">
                <el-form-item label="选项设置:">
                  <el-select v-model="detail.condition" disabled>
                    <el-option label="选中" :value="1"></el-option>
                    <el-option label="不选中" :value="2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-select v-model="detail.optionId" disabled>
                    <el-option :label="opt.name" :value="opt.id" v-for="opt in optionList"></el-option>
                  </el-select>
                </el-form-item>
              </section>
              <section v-if="ques.questionType === 3">
                <el-form-item label="选项设置:">
                  <el-select v-model="detail.condition" disabled>
                    <el-option label="大于" :value="3"></el-option>
                    <el-option label="小于" :value="4"></el-option>
                    <el-option label="等于" :value="5"></el-option>
                    <el-option label="包含" :value="6"></el-option>
                    <el-option label="不包含" :value="7"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-input v-model="detail.value" disabled></el-input>
                </el-form-item>
              </section>
              <section v-if="ques.questionType === 4">
                <el-form-item label="选项设置:">
                  <el-input v-model="detail.value" disabled></el-input>
                </el-form-item>
                <el-form-item>
                  <el-form-item>
                    <el-select v-model="detail.optionId" disabled>
                      <el-option :label="opt.name" :value="opt.id" v-for="opt in optionList"></el-option>
                    </el-select>
                  </el-form-item>
                </el-form-item>
              </section>
              <section v-if="ques.questionType === 5 || ques.questionType === 7 || ques.questionType === 11">
                <el-form-item label="选项设置:">
                  <el-select v-model="detail.condition" disabled>
                    <el-option label="大于" :value="3"></el-option>
                    <el-option label="小于" :value="4"></el-option>
                    <el-option label="等于" :value="5"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-input v-model="detail.value" disabled></el-input>
                </el-form-item>
              </section>
              <section v-if="ques.questionType === 6">
                <el-form-item label="选项设置:">
                  <el-select v-model="detail.optionId" disabled>
                    <el-option :label="opt.name" :value="opt.id" v-for="opt in optionList"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-select v-model="detail.condition" disabled>
                    <el-option label="大于" :value="3"></el-option>
                    <el-option label="小于" :value="4"></el-option>
                    <el-option label="等于" :value="5"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-input v-model="detail.value" disabled></el-input>
                </el-form-item>
              </section>
              <section v-if="ques.questionType === 8 || ques.questionType === 9">
                <el-form-item label="选项设置:">
                  <el-select v-model="detail.optionId" disabled>
                    <el-option :label="opt.name" :value="opt.id" v-for="opt in optionList"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-select v-model="detail.condition" disabled>
                    <el-option label="选中" :value="8"></el-option>
                    <el-option label="不选中" :value="9"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-select v-model="detail.value" disabled>
                    <el-option :label="opt.name" :value="opt.id + ''" v-for="opt in optionList"></el-option>
                  </el-select>
                </el-form-item>
              </section>
              <section v-if="ques.questionType === 10">
                <el-form-item label="选项设置:">
                  <el-select v-model="detail.condition" disabled>
                    <el-option label="选中" :value="1"></el-option>
                    <el-option label="不选中" :value="2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-select v-model="detail.optionId" disabled>
                    <el-option :label="opt.name" :value="opt.id" v-for="opt in optionList"></el-option>
                  </el-select>
                </el-form-item>
              </section>
            </el-form>
          </div>
        </div>
        <el-form v-for="formula in item.formulaList" inline>
          <el-form-item label="优先级">
            <el-input v-model="formula.priority" disabled></el-input>
          </el-form-item>
          <el-form-item label="公式">
            <el-input v-model="formula.value" disabled></el-input>
          </el-form-item>
          <el-form-item style="width: 100%">
            <el-form inline v-for="v in formula.variable" style="width: 100%">
              <el-form-item label="题目">
                <el-select v-model="v.queId" disabled>
                  <el-option v-for="opt in question" :value="opt.question.id" :label="opt.question.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="变量">
                <el-input v-model="v.x" disabled></el-input>
              </el-form-item>
            </el-form>
          </el-form-item>
        </el-form>
      </el-card>
    </div>




    <!--  没有数据  -->
    <el-result v-if="subTitle && !loading" title="没有数据" :sub-title="subTitle">
      <template #extra>
        <el-button type="primary" @click="GetPrizeData">刷新</el-button>
      </template>
    </el-result>
  </div>
</template>

<script>
import { ref } from "vue";
import { ElMessage } from "element-plus";
import global_api, { DeleteRewardListByRewardId, GetRewardListBySpecialEventId, getTargetAudienceById, QuerySurveyInfoBySurveyTypeAndOriginId } from "@/config/Api";

export default {
  data() {
    return {
      specialEventId: null,
      loading: false,
      subTitle: '',
      phaseList: {},
      tagsList: {},
      scoreList: {},
      optionList: [],
      question: [],
      questionData: {},
      timeList: []
    }
  },
  mounted() {
    let query = this.$route.query;
    this.specialEventId = query.id;
    this.name = query.name;
    this.GetPrizeData();

  },
  methods: {
    // 获取调研奖励
    GetPrizeData() {
      // 检查是否有调研ID
      if (!this.specialEventId) {
        ElMessage.error('缺少调研ID');
        setTimeout(() => {
          this.$router.push({ path: '/survey' })
        }, 1000);
      } else {
        let param = {
          specialEventId: this.specialEventId
        }
        this.loading = true;
        GetRewardListBySpecialEventId({ params: param }).then(res => {
          let { result } = res;
          for (let i in result) {
            if (result[i].value.indexOf('{') == 0) {
              result[i].valueJson = JSON.parse(res.result[i].value)
            } else {
              result[i].valueJson = { coin: result[i].value }
            }
          }
          this.prizeData = result;
          this.loading = false;
          this.subTitle = '';
          for (let i in result) {
            this.getPhase(result[i].rewardConditionList, i);
            this.getTarget(result[i].rewardConditionList, i)
            this.getScoreList(result[i].rewardConditionList, i)
            this.getFormulaList(result[i].rewardConditionList, i)
            this.getTime(result[i].rewardConditionList, i)
          }
          this.getQuestionInfo();
        }, err => {
          this.prizeData = null;
          this.subTitle = err.msg;
          this.loading = false;
        })
      }
    },
    // 删除调研奖励
    removePrize(item) {
      let param = {
        rewardId: item.id
      }
      this.loading = true;
      DeleteRewardListByRewardId(param).then(res => {
        this.loading = false;
        this.GetPrizeData();
      }, err => {
        this.loading = false;
        ElMessage.error(`删除失败${err.msg}`)
      })
    },
    // 获取期数
    getPhase(list, i) {
      let phaseList = [];
      for (let n in list) {
        if (list[n].conditionType === 4) {
          phaseList.push(...list[n].rewardConditionDetails)
        }
      }
      this.phaseList[i] = phaseList;
    },
    // 获取人群库分类
    getTarget(list, i) {
      console.log(list)
      let ids = [];
      for (let i in list) {
        if (list[i].conditionType === 0) {
          ids.push(list[i].rewardConditionDetails[0].value)
        }
      }
      if (ids.length) {
        let param = {
          targetAudienceId: ids.join(',')
        }
        getTargetAudienceById({ params: param }).then(res => {
          this.tagsList[i] = res.result;
        }, err => {
          this.tagsList[i] = null;
        })
      }
    },
    // 获取分数
    getScoreList(list, index) {
      for (let i in list) {
        if (list[i].conditionType == 2) {
          this.scoreList[index] = list[i];
          break
        }
      }
    },
    // 获取问卷信息
    getQuestionInfo() {
      let param = {
        originId: this.specialEventId,
        surveyName: this.name,
        surveyType: 4
      }
      QuerySurveyInfoBySurveyTypeAndOriginId(param).then(res => {
        this.getQuestion(res.result.id);
      })
    },
    // 获取题目列表
    getQuestion(id) {
      let param = {
        surveyId: id,
        pageNo: 1,
        pageSize: 500,
      }
      global_api.GetQuestionList(param).then(res => {
        console.log(res)
        let { result } = res;
        for (let i in result) {
          this.optionList.push(...result[i].optionList);
        }
        this.question = result;
        this.setQuestionData();
      })
    },
    // 处理问题数据
    setQuestionData() {
      let questionData = {};
      let list = this.prizeData;
      let question = this.question;
      for (let i in list) {
        let rewardConditionList = list[i].rewardConditionList;
        let arr = [];
        for (let n in rewardConditionList) {
          if (rewardConditionList[n].conditionType === 1) {
            for (let k in question) {
              if (question[k].question.id === Number(rewardConditionList[n].value)) {
                rewardConditionList[n].questionType = question[k].question.type;
                break;
              }
            }
            arr.push(rewardConditionList[n])
          }
        }
        questionData[i] = arr;
      }
      this.questionData = questionData;
      console.log(questionData)
    },
    // 处理公式
    getFormulaList(list, index) {
      for (let i in list) {
        console.log(list[i]);
        if (list[i].conditionType === 3) {
          let variable = [];
          for (let n in list[i].rewardConditionDetails) {
            list[i].rewardConditionDetails[n];
            variable.push({
              x: list[i].rewardConditionDetails[n].value,
              queId: list[i].rewardConditionDetails[n].optionId
            })
          }
          let value = list[i].value;

          this.prizeData[index].formulaList = [{
            priority: list[i].priority,
            variable: variable,
            value: list[i].value
          }]
        }
      }
    },
    // 获取时间
    getTime(list, index) {
      for (let i in list) {
        if (list[i].conditionType == 5) {
          if (list[i].rewardConditionDetails.length == 2) {
            list[i].timeType = 3;
          }
          if (list[i].rewardConditionDetails.length == 1) {
            list[i].timeType = list[i].rewardConditionDetails[0].condition == 3 ? 1 : 2;
          }
          this.timeList[index] = list[i];
          break
        }
      }
    },
    // 修改奖励规则
    gotoEdit(item) {
      sessionStorage.setItem('prizeData', JSON.stringify(item));
      if (item.type == 5) {
        this.$router.push({ path: '/survey/special', query: { id: this.specialEventId, name: this.name, type: 'edit' } })
      } else {
        this.$router.push({ path: '/survey/addprize', query: { id: this.specialEventId, name: this.name, type: 'edit' } })
      }

    }
  },
}
</script>

<style scoped lang="scss">
.prize {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  overflow: auto;

  .option {
    display: flex;
    align-items: flex-start;
    padding: 5px 0;
    line-height: 20px;
    font-size: 16px;

    .optionType {
      width: 100px;
      text-align: right;
      font-weight: bold;
    }

    .optionCount {
      width: calc(100% - 100px);
      padding: 0 20px;
    }
  }

  .question {
    display: flex;
    flex-direction: column;
    line-height: 20px;
    font-size: 16px;

    .questionName {
      font-weight: bold;
    }

    .questionAnswer {
      font-size: 14px;
    }
  }
}

.itemHeader {
  .itemName {
    margin-right: 30px;
  }
}

.card {
  margin-bottom: 30px;
}</style>