<template>
  <div class="aiBrandList" v-loading="loading">
    <section class="search_btn">
      <div class="search_items">
        <span class="title">标签：</span>
        <div>
          <el-input
              v-model="this.searchLabel"
              class="w-50 m-2"
              size="large"
              placeholder="请输入标签"
          ></el-input>
        </div>
      </div>
      <div class="search_items">
        <el-button size="small" type="primary" :icon="Search" @click="search">查询</el-button>
        <el-button type="primary" @click="openAddBrand">添加AI品牌</el-button>
        <el-button type="primary" @click="exportToExcel">导出</el-button> <!-- 导出按钮 -->
        <el-button type="primary" @click="triggerFileInput">导入</el-button> <!-- 导入按钮 -->
        <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none;"/> <!-- 文件输入框 -->
      </div>
    </section>
    <section class="tableList">
      <el-table :data="tableList">
        <!-- 标签列 -->
        <el-table-column label="标签">
          <template #default="scope">
            <div v-if="editIndex === scope.$index && editField === 'label'">
              <!--              <el-select
                                v-model="scope.row.label"
                                @blur="saveBrand(scope.$index)"
                                ref="labelInput"
                                placeholder="请选择标签"
                            >
                              <el-option
                                  v-for="item in tagOptions"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value"
                              />
                            </el-select>-->
              <el-input
                  v-model="scope.row.label"
                  @blur="saveBrand(scope.$index)"
                  ref="nameInput"
              ></el-input>
            </div>
            <div v-else @click="startEditing(scope.$index, 'label')">
              {{ scope.row.label || '编辑' }}
            </div>
          </template>
        </el-table-column>
        <!-- 细分品类列 -->
        <el-table-column label="细分品类">
          <template #default="scope">
            <div v-if="editIndex === scope.$index && editField === 'subLabel'">
              <el-input
                  v-model="scope.row.subLabel"
                  @blur="saveBrand(scope.$index)"
                  ref="nameInput"
              ></el-input>
            </div>
            <div v-else @click="startEditing(scope.$index, 'subLabel')">
              {{ scope.row.subLabel || '编辑' }}
            </div>
          </template>
        </el-table-column>
        <!-- 品牌名称列 -->
        <el-table-column label="品牌名称">
          <template #default="scope">
            <div v-if="editIndex === scope.$index && editField === 'name'">
              <el-input
                  v-model="scope.row.name"
                  @blur="saveBrand(scope.$index)"
                  ref="nameInput"
              ></el-input>
            </div>
            <div v-else @click="startEditing(scope.$index, 'name')">
              {{ scope.row.name || '编辑' }}
            </div>
          </template>
        </el-table-column>
        <!-- 品牌理念 列 -->
        <el-table-column label="品牌理念">
          <template #default="scope">
            <div v-if="editIndex === scope.$index && editField === 'brandIdea'">
              <el-input
                  v-model="scope.row.brandIdea"
                  @blur="saveBrand(scope.$index)"
                  ref="nameInput"
              ></el-input>
            </div>
            <div v-else @click="startEditing(scope.$index, 'brandIdea')">
              {{ scope.row.brandIdea || '编辑' }}
            </div>
          </template>
        </el-table-column>
        <!--代言人 列-->
        <el-table-column label="代言人">
          <template #default="scope">
            <div v-if="editIndex === scope.$index && editField === 'spokesperson'">
              <el-input
                  v-model="scope.row.spokesperson"
                  @blur="saveBrand(scope.$index)"
                  ref="nameInput"
              ></el-input>
            </div>
            <div v-else @click="startEditing(scope.$index, 'spokesperson')">
              {{ scope.row.spokesperson || '编辑' }}
            </div>
          </template>
        </el-table-column>
        <!--知名产品 列-->
        <el-table-column label="知名产品">
          <template #default="scope">
            <div v-if="editIndex === scope.$index && editField === 'famousProduct'">
              <el-input
                  v-model="scope.row.famousProduct"
                  @blur="saveBrand(scope.$index)"
                  ref="nameInput"
              ></el-input>
            </div>
            <div v-else @click="startEditing(scope.$index, 'famousProduct')">
              {{ scope.row.famousProduct || '编辑' }}
            </div>
          </template>
        </el-table-column>
        <!-- 品牌描述列 -->
        <el-table-column label="品牌描述">
          <template #default="scope">
            <div v-if="editIndex === scope.$index && editField === 'description'">
              <el-input
                  v-model="scope.row.description"
                  @blur="saveBrand(scope.$index)"
                  ref="descriptionInput"
              ></el-input>
            </div>
            <div v-else @click="startEditing(scope.$index, 'description')">
              {{ scope.row.description || '编辑' }}
            </div>
          </template>
        </el-table-column>
        <!-- 操作列 -->
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="danger" size="small" @click="removeBrand(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>

    <!-- 新增品牌 -->
    <el-dialog v-model="brandDialog" title="编辑" width="500px">
      <el-form label-width="150px">
        <el-form-item label="品牌名称">
          <el-input v-model="brand.name"></el-input>
        </el-form-item>
        <el-form-item label="标签">
          <!--          <el-select v-model="brand.label" placeholder="请选择标签">
                      <el-option
                          v-for="item in tagOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                      />
                    </el-select>-->
          <el-input v-model="brand.label"></el-input>
        </el-form-item>
        <el-form-item label="细分品类">
          <el-input v-model="brand.subLabel"></el-input>
        </el-form-item>
        <el-form-item label="品牌理念">
          <el-input v-model="brand.brandIdea"></el-input>
        </el-form-item>
        <el-form-item label="代言人">
          <el-input v-model="brand.spokesperson"></el-input>
        </el-form-item>
        <el-form-item label="知名产品">
          <el-input v-model="brand.famousProduct"></el-input>
        </el-form-item>
        <el-form-item label="品牌描述">
          <el-input v-model="brand.description"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="brandDialog = false">取消</el-button>
        <el-button @click="addAiBrand">确定</el-button>
      </template>
    </el-dialog>


    <!-- 预览对话框 -->
    <el-dialog v-model="previewDialogVisible"
               title="预览导入记录"
               width="80%"

    >
      <el-table :data="parsedData" style="width: 100%">
        <el-table-column prop="id" label="ID"/>
        <el-table-column prop="label" label="标签"/>
        <el-table-column prop="subLabel" label="细分品类"/>
        <el-table-column prop="name" label="品牌名称"/>
        <el-table-column prop="brandIdea" label="品牌理念"/>
        <el-table-column prop="spokesperson" label="代言人"/>
        <el-table-column prop="famousProduct" label="知名产品"/>
        <el-table-column prop="description" label="品牌描述"/>
        <el-table-column label="状态">
          <template #default="scope">
            <el-tag
                :type="scope.row.status === 'successUpdate' ? 'success' : scope.row.status === 'successInsert' ? 'success' : scope.row.status === 'failed' ? 'danger' : 'info'"
            >
              {{
                scope.row.status === 'successUpdate' ? '成功更新' : scope.row.status === 'successInsert' ? '成功插入' : scope.row.status === 'failed' ? '失败' : '处理中'
              }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <el-button @click="previewDialogVisible = false" :disabled="importInProgress">取消</el-button>
        <el-button
            type="primary"
            @click="confirmImport"
            :disabled="importInProgress || importCompleted">
          {{ importInProgress ? '执行中' : importCompleted ? '执行完毕' : '开始执行' }}
        </el-button>
      </template>
    </el-dialog>


    <!-- Warning Dialog -->
    <el-dialog
        v-model="warningDialogVisible"
        title="警告"
        width="400px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
    >
      <div>
        此页面的数据不区分开发和正式环境，修改需谨慎！
      </div>
      <template #footer>
        <el-button type="primary" @click="warningDialogVisible = false">我知道了</el-button>
      </template>
    </el-dialog>


  </div>
</template>

<script>
import {ElMessage, ElMessageBox} from "element-plus";
import {Get_url} from "@/config/Get_url";
import fileTemplate from "@/components/fileTemplate.vue";
import {Search} from "@element-plus/icons-vue";
import global_api from "@/config/Api";
import * as XLSX from "xlsx";

export default {
  components: {fileTemplate},
  data() {
    return {
      Search,
      tableList: [],
      loading: false,
      brandDialog: false,
      brand: {},
      updateBrand: {},
      headers: {
        id: localStorage.getItem("id"),
        token: localStorage.getItem("token"),
      },
      Get_url: Get_url(),
      searchLabel: "",
      editIndex: null, // 当前编辑的行索引
      editField: null, // 当前编辑的字段
      tagOptions: [], // 存放标签选项的数组
      parsedData: [], // 存储解析后的数据
      processedCount: 0, // 已处理的条数
      previewDialogVisible: false, // 控制预览对话框的显示
      importCompleted: false, // Flag to indicate if the import is completed
      importInProgress: false, // Flag to indicate if the import is in progress
      warningDialogVisible: false, // Flag to control the visibility of the warning dialog
    };
  },
  mounted() {
    this.queryAiBrandListByLabel();
    this.fetchTagOptions(); // 获取标签选项

    // Show the warning dialog when the page loads
    this.warningDialogVisible = true;
  },
  methods: {
    search() {
      this.queryAiBrandListByLabel();
    },
    // 获取数据
    queryAiBrandListByLabel() {
      let param = {
        label: this.searchLabel,
      };
      this.loading = true;
      global_api.QueryAiBrandListByLabel({params: param}).then(
          (res) => {
            this.tableList = res.result;
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            ElMessage.error("获取AI品牌列表失败");
          }
      );
    },
    // 获取标签选项数据
    fetchTagOptions() {
      // global_api.GetTagOptions().then(
      //     (res) => {
      //       this.tagOptions = res.data; // 假设返回的数据结构是 { data: [...] }
      //     },
      //     (err) => {
      //       ElMessage.error("获取标签选项失败");
      //     }
      // );
    },
    // 删除品牌
    removeBrand(row) {
      ElMessageBox.confirm("确认删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        let param = {
          brandId: row.id,
        };
        this.loading = true;
        global_api.DeleteAiBrand(param).then(
            (res) => {
              this.loading = false;
              this.queryAiBrandListByLabel();
            },
            (err) => {
              this.loading = false;
              ElMessage.error("删除失败");
            }
        );
      });
    },
    // 开始编辑品牌
    startEditing(index, field) {
      this.editIndex = index;
      this.editField = field;
      this.$nextTick(() => {
        this.$refs[`${field}Input`]?.focus();
      });
    },
    // 保存品牌信息
    saveBrand(index) {
      let updatedBrand = this.tableList[index];
      // console.log(updatedBrand);
      this.updateBrand = {
        brandId: updatedBrand.id,
        label: updatedBrand.label,
        subLabel: updatedBrand.subLabel,
        name: updatedBrand.name,
        brandIdea: updatedBrand.brandIdea,
        spokesperson: updatedBrand.spokesperson,
        famousProduct: updatedBrand.famousProduct,
        description: updatedBrand.description,
      };
      // 这里添加您的保存逻辑
      this.loading = true;
      global_api.UpdateAiBrand(this.updateBrand).then(
          (res) => {
            this.loading = false;
            this.queryAiBrandListByLabel();
            this.brandDialog = false;
          },
          (err) => {
            this.loading = false;
            ElMessage.error("更新失败");
          }
      );
      this.editIndex = null;
      this.editField = null;
    },
    // 添加品牌
    openAddBrand() {
      this.brand = {
        label: "",
        name: "",
        description: "",
        subLabel: "",
        brandIdea: "",
        spokesperson: "",
        famousProduct: "",
      };
      this.brandDialog = true;
    },
    // 添加品牌
    addAiBrand() {
      if (!this.brand.name) return ElMessage.error("请输入品牌名称");
      if (!this.brand.label) return ElMessage.error("请输入标签");
      if (!this.brand.subLabel) return ElMessage.error("请输入细分品类");
      if (!this.brand.brandIdea) return ElMessage.error("请输入品牌理念");
      if (!this.brand.spokesperson) return ElMessage.error("请输入代言人");
      if (!this.brand.famousProduct) return ElMessage.error("请输入知名产品");
      if (!this.brand.description) return ElMessage.error("请输入品牌描述");
      this.loading = true;
      global_api.AddAiBrand(this.brand).then(
          (res) => {
            this.loading = false;
            this.queryAiBrandListByLabel();
            this.brandDialog = false;
          },
          (err) => {
            this.loading = false;
            ElMessage.error("添加失败");
          }
      );
    },
    // 导出表格数据到Excel
    exportToExcel() {
      // 表格标题
      const header = ["id", "标签", "细分品类", "品牌名称", "品牌理念", "代言人", "知名产品", "品牌描述"];
      // 表格数据
      const data = this.tableList.map(item => [
        item.id,
        item.label,
        item.subLabel,
        item.name,
        item.brandIdea,
        item.spokesperson,
        item.famousProduct,
        item.description
      ]);

      // 创建工作表
      const worksheet = XLSX.utils.aoa_to_sheet([header, ...data]);
      // 创建工作簿
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "AI Brands");

      // 导出Excel文件
      // filename: 导出的文件名，按照时间戳命名
      const filename = new Date().getTime();
      XLSX.writeFile(workbook, "AI_Brands" + filename + ".xlsx");
    },
    triggerFileInput() {
      this.$refs.fileInput.click(); // 触发文件选择对话框
    },
    handleFileUpload(event) {
      const keyMap = {
        "标签": "label",
        "细分品类": "subLabel",
        "品牌名称": "name",
        "品牌理念": "brandIdea",
        "代言人": "spokesperson",
        "知名产品": "famousProduct",
        "品牌描述": "description",
        "id": "id",
      };

      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, {type: "array"});
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          let jsonData = XLSX.utils.sheet_to_json(sheet);

          // 清理不必要的字段，并进行键名转换
          jsonData = jsonData.map(item => {
            const newItem = {};

            // 遍历每个字段并使用keyMap映射
            for (let key in item) {
              if (keyMap[key]) {
                newItem[keyMap[key]] = item[key];
              }
            }

            return newItem;
          });

          this.parsedData = jsonData; // 保存清理后的数据
          // 显示预览对话框
          this.previewDialogVisible = true;
          this.importCompleted = false; // Reset the flag
          this.importInProgress = false; // Reset the flag
        };
        reader.readAsArrayBuffer(file); // 读取文件
      }
    },
    confirmImport() {
      // this.previewDialogVisible = false;
      this.startImport(); // 确认后开始执行导入
      this.importInProgress = true; // Set the flag to indicate that the import is in progress
    },

    async startImport() {
      if (!this.parsedData.length) {
        ElMessage.error("请先上传文件并解析数据");
        return;
      }

      this.loading = true;
      this.processedCount = 0;

      // Add a status property to each item in parsedData
      this.parsedData = this.parsedData.map(item => ({...item, status: 'pending'}));

      for (const [index, row] of this.parsedData.entries()) {
        try {
          if (row.id) {
            // 如果存在 ID，则执行更新操作
            const params = {
              brandId: row.id,
              label: row.label,
              subLabel: row.subLabel,
              name: row.name,
              brandIdea: row.brandIdea,
              spokesperson: row.spokesperson,
              famousProduct: row.famousProduct,
              description: row.description,
            };
            await global_api.UpdateAiBrand(params).then(
                () => {
                },
                () => {
                  ElMessage.error("更新失败");
                }
            );

            this.parsedData[index].status = 'successUpdate';
          } else {
            // 否则执行新增操作
            const params = {
              label: row.label,
              subLabel: row.subLabel,
              name: row.name,
              brandIdea: row.brandIdea,
              spokesperson: row.spokesperson,
              famousProduct: row.famousProduct,
              description: row.description,
            };
            await global_api.AddAiBrand(params).then(
                (res) => {
                },
                (err) => {
                  ElMessage.error("添加失败");
                }
            );

            this.parsedData[index].status = 'successInsert';
          }
          this.processedCount++;
        } catch (err) {
          this.parsedData[index].status = 'failed';
          ElMessage.error(`处理品牌 ${row.name || '未知名称'} 时出错`);
        }
      }
      this.loading = false;
      //休眠3s
      setTimeout(() => {
        this.queryAiBrandListByLabel();
      }, 3000);
      this.importInProgress = false; // Mark the process as completed
      this.importCompleted = true; // Set flag to true after starting the import
    },
  },
};
</script>

<style scoped lang="scss">
.aiBrandList {
  margin-top: 20px;
}
</style>
