<template>
  <div class="brandlist" v-loading="loading">
    <section class="searchForm">
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="openAddBrand">添加</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="tableList">
        <el-table-column label="ID" prop="id"></el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="副标题" prop="subtitle"></el-table-column>
        <el-table-column label="品牌图标" prop="icon">
          <template #default="scope">
            <img style="width:100px" :src="scope.row.icon" alt="">
          </template>
        </el-table-column>
        <el-table-column label="描述" prop="content"></el-table-column>
        <el-table-column label="品牌封面图" prop="cover">
          <template #default="scope">
            <img style="width:100px" :src="scope.row.cover" alt="">
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="上下架" prop="isActive">
          <template #default="scope">
            {{scope.row.isActive?'上架':'下架'}}
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="isActive">
          <template #default="scope">
            <el-button type="primary" size="small" @click="changeIsActive(scope.row)">{{scope.row.isActive?'下架':'上架'}}</el-button>
            <el-button type="primary" size="small" @click="openModifyBrand(scope.row)">编辑</el-button>
            <el-button type="danger" size="small" @click="removeBrand(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="cunPage"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </section>

    <!-- 新增品牌 -->
    <el-dialog v-model="brandDialog" title="编辑" width="500px">
      <el-form label-width="150px">
        <el-form-item label="品牌名">
          <el-input v-model="brand.name"></el-input>
        </el-form-item>
        <el-form-item label="品牌副标题">
          <el-input v-model="brand.subtitle	"></el-input>
        </el-form-item>
        <el-form-item label="品牌描述">
          <el-input v-model="brand.content	"></el-input>
        </el-form-item>
        <el-form-item label="品牌图标">
          <img style="width:200px;margin-right: 10px;" :src="brand.icon" alt="">
          <el-upload :action="Get_url+'admin/file/uploadFile'" :headers="headers" :on-success="iconSuccess" :show-file-list="false">
            <el-button type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="品牌封面图">
          <img style="width:200px;margin-right: 10px;" :src="brand.cover" alt=""/>
          <el-upload :action="Get_url+'admin/file/uploadFile'" :headers="headers" :on-success="coverSuccess" :show-file-list="false">
            <el-button type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="brandDialog = false">取消</el-button>
        <el-button v-if="brand.id" @click="modifyBrand">修改</el-button>
        <el-button v-else @click="addBrand">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {ElMessageBox,ElMessage} from 'element-plus'
import {queryBrandListByBrandSearchVo, modifyBrandActiveByBrandId, removeBrandByBrandId, addBrand, modifyBrandByBrandId} from '../../config/Api';
import {Get_url} from "../../config/Get_url";
export default {
  data(){
    return {
      cunPage: 1,
      pageSize: 10,
      total: 1,
      tableList: [],
      loading: false,
      brandDialog: false,
      brand:{},
      headers:{
        id:localStorage.getItem('id'),
        token: localStorage.getItem('token')
      },
      Get_url:Get_url(),
    }
  },
  mounted() {
    let query = this.$route.query;
    this.cunPage = Number(query.page || 1);
    this.pageSize = Number(query.size || 10);
    this.queryBrandListByBrandSearchVo();
  },
  methods: {
    // 获取数据
    queryBrandListByBrandSearchVo(){
      let param = {
        cunPage: this.cunPage,
        pageSize: this.pageSize,
      }
      this.loading = true;
      queryBrandListByBrandSearchVo(param).then(res=>{
        this.total = res.data.totalCount;
        this.tableList = res.result;
        this.loading = false;
      },err=>{
        this.loading = false;
        ElMessage.error('获取品牌列表失败')
      })
    },
    // 上下页
    handleCurrentChange(){
      this.queryBrandListByBrandSearchVo();
    },
    // 删除品牌
    removeBrand(row){
      ElMessageBox.confirm(
        '确认删除?',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(()=>{
        this.loading = true;
        let param = {
          brandId: row.id
        }
        this.loading = true;
        removeBrandByBrandId(param).then(res=>{
          this.loading = false;
          this.queryBrandListByBrandSearchVo()
        },err=>{
          this.loading = false;
          ElMessage.error('删除失败')
        })
      })
    },
    // 上下架
    changeIsActive(row){
      ElMessageBox.confirm(
        row.isActive?'确认下架?':'确认上架?',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(()=>{
        let param = {
          brandId: row.id
        };
        this.loading = true;
        modifyBrandActiveByBrandId(param).then(res=>{
          this.loading = false;
          this.queryBrandListByBrandSearchVo();
        },err=>{
          this.loading = false;
          ElMessage.error('修改上下架状态失败')
        })
      })
    },
    // 添加品牌
    openAddBrand(){
      this.brand = {
        content:'',
        cover:'',
        icon:'',
        name:'',
        subtitle:''
      }
      this.brandDialog = true;
    },
    // 上传图片
    iconSuccess(e){
      this.loading = false;
      this.brand.icon = e.msg;
    },
    coverSuccess(e){
      this.loading = false;
      this.brand.cover = e.msg;
    },
    // 添加品牌
    addBrand(){
      if(!this.brand.name) return ElMessage.error('请输入品牌名称');
      if(!this.brand.subtitle) return ElMessage.error('请输入品牌副标题');
      if(!this.brand.content) return ElMessage.error('请输入品牌描述');
      if(!this.brand.icon) return ElMessage.error('请选择品牌图标');
      if(!this.brand.cover) return ElMessage.error('请选择品牌封面');
      this.loading = true;
      addBrand(this.brand).then(res=>{
        this.loading = false;
        this.queryBrandListByBrandSearchVo();
        this.brandDialog = false;
      },err=>{
        this.loading = false;
        ElMessage.error('添加失败')
      })
    },
    // 编辑
    openModifyBrand(row){
      this.brand = {...row}
      this.brandDialog = true;
    },
    // 修改品牌
    modifyBrand(){
      if(!this.brand.name) return ElMessage.error('请输入品牌名称');
      if(!this.brand.subtitle) return ElMessage.error('请输入品牌副标题');
      if(!this.brand.content) return ElMessage.error('请输入品牌描述');
      if(!this.brand.icon) return ElMessage.error('请选择品牌图标');
      if(!this.brand.cover) return ElMessage.error('请选择品牌封面');
      this.brand.brandId = this.brand.id;
      this.loading = true;
      modifyBrandByBrandId(this.brand).then(res=>{
        this.loading = false;
        this.queryBrandListByBrandSearchVo();
        this.brandDialog = false;
      },err=>{
        this.loading = false;
        ElMessage.error('修改失败')
      })
    },
  }
}
</script>
