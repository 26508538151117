<template>
  <div id="right_manage">
    <section class="searchForm">
      <el-form inline>
        <el-form-item label="权限名称">
          <el-input type="text"></el-input>
        </el-form-item>
        <el-form-item label="URL">
          <el-input type="text"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">查询</el-button>
          <el-button type="primary">导出</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" :icon="Plus" @click="dialogVisible=true">权限录入</el-button>
    </section>
    <section class="tableList">
      <el-table :data="tableData">
        <el-table-column fixed prop="id" label="ID" width="80"/>
        <el-table-column fixed prop="name" label="权限名称" width="150"/>
        <el-table-column prop="URL" label="URL" width="250"/>
        <el-table-column prop="explain" label="权限说明"/>
        <el-table-column prop="createTime" label="创建时间" width="180"/>
        <el-table-column fixed="right" label="操作" width="140">
          <template #default>
            <el-button size="small" type="primary" @click="dialogVisible=true">编辑</el-button>
            <el-button size="small" type="danger" @click="deleteRow">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="pageCurrent"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </section>

    <!-- 添加编辑弹窗 -->
    <el-dialog v-model="dialogVisible" title="添加" width="500px" draggable>
      <el-form label-width="100px">
        <el-form-item label="权限名称">
          <el-input type="text"></el-input>
        </el-form-item>
        <el-form-item label="URL">
          <el-input type="text"></el-input>
        </el-form-item>
        <el-form-item label="权限说明">
          <el-input type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible=false">取消</el-button>
          <el-button type="primary" @click="dialogVisible=false">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { Plus } from '@element-plus/icons-vue'
import { ElMessageBox } from 'element-plus'
export default {
  data() {
    return {
      Plus: Plus,
      // 当前页码
      pageCurrent: 1,
      // 页面数据量
      pageSize: 10,
      // 总数据条数
      total: 1000,
      // 表格数据
      tableData: [
        {id:1,name: '登录', URL: "/user/login", explain: '用户登录权限', createTime: '1254631636'},
        {id:1,name: '注册', URL: "/user/reg", explain: '用户注册权限', createTime: '1254631636'},
        {id:1,name: '获取用户信息', URL: "/user/getUserInfo", explain: '用户获取信息权限', createTime: '1254631636'},
      ],
      // 添加编辑弹窗
      dialogVisible: false,
    }
  },
  mounted() {
    let query = this.$route.query;
    this.pageCurrent = Number(query.page || 1);
    this.pageSize = Number(query.size || 10);
    this.getCommentList();
  },
  methods: {
    // 页码改变触发
    handleCurrentChange() {
      this.$router.push({
        path: "/right_manage",
        query: { page: this.pageCurrent, size: this.pageSize },
      });
      this.getCommentList();
    },
    // 获取数据
    getCommentList() {},
    // 删除
    deleteRow() {
      ElMessageBox.alert('确认删除','提示',)
    }
  },
}
</script>
<style scoped lang="scss">
.icon {
  width: 40px;
  height: 40px;
  img {
    width: 40px;
    height: 40px;
  }
}
</style>