<template>
  <div id="squareBanner" v-loading="loading">
    <section class="searchForm">
      <el-button type="primary" :icon="Plus" @click="openAdd">添加轮播图</el-button>
    </section>
    <section class="tableList">
      <el-table :data="tableData">
        <el-table-column prop="id" label="ID" width="80" />
        <el-table-column prop="sort" label="排序" width="80" />
        <el-table-column prop="right" label="图片">
          <template #default="scope">
            <div class="tableImg">
              <img :src="scope.row.image" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="right" label="跳转类型">
          <template #default="scope">
            {{(scope.row.pathType == -1 && '不跳转')||(scope.row.pathType == 0 && '产品')||(scope.row.pathType == 1 && '公众号')||(scope.row.pathType == 2 && '小程序')||(scope.row.pathType==3&&'内部跳转')}}
          </template>
        </el-table-column>
        <el-table-column prop="pathContent" label="跳转路径" width="300" />
        <el-table-column prop="createTime" label="创建时间" width="180" />
        <el-table-column fixed="right" label="操作" width="140">
          <template #default="scope">
            <el-button size="small" type="primary" @click="openModify(scope.row)">编辑</el-button>
            <el-button size="small" type="danger" @click="deleteRow(scope.row)" >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="pageCurrent"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </section>

    <!-- 添加轮播图 -->
    <el-dialog v-model="dialogVisible" width="500px" title="添加轮播图" draggable>
      <el-form v-model="formData" label-width="120px">
        <el-form-item label="排序">
          <el-input-number v-model="formData.sort"/>
        </el-form-item>
        <el-form-item label="跳转类型">
          <el-select v-model="formData.pathType">
            <el-option label="不跳转" :value="-1" />
            <el-option label="产品 " :value="0" />
            <el-option label="公众号 " :value="1" />
            <el-option label="小程序" :value="2" />
            <el-option label="内部跳转" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="跳转路径">
          <el-input v-model="formData.pathContent" placeholder="" />
        </el-form-item>
        <el-form-item label="图片">
<!--          <img width="200" :src="formData.image" alt="">-->
        </el-form-item>
        <el-form-item>
          <el-upload 
            :action="Get_url+'admin/file/uploadFile'"
            :show-file-list="false"
            :on-success="uploadSuccess"
            :headers="headers"
          >
<!--            <el-button type="primary">上传</el-button>-->
            <img v-if="this.formData.image" :src="this.formData.image" class="avatar" style="width:200px"/>
            <img v-else src="../../assets/bannerDefualt.png" class="avatar" style="width:200px"/>
          </el-upload>
          <div class="red">图片尺寸小于1MB</div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="addBanner">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { Plus } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import {getBannerList,InsertOrUpdateBanner} from "../../config/Api";
import {Get_url} from "../../config/Get_url";
import global_api from "@/config/Api";
export default {
  data() {
    return {
      Plus: Plus,
      // 当前页码
      pageCurrent: 1,
      // 页面数据量
      pageSize: 15,
      // 总数据条数
      total: 1,
      // 表格数据
      tableData: [],
      // 弹窗状态
      dialogVisible: false,
      // 弹窗数据
      formData: {},
      Get_url:Get_url(),
      headers:{
        id:localStorage.getItem('id'),
        token: localStorage.getItem('token')
      },
      isAdd: '',
    };
  },
  mounted() {
    let query = this.$route.query;
    this.pageCurrent = Number(query.page || 1);
    this.pageSize = Number(query.size || 15);
    this.getCommentList();
  },
  methods: {
    // 页码改变触发
    handleCurrentChange() {
      this.$router.push({
        path: "/square_banner",
        query: { page: this.pageCurrent, size: this.pageSize },
      });
      this.getCommentList();
    },
    // 获取数据
    getCommentList() {
      let param = {
        pageNo: this.pageCurrent,
        pageSize: this.pageSize,
        type: 1
      };
      this.loading = true;
      getBannerList({params: param}).then(res => {
        this.loading = false;
        this.tableData = res.result;
        this.total = res.data.totalCurrent;
      }, err => {
        this.loading = false;
        ElMessage.error(`获取轮播图失败：${err.msg}`)
      })
    },
    // 打开添加轮播图
    openAdd(){
      this.dialogVisible = true;
      this.formData = {
        bannerId: -1,
        associatedId:0,
        type: 1,
      };
      this.isAdd = true;
    },
    // 编辑
    openModify(row){
      this.formData = {
        sort: row.sort,
        pathType: row.pathType,
        associatedId:row.associatedId,
        pathContent: row.pathContent,
        image: row.image,
        bannerId: row.id,
        type: 1
      },
      this.dialogVisible = true;
      this.isAdd = false;
    },
    // 添加
    addBanner() {
      if(!this.formData.sort) {
        ElMessage.error('请输入排序');
        return ;
      }
      if(!this.formData.pathType&&this.formData.pathType!==0) {
        ElMessage.error('请选择类型');
        return ;
      }
      if(!this.formData.pathContent&&this.formData.pathType!=-1) {
        ElMessage.error('请输入跳转路径');
        return ;
      }
      if(!this.formData.image) {
        ElMessage.error('请上传图片');
        return ;
      }
      let param = {
        ...this.formData,
        content: ''
      }
      this.loading = true;
      InsertOrUpdateBanner(param).then(res => {
        this.loading = false;
        this.dialogVisible = false;
        this.getCommentList();
      },err => {
        this.loading = false;
        ElMessage.error(`${this.isAdd?'添加':'修改'}轮播图失败：${err.msg}`);
      })
    },
    // 上传图片
    uploadSuccess(e) {
      this.formData.image = e.msg;
    },
    // 删除
    deleteRow(row) {
      let param = {
        bannerId: row.id,
        type: 1
      }
      this.loading = true;
      global_api.DeleteBannerImage(param).then(res=>{
        this.loading = false;
        this.getCommentList();
      },err=>{
        this.loading = false;
        ElMessage.error(`删除失败：${err.msg}`)
      })
    },
  },
};
</script>
<style scoped lang="scss">
.tableImg {
  img {
    width: 80px;
    height: 80px;
  }
}
</style>