<template>
  <div class="Order_management" v-loading="loading">
    <section class="search_btn">
      <el-form v-model="formData" inline>
        <el-form-item label="订单号" class="search_items">
          <el-input v-model="formData.orderNumber"></el-input>
        </el-form-item>
        <el-form-item label="用户ID" class="search_items">
          <el-input v-model="formData.userId"></el-input>
        </el-form-item>
        <el-form-item label="用户昵称" class="search_items">
          <el-input v-model="formData.userName"></el-input>
        </el-form-item>
        <el-form-item label="订单状态" class="search_items">
          <el-select v-model="formData.shipState">
            <el-option label="未发货" :value="0"/>
            <el-option label="派送中" :value="1"/>
            <el-option label="已完成" :value="2"/>
          </el-select>
        </el-form-item>
        <el-form-item label="是否删除" class="search_items">
          <el-select v-model="formData.isDeleted	">
            <el-option label="未删除" :value="0"/>
            <el-option label="已删除" :value="1"/>
          </el-select>
        </el-form-item>
        <el-form-item label="是否评论" class="search_items">
          <el-select v-model="formData.isCommented	">
            <el-option label="未评论" :value="0"/>
            <el-option label="已评论" :value="1"/>
          </el-select>
        </el-form-item>
        <el-form-item label="收货电话" class="search_items">
          <el-input v-model="formData.userPhone"></el-input>
        </el-form-item>
        <el-form-item label="快递单号" class="search_items">
          <el-input v-model="formData.orderLogistics"></el-input>
        </el-form-item>
        <el-form-item class="search_items">
          <el-button type="primary" :icon="Search" @click="search">查询</el-button>
          <el-button type="primary" @click="clearSearch">清除</el-button>
          <el-button type="primary" @click="export_btn">导出</el-button>
          <el-button type="success" @click="openFileDialog">批量添加产品</el-button>
          <el-button type="primary" @click="openAddDialog">上传地址异常订单</el-button>
          <el-button type="primary" @click="openExtraGoods">额外发货</el-button>
        </el-form-item>
      </el-form>
    </section>

    <section class="tableList">
      <el-table :data="tableData">
        <el-table-column fixed prop="userWish.id" label="ID" width="80"/>
        <!-- 用户ID -->
        <el-table-column fixed prop="user.id" label="用户ID" width="80">
          <template v-slot="scope">
            <el-tooltip content="单击复制" placement="top">
          <span class="copy-text" @click="copyText(scope.row.user.id)">
            {{ scope.row.user.id }}
          </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <!--        <el-table-column fixed prop="user.id" label="用户ID" width="80"/>-->
        <el-table-column prop="user.nickname" label="用户昵称" width="120"/>
        <!-- 心愿订单号 -->
        <el-table-column prop="userWish.orderNumber" label="心愿订单号" width="250">
          <template v-slot="scope">
            <el-tooltip content="单击复制" placement="top">
          <span class="copy-text" @click="copyText(scope.row.userWish.orderNumber)">
            {{ scope.row.userWish.orderNumber }}
          </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <!--        <el-table-column prop="userWish.orderNumber" label="心愿订单号" width="250"/>-->
        <el-table-column prop="wish.name" label="心愿详情" width="150"/>
        <el-table-column prop="box.name" label="心愿盒详情" width="150">
          <template #default="scope">
            {{ scope.row.box ? scope.row.box.name : '' }}
            <el-tag class="ml-2" v-if="scope.row.box&&scope.row.userWish.isDeleted===0" @click="gotoBox(scope.row)">
              修改
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="" label="物流信息" width="150">
          <template #default="scope">
            {{ scope.row.userWish.orderCarrier }}<br>{{ scope.row.userWish.orderLogistics }}
          </template>
        </el-table-column>
        <el-table-column prop="" label="收货地址">
          <template #default="scope">
            <div v-show="scope.row.userAddress">
              {{
                scope.row.userAddress ? (scope.row.userAddress.province + ' ' + scope.row.userAddress.city + ' ' + scope.row.userAddress.district + ' ' + scope.row.userAddress.extInfo) : ''
              }}
              <el-tag class="ml-2" v-if="scope.row.userWish.isDeleted===0" @click="this.edit_address(scope)">编辑
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="收货电话" width="150">
          <template #default="scope">
            {{ scope.row.userAddress ? scope.row.userAddress.phone : '' }}
          </template>
        </el-table-column>
        <el-table-column label="完成状态" width="80">
          <template #default="scope">
            {{ scope.row.userWish.isCommented ? '已评论' : '未评论' }}
          </template>
        </el-table-column>
        <el-table-column prop="" label="订单状态" width="100">
          <template #default="scope">
            {{
              scope.row.userWish.shipState === 0 ? '未发货' : (scope.row.userWish.shipState === 1 ? '派送中' : '已完成')
            }}
          </template>
        </el-table-column>
        <el-table-column prop="userWish.createTime" label="创建时间" width="100"/>
        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-tag class="ml-2" v-if="scope.row.userWish.isDeleted===0" @click="Is_detail(scope.row)">详情</el-tag>
            <el-tag class="ml-2" v-if="scope.row.userWish.isDeleted===0" type="danger" @click="Is_update(scope.row)">
              修改
            </el-tag>
            <el-tag class="ml-2" v-if="scope.row.userWish.isDeleted===0" type="success" @click="Is_delete(scope.row)">
              删除
            </el-tag>
            <el-tag class="ml-2" v-if="scope.row.userWish.isDeleted===1">已被删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
          v-model:currentPage="pageCurrent"
          v-model:page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
      />
    </section>

    <el-dialog v-model="update_address_dialog" title="修改手机号码" width="30%" draggable>
      <div class="search_items no_float">
        <span class="title"><span class="red">*</span>省：</span>
        <div>
          <el-input
              v-model="this.item_obj.userAddress.province"
              class="w-50 m-2"
              size="large"
              placeholder="请输入省"
          ></el-input>
        </div>
      </div>
      <div class="search_items no_float">
        <span class="title"><span class="red">*</span>市：</span>
        <div>
          <el-input
              v-model="this.item_obj.userAddress.city"
              class="w-50 m-2"
              size="large"
              placeholder="请输入市"
          ></el-input>
        </div>
      </div>
      <div class="search_items no_float">
        <span class="title"><span class="red">*</span>区：</span>
        <div>
          <el-input
              v-model="this.item_obj.userAddress.district"
              class="w-50 m-2"
              size="large"
              placeholder="请输入区"
          ></el-input>
        </div>
      </div>
      <div class="search_items no_float">
        <span class="title"><span class="red">*</span>详细地址：</span>
        <div>
          <el-input
              v-model="this.item_obj.userAddress.extInfo"
              class="w-50 m-2"
              size="large"
              placeholder="请输入详细地址"
          ></el-input>
        </div>
      </div>
      <div class="search_items no_float">
        <span class="title"><span class="red">*</span>收货人姓名：</span>
        <div>
          <el-input
              v-model="this.item_obj.userAddress.name"
              class="w-50 m-2"
              size="large"
              placeholder="请输入收货人姓名"
          ></el-input>
        </div>
      </div>
      <div class="search_items no_float">
        <span class="title"><span class="red">*</span>电话号码：</span>
        <div>
          <el-input
              v-model="this.item_obj.userAddress.phone"
              class="w-50 m-2"
              size="large"
              placeholder="请输入电话号码"
          ></el-input>
        </div>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="this.update_address_cancel">取消</el-button>
        <el-button type="primary" @click="update_address_confirm">确认</el-button>
      </span>
      </template>
    </el-dialog>

    <!--导出-->
    <el-dialog v-model="export_dialog" title="导出" draggable width="600px">
      <el-form>
        <el-form-item label="心愿id">
          <el-input v-model="export_wishId"/>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select v-model="export_shipState">
            <el-option label="未发货" :value="0"></el-option>
            <el-option label="已发货" :value="1"></el-option>
            <el-option label="已送达" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="export_dialog_cancel">取消</el-button>
        <el-button type="primary" @click="export_dialog_confirm">导出</el-button>
      </template>
    </el-dialog>

    <!-- 批量添加产品 -->
    <el-dialog v-model="fileDialog" title="批量添加产品" width="600px">
      <el-form>
        <el-form-item label="产品">
          <el-select v-model="fileData.productId" filterable @change="gitProjectList">
            <el-option v-for="(item,index) in productList" :key="item.id" :value="item.id"
                       :label="item.title"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目">
          <el-select v-model="fileData.projectIndex">
            <el-option v-for="(item,index) in projectList" :key="item.id" :value="index" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="心愿">
          <el-input v-model="fileData.wishId"></el-input>
        </el-form-item>
        <el-form-item label="文件">
          <el-upload
              ref="upload"
              multiple
              :limit="1"
              :on-change="handleExceed"
              :auto-upload="false"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          >
            <el-button type="primary">选择文件</el-button>

          </el-upload>
          <el-link style="margin-left:4px" @click="downfile('/xlsx/userId.xlsx','用户ID模板')">下载模板</el-link>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="fileDialog = false">取消</el-button>
        <el-button type="primary" @click="submitFile">确定</el-button>
      </template>
    </el-dialog>
    <!-- 结果显示 -->
    <el-dialog v-model="resDialog" title="批量添加产品" width="600px">
      <el-table :data="resData">
        <el-table-column label="用户id" prop="userId"></el-table-column>
        <el-table-column label="状态" prop="text"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 上传地址异常订单 -->
    <el-dialog v-model="addDialog" title="上传地址异常订单">
      <read-file @getdata="getAddDialog"></read-file>
      <el-link style="margin-left:4px" @click="downfile('/xlsx/地址异常订单.xlsx','地址异常订单')">下载模板</el-link>
      <template #footer>
        <el-button @click="addDialog=false">取消</el-button>
        <el-button type="primary" @click="addSubmit">确定</el-button>
      </template>
    </el-dialog>

    <!-- 额外发货 -->
    <el-dialog v-model="extraDialog" title="额外发货" width="700px">
      <el-form>
        <el-form-item label="选择文件">
          <readFile :fileshow="false" @getdata="getExtraGoodsData"/>
          <el-link href="/xlsx/额外派发产品模板.xlsx">下载模板</el-link>
        </el-form-item>
        <el-form-item label="产品设置">
          <el-table :data="ExtraGoodsList">
            <el-table-column label="产品ID" prop="productId" width="120"></el-table-column>
            <el-table-column label="产品名称" prop="productName"></el-table-column>
            <el-table-column label="发放总量" prop="number" width="120"></el-table-column>
            <el-table-column label="对应项目">
              <template #default="scope">
                <el-select v-model="scope.row.projectId">
                  <el-option label="无" :value="-1"></el-option>
                  <el-option v-for="item in projectObj[scope.row.productId]" :key="item.id" :label="item.name"
                             :value="item.id"></el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="发货订单">

        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="extraDialog=false">取消</el-button>
        <el-button type="primary" @click="submitExtra">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {MessageBox, Search} from '@element-plus/icons-vue'
import {ElMessage, ElMessageBox} from 'element-plus'
import {
  GetProductList,
  queryProjectListByProductId,
  QueryUserWishListByUserWishSearchVo,
  removeUserWishByUserIdAndWishId,
  userWishDownload
} from '../../config/Api';
import {addBoxProductAndLogisticBatch, modifyUserWishAddrNormStatusByUserWishIds} from '../../config/jsonapi';
import global_api from "@/config/Api";
import axios from "axios";
import {Get_url} from "@/config/Get_url";
import exportExcel from '@/utils/excel';
import {downfile} from '@/utils/downfile'
import readFile from '@/components/readfile.vue'

export default {
  name: 'Order_management',
  data() {
    return {
      Search: Search,    //图标
      MessageBox: MessageBox,//图标
      pageCurrent: 1,
      pageSize: 10,
      total: 1,
      formData: {},
      loading: false,
      tableData: [],
      update_address_dialog: false,
      order_status_value: '请选择',
      options: [],
      item_obj: {
        userAddress: {
          province: '',
          city: '',
          district: '',
          extInfo: '',
          name: '',
          phone: ''
        },
        userWish: {
          id: ''
        }
      },
      export_dialog: false,             //导出弹框
      export_wishId: '',
      export_shipState: '',
      fileDialog: false,
      productList: [],
      fileData: {},
      projectList: [],
      resData: [],
      resDialog: false,
      addDialog: false,
      extraDialog: false,
      ExtraGoodsList: [],
      projectObj: {},
    }
  },
  components: {readFile},
  mounted() {
    let query = this.$route.query;
    this.pageCurrent = Number(query.page || 1);
    this.pageSize = Number(query.size || 10);
    this.getList();
  },
  methods: {
    downfile(href, name) {
      downfile(href, name)
    },
    export_btn() {    //导出按钮
      this.export_wishId = ''
      this.export_shipState = ''
      this.export_dialog = true
    },

    export_dialog_confirm() {   //确定导出
      this.loading = true
      let param = {
        wishId: this.export_wishId,
        shipState: this.export_shipState
      }
      userWishDownload({params: param}).then((res) => {
        this.loading = false
        console.log(res)
        console.log(11111)
        if (res?.data) {
          let fileURL = window.URL.createObjectURL(new Blob([res.data]));
          let fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'file.xlsx');
          document.body.appendChild(fileLink);

          fileLink.click();
        } else {
          ElMessage.error(`获取数据失败：${err.msg}`)
        }

      }, err => {
        this.loading = false
        console.log(222)
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },

    export_dialog_cancel() {     //取消导出
      this.export_wishId = null
      this.export_shipState = null
      this.export_dialog = false
    },

    update_address_cancel() {
      this.update_address_dialog = false
    },

    update_address_confirm() {
      let param = {
        addressId: this.item_obj.userAddress.id,
        province: this.item_obj.userAddress.province,
        city: this.item_obj.userAddress.city,
        district: this.item_obj.userAddress.district,
        extInfo: this.item_obj.userAddress.extInfo,
        name: this.item_obj.userAddress.name,
        phone: this.item_obj.userAddress.phone,
        userWishId: this.item_obj.userWish.id,
      }
      global_api.ModifyUserAddressByAddressId(param).then((res) => {
        if (res.code != 0) {
          ElMessage.error('通过用户地址id修改用户地址')
        }
        this.update_address_dialog = false
        ElMessage({
          type: 'success',
          message: '修改成功',
        })
        this.getList();
        // console.log(res)
      })
    },

    edit_address(scope) {
      this.item_obj = scope.row
      this.update_address_dialog = true
      // console.log(scope.row)
    },
    // 上一页下一页
    handleCurrentChange() {
      this.$router.push({
        path: '/Order_management',
        query: {page: this.pageCurrent, size: this.pageSize}
      })
      this.getList();
    },
    // 获取列表
    getList() {
      let param = {
        cunPage: this.pageCurrent,
        pageSize: this.pageSize,
        ...this.formData,
      };
      this.loading = true;
      QueryUserWishListByUserWishSearchVo(param).then(res => {
        this.loading = false;
        this.tableData = res.result;
        this.total = res.data.totalCount;
        console.log(this.tableData)
      }, err => {
        this.loading = false;
        ElMessage.error(`获取心愿订单失败：${err.msg}`)
      })
    },
    // 搜索
    search() {
      this.pageCurrent = 1;
      this.getList();
    },
    // 清除
    clearSearch() {
      this.formData = {};
      this.pageCurrent = 1;
      this.getList();
    },
    Is_detail(row) {    //详情
      this.$router.push({
        path: '/Order_management/detail',
        query: {id: row.userWish.id, b: `${this.pageCurrent}_${this.pageSize}`}
      })
    },
    Is_update(row) {      //修改
      sessionStorage.setItem('order', JSON.stringify(row))
      this.$router.push('/Order_management/update')
    },
    Is_delete(row) {           //是否删除
      ElMessageBox.confirm(
          '是否删除?',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        console.log(row)
        let param = {
          userId: row.user.id,
          wishId: row.wish.id,
        }
        this.loading = true;
        removeUserWishByUserIdAndWishId(param).then(res => {
          this.loading = false;
          this.getList();
          ElMessage.success('删除成功');
        }, err => {
          this.loading = false;
          ElMessage.error('删除失败');
        })
        // let param = {
        //   userWishId:row.userWish.id,
        // }
        // this.loading = true;
        // RemoveUserWishByUserWishId(param).then(res => {
        //   this.loading = false;
        //   this.getList();
        //   ElMessage({
        //     type: 'success',
        //     message: '已删除',
        //   })
        // },err => {
        //   this.loading = false;
        //   ElMessage.error(`删除失败：${err.msg}`);
        // })

      })
    },
    gotoBox(row) {
      sessionStorage.setItem('wishBox', JSON.stringify(row.box))
      this.$router.push({
        path: '/Order_management/edit',
        query: {type: 'edit'}
      })
    },
    // 打开批量添加产品弹窗
    openFileDialog() {
      this.fileDialog = true;
      this.fileData = {
        productId: '',
        projectIndex: '',
        wishId: ''
      };

      GetProductList().then(res => {
        this.productList = res.result;
        this.$refs.upload.clearFiles();
      }, err => {
        ElMessageBox.error('获取产品列表失败')
      })
    },
    // 选择文件
    handleExceed(files) {
      console.log(files)
      let upload = this.$refs.upload;
      upload.clearFiles();
      upload.uploadFiles = [files];
    },
    // 获取项目列表
    gitProjectList() {
      let param = {
        productId: this.fileData.productId
      };
      queryProjectListByProductId(param).then(res => {
        this.projectList = res.result;
        this.fileData.projectIndex = 0;
      })
    },
    // 确定批量添加产品
    submitFile() {
      if (!this.fileData.productId) return ElMessage.error('请选择产品');
      if (!this.projectList[this.fileData.projectIndex]) return ElMessage.error('请选择项目');
      if (!this.fileData.wishId) return ElMessage.error('请输入心愿id');
      if (!this.$refs.upload.uploadFiles[0]?.raw) return ElMessage.error('请选择文件');

      let fd = new FormData();
      fd.append('file', this.$refs.upload.uploadFiles[0].raw);
      fd.append('productId', this.fileData.productId);
      fd.append('wishId', this.fileData.wishId);
      fd.append('projectId', this.projectList[this.fileData.projectIndex].id);
      this.loading = true;
      axios({
        method: 'POST',
        url: `${Get_url()}admin/import/file/addBoxProductBatch`,
        data: fd,
        headers: {
          token: localStorage.getItem('token'),
          id: localStorage.getItem('id')
        }
      }).then(res => {
        this.loading = false;
        if (res.data.code !== 0) return ElMessage.error('批量添加产品失败')
        let resData = [];
        let list = [];
        for (let i in res.data.result) {
          console.log(i)
          let text = '';
          switch (i) {
            case 'fail':
              text = '失败：无订单';
              break;
            case 'fail1':
              text = '失败：盒子内产品添加失败';
              break;
            case 'fail2':
              text = '失败：盒子修改失败';
              break;
            case 'fail3':
              text = '失败：修改评论状态失败';
              break;
            case 'fail4':
              text = '失败：新建盒子失败';
              break;
            case 'fail5':
              text = '失败：修改盒子至发货状态失败';
              break;
            case 'fail6':
              text = '没有指定订单，加入往期订单';
              break;
            case 'success':
              text = '成功';
              break;
          }
          res.data.result[i].map(item => {
            resData.push({userId: item, text: text})
            list.push({
              "userId": item,
              "状态": text
            })
          })
        }
        this.resData = resData;
        this.fileDialog = false;
        this.resDialog = true;
        ElMessage.success('批量添加产品成功')
        // 导出表格
        exportExcel.exportExcel(list, '批量添加产品结果')
      })
    },

    // 地址异常订单
    openAddDialog() {
      this.addDialog = true;
      this.wishOrderIdArr = [];
    },
    getAddDialog(data) {
      console.log(data);
      let wishOrderIdArr = [];
      for (let i in data) {
        wishOrderIdArr.push(data[i].心愿订单号);
      }
      this.wishOrderIdArr = wishOrderIdArr;
    },
    addSubmit() {
      if (this.wishOrderIdArr.length) {
        modifyUserWishAddrNormStatusByUserWishIds({userWishOrderNumbers: this.wishOrderIdArr}).then(res => {
          ElMessage.success('成功');
          this.addDialog = false;
        }, err => {
          ElMessage.error('失败')
        })
      } else {
        ElMessage.error('没有数据')
      }
    },

    /**
     * 额外发货
     */
    // 打开额外发货弹窗
    openExtraGoods() {
      this.extraDialog = true;
      this.extraData = [];
    },
    // 处理产品数据
    getExtraGoodsData(data) {
      console.log(data);
      let arr = [];
      for (let i in data) {
        arr[data[i].产品ID] = {
          productId: data[i].产品ID,
          productName: data[i].产品名称,
          number: (arr[data[i].产品ID]?.number || 0) + parseInt(data[i].数量),
          projectId: -1
        };
      }
      let index = 0;
      while (index < arr.length) {
        if (!arr[index]) {
          arr.splice(index, 1);
        } else {
          this.queryProjectListByProductId(arr[index].productId)
          index++;
        }
      }
      console.log(arr);
      this.ExtraGoodsList = arr;
      this.extraData = data;
    },
    // 根据产品id获取项目信息
    queryProjectListByProductId(id) {
      let param = {
        productId: id
      };
      queryProjectListByProductId(param).then(res => {
        this.projectObj[id] = res.result;
      })
    },
    // 额外发货
    submitExtra() {
      ElMessageBox.confirm(
          '是否确认额外发货？',
          '操作提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'info',
          }
      )
          .then(() => {
            let param = {};
            let addBoxProducts = [];
            let productsObj = {};
            for (let i in this.extraData) {
              for (let n in this.ExtraGoodsList) {
                if (this.extraData[i].产品ID === this.ExtraGoodsList[n].productId) {
                  if (this.ExtraGoodsList[n].projectId == -1) return ElMessage.error('请选择产品的项目');
                  this.extraData[i].projectId = this.ExtraGoodsList[n].projectId;
                }
              }
              if (productsObj[this.extraData[i].心愿订单]) {
                productsObj[this.extraData[i].心愿订单].push({
                  productId: this.extraData[i].产品ID,
                  productName: this.extraData[i].产品名称,
                  projectId: this.extraData[i].projectId
                })
              } else {
                productsObj[this.extraData[i].心愿订单] = [{
                  productId: this.extraData[i].产品ID,
                  productName: this.extraData[i].产品名称,
                  projectId: this.extraData[i].projectId
                }]
              }
            }
            let o = {};
            for (let i in this.extraData) {
              o[this.extraData[i].心愿订单] = this.extraData[i]
            }
            for (let i in o) {
              addBoxProducts.push({
                logisticId: o[i].快递单号,
                orderId: o[i].心愿订单,
                productCarrier: o[i].快递公司,
                products: productsObj[o[i].心愿订单]
              })
            }
            console.log(addBoxProducts);
            if (!addBoxProducts.length) ElMessage.error('没有数据')
            addBoxProductAndLogisticBatch({addBoxProducts}).then(res => {
              this.extraDialog = false;
              this.getList();
            }, err => {
              ElMessage.error(err.msg)
            })
          })
          .catch(() => {
            ElMessage.warning('已取消额外发货');
          })
    },
    copyText(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$message({
          message: '复制成功！',
          type: 'success'
        });
      }).catch(() => {
        this.$message({
          message: '复制失败！',
          type: 'error'
        });
      });
    },
  },
}
</script>
<style scoped lang="scss">
.Order_management {
  margin-top: 20px;
}

.no_float {
  float: none !important;
}

.title {
  width: 90px;
  text-align: right;
}

.copy-text {
  cursor: pointer; /* 鼠标悬停时变成小手 */
  //color: #409EFF; /* 显示为蓝色 */
}

.copy-text:hover {
  text-decoration: underline; /* 悬停时下划线 */
}
</style>
