<template>
  <div class="Variety_good_things" v-loading="loading">
    <section class="search_btn">
      <div class="search_items">
        <el-button size="small" type="primary" @click="search">刷新</el-button>
        <el-button size="small" type="primary" @click="syncProductShopCommodityList">同步数据</el-button>
      </div>
    </section>
    <section class="tableList">
      <el-table :data="tableData">
        <el-table-column prop="spuId" label="spuID"/>
        <el-table-column prop="productSpu.productIds" label="产品ID"/>
        <el-table-column prop="title" label="产品名称"/>
        <el-table-column label="排序">
          <template #default="scope">
            {{scope.row.sort}}
            <el-button size="small" @click="openSort(scope.row)">修改</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="productName" label="价格">
          <template #default="scope">
            {{scope.row.minPrice/100}}
          </template>
        </el-table-column>
        <el-table-column prop="productSpu.coin" label="消耗心愿币数量"/>
        <el-table-column prop="title" label="商品名称"/>
<!--        <el-table-column prop="headImg" label="图片" width="300">-->
<!--          <template #default="scope">-->
<!--            <img :src="item" v-for="(item,index) in (scope.row.headImg?JSON.parse(scope.row.headImg):'')" class="image">-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column prop="" label="高频词">
          <template #default="scope">
            <div v-for="(item,index) in (scope.row.productSpu?.productTag?JSON.parse(scope.row.productSpu.productTag):'')">
              {{item.name}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="上下架">
          <template #default="scope">
            {{scope.row.status==5?'上架':''}}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template #default="scope">
            <el-tag class="ml-2" @click="edit(scope.row)">编辑</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
          v-model:currentPage="cunPage"
          v-model:page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="totalCount"
          @current-change="Current_change"
      />
    </section>

    <el-dialog v-model="variety_dialog" title="编辑" width="600px">
      <el-form>
        <el-form-item label="消耗心愿币数量：">
          <el-input v-model="item_obj.coin" placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="对应产品：">
          <el-select ref="select" v-model="item_obj.productId" filterable multiple placeholder="请选择" @change="selectChange">
            <el-option v-for="item in product_options" :key="item.id" :label="item.title" :value="item.id+''"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="item.isSelected" :key="item.name" :label="item.name" size="large" v-for="(item,index) in this.GPC_options">
            <div>
              <span v-show="item.name">{{item.name}}</span>
            </div>
          </el-checkbox>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
        <el-button type="primary" @click="DeleteProductSpu">删除对应关系</el-button>
      </template>
    </el-dialog>

    <!-- 修改排序 -->
    <el-dialog v-model="sortDialog" title="修改排序" width="600px">
      <el-form>
        <el-form-item label="排序">
          <el-input-number v-model="sort"></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="sortDialog=false">取消</el-button>
        <el-button type="primary" @click="modifyProductShopCommoditySortBySpuId">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { Plus } from '@element-plus/icons-vue'
import {ElMessage, ElMessageBox} from 'element-plus'
import global_api,{syncProductShopCommodityList,modifyProductShopCommoditySortBySpuId} from "@/config/Api";

let optionObj = {};
let optionArr = [];
export default {
  data() {
    return {
      Plus,
      // 当前页码
      cunPage: 1,
      // 页面数据量
      pageSize: 10,
      // 总数据条数
      totalCount: 1,
      // 表格数据
      tableData: [],
      loading: false,
      variety_dialog:false,
      product_options:[],
      GPC_options:[],
      item_obj:{},
      sort:0,
      sortDialog:false,
      productShopId: '',
    }
  },
  mounted() {
    this.GueryVarietyShopList()
    this.GetProductList()
  },
  methods: {
    // 打开排序弹窗
    openSort(row){
      this.sortDialog = true;
      this.sort = row.sort;
      this.productShopId = row.id;
    },
    // 修改排序
    modifyProductShopCommoditySortBySpuId(){
      let param = {
        productShopId: this.productShopId,
        sort: this.sort
      }
      this.loading = true;
      modifyProductShopCommoditySortBySpuId(param).then(res=>{
        this.loading = false;
        this.sortDialog=false;
        this.GueryVarietyShopList()
      },err=>{
        this.loading = false;
        ElMessage.error('修改失败')
      })
    },


    syncProductShopCommodityList(){
      this.loading = true
      syncProductShopCommodityList().then(res=>{
        this.loading = false
        ElMessage({
          type: 'success',
          message: '同步数据成功',
        })
        this.GueryVarietyShopList()
      },err=>{
        this.loading = false;
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },

    confirm(){    //确定
      let param = {
        productIds:this.item_obj.productId.join(','),
        spuId:this.item_obj.spuId,
        coin:this.item_obj.coin
      }
      let gpc_list = []
      for (let i=0;i<this.GPC_options.length;i++){
        if(this.GPC_options[i].isSelected){
          gpc_list.push(this.GPC_options[i])
        }
      }
      param.productTag = JSON.stringify(gpc_list)
      // console.log(gpc_list)
      global_api.InsertOrUpdateProductSpu(param).then((res)=>{
        this.variety_dialog = false
        this.GueryVarietyShopList()
        this.GetProductList()
        ElMessage({
          type: 'success',
          message: '成功',
        })
      },err =>{
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },

    search(){
      this.GueryVarietyShopList()
      this.GetProductList()
    },

    cancel(){      //取消
      this.variety_dialog = false
      this.GueryVarietyShopList()
      this.GetProductList()
    },

    edit(row){
      console.log(row)

      this.item_obj = row;
      this.item_obj.coin = row.coin || 0;
      this.item_obj.productId = this.item_obj.productSpu?.productIds?this.item_obj.productSpu.productIds.split(','):[];
      this.item_obj.productTag = row.productSpu?JSON.parse(row.productSpu.productTag):[];
      this.GPC_options = [];
      if(this.item_obj.productIds){
        this.selectChange();
      }
      this.variety_dialog = true
    },

    //删除杂货铺关联产品接口
    DeleteProductSpu(){
      let param = {
        spuId:this.item_obj.spuId
      }
      global_api.DeleteProductSpu(param).then((res)=>{
        this.variety_dialog = false
        this.GueryVarietyShopList()
        this.GetProductList()
      },err =>{
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },

    // 获取杂货铺商品列表
    GueryVarietyShopList(){
      this.loading = true
      let param = {
        cunPage:this.cunPage,
        pageSize:this.pageSize,
        needEditSpu:0,
        status:5
      }
      global_api.GueryVarietyShopList(param).then((res)=>{
        this.loading = false
        this.totalCount = res.data.totalCount
        this.tableData = res.result
        console.log(res)
      },err =>{
        this.loading = false;
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },
    // 获取产品列表(下拉框)
    GetProductList(){
      global_api.GetProductList().then((res)=>{
        this.product_options = res.result
        // console.log(res)
      },err =>{
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },
    // 选择产品
    selectChange(){
      optionArr = [];
      let ids = this.item_obj.productId;
      for(let i in ids){
        console.log(ids[i])
        if(!optionObj[ids[i]]){
          this.GetProductTagByProductId(ids[i])
        }
      }
      this.getGPCoption();
    },
    //通过产品id查询高频词列表
    GetProductTagByProductId(id){
       let param = {
         productId: id
       }
      global_api.GetProductTagByProductId(param).then((res)=>{
        // console.log(res.result)
        if(!res.result){
          return optionObj[id] = [];
        }
        optionObj[id] = JSON.parse(res.result);

        this.getGPCoption();
        // console.log(JSON.parse(res.result))
      },err =>{
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },
    // 确定标签数据
    getGPCoption(){
      let GPC_options = [];
      let optionObjCopy = JSON.parse(JSON.stringify(optionObj))
      let ids = this.item_obj.productId;
      for(let i in ids){
        if(optionObjCopy[ids[i]]){
          GPC_options.push(...optionObjCopy[ids[i]])
        }
      }

      let productTag = this.item_obj.productTag
      for(let i = 0; i < productTag.length; i ++){
        for(let n = 0; n < GPC_options.length; n ++){
          if(GPC_options[n].name == productTag[i].name){
            GPC_options[n].isSelected = true;
            break ;
          }
        }
      }
      this.GPC_options = GPC_options;
    },

    // 页码改变触发
    Current_change(number){
      this.cunPage = number
      this.GueryVarietyShopList()
    },
    // 清除焦点
    clearBlur(){
      console.log(this.$refs.select.$refs.input)
      this.$refs.select.$refs.input.blur();
    }


  },
}
</script>
<style scoped lang="scss">
.Variety_good_things{
  margin-top: 20px;
  .image{
    width: 100px;
    padding: 10px;
  }
}
</style>