<template>
  <div class="paylist" v-loading="loading">
    <section class="searchForm">
      <el-form inline>
        <el-form-item label="订单号">
          <el-input v-model="formData.outTradeNo"></el-input>
        </el-form-item>
        <el-form-item label="用户id">
          <el-input v-model="formData.userId"></el-input>
        </el-form-item>
        <el-form-item label="支付状态">
          <el-select v-model="formData.tradeState">
            <el-option label="SUCCESS" value="SUCCESS"></el-option>
            <el-option label="NOTPAY" value="NOTPAY"></el-option>
            <el-option label="CLOSED" value="CLOSED"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getList" type="primary" >搜索</el-button>
          <el-button @click="()=>{formData={};getList()}">清除</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="list">
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="userId" prop="userId"></el-table-column>
        <el-table-column label="昵称" prop="nickname"></el-table-column>
        <el-table-column label="订单号" prop="outTradeNo"></el-table-column>
        <el-table-column label="金额" prop="goodsPrice"></el-table-column>
        <el-table-column label="数量" prop="goodsAmount"></el-table-column>
        <el-table-column label="总价">
          <template #default='scoped'>
            {{scoped.row.goodsPrice*scoped.row.goodsAmount}}
          </template>
        </el-table-column>
        <el-table-column label="商品id" prop="goodsId"></el-table-column>
        <el-table-column label="支付状态" prop="tradeState"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="page"
        :page-size="20"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="getList"
      />
    </section>
  </div>
</template>
<script>
import {queryGoodsOrderList} from "../../../config/jsonapi";
export default {
  data() {
    return {
      page: 1,
      total: 0,
      loading: false,
      list: [],
      formData: {},
    }
  },
  mounted() {
    this.page = 1;
    this.getList();
  },
  methods: {
    getList(){
      this.loading = true;
      let param = {
        cunPage: this.page,
        pageSize: 20,
        ...this.formData,
      };
      queryGoodsOrderList(param).then(res=>{
        this.loading = false;
        this.list = res.result;
        this.total = res.data.totalCount;
      },err=>{
        this.loading = false;
        this.list = [];
      })
    }
  }
}
</script>
<style scoped>

</style>