<template>
  <div v-loading.fullscreen.lock="loading">
    <section class="searchForm">
      <el-form>
        <el-form-item>
          <el-button type="primary" @click="openDialog">添加</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-tabs v-model="activeName" @tab-change="handleClick">
        <el-tab-pane label="提示信息（文字）" name="0">
          <el-table :data="tableData">
            <el-table-column label="id" prop="id" width="80"/>
            <el-table-column label="类别" prop="kvKey" width="300"/>
            <el-table-column label="值" prop="kvValue"/>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="图片链接" name="1">
          <el-table :data="tableData">
            <el-table-column label="id" prop="id" width="80"/>
            <el-table-column label="类别" prop="kvKey" width="300"/>
            <el-table-column label="值" prop="kvValue">
              <template #default="scope">
                <img :src="scope.row.kvValue" alt="" style="width:100px">
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button type="primary" size="small" @click="openDialog(scope.row)">修改</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <el-tab-pane label="html链接" name="2">
          <el-table :data="tableData">
            <el-table-column label="id" prop="id" width="80"/>
            <el-table-column label="类别" prop="kvKey" width="300"/>
            <el-table-column label="值">
              <template #default="scope">
                <el-button type="primary" size="small" @click="openDialog(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

        <el-tab-pane label="数值（系数）" name="3">
          <el-table :data="tableData">
            <el-table-column label="id" prop="id" width="80"/>
            <el-table-column label="类别" prop="kvKey" width="300"/>
            <el-table-column label="值" prop="kvValue">
              <template #default="scope">
                {{
                  ((scope.row.id === 128 || scope.row.id === 129) && new Date(Number(scope.row.kvValue)).toLocaleString()) || scope.row.kvValue
                }}
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button size="small" type="primary" @click="openDialog(scope.row)">修改</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="表情" name="4">
          <el-table :data="tableData">
            <el-table-column label="id" prop="id" width="80"/>
            <el-table-column label="类别" prop="kvKey" width="300"/>
            <el-table-column label="值" prop="kvValue">
              <template #default="scope">
                <img :src="scope.row.kvValue" alt="" style="width:100px">
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="开关" name="5">
          <el-table :data="tableData">
            <el-table-column label="id" prop="id" width="80"/>
            <el-table-column label="类别" prop="kvKey" width="300"/>
            <el-table-column label="值" prop="kvValue">
              <template #default="scope">
                <el-switch v-model="scope.row.value"/>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="订阅推送" name="6">
          <el-table :data="tableData">
            <el-table-column label="id" prop="id"></el-table-column>
            <el-table-column label="埋点" prop="kvKey"></el-table-column>
            <el-table-column label="模板" prop="kvValueName"></el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button size="small" type="primary" @click="openDialog(scope.row)">修改</el-button>
                <el-button size="small" type="denger">清空</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="基础问卷" name="7">
          <el-table :data="tableData">
            <el-table-column label="id" prop="id"></el-table-column>
            <el-table-column label="名称" prop="kvKey"></el-table-column>
            <el-table-column label="对应关系" prop="kvValue">

            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-button size="small" type="primary" @click="openDialog(scope.row)">修改</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </section>
    <section class="pagination">
      <el-pagination
          v-model:currentPage="pageCurrent"
          v-model:page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="Current_change"
      />
    </section>

    <!-- 添加数据字典 -->
    <el-dialog v-model="dialog" title="编辑数据字典">
      <el-form label-width="100px" label-suffix=":">
        <el-form-item label="字段名">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="formData.type">
            <el-option label="提示信息（文字）" value="0"></el-option>
            <el-option label="图片链接" value="1"></el-option>
            <el-option label="html链接" value="2"></el-option>
            <el-option label="数值（系数）" value="3"></el-option>
            <el-option label="表情" value="4"></el-option>
            <el-option label="开关" value="5"></el-option>
            <el-option label="订阅推送" value="6"></el-option>
            <el-option label="基础问卷" value="7"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="值" v-if="formData.type==='0'">
          <el-input v-model="formData.value"></el-input>
        </el-form-item>

        <el-form-item label="值" v-if="formData.type === '2'">
          <editor :key="editorKey" :text="formData.value"></editor>
        </el-form-item>


        <el-form-item label="数值系数" v-if="formData.type==='3'">
          <el-input-number v-model="formData.value" v-if="formData.id!==128&&formData.id!==129"></el-input-number>
          <el-date-picker v-model="formData.value" v-if="formData.id===128||formData.id===129" type="datetime"
                          format="YYYY/MM/DD hh:mm:ss" value-format="x"/>
        </el-form-item>
        <el-form-item label="选择模板" v-if="formData.type === '6'">
          <el-select v-model="formData.value" multiple>
            <el-option :label="item.title" :value="item.priTmplId" v-for="item in templateList"
                       :key="item.priTmplId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择图片" v-if="formData.type === '1'">
          <el-upload
              :action="Get_url+'admin/file/uploadFile'"
              :headers="{token:this.token,id:this.id}"
              :show-file-list="false"
              :on-success="AvatarSuccess"
          >
            <img v-if="formData.value" :src="formData.value" class="avatar"/>
            <img v-else src="../../assets/bannerDefualt.png" class="avatar"/>
          </el-upload>
        </el-form-item>
        <el-form-item label="问卷选项id" v-if="formData.type === '7'">
          <el-input v-model="formData.optionId"></el-input>
        </el-form-item>
        <el-form-item label="基础问卷id" v-if="formData.type === '7'">
          <el-input v-model="formData.serveyId"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialog = false">取消</el-button>
        <el-button v-if="formData.id" type="primary" @click="modifyKvMapByKvMapId">修改</el-button>
        <el-button v-else type="primary" @click="addKvMap">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import global_api, {queryTemplateList} from '@/config/Api'
import {ElMessage} from 'element-plus'
import {Get_url} from '../../config/Get_url'
import editor from '@/components/editor.vue'


export default {
  data() {
    return {
      token: localStorage.getItem('token'),
      id: localStorage.getItem('id'),
      pageCurrent: 1,
      pageSize: 10,
      total: 1,
      loading: false,
      activeName: '0',
      tableData: [],
      dialog: false,
      formData: {},
      templateList: [],
      Get_url: Get_url(),
      editDialog: false,
      editorKey: 0,
    }
  },
  components: {
    editor,
  },
  mounted() {
    this.getData()
  },
  methods: {
    handleClick() {
      this.pageCurrent = 1
      this.getData()
    },
    getData() {
      this.loading = true
      let param = {
        cunPage: this.pageCurrent,
        pageSize: this.pageSize,
        type: this.activeName,
      }
      global_api.QueryKvMapListByType(param).then((res) => {
        let list = res.result
        if (this.activeName == '5') {
          list.forEach(element => {
            element.value = element.kvValue == 1 ? true : false
          })
        }
        this.tableData = list
        if (this.activeName == 6) {
          this.queryTemplateList()
        }


        this.total = res.data.totalCount
        this.loading = false
      }, err => {
        this.loading = false
        ElMessage.error(err.msg)
      })
    },
    // 上下页
    Current_change() {
      this.getData()
    },
    openDialog(item) {
      this.formData = {}
      this.editorKey = item.id // 或者其他唯一值
      if (item.type === 6) {
        this.formData = {
          name: item.kvKey,
          type: item.type + '',
          value: item.kvValue.split(','),
          id: item.id,
        }
      } else if (item.type === 1) {
        this.formData = {
          name: item.kvKey,
          type: item.type + '',
          value: item.kvValue,
          id: item.id,
        }
      } else if (item.type === 7) {
        console.log(Object.keys(JSON.parse(item.kvValue)))
        this.formData = {
          name: item.kvKey,
          type: item.type + '',
          optionId: Object.keys(JSON.parse(item.kvValue))[0],
          serveyId: JSON.parse(item.kvValue)[Object.keys(JSON.parse(item.kvValue))[0]],
          id: item.id,
        }
      } else if (item.type === 3) {
        this.formData = {
          name: item.kvKey,
          type: item.type + '',
          value: Number(item.kvValue),
          id: item.id,
        }
      } else if (item.type === 2) {
        this.formData = {
          name: item.kvKey,
          type: item.type + '',
          value: item.kvValue,
          id: item.id,
        }
      }
      this.dialog = true
    },
    // 获取官方模板
    queryTemplateList() {
      queryTemplateList().then(res => {
        let data = JSON.parse(res.result)

        if (this.tableData) {
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].kvValueName = []
          }
          for (let i = 0; i < this.tableData.length; i++) {
            for (let j = 0; j < data.length; j++) {
              if (this.tableData[i].kvValue.split(',').includes(data[j].priTmplId)) {
                this.tableData[i].kvValueName.push(data[j].title)
              }
            }
          }
        }


        this.templateList = data

      })
    },
    // 添加数据字典
    addKvMap() {
      if (!this.formData.name) return ElMessage.error('请输入字典名')
      if (!this.formData.type) return ElMessage.error('请选择类型')
      if (this.formData.type === '6' && !this.formData.value?.length) return ElMessage('请选择模板')
      if (this.formData.type === '1' && !this.formData.value) return ElMessage('请上传图片')
      if (this.formData.type === '3' && !this.formData.value) return ElMessage('请输入系数')
      if (this.formData.type === '7' && !this.formData.optionId) return ElMessage('请输入选项ID')
      if (this.formData.type === '7' && !this.formData.serveyId) return ElMessage('请输入问卷ID')
      if (this.formData.type === '0' && !this.formData.value) return ElMessage('请输入值')

      let param = {}
      param.kvKey = this.formData.name
      param.type = this.formData.type
      if (this.formData.type === '1') {
        param.kvValue = this.formData.value
      }
      if (this.formData.type === '3') {
        param.kvValue = this.formData.value
      }
      if (this.formData.type === '6') {
        param.kvValue = this.formData.value.join(',')
      }
      if (this.formData.type === '5') {
        param.kvValue = 0
      }
      if (this.formData.type === '7') {
        param.kvValue = JSON.stringify({[this.formData.optionId]: this.formData.serveyId})
      }
      if (this.formData.type === '0') {
        param.kvValue = this.formData.value
      }
      if (this.formData.type === '2') {
        param.kvValue = this.formData.value
      }
      this.loading = true
      global_api.AddKvMap(param).then(res => {
        this.loading = false
        this.getData()
        this.dialog = false
      }, err => {
        this.loading = false
        ElMessage.error('添加失败')
      })
    },
    // 修改数据字典
    modifyKvMapByKvMapId() {
      if (!this.formData.name) return ElMessage.error('请输入字典名')
      if (!this.formData.type) return ElMessage.error('请选择类型')
      if (this.formData.type === '6' && !this.formData.value?.length) return ElMessage('请选择模板')
      if (this.formData.type === '1' && !this.formData.value) return ElMessage('请上传图片')
      if (this.formData.type === '7' && !this.formData.optionId) return ElMessage('请输入选项ID')
      if (this.formData.type === '7' && !this.formData.serveyId) return ElMessage('请输入问卷ID')
      if (this.formData.type === '3' && !this.formData.value) return ElMessage('请输入数值')

      let param = {}
      param.kvMapId = this.formData.id
      param.kvKey = this.formData.name
      param.type = this.formData.type
      if (this.formData.type === '1') {
        param.kvValue = this.formData.value
      }
      if (this.formData.type === '6') {
        param.kvValue = this.formData.value.join(',')
      }
      if (this.formData.type === '7') {
        param.kvValue = JSON.stringify({[this.formData.optionId]: this.formData.serveyId})
      }
      if (this.formData.type === '3') {
        param.kvValue = this.formData.value
      }

      if (this.formData.type === '2') {
        param.kvValue = this.formData.value
      }
      this.loading = true
      global_api.modifyKvMapByKvMapId(param).then(res => {
        this.loading = false
        this.getData()
        this.dialog = false
      }, err => {
        this.loading = false
        ElMessage.error('修改失败')
      })
    },
    // 图片上传
    AvatarSuccess(response, uploadFile) {
      this.formData.value = uploadFile.response.msg
    },
  },
}
</script>
<style lang="scss" scoped>
.avatar {
  height: 100px;
}
</style>