<template>
  <div v-loading.fullscreen.lock="loading">
    <el-table :data="tableData">
      <el-table-column label="id" prop="id" width="80"></el-table-column>
      <el-table-column label="名称" prop="kvKey"></el-table-column>
      <el-table-column label="状态" >
        <template #default="scope">
          <el-switch v-model="scope.row.value" @change="modifyKvMapByKvMapId(scope)"/>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" prop="updateTime"></el-table-column>
    </el-table>
  </div>
</template>
<script>
import global_api from "@/config/Api";
import { ElMessage } from 'element-plus';
export default {
  data () {
    return {
      tableData: [],
      loading: false,
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      let param = {
        cunPage:1,
        pageSize:100,
        type:5,
      }
      global_api.QueryKvMapListByType(param).then((res)=>{
        if(res.code!=0){
          ElMessage.error('根据type获取数据字典列表接口错误')
        }else{
          let list = res.result;
          list.forEach(element => {
            element.value = element.kvValue == 1? true : false;
          });
          this.tableData = list
          console.log(this.tableData)
        }
        this.loading = false

      })
    },
    // 修改数据字典
    modifyKvMapByKvMapId(scope){
      this.loading = true;
      let param = {
        kvKey: scope.row.kvKey,
        kvMapId: scope.row.id,
        kvValue: scope.row.value?1:0,
        type: 5,
      }
      global_api.modifyKvMapByKvMapId(param).then((res) => {
        this.loading = false;
      },err => {
        this.tableData[scope.$index].value = !this.tableData[scope.$index].value;
        ElMessage.error('修改失败');
        this.loading = false;
      })
    }
  }
}
</script>
