<!-- @format -->

<template>
	<section class="searchForm">
		<el-form inline>
			<el-form-item>
				<el-button type="primary" @click="addNew">新建题目</el-button>
			</el-form-item>
			<el-form-item label="问卷显示样式">
				<el-radio-group v-model="initalQuestion.showStyleType" @change="modifyGradeTypeBySurveyId">
					<el-radio-button :label="1">卡片</el-radio-button>
					<el-radio-button :label="2">列表</el-radio-button>
				</el-radio-group>
			</el-form-item>
		</el-form>
	</section>
	<section class="tableList" v-loading.fullscreen.lock="fullscreenLoading">
		<el-table :data="tableList">
			<el-table-column prop="question.id" label="ID" width="100"></el-table-column>
			<el-table-column prop="question.sort" label="题目编号" width="100"></el-table-column>
			<el-table-column prop="question.name" label="题目名称"></el-table-column>
			<el-table-column prop="optionList.length" label="选项数量" width="100"></el-table-column>
			<el-table-column prop="questionType.name" label="题目类型" width="100"></el-table-column>
			<el-table-column prop="question.isActive" label="上下架" width="100">
				<template #default="scope">
					{{ scope.row.question.isActive ? "上架" : "下架" }}
				</template>
			</el-table-column>
			<el-table-column prop="question.isRequired" label="是否必答" width="100">
				<template #default="scope">
					{{ scope.row.question.isRequired ? "是" : "否" }}
				</template>
			</el-table-column>
			<el-table-column label="显示设置" width="100">
				<template #default="scope">
					<el-button type="primary" plain size="small"
						@click="queryQuestionShowByQuestionId(scope.row)">设置</el-button>
				</template>
			</el-table-column>
			<el-table-column label="用户回答" width="100">
				<template #default="scope">
					<el-button size="small" type="success" @click="gotoUserAnswer(scope.row)">查看</el-button>
				</template>
			</el-table-column>
			<el-table-column prop="question.createTime" label="添加时间" width="200"></el-table-column>
			<el-table-column label="操作" width="200" fixed="right">
				<template #default="scope">
					<el-button type="primary" size="small" @click="changeLine(scope.row)">{{ scope.row.question.isActive ==
						1 ? "下架" : "上架" }}</el-button>
					<el-button type="success" size="small" @click="modifyQuestion(scope.row)">编辑</el-button>
					<el-button type="danger" size="small" @click="remove(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
	</section>
	<el-dialog v-model="dialog" title="显示规则" width="500px">
		<p>选择题目</p>
		<el-select v-model="value" @change="selectChange" placeholder="显示题目" class="init" style="width: 100%">
			<el-option v-for="item in questionList" :key="item.question.id" :label="item.question.name"
				:value="item.question.id" />
		</el-select>
		<p>绑定选项</p>
		<el-checkbox-group v-model="selectId">
			<el-checkbox v-for="item in selectList" :key="item.id" :label="item.id">{{ item.name }}</el-checkbox>
		</el-checkbox-group>
		<p>逻辑类型</p>
		<el-select v-model="type">
			<el-option :value="0" label="或" />
			<el-option :value="1" label="与" />
		</el-select>

		<template #footer>
			<el-button type="primary" plain @click="dialog = false">取消</el-button>
			<el-button type="primary" v-if="!questionShowId" @click="addQuestionShowByQuestionId">确定</el-button>
			<el-button type="primary" v-if="questionShowId" @click="modifyQuestionShowByShowId">修改</el-button>
			<el-button type="danger" v-if="questionShowId" @click="removeQuestionShowByShowId">删除</el-button>
		</template>
	</el-dialog>
</template>
<script>
import {
	QuerySurveyInfoBySurveyTypeAndOriginId,
	UpdateQuestionShelfOrDelete,
	addQuestionShowByQuestionId,
	queryQuestionShowByQuestionId,
	modifyQuestionShowByShowId,
	removeQuestionShowByShowId,
	modifyGradeTypeBySurveyId
} from "../../config/Api";
import global_api from "@/config/Api";
import { ElMessage } from "element-plus";
export default {
	data() {
		return {
			initalQuestion: {},
			tableList: [],
			fullscreenLoading: false,
			dialog: false,

			selectList: [],
			selectId: [],
			type: 0,
			questionId: "",
			questionList: [],
			value: "",
			questionShowId: "",

		};
	},
	mounted() {
		this.QuerySurveyInfoBySurveyTypeAndOriginId();
	},
	methods: {
		// 获取初始问卷信息
		QuerySurveyInfoBySurveyTypeAndOriginId() {
			this.fullscreenLoading = true;
			let param = {
				originId: 0,
				surveyName: "初始问卷",
				surveyType: 3,
			};
			QuerySurveyInfoBySurveyTypeAndOriginId(param).then(
				res => {
					this.initalQuestion = res.result;
					this.getList();
				},
				err => {
					this.fullscreenLoading = false;
				}
			);
		},
		// 获取问卷题目
		getList() {
			this.fullscreenLoading = true;
			let param = {
				surveyId: this.initalQuestion.id,
				pageNo: 1,
				pageSize: 500,
			};
			global_api.GetQuestionList(param).then(
				res => {
					this.fullscreenLoading = false;
					this.tableList = res.result;
				},
				err => {
					this.fullscreenLoading = false;
					ElMessage.error(`获取失败:${err.msg}`);
				}
			);
		},
		// 上下架
		changeLine(row) {
			let param = {
				questionId: row.question.id,
				type: row.question.isActive ? 0 : 1,
				surveyId: row.question.surveyId,
			};
			this.fullscreenLoading = true;
			UpdateQuestionShelfOrDelete(param).then(
				res => {
					this.fullscreenLoading = false;
					this.getList();
				},
				err => {
					this.fullscreenLoading = false;
					ElMessage.error(`上下架失败:${err.msg}`);
				}
			);
		},
		// 编辑
		modifyQuestion(row) {
			sessionStorage.setItem("subject_item", JSON.stringify(row));
			this.$router.push({
				path: "/edit/question",
				query: { type: "edit", surveyId: this.initalQuestion.id, bt: 4, name: "初始问卷" },
			});
		},
		// 新建题目
		addNew() {
			this.$router.push({
				path: "/edit/question",
				query: { type: "add", surveyId: this.initalQuestion.id, bt: 4, name: "初始问卷" },
			});
		},
		// 删除题目
		remove(row) {
			let param = {
				questionId: row.question.id,
				type: 2,
				surveyId: row.question.surveyId,
			};
			this.fullscreenLoading = true;
			UpdateQuestionShelfOrDelete(param).then(
				res => {
					this.fullscreenLoading = false;
					this.getList();
				},
				err => {
					this.fullscreenLoading = false;
					ElMessage.error(`删除失败:${err.msg}`);
				}
			);
		},
		// 选择题目
		selectChange(v) {
			console.log(v);
			for (let i in this.tableList) {
				if (this.tableList[i].question.id == v) {
					this.selectList = this.tableList[i].optionList;
					return;
				}
			}
		},
		// 添加显示逻辑
		addQuestionShowByQuestionId() {
			if (!this.selectId?.length) {
				ElMessage.error("请选择选项");
				return;
			}
			if (!this.type && this.type != 0) {
				ElMessage.error("请选择逻辑泪类型");
				return;
			}
			let param = {
				questionId: this.questionId,
				selectId: this.selectId.join(","),
				type: this.type,
			};
			console.log(param);
			this.fullscreenLoading = true;
			addQuestionShowByQuestionId(param).then(
				res => {
					this.fullscreenLoading = false;
					this.dialog = false;
					this.getList();
				},
				err => {
					this.fullscreenLoading = false;
					ElMessage.error("添加失败");
				}
			);
		},
		// 查看显示规则
		queryQuestionShowByQuestionId(row) {
			let param = {
				questionId: row.question.id,
			};
			this.fullscreenLoading = true;
			queryQuestionShowByQuestionId(param).then(
				res => {
					this.fullscreenLoading = false;
					if (res.result) {
						let selectId = res.result.selectId.split(",");
						for (let i in selectId) {
							selectId[i] = Number(selectId[i]);
						}
						let questionList = [];
						for (let i in this.tableList) {
							for (let n in this.tableList[i].optionList) {
								if (this.tableList[i].optionList[n].id == selectId[0]) {
									this.value = this.tableList[i].question.id;
									this.selectList = this.tableList[i].optionList;
								}
							}
							if (this.tableList[i].question.sort < row.question.sort) {
								questionList.push(this.tableList[i]);
							}
						}

						this.questionList = questionList;
						this.selectId = selectId;
						this.type = res.result.type;
						this.questionShowId = res.result.id;
						this.dialog = true;
					} else {
						this.selectId = [];
						this.selectList = [];
						this.type = 0;
						this.value = "";
						this.questionShowId = "";

						this.questionId = row.question.id;

						let questionList = [];
						for (let i in this.tableList) {
							if (this.tableList[i].question.sort < row.question.sort) {
								questionList.push(this.tableList[i]);
							}
						}
						this.questionList = questionList;
						this.dialog = true;
					}
				},
				err => {
					this.fullscreenLoading = false;
					ElMessage.error("获取显示规则失败");
				}
			);
		},
		// 修改
		modifyQuestionShowByShowId() {
			if (!this.selectId?.length) {
				ElMessage.error("请选择选项");
				return;
			}
			if (!this.type && this.type != 0) {
				ElMessage.error("请选择逻辑泪类型");
				return;
			}
			let param = {
				questionShowId: this.questionShowId,
				selectId: this.selectId.join(","),
				type: this.type,
			};
			this.fullscreenLoading = true;
			modifyQuestionShowByShowId(param).then(
				res => {
					this.fullscreenLoading = false;
					this.dialog = false;
					this.getList();
				},
				err => {
					this.fullscreenLoading = false;
					ElMessage.error("修改失败");
				}
			);
		},
		// 删除
		removeQuestionShowByShowId() {
			let param = {
				questionShowId: this.questionShowId,
			};
			this.fullscreenLoading = true;
			removeQuestionShowByShowId(param).then(
				res => {
					this.fullscreenLoading = false;
					this.dialog = false;
					this.getList();
				},
				err => {
					this.fullscreenLoading = false;
					ElMessage.error("删除失败");
				}
			);
		},
		// 查看用户回答
		gotoUserAnswer(row) {
			console.log(row);
			localStorage.setItem("question", JSON.stringify(row));
			this.$router.push({ path: "/useranswer" });
		},

		// 修改问卷显示样式
		modifyGradeTypeBySurveyId(type) {
			console.log(type);
			this.loading = true;
			let param = {
				surveyId: this.initalQuestion.id,
				type: 1,
				showStyleType: type
			};
			modifyGradeTypeBySurveyId(param).then(
				res => {
					this.loading = false;
					this.QuerySurveyInfoBySurveyTypeAndOriginId();
				},
				err => {
					this.loading = false;
					ElMessage.error(err.msg);
				}
			);
		},
	},
};
</script>
<style lang="scss">
.init .el-input__inner {
	width: 100% !important;
}
</style>
