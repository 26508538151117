<template>
  <section class="Questionnaire" v-loading="loading">
    <div class="title">初始问卷</div>
    <div class="main">
      <div class="list" v-for="(item,index) in list" :key="item.id">
        <div class="subject">{{index+1}}.{{item.name}}{{item.questionType.name}}</div>
        <div class="result" v-for="(val,i) in item.answerList" :key="i">{{val}}</div>
      </div>
    </div>
  </section>
</template>

<script>

import {QuerySurveyWithQuestionAndOptionInfoByTypeAndOriginId} from "@/config/Api";
import {ElMessage} from "element-plus";

export default {
  name: 'Member_address',
  data(){
    return {
      userId:'',
      loading:false,
      list:[]
    }
  },
  mounted() {
    let {userId} = this.$route.query;
    this.userId = userId
    this.QuerySurveyWithQuestionAndOptionInfoByTypeAndOriginId()
  },
  methods: {
    QuerySurveyWithQuestionAndOptionInfoByTypeAndOriginId(){
      let param = {
        originId:0,
        type:3,
        userId:this.userId
      }
      this.loading = true;
      QuerySurveyWithQuestionAndOptionInfoByTypeAndOriginId(param).then(res => {
        this.loading = false;
        // console.log(res)
        if(res.result[0]?.questionWithTypeWithAnswerDTOS){
          let list = res.result[0].questionWithTypeWithAnswerDTOS;
          for(let i in list){
            if(list[i].answers.length){
              let val = [];
              list[i].answers.map(item => {
                for(let n in list[i].options){
                  if(item.optionId == list[i].options[n].id){
                    val.push(list[i].options[n].name);
                  }
                }

              })
              list[i].answerList = val;
            }
          }
          this.list = list
          // console.log(this.list)
        } else {
          this.list = [];
        }
      },err => {
        ElMessage.error(`获取信息失败`)
        this.loading = false;
        this.list = [];
      })
    }

  },
  
}
</script>
<style scoped lang="scss">
  .Questionnaire{
    margin-top: 20px;
    .title{
      font-size: 16px;
      font-weight: bold;
    }
    .main{
      margin-top: 10px;
    }
    .list{
      padding: 10px 0;
      box-sizing: border-box;
      border-bottom: 1px dotted #eee;
      .subject{
        font-size: 16px;
      }
      .result{
        color: #888;
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
</style>
