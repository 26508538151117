
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const exportExcel = {
    exportExcel(List,name) {
        let wopts = { bookType: 'xlsx', bookSST: true, type: 'binary' };
        let workBook = {SheetNames: ['1'],Sheets: {}, Props: {}};
        workBook.Sheets['1'] = XLSX.utils.json_to_sheet(List);
        console.log(name,'namename')
        let excelName = name +'.xlsx'
        FileSaver.saveAs(new Blob([exportExcel.changeData(XLSX.write(workBook, wopts))],
            { type: 'application/octet-stream' }), excelName) },
    changeData(s) { if (typeof ArrayBuffer !== 'undefined') {
        let buf = new ArrayBuffer(s.length);
        let view = new Uint8Array(buf);
        for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF; return buf; }else{
        let buf = new Array(s.length);
        for (let i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF;  return buf;} }
}
export default exportExcel