<template>
  <div class="goods_edit">
    <div class="main">
      <section class="search_btn">
        <div class="selected_items">
          <span class="title"><span class="red">*</span>产品名称：</span>
          <div>
            <el-input
              v-model="this.item_obj.title"
              class="w-50 m-2"
              size="large"
              placeholder="请输入产品名称"
            ></el-input>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>产品定价：</span>
          <div>
            <el-input
              step="1"
              :precision="2"
              v-model="this.item_obj.price"
              @change="checkPrice"
            ></el-input>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>品牌：</span>
          <div>
            <!--            <el-input v-model="this.item_obj.brandName"></el-input>-->
            <el-select
              v-model="this.item_obj.brandId"
              placeholder="请选择"
              allow-create
              filterable
            >
              <el-option
                v-for="item in brand_options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>标签分类：</span>
          <div>
            {{ this.item_obj.tagName }}
            <el-button typeof="primary" @click="$refs.tagclass.openTag()"
              >选择标签分类</el-button
            >
          </div>
          <tagclass ref="tagclass" @setTag="getTag"></tagclass>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>仓库ID：</span>
          <div>
            <el-input
              v-model="this.item_obj.pid"
              class="w-50 m-2"
              size="large"
              placeholder="请输入仓库ID"
            ></el-input>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>产品库存：</span>
          <div>
            <el-input-number
              v-model="this.item_obj.amount"
              class="w-50 m-2"
              size="large"
              placeholder="请输入产品库存"
            ></el-input-number>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>评论引用：</span>
          <div>
            <el-select
              v-model="this.item_obj.source"
              filterable
              placeholder="请选择"
              size="large"
            >
              <el-option
                v-for="item in this.source_options"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>可评论时间</span>
          <div>
            <el-input-number
              v-model="this.item_obj.allowCommentTime"
              class="w-50 m-2"
              size="large"
              placeholder="请输入可评论时间"
            ></el-input-number>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>是否需要评论：</span>
          <div>
            <el-select v-model="this.item_obj.requireComment">
              <el-option label="不需要评论" :value="0" />
              <el-option label="需要评论" :value="1" />
            </el-select>
          </div>
        </div>
        <div class="selected_items" v-if="item_obj.requireComment==1">
          <span class="title">前置限制：</span>
          <div>
            <el-button type="primary" size="small" @click="openDialog1">设置</el-button>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>评论是否可见：</span>
          <div>
            <el-select v-model="this.item_obj.isVisiable">
              <el-option label="不可见" :value="0" />
              <el-option label="可见" :value="1" />
            </el-select>
          </div>
        </div>
        <div class="start_items">
          <span class="title"><span class="red">*</span>产品简述：</span>
          <div class="textarea">
            <el-input
              v-model="this.item_obj.subtitle"
              type="textarea"
              :rows="10"
            />
          </div>
        </div>
        <div class="start_items">
          <span class="title"><span class="red">*</span>产品详情：</span>
          <div style="width: 700px;">
            <editor :text="item_obj.description" @change="getRole"></editor>
            <!-- <div
              className="Editor"
              id="Editor"
              style="width: 700px"
              ref="Editor"
            ></div> -->
          </div>
        </div>
      </section>
      <section>
        <div class="selected_items">
          <span class="title"></span>
          <div>
            <el-button type="primary" @click="cancel">取消</el-button>
            <el-button type="danger" @click="confirm">确认</el-button>
          </div>
        </div>
      </section>
    </div>
    <!-- 评论前置设置 -->
    <el-dialog title="评论前置设置" v-model="dialogFlag" width="600px">
      <el-button @click="addItem">增加</el-button>
      <div v-for="(item,index) in setList" :key="index">
        <el-form label-suffix=":">
          <el-form-item label="心愿期数">
            <el-select v-model="item.wishId">
              <el-option v-for="(wish,wishIndex) in wishList" :key="wishIndex" :label="wish.name" :value="wish.id"></el-option>
            </el-select>
            <el-button @click="addGoodIds(index)">添加产品</el-button>
            <el-button @click="removeItem(index)">删除</el-button>
          </el-form-item>
          <template v-for="(good,goodIndex) in item.goodIds" :key="goodIndex">
            <el-form-item label="产品">
              <el-select v-model="good.id" filterable>
                <el-option v-for="(g,gIndex) in goodList" :key="gIndex" :label="g.title" :value="g.id"></el-option>
              </el-select>
              <el-button @click="removeGoodIds(index,goodIndex)">删除</el-button>
            </el-form-item>
            <el-form-item label="天数">
              <el-input type="number" v-model="good.time"></el-input>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <template #footer>
        <el-button size="small" @click="dialogFlag=false">取消</el-button>
        <el-button type="primary" size="small" @click="submitDialog1">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { Search, Plus } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import global_api, { ModifyProductByProductId,QueryWishListByWishSearchVo,GetProductList} from "@/config/Api";

import editor from '@/components/editor.vue'
import tagclass from "@/components/tagClass";
export default {
  data() {
    return {
      Search: Search, //图标
      Plus: Plus,
      source_options: [],
      brand_options: [],
      categoryId_one: "", //大类id
      categoryId_two: "", //小类id
      category_one_options: [],
      category_two_options: [],
      item_obj: {},
      category: {
        id: "",
        parentId: "",
      },
      flag: "",
      dialogFlag: false,
      setList:[],
      wishList:[],
      goodList:[],
      description: '',
    };
  },
  components: {
    tagclass,
    editor
  },
  mounted() {
    this.GetProductList();
    this.GetBrandList();

    this.Init_date();
    this.getTargetAudienceInfo();
  },
  methods: {
    // 打开选择标签分类弹窗
    openTagClass() {
      this.tagClassDialog = true;
    },

    // 检查价格是否输入正确
    checkPrice() {
      let price = this.item_obj.price;
      console.log(/^\d+\.\d\d/.test(price));
      if (/^\d+$/.test(price)) {
        this.item_obj.price += ".00";
        return;
      }
      if (/^\d+\.\d$/.test(price)) {
        this.item_obj.price += "0";
        return;
      }
      if (/^\d+\.\d\d$/.test(price)) {
        return;
      }
      this.item_obj.price = "";
    },
    Init_date() {
      //初始化数据
      this.flag = this.$route.query.type;
      if (this.$route.query.type == "edit") {
        this.item_obj = JSON.parse(sessionStorage.getItem("goods_item"));
        let price = this.item_obj.price + "";
        if (price === "0") {
          this.item_obj.price = "0";
        } else {
          if (price.length == 1) {
            this.item_obj.price = "0.0" + price;
          } else if (price.length == 2) {
            this.item_obj.price = "0." + price;
          } else {
            this.item_obj.price =
              price.substring(0, price.length - 2) +
              "." +
              price.substring(price.length - 2, price.length);
          }
        }
        this.item_obj.brandName = this.item_obj.brand
          ? this.item_obj.brand.name
          : "";
        if (
          this.item_obj.projectRepDTOS &&
          this.item_obj.projectRepDTOS.length
        ) {
          this.item_obj.amount = this.item_obj.projectRepDTOS[0].amount || 0;
        }
        console.log(this.item_obj);
        if (this.item_obj.category) {
          this.category = this.item_obj.category;
        } else {
          this.category = {
            id: "",
            parentId: "",
          };
        }
      } else {
        this.item_obj = {
          allowCommentTime: 0,
          brandId: "",
          brandName: "",
          amount: 0,
          categoryId: "",
          description: "",
          isVisiable: 1,
          pid: "",
          price: "",
          requireComment: 1,
          source: 0,
          subtitle: "",
          title: "",
          isIndexShow: 0,
          highFrequency: '',
        };
      }
    },

    GetProductList() {
      //获取产品列表(下拉框)
      global_api.GetProductList().then((res) => {
        if (res.code != 0) {
          ElMessage.error("获取产品列表错误");
        } else {
          this.source_options = res.result;
          this.source_options.unshift({ id: 0, title: "不引用" });
        }
      });
    },

    GetBrandList() {
      //获取品牌列表
      global_api.GetBrandList().then((res) => {
        if (res.code != 0) {
          ElMessage.error("获取品牌列表错误");
        }
        this.brand_options = res.result;
      });
    },

    cancel() {
      this.$router.push("/goods_list");
    },
    confirm() {
      let name = "";
      for (let i in this.brand_options) {
        if (this.brand_options[i].id === this.item_obj.brandId) {
          name = this.brand_options[i].name;
        }
      }
      if (!name) {
        name = this.item_obj.brandId;
        this.item_obj.brandId = 0;
      }
      console.log(this.item_obj);
      let obj = {
        allowCommentTime: this.item_obj.allowCommentTime,
        brandId: this.item_obj.brandId,
        brandName: name,
        categoryId: this.category.id,
        description: this.description,
        isVisiable: this.item_obj.isVisiable,
        pid: this.item_obj.pid,
        price: this.item_obj.price.replace(".", ""),
        requireComment: this.item_obj.requireComment,
        source: this.item_obj.source,
        subtitle: this.item_obj.subtitle,
        title: this.item_obj.title,
        amount: this.item_obj.amount,
        tagCategoryId: this.item_obj.tagCategoryId,
        isIndexShow: this.item_obj.isIndexShow,
        highFrequency: this.item_obj.highFrequency,
        productRequestVo: '',
        prefinishedPids:this.item_obj.prefinishedPids,
      };
      if (this.flag == "edit") {
        obj.productId = this.item_obj.id;
        ModifyProductByProductId(obj).then(
          (res) => {
            ElMessage({
              type: "success",
              message: "修改成功",
            });
            this.$router.push("/goods_list");
          },
          (err) => {
            ElMessage.error(`修改失败：${err.msg}`);
          }
        );
      } else {
        global_api.AddProduct(obj).then(
          (res) => {
            ElMessage({
              type: "success",
              message: "新增成功",
            });
            this.$router.push("/goods_list");
          },
          (err) => {
            this.loading = false;
            ElMessage.error(`新增失败：${err.msg}`);
          }
        );
      }
    },
    getTag(obj) {
      console.log(obj);
      this.item_obj.tagCategoryId = obj.id;
      this.item_obj.tagName = obj.name;
    },
    // 获取标签
    getTargetAudienceInfo() {
      global_api.QueryTagListByTagSearchVo({ cunPage: 0, pageSize: 0 }).then(
        (res) => {
          //获取标签列表

          let list = d(res.result, []);
          console.log(list);
          console.log(this.item_obj.tagCategoryId);
          for (let i in list) {
            if (list[i].id == this.item_obj.tagCategoryId) {
              this.item_obj.tagName = list[i].name;
            }
          }

          function d(list, a) {
            for (let i in list) {
              a.push(list[i]);
              if (list[i].tagRespDTOS?.length) {
                d(list[i].tagRespDTOS, a);
              }
            }
            return a;
          }
        },
        () => {
          ElMessage.error("获取标签失败");
        }
      );
    },
    // 打开前置设置
    openDialog1(){
      this.dialogFlag = true;
      if(this.item_obj.prefinishedPids){
        this.setList = JSON.parse(this.item_obj.prefinishedPids)
      }else{
        this.setList = [];
      }
      this.getWishList();
      this.GetProductList();
    },
    // 获取心愿列表
    getWishList() {
      let param = {
        cunPage: 1,
        pageSize: 100,
      }
      this.loading = true;
      QueryWishListByWishSearchVo(param).then(res => {
        this.loading = false;
        this.wishList = res.result;
      },err => {
        this.loading = false;
        ElMessage.error(`获取心愿列表失败：${err.msg}`);
      })
    },
    // 获取产品列表
    GetProductList(){
      GetProductList().then(res=>{
        this.goodList = res.result;
      })
    },
    // 增加一项设置
    addItem(){
      let setList = this.setList;
      setList.push({goodIds:[]});
      this.setList = setList;
    },
    // 删除产品限制
    removeItem(i){
      let setList = this.setList;
      setList.splice(i,1);
    },
    // 添加产品限制
    addGoodIds(i){
      let item = this.setList[i];
      item.goodIds.push({})
    },
    // 删除产品
    removeGoodIds(index,goodindex){
      let item = this.setList[index];
      item.goodIds.splice(goodindex,1)
    },
    // 确定限制
    submitDialog1(){
      let setList = this.setList;
      if(setList.length){
        let flag = true;
        for(let i in setList){
          if(!setList[i].wishId){
            flag = false;
            ElMessage.error('请选择心愿期数');
            break;
          }
          if(!setList[i].goodIds.length){
            flag = false;
            ElMessage.error('请设置产品信息');
            break;
          }
          for(let n in setList[i].goodIds){
            if(!setList[i].goodIds[n].id){
              flag = false;
              ElMessage.error('请选择产品');
              break;
            }
            if(!setList[i].goodIds[n].time){
              flag = false;
              ElMessage.error('请输入天数');
              break;
            }
          }
        }
        if(flag){
          this.item_obj.prefinishedPids = JSON.stringify(setList);
          this.dialogFlag = false;
          console.log(this.item_obj);
        }
      }else{
        this.dialogFlag = false;
      }
    },
    getRole(data){
      this.description = data;
    },
  },
};
</script>
<style scoped lang="scss">
.goods_edit {
  margin-top: 20px;
  .start_items {
    img {
      width: 200px;
      height: 200px;
      border-radius: 10px;
    }
    .sub {
      margin-top: 10px;
      font-size: 12px;
      color: red;
    }
  }
}

.el-input__inner,
.el-input-number,
.el-textarea {
  width: 400px !important;
}
</style>
<style>
.goods_edit .el-overlay {
  z-index: 999 !important;
}
#Editor>div{
  z-index: 99 !important;
}
</style>
