<template>
  <div class="main" v-loading="loading">
    <el-form inline>
      <el-form-item label="产品">
        <el-input v-model="productTitle"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="sou">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" stripe highlight-current-row @current-change="handleCurrentChange">
      <el-table-column label="id" prop="id" width="200"></el-table-column>
      <el-table-column label="产品名" prop="title"></el-table-column>
      <el-table-column label="品牌" prop="brand.name"></el-table-column>
      <el-table-column label="描述" prop="subtitle"></el-table-column>
    </el-table>
    <el-pagination
      v-model:currentPage="cunPage"
      :page-size="10"
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    />
  </div>
</template>
<script>
import {QueryProductListByProductSearchVo} from "@/config/Api"
export default {
  data(){
    return {
      cunPage: 1,
      total: 1,
      list: [],
      loading: false,
      productTitle: '',
    }
  },
  mounted(){
    this.getList();
  },
  methods:{
    getList(){
      let param = {
        pageSize: 10,
        cunPage: this.cunPage,
        productTitle: this.productTitle,
      }
      this.loading = true;
      QueryProductListByProductSearchVo(param).then(res=>{
        this.loading = false;
        this.list = res.result;
        this.total = res.data.totalCount;
      },err=>{
        this.loading = false;
        this.list = [];
        this.total = 1;
      })
    },
    handleCurrentChange(e){
      console.log(e);
      this.$emit('select',e)
    },
    sou(){
      this.cunPage = 1;
      this.getList();
    }
  }
}
</script>
<style scoped>
.main{
  width: 100%;
}
</style>