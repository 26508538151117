import { createRouter, createWebHistory } from 'vue-router'
// 会员管理
import Member_detail from '../views/Member_detail/Member_detail'
import Member_address from '../components/Member_detail/Member_address'
import Member_comment from '../views/Member_comment/Member_comment'
import Questionnaire from '../components/Member_detail/Questionnaire'
import initial from '../views/Member_detail/initialQuestion'
import coin from '../views/Member_detail/coinChange'
import userTags from "@/views/userTags/userTags";
import unblock from "../views/unblock/unblock"
// 基础属性管理
import Address from '../views/Address/Address'
import sensitive from '../views/sensitive/sensitive'
import kvmap from '../views/kvmap/kvmap'
// 运营规则管理
import level from '../views/level/level'
import coin_rule from '../views/coin_rule/coin_rule'
import Feedback_message from '../views/Feedback_message/Feedback_message'
import formwork from '../views/formwork/formwork'
import editformwork from '../views/formwork/editFormwork'

//许愿管理
import Cherished_desire_list from '../views/Cherished_desire_list/Cherished_desire_list'
import Cherished_desire_edit from '../components/Cherished_desire_list/Cherished_desire_edit'
import Cherished_desire_update_line from '../components/Cherished_desire_list/Cherished_desire_update_line'
import Cherished_desire_detail from '../components/Cherished_desire_list/Cherished_desire_detail'
import Subject_list from '../components/Cherished_desire_list/Subject_list'
import Order_management from '../views/Order_management/Order_management'
import Order_management_update from '../components/Order_management/Order_management_update'
import Order_management_detail from "@/components/Order_management/Order_management_detail";
import updataOrder from '@/views/updataOrder/updataOrder'
//二维码生成
import WX_code from '../views/WX_code/WX_code'

//人群库
import Crowd_library_list from '../views/Crowd_library/Crowd_library_list'
import Crowd_library_edit from '../components/Crowd_library/Crowd_library_edit'
//品类分类管理
import Subject_management from '../views/Category_management/Subject_management'
import Subject_management_edit from '../components/Category_management/Subject_management_edit'
import Category_management_list from '../views/Category_management/Category_management_list'

//小程序配置
import Slide_show_setting from '../views/Slide_show_setting/Slide_show_setting'
import Slide_show_edit from '../components/Slide_show_setting/Slide_show_edit'
import H5_page_setting from '../views/H5_page_setting/H5_page_setting'
import H5_edit from '../components/H5_page_setting/edit'
// 权限管理
import right_manage from '../views/right_manage/right_manage'
import role_manage from '../views/role_manage/role_manage'
import account_manage from '../views/account_manage/account_manage'
// 问卷管理
import inital_questionnaire from '../views/Initial_questionnaire/Initial_questionnaire'
// 心愿广场
import square_banner from '../views/square_banner/square_banner'
import Topic from '../views/Topic/Topic'
import topic_list from '../views/topicList/topicList'
import topic_edit from '../components/Topic/edit'
import topic_goods from '../views/topicList/topicGoods'
import topic_comment from '../views/topicList/topicComment'
import wishBox from '../views/wishBox/wishBox'
import wishBoxEdit from '../views/wishBox/wishBox_edit'
import poster from '../views/poster/poster'
// 小程序设置
import setapp_image from '../views/setapp_image/setapp_image'
import Prompt_message from '../views/Prompt_message/Prompt_message'
import Image_setting from '../views/Image_setting/Image_setting'
import Subscribe_image from '../views/Subscribe_image/Subscribe_image'
import switchset from '../views/seting/switchSet'
// 产品管理
import goods_list from '../views/goods/goodsList'
import goods_edit from '../views/goods/goods_edit'
import goods_resource_list from '../views/goods/goods_resource_list'
import goods_image_list from '../views/goods/goods_image_list'
import goods_comment_list from '../views/goods/goods_comment_list'
import goods_question from '../views/goods/goods_question'
import brandlist from '../views/brand/brandList'
import homeGoods from '../views/homeGoods/homeGoods.vue'
// 调研管理
import survey from '../views/survey/survey_list'
import survey_question from '../views/survey/survey_question'
import survey_stock from '../views/survey/survey_stock'
import survey_prize from "@/views/survey/survey_prize";
import survey_add_prize from "@/views/survey/survey_add_prize";
import survey_add from "@/views/survey/survey_add";
import special_prize from "@/views/survey/special_prize";
//标签管理
import Tag_management from '../views/Tag_management/Tag_management'
import Tag_classification_management from '../views/Tag_classification_management/Tag_classification_management'
import weitag from '@/views/Tag_management/weiTag'
//登录
import Login from '../views/Login/Login'
//主页内容管理
import Main_content_manage from '../views/Main_content_manage/Main_content_manage'
import Main_content_manage_edit from '../components/Main_content_manage/Main_content_manage_edit'
//杂货铺本期好物
import Variety_good_things from '../views/Variety_good_things/Variety_good_things'
//杂货铺详情
import Variety_detail from '../views/Variety_detail/Variety_detail'
import Variety_detail_edit from '../components/Variety_detail/Variety_detail_edit'
import Variety_detail_image from '../components/Variety_detail/Variety_detail_image'
//杂货铺订单
import Variety_shop_order from '../views/Variety_shop_order/Variety_shop_order'

//问卷跳题
import Question_jump from '../components/Question_jump/jump.vue'

// 权限管理
import admin from '../views/Permission/admin.vue'
import role from '../views/Permission/role.vue'

// 获奖管理
import awardList from "../views/awardManagement/awardList/awaedList.vue"
import cashout from "../views/awardManagement/cashout/cashout.vue"
import paylist from "../views/awardManagement/pay/paylist.vue"

import useranswer from '@/views/userAnswer/useranswer.vue'
import newyear from '@/views/active/newyear'

// cem管理
import cemnotice from '../views/cem/cemNotice'
import cemAccount from '../views/cem/cemAccount'
import accountProduct from '../views/cem/accountProduct'
import accountSurvey from '../views/cem/accountSurvey'

// 引入路由文件
import question from './modules/question'

//AI品牌维护管理
import aiBrandList from '../views/AiBrand/aiBrandList'

const routes = [
  ...question,
  {
    path: '/',
    redirect: 'Member_detail'
  },
  {
    path: '/sign_in_survey',
    name: "sign_in_survey",
    meta: {title: '签到问卷',barArr:[{name:'签到问卷',path:'/sign_in_survey'}]},
    component: () => import('@/views/sign_in/sign_in_survey.vue')
  },
  {
    path: '/sign_in_survey/question',
    name: "sign_in_question",
    meta: {title: '题目列表',barArr:[{name:'签到问卷',path:'/sign_in_survey'},{name:'题目列表',path:'/sign_in_survey'}]},
    component: () => import('@/views/sign_in/sign_in_question.vue')
  },
  {
    path: '/set_sign_in',
    name: "set_sign_in",
    meta: {title: '签到设置',barArr:[{name:'签到设置',path:'/set_sign_in'}]},
    component: () => import('@/views/sign_in/set_sign_in.vue')
  },
  {
    path: '/user_sign_in',
    name: "user_sign_in",
    meta: {title: '用户签到',barArr:[{name:'用户签到',path:'/user_sign_in'}]},
    component: () => import('@/views/sign_in/user_sign_in.vue')
  },
  {
    path: '/GPT',
    name: "GPT",
    meta: {title: 'GPT',barArr:[{name:'GPT',path:'/GPT'}]},
    component: () => import('@/views/GPTAnswer/GPTAnswer.vue')
  },
  {
    path: '/GPTAnswer',
    name: "GPTAnswer",
    meta: {title: 'GPTAnswer',barArr:[{name:'GPTAnswer',path:'/GPTAnswer'}]},
    component: () => import('@/views/GPTAnswer/answer_detail.vue')
  },
  {
    path: '/Login',
    name: "Login",
    meta: {title: 'Login',barArr:[{name:'Login',path:'/Login'}]},
    component: Login
  },
  {
    path: '/useranswer',
    name: "useranswer",
    meta: {title: '用户回答',barArr:[{name:'用户回答',path:'/useranswer'}]},
    component: useranswer
  },
  {
    path: '/newyear',
    name: "newyear",
    meta: {title: '新年活动',barArr:[{name:'新年活动',path:'/newyear'}]},
    component: newyear
  },
  {
    path: '/laxin',
    name: "laxin",
    meta: {title: '新年活动',barArr:[{name:'新年活动',path:'/laxin'}]},
    component: () => import('@/views/active/laxin.vue')
  },
  {
    path: '/awardList',
    name: "awardList",
    meta: {title: '获奖列表',barArr:[{name:'获奖列表',path:'/awardList'}]},
    component: awardList
  },
  {
    path: '/cashout',
    name: "cashout",
    meta: {title: '提现列表',barArr:[{name:'提现列表',path:'/cashout'}]},
    component: cashout
  },
  {
    path: '/paylist',
    name: "paylist",
    meta: {title: '支付列表',barArr:[{name:'支付列表',path:'/paylist'}]},
    component: paylist
  },
  // 资金流水
  {
    path: '/capital',
    name: "capital",
    meta: {title: '资金流水',barArr:[{name:'资金流水',path:'/capital'}]},
    component: () => import('@/views/awardManagement/CapitalFlow/capitalFlow.vue'),
  },
  {
    path: '/Member_detail',
    name: "Member_detail",
    meta: {title: '会员内容详情',barArr:[{name:'会员内容详情',path:'/Member_detail'}]},
    component: Member_detail
  },
  {
    path: '/Member_detail/Member_address',
    name: "Member_address",
    meta: {title: '用户收货地址', barArr:[{name:'会员内容详情',path:'/Member_detail'},{name:'用户收货地址',path:'/Member_detail/Member_address'}]},
    component: Member_address
  },
  {
    path: '/Member_detail/unblock',
    name: "unblock",
    meta: {title: '用户解封', barArr:[{name:'会员内容详情',path:'/Member_detail'},{name:'用户解封',path:'/Member_detail/unblock'}]},
    component: unblock
  },
  {
    path: '/Member_detail/Questionnaire',
    name: 'Questionnaire',
    meta: {title: '地址管理', barArr: [{name:'会员内容详情',path:'/Member_detail'},{name:'初始问卷信息',path:'/Member_detail/Questionnaire'}]},
    component: Questionnaire
  },
  {
    path: '/Member_detail/initial',
    name: 'initial',
    meta: {title: '初始问卷', barArr: [{name:'初始问卷',path:'/Member_detail/initial'}]},
    component: initial
  },
  {
    path: '/initial/add_question',
    name: 'add_initial_question',
    meta: {title: '初始问卷', barArr: [{name: '初始问卷', path:'/Member_detail/initial'},{name: '新建题目', path:'/initial/add_question'}]},
    component: Subject_management_edit,
  },
  {
    path: '/Member_detail/coin',
    name: 'coin',
    meta: {title: '心愿币', barArr: [{name:'心愿币',path:'/Member_detail/coin'}]},
    component: coin
  },
  {
    path: '/Member_detail/usertags',
    name: 'userTags',
    meta: {title: '用户标签', barArr: [{name:'用户标签',path:'/Member_detail/usertags'}]},
    component: userTags
  },
  {
    path: '/Address',
    name: 'Address',
    meta: {title: '地址管理', barArr: [{name:'地址管理',path:'/Address'}]},
    component: Address
  },
  {
    path: '/sensitive',
    name: 'sensitive',
    meta: {title: '敏感词管理', barArr: [{name:'敏感词管理',path:'/sensitive'}]},
    component: sensitive
  },
  {
    path: '/kvmap',
    name: 'kvmap',
    meta: {title: '数据字典', barArr: [{name:'数据字典',path:'/kvmap'}]},
    component: kvmap
  },
  {
    path: '/Coin_rule',
    name: 'Coin_rule',
    meta: {title: '心愿币获取规则', barArr: [{name: '心愿币获取规则', path:'/Coin_rule'}]},
    component: coin_rule,
  },
  {
    path: '/Feedback_message',
    name: 'Feedback_message',
    meta: {title: '反馈留言', barArr: [{name: '反馈留言', path:'/Feedback_message'}]},
    component: Feedback_message,
  },
  {
    path: '/WX_code',
    name: 'WX_code',
    meta: {title: '活动二维码生成', barArr: [{name: '活动二维码生成', path:'/WX_code'}]},
    component: WX_code,
  },
  {
    path: '/formwork',
    name: 'formwork',
    meta: {title: '模板管理', barArr: [{name: '模板管理', path:'/formwork'}]},
    component: formwork,
  },
  {
    path: '/editformwork',
    name: 'editformwork',
    meta: {title: '模板发送', barArr: [{name: '模板发送', path:'/editformwork'}]},
    component: editformwork,
  },
  {
    path: '/cemnotice',
    name: 'cemnotice',
    meta: {title: 'CEM公告', barArr: [{name: 'CEM公告', path:'/cemnotice'}]},
    component: cemnotice,
  },
  {
    path: '/cemAccount',
    name: 'cemAccount',
    meta: {title: 'CEM账户', barArr: [{name: 'CEM账户', path:'/cemAccount'}]},
    component: cemAccount,
  },
  {
    path: '/cemChildAccount',
    name: 'cemChildAccount',
    meta: {title: 'CEM账户', barArr: [{name: 'CEM账户', path:'/cemChildAccount'}]},
    component: () => import('@/views/cem/cemChildAccount.vue'),
  },
  {
    path: '/cemAccount/product',
    name: 'accountProduct',
    meta: {title: '账户产品', barArr: [{name: '账户产品', path:'/cemAccount/product'}]},
    component: accountProduct,
  },
  {
    path: '/cemAccount/survey',
    name: 'accountSurvey',
    meta: {title: '账户调研', barArr: [{name: '账户调研', path:'/cemAccount/survey'}]},
    component: accountSurvey,
  },
  {
    path: '/cemInvitation',
    name: 'cemInvitation',
    meta: {title: 'CEM邀请码', barArr: [{name: 'CEM邀请码', path:'/cemInvitation'}]},
    component: () => import('@/views/cem/InvitationCode.vue')
  },
  {
    path: '/cemUpdataNotice',
    name: 'cemUpdataNotice',
    meta: {title: "CEM更新公告",barArr: [{name: 'CEM更新公告', path:'/cemUpdataNotice'}]},
    component: () => import('@/views/cem/cemUpdataNotice.vue')
  },
  {
    path: '/cemFQA',
    name: 'cemFQA',
    meta: {title: "CEM常见问题",barArr: [{name: 'CEM常见问题', path:'/cemFQA'}]},
    component: () => import('@/views/cem/cemFQA.vue')
  },
  {
    path: '/Cherished_desire_list',
    name: 'Cherished_desire_list',
    meta: {title: '心愿列表', barArr: [{name: '心愿列表', path:'/Cherished_desire_list'}]},
    component: Cherished_desire_list,
  },
  {
    path: '/Cherished_desire_list/edit',
    name: 'Cherished_desire_add',
    meta: {title: '添加心愿', barArr: [{name: '心愿列表', path:'/Cherished_desire_list'},{name: '添加心愿', path:'/Cherished_desire_list/edit'}]},
    component: Cherished_desire_edit,
  },
  {
    path: '/Cherished_desire_list/update_line',
    name: 'Cherished_desire_update_line',
    meta: {title: '修改许愿上线', barArr: [{name: '心愿列表', path:'/Cherished_desire_list'},{name: '修改许愿上线', path:'/Cherished_desire_list/update_line'}]},
    component: Cherished_desire_update_line,
  },
  {
    path: '/Cherished_desire_list/detail',
    name: 'Cherished_desire_detail',
    meta: {title: '许愿详情', barArr: [{name: '心愿列表', path:'/Cherished_desire_list'},{name: '许愿详情', path:'/Cherished_desire_list/detail'}]},
    component: Cherished_desire_detail,
  },
  {
    path: '/Cherished_desire_list/Subject_list',
    name: 'Subject_list',
    meta: {title: '题目列表', barArr: [{name: '心愿列表', path:'/Cherished_desire_list'},{name: '题目列表', path:'/Cherished_desire_list/Subject_list'}]},
    component: Subject_list,
  },
  {
    path: '/Cherished_desire_list/Subject_list/jump',
    name: 'Subject_list_jump',
    meta: {title: '跳题设置', barArr: [{name: '心愿列表', path:'/Cherished_desire_list'},{name: '跳题设置', path:'/Cherished_desire_list/Subject_list/jump'}]},
    component: Question_jump,
  },
  {
    path: '/Order_management',
    name: 'Order_management',
    meta: {title: '心愿订单管理', barArr: [{name: '心愿订单管理', path:'/Order_management'}]},
    component: Order_management,
  },
  {
    path: '/Order_management/update',
    name: 'Order_management_update',
    meta: {title: '心愿订单管理', barArr: [{name: '心愿订单管理', path:'/Order_management'},{name: '修改订单', path:'/Order_management/update'}]},
    component: Order_management_update,
  },
  {
    path: '/Order_management/detail',
    name: 'Order_management_detail',
    meta: {title: '心愿订单管理', barArr: [{name: '心愿订单管理', path:'/Order_management'},{name: '订单详情', path:'/Order_management/detail'}]},
    component: Order_management_detail,
  },
  {
    path: '/Order_management/edit',
    name: 'OrderWishBoxEdit',
    meta: {title: '心愿盒管理', barArr: [{name: '心愿订单管理', path:'/Order_management'},{name: '心愿盒编辑', path:'/Order_management/edit'}]},
    component: wishBoxEdit,
  },
  {
    path: '/wishBox',
    name: 'wishBox',
    meta: {title: '心愿盒管理', barArr: [{name: '心愿盒管理', path:'/wishBox'}]},
    component: wishBox,
  },
  {
    path: '/wishBox/edit',
    name: 'wishBoxEdit',
    meta: {title: '心愿盒管理', barArr: [{name: '心愿盒管理', path:'/wishBox'},{name: '编辑', path:'/wishBox/edit'}]},
    component: wishBoxEdit,
  },
  {
    path: '/updataOrder',
    name: 'updataOrder',
    meta: {title: '订单更新', barArr: [{name: '订单更新', path:'/updataOrder'}]},
    component: updataOrder,
  },
  {
    path: '/poster',
    name: 'poster',
    meta: {title: '海报', barArr: [{name: '海报', path:'/poster'}]},
    component: poster,
  },
  {
    path: '/Member_comment',
    name:'Member_comment',
    meta: {title: '会员评论', barArr: [{name:'会员评论',path:'/Member_comment'}]},
    component: Member_comment
  },
  {
    path: '/Crowd_library_list',
    name: 'Crowd_library_list',
    meta: {title: '人群库', barArr: [{name: '人群库列表', path:'/Crowd_library_list'}]},
    component: Crowd_library_list,
  },
  {
    path: '/Crowd_library_list/edit',
    name: 'Crowd_library_edit',
    meta: {title: '人群库', barArr: [{name: '人群库列表', path:'/Crowd_library_list'},{name: '编辑', path:'/Crowd_library_list/edit'}]},
    component: Crowd_library_edit,
  },
  {
    path: '/level',
    name: 'level',
    meta: {title: '等级管理', barArr: [{name: '等级管理', path:'/level'}]},
    component: level,
  },
  {
    path: '/right_manage',
    name: 'right_manage',
    meta: {title: '权限管理', barArr: [{name: '权限管理', path:'/right_manage'}]},
    component: right_manage,
  },
  {
    path: '/role_manage',
    name: 'role_manage',
    meta: {title: '角色管理', barArr: [{name: '角色管理', path:'/role_manage'}]},
    component: role_manage,
  },
  {
    path: '/account_manage',
    name: 'account_manage',
    meta: {title: '账户管理', barArr: [{name: '账户管理', path:'/account_manage'}]},
    component: account_manage,
  },
  {
    path: '/inital_questionnaire',
    name: 'inital_questionnaire',
    meta: {title: '初始问卷', barArr: [{name: '初始问卷', path:'/inital_questionnaire'}]},
    component: inital_questionnaire,
  },
  {
    path: '/square_banner',
    name: 'square_banner',
    meta: {title: '心愿广场轮播图', barArr: [{name: '心愿广场轮播图', path:'/square_banner'}]},
    component: square_banner,
  },
  {
    path: '/Topic',
    name: 'Topic',
    meta: {title: '心愿广场话题', barArr: [{name: '心愿广场话题', path:'/Topic'}]},
    component: Topic,
  },
  {
    path: '/Topic/edit',
    name: 'edit',
    meta: {title: '心愿广场话题', barArr: [{name: '心愿广场话题', path:'/Topic'},{name: '编辑', path:'/Topic/edit'}]},
    component: topic_edit,
  },
  {
    path: '/topic_list',
    name: 'topic_list',
    meta: {title: '用户发帖', barArr: [{name: '用户发帖', path:'/topic_list'}]},
    component: topic_list,
  },
  {
    path: '/topic_list/goods',
    name: 'topic_goods',
    meta: {title: '用户发帖', barArr: [{name: '用户发帖', path:'/topic_list'},{name: '关联产品', path:'/topic_list/goods'}]},
    component: topic_goods,
  },
  {
    path: '/topic_list/comment',
    name: 'topic_comment',
    meta: {title: '用户发帖', barArr: [{name: '用户发帖', path:'/topic_list'},{name: '用户评论', path:'/topic_list/comment'}]},
    component: topic_comment,
  },
  {
    path: '/setapp_image',
    name: 'setapp_image',
    meta: {title: '图片设置', barArr: [{name: '图片设置', path:'/setapp_image'}]},
    component: setapp_image,
  },
  {
    path: '/Subscribe_image',
    name: 'Subscribe_image',
    meta: {title: '订阅图片', barArr: [{name: '订阅图片', path:'/Subscribe_image'}]},
    component: Subscribe_image,
  },
  {
    path: '/switchset',
    name: 'switchset',
    meta: {title: '设置', barArr: [{name: '设置', path:'/switchset'}]},
    component: switchset,
  },
  {
    path: '/Subject_management',
    name: 'Subject_management',
    meta: {title: '品类分类管理', barArr: [{name: '题目管理', path:'/Subject_management'}]},
    component: Subject_management,
  },
  {
    path: '/Subject_management/edit',
    name: 'Subject_management_edit',
    meta: {title: '品类分类管理', barArr: [{name: '题目管理', path:'/Subject_management'},{name: '编辑', path:'/Subject_management/edit'}]},
    component: Subject_management_edit,
  },
  {
    path: '/Category_management_list',
    name: 'Category_management_list',
    meta: {title: '品类分类管理', barArr: [{name: '品类管理列表', path:'/Category_management_list'}]},
    component: Category_management_list,
  },
  {
    path: '/Slide_show_setting',
    name: 'Slide_show_setting',
    meta: {title: '小程序配置', barArr: [{name: '首页轮播图配置', path:'/Slide_show_setting'}]},
    component: Slide_show_setting,
  },
  {
    path: '/Slide_show_setting/edit',
    name: 'Slide_show_edit',
    meta: {title: '小程序配置', barArr: [{name: '首页轮播图配置', path:'/Slide_show_setting'},{name: '编辑', path:'/Slide_show_setting/edit'}]},
    component: Slide_show_edit,
  },
  {
    path: '/H5_page_setting',
    name: 'H5_page_setting',
    meta: {title: '小程序配置', barArr: [{name: 'H5页面配置', path:'/H5_page_setting'}]},
    component: H5_page_setting,
  },
  {
    path: '/H5_page_setting/edit',
    name: 'H5_page_edit',
    meta: {title: '小程序配置', barArr: [{name: 'H5页面配置', path:'/H5_page_setting'},{name: '编辑', path:'/H5_page_setting/edit'}]},
    component: H5_edit,
  },
  {
    path: '/Prompt_message',
    name: 'Prompt_message',
    meta: {title: '小程序配置', barArr: [{name: '提示信息配置', path:'/Prompt_message'}]},
    component: Prompt_message,
  },
  {
    path: '/Image_setting',
    name: 'Image_setting',
    meta: {title: '小程序配置', barArr: [{name: '图片配置', path:'/Image_setting'}]},
    component: Image_setting,
  },
  {
    path: '/goods_list',
    name: 'goods_list',
    meta: {title: '产品管理', barArr: [{name: '产品列表', path:'/goods_list'}]},
    component: goods_list,
  },
  {
    path: '/goods_list/edit',
    name: 'goods_edit',
    meta: {title: '产品管理', barArr: [{name: '产品列表', path:'/goods_list'},{name: '编辑', path:'/goods_list/edit'}]},
    component: goods_edit,
  },
  {
    path: '/goods_list/resource',
    name: 'goods_resource',
    meta: {title: '产品管理', barArr: [{name: '产品列表', path:'/goods_list'},{name: '产品资源', path:'/goods_list/resource'}]},
    component: goods_resource_list,
  },
  {
    path: '/goods_list/image',
    name: 'goods_image_list',
    meta: {title: '产品管理', barArr: [{name: '产品列表', path:'/goods_list'},{name: '产品图片', path:'/goods_list/image'}]},
    component: goods_image_list,
  },
  {
    path: '/goods_list/comment',
    name: 'goods_comment_list',
    meta: {title: '产品管理', barArr: [{name: '产品列表', path:'/goods_list'},{name: '产品评论', path:'/goods_list/comment'}]},
    component: goods_comment_list,
  },
  {
    path: '/goods_list/question',
    name: 'goods_question',
    meta: {title: '产品管理', barArr: [{name: '产品列表', path:'/goods_list'},{name: '产品题目', path:'/goods_list/question'}]},
    component: goods_question,
  },
  {
    path: '/goods/question/jump',
    name: 'goods_question_jump',
    meta: {title: '产品管理', barArr: [{name: '产品列表', path:'/goods_list'},{name: '跳题设置', path:'/goods/question/jump'}]},
    component: Question_jump,
  },
  {
    path: '/goods_list/add_question',
    name: 'add_question',
    meta: {title: '产品管理', barArr: [{name: '产品列表', path:'/goods_list'},{name: '新建题目', path:'/goods_list/add_question'}]},
    component: Subject_management_edit,
  },
  {
    path: '/brandlist',
    name: 'brandlist',
    meta: {title: '品牌管理', barArr: [{name: '品牌列表', path:'/brandlist'}]},
    component: brandlist,
  },
  {
    path: '/homegoods',
    name: 'homegoods',
    meta: {title: '首页商品', barArr: [{name: '首页商品', path:'/homegoods'}]},
    component: homeGoods,
  },
  {
    path: '/survey',
    name: 'survey',
    meta: {title: '调研管理', barArr: [{name: '调研列表', path:'/survey'}]},
    component: survey,
  },
  {
    path: '/survey/question',
    name: 'survey_question',
    meta: {title: '调研管理', barArr: [{name: '调研列表', path:'/survey'},{name: '调研题目', path:'/survey/question'}]},
    component: survey_question,
  },
  {
    path: '/survey/question/jump',
    name: 'survey_question_jump',
    meta: {title: '调研跳题', barArr: [{name: '调研列表', path:'/survey'},{name: '调研跳题', path:'/survey/question/jump'}]},
    component: Question_jump,
  },
  {
    path: '/survey/stock',
    name: 'survey_stock',
    meta: {title: '调研管理', barArr: [{name: '调研列表', path:'/survey'},{name: '调研库存', path:'/survey/stock'}]},
    component: survey_stock,
  },
  {
    path: '/survey/add_question',
    name: 'add_survey_question',
    meta: {title: '调研管理', barArr: [{name: '调研列表', path:'/survey'},{name: '新建题目', path:'/survey/add_question'}]},
    component: Subject_management_edit,
  },
  {
    path: '/survey/prize',
    name: 'survey_prize',
    meta: {title: '调研管理', barArr: [{name: '调研列表', path:'/survey'},{name: '调研奖励', path:'/survey/prize'}]},
    component: survey_prize,
  },
  {
    path: '/survey/add',
    name: 'survey_add',
    meta: {title: '编辑调研', barArr: [{name: '调研列表', path:'/survey'},{name: '调研奖励', path:'/survey/add'}]},
    component: survey_add,
  },
  {
    path: '/survey/addprize',
    name: 'add_prize',
    meta: {title: '调研管理', barArr: [{name: '调研列表', path:'/survey'},{name: '编辑奖励', path:'/survey/addprize'}]},
    component: survey_add_prize,
  },
  {
    path: '/survey/special',
    name: 'special',
    meta: {title: '调研管理', barArr: [{name: '调研列表', path:'/survey'},{name: '编辑特殊奖励', path:'/survey/special'}]},
    component: special_prize,
  },
  {
    path: '/survey/copy',
    name: 'copyanswer',
    meta: {title: '题目关联', barArr: [{name: '题目关联', path: '/survey/copy'}]},
    component: () => import('@/views/copy/answerCopy.vue')
  },
  {
    path: '/project',
    name: 'project',
    meta: {title: '项目列表', barArr: [{name: '项目列表', path: '/project'}]},
    component: () => import('@/views/project/project_list.vue')
  },
  {
    path: '/Cherished/add_question',
    name: 'add_Cherished_question',
    meta: {title: '产品管理', barArr: [{name: '心愿列表', path:'/Cherished_desire_list'},{name: '新建题目', path:'/Cherished/add_question'}]},
    component: Subject_management_edit,
  },
  {
    path: '/Tag_management',
    name: 'Tag_management',
    meta: {title: '标签管理', barArr: [{name: '标签管理', path:'/Tag_management'}]},
    component: Tag_management,
  },
  {
    path: '/weitag',
    name: 'weitag',
    meta: {title: '企微标签管理', barArr: [{name: '企微标签管理', path:'/weitag'}]},
    component: weitag,
  },
  {
    path: '/Tag_classification_management',
    name: 'Tag_classification_management',
    meta: {title: '标签管理', barArr: [{name: '标签分类管理', path:'/Tag_classification_management'}]},
    component: Tag_classification_management,
  },
  {
    path: '/Main_content_manage',
    name: 'Main_content_manage',
    meta: {title: '主页内容管理', barArr: [{name: '主页内容管理', path:'/Main_content_manage'}]},
    component: Main_content_manage,
  },
  {
    path: '/Main_content_manage/edit',
    name: 'Main_content_manage_edit',
    meta: {title: '主页内容管理', barArr: [{name: '主页内容管理', path:'/Main_content_manage'},{name: '编辑', path:'/Main_content_manage/edit'}]},
    component: Main_content_manage_edit,
  },
  {
    path: '/Variety_good_things',
    name: 'Variety_good_things',
    meta: {title: '杂货铺', barArr: [{name: '商品列表', path:'/Variety_good_things'}]},
    component: Variety_good_things,
  },
  {
    path: '/Variety_detail',
    name: 'Variety_detail',
    meta: {title: '杂货铺', barArr: [{name: '详情列表', path:'/Variety_detail'}]},
    component: Variety_detail,
  },
  {
    path: '/Variety_detail/edit',
    name: 'Variety_detail_edit',
    meta: {title: '杂货铺', barArr: [{name: '详情列表', path:'/Variety_detail'},{name: '编辑', path:'/Variety_detail/edit'}]},
    component: Variety_detail_edit,
  },
  {
    path: '/Variety_detail/image',
    name: 'Variety_detail_image',
    meta: {title: '杂货铺', barArr: [{name: '详情列表', path:'/Variety_detail'},{name: '编辑', path:'/Variety_detail/image'}]},
    component: Variety_detail_image,
  },
  {
    path: '/Variety_shop_order',
    name: 'Variety_shop_order',
    meta: {title: '杂货铺', barArr: [{name: '订单列表', path:'/Variety_shop_order'}]},
    component: Variety_shop_order,
  },
  {
    path: '/permission',
    name: 'permission',
    meta: {title: '权限管理', barArr: [{name: '权限管理', path:'/permission'}]},
    component: admin,
  },
  {
    path: '/permission/role',
    name: 'role',
    meta: {title: '权限管理', barArr: [{name: '权限管理', path:'/permission/role'}]},
    component: role,
  },
  {
    path: '/aiBrandList',
    name: 'aiBrandList',
    meta: {title: 'AI品牌管理', barArr: [{name: 'AI品牌管理', path:'/aiBrandList'}]},
    component: aiBrandList,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `心盒-${to.meta.title}`
  window.set_title()
  if(to.href=="/login"){
    next()
  }else{
    let lastLoginTime = localStorage.getItem('lastLogin');
    if(new Date().getTime() - new Date(lastLoginTime).getTime() < 24 * 60 * 60 * 1000){
      next()
    }else{
      localStorage.removeItem('id');
      localStorage.removeItem('token');
      localStorage.removeItem('lastLogin');
      next('/login')
    }
  }
})

export default router
