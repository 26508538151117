<template>
  <div class="Image_setting" v-loading="loading">
    <div v-for="(item,index) in this.tableData" :key="item.id">
      <div class="upload_img">
        <el-upload
            class="avatar-uploader"
            :action="Get_url+'admin/file/uploadFile'"
            :headers="{token:this.token,id:this.id}"
            :show-file-list="false"
            :on-success="AvatarSuccess"
            :before-upload="beforeAvatarUpload"
            @click="upload(index)"
        >
          <img v-if="item.image" :src="item.image" class="avatar" />
          <img v-else src="../../assets/bannerDefualt.png" class="avatar"/>
        </el-upload>
        <div class="upload_img_txt">{{'/pages/activity/imgSrcLink/imgSrcLink?id='+item.id}}</div>
        <div class="upload_img_txt">{{'订阅图片'+(index+1)}} <span class="red">(尺寸750*X,不超过1MB)</span></div>
      </div>

    </div>
  </div>
</template>

<script>
import {Search,MessageBox} from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import global_api from "@/config/Api";
import {Get_url} from "../../config/Get_url";
export default {
  name: 'Image_setting',
  data(){
    return {
      Search:Search,    //图标
      MessageBox:MessageBox,//图标
      loading:false,
      tableData:[],
      pageNo:1,       //页码
      pageSize:10,      //每页数量
      totalCount:0,         //总数
      Get_url:Get_url(),
      index:0,
      token:localStorage.getItem('token'),
      id:localStorage.getItem('id')
    }
  },
  mounted() {
    this.GetBannerList()
  },
  methods: {
    GetBannerList(){       //订阅图片列表
      let obj = {
        pageNo:this.pageNo,
        pageSize:this.pageSize,
        type:4
      }
      this.loading = true;
      global_api.GetBannerList(obj).then((res)=>{
        this.loading = false;
        if(res.code!=0){
          ElMessage.error('列表错误')
          this.loading = false;
        }else{
          this.tableData = res.result
          this.loading = false;
        }
        console.log(res)
      })
    },

    upload(index){
      this.index = index
    },
    AvatarSuccess(response,uploadFile){
      this.tableData[this.index].image = uploadFile.response.msg
      this.InsertOrUpdateBanner()
    },
    beforeAvatarUpload(rawFile){
      if (rawFile.size / 1024 / 1024 > 1) {
        ElMessage.error('上传的图片超过1MB了！')
        return false
      }
      return true
    },
    InsertOrUpdateBanner(){
      let item = this.tableData[this.index]
      let param = {
        bannerId:item.id,
        associatedId:item.associatedId,
        image:item.image,
        pathContent:item.pathContent,
        pathType:3,
        type:4,
        sort:item.sort,
        content: '',
      }
      global_api.InsertOrUpdateBanner(param).then((res)=>{
        if(res.code!=0){
          ElMessage.error('修改失败')
        }else{
          ElMessage({
            type: 'success',
            message: '修改成功',
          })
        }
        console.log(res)
      })
    }



  },
}
</script>
<style scoped lang="scss">
.Image_setting{
  margin-top: 20px;
  display: flex;
  justify-content: start;
  align-items: start;
  .upload_img{
    padding: 10px;
    margin: 10px;
    border: 1px solid #eee;
  }
  .upload_img_txt{
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
  }
  .avatar{
    width: 200px;
  }
}

</style>
