<template>
  <div class="addSurvey" v-loading.fullscreen.lock="loading">
    <section class="tableList">
      <el-form label-width="100px">
        <el-form-item label="名称">
          <el-input v-model="prizeData.name" placeholder="调研名称"/>
        </el-form-item>
        <el-form-item label="描述">
          <editor :text="prizeData.content" @change="getContent"></editor>
        </el-form-item>
        <el-form-item label="规则">
          <editor :text="prizeData.role" @change="getRole"></editor>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="prizeData.type">
            <el-option label="常规调研" :value="0"></el-option>
            <el-option label="打卡调研" :value="2"></el-option>
            <el-option label="需求问卷" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="打卡周期" v-if="prizeData.type === 2">
          <el-input-number class="w110" v-model="prizeData.taskPeriod1" :min="0" :step="1"></el-input-number>&nbsp;&nbsp;天&nbsp;&nbsp;
          <el-input-number class="w110" v-model="prizeData.taskPeriod2" :min="0" :step="1" :max="23"></el-input-number>&nbsp;&nbsp;小时&nbsp;&nbsp;
        </el-form-item>
        <el-form-item label="每轮打卡次数" v-if="prizeData.type === 2">
          <el-input-number v-model="prizeData.timesPerTask" :min="1" step="1"></el-input-number>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="prizeData.sort" />
        </el-form-item>
        <el-form-item label="支付心愿币">
          <el-input-number v-model="prizeData.price" />
        </el-form-item>
        <el-form-item label="开始时间">
          <el-date-picker value-format="YYYY-MM-DD HH:mm:ss" format="YYYY/MM/DD HH:mm:ss" v-model="prizeData.startTime" :clearable="false" type="datetime"/>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker value-format="YYYY-MM-DD HH:mm:ss" format="YYYY/MM/DD HH:mm:ss" v-model="prizeData.endTime" type="datetime" />
        </el-form-item>
        <el-form-item label="使用密码">
          <el-select v-model="prizeData.isPassword">
            <el-option label="不使用" :value="0"/>
            <el-option label="使用" :value="1"/>
          </el-select>
        </el-form-item>
        <el-form-item label="密码" v-if="prizeData.isPassword === 1">
          <el-input v-model="prizeData.password" style="width: 288px">
            <template #append><el-button @click="automaticallyGenerate">生成密码</el-button></template>
          </el-input>
        </el-form-item>
        <el-form-item label="标题颜色">
          <el-input v-model="prizeData.titleColor" />
        </el-form-item>
        <el-form-item label="背景色">
          <el-input v-model="prizeData.backgroundColor" />
        </el-form-item>
        <el-form-item label="图片">
          <el-upload :action="Get_url+'admin/file/uploadFile'" :headers="headers" :before-upload="beforeImageUpload" :on-success="handleAvatarSuccess" on-error="errorImageUpload" :show-file-list="false">
            <img class="prizeImage" v-if="prizeData.image" :src="prizeData.image" alt="调研">
            <img class="prizeImage" v-else src="/images/bannerDefualt.png" alt="">
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button>取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </el-form-item>
      </el-form>
    </section>
  </div>
</template>
<script>
import {Get_url} from "@/config/Get_url";
import {ElMessage} from "element-plus";
import {InsertOrUpdateSpecialEvent} from "@/config/Api";
import editor from '@/components/editor.vue'
export default {
  data() {
    return {
      prizeData: {},
      type: '',
      Get_url: Get_url(),
      headers: {
        id: localStorage.getItem('id'),
        token: localStorage.getItem('token')
      },
      loading: false,
    }
  },
  components: {
    editor
  },
  mounted() {
    this.type = this.$route.query.type;
    if(this.type==='edit'){
      let editPrizeData = sessionStorage.getItem('editPrizeData');

      let prizeData = {...JSON.parse(editPrizeData)};
      console.log(prizeData)
      if(prizeData.type === 2){
        prizeData.taskPeriod1 = Math.floor(prizeData.taskPeriod/24);
        prizeData.taskPeriod2 = prizeData.taskPeriod%24;
      }
      this.prizeData = prizeData;

    } else {
      this.prizeData = {
        price: 0,
        type: 0,
        isPassword: 0,
        sort: 0,
        taskPeriod1: 1,
        taskPeriod2: 0,
        timesPerTask: 1,
      }
    }
  },
  methods: {
    getContent(data){
      this.content = data;
    },
    getRole(data){
      this.role = data;
    },
    // 上传图片前
    beforeImageUpload(){
      this.loading = true;
    },
    // 上传图片
    handleAvatarSuccess(e) {
      this.prizeData.image = e.msg;
      this.loading = false;
    },
    // 上传图片失败
    errorImageUpload(e){
      console.log(e);
      this.prizeData.image = '';
      this.loading = false;
      ElMessage.error('上传图片失败')
    },
    // 检查数据
    inspectPrizeData(){
      let flag = false;
      let prizeData = this.prizeData;
      if(!prizeData.name){
        ElMessage.error('请输入调研名称');
        return flag = true;
      }
      if(!this.content){
        ElMessage.error('请输入调研描述');
        return flag = true;
      }
      if(!this.role){
        ElMessage.error('请输入调研规则');
        return flag = true;
      }
      if(prizeData.type === 2 && !prizeData.taskPeriod1&&!prizeData.taskPeriod2){
        ElMessage.error('请输入打卡周期');
        return flag = true;
      }
      if(prizeData.type === 2 && !prizeData.timesPerTask){
        ElMessage.error('请输入周期打卡次数');
        return flag = true;
      }
      if(!prizeData.sort&&prizeData.sort!==0){
        ElMessage.error('请输入调研排序');
        return flag = true;
      }
      if(!prizeData.price&&prizeData.price!==0){
        ElMessage.error('请输入调研支付心愿币');
        return flag = true;
      }
      if(!prizeData.startTime){
        ElMessage.error('请输入调研开始时间');
        return flag = true;
      }
      if(!prizeData.endTime){
        ElMessage.error('请输入调研结束时间');
        return flag = true;
      }
      if(!prizeData.isPassword&&prizeData.isPassword!==0){
        ElMessage.error('请选择是否使用密码');
        return flag = true;
      }
      if(prizeData.isPassword===1&&!prizeData.password){
        ElMessage.error('请输入调研密码');
        return flag = true;
      }
      if(prizeData.isPassword===1&&!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8}$/.test(prizeData.password)){
        ElMessage.error('密码格式不正确');
        return flag = true;
      }
      if(!prizeData.image){
        ElMessage.error('请上传图片');
        return flag = true;
      }
      return flag;
    },
    // 添加调研
    submit(){
      if(this.inspectPrizeData()) return;
      let status = this.prizeData.status || 0;
      if(this.prizeData.id){
        let nowTimeStep = new Date().getTime();
        let minTimeStep = new Date(this.prizeData.startTime).getTime();
        let maxTimeStep = new Date(this.prizeData.endTime).getTime();
        if(minTimeStep>nowTimeStep||nowTimeStep>maxTimeStep){
          status = 0;
        }
      }
      let param = {
        id: this.prizeData.id?this.prizeData.id:-1,
        currency: 1,
        sort: this.prizeData.sort,
        price: this.prizeData.price,
        name: this.prizeData.name,
        content: this.content,
        startTime: this.prizeData.startTime.replace(/\-/g,'/'),
        endTime: this.prizeData.endTime.replace(/\-/g,'/'),
        role: this.role,
        type: this.prizeData.type,
        image: this.prizeData.image,
        status: status,
        isPassword: this.prizeData.isPassword,
        password: this.prizeData.isPassword?this.prizeData.password:'',
        isRecommend: 0,
        taskPeriod: this.prizeData.type === 2 ? this.prizeData.taskPeriod1*24+this.prizeData.taskPeriod2 : 0,
        timesPerTask: this.prizeData.type === 2 ? this.prizeData.timesPerTask : 0,
        remarks: '无',
        auditProgress: 1,
        isReport: 0,
      };

      this.loading = true;
      InsertOrUpdateSpecialEvent(param).then(res => {
        this.loading = false;
        this.backUrl();
      },err => {
        this.loading = false;
      })
    },
    // 返回
    backUrl(){
      this.$router.back();
    },
    // 生成密码
    automaticallyGenerate(){
      let chars = 'ABCDEFGHJKLMNOPQRSTUVWSYZabcdefghijkmnopqrstuvwsyz0123456789'
      let list = []
      //通过随机获取八个字符串的索引下标
      for(var i=0;i<8;i++){
        let val_1 = Math.round(Math.random()*59)
        list.push(val_1)
      }
      var passwd = ''
      for(var n=0;n<list.length;n++){
        passwd +=  chars.charAt(list[n])
      }
      var regNumber = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8}$/
      if(regNumber.test(passwd) ){
        this.prizeData.password = passwd
      }else{
        this.automaticallyGenerate()
      }
    },
  }
}
</script>
<style scoped lang="scss">
.editor {
  width: 520px;
  position: relative;
  z-index: 1;
}
.prizeImage{
  width: 200px;

}
</style>
<style>
.w110.el-input-number {
  width: 110px !important;
}
.w110 .el-input__inner{
  width: 110px !important;
}
</style>