<template>
  <div class="Cherished_desire_list" v-loading="loading">
    <section>
      <el-form :inline="true">
        <el-form-item class="search_items">
          <el-button type="danger" :icon="Plus" @click="Cherished_desire_edit('add')">添加心愿</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="tableData">
        <el-table-column prop="id" label="ID" width="80"/>
        <el-table-column prop="name" label="心愿名称" width="120"/>
        <el-table-column prop="cover" label="心愿图片" width="100">
          <template #default="scope">
            <div class="tableImg">
              <img :src="scope.row.cover"/>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="心愿描述" width="200px"/>
        <el-table-column prop="high_line" label="许愿上线" width="100">
          <template #default="scope">
            <div>
              <div style="margin-bottom: 10px;margin-left: 5px">{{ scope.row.stock }}</div>
              <el-tag class="ml-2 marginTop10" type="danger" @click="Update_line(scope.row)">查看</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="stockUsed" label="累计许愿次数" width="80">
        </el-table-column>
        <!--本次许愿通知次数-->
        <el-table-column prop="notifyCount" label="通知次数" width="80">
          <!--按钮-通知-->
          <template #default="scope">
            <div style="margin-bottom: 10px;margin-left: 5px">{{ scope.row.notifyCount }}</div>
            <el-tag class="ml-2" type="success" @click="notifyUsers(scope.row)">通知</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="questionCount" label="题目" width="120">
          <template #default="scope">
            <div>
              <div style="margin-bottom: 10px;margin-left: 5px">{{ scope.row.questionCount || 0 }}道题目</div>
              <el-tag class="ml-2 marginTop10" @click="subject_detail(scope.row)">查看</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="80">
          <template #default="scope">{{ scope.row.status == 0 ? '下架' : '上架' }}</template>
        </el-table-column>
        <el-table-column prop="startTime" label="上线时间" width="100">
        </el-table-column>
        <el-table-column prop="endTime" label="结束时间" width="100"/>
        <el-table-column prop="createTime" label="创建时间" width="100"/>
        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-tag class="ml-2" type="danger" @click="changeLine(scope.row)">
              {{ scope.row.status == 0 ? '上架' : '下架' }}
            </el-tag>
            <el-tag class="ml-2" @click="Cherished_desire_edit('edit',scope.row)">编辑</el-tag>
            <el-tag class="ml-2" type="success" @click="remove(scope.row)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
          v-model:currentPage="pageCurrent"
          v-model:page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
      />
    </section>
  </div>
</template>
<script>

import {Plus, Search} from '@element-plus/icons-vue'
import {ElMessage, ElMessageBox} from 'element-plus'
import {
  ModifyWishStatusByWishId,
  notifyWishUserByWishId,
  QueryWishListByWishSearchVo,
  removeWishByWishId,
} from '../../config/Api'

export default {
  data() {
    return {
      Search: Search,    //图标
      Plus: Plus,
      pageCurrent: 1,
      pageSize: 5,
      total: 1,
      tableData: [],
      loading: false,
    }
  },
  mounted() {
    let query = this.$route.query
    this.pageCurrent = Number(query.page || 1)
    this.pageSize = Number(query.size || 5)
    this.getWishList()
  },
  methods: {
    // 获取心愿列表
    getWishList() {
      let param = {
        cunPage: this.pageCurrent,
        pageSize: this.pageSize,
      }
      this.loading = true
      QueryWishListByWishSearchVo(param).then(res => {
        this.loading = false
        this.tableData = res.result
        this.total = res.data.totalCount
      }, err => {
        this.loading = false
        ElMessage.error(`获取心愿列表失败：${err.msg}`)
      })
    },
    // 上一页下一页
    handleCurrentChange() {
      this.$router.push({
        path: '/Cherished_desire_list',
        query: {page: this.pageCurrent, size: this.pageSize},
      })
      this.getWishList()
    },

    // 上下架心愿
    changeLine(row) {
      ElMessageBox.confirm(
          row.status == 2 ? '是否下架？' : '是否上架？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          },
      ).then(() => {
        let param = {
          type: row.status == 0 ? 2 : 0,
          wishId: row.id,
        }
        this.loading = true
        ModifyWishStatusByWishId(param).then(res => {
          this.loading = false
          this.getWishList()
        }, err => {
          this.loading = false
          ElMessage.error(`上下架失败：${err.msg}`)
        })
      })
    },

    // 删除
    remove(row) {
      ElMessageBox.confirm(
          '是否删除?',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          },
      ).then(() => {
        let param = {
          wishId: row.id,
        }
        this.loading = true
        removeWishByWishId(param).then(res => {
          this.loading = false
          this.getWishList()
        }, err => {
          this.loading = false
          ElMessage.error(`删除失败:${err.msg}`)
        })
      })

    },
    // 跳转编辑
    Cherished_desire_edit(string, row) {
      if (string == 'edit') {
        sessionStorage.setItem('wish', JSON.stringify(row))
        this.$router.push('/Cherished_desire_list/edit?type=edit')
      } else {
        sessionStorage.setItem('wish', '')
        this.$router.push('/Cherished_desire_list/edit?type=add')
      }

    },
    // 跳转许愿详情
    detail(scope) {
      ElMessage.success('开发中')
      // this.$router.push('/Cherished_desire_list/detail')
    },
    // 查看心愿库存
    Update_line(row) {
      this.$router.push({
        path: '/Cherished_desire_list/update_line',
        query: {id: row.id, b: `${this.pageCurrent}_${this.pageSize}`},
      })
    },
    //模板通知本期未许愿的用户
    notifyUsers(row) {
      //弹窗让用户再次确认
      ElMessageBox.confirm(
          '是否通知本期未许愿的用户？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          },
      ).then(() => {
        //调用接口
        let param = {
          wishId: row.id,
        }
        this.loading = true
        notifyWishUserByWishId(param).then(res => {
          this.loading = false
          ElMessage.success('通知成功')
          this.getWishList()
        }, err => {
          this.loading = false
          ElMessage.error(`通知失败:${err.msg}`)
        })

      })
    },
    //
    subject_detail(row) {
      this.$router.push({
        path: '/Cherished_desire_list/Subject_list',
        query: {id: row.id, b: `${this.pageCurrent}_${this.pageSize}`, name: row.name},
      })
    },
  },
}
</script>
<style scoped lang="scss">
.Cherished_desire_list {
  margin-top: 20px;
}

.tableImg {
  img {
    width: 60px;
  }
}
</style>
