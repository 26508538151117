<template>
  <div class="searchForm" v-loading = "loading">
    <el-form label-width="200px" label-suffix="：">
      <el-form-item label="事件名称">
        <el-input v-model="template.name"></el-input>
      </el-form-item>
      <el-form-item label="每批次发送人数">
        <el-input-number v-model="template.batchCount" :max="1000" :min="1" :step="100"></el-input-number>
      </el-form-item>
      <el-form-item label="每批次发送的间隔时间">
        <el-input-number v-model="template.batchSpaceTime"></el-input-number>
      </el-form-item>
      <el-form-item label="第一次发送的延迟时间">
        <el-input-number v-model="template.firstTime"></el-input-number>
      </el-form-item>
      <el-form-item label="打开页面">
        <el-radio-group v-model="radio">
          <el-radio :label="1">首页</el-radio>
          <el-radio :label="2">调研规则页</el-radio>
          <el-radio :label="3">活动页</el-radio>
          <el-radio :label="4">自定义</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <div v-if="radio==1">/pages/home/home</div>
        <el-input v-if="radio==2" type="number" v-model="template.page">
          <template #prepend>/pages/secTransfer/secTransfer?id=</template>
        </el-input>
        <div v-if="radio==3">/pages/activity/imgSrcLink/imgSrcLink</div>
        <el-input v-if="radio==4" v-model="template.page"></el-input>
      </el-form-item>
      <el-divider />
      <block v-for="(item,index) in kvList" :key="index">
        <el-form-item :label="item.name">
              <!-- 文字 thing -->
              <template v-if="/^(thing)/.test(item.key)">
                <el-input v-model="template.priTmplPrams[item.key]"></el-input>
              </template>
              <!-- 数字 number -->
              <template v-else-if="/^(number)/.test(item.key)">
                <el-input-number v-model="template.priTmplPrams[item.key]"></el-input-number>
              </template>

              <template v-else>
                <el-input v-model="template.priTmplPrams[item.key]"></el-input>
              </template>

              <el-alert :title="item.remark" type="success" :closable="false"/>
          
        </el-form-item>
      </block>
      <el-form-item label="文件">
        <fileTemplate sign="1" @fileData="getdata"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="sendTemplate">发送</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {sendTmplPush} from "@/config/Api";
import {downfile} from '@/utils/downfile'
import fileTemplate from "@/components/fileTemplate.vue";

export default {
  name: "editFormwork",
  data(){
    return{
      template: {
        miniprogram: '',
        priTmplId: '',
        priTmplPrams:{},
        page: '',
        batchCount: 100,
        batchSpaceTime: 5,
        firstTime: 0,
      },
      kvList:[],
      loading: '',
      userIds:[],
      radio: 1,
    }
  },
  components: {
    fileTemplate
  },
  mounted() {
    if(sessionStorage.getItem('template')){
      let template = JSON.parse(sessionStorage.getItem('template'))
      this.template.priTmplId = template.priTmplId;
      this.analysis(template.content);
      console.log(template);
    } else {
      this.$router.push({path:'/formwork'})
    }
  },
  methods:{
    downfile(href, name) {
      downfile(href, name)
    },
    // 解析模板内容
    analysis(content){
      let contentList = content.split('\n');
      let kvList = [];
      for(let i in contentList){
        if(!contentList[i]) continue;
        let obj = {};
        obj.name = contentList[i].split(':')[0];
        obj.key = contentList[i].split(':')[1].replace(/[{,}]/g,'').split('.')[0];
        obj.remark = this.setRemark(obj.key)
        kvList.push(obj);
      }
      this.kvList = kvList;
      console.log(kvList);
    },
    // 生成备注
    setRemark(str){
      str = str.replace(/\d*/g,'');
      let remark = '';
      switch(str){
        case 'thing':
          remark = '20个以内字符,可汉字、数字、字母或符号组合';
          break;
        case 'number':
          remark = '32位以内数字,只能数字，可带小数';
          break;
        case 'letter':
          remark = '2位以内字母,只能字母';
          break;
        case 'symbol':
          remark = '5位以内符号,只能符号';
          break;
        case 'character_string':
          remark = '32位以内数字、字母或符号,可数字、字母或符号组合';
          break;
        case 'time':
          remark = '24小时制时间格式（支持+年月日）,例如：15:01，或：2019年10月1日 15:01';
          break;
        case 'date':
          remark = '年月日格式（支持+24小时制时间）,例如：2019年10月1日，或：2019年10月1日 15:01';
          break;
        case 'phone_number':
          remark = '17位以内，数字、符号。电话号码，例：+86-0766-66888866';
          break;
        case 'car_number':
          remark = '车牌号码：粤A8Z888挂';
          break;
        case 'name':
          remark = '10个以内纯汉字或20个以内纯字母或符号';
          break;
        case 'phrase':
          remark = '5个以内纯汉字，例如：配送中';
          break;
      }
      return remark ;
    },
    // 获取数据
    getdata(e){
      console.log(e);
      let userIds = [];
      for(let i in e){
        userIds.push(e[i].用户ID)
      }
      this.userIds = userIds;
    },

    // 发送
    sendTemplate(){
      if(!this.userIds?.length){
        return ElMessage.error('没有数据')
      }
      // if(!this.checkTemplate()) return ;
      let template = JSON.parse(JSON.stringify(this.template));
      if(this.radio==1){
        template.page = '/pages/home/home'
      }else if(this.radio==2){
        if(!template.page) return ElMessage.error('请输入调研ID');
        template.page = '/pages/secTransfer/secTransfer?id=' + template.page;
      }else if(this.radio==3){
        template.page = '/pages/activity/imgSrcLink/imgSrcLink'
      }else if(this.radio==4){
        if(!template.page) return ElMessage.error('请输入页面地址');
      }
      let arr = [];
      for(let i in template.priTmplPrams){
        arr.push({key:i,value:template.priTmplPrams[i]})
      }
      template.priTmplPrams = JSON.stringify(arr);
      let param = {
        userIds: this.userIds.join(','),
        ...template
      };
      sendTmplPush(param);
      this.$router.push({path:'/formwork'})
    },
    // 检查模板数据是否正确
    checkTemplate(){
      let template = this.template;
      console.log(template);
      let priTmplPrams = template.priTmplPrams
      for(let k in priTmplPrams){
        let str = k.replace(/\d*/g,'');
        switch(str){
          case 'thing':
            
            return false;
        }
      }
      return false;
    }
  }
  
}
</script>

<style scoped>

</style>