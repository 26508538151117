<template>
  <div class="admin" v-loading.fullscreen.lock="loading">
    <section class="searchForm">
      <el-form inline>
        <el-form-item label="账户名">
          <el-input v-model="name" @keyup.enter="search" placeholder="请输入账户名"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="email" @keyup.enter="search" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="isLocked">
            <el-option label="禁用" :value="1"></el-option>
            <el-option label="正常" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button type="primary" @click="clearSearch">清除</el-button>
          <el-button @click="openModify">添加</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="adminList">
        <el-table-column label="id" prop="id" width="80"></el-table-column>
        <el-table-column label="name" prop="name"></el-table-column>
        <el-table-column label="email" prop="email"></el-table-column>
        <el-table-column label="角色">
          <template #default="scope">
            <el-tag v-for="(item,index) in scope.row.adminRoleRespDTOS" class="ml-2" :key="index">{{item.role.roleName}}</el-tag>
            <el-button type="primary" size="small" @click="openRoleDialog(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template #default="scope">
            {{(scope.row.isLocked===0&&'正常')||(scope.row.isLocked===1&&'封禁')}}
          </template>
        </el-table-column>
        <el-table-column label="createTime" prop="createTime"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="primary" @click="openModify(scope.row)">修改</el-button>
            <el-button type="warning" @click="modifyAdminActiveByAdminId(scope.row)">{{scope.row.isLocked?'解禁':'禁用'}}</el-button>
            <el-button type="danger" @click="removeAdmin(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="cunPage"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="Current_change"
      />
    </section>

    <!-- 修改用户信息弹窗 -->
    <el-dialog title="编辑用户信息" v-model="modifyDialog" width="500px">
      <el-form label-width="80px">
        <el-form-item label="name">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="密码" v-if="!formData.id">
          <el-input v-model="formData.password"></el-input>
        </el-form-item>
        <el-form-item label="email">
          <el-input v-model="formData.email"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="modifyDialog=false">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </template>
    </el-dialog>
    <!-- 编辑用户关联角色 -->
    <el-dialog v-model="modifyRoleDialog" title="编辑用户关联角色" width="900px" @close="getList">
      <el-table :data="roleList" border row-key="id" default-expand-all :tree-props="{ children: 'roles'}">
        <el-table-column label="id" prop="id" width="200px"></el-table-column>
        <el-table-column label="name" prop="roleName"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-switch v-model="scope.row.own" :key="scope.row.id" @change="changeRole(scope.row)" style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"></el-switch>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import { ElMessageBox, ElMessage } from 'element-plus';
import {queryAdminListByAdminSearchVo, removeAdminByAdminId, modifyAdminActiveByAdminId, modifyAdminByAdminId,addAdmin,queryRoleList,addAdminRole,removeAdminRoleByAdminRoleId} from '../../config/Api'
export default {
  data() {
    return {
      cunPage: 1,
      pageSize: 20,
      total: 1,
      adminList: [],
      loading: false,
      email: '',
      isLocked: '',
      name: '',
      formData: {},
      modifyDialog: false,
      modifyRoleDialog: false,
      roleList: [],
    }
  },
  mounted() {
    let quety = this.$route.query;
    this.pageCurrent = Number(quety.page || 1);
    this.pageSize = Number(quety.size || 20);
    this.getList();
  },
  methods: {
    // 获取数据
    getList() {
      let param = {
        cunPage: this.cunPage,
        pageSize: this.pageSize,
        email: this.email,
        name: this.name,
        isLocked: this.isLocked,
      }
      this.loading = true;
      queryAdminListByAdminSearchVo(param).then(res=>{
        this.adminList = res.result;
        this.total = res.data.totalCount
        this.loading = false;
      },err=>{
        this.adminList = [];
        this.total = 1;
        this.loading = false;
      })
    },
    // 上一页下一页
    Current_change() {
      this.$router.push({
        path: '/permission',
        query: {page: this.pageCurrent, size: this.pageSize}
      });
      this.getList();
    },
    // 搜索
    search(){
      this.cunPage = 1;
      this.getList();
    },
    // 清除搜索
    clearSearch(){
      this.cunPage = 1;
      this.name = '';
      this.email = '';
      this.isLocked = '';
      this.getList();
    },
    // 删除管理员
    removeAdmin(row){
      ElMessageBox.confirm(
        '是否删除',
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
      .then(() => {
        this.loading = true;
        removeAdminByAdminId({adminId: row.id}).then(res => {
          this.loading = false;
          this.getList();
          ElMessage.success('删除成功');
        }, err => {
          this.loading = false;
          ElMessage.error('删除失败');
        })
      })
      .catch(() => {
        ElMessage.success('取消删除');
      })
    },
    // 禁用
    modifyAdminActiveByAdminId(row){
      ElMessageBox.confirm(
        row.isLocked?'是否解禁管理员':'是否禁用管理员',
        '提示',
        {
          confirmButtonText: row.isLocked?'解禁':'禁用',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        this.loading = true;
        modifyAdminActiveByAdminId({adminId: row.id, isLocked: row.isLocked?0:1}).then(res => {
          this.loading = false;
          this.getList();
          ElMessage.success('操作成功');
        },err => {
          this.loading = false;
          ElMessage.error('操作失败');
        })
      })
    },
    // 打开编辑弹窗
    openModify(row){
      if(row){
        this.formData = {
          name: row.name,
          email: row.email,
          id: row.id,
        }
      } else {
        this.formData = {
          name: '',
          email: '',
          id: ''
        }
      }
      this.modifyDialog = true;
    },
    // 编辑确定按钮
    submit() {
      if(this.formData.id){
        this.modifyAdminByAdminId();
      } else {
        this.addAdmin();
      }
    },
    // 编辑管理员
    modifyAdminByAdminId(){
      if(!this.formData.name) return ElMessage.error('请输入名字');
      this.loading = true;
      let param = {
        adminId: this.formData.id,
        email: this.formData.email,
        name: this.formData.name,
        password: 'a'
      }
      modifyAdminByAdminId(param).then(res => {
        this.loading = false;
        this.getList();
        this.modifyDialog = false;
      },err => {
        this.loading = false;
        ElMessage.error('修改失败')
      })
    },
    // 添加管理员
    addAdmin(){
      if(!this.formData.name) return ElMessage.error('请输入名字');
      if(!this.formData.password) return ElMessage.error('请输入密码');
      this.loading = true;
      let param = {
        email: this.formData.email,
        name: this.formData.name,
        password: this.formData.password
      }
      addAdmin(param).then(res => {
        this.loading = false;
        this.getList();
        this.modifyDialog = false;
      },err => {
        this.loading = false;
        ElMessage.error('添加管理员失败')
      })
    },
    // 打开添加角色
    openRoleDialog(row){
      this.saveRow = row;
      this.loading = true;
      queryRoleList({}).then(res=>{
        let roleList = res.result;
        roleList = d(roleList);
        console.log(roleList)
        this.roleList = roleList;
        this.loading = false;
        this.modifyRoleDialog = true;
      },err=>{
        this.roleList = [];
        this.loading = false;
      })
      function d (list){
        for(let i in list){
          for(let n in row.adminRoleRespDTOS){
            if(row.adminRoleRespDTOS[n].roleId == list[i].id){
              list[i].own = true;
            }
          }
          if(!list[i].roles.length){
            delete list[i].roles
          }
        }
        for(let i in list){
          if(list[i].roles?.length){
            list[i].roles = d(list[i].roles);
          }
        }
        return list;
      }
    },
    changeRole(row){
      if(row.own){
        this.addAdminRole(row.id)
      }else{
        this.removeAdminRoleByAdminRoleId(row.id)
      }
    },
    // 添加角色
    addAdminRole(roleId){
      this.loading = true;
      let param = {
        adminId: this.saveRow.id,
        roleId: roleId
      }
      addAdminRole(param).then(res => {
        this.loading = false;
        ElMessage.success('添加成功')
      },err=>{
        this.loading = false;
        this.roleList = d(this.roleList)
        function d (list){
          for(let i in list){
            if(list[i].id == roleId){
              list[i].own = !list[i].own;
            }
          }
          for(let i in list){
            if(list[i].roles?.length){
              list[i].roles = d(list[i].roles);
            }
          }
          return list;
        }
        ElMessage.error('修改失败')
      })
    },
    // 删除角色
    removeAdminRoleByAdminRoleId(roleId){
      this.loading = true;
      let param = {
        adminId: this.saveRow.id,
        roleId: roleId
      }
      removeAdminRoleByAdminRoleId(param).then(res => {
        this.loading = false;
      },err=>{
        this.loading = false;
        this.roleList = d(this.roleList)
        function d (list){
          for(let i in list){
            if(list[i].id == roleId){
              list[i].own = !list[i].own;
            }
          }
          for(let i in list){
            if(list[i].roles?.length){
              list[i].roles = d(list[i].roles);
            }
          }
          return list;
        }
        ElMessage.error('修改失败')
      })
    }
  }
}
</script>