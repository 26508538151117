<script setup>
import { ref, onMounted } from 'vue';
import {uploadFile,readExeclFile,downfile} from '@/utils/file'
import { ElMessage } from 'element-plus';

let props = defineProps(['sign']);
let emit = defineEmits(['fileData']);

let fileData = ref([]);
let tableHead = ref([]);
let dialog = ref(false);


onMounted:{
  console.log(props.sign);
}
// 选择文件
function selectFile() {
  uploadFile({}).then(
    res => {
      console.log(res);
      readFile(res)
    },
    err => {
      console.log(err);
      ElMessage.error(err)
    }
  )
}
// 读取问卷
function readFile(file){
  readExeclFile(file).then(
    res => {
      console.log(res);
      checkData(res,file)
    },
    err => {
      ElMessage.error(err);
    }
  )
}
// 数据验证
function checkData(data,file) {
  let header = [];
  let sign = Number(props.sign);
  switch (sign) {
    case 1 :
      header = ['用户ID'];
      break;
    case 2 :
      header = ['userid','number'];
      break;
    case 3 :
      header = ['用户ID','获奖金额'];
      break ;
    case 4 :
      header = ['产品名称','副标题','描述','品牌名称','品类id','仓库id','派送数量','价格','是否可见','是否需要评论','派发后允许评论时间'];
      break ;
    case 5 :
      header = ['user_id','发货仓','order_carrier','order_logistics','ship_time','店铺名称','仓库系统单号','order_number','收件人','省','市','区','手机','固话','邮编','网名','地址','发货条件','应收合计','邮费','优惠金额','客服备注','pid','货品数量'];
      break ;
    case 6: 
      header = ['userId']; 
      break;
  }
  // 检验表头是否符合
  let keys = Object.keys(data[0]);
  console.log(keys);
  console.log(header);
  for(let i in keys){
    if(!header.includes(keys[i])){
      fileData.value = [];
      ElMessage.error('请使用规定模板上传数据1');
      return ;
    }
  }
  fileData.value = data;
  tableHead.value = keys;
  emit('fileData',data);
  emit('file',file)
}
// 下载模板
function down() {
  let sgin = Number(props.sign);
  console.log(sgin);
  switch (sgin) {
    case 1 :
      downfile('/xlsx/用户ID模板.xlsx','用户ID模板');
      break ;
    case 2 :
      downfile('/xlsx/批量修改心愿币模板.xlsx','批量修改心愿币模板');
      break ;
    case 3 :
      downfile('/xlsx/用户获奖名单模板.xlsx','获奖名单模板');
      break ;
    case 4 :
      downfile('/xlsx/批量添加产品模板.xlsx','批量添加产品模板');
      break ;
    case 5 :
      downfile('/xlsx/订单更新模板.xlsx','订单更新模板');
      break ;
    case 6: 
      downfile('/xlsx/批量修改用户信息模板.xlsx','批量修改用户信息模板');
      break; 
    default : 
      ElMessage.error('不知道下载什么模板')
  }
}
// 查看数据
function look() {
  dialog.value = true;
}
</script>
<template>
  <el-button type="primary" size="small" @click="selectFile">上传</el-button>
  <el-button type="success" size="small" v-show="fileData.length" @click="look">查看</el-button>
  <el-button type="text" @click="down">下载模板</el-button>
  <el-dialog title="文件数据" v-model="dialog">
    <el-table border :data="fileData" max-height="500px">
      <el-table-column  type="index" width="50"></el-table-column>
      <el-table-column v-for="(key,index) in tableHead" :key="index" :label="key" :property="key"></el-table-column>
    </el-table>
  </el-dialog>
</template>
<style scoped></style>