<template>
  <div class="topicList" v-loading="loading">
    <section class="searchForm">
      <el-button @click="openAdd">添加</el-button>
    </section>
    <section class="tableList">
      <el-table :data="tableData">
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="name" label="名称" width="200"></el-table-column>
        <el-table-column prop="image" label="用户信息" width="120">
          <template #default="scope">
            <img style="width:50px" :src="scope.row.image" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="content" label="心愿盒描述"></el-table-column>
        <el-table-column prop="" label="产品" width="120">
          <template #default="scope">
            {{scope.row.productIds?scope.row.productIds.split(',').length:0}}个产品
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template #default="scope">
            <el-tag class="ml-2" type="danger" @click="openExamine(scope.row)" v-if="scope.row.status==1">审核</el-tag>
            <el-tag class="ml-2" @click="openModify(scope.row)">编辑</el-tag>
            <!-- <el-tag class="ml-2" type="success" @click="remove">删除</el-tag> -->
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="pageCurrent"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </section>

  </div>
</template>

<script>
import {ElMessage,ElMessageBox } from 'element-plus';
import {QueryBoxListByBoxSearchVo} from '../../config/Api';
export default {
  data() {
    return {
      pageCurrent: 1,
      pageSize: 7,
      total: 1,
      loading: false,
      tableData: [],
    }
  },
  mounted() {
    let query = this.$route.query;
    this.pageCurrent = Number(query.page || 1);
    this.pageSize = Number(query.size || 7);
    this.getList();
  },
  methods: {
    // 上一页下一页
    handleCurrentChange(number){
      this.pageCurrent = number
      // this.$router.push({
      //   path: '/topic_list',
      //   query: {page: this.pageCurrent, size: this.pageSize}
      // })
      this.getList();
    },
    // 获取数据
    getList() {
      let param = {
        cunPage: this.pageCurrent,
        pageSize: this.pageSize,
        name:''
      }
      this.loading = true;
      QueryBoxListByBoxSearchVo(param).then(res => {
        this.loading = false;
        this.tableData = res.result;
        this.total = res.data.totalCount;
      }, err => {
        this.loading = false;
        ElMessage.error(`获取心愿盒失败：${err.msg}`)
      })
    },
    openModify(row){
      sessionStorage.setItem('wishBox',JSON.stringify(row))
      this.$router.push({
        path: '/wishBox/edit',
        query: {type:'edit'}
      })
    },
    openAdd(){
      sessionStorage.removeItem('wishBox')
      this.$router.push({
        path: '/wishBox/edit',
        query: {type:'add'}
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>