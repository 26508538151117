<template>
  <div class="Crowd_library_edit" v-loading="loading">
    <section class="throng_setting">
      <div class="throng_top">
        <el-input v-model="name" placeholder="请输入人群库名称" />
        <el-button type="danger" @click="add_group">添加分组</el-button>
      </div>
      <div class="throng_top">
        <p>人群库描述：{{content}}</p>
      </div>
      <div class="tag_setting">
         <div :class="this.groupList.length>=2?'tag_setting_icon' : 'isHide'">
           <img src="../../assets/cur_icon_and_pink.png" v-show="this.grounp_type==0" @click="set_grounp_type">
           <img src="../../assets/cur_icon_or_white.png" v-show="this.grounp_type==1" @click="set_grounp_type">
         </div>
         <div class="tag_setting_items">
           <div class="tag_setting_list" v-for="(item,index) in this.groupList" :key="index">
             <div class="tag_setting_list_contain">
               <div>
                 <img src="../../assets/cur_icon_delete.png" @click="delete_group(index)">
                 <span>{{item.name}}</span>
               </div>
               <div class="cursor" @click="add_tag(item,index)">
                 <img src="../../assets/cur_icon_add.png">
                 <span>添加</span>
               </div>
             </div>
             <div class="tag_setting_padding_left ">
               <div class="tag_dispatch_main">
                 <div class="tag_dispatch_item" v-for="(item2,index2) in item.tagList" :key="item2.id">
                   <div class="tag_dispatch_select region_text">
                     <span>{{item2.value}}</span>
                   </div>
                   <img class="cur_icon_delete" src="../../assets/cur_icon_delete.png" @click="delete_tag(index,index2)">
                 </div>
               </div>
             </div>
           </div>
         </div>
      </div>
      <div class="throng_bottom" v-if="groupList.length">
        <el-button type="danger" @click="submitBtn">确定</el-button>
      </div>
    </section>
    <!-- 标签组弹窗 -->
    <el-dialog v-model="is_dialog_more" title="添加更多标签" :width="isEdit?'850px':'620px'">
      <div class="moveBox">
        <div class="moveBoxRight" v-if="isEdit">
          <el-input v-model="filterText" @change="inputChange"></el-input>
          <el-tree ref="treeRef" :data="treeData" :props="props" accordion @node-click="moveNodeClick" :filter-node-method="filterNode"></el-tree>
        </div>
        <div class="moveBoxLeft">
          <el-transfer
            v-model="rightData"
            :props="{
              key: 'id',
              label: 'value',
            }"
            :data="leftData"
            :titles="titles"
          >
            <template #default="{ option }">
              <el-tooltip placement="top" :content="option.value">
                <span>{{ option.parentName }} - {{ option.value }}</span>
              </el-tooltip>
            </template>
          </el-transfer>
          <el-form v-if="leftData.length&&leftData[0].parentName.includes('年龄')">
            <el-form-item label="最小年龄">
              <el-input-number v-model="minYear"></el-input-number>
            </el-form-item>
            <el-form-item label="最大年龄">
              <el-input-number v-model="maxYear"></el-input-number>
            </el-form-item>
            <el-form-item>
              <el-button @click="sureYear">确定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <template #footer>
        <el-button @click="is_dialog_more=false">取消</el-button>
        <el-button type="primary" @click="sureTags">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {getTargetAudienceInfo,HandleTargetAudienceInfo} from "../../config/Api";
import {ElMessage} from 'element-plus';
import global_api from "@/config/Api";
export default {
  name: 'Crowd_library_edit',
  data(){
    return {
      tagList: [],
      checkGroupList: [],
      groupList: [],
      tags: [],
      tagDialog: false,
      checkTagList: [],
      groupIndex: 0,
      name: '',
      grounp_type:0,
      is_dialog_more:false,     //添加更多标签的弹框
      loading: false,
      content: '',

      type:1,
      start_age:'',
      end_age:'',

      titles:['待选择','已选择'],
      treeData: [],
      props: {
        label: 'name',
        children: 'tagRespDTOS',
      },
      leftData:[],
      rightData:[],
      isEdit: false,

      filterText: '',
      minYear: 0,
      maxYear:0,
    }
  },
  mounted() {
    let query = this.$route.query;
    if(query.type === 'edit'){
      let crowd = JSON.parse(sessionStorage.getItem('crowd'));
      console.log(crowd)
      this.name = crowd.name
      this.grounp_type = crowd.type
      let groupList = [];
      for(let i in crowd.targetAudienceConditionGroupDTOList){
        groupList.push(crowd.targetAudienceConditionGroupDTOList[i].tagCategoryDTO);
        console.log(groupList)
        for(let n in groupList){
          if(groupList[n].name.includes('年龄')){
            groupList[n].tagList = this.bubbleSort(groupList[n].tagList)
          }
        }
      }
      this.groupList = groupList;
      this.buildContent();
    }
  },
  methods: {
    // 切换与或非状态
    set_grounp_type(){
      if(this.grounp_type==0){
        this.grounp_type = 1
      }else{
        this.grounp_type = 0
      }
    },
    add_group(){      //添加分组
      this.loading = true;
      global_api.QueryTagListByTagSearchVo({cunPage:0,pageSize:0}).then((res)=>{     //获取标签列表
        this.loading = false;
        let list = res.result;
        this.treeData = list;
        this.leftData = [];
        this.rightData = [];
        this.is_dialog_more = true;
        this.isEdit = true;
      },err=>{
        this.loading = false;
        ElMessage.error('获取标签失败')
      })
    },
    // 删除组
    delete_group(index){
      let groupList = this.groupList
      groupList.splice(index,1);
      this.groupList = groupList;
      this.buildContent();
    },

    add_tag(item){
      global_api.QueryTagListByTagSearchVo({cunPage:0,pageSize:0}).then((res)=>{     //获取标签列表
        this.loading = false;
        let list = res.result;
        let rightData = [];
        for(let i in item.tagList){
          rightData.push(item.tagList[i].id)
        }
        console.log(item)
        let leftData = d(list,[],item.id);
        console.log(leftData)
        console.log(rightData)
        this.leftData = leftData;
        this.rightData = rightData;
        this.treeData = list;
        this.is_dialog_more = true;
        this.isEdit = false;
      },err=>{
        this.loading = false;
        ElMessage.error('获取标签失败')
      })
      // 递归找到标签组
      function d(data,arr,id,){
        for(let i in data){
          if(data[i].id === id){
            console.log(data[i])
            let tags = JSON.parse(JSON.stringify(data[i].tags))
            for(let n in tags){
              tags[n].parentName = data[i].name;
              tags[n].parentId = data[i].id;
            }
            arr = tags;
          }
          if(data[i].tagRespDTOS.length){
            arr = d(data[i].tagRespDTOS,arr,id)
          }
        }
        return arr;
      }
    },

    // 删除标签
    delete_tag(index,index2) {
      this.groupList[index].tagList.splice(index2,1);
      this.buildContent();
    },
    // 添加人群库
    submitBtn(){
      let groupList = this.groupList;
      let targetAudienceConditionGroupBos = [];
      if(!this.name) {
        ElMessage.error('请输入人群库名称');
        return ;
      }

      for(let i in groupList){
        if(!groupList[i].tagList?.length){
          ElMessage.error(`标签组“${groupList[i].name}"为选择具体标签！`);
          return ;
        }
        let tagList = [];
        groupList[i].tagList.map(item => tagList.push(item.id));
        targetAudienceConditionGroupBos.push({
          group: groupList[i].name,
          groupOriginId: groupList[i].id,
          groupPriority: 0,
          groupType: 1,
          tagIdList: tagList.join(',')
        })
      }
      let param = {
        content: this.content,
        name: this.name,
        creator: localStorage.getItem('id'),
        creatorType: 0,
        type:this.grounp_type,
        targetAudienceConditionGroupBos: JSON.stringify(targetAudienceConditionGroupBos)
      }
      if(this.$route.query.type=='edit') param.id = JSON.parse(sessionStorage.getItem('crowd')).id;
      this.loading = true;
      HandleTargetAudienceInfo(param).then(res=>{
        this.loading = false;
        let query = this.$route.query.b.split('_')
        this.$router.push({
          path: '/Crowd_library_list',
          query: {page:query[0],size:query[1]}
        })
      },err => {
        this.loading = false;
        ElMessage.error(`操作失败：${err.msg}`)
      })
    },
    // 点击树节点
    moveNodeClick(e){
      let tags = JSON.parse(JSON.stringify(e.tags))
      for(let i in tags){
        tags[i].parentName = e.name;
        tags[i].parentId = e.id;
      }
      this.leftData = [...tags];
      this.rightData = [];
    },
    // 确定选择的标签
    sureTags(){
      let groupList = this.groupList;
      let checkTagList = [];
      for(let i in this.leftData){
        for(let n in this.rightData){
          if(this.rightData[n] === this.leftData[i].id){
            checkTagList.push(this.leftData[i])
            break ;
          }
        }
      }
      if(this.isEdit){
        groupList.push({
          id:checkTagList[0].parentId,
          name:checkTagList[0].parentName,

          tagList:checkTagList
        })
      }else{
        for(let i in groupList){
          console.log(groupList[i])
          if(groupList[i].id === checkTagList[0].parentId){
            groupList[i].tagList = checkTagList
          }
        }
      }
      this.groupList = groupList;
      this.is_dialog_more = false;
      this.buildContent();
    },

    // 过滤词change
    inputChange(){
      this.$refs.treeRef.filter(this.filterText)
    },
    // 标签树过滤方法
    filterNode(value, data){
      if (!value) return true
      if(data.name.includes(value)){
        return true;
      } else {
        for(let i in data.tags){
          if(data.tags[i].value.includes(value)){
            return true;
          }
        }
        return false;
      }
    },
    // sureYear
    sureYear(){
      let leftData = this.leftData;
      let rightData = [];
      for(let i in leftData){
        if(this.minYear<=Number(leftData[i].value)&&this.maxYear>=Number(leftData[i].value)){
          rightData.push(leftData[i].id)
        }
      }
      this.rightData = rightData;
    },
    // 排序算法
    bubbleSort (list) {
      console.log(list)
      for (let i = 1; i < list.length; i++) {
        for (let j = 0; j < list.length - i; j++) {
          console.log(list[j].value)
          console.log(list[j + 1])
          if (list[j].value > list[j + 1].value) {
            let temp = list[j];
            list[j] = list[j + 1];
            list[j + 1] = temp;
          }
        }
      }
      return list;
    },
    // 生成人群库描述
    buildContent(){
      let groupList = JSON.parse(JSON.stringify(this.groupList));
      let str = '';
      for(let i in groupList){
        let pname = groupList[i].name + '：';
        let a = []
        for(let n in groupList[i].tagList){
          a.push(groupList[i].tagList[n].value)
        }
        if(groupList[i].name=='年龄'){
          let arr=[];
          let s = ''
          a.map(item=>{
            if(arr.length){
              let last = arr[arr.length-1][arr[arr.length-1].length-1];
              if(item-last == 1){
                arr[arr.length-1].push(item)
              }else{
                arr.push([item])
              }
            }else{
              arr.push([item]);
            }
          })
          arr.forEach(item=>{
            s += item[0] + '-' + item[item.length - 1] + ','
          })
          str += pname + s + '；';
        } else {
        str += pname + a.join(',') + '；';
        }
      }
      this.content = str;
    }
  },
}
</script>
<style scoped lang="scss">
.Crowd_library_edit{
  margin-top: 20px;
  .throng_setting{
    margin-top: 20px;
    padding: 16px 24px;
    border: 1px solid #E8E8EB;
    border-radius: 0.5vw;
    max-width: 500px;
    .throng_top{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .tag_setting{
      margin-top: 24px;
      padding-left: 24px;
      box-sizing: border-box;
      border-left: 1px solid #E8E8EB;
      display: flex;
      align-items: center;
      .tag_setting_icon{
        margin-left: -33px;
        width: 20px;
        height: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .tag_setting_items {
        margin-left: 20px;
        .tag_setting_list {
          border: 1px solid #E3E2E5;
          padding: 20px;
          box-sizing: border-box;
          border-radius: 5px;
          width: 470px;
          margin-top: 20px;
          .tag_setting_list_contain {
            display: flex;
            justify-content: space-between;
            img {
              width: 12px;
              height: 12px;
              margin-right: 10px;
              cursor: pointer;
            }
            span {
              font-size: 14px;
            }
          }
          .tag_setting_padding_left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 10px;
            .line {
              width: 20px;
              border: 1px solid #E8E8EB;
              border-right: none;
            }
            .icon {
              width: 20px;
              height: 20px;
              margin-left: -33px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .tag_dispatch_main {
              margin-left: 20px;
              .tag_dispatch_item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
                //margin-bottom: 20px;
                .region_text {
                  border: 1px solid #E3E2E5;
                  border-radius: 5px;
                  padding: 0px 24px;
                  box-sizing: border-box;
                  font-size: 14px;
                  color: #595961;
                  width: 200px;
                  height: 40px !important;
                  line-height: 40px;
                }
                .tag_dispatch_select {
                  position: relative;
                  cursor: pointer;
                  .left_text {
                    display: inline-block;
                    height: 40px !important;
                    line-height: 40px;
                  }
                  .center_text {
                    display: inline-block;
                    height: 40px !important;
                    line-height: 40px;
                    margin-left: 10px;
                  }
                  .right_text {
                    display: inline-block;
                    height: 40px !important;
                    line-height: 40px;
                    margin-left: 10px;
                  }
                }
                .cur_icon_delete {
                  width: 12px;
                  height: 12px;
                  margin-left: 10px;
                  cursor: pointer;
                }
              }

            }
          }
        }
        .tag_setting_list:nth-child(1){
          margin-top: 0px;
        }
      }
    }
    .throng_bottom{
      text-align: right;
      margin-top: 20px;
    }
  }
  .dialog_more_btn{
    //text-align: right;
    margin-top: 20px;
  }
}
.search_items{
  float: none !important;
}
.moveBox{
  display: flex;
  .moveBoxLeft{
    width: 580px;
  }
  .moveBoxRight{
    height: 500px;
    width: 250px;
    overflow: auto;
  }
}
</style>
