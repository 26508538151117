<template>
  <div class="Variety_detail" v-loading="loading">
    <section class="search_btn">
      <div class="search_items">
        <span class="title">产品名称：</span>
        <div>
          <el-input
              v-model="this.productName"
              class="w-50 m-2"
              size="large"
              placeholder="请输入产品名称"
          ></el-input>
        </div>
      </div>
      <div class="search_items">
        <el-button size="small" type="primary" :icon='Search' @click="QueryProductShopList">查询</el-button>
        <el-button type="danger" :icon="Plus" @click="edit_detail('add')">录入详情</el-button>
      </div>
    </section>
    <section class="tableList">
      <el-table :data="tableList">
        <el-table-column prop="spuId" label="杂货铺spuID" width="150"/>
        <el-table-column prop="productIds" label="产品ID" width="100"/>
        <el-table-column prop="productResources" label="简略图">
          <template #default="scope">
            <img class="productResources_img" :src="scope.row.images.length>=1?scope.row.images[0].url:''">
            <el-tag class="ml-2" type="success" @click="this.examine(scope.row)">查看</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="productResources" label="轮播图" width="150">
          <template #default="scope">
            <img class="productResources_img" :src="scope.row.bannerImg.length>=1?scope.row.bannerImg[0].url:''">
            <el-tag class="ml-2" type="success" @click="this.examine(scope.row)">查看</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="productName" label="产品名称" width="150"/>
        <el-table-column prop="sentRate" label="好评率" width="100"/>
        <el-table-column prop="sentCountFinish" label="评测发放名额(已评论)" width="170"/>
        <el-table-column prop="sentCount" label="评测发放名额(总)" width="150"/>
        <el-table-column prop="productTag" label="高频词" width="100">
          <template #default="scope">
            <div v-for="(item,index) in (scope.row.productTag?JSON.parse(scope.row.productTag):'')">{{item.name}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="isActive" label="上架/下架" width="100">
          <template #default="scope">
            {{scope.row.isActive==1?'上架':'下架'}}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="150"/>
        <el-table-column prop="id" label="操作" width="200">
          <template #default="scope">
            <el-tag class="ml-2" type="danger" @click="Is_reveal(scope)">{{scope.row.isActive==1?'下架':'上架'}}</el-tag>
            <el-tag class="ml-2" @click="edit_detail('edit',scope)">编辑</el-tag>
            <el-tag class="ml-2" type="success" @click="remove(scope.row)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
          v-model:currentPage="cunPage"
          v-model:page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="Current_change"
      />
    </section>
  </div>
</template>

<script>
import { ElMessage,ElMessageBox } from 'element-plus'
import global_api from "@/config/Api";
import {Search,Plus} from "@element-plus/icons-vue";
export default {
  data() {
    return {
      Plus:Plus,
      Search:Search,    //图标
      productTitle:'',
      cunPage: 1,
      pageSize: 7,
      total: 1,
      loading: false,
      tableList: [],
      productName:''
    }
  },
  mounted() {
     this.QueryProductShopList()
  },
  methods: {
    Is_reveal(scope){                           // 是否上架
      if(scope.row.isActive==0){
        if(scope.row.bannerImg.length==0){
          ElMessage.error('产品缺少轮播图');
          return ;
        }
        if(scope.row.images.length==0){
          ElMessage.error('产品缺少简略图');
          return ;
        }
      }
      ElMessageBox.confirm(
          scope.row.isActive==1?'是否下架？':'是否上架？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        this.ModifyProductShopActiveByProductShopId(scope.row.id)
      })
    },

    ModifyProductShopActiveByProductShopId(productShopId){
      global_api.ModifyProductShopActiveByProductShopId({productShopId:productShopId}).then((res)=>{
        console.log(res)
        this.QueryProductShopList()
      },err =>{
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },

    examine(row){     //查看
      this.$router.push({
        path: "/Variety_detail/image",
        query: { productId: row.productIds.split(',')[0], },
      });
    },
    QueryProductShopList(){
      this.loading = true
      let param = {
        cunPage:this.cunPage,
        pageSize:this.pageSize,
        productName:this.productName
      }
      global_api.QueryProductShopList(param).then((res)=>{
        let tableList = res.result;
        // 图片资源处理
        for(let i in tableList){
          let bannerImg = [];
          let images = [];
          for(let n in tableList[i].productResources){
            if(tableList[i].productResources[n].type == 7){
              bannerImg.push(tableList[i].productResources[n])
            }
            if(tableList[i].productResources[n].type == 9){
              images.push(tableList[i].productResources[n])
            }
          }
          tableList[i].bannerImg = bannerImg;
          tableList[i].images = images;
        }
        this.tableList = tableList;
        this.loading = false
        this.total = res.data.totalCount;
        console.log(res)
      },err =>{
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },

    // 页码改变触发
    Current_change(number){
      this.cunPage = number
      this.QueryProductShopList()
    },

    edit_detail(string ,scope){       //编辑 新增 详情
      if(string=='edit'){
        sessionStorage.setItem('variety_detail_item',JSON.stringify(scope.row))
      }
      this.$router.push({
        path: "/Variety_detail/edit",
        query: { type: string, },
      });
    },

    // 删除
    remove(row) {
      ElMessageBox.confirm(
          '是否删除?',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        let param = {
          productShopId: row.id
        };
        global_api.RemoveProductShopByProductShopId(param).then(res => {
          ElMessage({
            type: 'success',
            message: '已删除',
          });
          this.QueryProductShopList()
        },err => {
          this.loading = false;
          ElMessage.error(`删除失败:${err.msg}`)
        })
      })
    },

  }
}
</script>
<style lang="scss" scoped>
.Variety_detail{
  margin-top: 20px;
}
.productResources_img{
  width: 100px;
}
//.tableImg{
//  img{
//    width: 40px;
//  }
//}
//.description{
//  overflow: hidden;
//  text-overflow: ellipsis;
//  display: -webkit-box;
//  -webkit-line-clamp: 3;
//  -webkit-box-orient: vertical;
//}
</style>