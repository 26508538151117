<template>
  <div class="Topic_edit">
    <div class="main">
      <section class="search_btn">
        <div class="selected_items">
          <span class="title"><span class="red">*</span>话题名称：</span>
          <div>
            <el-input
                v-model="this.item_obj.title"
                class="w-50 m-2"
                size="large"
                placeholder="请输入话题名称"
            ></el-input>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>话题描述：</span>
          <div>
            <el-input
                v-model="this.item_obj.description"
                class="w-50 m-2"
                size="large"
                placeholder="请输入话题描述"
            ></el-input>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red"></span>标签：</span>
          <div>
            <el-input
                v-model="this.item_obj.label"
                class="w-50 m-2"
                size="large"
                placeholder="请输入标签"
            ></el-input>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>排序：</span>
          <div>
            <el-input-number v-model="this.item_obj.sort" :min="0"/>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>推荐：</span>
          <div>
            <el-select v-model="item_obj.isRecommend">
              <el-option label="不推荐" :value="0"></el-option>
              <el-option label="推荐" :value="1"></el-option>
            </el-select>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>关联产品最小数：</span>
          <div>
            <el-input-number v-model="this.item_obj.minProductNumber" type="textarea" :rows="10" placeholder="请输入产品最小数"/>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>关联产品最大数：</span>
          <div>
            <el-input-number v-model="this.item_obj.maxProductNumber" type="textarea" :rows="10" placeholder="请输入产品最大数"/>
          </div>
        </div>
        <div class="start_items">
          <span class="title"><span class="red">*</span>话题导语：</span>
          <div class="textarea">
            <el-input v-model="this.item_obj.subtitle" type="textarea" :rows="10" placeholder="请输入话题导语"/>
          </div>
        </div>
        <div class="start_items">
          <span class="title"><span class="red">*</span>图片上传：</span>
          <div>
            <el-upload
                class="avatar-uploader"
                :action="Get_url+'admin/file/uploadFile'"
                :headers="{token:this.token,id:this.id}"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
            >
              <img v-if="this.item_obj.cover" :src="this.item_obj.cover" class="avatar" />
              <img v-else src="../../assets/bannerDefualt.png" class="avatar"/>
            </el-upload>
            <div class="sub">{{'图尺寸：690*760 大小<=1M'}}</div>
          </div>
        </div>
      </section>
      <section>
        <div class="selected_items">
          <span class="title"></span>
          <div>
            <el-button type="primary" @click="cancel">取消</el-button>
            <el-button type="danger" @click="confirm">确认</el-button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>

import {Search,Plus} from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import global_api from "@/config/Api";
import {Get_url} from "../../config/Get_url";
export default {
  data() {
    return {
      Search:Search,    //图标
      Plus:Plus,
      Get_url:Get_url(),
      item_obj:{},
      token:localStorage.getItem('token'),
      id:localStorage.getItem('id')
    }
  },
  mounted() {
    this.Init_item_obj()
  },
  methods: {
    Init_item_obj(){    //初始化数据
      if(this.$route.query.type=='edit'){
        this.item_obj = JSON.parse(sessionStorage.getItem('topic_item'))
      }else{
        this.item_obj = {
          id:'-1',
          title:'',                 //话题名称
          description:'',           //话题描述
          label:'',                 //标签
          sort:'',                  //排序
          subtitle:'',              //导语
          cover:'',                  //话题封面图片
          minProductNumber:0,
          maxProductNumber:0,
          isRecommend: 0,
        }
      }
    },
    handleAvatarSuccess(response,uploadFile){
      this.item_obj.cover = uploadFile.response.msg
    },
    beforeAvatarUpload(rawFile){
      if (rawFile.size / 1024 / 1024 > 1) {
        ElMessage.error('上传的图片超过1MB了！')
        return false
      }
      return true
    },
    cancel(){
      this.$router.push('/Topic')
    },
    confirm(){                 //新增/修改话题
      if(this.item_obj.maxProductNumber<this.item_obj.minProductNumber){
        return ElMessage.error('产品数量设置不正确')
      }
      let obj = {
        id:this.item_obj.id,
        title:this.item_obj.title,
        description:this.item_obj.description,
        label:this.item_obj.label,
        sort:this.item_obj.sort,
        subtitle:this.item_obj.subtitle,
        cover:this.item_obj.cover,
        isRecommend:this.item_obj.isRecommend,
        maxProductNumber:this.item_obj.maxProductNumber,
        minProductNumber:this.item_obj.minProductNumber
      }
      global_api.InsertOrUpdateTopic(obj).then((res)=>{
        if(res.code!=0){
          ElMessage.error('新增/修改话题')
        }else{
          this.$router.push('/Topic')
          // console.log(res)
        }
      })
      //this.$router.push('/Cherished_desire_list')
    }
  },
}
</script>
<style scoped lang="scss">
.Topic_edit{
  margin-top: 20px;
  .start_items{
    img{
      width: 200px;
      height: 200px;
      border-radius: 10px;
    }
    .sub{
      margin-top: 10px;
      font-size: 12px;
      color: red;
    }
  }
}

.el-input__inner,.el-input-number,.el-textarea{
  width: 400px !important;
}

</style>