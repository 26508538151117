<template>
  <div class="Login">
    <img src="../../assets/xxtx.png" class="image">
    <div class="login_info">
       <div class="login-title"><span>心盒共创后台管理</span></div>
       <div class="main">
         <el-input v-model="username" placeholder="请输入用户名" />
         <el-input
             class="password"
             v-model="password"
             type="password"
             placeholder="请输入密码"
             show-password
         />
         <div class="msg">{{this.msg}}&nbsp;</div>
         <el-button class="btn" type="danger" plain @click="login_btn">登录</el-button>
       </div>
    </div>

  </div>
</template>
<script>

import global_api from "@/config/Api";
import {ElMessage} from "element-plus";
export default {
  data() {
    return {
      username:'',
      password:'',
      msg:''
    }
  },
  mounted() {

  },
  methods: {
    login_btn(){
      if(this.username!='' && this.password!='' ){
        let obj={
          username:this.username,
          password:this.password
        }
        global_api.Login(obj).then((res)=>{
          if(res.code!=0){
            this.msg = '账号或密码错误'
          }else{
            this.msg = ''
            localStorage.setItem('id',res.result.id)
            localStorage.setItem('token',res.result.token)
            localStorage.setItem('lastLogin',new Date())
            this.$router.push('/')
            // console.log(res)
          }
        },err=>{
          this.msg = err.msg
        })
      }else{
        this.msg = '请输入账号和密码'
      }
    }
  },
}
</script>
<style scoped lang="scss">
 .Login{
   width: 100%;
   position: fixed;
   background: url("../../assets/background.png");
   background-size: 100% 100%;
   left: 0;
   top:0;
   height: 100%;
   z-index: 99999;
   display: flex;
   justify-content: center;
   align-items: center;
   .image{
     width: 20vw;
     margin-right: 50px;
   }
   .login_info{
     border-radius: 1.3888888889vw;
     background-color: #fff;
     box-shadow: 0.347vw 0.625vw 1.111vw 0.277vw rgb(0 0 0 / 10%);
     padding: 7.083vw 5.347vw;
     .login-title span{
       font-size: 2.43vw;
       font-family: Microsoft YaHei;
       font-weight: 400;
       color: #f56c6c;
       position: relative;
     }
     .login-title span::before{
       content: "";
       position: absolute;
       top: 3.819vw;
       left: 0;
       right: 10px;
       width: 9.583vw;
       height: 0.347vw;
       background: #f56c6c;
     }

     .main{
       margin-top: 80px;
       .password{
         margin-top: 30px;
       }
       .btn{
         margin-top: 30px;
       }
       .msg{
         margin-top: 10px;
         color: red;
         font-size: 14px;
       }
     }

   }
 }
</style>