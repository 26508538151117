<template>
  <el-button type="primary" @click="openHideFuncDialog">隐藏用户回答</el-button>
  <el-dialog title="" v-model="dialogFlag" width="500px" v-loading="loading">
    <el-form label-suffix="：" label-width="100px">
      <el-form-item label="导入用户">
					<fileTemplate sign="6" @fileData="getdata"/>
				</el-form-item>
      <el-form-item label="用户id">
        <el-input type="textarea" rows="5" v-model="userIds"></el-input>
      </el-form-item>
      <el-form-item label="显示状态">
        <el-radio-group v-model="isVisible">
          <el-radio label="1">显示</el-radio>
          <el-radio label="0">隐藏</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="updateUserAnswerIsVisibleBatch">确定</el-button>
    </template>
  </el-dialog>
  <section class="tableList" v-loading.fullscreen.lock="loading">
    <el-row :gutter="20">
      <el-col :span="12"><span>问题名称：{{question.question?.name.replace(/<.+?>/g,'')}}</span></el-col>
      <el-col :span="12"><span>问题类型：{{question.questionType?.name}}</span></el-col>
    </el-row>
    <el-table :data="list" :span-method="spanMethod" border>
      <el-table-column label="id" prop="id" width="200"></el-table-column>
      <el-table-column label="用户ID" prop="userId" width="200"></el-table-column>
      <el-table-column label="用户" prop="nickname" width="300"></el-table-column>
      <!-- 单选题,多选题 种草 购买意愿-->
      <el-table-column label="用户回答" v-if="question.questionType?.id==1||question.questionType?.id==2||question.questionType?.id==12||question.questionType?.id==13">
        <template #default='scope'>
          <div v-for="(item,index) in question.optionList" :key="index">
            <div v-if="item.id == scope.row.optionId">{{item.name}}</div>
          </div>
        </template>
      </el-table-column>
      <!-- 简答题 -->
      <el-table-column label="用户回答" v-if="question.questionType?.id==3">
        <template #default='scope'>{{scope.row.independentAnswer}}</template>
      </el-table-column>
      <!-- 排序题 -->
      <el-table-column label="用户回答" v-if="question.questionType?.id==4">
        <template #default='scope'>
          <div v-for="(item,index) in question.optionList" :key="index">
            <div v-if="item.id == scope.row.optionId">{{scope.row.optionNO.indexOf(item.id+'')+1}}::{{item.name}}。{{scope.row.independentAnswer}}</div>
          </div>
        </template>
      </el-table-column>
      <!-- 打分题 滑条题 nps-->
      <el-table-column label="用户回答" v-if="question.questionType?.id==5||question.questionType?.id==7||question.questionType?.id==11">
        <template #default='scope'>
          {{scope.row.value}}分
        </template>
      </el-table-column>
      <!-- 比重题 -->
      <el-table-column label="用户回答" v-if="question.questionType?.id==6">
        <template #default='scope'>
          {{scope.row.value}}%
        </template>
      </el-table-column>
      <!-- 矩阵单选 矩阵多选-->
      <el-table-column label="问题" v-if="question.questionType?.id==8||question.questionType?.id==9">
        <template #default='scope'>
          <template v-for="(item,index) in question.optionList" :key="index"><span v-if="item.id==scope.row.optionId">{{item.name}}</span></template>
        </template>
      </el-table-column>
      <el-table-column label="用户回答" v-if="question.questionType?.id==8||question.questionType?.id==9">
        <template #default='scope'>
          <template v-for="(item,index) in question.optionList" :key="index"><span v-if="item.id==scope.row.value">{{item.name}}</span></template>
        </template>
      </el-table-column>
      <!-- 图片题 -->
      <el-table-column label="用户回答" v-if="question.questionType?.id==10">
        <template #default='scope'>
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.independentAnswer"
            :zoom-rate="1.2"
            :preview-src-list="[scope.row.independentAnswer]"
            :initial-index="1"
            preview-teleported
            :z-index="9999999"
            fit="cover"
          />
        </template>
      </el-table-column>
      <!-- 矩阵打分题 矩阵滑条题-->
      <el-table-column label="问题" v-if="question.questionType?.id==14||question.questionType?.id==15||question.questionType?.id==16">
        <template #default='scope'>
          <template v-for="(item,index) in question.optionList" :key="index"><span v-if="item.id==scope.row.optionId">{{item.name}}</span></template>
        </template>
      </el-table-column>
      <el-table-column label="问题2" v-if="question.questionType?.id==16">
        <template #default='scope'>
          <template v-for="(item,index) in question.optionList" :key="index"><span v-if="item.id==scope.row.value">{{item.name}}</span></template>
        </template>
      </el-table-column>
      <el-table-column label="用户回答" v-if="question.questionType?.id==16">
        <template #default='scope'>{{scope.row.independentAnswer}}</template>
      </el-table-column>
      <el-table-column label="用户回答" v-if="question.questionType?.id==14||question.questionType?.id==15">
        <template #default='scope'>
          {{scope.row.value}}分
        </template>
      </el-table-column>
      <!-- 例举题 -->
      <el-table-column label="用户回答" v-if="question.questionType?.id==18">
        <template #default='scope'>
          {{scope.row.independentAnswer}}
        </template>
      </el-table-column>
      <el-table-column label="显示状态">
        <template #default='scope'>{{scope.row.isVisible==1?'显示':'不显示'}}</template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template #default='scope'>
          <el-button @click="openDialog(scope.row)" type="primary">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
  </section>
  <section class="pagination">
    <el-pagination
      v-model:currentPage="pageCurrent"
      v-model:page-size="pageSize"
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    />
  </section>
</template>
<script>
import {queryQuestionAnswerByQuestionId,updateUserAnswerIsVisible, updateUserAnswerIsVisibleBatch} from '@/config/Api'; 
import fileTemplate from "@/components/fileTemplate.vue";
import { ElMessage } from 'element-plus'
export default {
  data(){
    return {
      pageCurrent: 1,
      pageSize: 5,
      total: 1,
      loading: 0,
      question:{},
      list:[],
      userIds: '',
      isVisible: '',
      dialogFlag: false,
    }
  },
  components: {
    fileTemplate
  },
  mounted(){
    let question = localStorage.getItem('question');
    if(question&&JSON.parse(question)){
      this.question = JSON.parse(question);
      this.getList();
    }else{
      ElMessage.error('缺少问题信息')
    }
  },
  methods:{
    getList(){
      let param = {
        cunPage: this.pageCurrent,
        pageSize: this.pageSize,
        questionId: this.question.question.id,
        questionType: this.question.questionType.id
      }
      this.loading += 1;
      queryQuestionAnswerByQuestionId(param).then(res=>{
        this.loading -= 1;
        this.list = this.reList(res.result);
        this.total = res.data.totalCount
      },err=>{
        this.loading -= 1;
        ElMessage.error(err.msg);
        this.list = [];
      })
    },
    reList(list){
      let data = []
      console.log(this.question);
      // 单选题 种草 购买意愿
      if(this.question?.questionType?.id == 1||this.question?.questionType?.id == 12||this.question?.questionType?.id == 13){
        for(let k in list){
          data.push({
            rowspan:1,
            ...list[k]
          })
        }
      }else if(this.question?.questionType?.id == 2){  // 多选题
        for(let k in list){
          let length = list[k].length;
          for(let n in list[k]){
            data.push({
              rowspan: n==0?length:0,
              ...list[k][n]
            })
          }
        }
      }else if(this.question?.questionType?.id == 3){ // 简答题
        for(let k in list){
          data.push({
            rowspan:1,
            ...list[k]
          })
        }
      }else if(this.question?.questionType?.id == 4){ // 排序题
        for(let k in list){
          let length = list[k].length;
          for(let n in list[k]){
            data.push({
              rowspan: n==0?length:0,
              ...list[k][n],
              optionNO:list[k][n].optionIndex.split(',')
            })
          }
        }
      }else if(this.question?.questionType?.id == 5){ // 打分题
        for(let k in list){
          data.push({
            rowspan:1,
            ...list[k]
          })
        }
      }else if(this.question?.questionType?.id == 6){ // 比重题
        for(let k in list){
          data.push({
            rowspan:1,
            ...list[k]
          })
        }
      }else if(this.question?.questionType?.id == 7||this.question?.questionType?.id == 11){ // 滑条题  nps
        for(let k in list){
          data.push({
            rowspan:1,
            ...list[k]
          })
        }
      }else if(this.question?.questionType?.id == 8){ // 矩阵单选
        for(let k in list){
          data.push({
            rowspan:1,
            ...list[k]
          })
        }
      }else if(this.question?.questionType?.id == 9){ // 矩阵多选
        for(let k in list){
          let length = list[k].length;
          for(let n in list[k]){
            data.push({
              rowspan: n==0?length:0,
              ...list[k][n]
            })
          }
        }
      }else if(this.question?.questionType?.id == 10){ // 图片题
        for(let k in list){
          data.push({
            rowspan:1,
            ...list[k]
          })
        }
      }else if(this.question?.questionType?.id == 14){ // 矩阵打分
        for(let k in list){
          data.push({
            rowspan:1,
            ...list[k]
          })
        }
      }else if(this.question?.questionType?.id == 15){ // 矩阵滑条
        for(let k in list){
          data.push({
            rowspan:1,
            ...list[k]
          })
        }
      }else if(this.question?.questionType?.id == 16){ // 开放题
        for(let k in list){
          data.push({
            rowspan:1,
            ...list[k]
          })
        }
      }else if(this.question?.questionType?.id == 18){ // 例举题
        for(let k in list){
          data.push({
            rowspan:1,
            ...list[k]
          })
        }
      }

      console.log(data);
      return data;
    },
    // 获取数据
    getdata(data){
      let userIds = [];
      for(let i in data){
        userIds.push(data[i].userId)
      }
      this.userIds = userIds.join(',');
    },
    // 修改用户答案显示状态
    openHideFuncDialog() {
      this.dialogFlag = true;
    },
    // 关闭弹窗
    closeDialog() {
      this.dialogFlag = false;
    },
    updateUserAnswerIsVisibleBatch(){
      // 批量更新用户答案隐藏状态

      let userIds = this.userIds.replace(/\s/g,'')
      if(!userIds) return ElMessage.error('请输入用户id');
      if(!this.isVisible) return ElMessage.error('请选择显示状态');
      // userIds = userIds.split(',')
      console.log(userIds);
      
      console.log(this.list);

      let param = {
            userIds: userIds,
            isVisible: this.isVisible,
            questionId: this.list[0].questionId,
          }
      console.log(param);
      
      updateUserAnswerIsVisibleBatch(param).then(res => {
        this.dialogFlag = false;
        
        this.getList();
      },err => {
        this.dialogFlag = false;
        
        this.getList();
      })

      
    },
    openDialog(row){
      console.log(this.list);
      console.log(row.rowspan);
      let paramList = [];
      for(let i in this.list){
        if(this.list[i].userId == row.userId){
          paramList.push({
            answerId: this.list[i].id,
            isVisible: row.isVisible?0:1,
          })
        }
      }
      this.loading = paramList.length;
      paramList.map(item => {
        updateUserAnswerIsVisible(item).then(res => {
          this.loading -= 1;
          if(!this.loading) this.getList();
        },err => {
          this.loading -= 1;
          if(!this.loading) this.getList();
        })
      })
    },
    // 合并多行
    spanMethod(e){
      if(e.columnIndex<3&&e.columnIndex>0){
        return {
          rowspan: e.row.rowspan,
          colspan: e.row.rowspan?1:0,
        }
      }else if(e.columnIndex>=5){
        return {
          rowspan: e.row.rowspan,
          colspan: e.row.rowspan?1:0,
        }
      } else{
        return {
          rowspan: 1,
          colspan: 1
        }
      }
    }
  }
}
</script>
<style>
.el-checkbox__input.is-disabled.is-checked+span.el-checkbox__label{
  color: #409eff !important;
}
</style>