<template>
  <div>
    <div class="tool">
      <span @click="setColor">颜色<el-color-picker v-model="color" /></span>
      <span @click="setUnderline">下划线</span>
      <span @click="setStrikeThrough">删除线</span>
      <span @click="setItalic">斜体</span>
      <span @click="getData">sss</span>
    </div>
    <div class="editor">
      <div id="myeditor" ref="editor" style="height:100%" contenteditable="true"></div>
    </div>
    <div class="foot">
      <el-button>取消</el-button>
      <el-button type="primary">确定</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      color: '#ff0000'
    }
  },
  methods: {
    setColor() {
      document.execCommand('styleWithCSS',null,true);
      document.execCommand('foreColor',false,this.color);
    },
    setUnderline() {
      document.execCommand('styleWithCSS',null,true);
      document.execCommand('Underline');
    },
    setStrikeThrough(){
      document.execCommand('styleWithCSS',null,true);
      document.execCommand('StrikeThrough');
    },
    setItalic(){
      document.execCommand('styleWithCSS',null,true);
      document.execCommand('Italic');
    },
    getData(){
      console.log(document.getElementById('myeditor').innerHTML);
    }
  }
}
</script>
<style lang="scss" scoped>
.tool {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  background-color: #f1f1f1;
  span {
    height: 32px;
    padding: 0px 8px 0 6px;
    font-size: 16px;
    position: relative;
    line-height: 32px;
    color: #666;
    cursor: pointer;
    &:hover{
      color: rgb(17, 145, 219);
    }
    &::after{
      content: '';
      width: 2px;
      height: 16px;
      background-color: #666;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
    &:last-child::after{
      content: "";
      width: 0;
    }
  }
}
.editor {
  height: 200px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  div {
    font-size: 18px;
    &:focus{outline: none;}
  }
}
.foot {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 50px;
}
</style>