<template>
  <div class="Slide_show_setting" v-loading="loading">
    <section class="search_btn">
      <div class="search_items">
        <el-button type="danger" @click="edit_slide_show('add')">添加轮播图</el-button>
      </div>
    </section>
    <section class="user_info_list">
      <el-table :data="tableData">
        <el-table-column prop="id" label="ID" />
        <el-table-column prop="sort" label="排序" />
        <el-table-column prop="pathContent" label="跳转产品的ID" />
        <el-table-column prop="productName" label="跳转产品" />
        <el-table-column prop="image" label="图片" >
          <template #default="scope">
            <img :src="scope.row.image" class="avatar">
          </template>
        </el-table-column>
<!--        <el-table-column prop="pathContent" label="跳转产品" />-->
        <el-table-column prop="createTime" label="创建时间"/>
        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-tag class="ml-2" @click="edit_slide_show('edit',scope)">编辑</el-tag>
            <el-tag class="ml-2" type="success" @click="Is_delete(scope)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </section>

    <section class="pagination">
      <el-pagination :page-size="this.pageSize" :currentPage="this.pageNo" :total="this.totalCount" @current-change="Current_change" pager-count="10" background layout="prev, pager, next"></el-pagination>
    </section>

  </div>
</template>

<script>
import {Search,MessageBox} from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import global_api from "@/config/Api";
export default {
  name: 'Slide_show_setting',
  data(){
    return {
      Search:Search,    //图标
      MessageBox:MessageBox,//图标
      update_phone_dialog:false,
      options:[{
        value: 'Option1',
        label: 'Option1',
      },
        {
          value: 'Option2',
          label: 'Option2',
        }],
      pageNo:1,       //页码
      pageSize:10,      //每页数量
      totalCount:0,         //总数
      loading: false,
      tableData:[]
    }
  },
  mounted() {
    // this.global_api.test({a:1,b:2}).then((res)=>{
    //   console.log(res)
    // })
    // console.log(this.util.login())
    this.GetBannerList()
  },
  methods: {
    Current_change(number){
      this.pageNo = number
      this.GetBannerList()
    },

    GetBannerList(){       //获取首页/心愿广场轮播图列表
      let obj = {
        pageNo:this.pageNo,
        pageSize:this.pageSize,
        type:0
      }
      this.loading = true;
      global_api.GetBannerList(obj).then((res)=>{
        if(res.code!=0){
          ElMessage.error('新增/修改题目错误')
        }else{
          this.tableData = res.result
          this.loading = false;
        }
        console.log(res)
      })
    },
    Is_delete(scope){           //是否删除
      ElMessageBox.confirm(
          '是否删除?',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        let obj = {
          bannerId:scope.row.id,
          id:1,
          type:0
        }
        global_api.DeleteBannerImage(obj).then((res)=>{
          if(res.code!=0){
            ElMessage.error('新增/修改题目错误')
          }else{
            ElMessage({
              type: 'success',
              message: '已删除',
            })
            this.GetBannerList()
          }
        })
      })
    },

    edit_slide_show(string ,scope){       //编辑 新增 题目
      if(string=='edit'){
        sessionStorage.setItem('slide_item',JSON.stringify(scope.row))
      }
      this.$router.push({
        path: "/Slide_show_setting/edit",
        query: { type: string, },
      });
    }

  },
}
</script>
<style scoped lang="scss">
.Slide_show_setting{
  margin-top: 20px;
}
.search_btn{
  //border-radius: 5px;
}
.user_info_list{
  margin-top: 20px;
  min-width: 800px;
  .btn{
    margin-top: 5px !important;
  }
}
.pagination{
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.no_float{
  float: none !important;
}
.avatar{
  width: 100px;
  //height: 60px;
}

</style>
