<template>
  <div class="cashout" v-loading.fullscreen.lock="loading">
    <section class="searchForm">
      <el-form inline>
        <el-form-item label="是否开启批量审核">
          <el-switch v-model="piSwitch" @change="change1" active-text="开启" inactive-text="关闭"/>
        </el-form-item>
        <el-form-item>
          <el-button v-if="piSwitch" @click="openDialog">批量审核</el-button>
        </el-form-item>
        <el-form-item label="用户ID">
          <el-input v-model="formData.userId"></el-input>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select v-model="formData.applyStatus">
            <el-option label="" value="" />
            <el-option label="已申请" :value="1" />
            <el-option label="申请成功" :value="2" />
            <el-option label="申请失败" :value="3" />
          </el-select>
        </el-form-item>
        <el-form-item label="提现状态">
          <el-select v-model="formData.grantProgress">
            <el-option label="" value="" />
            <el-option label="待发放" :value="1" />
            <el-option label="发放中" :value="2" />
            <el-option label="发放成功" :value="3" />
            <el-option label="发放失败" :value="4" />
            <el-option label="需重新发起" :value="5" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="cunPage=1;getList()">搜索</el-button>
          <el-button  @click="cunPage=1;formData={};getList()">清空</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="list" @selection-change="handleSelectionChange" ref="multipleTableRef">
        <el-table-column type="selection" width="55" v-if="piSwitch" :selectable="selectable"/>
        <el-table-column label="ID" prop="id" width="100"></el-table-column>
        <el-table-column label="用户名" prop="username" width="200"></el-table-column>
        <el-table-column label="用户ID" prop="userId" width="100"></el-table-column>
        <el-table-column label="提现金额" prop="applyAmount" width="200"></el-table-column>
        <el-table-column label="账户余额" prop="currentAmount" width="200"></el-table-column>
        <el-table-column label="申请时间" prop="createTime" width="300"></el-table-column>
        <el-table-column label="审核状态" prop="applyStatus">
          <template #default="scope">
            {{(scope.row.applyStatus==1&&'已申请')||(scope.row.applyStatus==2&&'申请成功')||(scope.row.applyStatus==3&&'申请失败')}}
          </template>
        </el-table-column>
        <el-table-column label="提现状态" prop="grantProgress">
          <template #default="scope">
            {{(scope.row.grantProgress==0&&' ')||(scope.row.grantProgress==1&&'待发放')||(scope.row.grantProgress==2&&'发放中')||(scope.row.grantProgress==3&&'发放成功')||(scope.row.grantProgress==4&&'发放失败')||(scope.row.grantProgress==5&&'需重新发起')}}
          </template>
        </el-table-column>
        <el-table-column label="批次单号" prop="mchTransferInfo.outBatchNo"></el-table-column>
        <el-table-column label="批次状态" prop="mchTransferInfo.transferStatus"></el-table-column>
        <el-table-column label="明细单号" prop="mchTransferDetail.outDetailNo"></el-table-column>
        <el-table-column label="明细状态" prop="mchTransferDetail.detailStatus"></el-table-column>
        <el-table-column label="审核时间" prop="auditTime">
          <template #default="scope">
            {{scope.row.createTime==scope.row.auditTime?'':scope.row.auditTime}}
          </template>
        </el-table-column>
        <el-table-column label="发放时间" prop="sentTime">
          <template #default="scope">
            {{scope.row.createTime==scope.row.sentTime?'':scope.row.sentTime}}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="300">
          <template #default="scope">
            <el-popconfirm v-if="scope.row.applyStatus==1&&!piSwitch" cancel-button-text="不通过" confirm-button-text="通过" icon="none" title="是否审核通过？" @confirm="examine(scope.row,1)" @cancel="examine(scope.row,2)">
              <template #reference>
                <el-button size="small" type="success">审核</el-button>
              </template>
            </el-popconfirm>
            <el-button v-if="scope.row.grantProgress==2" @click="queryMchTransferStatus(scope.row.mchTransferInfo.id)">查询</el-button>
            <el-button v-if="scope.row.grantProgress==5" @click="tryAgainMerchantTransferByTransferId(scope.row)">重新发起批次</el-button>
          </template>
          
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="cunPage"
        page-size="10"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="getList"
      />
    </section>
    <!-- 批量审核 -->
    <el-dialog v-model="dialog" title="批量审核" width="700px">
      <div class="piShen">
        <p>审核总数：{{piList.length}}条</p>
        <p>审核总金额：{{allAmount}}元</p>
      </div>
      <el-table height='400' :data="piList">
        <el-table-column label="ID" prop="id"></el-table-column>
        <el-table-column label="用户名" prop="username"></el-table-column>
        <el-table-column label="用户ID" prop="userId"></el-table-column>
        <el-table-column label="提现金额" prop="applyAmount"></el-table-column>
        <el-table-column label="账户余额" prop="currentAmount"></el-table-column>
        <el-table-column label="申请时间" prop="createTime"></el-table-column>
      </el-table>
      <template #footer>
        <el-button @click="reviewerReviewMoneyWithdrawalBatch(2)">不通过</el-button>
        <el-button type="primary" @click="reviewerReviewMoneyWithdrawalBatch(1)">通过</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ElMessage } from 'element-plus';
import {queryUserMoneyWithdrawalListBySearchVo,reviewerReviewMoneyWithdrawal,reviewerReviewMoneyWithdrawalBatch,} from '../../../config/jsonapi';
import {queryMchTransferStatus,tryAgainMerchantTransferByTransferId} from '../../../config/Api'
export default {
  data(){
    return {
      formData:{},
      cunPage: 1,
      total: 1,
      list: [],
      loading: false,

      piSwitch: false,
      pageSelect: [],

      dialog: false,
      piList: [],
      allAmount: 0,
    }
  },
  mounted(){
    this.getList();
  },
  methods:{
    // 获取申请列表
    getList(){
      let param = {
        cunPage: this.cunPage,
        pageSize: 10,
        ...this.formData,
      }
      this.loading = true;
      queryUserMoneyWithdrawalListBySearchVo(param).then(res=>{
        this.loading = false;
        let list = res.result;
        for(let i in list){
          list[i] = {...list[i],...list[i].userMoneyWithdrawal,...list[i].detailTransferVO};
        }
        console.log(list);
        let pageSelect = JSON.parse(JSON.stringify(this.pageSelect));
        let arr = pageSelect[this.cunPage];
        setTimeout(()=>{
          if(!arr) return ;
          for(let i in list){
            for(let n in arr){
              if(list[i].id===arr[n].id){
                this.$refs.multipleTableRef.toggleRowSelection(list[i],true);
              }
            }
          }
        },0)
        this.list = list;
        this.total = res.data.totalCount;

      },err=>{
        this.loading = false;
        ElMessage.error(err.msg)
      })
    },
    // 审核
    examine(row,type){
      this.loading = true;
      let param = {
        auditResult: type,
        userMoneyWithdrawalId: row.id,
      }
      reviewerReviewMoneyWithdrawal(param).then(res=>{
        this.loading = false;
        this.getList();
      },err=>{
        this.loading = false;
        ElMessage.error(err.msg)
      })
    },
    // 查询转账结果
    queryMchTransferStatus(transferId){
      this.loading = true;
      let param = {
        transferId
      }
      queryMchTransferStatus(param).then(res=>{
        this.loading = false;
        this.getList();
      },err=>{
        this.loading = false;
      })
    },
    // 重新发起批次
    // 批量审核
    piShen(){
      ElMessage.error('未开发')
    },

    // 是否可勾选
    selectable(row){
      if(this.piSwitch){
        return row.grantProgress == 0;
      }
      return false;
    },
    // 选择多个批次
    handleSelectionChange(e){
      let pageSelect = this.pageSelect;
      pageSelect[this.cunPage] = e;
      this.pageSelect = pageSelect;
    },
    // 打开批量提现审核
    openDialog(){
      this.dialog = true;
      this.comment = '';
      let pageSelect = this.pageSelect;
      let list = [];
      let allAmount = 0;
      for(let k in pageSelect){
        if(pageSelect[k]&&pageSelect[k].length){
          list.push(...pageSelect[k])
          pageSelect[k].forEach(item => {
            allAmount += item.applyAmount;
          });
        }
      }
      this.allAmount = allAmount;
      this.piList = list;
    },
    // 批量发起请求
    reviewerReviewMoneyWithdrawalBatch(auditStatus){
      let list = this.piList;
      if(!list.length){
        ElMessage.error('请选择审核数据')
        return ;
      }
      let param = [];
      for(let i in list) {
        param.push({
          auditResult:auditStatus,
          userMoneyWithdrawalId: list[i].id
        })
      }
      this.loading = true;
      reviewerReviewMoneyWithdrawalBatch(param).then(res=>{
        this.loading = false;
        this.dialog = false;
        this.pageSelect = {};
        this.getList();
      },err=>{
        this.loading = false;
        ElMessage.error(err.msg)
      })
    },
    // 重新发起
    tryAgainMerchantTransferByTransferId(row){
      this.loading = true;
      let param = {
        transferId: row.detailTransferVO.mchTransferInfo.id
      }
      tryAgainMerchantTransferByTransferId(param).then(res=>{
        this.loading = false;
        this.getList();
      },err=>{
        this.loading = false;
        ElMessage.error(err.msg)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.piShen{
  display: flex;
  justify-content: space-around;
  align-items: center;
  p{
    font-size: 14px;
    line-height: 20px;
    color: #333;
  }
}
</style>