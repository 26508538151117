<template>
  <div class="Topic" v-loading="loading">
    <section class="search_btn">
      <div class="search_items">
        <span class="title">话题名称：</span>
        <div>
          <el-input
              v-model="this.topicName"
              class="w-50 m-2"
              size="large"
              placeholder="请输入话题名称"
          ></el-input>
        </div>
      </div>
      <div class="search_items">
        <el-button type="primary" :icon="Search" @click="this.GetTopicList">查询</el-button>
        <el-button type="danger" @click="topic_edit('add')">添加话题</el-button>
      </div>
    </section>
    <section class="user_info_list">
      <el-table :data="tableData">
        <el-table-column prop="id" label="ID" width="120" />
        <el-table-column prop="sort" label="排序" width="120" />
        <el-table-column prop="parentCategory" label="话题图片" width="150">
          <template #default="scope">
            <img :src="scope.row.cover" class="avatar">
          </template>
        </el-table-column>
        <el-table-column prop="title" label="话题名称" width="150" />
        <el-table-column prop="subtitle" label="话题导语" width="200" />
        <el-table-column prop="description" label="话题描述" width="200" />
        <el-table-column prop="childrenCategory" label="推荐" width="100">
          <template #default="scope">
            {{scope.row.isRecommend?'推荐':'不推荐'}}
          </template>
        </el-table-column>
        <el-table-column prop="childrenCategory" label="参与讨论" width="100">
          <template #default="scope">
            {{scope.row.childrenCategory?scope.row.childrenCategory+'人':0+'人'}}
          </template>
        </el-table-column>
        <el-table-column prop="label" label="标签" width="120" />
        <el-table-column prop="topicResourceList" label="视频/淘口令/公众号/小程序" width="400">
          <template #default="scope">
            <div>
              <div>视频：{{scope.row.topicResourceList[0]?scope.row.topicResourceList[0].url:''}}</div>
              <div>淘口令：{{scope.row.topicResourceList[1]?scope.row.topicResourceList[1].url:''}}</div>
              <div>公众号：{{scope.row.topicResourceList[2]?scope.row.topicResourceList[2].url:''}}</div>
              <div>小程序：{{scope.row.topicResourceList[3]?scope.row.topicResourceList[3].url:''}}</div>
              <div><el-tag class="ml-2" @click="topicResource_edit(scope)">编辑</el-tag></div>
            </div>

          </template>
        </el-table-column>
        <el-table-column prop="isActive" label="状态" width="100">
          <template #default="scope">
            {{scope.row.isActive==1?'上架':'下架'}}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="200" />
        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-tag class="ml-2" type="danger" @click="Is_reveal(scope)">{{scope.row.isActive==1?'下架':'上架'}}</el-tag>
            <el-tag class="ml-2" @click="topic_edit('edit',scope)">编辑</el-tag>
            <el-tag class="ml-2" type="success" @click="Is_delete(scope)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination :page-size="this.pageSize" :currentPage="this.pageNo" :total="this.totalCount" @current-change="Current_change" pager-count="10" background layout="prev, pager, next"></el-pagination>
    </section>

    <!-- 视频/淘口令/公众号/小程序 -->
    <el-dialog v-model="topicResource_dialogVisible" title="编辑" width="500px" draggable>
      <el-form :label-width="100" v-model="this.topicResourceList">
        <el-form-item label="视频">
          <el-input type="text" v-model="this.topicResourceList[0].url" placeholder="请输入视频链接"></el-input>
        </el-form-item>
        <el-form-item label="淘口令">
          <el-input type="text" :rows="3" v-model="this.topicResourceList[1].url" placeholder="请输入淘口令"></el-input>
        </el-form-item>
        <el-form-item label="公众号">
          <el-input type="text" :rows="3" v-model="this.topicResourceList[2].url" placeholder="请输入公众号链接"></el-input>
        </el-form-item>
        <el-form-item label="小程序">
          <el-input type="text" :rows="3" v-model="this.topicResourceList[3].appid" placeholder="请输入小程序ID"></el-input>
          <el-input type="text" :rows="3" v-model="this.topicResourceList[3].path" placeholder="请输入小程序跳转地址" style="margin-top: 20px"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="this.topicResource_dialogVisible=false">取消</el-button>
          <el-button type="primary" @click="this.OperationTopicResource">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {Search,MessageBox} from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import global_api from "@/config/Api";
export default {
  name: 'Topic',
  data(){
    return {
      Search:Search,    //图标
      MessageBox:MessageBox,//图标
      pageNo:1,       //页码
      pageSize:5,      //每页数量
      totalCount:0,         //总数
      loading: false,
      tableData:[],         //列表
      topicName:'',         //话题名称
      topicResource_dialogVisible:false,
      topicResourceList:[            //1视频;2淘口令;3公众号;4小程序appid#路径
          {topicId:16,url:'',type:1},
          {topicId:16,url:'',type:2},
          {topicId:16,url:'',type:3},
          {topicId:16,url:'',type:4,appid:'',path:''}
      ],
      index:0,            //选中的下标
    }
  },
  mounted() {
    this.GetTopicList()
  },
  methods: {
    topicResource_edit(scope){     //视频/淘口令/公众号/小程序 的弹框显示
      let topicResourceList = this.topicResourceList
      topicResourceList[0].topicId = scope.row.id
      topicResourceList[1].topicId = scope.row.id
      topicResourceList[2].topicId = scope.row.id
      topicResourceList[3].topicId = scope.row.id
      for (let i=0;i<scope.row.topicResourceList.length;i++){
        for(let j = 0;j<topicResourceList.length;j++){
          if(scope.row.topicResourceList[i].type==topicResourceList[j].type){
            topicResourceList[j].url = scope.row.topicResourceList[i].url
          }
        }
      }
      if(topicResourceList[3].url.length>0 && topicResourceList[3].url.includes('#')){
        topicResourceList[3].appid = topicResourceList[3].url.split('#')[0]
        topicResourceList[3].path = topicResourceList[3].url.split('#')[1]
      }
      this,topicResourceList = topicResourceList
      this.topicResource_dialogVisible = true
    },
    OperationTopicResource(){                         //新增/修改关联链接
      let topicResourceList=this.topicResourceList
      if(topicResourceList[3].appid.length<=0 || topicResourceList[3].path.length<=0){
        topicResourceList[3].url = ''
      }else{
        topicResourceList[3].url = (topicResourceList[3].appid+'#'+topicResourceList[3].path)
      }
      let obj = {
        topicResourceList:JSON.stringify(topicResourceList)
      }
      global_api.OperationTopicResource(obj).then((res)=>{
        if(res.code!=0){
          ElMessage.error('新增/修改关联链接错误')
        }else{
          this.topicResource_dialogVisible = false
          this.GetTopicList()
        }
      })
    },
    GetTopicList(){     //获取话题列表
      let obj = {
        pageNo:this.pageNo,
        pageSize:this.pageSize,
        topicName:this.topicName
      }
      this.loading = true;
      global_api.GetTopicList(obj).then((res)=>{
        if(res.code!=0){
          ElMessage.error('获取话题列表错误')
        }
        this.tableData = res.result
        this.totalCount = res.data.totalCount
        this.loading = false
        console.log(res)
      })
    },
    Current_change(number){
      this.pageNo = number
      this.GetTopicList()
    },

    Is_reveal(scope){                           // 是否上架
      // console.log(scope.row)
      let isActive = scope.row.isActive==1?0:1
      ElMessageBox.confirm(
          scope.row.isActive==1?'是否下架？':'是否上架？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        this.OperationTopic(scope.row.id,isActive,'reveal')
      })
    },

    Is_delete(scope){           //是否删除
      ElMessageBox.confirm(
          '是否删除?',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        this.OperationTopic(scope.row.id,2,'delete')
      })
    },

    OperationTopic(topicId,type,string){     //删除/上下架话题  上架 = 1 / 下架 = 0 / 删除 = 2
      global_api.OperationTopic({topicId,type}).then((res)=>{
        if(res.code!=0){
          ElMessage.error('话题上下架/删除接口错误')
        }
        if(string=='reveal'){
          ElMessage({
            type: 'success',
            message: type==0?'已下架':'已上架',
          })
        }
        if(string=='delete'){
          ElMessage({
            type: 'success',
            message: '已删除',
          })
        }
        this.GetTopicList()
      })
    },

    topic_edit(string ,scope){       //编辑 新增 题目
      if(string=='edit'){
        sessionStorage.setItem('topic_item',JSON.stringify(scope.row))
      }
      this.$router.push({
        path: "/topic/edit",
        query: { type: string, },
      });
    },

  },
}
</script>
<style scoped lang="scss">
.Topic{
  margin-top: 20px;
}
.search_btn{
  //border-radius: 5px;
}
.user_info_list{
  margin-top: 20px;
  min-width: 800px;
  .btn{
    margin-top: 5px !important;
  }
}
.pagination{
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.no_float{
  float: none !important;
}
.avatar{
  width: 100px;
  //height: 60px;
}

</style>
