<template>
  <div class="goodsImageList" v-loading="loading">
    <section class="searchForm">
      <el-button type="danger" :icon="Plus" @click="openAdd">添加产品图片</el-button>
    </section>
    <section class="tableList">
      <el-table :data="tableList">
        <el-table-column prop="id" label="id"/>
        <el-table-column label="图片/视频">
          <template #default="scope">
            <div class="tableImg">
              <img :src="scope.row.url" alt="">
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" />
        <el-table-column label="类型">
          <template #default="scope">
            {{scope.row.type?'视频':'图片'}}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" />
        <el-table-column label="操作">
          <template #default="scope">
            <el-tag class="ml-2" @click="openModify(scope.row)">编辑</el-tag>
            <el-tag class="ml-2" type="danger" @click="remove(scope.row)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="pageCurrent"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </section>

    <!-- 添加图片弹窗 -->
    <el-dialog v-model="addDialog" :title="addTitle" draggable width="500px">
      <el-form label-width="100px">
        <el-form-item label="排序">
          <el-input-number v-model="formAdd.sort" />
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="formAdd.type">
            <el-option label="图片" :value="0" />
            <el-option label="视频" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item label="图片">
          <img style="width:300px" :src="formAdd.url" alt="">
        </el-form-item>
        <el-form-item>
          <el-upload :action="Get_url+'admin/file/uploadFile'" :headers="headers" :on-success="handleAvatarSuccess" :show-file-list="false">
            <el-button type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="addDialog = false">取消</el-button>
        <el-button v-if="modifyFlag" type="primary" @click="submitAdd">确定</el-button>
        <el-button v-else type="primary" @click="submitModify">修改</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {QueryProductResourceListByProductResourceSearchVo,AddProductResouce,ModifyProductResourceByProductResourceId,RemoveProductResourceByProductResourceId} from '../../config/Api';
import { ElMessage,ElMessageBox } from 'element-plus'
import {Plus} from '@element-plus/icons-vue'
import {Get_url} from "../../config/Get_url";
export default {
  data() {
    return {
      Plus:Plus,
      id: '',
      b: '',
      pageCurrent: 1,
      pageSize: 15,
      total: 1,
      loading: false,
      addDialog: false,
      addTitle: '',
      formAdd: {},
      modifyFlag: false,
      Get_url:Get_url(),
      headers:{
        id:localStorage.getItem('id'),
        token: localStorage.getItem('token')
      },
    }
  },
  mounted() {
    let query = this.$route.query;
    this.id = query.id;
    this.b = query.b;
    this.pageCurrent = Number(query.page || 1);
    this.pageSize = Number(query.size || 15);
    this.getList();
  },
  methods: {
    // 获取数据
    getList() {
      let param = {
        productId: this.id,
        cunPage: this.pageCurrent,
        pageSize: this.pageSize,
        type: 0
      };
      this.loading = true;
      QueryProductResourceListByProductResourceSearchVo(param).then(res => {
        this.loading = false;
        this.total = res.data.totalCount;
        this.tableList = res.result;
      },err => {
        this.loading = false;
        ElMessage.error(`获取失败:${err.msg}`)
      })
    },
    // 上一页下一页
    handleCurrentChange() {
      this.$router.push({
        path: '/goods_list/image',
        query: {page: this.pageCurrent, size: this.pageSize, id: this.id, b: this.b}
      })
      this.getList();
    },
    // 打开添加弹窗
    openAdd() {
      this.addTitle = '添加';
      this.formAdd = {};
      this.modifyFlag = true;
      this.addDialog = true;
    },
    // 打开修改弹窗
    openModify(row) {
      this.addTitle = '修改';
      this.formAdd = {...row};
      this.modifyFlag = false;
      this.addDialog = true;
    },
    // 上传图片成功钩子
    handleAvatarSuccess(e){
      console.log(e);
      this.formAdd.url = e.msg;
    },
    // 添加
    submitAdd() {
      let param = {
        productId: this.id,
        purpose: 0,
        sort: this.formAdd.sort,
        type: this.formAdd.type,
        url: this.formAdd.url
      };
      this.loading = true;
      AddProductResouce(param).then(res => {
        this.loading = false;
        this.addDialog = false;
        this.getList();
      },err => {
        this.loading = false;
        ElMessage.error(`添加失败：${err.msg}`)
      })
    },
    // 修改
    submitModify() {
      let param = {
        productId: this.id,
        productResourceId: this.formAdd.id,
        purpose: 0,
        sort: this.formAdd.sort,
        type: this.formAdd.type,
        url: this.formAdd.url
      };
      this.loading = true;
      ModifyProductResourceByProductResourceId(param).then(res => {
        this.loading = false;
        this.addDialog = false;
        this.getList();
      },err => {
        this.loading = false;
        ElMessage.error(`添加失败：${err.msg}`)
      })
    },
    // 删除
    remove(row){
      ElMessageBox.confirm(
          '是否删除?',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        let param = {
          productResourceId: row.id
        };
        RemoveProductResourceByProductResourceId(param).then(res => {
          ElMessage({
            type: 'success',
            message: '已删除',
          });
          this.getList();
        },err => {err.msg
          this.loading = false;
          ElMessage.error(`删除失败：${err.msg}`)
        })
      })
    },
    // 
  }
}
</script>
<style lang="scss" scoped>
.tableImg{
  img{
    width: 80px;
  }
}
</style>