<template>
  <div id="InitialQuestionnaire">
    <section class="searchForm">
      <el-button type="primary" :icon="Plus" @click="dialogVisible=true">添加题目</el-button>
    </section>
    <section class="tableList">
      <el-table :data="tableData">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="number" label="题目编号"></el-table-column>
        <el-table-column prop="name" label="题目名称"></el-table-column>
        <el-table-column prop="type" label="题目类型">
          <template #default="scope">
            {{questionType(scope.row.type)}}
          </template>
        </el-table-column>
        <el-table-column prop="optionNum" label="选项数量"></el-table-column>
        <el-table-column prop="img" label="顶部图片">
          <template #default="scope">
            <div class="tableImg">
              <img :src="scope.row.img" alt="">
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="requisite" label="是否必答">
          <template #default="scope">
            {{scope.row.requisite == 1? '必答': '非必答'}}
          </template>
        </el-table-column>
        <el-table-column prop="rule" label="显示设置">
          <template #default>
            <el-button type="text" @click="openDialogVisible">编辑</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="available" label="上架/下架">
        <template #default="scope">
            {{scope.row.available == 1? '上架': '下架'}}
          </template></el-table-column>
        <el-table-column prop="creatrTime" label="添加时间"></el-table-column>
        <el-table-column label="操作">
          <template #default>
            <el-button size="small" type="primary" @click="dialogVisible=true">编辑</el-button>
            <el-button size="small" type="danger" @click="deleteRow">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="pageCurrent"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </section>

    <!-- 显示设置弹窗 -->
    <el-dialog v-model="dialogVisible" title="编辑显示规则" draggable>
      <el-form>
        <el-form-item label="等级名称">
          <el-input type="text"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible=false">取消</el-button>
          <el-button type="primary" @click="dialogVisible=false">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { Plus } from '@element-plus/icons-vue'
import { ElMessageBox } from 'element-plus'
export default {
  data() {
    return {
      Plus,
      // 当前页码
      pageCurrent: 1,
      // 页面数据量
      pageSize: 10,
      // 总数据条数
      total: 1000,
      // 表格数据
      tableData: [
        {id: 1, number: 'No.1', name: '大风刮大风刮风大', type: 1, optionNum: 6, img: './images/autor.jpeg', requisite: 1, rule: '', available: 1, creatrTime: 165465161},
        {id: 1, number: 'No.1', name: '大风刮大风刮风大', type: 1, optionNum: 6, img: './images/autor.jpeg', requisite: 1, rule: '', available: 1, creatrTime: 165465161},
        {id: 1, number: 'No.1', name: '大风刮大风刮风大', type: 1, optionNum: 6, img: './images/autor.jpeg', requisite: 1, rule: '', available: 1, creatrTime: 165465161},
        {id: 1, number: 'No.1', name: '大风刮大风刮风大', type: 1, optionNum: 6, img: './images/autor.jpeg', requisite: 1, rule: '', available: 1, creatrTime: 165465161},
      ],
      //
      dialogVisible: false,
    }
  },
  mounted() {
    let query = this.$route.query;
    this.pageCurrent = Number(query.page || 1);
    this.pageSize = Number(query.size || 10);
    this.getCommentList();
  },
  methods: {
    // 页码改变触发
    handleCurrentChange() {
      this.$router.push({
        path: "/inital_questionnaire",
        query: { page: this.pageCurrent, size: this.pageSize },
      });
      this.getCommentList();
    },
    // 获取数据
    getCommentList() {},
    // 删除
    deleteRow() {
      ElMessageBox.alert('确认删除','提示',)
    },
    // 打开编辑弹窗
    openDialogVisible() {
      this.dialogVisible = true;
    },
    // 添加题目
    addQuestion() {

    },
    // 编辑题目

    // 返回题目类型
    questionType(type){
      switch (type) {
        case 1 : return '单选题'
      }
    }
  },
}
</script>
<style scoped lang="scss">
.tableImg{
  img{
    width: 40px;
    height: 40px;
  }
}
</style>