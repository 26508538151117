<template>
  <el-upload v-loading.fullscreen="loadingfile"
    ref="upload"
    multiple
    :limit="1"
    :on-exceed="handleExceed"
    :on-change="handleChange"
    :auto-upload="false"
    :show-file-list="fileshow"
    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  >
    <el-button type="primary">选择文件</el-button>
  </el-upload>
</template>
<script>
import * as XLSX from "xlsx";
import {ElMessage} from 'element-plus'
export default {
  props:['fileshow'],
  data(){
    return {
      fileshow: this.fileshow&&true,
      loadingfile: false,
    }
  },
  methods:{
    // 选择文件
    handleExceed(files) {
      let upload = this.$refs.upload;
      upload.clearFiles();
      upload.uploadFiles = [files[0]];
      this.readFile(files[0]);
    },
    handleChange(file){
      this.readFile(file.raw);
      this.$emit('getfile',file.raw);
    },
    // 读取文件
    readFile(file) {
      this.loadingfile = true;
      setTimeout(()=>{
        let reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => {
          const { result } = event.target;
          // 以二进制流方式读取得到整份excel表格对象
          const workbook = XLSX.read(result, { type: "binary" });
          let data = []; // 存储获取到的数据
          // 遍历每张工作表进行读取（这里默认只读取第一张表）
          for (const sheet in workbook.Sheets) {
            if (Object.prototype.hasOwnProperty.call(workbook.Sheets, sheet)) {
              //这是关键的一步，hasOwnPropert要从Object的原型中调用
              data = data.concat(
                XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
              );
              break ;
            }
          }
          // 检验数据是否正确
          if (data.length === 0) {
            return ElMessage.error("没有上传数据");
          }
          this.$emit('getdata',data);
          this.loadingfile = false;
        };
      },1000)
      
    },
  }
}
</script>