<template>
  <div class="Order_management_detail" v-loading="loading">
    <section class="base_info">
      <div class="title">订单基本信息</div>
      <div class="main">
        <div class="list">
          <div class="item">
            <span>心愿订单号：</span>
            <span>{{userWish.orderNumber}}</span>
          </div>
          <div class="item">
            <span>心愿用户：</span>
            <span>{{user.nickname}}</span>
          </div>
        </div>
        <div class="list">
          <div class="item">
            <span>订单状态：</span>
            <span>{{userWish.isCommented?'已评论':'未评论'}}</span>
          </div>
          <div class="item">
            <span>发货状态：</span>
            <span>{{(userWish.shipState==2 && '已完成') || (userWish.shipState==0 && '未发货') || (userWish.shipState==1 && '派送中')}}</span>
          </div>
        </div>
        <div class="list">
          <div class="item">
            <span>创建时间：</span>
            <span>{{userWish.createTime}}</span>
          </div>
          <div class="item">
            <span>领取时间：</span>
            <span>{{userWish.receiveTime}}</span>
          </div>
          <div class="item">
            <span>发货时间：</span>
            <span>{{userWish.shipTime}}</span>
          </div>
        </div>
      </div>
    </section>
    <section class="base_info marginTop30">
      <div class="title">买家信息</div>
      <div class="main">
        <div class="list">
          <div class="item">
            <span>收货人：</span>
            <span>{{user.nickname}}</span>
          </div>
          <div class="item">
            <span>联系电话：</span>
            <span>{{user.phone}}</span>
          </div>
          <div class="item">
            <span>收货地址：</span>
            <span>{{userAddress.extInfo}}</span>
          </div>
        </div>
      </div>
    </section>
    <section class="base_info marginTop30">
      <div class="title">物流信息</div>
      <div class="main">
        <div class="list">
          <div class="item">
            <span>快递公司：</span>
            <span>{{userWish.orderCarrier}}</span>
          </div>
          <div class="item">
            <span>快递单号：</span>
            <span>{{userWish.orderLogistics}}</span>
          </div>
          <div class="item">
            <span>心愿盒名称：</span>
            <span>{{userWish.orderNumber}}</span>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="base_info marginTop30">
      <div class="title">预设评论</div>
      <div class="main">
        <div class="list">
          <div class="item">
            <span>少女樱花祭</span>
          </div>
        </div>
      </div>
    </section> -->
    <section class="cherished_desire_info marginTop30" v-if="question.length">
      <div class="title">许愿信息</div>
      <div class="main">
        <div class="list" v-for="(item,index) in question" :key="item.id">
          <div class="subject">{{index+1}}.{{item.name}}{{item.questionType.name}}</div>
          <div class="result" v-for="(val,i) in item.answerList" :key="i">{{val}}</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {QueryUserWishInfoByUserWishId,QuerySurveyWithQuestionAndOptionInfoByTypeAndOriginId} from '../../config/Api';
import { ElMessage } from 'element-plus'
export default {
  name: 'Order_management_detail',
  data(){
    return {
      id: '',
      loading: false,
      userWish: {},
      user: {},
      wish: {},
      userAddress:{},
      question: [],
    }
  },
  mounted() {
    let query = this.$route.query;
    this.id = query.id;
    this.getData();
  },
  methods: {
    // 获取详情
    getData() {
      let param = {
        userWishId: this.id
      };
      this.loading = true;
      QueryUserWishInfoByUserWishId(param).then(res => {
        this.loading = false;
        this.userWish = res.result.userWish;
        this.user = res.result.user;
        this.wish = res.result.wish;
        this.userAddress = res.result.userAddress
        console.log(res)
        this.getQuestion();
      },err => {
        this.loading = false;
        ElMessage.error(`获取详情失败：${err.msg}`)
      })
    },
    // 获取许愿详情
    getQuestion(item){
      let param = {
        originId: this.userWish?.wishId,
        type: 1,
        userId: this.user?.id
      }
      QuerySurveyWithQuestionAndOptionInfoByTypeAndOriginId(param).then(res => {
        if(res.result[0]?.questionWithTypeWithAnswerDTOS){
          let question = res.result[0].questionWithTypeWithAnswerDTOS;
          for(let i in question){
            if(question[i].answers.length){
              let val = [];
              question[i].answers.map(item => {
                for(let n in question[i].options){
                  if(item.optionId == question[i].options[n].id){
                    val.push(question[i].options[n].name);
                  }
                }
                
              })
              question[i].answerList = val;
            }
          }
          this.question = question
        } else {
          this.question = [];
        }
      },err=>{
        this.question = [];
      })
    }
  },
}
</script>
<style scoped lang="scss">
.Order_management_detail{
  margin-top: 20px;
  .title{
    font-size: 16px;
    font-weight: bold;
  }
  .main{
    margin-top: 10px;
  }
  .marginTop30{
    margin-top: 30px;
  }

  .base_info{
    .list{
      margin-top: 10px;
      display: flex;
      justify-content: start;
      align-items: start;
      .item{
        width: 400px;
        font-size: 14px;
        span:nth-child(2){
          color: #888;
        }
      }
    }
  }
  .cherished_desire_info{
    .list{
      padding: 10px 0;
      box-sizing: border-box;
      border-bottom: 1px dotted #eee;
      .subject{
        font-size: 16px;
      }
      .result{
        color: #888;
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
}
</style>
