<template>
  <div v-loading.fullscreen.lock="loading">
    <section class="searchForm">
      <el-form inline label-suffix="：">
        <el-form-item label="审核结果">
          <el-select v-model="formData.applyResult">
            <el-option label="失败" :value="1"></el-option>
            <el-option label="成功" :value="2"></el-option>
            <el-option label="成功（有风险）" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核进度">
          <el-select v-model="formData.applyStep">
            <el-option label="审核中" :value="1"></el-option>
            <el-option label="已完成" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封禁类型">
          <el-select v-model="formData.banType">
            <el-option label="重复注册" value="1"></el-option>
            <el-option label="胡乱评价/抄袭回答/反馈不真实" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封禁用户id">
          <el-input v-model="formData.userId"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchForm">搜索</el-button>
          <el-button @click="clearSearch">清除</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="list">
        <el-table-column  label="id" prop="id"></el-table-column>
        <el-table-column  label="封禁用户id" prop="userId"></el-table-column>
        <el-table-column  label="封禁类型" prop="banType">
          <template #default="scope">{{(scope.row.banType==1&&'重复注册')||(scope.row.banType==2&&'胡乱评价/抄袭回答/反馈不真实')}}</template>
        </el-table-column>
        <el-table-column  label="申请理由" prop="content"></el-table-column>
        <el-table-column  label="相关图片" prop="images">
          <template #default="scope">
            <el-image
              style="width: 100px; height: 100px;z-index:9999999"
              :src="scope.row.images.split(',')[0]"
              :preview-src-list="scope.row.images.split(',')"
              :initial-index="4"
              :preview-teleported="true"
              fit="cover"
            />
          </template>
        </el-table-column>
        <el-table-column  label="手机号" prop="phone"></el-table-column>
        <el-table-column  label="申请进度" prop="applyStep">
          <template #default="scope">{{(scope.row.applyStep==1&&'未审核')||(scope.row.applyStep==2&&'审核完成')}}</template>
        </el-table-column>
        <el-table-column  label="申请结果" prop="applyResult">
          <template #default="scope">{{(scope.row.applyResult==-1&&' ')||(scope.row.applyResult==1&&'未通过')||(scope.row.applyResult==2&&'通过')}}</template>
        </el-table-column>
        <el-table-column  label="备注" prop="applyRemark"></el-table-column>
        <el-table-column  label="申请次数" prop="applyTimes"></el-table-column>
        <el-table-column  label="申请时间" prop="createTime"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button v-if="scope.row.applyStep==1" type="primary" size="mini" @click="openDialog(scope.row)">处理</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="pageCurrent"
        page-size="10"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="getList"
      />
    </section>
    <el-dialog title="处理解封申请" v-model="dialog1">
      <el-form label-width="100px" label-suffix="：">
        <el-form-item label="申请理由">{{formEdit.content}}</el-form-item>
        <el-form-item label="图片证据">
          <img v-for="item in formEdit.images.split(',')" :key="item" :src="item" style="width:130px;height:130px;margin:5px;">
        </el-form-item>
        <el-form-item label="审核结果">
          <el-radio-group v-model="formEdit.applyResult">
            <el-radio :label="1">不解封</el-radio>
            <el-radio :label="2">解封</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="formEdit.applyRemark"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialog1=false">取消</el-button>
        <el-button @click="changeDialog" type="primary">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ElMessage } from 'element-plus';
import {queryUserUnbannedListBySearchVo,modifyUserUnbannedRequestById,} from '../../config/jsonapi'
import {sendTmplPush} from '../../config/Api'
export default {
  data(){
    return {
      pageCurrent: 1,
      total: 0,
      loading: false,
      formData: {},
      list: [],
      dialog1: false,
      formEdit:{},
    }
  },
  mounted(){
    let query = this.$route.query;
    this.pageCurrent = Number(query.page || 1);
    this.getList();
  },
  methods:{
    // 获取数据列表
    getList(){
      let param = {
        cunPage: this.pageCurrent,
        pageSize: 10,
        ...this.formData
      }
      this.loading = true;
      queryUserUnbannedListBySearchVo(param).then(res=>{
        this.loading = false;
        this.list = res.result;
        this.total = res.data.totalCount;
      },err=>{
        this.loading = false;
        this.list = [];
        this.total = 0;
        ElMessage.error(err.msg)
      })
    },
    // 搜索
    searchForm(){
      this.pageCurrent = 1;
      this.getList();
    },
    // 清除搜索
    clearSearch(){
      this.pageCurrent = 1;
      this.formData = {};
      this.getList();
    },
    // 打开处理弹窗
    openDialog(row){
      console.log(row);
      this.dialog1 = true;
      this.formEdit = JSON.parse(JSON.stringify(row))
    },
    // 修改申请记录
    changeDialog(){
      let param = {
        ...this.formEdit,
        applyAdminId: Number(localStorage.getItem('id')),
      }
      delete param.images;
      console.log(param);
      if(param.applyResult!=-1) param.applyStep = 2;
      this.loading = true;
      modifyUserUnbannedRequestById(param).then(res=>{
        this.loading = false;
        this.dialog1 = false;
        this.getList();
        this.sendTmplPush();
      },err=>{
        this.loading = false;
        ElMessage.error(err.msg)
      })
    },
    // 发送模板消息
    sendTmplPush(){
      let txt = this.formEdit.applyResult==1?"您的解封申请未通过。":"您的解封申请已通过";
      let date = new Date();
      let day = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
      let param = {
        userIds: this.formEdit.userId+'',
        miniprogram: '',
        priTmplId: "b9O1EDZEzaEgrdDIO_YIpwi-KcYtuo4No3FC4bescCw",
        priTmplPrams: JSON.stringify([{
          "key":"thing1",
          "value":txt
        },{
          "key":"date2",
          "value":day
        }]),
        page: '/pages/home/home',
        batchCount: 100,
        batchSpaceTime: 5,
        firstTime: 0,
        name: `${this.formEdit.userId}-申请解封结果通知`
      };
      sendTmplPush(param)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
<style>

</style>
