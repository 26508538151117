<template>
  <div class="coinChange" v-loading="loading">
    <section class="searchForm">
      <el-form :inline="true">
        <el-form-item label="用户id">
          <el-input v-model="userId" placeholder="用户id" @keyup.enter="search"></el-input>
        </el-form-item>
        <el-form-item label="用户名">
          <el-input v-model="userName" placeholder="用户名" @keyup.enter="search"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="userPhone" placeholder="手机号" @keyup.enter="search"></el-input>
        </el-form-item>
        <el-form-item label="任务">
          <el-select v-model="taskId">
            <el-option v-for="item in taskList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            <el-option label="其他" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间段">
          <el-date-picker
            v-model="timearr"
            type="datetimerange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button type="primary" @click="clearSearch">清除</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="tableList">
        <el-table-column label="ID" prop="id"></el-table-column>
        <el-table-column label="用户ID" prop="userId"></el-table-column>
        <el-table-column label="用户名" prop="user.nickname"></el-table-column>
        <el-table-column label="手机号" prop="user.phone"></el-table-column>
        <el-table-column label="任务" prop="task.name"></el-table-column>
        <el-table-column label="描述" prop="taskDescription"></el-table-column>
        <el-table-column label="心愿币变动" prop="rewardType">
          <template #default="scope">
            {{scope.row.rewardType==1?'-':'+'}}{{scope.row.amount}}
          </template>
        </el-table-column>
        <el-table-column label="结余" prop="rewardTotal"></el-table-column>
        <el-table-column label="时间" prop="createTime"></el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="cunPage"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </section>
  </div>
</template>
<script>
import { ElMessage } from 'element-plus';
import {queryUserCoinLogListByUserCoinLogSearchVo,QueryTaskList} from '../../config/Api';
import {formatDate} from '../../utils/date';
export default {
  data(){
    return{
      cunPage: 1, // 当前页
      pageSize: 10, // 页面数据量
      total:1,
      tableList: [],
      userId: '',
      taskList: [],
      taskId: '',
      userName:'',
      userPhone:'',
      timearr:[],
    }
  },
  mounted() {
    let query = this.$route.query;
    this.userId = query.userId;
    this.cunPage = Number(query.page || 1);
    this.pageSize = Number(query.size || 10);
    this.getList();
    this.QueryTaskList();
  },
  methods:{
    // 获取列表
    getList(){
      this.loading = true;
      let param = {
        cunPage:this.cunPage,
        pageSize:this.pageSize,
        userId: this.userId,
        taskId: this.taskId,
        userName: this.userName,
        userPhone: this.userPhone,
        startTime: this.timearr[0]?formatDate(this.timearr[0],'yyyy-MM-dd hh:mm:ss'):'',
        endTime: this.timearr[1]?formatDate(this.timearr[1],'yyyy-MM-dd hh:mm:ss'):''
      }
      queryUserCoinLogListByUserCoinLogSearchVo(param).then(res=>{
        this.loading = false;
        this.tableList = res.result;
        this.total = res.data.totalCount;
      },err=>{
        this.loading = false;
        ElMessage.error('获取失败')
      })
    },
    // 上一页下一页
    handleCurrentChange(){
      this.$router.push({
        path: "/Member_detail/coin",
        query: { page: this.cunPage, size: this.pageSize },
      });
      this.getList();
    },
    // 搜索
    search(){
      this.cunPage = 1;
      this.$router.push({
        path: "/Member_detail/coin",
        query: { page: this.cunPage, size: this.pageSize },
      });
      this.getList();
    },
    // 清空
    clearSearch(){
      this.cunPage = 1;
      this.userId = '';
      this.taskId = ''
      this.userName = '';
      this.userPhone = '';
      this.$router.push({
        path: "/Member_detail/coin",
        query: { page: this.cunPage, size: this.pageSize },
      });
      this.getList();
    },
    // 获取任务
    QueryTaskList(){
      QueryTaskList({cunPage:1,pageSize:10000}).then(res=>{
        this.taskList = res.result;
      },err=>{
        this.taskList = [];
      })
    } 

  }
}
</script>