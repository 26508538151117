<template>
    <el-dialog v-model="tagDialog" title="选择标签分类" width="800px" v-loading="loading">
        <el-tree :data="groupList" :props="setKesLabel" accordion @node-click="handleNodeClick"></el-tree>
        <template #footer>
            <el-button @click="tagDialog = false">取消</el-button>
            <el-button type="primary" @click="sureBtn">确定</el-button>
        </template>
    </el-dialog>
</template>
<script>
    import global_api from "@/config/Api";
    import { ElMessage } from "element-plus";
    export default {
        data() {
            return {
                tagDialog: false,
                loading: false,
                groupList: [],
                setKesLabel: {
                    children: 'tagRespDTOS',
                    value: 'id',
                    label: 'name',
                },
                tagClass: ''
            }
        },
        methods: {
            openTag() {
                this.tagDialog = true;
                this.getTargetAudienceInfo();
                this.tagClass = '';
            },
            getTargetAudienceInfo() {
                this.loading = true;
                global_api.QueryTagListByTagSearchVo({ cunPage: 0, pageSize: 0 }).then((res) => {
                    this.loading = false;
                    let list = res.result;
                    list = sort(list);
                    this.groupList = list;

                    function sort(data){
                        for(let i = 0; i< data.length-1; i++){
                            for(let n = i+1;n<data.length; n++){
                                if(data[i].sort>data[n].sort){
                                    let tmp = data[i];
                                    data[i] = data[n];
                                    data[n] = tmp;
                                }
                            }
                        }
                        for(let i in data){
                            if(data[i].tagRespDTOS?.length){
                                data[i].tagRespDTOS = sort(data[i].tagRespDTOS);
                            }
                        }
                        return data;
                    }
                }, () => {
                    this.loading = false;
                    ElMessage.error('获取分类失败')
                })
            },
            handleNodeClick(e) {
                this.tagClass = e;
            },
            sureBtn() {
                if (this.tagClass) {
                    this.tagDialog = false;
                    this.$emit('setTag', this.tagClass);
                } else {
                    ElMessage.error('请选择标签分类');
                }
                
            },
        }
    }
</script>