<template>
  <el-button type="primary" @click="openDialog">隐藏用户回答</el-button>
  <el-dialog title="" v-model="dialogFlag" width="500px" v-loading="loading">
    <el-form label-suffix="：" label-width="100px">
      <el-form-item label="导入用户">
					<fileTemplate sign="6" @fileData="getdata"/>
				</el-form-item>
      <el-form-item label="用户id">
        <el-input type="textarea" rows="5" v-model="userIds"></el-input>
      </el-form-item>
      <el-form-item label="问卷名称">
        {{survey.name}}
      </el-form-item>
      <el-form-item label="surveyId">
        {{survey.id}}
      </el-form-item>
      <el-form-item label="显示状态">
        <el-radio-group v-model="isVisible">
          <el-radio label="1">显示</el-radio>
          <el-radio label="0">隐藏</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="updateUserAnswerIsVisibleByUserIdAndSurveyId">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { ElMessage } from 'element-plus';
import {updateUserAnswerIsVisibleByUserIdAndSurveyId} from '../config/Api';
import fileTemplate from "@/components/fileTemplate.vue";
export default {
  props: ["survey"],
  data() {
    return {
      dialogFlag: false,
      userIds: '',
      isVisible: ''
    }
  },
  components: {
    fileTemplate
  },
  methods: {
    // 打开弹窗
    openDialog() {
      this.userId = '';
      this.isVisible = '';
      if(this.survey?.id){
        this.dialogFlag = true;
      }else{
        ElMessage.error('没有问卷信息')
      }
    },
    // 关闭弹窗
    closeDialog() {
      this.dialogFlag = false;
    },
    // 获取数据
    getdata(data){
      let userIds = [];
      for(let i in data){
        userIds.push(data[i].userId)
      }
      this.userIds = userIds.join(',');
    },
    // 修改状态
    updateUserAnswerIsVisibleByUserIdAndSurveyId(){
      let userIds = this.userIds.replace(/\s/g,'')
      if(!userIds) return ElMessage.error('请输入用户id');
      if(!this.isVisible) return ElMessage.error('请选择显示状态');
      userIds = userIds.split(',')
      console.log(userIds);
      this.index = 0;
      if(userIds.length){
        this.loading = true;
        this.p(userIds);
        this.dialogFlag = false;
      }

    },
    p(userIds){
      if(this.index < userIds.length){
        console.log(this)
        let param = {
          isVisible: this.isVisible,
          surveyId: this.survey.id,
          userId: userIds[this.index]
        }
        updateUserAnswerIsVisibleByUserIdAndSurveyId(param).then(res => {
          this.index += 1;
          this.p(userIds);
        },err => {
          this.index += 1;
          this.p(userIds);
        })
      }else{
        this.loading = false;
      }
    }
  }
}
</script>