<template>
  <div v-loading.fullscreen.lock="loading">
    <el-button type="primary" @click="openDialog">批量查找用户</el-button>
    <section class="searchForm">
      <el-form inline>
        <el-form-item>
          <el-button type="primary" @click="openDialog1">导入奖励</el-button>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchData.auditStatus">
            <el-option label="未审核" :value="1"></el-option>
            <el-option label="运营不通过" :value="2"></el-option>
            <el-option label="运营通过" :value="3"></el-option>
            <el-option label="终审完成" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户ID">
          <el-input v-model="searchData.userId" placeholder="请输入用户ID"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button type="primary" @click="clear">清除</el-button>
        </el-form-item>
        <el-form-item label="是否开启批量审核">
          <el-switch v-model="piSwitch" @change="change1" active-text="开启" inactive-text="关闭" />
        </el-form-item>

        <el-form-item label="是否开启批量终审">
          <el-switch v-model="zhongSwitch" @change="change2" active-text="开启" inactive-text="关闭" />
        </el-form-item>
        <el-form-item>
          <el-button v-if="piSwitch" @click="openDialog4">批量审核</el-button>
          <el-button v-if="zhongSwitch" @click="openDialog5">批量终审</el-button>
          <el-button type="danger" @click="openDialog6">奖励扣除</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="list" @selection-change="handleSelectionChange" ref="multipleTableRef">
        <el-table-column type="selection" width="55" v-if="piSwitch || zhongSwitch" :selectable="selectable" />
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="userId" prop="userId"></el-table-column>
        <el-table-column label="用户名" prop="username"></el-table-column>
        <el-table-column label="获奖类型" prop="type">
          <template #default="scope">
            {{ (scope.row.type == 1 && '活动奖励') || (scope.row.type == 2 && '问卷奖励') || (scope.row.type == 3 && '小红书激励') ||
              ('未知类型') }}
          </template>
        </el-table-column>
        <el-table-column label="奖励来源" prop="specialEvent.name"></el-table-column>
        <el-table-column label="奖励内容" prop="content"></el-table-column>
        <el-table-column label="奖励金额" prop="amount"></el-table-column>
        <el-table-column label="审核状态" prop="auditStatus">
          <template #default="scope">
            {{ (scope.row.auditStatus == 1 && '未审核') || (scope.row.auditStatus == 2 && '运营未通过') || (scope.row.auditStatus
              == 3 && '运营通过') || (scope.row.auditStatus == 4 && '终审完成') }}
          </template>
        </el-table-column>
        <el-table-column label="审核备注" prop="comment"></el-table-column>
        <el-table-column label="发放状态" prop="auditStatus">
          <template #default="scope">
            {{ (scope.row.isGranted == 1 && '终审通过') || (scope.row.isGranted == 2 && '终审未通过') || '未终审' }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="操作者" prop="adminId"></el-table-column>
        <el-table-column label="操作" width="200px">
          <template #default="scope">
            <el-button v-if="scope.row.auditStatus == 1" size="small" type="primary"
              @click="openDialog2(scope.row)">修改</el-button>
            <el-popconfirm v-if="scope.row.auditStatus == 1 && !piSwitch && !zhongSwitch" cancel-button-text="不通过"
              confirm-button-text="通过" icon="none" title="是否审核通过？" @confirm="examineAward(scope.row, 3)"
              @cancel="examineAward(scope.row, 2)">
              <template #reference>
                <el-button size="small" type="success">审核</el-button>
              </template>
            </el-popconfirm>
            <el-button v-if="scope.row.auditStatus == 3 && !piSwitch && !zhongSwitch" size="small" type="success"
              @click="openDialog3(scope.row)">终审</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination v-model:currentPage="pageCurrent" page-size="100" background layout="prev, pager, next"
        :total="total" @current-change="getList" />
    </section>
    <!-- 批量查找用户弹窗 -->
    <el-dialog title="" v-model="dialogFlag" width="500px" v-loading="loading">
      <el-form label-suffix="：" label-width="100px">
        <el-form-item label="导入用户">
          <fileTemplate sign="6" @fileData="getSearchUser" />
        </el-form-item>
        <el-form-item label="用户id">
          <el-input type="textarea" rows="5" v-model="searchData.userIds"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="searchBatchUser">确定</el-button>
      </template>
    </el-dialog>

    <!-- 导入奖励弹窗 -->
    <el-dialog v-model="dialog1" title="导入奖励" width="500px">
      <el-form label-suffix="：" label-width="100px">
        <el-form-item label="获奖类型">
          <el-select v-model="formData.type">
            <el-option label="活动奖励" :value="1"></el-option>
            <el-option label="问卷奖励" :value="2"></el-option>
            <el-option label="小红书激励" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="获奖来源ID" v-if="formData.type == 2">
          <el-input v-model="formData.originId"></el-input>
          <!-- <el-select v-model="formData.originId">
            <el-option label="问卷奖励" :value="2"></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="获奖内容">
          <el-input v-model="formData.content"></el-input>
        </el-form-item>
        <el-form-item label="上传获奖人">
          <fileTemplate sign="3" @fileData="getdata" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialog1 = false">取消</el-button>
        <el-button type="primary" @click="submitAward">确定</el-button>
      </template>
    </el-dialog>
    <!-- 修改金额弹窗 -->
    <el-dialog v-model="dialog2" title="修改奖励金额" width="500px">
      <el-form label-suffix="：" label-width="100px">
        <el-form-item label="奖励金额">
          <el-input-number min="0" v-model="editFormData.amount"></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialog2 = false">取消</el-button>
        <el-button type="primary" @click="modifyAward">确定</el-button>
      </template>
    </el-dialog>
    <!-- 终审弹窗 -->
    <el-dialog v-model="dialog3" title="审核" width="500px">
      <el-form label-suffix="：">
        <el-form-item label="用户id">{{ editFormData.userId }}</el-form-item>
        <el-form-item label="奖励金额">{{ editFormData.amount }}</el-form-item>
        <el-form-item label="奖励类型">{{ (editFormData.type == 1 && "活动奖励") || (editFormData.type == 2 && "问卷奖励") ||
          (editFormData.type == 3 && "小红书激励") }}</el-form-item>
        <el-form-item label="奖励来源">{{ editFormData.originId }}</el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialog3 = false">取消</el-button>
        <el-button type="danger" @click="examineAmount(2)">不通过</el-button>
        <el-button type="primary" @click="examineAmount(1)">通过</el-button>
      </template>
    </el-dialog>
    <!-- 审核弹窗 -->
    <el-dialog v-model="dialog5" title="审核" width="500px">
      <el-row>
        <el-col :span="3"></el-col>
        <el-col :span="21"></el-col>
      </el-row>
      <template #footer>
        <el-button>不通过</el-button>
        <el-button type="primary">通过</el-button>
      </template>
    </el-dialog>
    <!-- 运营批量审核弹窗 -->
    <el-dialog v-model="dialog4" title="批量审核" width="900px">
      <div class="piShen">
        <p>审核总数：{{ piList.length }}条</p>
        <p>审核总金额：{{ allAmount }}元</p>
      </div>
      <el-table height='400' :data="piList">
        <el-table-column label="userId" prop="userId"></el-table-column>
        <el-table-column label="用户名" prop="username"></el-table-column>
        <el-table-column label="获奖类型" prop="type">
          <template #default="scope">
            {{ (scope.row.type == 2 && '问卷奖励') || ('未知类型') }}
          </template>
        </el-table-column>
        <el-table-column label="奖励来源" prop="specialEvent.name"></el-table-column>
        <el-table-column label="奖励内容" prop="content"></el-table-column>
        <el-table-column label="奖励金额" prop="amount"></el-table-column>
      </el-table>
      <div style="margin-top:30px;">
        <el-row>
          <el-col :span="3">
            <p>审核备注：</p>
          </el-col>
          <el-col :span="21">
            <el-input type="textarea" v-model="comment"></el-input>
          </el-col>
        </el-row>
      </div>
      <template #footer>
        <el-button @click="piShen(2)">不通过</el-button>
        <el-button type="primary" @click="piShen(3)">通过</el-button>
      </template>
    </el-dialog>
    <!-- 批量终审 -->
    <el-dialog v-model="dialog5" title="批量终核" width="900px">
      <div class="piShen">
        <p>审核总数：{{ piList.length }}条</p>
        <p>审核总金额：{{ allAmount }}元</p>
      </div>
      <el-table height='400' :data="piList">
        <el-table-column label="userId" prop="userId"></el-table-column>
        <el-table-column label="用户名" prop="username"></el-table-column>
        <el-table-column label="获奖类型" prop="type">
          <template #default="scope">
            {{ (scope.row.type == 2 && '问卷奖励') || ('未知类型') }}
          </template>
        </el-table-column>
        <el-table-column label="奖励来源" prop="specialEvent.name"></el-table-column>
        <el-table-column label="奖励内容" prop="content"></el-table-column>
        <el-table-column label="奖励金额" prop="amount"></el-table-column>
      </el-table>
      <!-- <div style="margin-top:30px;">
        <el-row>
          <el-col :span="3">
            <p>审核备注：</p>
          </el-col>
          <el-col :span="21">
            <el-input type="textarea" v-model="comment"></el-input>
          </el-col>
        </el-row>
      </div> -->
      <template #footer>
        <el-button @click="reviewerReviewMoneyByMoneyIdBatch(2)">不通过</el-button>
        <el-button type="primary" @click="reviewerReviewMoneyByMoneyIdBatch(1)">通过</el-button>
      </template>
    </el-dialog>
    <!-- 扣除奖励 -->
    <el-dialog v-model="dialog6" title="扣除奖励" width="400px">
      <el-form label-suffix=":" label-width="100px">
        <el-form-item label="用户名">
          <el-input v-model="deductData.userId"></el-input>
        </el-form-item>
        <el-form-item label="扣除金额">
          <el-input-number style="width: 100%" min="0" v-model="deductData.money"></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button>取消</el-button>
        <el-button type="primary" @click="deduct">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import fileTemplate from "@/components/fileTemplate.vue";
import { ElMessage, ElSubMenu } from 'element-plus';
import { reviewerReviewMoneyByMoneyIdBatch, insertUserObtainMoneyList, queryUserObtainMoneyListBySearchVo, operatorReviewMoneyByMoneyId, reviewerReviewMoneyByMoneyId, operatorReviewMoneyByMoneyIdBatch } from '../../../config/jsonapi'
import { deductReward } from "@/config/Api";
export default {
  data() {
    return {
      dialog1: false,
      dialogFlag: false,
      formData: {},
      userIdList: [],
      loading: false,
      pageCurrent: 1,
      total: 1,
      list: [],
      searchData: {},

      dialog2: false,
      editFormData: {},

      dialog3: false,

      piSwitch: false,
      pageSelect: {},
      zhongSwitch: false,

      dialog4: false,
      piList: [],
      allAmount: 0,
      comment: '',
      dialog5: false,

      dialog6: false,
      deductData: {
        userId: '',
        money: 0
      },
    }
  },
  components: { fileTemplate },
  mounted() {
    this.getList();
  },
  methods: {
    openDialog1() {
      this.dialog1 = true;
      this.formData = {};
      this.userIdList = [];
    },
    // 打开弹窗
    openDialog() {
      this.dialogFlag = true;
      this.userId = '';
      this.isVisible = '';

    },
    // 关闭弹窗
    closeDialog() {
      this.dialogFlag = false;
    },
    searchBatchUser() {
      this.getList();
      this.dialogFlag = false;
    },
    getSearchUser(data) {
      let userIds = [];
      for (let i in data) {
        userIds.push(data[i].userId)
      }
      this.searchData.userIds = userIds.join(',');
    },
    getdata(e) {
      let userIdList = [];
      for (let i in e) {
        userIdList.push({ userId: e[i].用户ID, amount: e[i].获奖金额 })
      }
      this.userIdList = userIdList;
    },
    submitAward() {
      let { type, originId, content } = this.formData;
      let userIdList = this.userIdList;
      if (!type) return ElMessage.error('请选择获奖类型');
      if (!originId && type == 2) return ElMessage.error('请选择来源ID');
      if (!content) return ElMessage.error('请输入获奖内容');
      if (!userIdList.length) return ElMessage.error('请上传文件');
      let param = [];
      for (let i in userIdList) {
        param.push({
          type,
          originId: originId || 0,
          content,
          userId: userIdList[i].userId,
          amount: userIdList[i].amount,
        })
      }
      this.loading = true;
      insertUserObtainMoneyList(param).then(res => {
        this.loading = false;
        this.page = 1;
        this.getList();
        this.dialog1 = false;
      }, err => {
        this.loading = false;
        ElMessage.error(err.msg)
      })
    },
    getList() {
      let param = {
        cunPage: this.pageCurrent,
        pageSize: 100,
        ...this.searchData,
      }
      this.loading = true;
      queryUserObtainMoneyListBySearchVo(param).then(res => {
        this.loading = false;
        let list = res.result;
        for (let i in list) {
          list[i] = { ...list[i], ...list[i].userObtainMoney }
        }


        console.log(this.pageSelect);
        let pageSelect = JSON.parse(JSON.stringify(this.pageSelect));
        let arr = pageSelect[this.pageCurrent];
        setTimeout(() => {
          if (!arr) return;
          for (let i in list) {
            for (let n in arr) {
              if (list[i].id === arr[n].id) {
                this.$refs.multipleTableRef.toggleRowSelection(list[i], true);
              }
            }
          }
        }, 0)
        this.list = list;
        this.total = res.data.totalCount;
      }, err => {
        this.loading = false;
      })
    },
    search() {
      this.pageCurrent = 1;
      this.getList();
    },
    clear() {
      this.searchData = {};
      this.search();
    },
    // 打开修改弹窗
    openDialog2(row) {
      this.dialog2 = true;
      this.editFormData = { ...row };
    },
    // 修改奖励金额
    modifyAward() {
      let { adminId, amount, auditStatus, comment, id } = this.editFormData;
      let param = {
        adminId,
        amount,
        auditStatus,
        comment,
        userObtainMoneyId: id
      }
      this.loading = true;
      operatorReviewMoneyByMoneyId(param).then(res => {
        this.loading = false;
        this.dialog2 = false;
        this.getList();
      }, err => {
        this.loading = false;
        ElMessage.error(err.msg);
      })
    },
    // 审核
    examineAward(row, state) {
      let { adminId, amount, comment, id } = row;
      let param = {
        adminId,
        amount,
        auditStatus: state,
        comment,
        userObtainMoneyId: id
      }
      this.loading = true;
      operatorReviewMoneyByMoneyId(param).then(res => {
        this.loading = false;
        this.dialog2 = false;
        this.getList();
      }, err => {
        this.loading = false;
        ElMessage.error(err.msg);
      })
    },
    // 打开终审弹窗
    openDialog3(row) {
      this.dialog3 = true;
      this.editFormData = { ...row };
    },
    // 终审审核
    examineAmount(state) {
      let param = {
        isGranted: state,
        reviewerId: Number(localStorage.getItem('id')),
        userObtainMoneyId: this.editFormData.id,
      }
      this.loading = true;
      reviewerReviewMoneyByMoneyId(param).then(res => {
        this.loading = false;
        this.dialog3 = false;
        this.getList();
      }, err => {
        this.loading = false;
        ElMessage.error(err.msg);
      })
    },
    // 是否可以勾选
    selectable(row) {
      if (this.piSwitch) {
        return row.auditStatus == 1;
      }
      if (this.zhongSwitch) {
        return row.auditStatus == 3;
      }
      return false;
    },
    // 选择多个批次
    handleSelectionChange(e) {
      let pageSelect = this.pageSelect;
      pageSelect[this.pageCurrent] = e;
      this.pageSelect = pageSelect;
    },
    // 打开批量审核弹窗
    openDialog4() {
      this.dialog4 = true;
      this.comment = '';
      let pageSelect = this.pageSelect;
      let list = [];
      let allAmount = 0;
      for (let k in pageSelect) {
        if (pageSelect[k] && pageSelect[k].length) {
          list.push(...pageSelect[k])
          pageSelect[k].forEach(item => {
            allAmount += item.amount;
          });
        }
      }
      this.allAmount = allAmount;
      this.piList = list;
    },
    // 批量审核
    piShen(auditStatus) {
      let list = this.piList;
      if (!list.length) {
        ElMessage.error('请选择审核数据')
        return;
      }
      let param = [];
      for (let i in list) {
        param.push({
          adminId: list[i].adminId,
          amount: list[i].amount,
          auditStatus,
          comment: this.comment,
          userObtainMoneyId: list[i].id
        })
      }
      this.loading = true;
      operatorReviewMoneyByMoneyIdBatch(param).then(res => {
        this.loading = false;
        this.dialog4 = false;
        this.pageSelect = {};
        this.getList();
      }, err => {
        this.loading = false;
        ElMessage.error(err.msg)
      })
    },
    // 打开批量审核弹窗
    openDialog5() {
      this.dialog5 = true;
      this.comment = '';
      let pageSelect = this.pageSelect;
      let list = [];
      let allAmount = 0;
      for (let k in pageSelect) {
        if (pageSelect[k] && pageSelect[k].length) {
          list.push(...pageSelect[k])
          pageSelect[k].forEach(item => {
            allAmount += item.amount;
          });
        }
      }
      this.allAmount = allAmount;
      this.piList = list;
    },
    reviewerReviewMoneyByMoneyIdBatch(state) {
      let list = this.piList;
      if (!list.length) {
        ElMessage.error('请选择审核数据')
        return;
      }
      let param = [];
      for (let i in list) {
        param.push({
          isGranted: state,
          reviewerId: Number(localStorage.getItem('id')),
          userObtainMoneyId: list[i].id,
        })
      }
      this.loading = true;
      reviewerReviewMoneyByMoneyIdBatch(param).then(res => {
        this.loading = false;
        this.dialog5 = false;
        this.pageSelect = {};
        this.getList();
      }, err => {
        this.loading = false;
        ElMessage.error(err.msg)
      })
    },
    // 开关改变
    change1() {
      this.zhongSwitch = false;
      this.pageSelect = {};
      this.$refs.multipleTableRef.clearSelection();
    },
    change2() {
      this.piSwitch = false;
      this.pageSelect = {};
      this.$refs.multipleTableRef.clearSelection();
    },

    // 扣除
    openDialog6() {
      this.deductData = {};
      this.dialog6 = true;
    },
    // 确定扣除
    deduct() {
      let { userId, money } = this.deductData;
      if (!userId) {
        ElMessage.error('请输入用户ID')
        return;
      }
      if (!money) {
        ElMessage.error('请输入扣除金额')
        return;
      }
      let param = {
        userId,
        number: money,
      }
      this.loading = true;
      deductReward(param).then(res => {
        this.loading = false;
        this.dialog6 = false;
        this.getList();
      }, err => {
        this.loading = false;
        ElMessage.error(err.msg)
      })
    },

  }
}
</script>
<style lang="scss" scoped>
.piShen {
  display: flex;
  justify-content: space-around;
  align-items: center;

  p {
    font-size: 14px;
    line-height: 20px;
    color: #333;
  }
}
</style>

