<template>
  <div v-loading.fullscreen.lock="loading">
    <section class="searchForm">
      <el-form inline>
        <el-form-item>
          <el-button type="primary" @click="openDialog1">添加</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="table">
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="自有标签" prop="tagId">
          <template #default="scope">
            {{tagObj[scope.row.tagId] || scope.row.tagId}}
          </template>
        </el-table-column>
        <el-table-column label="企微标签" prop="qyTagId">
          <template #default="scope">
            {{qytagObj[scope.row.qyTagId] || scope.row.qyTagId}}
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="" @click="removeTagWechatMapByTagWechatMapId(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="cunPage"
        page-size="10"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="queryTagListByTagSearchVo"
      />
    </section>
    <!-- 编辑对应关系 -->
    <el-dialog v-model="dialog1" title="编辑对应关系" width="500px">
      <el-form label-width="90px" label-suffix="：">
        <el-form-item label="自有标签">
          {{dialog1Obj.tagName}}
          <el-button @click="$refs.tag.openTag()">选择</el-button>
          <tag ref="tag" @setTag="setTag"></tag>
        </el-form-item>
        <el-form-item label="企微标签">
          <el-cascader v-model="dialog1Obj.qyTagId" :options="qytags" :props="props " @change="handleChange" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialog1 = false">取消</el-button>
        <el-button type="primary" @click="addTagWeChatMap">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import global_api, {queryCorpTagList,queryTagListByTagSearchVo,addTagWeChatMap,removeTagWechatMapByTagWechatMapId} from '@/config/Api'

import tag from '@/components/tag'
import { ElMessage } from 'element-plus'
export default {
  data(){
    return {
      loading: false,
      cunPage: 1,
      total: 0,
      table: [],
      tagObj:{},
      qytagObj:{},

      dialog1: false,
      dialog1Obj: {},
      qytags: [],
      props:{
        expandTrigger: 'hover',
        value: 'group_id',
        label: 'group_name',
        children: 'tag'
      }
    }
  },
  components:{tag},
  mounted(){
    this.queryTagListByTagSearchVo();
    this.QueryTagListByTagSearchVo();
    this.queryCorpTagList();
  },
  methods:{
    // 获取企微标签列表
    queryTagListByTagSearchVo(){
      this.loading = true;
      let param = {
        cunPage: this.cunPage,
        pageSize: 10,
      }
      queryTagListByTagSearchVo(param).then(res => {
        this.loading = false;
        this.total = res.data.totalCount;
        this.table = res.result;
      },err => {
        this.loading = false;
      })
    },
    // 获取标签
    QueryTagListByTagSearchVo() {
      global_api.QueryTagListByTagSearchVo({cunPage:0,pageSize:0}).then(res=>{
        let list = res.result;
        this.tagObj = d(list);

        function d(list){
          let arr = {};
          for(let i in list){
            if(list[i].tags.length){
              list[i].tags.map(item=>{
                arr[item.id] = item.value;
              })
            }
          }
          for(let i in list){
            if(list[i].tagRespDTOS.length){
              arr = {...arr,...d(list[i].tagRespDTOS)}
            }
          }
          return arr;
        }
      })
    },
    // 获取企微标签
    queryCorpTagList() {
      queryCorpTagList().then(res=>{
        let result = JSON.parse(res.result);
        let arr = {};
        for(let i in result){
          if(result[i].tag.length){
            result[i].tag.map(item=>{
              arr[item.id] = item.name;
              item.group_name = item.name;
              item.group_id = item.id;
            })
          }
        }
        this.qytags = result;
        this.qytagObj = arr;
      })
    },
    // 打开编辑对应关系弹窗
    openDialog1(){
      this.dialog1Obj = {};
      this.dialog1 = true;
    },
    // 选择自有标签
    setTag(e){
      this.dialog1Obj.tagName = e.tagName;
      this.dialog1Obj.tagId = e.tagId;
    },
    // 添加对应关系
    addTagWeChatMap(){
      if(!this.dialog1Obj.tagId) return ElMessage.error('请选择自有标签');
      if(!this.dialog1Obj.qyTagId[1]) return ElMessage.error('请选择企微标签');
      this.loading = true;
      let param = {
        qyTagId: this.dialog1Obj.qyTagId[1],
        tagId: this.dialog1Obj.tagId
      };
      addTagWeChatMap(param).then(res=>{
        this.loading = false;
        ElMessage.success('添加对应关系成功');
        this.queryTagListByTagSearchVo();
        this.dialog1 = false;
      },err=>{
        this.loading = false;
        ElMessage.error('添加对应关系失败')
      })
    },
    // 删除对应关系
    removeTagWechatMapByTagWechatMapId(row){
      this.loading = true;
      removeTagWechatMapByTagWechatMapId({tagWechatMapId:row.id}).then(res=>{
        this.loading = false;
        ElMessage.success('成功删除对应关系');
        this.queryTagListByTagSearchVo();
      },err=>{
        this.loading = false;
        ElMessage.error('删除对应关系失败')
      })
    }
  },
}
</script>