<template>
  <div class="main" v-loading="loading">
    <el-affix :offset="60" v-if="!type">
      <el-button type="primary" @click="addNewPrize">新增奖励</el-button>
    </el-affix>
    <div class="prize" v-for="(prize,index) in prizeList" :key="index">
      <div class="prizeInfo">
        <p class="prizeNo">奖励：{{index + 1}}</p>
        <el-button type="danger" @click="deletePrize(index)">删除</el-button>
      </div>
      <!-- 奖励内容 -->
      <div class="prizeInfo">
        <p class="prizeNo">奖励内容</p>
      </div>
      <div class="container">
        <el-row :gutter="20" class="elrow">
          <el-col :span="6">
            <div class="item">
              <span>名称：</span>
              <el-input v-model="prize.name"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <span>标题：</span>
              <el-input v-model="prize.title"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <span>备注：</span>
              <el-input v-model="prize.remark"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <span>优先级：</span>
              <el-input-number style="width:100%" v-model="prize.priority" :min="0" :step="1"></el-input-number>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="elrow">
          <el-col :span="6">
            <div class="item">
              <span>上限：</span>
              <el-input-number style="width:100%" v-model="prize.maxRewardNo" :min="0" :step="1"></el-input-number>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <span>类型：</span>
              <el-select v-model="prize.type" style="width:100%">
                <el-option label="情感分析" :value="5" />
              </el-select>
            </div>
          </el-col>
          
          <el-col :span="6">
            <div class="item">
              <span>标题图片：</span>
              <el-image
                v-if="prize.value.title"
                style="width: 150px; height: 150px"
                :src="prize.value.title"
                :preview-src-list="[prize.value.title]"
                :initial-index="1"
                fit="cover"
              />
              <el-upload
                ref="upload1"
                list-type="picture-card"
                :on-success="uploadSuccess1(index)"
                :on-error="uploadError"
                :action="Geturl+'admin/file/uploadFile'"
                :headers="headers"
                :show-file-list="false"
              >
                <el-icon><Plus /></el-icon>
              </el-upload>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <span>雷达背景图：</span>
              <el-image
                v-if="prize.value.radar"
                style="width: 150px; height: 150px"
                :src="prize.value.radar"
                :preview-src-list="[prize.value.radar]"
                :initial-index="1"
                fit="cover"
              />
              <el-upload
                ref="upload1"
                list-type="picture-card"
                :on-success="uploadSuccess2(index)"
                :on-error="uploadError"
                :action="Geturl+'admin/file/uploadFile'"
                :headers="headers"
                :show-file-list="false"
              >
                <el-icon><Plus /></el-icon>
              </el-upload>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="elrow">
          
          <el-col :span="6">
            <div class="item">
              <span>性格特征：</span>
              <el-input v-model="prize.value.trait" placeholder="最多8个字"></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="item">
              <span>性格描述：</span>
              <!-- <el-input  v-model="prize.value.describe" type="textarea" :rows="5"></el-input> -->
              <editor :text="prize.value.describe" @change="(e)=>{getContent(e,index)}" style="height:150px"></editor>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="item">
              <span>性格颜色1：</span>
              <el-input v-model="prize.value.color1">
                <template #append>
                  <el-color-picker v-model="prize.value.color1" />
                </template>
              </el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="item">
              <span>性格颜色2：</span>
              <el-input v-model="prize.value.color2">
                <template #append>
                  <el-color-picker v-model="prize.value.color2" />
                </template>
              </el-input>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20" class="elrow">
          <el-col :span="6">
            <div class="item">
              <span>合得来：</span>
              <el-select v-model="prize.value.begets" style="width:100%">
                <el-option label="其余最高" :value="1"></el-option>
                <el-option label="其余最低" :value="2"></el-option>
                <el-option label="其他" :value="3"></el-option>
                <el-option v-for="(n,i) in rules" :key="i" :label="n.define" :value="n.define"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <span>合不来：</span>
              <el-select v-model="prize.value.phaseGrams" style="width:100%">
                <el-option label="其余最高" :value="1"></el-option>
                <el-option label="其余最低" :value="2"></el-option>
                <el-option label="其他" :value="3"></el-option>
                <el-option v-for="(n,i) in rules" :key="i" :label="n.define" :value="n.define"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6"></el-col>
          <el-col :span="6"></el-col>
        </el-row>
      </div>
      <!-- 奖励规则 -->
      <div class="prizeInfo">
        <p class="prizeNo">奖励规则</p>
      </div>
      <div class="container">
        <el-row class="elrow">
          <el-col :span="8">
            <div class="item">
              <span>最大组:</span>
              <el-select v-model="prize.max" multiple style="width:100%">
                <el-option v-for="(rule,rIndex) in rules" :key="rIndex" :label="rule.name" :value="rule.name"/>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 功能分组 -->
    <div class="prize">
      <!-- 奖励规则 -->
      <div class="prizeInfo">
        <p class="prizeNo">公共分组数据</p>
      </div>
      <div class="container">
        <el-row :gutter="20" class="elrow">
          <el-col :span="10">
              <div class="item">
                <span>背景图：</span>
                <el-image
                  v-if="giftImage"
                  style="width: 150px; height: 150px"
                  :src="giftImage"
                  :preview-src-list="[giftImage]"
                  :initial-index="1"
                  fit="cover"
                />
                <el-upload
                  ref="upload"
                  list-type="picture-card"
                  :on-success="uploadSuccess(index)"
                  :on-error="uploadError"
                  :action="Geturl+'admin/file/uploadFile'"
                  :headers="headers"
                  :show-file-list="false"
                >
                  <el-icon><Plus /></el-icon>
                </el-upload>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="item">
                <span>二维码图片：</span>
                <el-image
                  v-if="QRcode"
                  style="width: 150px; height: 150px"
                  :src="QRcode"
                  :preview-src-list="[QRcode]"
                  :initial-index="1"
                  fit="cover"
                />
                <el-upload
                  ref="upload"
                  list-type="picture-card"
                  :on-success="uploadSuccess3(index)"
                  :on-error="uploadError"
                  :action="Geturl+'admin/file/uploadFile'"
                  :headers="headers"
                  :show-file-list="false"
                >
                  <el-icon><Plus /></el-icon>
                </el-upload>
              </div>
            </el-col>
        </el-row>
      </div>
      
      <div class="container rule" v-for="(rule,rIndex) in rules" :key="rIndex">
        <el-row :gutter="20" class="elrow">
          <el-col :span="6">
            <div class="item">
              <span>组名:</span>
              <el-input v-model="rule.name"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <span>性格名称：</span>
              <el-input v-model="rule.define" placeholder="最多10个字"></el-input>
            </div>
          </el-col>
          <el-col :span="9" style="margin-bottom:10px">
            <div class="item">
              <span>题目</span>
              <el-select style="width:100%" multiple v-model="rule.questionIds">
                <el-option v-for="q in questionList" :key="q.question.id" :label="q.question.name.replace(/<.+?>/g,'')" :value="q.question.id"/>
              </el-select>
            </div>
          </el-col>
          <el-col :span="3">
            <el-button @click="deleteRule1(rIndex)">删除</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="addBtn">
        <el-button @click="addNewRule1()">新增分组</el-button>
      </div>
    </div>
    <div class="addBtn">
      
      <el-button type="success" @click="submitPrizeList">确定奖励</el-button>
    </div>
  </div>
</template>
<script>
import {Get_url} from "@/config/Get_url";
import {Plus} from "@element-plus/icons-vue"
import 'element-plus/es/components/icon/style/css'
import { ElMessage } from 'element-plus';
import global_api,{QuerySurveyInfoBySurveyTypeAndOriginId,InsertOrUpdateReward,DeleteRewardListByRewardId} from '@/config/Api';
import editor from '@/components/editor.vue'
export default {
  data() {
    return {
      id: '',
      name: '',

      prizeList: [],
      Geturl: Get_url(),
      headers: {
        id:localStorage.getItem('id'),
        token: localStorage.getItem('token')
      },
      questionList:[],
      loading: false,
      rules: [],
      giftImage:'',
      QRcode:'',
    }
  },
  components:{
    Plus,editor
  },
  mounted() {
    this.id = this.$route.query.id;
    this.name = this.$route.query.name;
    this.type = this.$route.query.type;
    this.getData();
    if(this.type == 'edit'){
      let prizeData = JSON.parse(sessionStorage.getItem('prizeData'));
      console.log(prizeData);
      let prize = {
        name: prizeData.name,
        title: prizeData.title,
        remark: prizeData.remark,
        priority: prizeData.priority,
        maxRewardNo: prizeData.maxRewardNo,
        type: 5,
        max: [],
        value: JSON.parse(prizeData.value),
        id: prizeData.id
      }
      let rewardConditionList = prizeData.rewardConditionList;
      let ruleV = JSON.parse(rewardConditionList[0].value);
      prize.max = ruleV.max;
      let rules = [];
      for(let i in ruleV.group){
        rules.push({
          name: i,
          questionIds: ruleV.group[i],
          define: prize.value.groupName[i]
        })
      }
      this.rules = rules;
      this.giftImage = prizeData.giftImage;
      this.QRcode = prizeData.backgroundImg;
      this.prizeList = [prize];
      console.log(this.prizeList);
    }
    
    
  },
  methods:{
    // 添加一个新奖励
    addNewPrize() {
      let prize = {
        name: '',
        title: '',
        remark: '',
        priority: 1,
        maxRewardNo: 0,
        type: 5,
        giftImage: '',
        max: [],
        value: {},
      };
      this.prizeList.push(prize);
    },
    // 删除一个奖励
    deletePrize(index) {
      this.prizeList.splice(index,1)
    },
    getContent(data,index){
      if('<p><br></p>'!=data){
        this.prizeList[index].value.describe = data
      }
      
    },
    // 上传成功
    uploadSuccess(){
      return (e,uploadFile) => {
        console.log(uploadFile);
        this.giftImage = e.msg
      }
    },
    uploadSuccess3(){
      return (e,uploadFile) => {
        console.log(uploadFile);
        this.QRcode = e.msg
      }
    },
    uploadSuccess1(index){
      return (e) => {
        this.prizeList[index].value.title = e.msg
      }
    },
    uploadSuccess2(index){
      return (e) => {
        this.prizeList[index].value.radar = e.msg
      }
    },
    // 上传失败
    uploadError(e){
      console.log(e);
      ElMessage.error('上传图片失败')
    },


    // 获取问卷
    getData(){
      let param = {
        originId: this.id,
        surveyName: this.name,
        surveyType: 4
      }
      this.loading = true;
      QuerySurveyInfoBySurveyTypeAndOriginId(param).then(res => {
        this.loading = false;
        this.getQuestionList(res.result.id)
      },err=>{
        this.loading = false;
        ElMessage.error('获取问卷信息失败')
      })
    },
    // 获取题目列表
    getQuestionList(id) {
      let param = {
        surveyId: id,
        pageNo: 1,
        pageSize: 100,
      }
      this.loading = true;
      global_api.GetQuestionList(param).then(res => {
        this.loading = false;
        this.questionList = res.result;
      }, err => {
        this.loading = false;
        ElMessage.error(`获取失败:${err.msg}`)
      })
    },
    // 确定奖励
    submitPrizeList(){
      if(!this.checkData()) return ;
      let prizeList = this.prizeList;
      let rules = this.rules;
      let params = [];
      for(let i in prizeList){
        let group = {};
        let groupName = {};
        for(let n in rules){
          group[rules[n].name] = rules[n].questionIds
          groupName[rules[n].name] = rules[n].define;
        }
        let value = {
          max: prizeList[i].max,
          group: group,
        }
        let rewardConditionList = [{
          value: JSON.stringify(value),
          conditionType: 4,
          type: 1,
          priority: 1,
          details: []
        }];
        prizeList[i].value.group = group;
        prizeList[i].value.groupName = groupName;
        params.push({
          specialEventId: this.$route.query.id,
          name: prizeList[i].name,
          title: prizeList[i].title,
          subtitle: prizeList[i].title,
          remark: prizeList[i].remark,
          priority: prizeList[i].priority,
          type: prizeList[i].type,
          maxRewardNo:prizeList[i].maxRewardNo,
          value: JSON.stringify(prizeList[i].value),
          surveyId: this.$route.query.id,
          rewardConditionList: JSON.stringify(rewardConditionList),
          rule: '0',
          id: -1,
          giftImage: this.giftImage,
          isUseDefaultImg: 0,
          backgroundImg: this.QRcode,
        })
      }
      for(let i in params){
        this.loading = params.length
        InsertOrUpdateReward(params[i]).then(res=>{
          this.loading -= 1;
          if(prizeList[i].id){
            DeleteRewardListByRewardId({rewardId:prizeList[i].id})
          }
          if(!this.loading){
            this.$router.push({path: '/survey'})
          }
        },err=>{
          this.loading -= 1;
        })
      }
    },
    // 检测数据是否正确
    checkData(){
      let flag = true;
      if(!this.giftImage){
        ElMessage.error('请上传背景图');
        return false;
      }
      if(!this.QRcode){
        ElMessage.error('请上传二维码图片');
        return false;
      }
      if(!this.rules.length){
        ElMessage.error('请设置分组信息');
        return false;
      }
      for(let i in this.rules){
        if(!this.rules[i].name){
          ElMessage.error('请完善分组组名');
          return false;
        }
        if(!this.rules[i].questionIds.length){
          ElMessage.error('请完善分组题目');
          return false;
        }
        if(!this.rules[i].define){
          ElMessage.error('请完善分组性格名称');
          return false;
        }
      }
      if(!this.prizeList.length){
        ElMessage.error('请设置奖励内容信息');
        return false;
      }
      for(let i in this.prizeList){
        if(!this.prizeList[i].name){
          ElMessage.error('请设置奖励名称');
          return false;
        }
        if(!this.prizeList[i].title){
          ElMessage.error('请设置奖励标题');
          return false;
        }
        if(!this.prizeList[i].remark){
          ElMessage.error('请设置奖励备注');
          return false;
        }
        if(!this.prizeList[i].value.title){
          ElMessage.error('请设置奖励标题图片');
          return false;
        }
        if(!this.prizeList[i].value.trait){
          ElMessage.error('请设置奖励性格特征');
          return false;
        }
        if(!this.prizeList[i].value.describe){
          ElMessage.error('请设置奖励性格描述');
          return false;
        }
        if(!this.prizeList[i].value.color1){
          ElMessage.error('请设置奖励性格颜色');
          return false;
        }
        if(!this.prizeList[i].value.begets){
          ElMessage.error('请设置奖励合得来信息');
          return false;
        }
        if(!this.prizeList[i].value.phaseGrams){
          ElMessage.error('请设置奖励合不来信息');
          return false;
        }
      }
      return flag;
    },
    // 添加公共分组一个组
    addNewRule1(){
      this.rules.push({
        name: '',
        questionIds: [],
      })
    },
    // 删除一个公共分组的组
    deleteRule1(rIndex){
      this.rules.splice(rIndex,1);
    },
    // 删除分组中一个题目
    deleteQuestion1(rIndex){
      this.rules[rIndex].questionIds=[];
    },


  }
}
</script>
<style lang="scss" scoped>
.main {
  .prize {
    padding: 0 20px 20px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    margin-top: 20px;
    .prizeInfo{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #E0E0E0;
      padding-bottom: 10px;
      padding-top: 10px;
    }
    .prizeNo {
      margin: 0;
      font-size: 16px;
      line-height: 30px;
    }
    .container{
      .elrow {
        margin-top: 10px;
      }
      .item {
        display: flex;
        align-items: flex-start;
        span{
          font-size: 14px;
          color: #333;
          width: 100px;
          line-height: 30px;
        }
      }
    }
    .rule{
      border: 1px solid #E0E0E0;
      margin: 20px 0;
      padding: 20px;
    }
  }
  .addBtn{
    margin-top: 20px;
  }
}
</style>
<style>
.item .el-input-group__append{
  padding: 0 !important;
}
.item .el-input-group__append .el-color-picker__trigger{
  padding: 0 !important;
}
</style>