<template>
  <div class="Cherished_desire_detail">
    <div class="main">
      <section class="search_btn">
        <div class="search_items">
          <span class="title">参与用户：</span>
          <div>
            <el-input
                v-model="input1"
                class="w-50 m-2"
                size="large"
                placeholder="Please Input"
            ></el-input>
          </div>
        </div>

        <div class="search_items">
          <span class="title">参与时间：</span>
          <div>
            <el-date-picker
                v-model="time"
                type="date"
                placeholder="请选择"
            />
          </div>
        </div>
        <div class="search_items">
          <el-button type="primary" :icon="Search">查询</el-button>
        </div>
      </section>
      <div class="detail_title">
        <span>心愿名称：</span>
        <span>虎年快乐</span>
      </div>
      <section class="user_info_list">
        <el-table :data="tableData">
          <el-table-column  prop="id" label="ID" />
          <el-table-column  prop="user" label="参与用户" />
          <el-table-column  prop="status" label="完成状态"/>
          <el-table-column  prop="create_time" label="创建时间"/>
<!--          <el-table-column fixed="right" label="操作" width="200">-->
<!--            <template #default="scope">-->
<!--              <el-tag class="ml-2">查看</el-tag>-->
<!--            </template>-->
<!--          </el-table-column>-->
        </el-table>
      </section>
      <section class="pagination">
        <el-pagination :page-size="20" :pager-count="11" background layout="prev, pager, next" :total="1000"></el-pagination>
      </section>
    </div>
  </div>
</template>
<script>

import {Search,Plus} from '@element-plus/icons-vue'
export default {
  data() {
    return {
      Search:Search,    //图标
      Plus:Plus,
      tableData:[
        {
          id:1,
          user:'aaa',
          status:'已完成',
          create_time:'2022-02-09'
        },
        {
          id:2,
          user:'bbb',
          status:'已完成',
          create_time:'2022-02-09'
        },
      ]
    }
  },
  mounted() {

  },
  methods: {
    Cherished_desire_add(){
      console.log(23)
      this.$router.push('/Cherished_desire_list/add')
    },
    Update_line(scope){
      this.$router.push('/Cherished_desire_list/update_line')
    }

  },
}
</script>
<style scoped lang="scss">
.Cherished_desire_detail{
  margin-top: 20px;
  .detail_title{
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
    span:nth-child(2){
      color: red;
      //font-size: 14px;
    }
  }
  .user_info_list{
    margin-top: 20px;
    .list_image{
      width: 40px;
      height: 40px;
    }
    .marginTop10{
      margin-top: 10px;
      margin-left: 0 !important;
    }
  }
  .pagination{
    margin-top: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

</style>