<template>
  <div class="addPrize" v-loading="loading">
    <section>
      <p class="title">特殊奖励</p>
      <el-button type="primary" @click="$router.push({ path: '/survey/special', query: { id: id, name: name } })">特殊奖励配置</el-button>
      <p class="title">奖励内容</p>
      <el-form label-width="120px" inline>
        <el-form-item label="名称（不显示）*">
          <el-input v-model="prizeData.name" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="奖励标题*">
          <el-input v-model="prizeData.title" placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="奖励备注*">
          <el-input v-model="prizeData.remark" placeholder="请输入备注" />
        </el-form-item>
        <el-form-item label="优先级*">
          <el-input-number v-model="prizeData.priority" placeholder="请输入优先级" />
          <span>（数字越大，用户拿到这个奖励就越优先。）</span>
        </el-form-item>
        <el-form-item label="获奖上限*">
          <el-input-number v-model="prizeData.maxRewardNo" placeholder="请输入获奖上限" />
        </el-form-item>
        <el-form-item label="奖励类型*">
          <el-select v-model="prizeData.type">
            <el-option label="没有奖励" :value="-1" />
            <el-option label="心愿币" :value="0" />
            <el-option label="优惠券" :value="1" />
            <el-option label="实物" :value="2" />
            <el-option label="红包" :value="3" />
            <el-option label="自定义(表格)" :value="4" />
            <el-option label="现金" :value="6" />
          </el-select>
        </el-form-item>
        <el-form-item label="心愿币" v-if="prizeData.type === 0">
          <el-input-number v-model="valObj.coin" />
          <span>个</span>
        </el-form-item>
        <el-form-item label="优惠券" v-if="prizeData.type === 1">
          <div style="width:100%;display:flex">
            <el-select style="margin-right:20px" v-model="valObj.type">
              <el-option label="淘口令" :value="1" />
              <el-option label="二维码" :value="2" />
              <el-option label="公众号" :value="3" />
              <el-option label="小程序" :value="4" />
            </el-select>
            <el-input v-model="valObj.in1" v-if="valObj.type == 4" placeholder="小程序ID" />
            <el-input v-model="valObj.in2" placeholder="链接" />
          </div>
        </el-form-item>
        <el-form-item label="红包" v-if="prizeData.type === 3">
          <el-input-number v-model="valObj.money" />
        </el-form-item>
        <el-form-item label="现金" v-if="prizeData.type === 6">
          <el-input-number v-model="valObj.coin" />
          <span>元</span>
        </el-form-item>
        <el-form-item label="找谁领" v-if="prizeData.type === 2 || prizeData.type === 3">
          <el-select v-model="valObj.lingUrl">
            <el-option label="小星" value="https://mp.weixin.qq.com/s/80wQidjJRSnY4pe6Npw1TQ" />
            <el-option label="小月" value="https://mp.weixin.qq.com/s/cMBOP43vOHXbUKyqCfA7nQ" />
          </el-select>
        </el-form-item>
        <el-form-item label="法律文件" v-if="prizeData.type !== -1 && prizeData.type !== 4 && prizeData.type !== 6">
          <div style="width:100%;display:flex">
            <el-select style="margin-right:20px" v-model="valObj.falvType">
              <el-option label="必选" :value="1" />
              <el-option label="非必选" :value="2" />
            </el-select>
            <el-select v-if="valObj.falvType === 1" v-model="valObj.falvUrl">
              <el-option v-for="item in falvList" :key="item.id" :label="item.kvKey" :value="item.id"></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="文件名字" v-if="prizeData.type !== -1 && prizeData.type !== 4 && valObj.falvType === 1">
          <el-input v-model="valObj.falvName" />
        </el-form-item>
        <!-- 表格 -->
        <el-form-item label="表格名" v-if="prizeData.type === 4" style="width:100%">
          <el-input v-model="tableName" placeholder="表格名" />
        </el-form-item>
        <el-form-item label="列名" v-if="prizeData.type === 4" style="width:100%">
          <el-tag v-for="(tag, i) in colList" class="ml-2" :key="tag" closable :disable-transitions="false"
            @close="handleClose1(i)">{{ tag }}</el-tag>
          <el-input v-if="inputVisible" ref="InputRef" v-model="inputValue" class="ml-1 w-20" size="small"
            @keyup.enter="handleInputConfirm" @blur="handleInputConfirm" />
          <el-button v-else size="small" @click="inputVisible = true">+ 列</el-button>
        </el-form-item>
        <el-form-item label="行组" v-if="prizeData.type === 4" style="width:100%">
          <el-button size="small" @click="addRow">新增行</el-button>
          <div class="rowBoxList" v-for="(item, index) in results" :key="index">
            <div class="rowBoxRow">
              {{ index + 1 }}.{{ item.rowName }}
              <el-button @click="removeRow(index)">删除</el-button>
            </div>
            <div class="rowBoxRow">
              <span>行名：</span><el-input v-model="item.rowName" class="rowInput" />
              <span>公式：</span><el-input v-model="item.formula" class="rowInput" />
            </div>
            <div class="rowBoxRow">
              <span>题目组：</span>
              <el-select class="select" multiple v-model="item.question_ids">
                <el-option v-for="(opt, optIndex) in questionList" :key="optIndex" :value="opt.question.id"
                  :label="opt.question.name">{{ opt.question.name }}</el-option>
              </el-select>
            </div>
            <div class="rowBoxRow">
              <span>分数区间：</span>
              <el-button @click="addScoreList(index)">新增分数区间</el-button>
            </div>
            <div class="scoreList" v-for="(score, scoreIndex) in item.scoreList" :key="scoreIndex">
              <div class="scoreRow">
                <el-button size="small" @click="item.scoreList.splice(scoreIndex, 1)">删除</el-button>
              </div>
              <div class="scoreRow">
                <span>类型：</span>
                <el-select v-model="score.type">
                  <el-option label="大于" :value="1"></el-option>
                  <el-option label="小于" :value="2"></el-option>
                  <el-option label="等于" :value="3"></el-option>
                  <el-option label="区间内(闭合)" :value="4"></el-option>
                  <el-option label="区间外(闭合)" :value="5"></el-option>
                  <el-option label="区间内(开放)" :value="6"></el-option>
                  <el-option label="区间内(开放)" :value="7"></el-option>
                </el-select>
              </div>
              <div class="scoreRow">
                <span>分数：</span>
                <el-input-number v-model="score.number1" style="margin-right:10px"></el-input-number>
                <el-input-number v-model="score.number2" v-if="score.type > 3"></el-input-number>
              </div>
              <div class="scoreRow">
                <span>结果：</span>
                <el-input v-model="score.text" style="width:200px"></el-input>
              </div>
              <div class="scoreRow">
                <span>位置：</span>
                <el-select v-model="score.col">
                  <el-option v-for="(col, colIndex) in colList" :key="colIndex" :value="colIndex" :label="col"></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="图片*" style="width:100%">
          <el-upload :action="Geturl + 'admin/file/uploadFile'" :show-file-list="false" :on-success="UploadSuccess"
            :headers="headers">
            <el-button type="primary">上传</el-button>
          </el-upload>
          <img style="width:100px" :src="prizeData.giftImage" alt="">
        </el-form-item>
        <el-form-item label="默认背景图">
          <el-select v-model="prizeData.isUseDefaultImg">
            <el-option label="使用" :value="0"></el-option>
            <el-option label="不使用" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="默认图片" style="width:100%" v-if="prizeData.isUseDefaultImg === 1">
          <el-upload :action="Geturl + 'admin/file/uploadFile'" :show-file-list="false" :on-success="uploadSuccess2"
            :headers="headers">
            <el-button type="primary">上传</el-button>
          </el-upload>
          <img style="width:100px" :src="prizeData.backgroundImg" alt="">
        </el-form-item>
        <el-form-item label="名称对应FAQ">
          <img style="width: 300px" src="/images/reward_sample.png" alt="">
        </el-form-item>
      </el-form>
      <el-divider content-position="left"></el-divider>
      <p class="title">奖励规则</p>
      <el-form label-width="100px">
        <el-form-item label="打卡次数">
          <el-input-number v-model="phaseNumber" :min="1" :step="1"></el-input-number>
          <el-button type="primary" style="margin-left: 4px" @click="addPhase">新增</el-button>
          <el-tag v-for="(item, index) in phase" :key="index" class="ml-2" closable
            @close="phase.splice(index, 1)">第{{ item }}次打卡</el-tag>
        </el-form-item>
        <el-form-item label="答卷时间">
          <div style="width: 100%;margin-bottom: 10px">
            <el-select v-model="prizeData.timeType">
              <el-option label="大于" :value="1"></el-option>
              <el-option label="小于" :value="2"></el-option>
              <el-option label="区间内" :value="3"></el-option>
            </el-select>
          </div>
          <el-input-number v-if="prizeData.timeType == 3 || prizeData.timeType == 1" placeholder="最小时间"
            v-model="prizeData.minTime" />
          <el-input-number v-if="prizeData.timeType == 2" placeholder="最大时间" v-model="prizeData.minTime" />
          <span v-if="prizeData.timeType == 3 || prizeData.timeType == 1 || prizeData.timeType == 2">分钟</span>
          <el-input-number placeholder="最大时间" v-if="prizeData.timeType == 3" v-model="prizeData.maxTime"
            style="margin-left:20px" />
          <span v-if="prizeData.timeType == 3">分钟</span>
        </el-form-item>
        <el-form-item label="选择人群" style="width:100%">
          <el-button type="primary" @click="openCrowd()">选择</el-button>
          <el-tag v-for="(item, index) in crowds" :key="index" class="ml-2" closable
            @close="handleClose(index)">{{ item.name }}</el-tag>
        </el-form-item>
        <el-form-item label="分数区间">
          <div style="width: 100%;margin-bottom: 10px">
            <span>逻辑关系</span>&nbsp;
            <el-select v-model="prizeData.scoreType">
              <el-option label="区间内" :value="0"></el-option>
              <el-option label="区间外" :value="1"></el-option>
            </el-select>
          </div>
          <el-input-number placeholder="最小分数" v-model="prizeData.minScore" />
          <el-input-number placeholder="最大分数" v-model="prizeData.maxScore" style="margin-left:20px" />
        </el-form-item>
        <el-form-item label="题目选择">
          <div style="width: 100%;margin-bottom: 10px">
            <el-button type="primary" @click="addQuesCheck">新增</el-button>
          </div>
          <div class="quesList" v-for="(item, index) in quesCheck" :key="index">
            <el-form inline label-width="80px">
              <el-form-item label="题目:">
                <el-select v-model="item.value" @change="questionChange(index)">
                  <el-option :label="item.question.name + '【' + item.questionType.name + '】'" :value="item.question.id"
                    v-for="item in questionList"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="逻辑关系:">
                <el-select v-model="item.type">
                  <el-option label="与逻辑" :value="0"></el-option>
                  <el-option label="或逻辑" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="优先级:">
                <el-input-number v-model="item.priority"></el-input-number>
              </el-form-item>
              <el-form-item v-if="item.questionType">
                <el-button type="primary" @click="addOptionSet(index)">新增选项设置</el-button>
              </el-form-item>
              <div class="optionItem" v-for="(detail, detailIndex) in item.details">
                <section
                  v-if="item.questionType && (item.questionType === 1 || item.questionType === 12 || item.questionType === 13)">
                  <el-form-item label="选项设置:">
                    <el-select v-model="detail.condition">
                      <el-option label="选中" :value="1"></el-option>
                      <el-option label="不选中" :value="2"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-radio-group v-model="detail.optionId">
                      <el-radio :label="opt.id" v-for="opt in item.optionList">
                        {{ opt.name }}
                        <img v-if="opt.img || opt.iconSelect" style="height: 50px;display:block;float: right"
                          :src="opt.img || opt.iconSelect">
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item v-if="item.details.length > 1">
                    <el-button type="danger" @click="removeOptionSet(index, detailIndex)">删除选项设置</el-button>
                  </el-form-item>
                </section>
                <section v-if="item.questionType === 2">
                  <el-form-item label="选项设置:">
                    <el-select v-model="detail.condition">
                      <el-option label="选中" :value="1"></el-option>
                      <el-option label="不选中" :value="2"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-checkbox-group v-model="detail.optionId">
                      <el-checkbox v-for="opt in item.optionList" :label="opt.id">
                        {{ opt.name }}
                        <img v-if="opt.img || opt.iconSelect" style="height: 50px;display:block;float: right"
                          :src="opt.img || opt.iconSelect">
                      </el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                  <el-form-item v-if="item.details.length > 1">
                    <el-button type="danger" @click="removeOptionSet(index, detailIndex)">删除选项设置</el-button>
                  </el-form-item>
                </section>
                <section v-if="item.questionType === 3">
                  <el-form-item label="选项设置:">
                    <el-select v-model="detail.condition">
                      <el-option label="大于" :value="3"></el-option>
                      <el-option label="小于" :value="4"></el-option>
                      <el-option label="等于" :value="5"></el-option>
                      <el-option label="包含" :value="6"></el-option>
                      <el-option label="不包含" :value="7"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-input v-if="detail.condition === 6 || detail.condition === 7" v-model="detail.value"
                      placeholder="请输入关键词"></el-input>
                    <el-input-number v-if="detail.condition === 3 || detail.condition === 4 || detail.condition === 5"
                      v-model="detail.value"></el-input-number>
                    <span v-if="detail.condition === 3 || detail.condition === 4 || detail.condition === 5">字</span>
                  </el-form-item>
                  <el-form-item v-if="item.details.length > 1">
                    <el-button type="danger" @click="removeOptionSet(index, detailIndex)">删除选项设置</el-button>
                  </el-form-item>
                </section>
                <section v-if="item.questionType === 4">
                  <el-form-item label="选项排序:">
                    <el-input-number v-model="detail.value"></el-input-number>
                  </el-form-item>
                  <el-form-item>
                    <el-radio-group v-model="detail.optionId">
                      <el-radio :label="opt.id" v-for="opt in item.optionList">
                        {{ opt.name }}
                        <img v-if="opt.img || opt.iconSelect" style="height: 50px;display:block;float: right"
                          :src="opt.img || opt.iconSelect">
                      </el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item v-if="item.details.length > 1">
                    <el-button type="danger" @click="removeOptionSet(index, detailIndex)">删除选项设置</el-button>
                  </el-form-item>
                </section>
                <section v-if="item.questionType === 5 || item.questionType === 7 || item.questionType === 11">
                  <el-form-item label="选项设置:">
                    <el-select v-model="detail.condition">
                      <el-option label="大于" :value="3"></el-option>
                      <el-option label="小于" :value="4"></el-option>
                      <el-option label="等于" :value="5"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-input-number v-model="detail.value"></el-input-number>
                  </el-form-item>
                  <el-form-item v-if="item.details.length > 1">
                    <el-button type="danger" @click="removeOptionSet(index, detailIndex)">删除选项设置</el-button>
                  </el-form-item>
                </section>
                <section v-if="item.questionType === 6">
                  <el-form-item label="选项设置:">
                    <el-select v-model="detail.optionId">
                      <el-option :label="opt.name" :value="opt.id" v-for="opt in item.optionList"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-select v-model="detail.condition">
                      <el-option label="大于" :value="3"></el-option>
                      <el-option label="小于" :value="4"></el-option>
                      <el-option label="等于" :value="5"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-input-number v-model="detail.value"></el-input-number>
                  </el-form-item>
                  <el-form-item v-if="item.details.length > 1">
                    <el-button type="danger" @click="removeOptionSet(index, detailIndex)">删除选项设置</el-button>
                  </el-form-item>
                </section>
                <section v-if="item.questionType === 8 || item.questionType === 9">
                  <el-form-item label="选项设置:">
                    <el-select v-model="detail.optionId">
                      <div v-for="opt in item.optionList">
                        <el-option :label="opt.name" :value="opt.id" v-if="opt.optionPosition === 'left'"></el-option>
                      </div>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-select v-model="detail.condition">
                      <el-option label="选中" :value="8"></el-option>
                      <el-option label="不选中" :value="9"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-select v-model="detail.value">
                      <div v-for="opt in item.optionList">
                        <el-option :label="opt.name" :value="opt.id" v-if="opt.optionPosition === 'up'"></el-option>
                      </div>
                    </el-select>
                  </el-form-item>
                  <el-form-item v-if="item.details.length > 1">
                    <el-button type="danger" @click="removeOptionSet(index, detailIndex)">删除选项设置</el-button>
                  </el-form-item>
                </section>
                <section v-if="item.questionType === 10">
                  <el-form-item label="选项设置:">
                    <el-select v-model="detail.condition">
                      <el-option label="选中" :value="1"></el-option>
                      <el-option label="不选中" :value="2"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-select v-model="detail.optionId">
                      <el-option :label="opt.name" :value="opt.id" v-for="opt in item.optionList"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item v-if="item.details.length > 1">
                    <el-button type="danger" @click="removeOptionSet(index, detailIndex)">删除选项设置</el-button>
                  </el-form-item>
                </section>
              </div>
            </el-form>
            <div class="item">
              <el-button type="danger" @click="removeQuesCheck(index)">删除</el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="公式">
          <div style="width: 100%;margin-bottom: 10px" v-if="formulaList.length === 0">
            <el-button type="primary" @click="addFormula">新增</el-button>
          </div>
          <div class="quesList" v-for="(item, index) in formulaList" :key="index">
            <div class="item">
              <el-button @click="removeFormula(index)">删除</el-button>
            </div>
            <div class="item">
              <span>优先级</span>&nbsp;
              <el-input-number v-model="item.priority"></el-input-number>
            </div>
            <div class="item">
              题目与变量对应关系
              <el-button type="primary" @click="addVariable(index)">添加变量</el-button>
            </div>
            <div>
              <div class="item" v-for="(va, n) in item.variable">
                <span>题目</span>&nbsp;&nbsp;
                <el-select v-model="va.queId">
                  <el-option :label="que.question.name" :value="que.question.id" v-for="que in questionList"></el-option>
                </el-select>&nbsp;&nbsp;
                <span>变量</span> &nbsp;
                <el-input style="display: inline" v-model="va.x" disabled></el-input>
                &nbsp;&nbsp;
                <el-button @click="removeVariable(index, n)">删除变量</el-button>
              </div>
            </div>
            <div class="item">
              公式&nbsp;&nbsp;
              <el-input style="display: inline" v-model="item.formula" disabled></el-input>&nbsp;&nbsp;
              <el-select v-model="item.type">
                <el-option label="大于" value=">"></el-option>
                <el-option label="大于等于" value=">="></el-option>
                <el-option label="小于" value="<"></el-option>
                <el-option label="小于等于" value="<="></el-option>
                <el-option label="等于" value="=="></el-option>
                <el-option label="不等于" value="!="></el-option>
              </el-select>&nbsp;&nbsp;
              <el-input-number v-model="item.res"></el-input-number>
              <el-button type="primary" @click="openEditFormula">编辑公式</el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </section>
    <section>
      <el-button type="primary" @click="AddPrize">确定</el-button>
    </section>

    <!-- 选择人群 -->
    <el-dialog v-model="crowdDialog" :title="人群列表" draggable width="800px">
      <section class="search_btn">
        <div class="search_items">
          <span class="title">人群名称：</span>
          <div>
            <el-input v-model="targetName" class="w-50 m-2" size="large" placeholder="请输入人群名称"></el-input>
          </div>
        </div>
        <div class="search_items">
          <el-button type="primary" @click="pageNo = 1; openCrowd()">查询</el-button>
        </div>
      </section>
      <el-table :data="tableData" highlight-current-row @current-change="handleCurrentChange">
        <el-table-column prop="id" label="id" width="100" />
        <el-table-column prop="name" label="人群名称" />
        <el-table-column prop="content" label="人群描述" />
      </el-table>
      <el-pagination style="margin-top:10px" v-model:currentPage="pageNo" :page-size="10" background
        layout="prev, pager, next" :total="total1" @current-change="openCrowd" />
      <template #footer>
        <el-button @click="crowdDialog = false">取消</el-button>
        <el-button v-if="!isShow" type="primary" @click="sureCrowd">确定</el-button>
        <el-button v-if="isShow" type="primary" @click="sureShow">确定</el-button>
      </template>
    </el-dialog>

    <!-- 编辑公式弹窗 -->
    <el-dialog v-model="formulaDialog" title="编辑公式" draggable width="580px">
      <div class="formulaBox">
        <div class="formulaText">{{ editFormula.join('') }}</div>
        <div class="formulaMain">
          <div class="numberBox">
            <div class="number" @click="clickBrackets('(')">(</div>
            <div class="number" @click="clickBrackets(')')">)</div>
            <div class="number"></div>
            <div class="number" @click="backspaceFormula">BS</div>
            <div class="number" @click="clickNumber(7)">7</div>
            <div class="number" @click="clickNumber(8)">8</div>
            <div class="number" @click="clickNumber(9)">9</div>
            <div class="number" @click="clickOperator('+')">+</div>
            <div class="number" @click="clickNumber(4)">4</div>
            <div class="number" @click="clickNumber(5)">5</div>
            <div class="number" @click="clickNumber(6)">6</div>
            <div class="number" @click="clickOperator('-')">-</div>
            <div class="number" @click="clickNumber(1)">1</div>
            <div class="number" @click="clickNumber(2)">2</div>
            <div class="number" @click="clickNumber(3)">3</div>
            <div class="number" @click="clickOperator('*')">*</div>
            <div class="number" @click="clearFormula">C</div>
            <div class="number" @click="clickNumber(0)">0</div>
            <div class="number" @click="clickOperator('%')">%</div>
            <div class="number" @click="clickOperator('/')">/</div>
          </div>
          <div class="formulaRight">
            <div class="number" v-for="item in editVariable" @click="clickFormula(item.x)">{{ item.x }}</div>
          </div>
        </div>
      </div>
      <template #footer>
        <el-button @click="formulaDialog = false">取消</el-button>
        <el-button @click="sureFormula">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import global_api, { DeleteRewardListByRewardId, getTargetAudienceById, GetTargetAudienceList, InsertOrUpdateReward, QuerySurveyInfoBySurveyTypeAndOriginId } from "@/config/Api";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { Get_url } from "@/config/Get_url";

let editC
export default {
  data() {
    return {
      formulaDialog: false,
      editFormula: '',
      editVariable: [],
      id: '',
      name: '',
      questionList: [],
      phaseNumber: 1,
      falvList: [],
    }
  },
  mounted() {
    let type = this.$route.query.type;
    this.id = this.$route.query.id;
    this.name = this.$route.query.name;
    if (type === 'edit') {
      let prizeData = JSON.parse(sessionStorage.getItem('prizeData'));
      console.log(prizeData)
      this.prizeData = prizeData;
      this.valObj = JSON.parse(prizeData.value);
      this.getPhase(this.prizeData.rewardConditionList);
      this.getTarget(this.prizeData.rewardConditionList);
      this.getScoreList(this.prizeData.rewardConditionList);
      this.getFormulaList(this.prizeData.rewardConditionList);
      this.getTime(this.prizeData.rewardConditionList);
    }
    this.getData();
    this.getFaLvlist();
  },
  methods: {
    // 获取法律信息列表
    getFaLvlist() {
      let param = {
        cunPage: 1,
        pageSize: 100,
        type: 2,
      }
      global_api.QueryKvMapListByType(param).then(res => {
        this.falvList = res.result;
      }, err => {
        this.falvList = [];
      })
    },
    // 选择题目
    questionChange(index) {
      let id = this.quesCheck[index].value;
      for (let i in this.questionList) {
        if (id === this.questionList[i].question.id) {
          this.quesCheck[index].optionList = this.questionList[i].optionList;
          this.quesCheck[index].questionType = this.questionList[i].question.type;
          this.quesCheck[index].details = [{
            optionId: [],
            value: '',
            condition: null,
          }]
        }
      }
    },
    // 增加选项设置
    addOptionSet(index) {
      this.quesCheck[index].details.push({
        optionId: [],
        value: '',
        condition: null,
      })
    },
    // 删除选项设置
    removeOptionSet(index, detailIndex) {
      this.quesCheck[index].details.splice(detailIndex, 1);
    },
    // 打开编辑题目
    openEditFormula() {
      let editFormula = this.formulaList[0].formula.split('');
      for (let i = 0; i < editFormula.length; i++) {
        if (/^X\d*$/.test(editFormula[i])) {
          if (editFormula[i + 1] && editFormula[i + 1] - 0 === Number(editFormula[i + 1])) {
            editFormula[i] = editFormula[i] + editFormula[i + 1];
            editFormula.splice(i + 1, 1);
            i -= 1;
          }
        }
      }
      console.log(editFormula)
      this.editFormula = editFormula;
      this.editVariable = this.formulaList[0].variable;
      this.formulaDialog = true;
    },
    // 点击输入 数字
    clickNumber(e) {
      let editFormula = this.editFormula;
      let length = editFormula.length;
      if (length) {
        let value = editFormula[length - 1];
        if (typeof value === "number") {
          this.editFormula[length - 1] = this.editFormula[length - 1] * 10 + e;
        } else if (value === '(') {
          this.editFormula.push(e);
        } else if (value === ")") {
          ElMessage.error('请选择运算符')
        } else if (/^X\d*$/.test(value)) {
          ElMessage.error('请选择运算符')
        } else {
          this.editFormula.push(e);
        }
      } else {
        this.editFormula.push(e);
      }
      console.log(this.editFormula)
    },
    // 点击输入 括号
    clickBrackets(e) {
      let editFormula = this.editFormula;
      let length = editFormula.length;
      switch (e) {
        case '(':
          if (length) {
            let value = editFormula[length - 1];
            if (typeof value === "number") {
              ElMessage.error('请选择运算符')
            } else if (value === '(') {
              this.editFormula.push(e);
            } else if (value === ')') {
              ElMessage.error('请选择运算符')
            } else if (/^X\d*$/.test(value)) {
              ElMessage.error('请选择运算符')
            } else {
              this.editFormula.push(e);
            }
          } else {
            this.editFormula.push(e);
          }
          break;
        case ')':
          if (length) {
            let value = editFormula[length - 1];
            if (typeof value === "number") {
              let num1 = 0;
              let num2 = 0;
              editFormula.map(item => {
                if (item === '(') num1 += 1;
                if (item === ')') num2 += 1;
              })
              num1 > num2 ? this.editFormula.push(e) : ElMessage.error('没有想匹配的"("')
            } else if (value === '(') {
              ElMessage.error('()中没有表达式')
            } else if (value === ')') {
              let num1 = 0;
              let num2 = 0;
              editFormula.map(item => {
                if (item === '(') num1 += 1;
                if (item === ')') num2 += 1;
              })
              num1 > num2 ? this.editFormula.push(e) : ElMessage.error('没有想匹配的"("')
            } else if (/^X\d*$/.test(value)) {
              let num1 = 0;
              let num2 = 0;
              editFormula.map(item => {
                if (item === '(') num1 += 1;
                if (item === ')') num2 += 1;
              })
              num1 > num2 ? this.editFormula.push(e) : ElMessage.error('没有想匹配的"("')
            } else {
              ElMessage.error('请输入数字或变量')
            }
          } else {
            ElMessage.error('）不能在第一位')
          }
          break;
      }

    },
    // 点击输入 运算符
    clickOperator(e) {
      let editFormula = this.editFormula;
      let length = editFormula.length;
      if (length) {
        let value = editFormula[length - 1];
        if (typeof value === "number") {
          this.editFormula.push(e);
        } else if (value === '(') {
          ElMessage.error('请输入数字或变量')
        } else if (value === ')') {
          this.editFormula.push(e);
        } else if (/^X\d*$/.test(value)) {
          this.editFormula.push(e);
        } else {
          ElMessage.error('请输入数字或变量')
        }
      } else {
        ElMessage.error(`${e}不能在第一位`)
      }
    },
    // 点击输入 变量
    clickFormula(e) {
      let editFormula = this.editFormula;
      let length = editFormula.length;
      if (length) {
        let value = editFormula[length - 1];
        if (typeof value === "number") {
          ElMessage.error('请选择运算符')
        } else if (value === '(') {
          this.editFormula.push(e);
        } else if (value === ")") {
          ElMessage.error('请选择运算符')
        } else if (/^X\d*$/.test(value)) {
          ElMessage.error('请选择运算符')
        } else {
          this.editFormula.push(e);
        }
      } else {
        this.editFormula.push(e);
      }
    },
    // 清除公式
    clearFormula() {
      this.editFormula = [];
    },
    // 回退
    backspaceFormula() {
      if (this.editFormula.length) {
        this.editFormula.splice(this.editFormula.length - 1, 1);
      }
    },
    // 确定
    sureFormula() {
      let editFormula = this.editFormula;
      let num1 = 0;
      let num2 = 0;
      editFormula.map(item => {
        if (item === '(') num1 += 1;
        if (item === ')') num2 += 1;
      })
      if (num1 === num2) {
        this.formulaList[0].formula = this.editFormula.join('');
        this.formulaDialog = false;
      } else {
        ElMessage.error('（）没有正确的闭合')
      }

    },
    // 获取打卡次数
    getPhase(list) {
      console.log(list)
      let phase = [];
      for (let i in list) {
        if (list[i].conditionType === 4) {
          for (let n in list[i].rewardConditionDetails) {
            phase.push(list[i].rewardConditionDetails[n].value)
          }
        }
      }
      this.phase = phase;
    },
    // 获取人群库分类
    getTarget(list) {
      console.log(list)
      let ids = [];
      for (let i in list) {
        if (list[i].conditionType === 0) {
          ids.push(list[i].rewardConditionDetails[0].value)
        }
      }
      if (ids.length) {
        let param = {
          targetAudienceId: ids.join(',')
        }
        getTargetAudienceById({ params: param }).then(res => {
          this.crowds = res.result;
        }, err => {
          this.crowds = [];
        })
      }
    },
    // 获取分数
    getScoreList(list) {
      for (let i in list) {
        if (list[i].conditionType == 2) {
          this.prizeData.scoreType = list[i].type;
          this.prizeData.minScore = list[i].rewardConditionDetails[0].value
          this.prizeData.maxScore = list[i].rewardConditionDetails[1].value
          break
        }
      }
    },
    // 获取时间
    getTime(list) {
      for (let i in list) {
        if (list[i].conditionType == 5) {
          if (list[i].rewardConditionDetails?.length == 2) {
            this.prizeData.timeType = 3;
            this.prizeData.minTime = list[i].rewardConditionDetails[0].value
            this.prizeData.maxTime = list[i].rewardConditionDetails[1].value
          } else {
            this.prizeData.timeType = list[i].rewardConditionDetails[0].condition == 3 ? 1 : 2;
            this.prizeData.minTime = list[i].rewardConditionDetails[0].value
          }
          break
        }
      }
    },
    // 获取公式
    getFormulaList(list) {
      for (let i in list) {
        console.log(list[i]);
        if (list[i].conditionType === 3) {
          let variable = [];
          for (let n in list[i].rewardConditionDetails) {
            list[i].rewardConditionDetails[n];
            variable.push({
              x: list[i].rewardConditionDetails[n].value,
              queId: list[i].rewardConditionDetails[n].optionId
            })
          }
          let value = list[i].value;
          let formula, type, res;
          if (value.indexOf('>=') !== -1) {
            type = '>=';
            formula = value.split('>=')[0];
            res = value.split('>=')[1]
          } else if (value.indexOf('>') !== -1) {
            type = '>';
            formula = value.split('>')[0];
            res = value.split('>')[1]
          } else if (value.indexOf('<=') !== -1) {
            type = '<=';
            formula = value.split('<=')[0];
            res = value.split('<=')[1]
          } else if (value.indexOf('<') !== -1) {
            type = '<';
            formula = value.split('<')[0];
            res = value.split('<')[1]
          } else if (value.indexOf('!=') !== -1) {
            type = '!=';
            formula = value.split('!=')[0];
            res = value.split('!=')[1]
          } else if (value.indexOf('==') !== -1) {
            type = '==';
            formula = value.split('==')[0];
            res = value.split('==')[1]
          }
          console.log(value.indexOf('>'))
          this.formulaList = [{
            priority: list[i].priority,
            variable: variable,
            formula,
            type,
            res,
          }]
        }
      }
    },
    // 处理题目
    getQuesCheck(list) {
      let quesCheck = [];
      for (let i in list) {
        if (list[i].conditionType === 1) {
          for (let n in this.questionList) {
            if (this.questionList[n].question.id === Number(list[i].value)) {
              quesCheck.push({
                value: list[i].value - 0, // 题目id
                conditionType: 1, // 根据选项
                type: list[i].type, // 逻辑关系
                priority: list[i].priority, // 优先级
                details: list[i].rewardConditionDetails,
                // details:[{
                //   optionId: null, // 选项id
                //   value: null, // 分数、矩阵选项Id
                //   condition: null, // 答题类型 1 选中 2 不选中 3 大于 4 小于 5 等于 6 包含 7 不包含 8 矩阵选中 9 矩阵不选中
                // }],
                questionType: this.questionList[n].question.type, // 题目类型
                optionList: this.questionList[n].optionList, // 选项列表
              })
            }
          }
        }
      }
      this.quesCheck = quesCheck;
      console.log(quesCheck)
    },
    // 获取问卷
    getData() {
      let param = {
        originId: this.id,
        surveyName: this.name,
        surveyType: 4
      }
      this.loading = true;
      QuerySurveyInfoBySurveyTypeAndOriginId(param).then(res => {
        this.loading = false;
        this.getQuestionList(res.result.id)
      }, err => {
        this.loading = false;
        ElMessage.error('获取问卷信息失败')
      })
    },
    // 获取题目列表
    getQuestionList(id) {
      let param = {
        surveyId: id,
        pageNo: 1,
        pageSize: 500,
      }
      this.loading = true;
      global_api.GetQuestionList(param).then(res => {
        this.loading = false;
        this.questionList = res.result;
        this.getQuesCheck(this.prizeData.rewardConditionList);
      }, err => {
        this.loading = false;
        ElMessage.error(`获取失败:${err.msg}`)
      })
    },
    //
    uploadSuccess2(e) {
      this.prizeData.backgroundImg = e.msg
    },
    // 新增期数
    addPhase() {
      if (!this.phaseNumber) return ElMessage.error('请输入期数');
      if (this.phase.indexOf(this.phaseNumber) !== -1) return ElMessage.error('期数已存在');
      this.phase.push(this.phaseNumber);
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    let loading = ref(false);
    let Geturl = ref('');
    Geturl = Get_url();
    let headers = ref({});
    headers = {
      id: localStorage.getItem('id'),
      token: localStorage.getItem('token')
    };

    let addPrizeData = {
      id: route.query.id,
      name: route.query.name
    }





    // 编辑奖励条件
    let quesCheck = ref([]);
    let phase = ref([]);
    let crowdDialog = ref(false);
    let tableData = ref([]);
    let total1 = ref(1);
    let pageNo = ref(1);
    let temCrowd = null;
    let crowds = ref([]);
    let targetName = ref('');
    let formulaList = ref([]);
    // 新增题目选择设置
    function addQuesCheck() {
      quesCheck.value.push({
        value: null, // 题目id
        conditionType: 1, // 根据选项
        type: null, // 逻辑关系
        priority: 1, // 优先级
        details: [{
          optionId: null, // 选项id
          value: null, // 分数、矩阵选项Id
          condition: null, // 答题类型 1 选中 2 不选中 3 大于 4 小于 5 等于 6 包含 7 不包含 8 矩阵选中 9 矩阵不选中
        }],
        questionType: null, // 题目类型
        optionList: [], // 选项列表
      })
    }
    // 删除题目选择设置
    function removeQuesCheck(index) {
      quesCheck.value.splice(index, 1);
    }
    // 打开人群库
    function openCrowd() {
      loading.value = true;
      let param = {
        pageNo: pageNo.value,
        pageSize: 10,
        targetName: targetName.value
      }
      GetTargetAudienceList({ params: param }).then(res => {
        loading.value = false;
        crowdDialog.value = true;
        tableData.value = res.result;
        total1.value = res.data.totalCount;

      }, err => {
        loading.value = false;
        ElMessage.error(`获取人群列表失败：${err.msg}`)
      })
    }
    // 选择人群
    function handleCurrentChange(row) {
      temCrowd = row;
    }
    // 确定选择的人群
    function sureCrowd() {
      if (!temCrowd?.id) return ElMessage.error('请选择');
      let f = false;
      for (let i in crowds.value) {
        if (crowds.value[i].id == temCrowd.id) {
          f = true;
          break;
        }
      }
      if (!f) {
        crowds.value.push({ ...temCrowd });
      }
      crowdDialog.value = false;
    }
    // 删除标签
    function handleClose(i) {
      crowds.value.splice(i, 1);
    }
    // 新增公式
    function addFormula() {
      formulaList.value.push({ variable: [], formula: '' })
    }
    // 删除公式
    function removeFormula(i) {
      formulaList.value.splice(i, 1)
    }
    // 添加变量
    function addVariable(i) {
      console.log(formulaList.value[i].variable)
      let num = 1;
      for (let n in formulaList.value[i].variable) {
        let x = formulaList.value[i].variable[n].x.substring(1);
        if (x >= num) {
          num = x - -1;
        }
      }
      formulaList.value[i].variable.push({
        x: `X${num}`
      })
    }
    // 删除变量
    function removeVariable(i, n) {
      formulaList.value[i].variable.splice(n, 1)
    }



    // 页面显示
    let questionList = ref([]);





    // 编辑奖励页面
    let inputVisible = ref(false);
    let inputValue = ref('');
    let colList = ref([]);
    let results = ref([]);
    // 上传图片
    function UploadSuccess(e) {
      prizeData.value.giftImage = e.msg
    }
    // 添加列
    function handleInputConfirm() {
      if (inputValue.value && inputValue.value.replace(/\s/g, '')) {
        colList.value.push(inputValue.value);
        inputValue.value = '';
        inputVisible.value = false;
      } else {
        inputValue.value = '';
        inputVisible.value = false;
      }
    }
    // 删除列
    function handleClose1(i) {
      colList.value.splice(i, 1)
    }
    // 增加行
    function addRow() {
      results.value.push({ scoreList: [] })
    }
    // 删除行组
    function removeRow(i) {
      results.value.splice(i, 1);
    }
    // 增加分数组
    function addScoreList(i) {
      results.value[i].scoreList.push({})
    }




    // 添加奖励功能
    let prizeData = ref({
      priority: 1,
      type: -1,
      isUseDefaultImg: 0,
    });
    let valObj = ref({
      falvType: 2, // 是否有法律文件 1 有 2 没有
    });
    let tableName = ref('');
    // 添加数据处理
    function AddPrize() {
      console.log(prizeData.value.title)
      if (!prizeData.value.name) return ElMessage.error('请输入名称');
      if (!prizeData.value.title) return ElMessage.error('请输入标题');
      if (!prizeData.value.remark) return ElMessage.error('请输入备注');
      if (!prizeData.value.priority && prizeData.value.priority !== 0) return ElMessage.error('请输入优先级');
      if (!prizeData.value.maxRewardNo && prizeData.value.maxRewardNo !== 0) return ElMessage.error('请输入获奖上线');
      if (!prizeData.value.type && prizeData.value.type !== 0) return ElMessage.error('请选择奖励类型');
      if (prizeData.value.type === 0 && !valObj.value.coin && valObj.value.coin !== 0) return ElMessage.error('请输入心愿币');

      if (!valObj.value.falvType && prizeData.value.type !== -1 && prizeData.value.type !== 4) return ElMessage.error('请选择是否必选法律文件');
      if (valObj.value.falvType === 1 && prizeData.value.type !== -1 && prizeData.value.type !== 4 && !valObj.value.falvUrl) return ElMessage.error('请填写法律链接');
      if (valObj.value.falvType === 1 && prizeData.value.type !== -1 && prizeData.value.type !== 4 && !valObj.value.falvName) return ElMessage.error('请填写法律文件名字');

      let valueJson = {};
      if (prizeData.value.type === -1) {
        // 没有奖励
        valueJson = '0';
      } else if (prizeData.value.type === 0) {
        // 心愿币
        valueJson.coin = valObj.value.coin;
        valueJson.falvType = valObj.value.falvType;
        if (valObj.value.falvType === 1) {
          valueJson.falvUrl = valObj.value.falvUrl;
          valueJson.falvName = valObj.value.falvName;
        }
      } else if (prizeData.value.type === 1) {
        if (!valObj.value.type) {
          ElMessage.error('请选择优惠券类型');
          return;
        }
        // 优惠券
        if (valObj.value.type === 4) {
          // 小程序
          if (!valObj.value.in1 || !valObj.value.in2) {
            ElMessage.error('请填写链接');
            return;
          }
          valueJson.type = valObj.value.type;
          valueJson.appId = valObj.value.in1;
          valueJson.value = valObj.value.in2;
          valueJson.falvType = valObj.value.falvType;
          if (valObj.value.falvType === 1) {
            valueJson.falvUrl = valObj.value.falvUrl;
            valueJson.falvName = valObj.value.falvName;
          }
        } else {
          // 其他
          if (!valObj.value.in2) {
            ElMessage.error('请填写链接');
            return;
          }
          valueJson.type = valObj.value.type;
          valueJson.value = valObj.value.in2;
          valueJson.falvType = valObj.value.falvType;
          if (valObj.value.falvType === 1) {
            valueJson.falvUrl = valObj.value.falvUrl;
            valueJson.falvName = valObj.value.falvName;
          }
        }
      } else if (prizeData.value.type === 2) {
        // 实物
        if (!valObj.value.lingUrl) {
          ElMessage.error('请选择找谁领');
          return;
        }
        valueJson.lingUrl = valObj.value.lingUrl;
        valueJson.falvType = valObj.value.falvType;
        if (valObj.value.falvType === 1) {
          valueJson.falvUrl = valObj.value.falvUrl;
          valueJson.falvName = valObj.value.falvName;
        }
      } else if (prizeData.value.type === 3) {
        // 红包
        if (!valObj.value.lingUrl) {
          ElMessage.error('请选择找谁领');
          return;
        }
        if (!valObj.value.money) {
          ElMessage.error('请输入红包数量');
          return;
        }
        valueJson.money = valObj.value.money;
        valueJson.lingUrl = valObj.value.lingUrl;
        valueJson.falvType = valObj.value.falvType;
        if (valObj.value.falvType === 1) {
          valueJson.falvUrl = valObj.value.falvUrl;
          valueJson.falvName = valObj.value.falvName;
        }
      } else if (prizeData.value.type === 4) {
        valueJson = {
          tableName: tableName.value,
          colList: colList.value,
          results: results.value,
        }
      } else if (prizeData.value.type === 6) { // 现金奖励
        valueJson.coin = valObj.value.coin;
        valueJson.falvType = valObj.value.falvType;
      }
      console.log(JSON.stringify(valueJson));
      if (prizeData.value.isUseDefaultImg === 1 && !prizeData.value.backgroundImg) {
        ElMessage.error('请上传背景图');
        return;
      }
      let rewardConditionList = [];
      // 人群
      for (let i in crowds.value) {
        rewardConditionList.push({
          value: '',
          conditionType: 0,
          type: 1,
          priority: 1,
          details: [{
            optionId: 0,
            value: crowds.value[i].id,
            condition: 5
          }]
        })
      }

      // 分数
      if (typeof prizeData.value.minScore === "number" || typeof prizeData.value.maxScore === "number") {
        if (!prizeData.value.minScore && prizeData.value.minScore !== 0 || !prizeData.value.maxScore && prizeData.value.maxScore !== 0) return ElMessage.error('请输入分数区间')
        if (prizeData.value.minScore >= prizeData.value.maxScore) return ElMessage.error('分数最小值必须小于最大值');
        rewardConditionList.push({
          value: '',
          conditionType: 2,
          type: prizeData.value.scoreType,
          priority: 1,
          details: [{
            optionId: 0,
            value: prizeData.value.minScore,
            condition: prizeData.value.scoreType ? 4 : 3
          }, {
            optionId: 0,
            value: prizeData.value.maxScore,
            condition: prizeData.value.scoreType ? 3 : 4
          }
          ]
        })
      }
      // 题目选项
      for (let i in quesCheck.value) {
        if (!quesCheck.value[i].value) return ElMessage.error(`题目选项第${i - -1}项，没有选择题目`);
        if (!quesCheck.value[i].type && quesCheck.value[i].type !== 0) return ElMessage.error(`题目选项第${i - -1}项，没有选择逻辑关系`);
        if (!quesCheck.value[i].priority && quesCheck.value[i].priority !== 0) return ElMessage.error(`题目选项第${i - -1}项，没有填写优先级`);

        let questionId = quesCheck.value[i].value;
        let type = quesCheck.value[i].type;
        let priority = quesCheck.value[i].priority;
        let rewardCondition = {
          value: questionId,
          conditionType: 1,
          type: type,
          priority: priority,
        }
        let details = [];
        let list = quesCheck.value[i].details;
        for (let n in list) {
          // 单选题、图片题
          if (quesCheck.value[i].questionType === 1 || quesCheck.value[i].questionType === 10 || quesCheck.value[i].questionType === 12 || quesCheck.value[i].questionType === 13) {
            if (!list[n].optionId) return ElMessage.error(`题目选项第${i - -1}项，没有选择选项`);
            if (!list[n].condition) return ElMessage.error(`题目选项第${i - -1}项，没有选择类型`);
            details.push(list[n]);
          }
          // 多选题
          if (quesCheck.value[i].questionType === 2) {
            if (!list[n].optionId.length) return ElMessage.error(`题目选项第${i - -1}项，没有选择选项`);
            if (!list[n].condition) return ElMessage.error(`题目选项第${i - -1}项，没有选择类型`);
            let optionId = list[n].optionId;
            for (let k in optionId) {
              details.push({
                optionId: optionId[k],
                value: '',
                condition: list[n].condition
              })
            }
          }
          // 排序题
          if (quesCheck.value[i].questionType === 4) {
            if (!list[n].optionId) return ElMessage.error(`题目选项第${i - -1}项，没有选择选项`);
            if (!list[n].value) return ElMessage.error(`题目选项第${i - -1}项，没有输入排序`);
            details.push({
              optionId: list[n].optionId,
              value: list[n].value,
              condition: 5
            });
          }
          // 简答题、打分题/滑条题
          if (quesCheck.value[i].questionType === 3 || quesCheck.value[i].questionType === 5 || quesCheck.value[i].questionType === 7 || quesCheck.value[i].questionType === 11) {
            if (!list[n].condition) return ElMessage.error(`题目选项第${i - -1}项，没有选择类型`);
            if (!list[n].value && list[n].value !== 0) return ElMessage.error(`题目选项第${i - -1}项，没有输入匹配内容`);
            let optionList = quesCheck.value[i].optionList;
            details.push({
              optionId: optionList[0].id,
              value: list[n].value,
              condition: list[n].condition
            });
          }
          // 比重题、矩阵单选题、矩阵多选题
          if (quesCheck.value[i].questionType === 6 || quesCheck.value[i].questionType === 8 || quesCheck.value[i].questionType === 9) {
            if (!list[n].optionId) return ElMessage.error(`题目选项第${i - -1}项，没有选择选项`);
            if (!list[n].condition) return ElMessage.error(`题目选项第${i - -1}项，没有选择类型`);
            if (!list[n].value && list[n].value !== 0) return ElMessage.error(`题目选项第${i - -1}项，没有输入分数`);
            details.push(list[n]);
          }
        }
        rewardCondition.details = details;
        rewardConditionList.push(rewardCondition)
      }
      // 公式
      for (let i in formulaList.value) {
        if (formulaList.value[i].variable.length === 0) return ElMessage.error('没有变量');
        let details = []
        for (let n in formulaList.value[i].variable) {
          if (!formulaList.value[i].variable[n].queId) return ElMessage.error(`变量${formulaList.value[i].variable[n].x}没有对应题目`);
          details.push({
            optionId: formulaList.value[i].variable[n].queId,
            value: formulaList.value[i].variable[n].x,
            condition: 1
          })
        }
        if (!formulaList.value[i].formula || !formulaList.value[i].type || (!formulaList.value[i].res && formulaList.value[i].res !== 0)) return ElMessage.error('公式信息不完整')
        let value = formulaList.value[i].formula + formulaList.value[i].type + formulaList.value[i].res
        rewardConditionList.push({
          value: value,
          conditionType: 3,
          type: 0,
          priority: formulaList.value[i].priority,
          details: details
        })
      }
      // 打卡期数
      let details = [];
      for (let i in phase.value) {
        details.push({
          optionId: 0,
          value: phase.value[i],
          condition: 5
        })
      }
      if (details.length) {
        rewardConditionList.push({
          value: '',
          conditionType: 4,
          type: 1,
          priority: 1,
          details: details
        })
      }
      // 答卷时间
      if (prizeData.value.timeType) {
        if ((prizeData.value.timeType == 1 || prizeData.value.timeType == 2) && prizeData.value.minTime) {
          rewardConditionList.push({
            value: '',
            conditionType: 5,
            type: 0,
            priority: 1,
            details: [{
              optionId: 0,
              value: prizeData.value.minTime,
              condition: prizeData.value.timeType == 1 ? 3 : 4
            }]
          })
        }
        if (prizeData.value.timeType == 3 && prizeData.value.minTime && prizeData.value.maxTime) {
          rewardConditionList.push({
            value: '',
            conditionType: 5,
            type: 0,
            priority: 1,
            details: [{
              optionId: 0,
              value: prizeData.value.minTime,
              condition: 3
            }, {
              optionId: 0,
              value: prizeData.value.maxTime,
              condition: 4
            }]
          })
        }
      }
      console.log(rewardConditionList);

      let param = {
        specialEventId: addPrizeData.id,
        name: prizeData.value.name,
        title: prizeData.value.title,
        subtitle: prizeData.value.title,
        remark: prizeData.value.remark,
        priority: prizeData.value.priority,
        type: prizeData.value.type,
        maxRewardNo: prizeData.value.maxRewardNo,
        value: JSON.stringify(valueJson),
        surveyId: addPrizeData.id,
        rewardConditionList: JSON.stringify(rewardConditionList),
        rule: '0',
        // id: prizeData.value.id?prizeData.value.id:-1,
        id: -1,
        giftImage: prizeData.value.giftImage,
        isUseDefaultImg: prizeData.value.isUseDefaultImg,
        backgroundImg: prizeData.value.isUseDefaultImg === 1 ? prizeData.value.backgroundImg : '',
      }
      console.log(param)
      loading.value = true;
      InsertOrUpdateReward(param).then(res => {
        if (prizeData.value.id) {
          DeleteRewardListByRewardId({ rewardId: prizeData.value.id })
        }
        loading.value = false;
        router.push({ path: '/survey' })
      }, err => {
        loading.value = false;
        ElMessage.error(`添加失败：${err.msg}`)
      })
    }


    return {
      prizeData,
      valObj,
      loading,
      questionList,
      tableName,
      colList,
      results,
      Geturl,
      headers,
      inputVisible,
      inputValue,
      quesCheck,
      phase,
      crowdDialog,
      tableData,
      total1,
      pageNo,
      crowds,
      targetName,
      formulaList,
      AddPrize,
      UploadSuccess,
      handleInputConfirm,
      handleClose1,
      addRow,
      removeRow,
      addScoreList,
      addQuesCheck,
      removeQuesCheck,
      openCrowd,
      handleCurrentChange,
      sureCrowd,
      handleClose,
      addFormula,
      removeFormula,
      addVariable,
      removeVariable,
    }
  }
}
</script>

<style>
.optionItem .el-radio,
.optionItem .el-checkbox {
  height: auto !important;
  min-height: 32px !important;
}

.optionItem .el-checkbox__label,
.optionItem .el-radio__label {
  display: flex !important;
  align-items: center !important;
}
</style>

<style scoped lang="scss">
.formulaBox {
  width: 100%;

  .formulaText {
    min-height: 50px;
    font-size: 30px;
    line-height: 50px;
    text-align: right;
    padding: 0 5px;
    background-color: #E8E8EB;
  }

  .formulaMain {
    display: flex;
    height: 450px;

    .numberBox {
      width: 340px;
    }

    .number {
      border: 1px solid #cccccc;
      border-radius: 4px;
      width: 70px;
      height: 70px;
      float: left;
      margin-left: 20px;
      margin-top: 20px;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 25px;
      line-height: 70px;
      text-align: center;

      &:hover {
        color: #ffffff;
        background-color: #409eff;
      }
    }

    .number:nth-child(4n-3) {
      margin-left: 0;
    }

    .formulaRight {
      width: 180px;
      margin-left: 20px;
      align-items: flex-start;
      overflow: auto;

      .number {
        margin-left: 0;

        &:nth-child(2n) {
          margin-left: 20px;
        }
      }
    }
  }
}


.rowBoxList {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;

  .rowBoxRow {
    width: 100%;
    margin-bottom: 10px;
    display: flex;

    span {
      width: 70px;
      margin-left: 30px;
    }

    .rowInput {
      width: 200px;
    }

    .select {
      width: 500px;
    }
  }

  .scoreList {
    width: 500px;
    margin: 10px 0 10px 100px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 5px;

    .scoreRow {
      display: flex;
      margin-bottom: 10px;
    }
  }
}

.addPrize {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  overflow: auto;

  .title {
    font-size: 16px;
    font-weight: bold;
  }
}

.ques {
  width: 100%;

  .quesTitle {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }
}

.quesList {
  padding: 20px;
  width: 100%;
  border: 1px solid #999;
  border-radius: 4px;
  margin-top: 10px;

  .optionItem {
    width: 100%;
    margin: 5px 0;
  }

  .item {
    margin-top: 10px;
  }
}</style>