<template>
  <div class="Variety_shop_order" v-loading="loading">
    <section class="search_btn">
      <div class="search_items">
        <span class="title">订单状态：</span>
        <div>
          <el-select v-model="this.status" placeholder="请输入分类等级" size="large">
            <el-option
                v-for="item in this.order_options"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search_items">
        <span class="title">商品名称：</span>
        <div>
          <el-input
              v-model="this.commodityName"
              class="w-50 m-2"
              size="large"
              placeholder="请输入商品名称"
          ></el-input>
        </div>
      </div>
      <div class="search_items">
        <span class="title">产品名称：</span>
        <div>
          <el-input
              v-model="this.productName"
              class="w-50 m-2"
              size="large"
              placeholder="请输入产品名称"
          ></el-input>
        </div>
      </div>
      <div class="search_items">
        <span class="title">用户ID：</span>
        <div>
          <el-input
              v-model="this.userId"
              class="w-50 m-2"
              size="large"
              placeholder="请输入用户ID"
          ></el-input>
        </div>
      </div>
      <div class="search_items">
        <span class="title">订单号：</span>
        <div>
          <el-input
              v-model="this.orderId"
              class="w-50 m-2"
              size="large"
              placeholder="请输入订单号"
          ></el-input>
        </div>
      </div>
      <div class="search_items">
        <span class="title">手机号：</span>
        <div>
          <el-input
              v-model="this.telNumber"
              class="w-50 m-2"
              size="large"
              placeholder="请输入手机号"
          ></el-input>
        </div>
      </div>
      <div class="search_items">
        <span class="title">开始时间：</span>
        <div>
          <el-date-picker
              v-model="this.startTime"
              value-format="YYYY-MM-DD HH:mm:ss"
              format="YYYY-MM-DD"
              placeholder="请选择"
          />
        </div>
      </div>
      <div class="search_items">
        <span class="title">结束时间：</span>
        <div>
          <el-date-picker
              v-model="this.endTime"
              value-format="YYYY-MM-DD HH:mm:ss"
              format="YYYY-MM-DD"
              placeholder="请选择"
          />
        </div>
      </div>
      <div class="search_items">
        <el-button size="small" type="primary" :icon='Search' @click="search">查询</el-button>
        <el-button size="small" type="primary" @click="SyncVarietyShopOrderList">同步数据</el-button>
      </div>
    </section>
    <section class="tableList">
      <el-table :data="tableData">
        <el-table-column fixed prop="userId" label="用户ID"/>
        <el-table-column fixed prop="userNickname" label="用户名"/>
        <el-table-column prop="orderNumber" label="订单号" width="130"/>
        <el-table-column prop="waybillNumber" label="快递单号" width="130"/>
        <el-table-column prop="orderStatus" label="订单状态">
          <template #default="scope">
            <span v-show="scope.row.orderStatus==10">待付款</span>
            <span v-show="scope.row.orderStatus==17">支付成功待核销</span>
            <span v-show="scope.row.orderStatus==20">待发货</span>
            <span v-show="scope.row.orderStatus==21">部分发货</span>
            <span v-show="scope.row.orderStatus==30">待收货</span>
            <span v-show="scope.row.orderStatus==100">完成</span>
            <span v-show="scope.row.orderStatus==250">取消</span>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="电话"/>
        <el-table-column prop="spuName" label="商品名称"/>
        <el-table-column prop="productName" label="产品名称"/>
        <el-table-column prop="orderPrice" label="价格">
          <template #default="scope">
            {{scope.row.orderPrice/100}}
          </template>
        </el-table-column>
        <el-table-column prop="coin" label="消耗心愿币的数量" width="150"/>
        <el-table-column prop="spuOrderAddressInfo.detailInfo" label="地址" width="150">
          <template #default="scope">
            {{scope.row.province+' '+scope.row.district+' '+scope.row.extInfo}}
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
          v-model:currentPage="cunPage"
          v-model:page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
      />
    </section>
  </div>
</template>
<script>
import { Plus ,Search} from '@element-plus/icons-vue'
import {ElMessage, ElMessageBox} from 'element-plus'
import global_api from "@/config/Api";

export default {
  data() {
    return {
      Plus,
      Search,
      // 当前页码
      cunPage: 1,
      // 页面数据量
      pageSize: 15,
      // 总数据条数
      total: 1,
      // 表格数据
      tableData: [],
      loading: false,
      order_options:[
        {name:'全部',value:''},
        {name:'待付款',value:10},
        {name:'支付成功待核销',value:17},
        {name:'待发货',value:20},
        {name:'部分发货',value:21},
        {name:'待收货',value:30},
        {name:'完成',value:100},
        {name:'取消',value:250}
      ],

      status:'',
      commodityName:'',   //商品名称
      productName:'',   //产品名称
      userId:'',   //用户ID
      orderId:'',  //订单号
      telNumber:'',  //手机号
      startTime:'',
      endTime:''
    }
  },
  mounted() {
    this.QueryVarietyShopOrderList()
  },
  methods: {
    search(){
      this.QueryVarietyShopOrderList()
    },
    // 获取数据
    QueryVarietyShopOrderList(){
      this.loading = true
      let param = {
        cunPage:this.cunPage,
        pageSize:this.pageSize,
        commodityName:this.commodityName,
        productName:this.productName,
        userId:this.userId,
        orderId:this.orderId,
        telNumber:this.telNumber,
        startTime:this.startTime,
        endTime:this.endTime,
        status:this.status
      }
      global_api.QueryVarietyShopOrderList(param).then((res)=>{
        this.loading = false
        this.total = res.data.totalCount
        this.tableData = res.result
        console.log(res)
      },err =>{
        this.loading = false;
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },

    //同步数据
    SyncVarietyShopOrderList(){
      this.loading = true
      global_api.SyncVarietyShopOrderList().then((res)=>{
        this.loading = false
        ElMessage({
          type: 'success',
          message: '同步数据成功',
        })
        this.QueryVarietyShopOrderList()
        // console.log(res)
      },err =>{
        this.loading = false;
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },

    // 页码改变触发
    handleCurrentChange() {
      this.QueryVarietyShopOrderList();
    },


  },
}
</script>
<style scoped lang="scss">
.Variety_shop_order{
  margin-top: 20px;
}
</style>