<template>
  <el-row class="tac">
    <el-col :span="24">
      <el-menu
        active-text-color="#ffd04b"
        background-color="#545c64"
        class="el-menu-vertical-demo"
        default-active="2"
        text-color="#fff"
        @open="handleOpen"
        @close="handleClose"
      >
        <div v-for="(item, index) in this.side_bar_list" :key="index">
          <div v-show="item.children.length > 0">
            <el-sub-menu :index="index">
              <template #title>
                <span>{{ item.name }}</span>
              </template>
              <div v-for="(item2, index2) in item.children" :key="index2">
                <el-menu-item-group
                  v-show="item2.children.length == 0"
                  @click="$router.push(item2.path)"
                >
                  <el-menu-item :index="index + '-' + index2">{{
                    item2.name
                  }}</el-menu-item>
                </el-menu-item-group>
                <el-sub-menu index="1-4" v-show="item2.children.length > 0">
                  <template #title>{{ item2.name }}</template>
                  <el-menu-item
                    v-for="(item3, index3) in item2.children"
                    :index="index + '-' + index2 + '-' + index3"
                    @click="$router.push(item3.path)"
                    :key="index3"
                    >{{ item3.name }}</el-menu-item
                  >
                </el-sub-menu>
              </div>
            </el-sub-menu>
          </div>
          <div v-show="item.children.length == 0">
            <el-menu-item index="index" @click="$router.push(item.path)">
              <span>{{ item.name }}</span>
            </el-menu-item>
          </div>
        </div>
      </el-menu>
    </el-col>
  </el-row>
</template>
<script>
import { Discount } from "@element-plus/icons-vue";
import Order_management from "@/views/Order_management/Order_management";
import H5_page_setting from "@/views/H5_page_setting/H5_page_setting";
export default {
  name: "Side_bar",
  components: { Discount },
  data() {
    return {
      side_bar_list: [
        {
          name: "会员管理",
          path: "",
          children: [
            { name: "会员内容详情", path: "/Member_detail", children: [] },
            { name: "用户解封", path: "/Member_detail/unblock", children: [] },
            { name: "用户标签", path: "/Member_detail/usertags", children: [] },
            { name: "初始问卷", path: "/Member_detail/initial", children: [] },
            { name: "心愿币", path: "/Member_detail/coin", children: [] },
          ],
        },
        {
          name: "获奖(资金)管理",
          path: "",
          children: [
            { name: "获奖列表", path: "/awardList", children: []},
            { name: "提现列表", path: "/cashout", children: []},
            { name: "支付列表", path: "/paylist", children: []},
            { name: "资金流水", path: "/capital", children: []},
          ]
        },
        {
          name: "基础属性管理",
          path: "",
          children: [
            { name: "地址管理", path: "/Address", children: [] },
            { name: "敏感词管理", path: "/sensitive", children: [] },
            { name: "数据字典", path: "/kvmap", children: [] },
          ],
        },
        {
          name: "运营规则管理",
          path: "",
          children: [
            // { name: "等级管理", path: "/level", children: [] },
            { name: "心愿币获取规则", path: "/Coin_rule", children: [] },
            { name: "反馈留言", path: "/Feedback_message", children: [] },
            { name: "活动二维码生成", path: "/WX_code", children: [] },
            { name: "模板管理", path: "/formwork", children: [] },
          ],
        },
        {
          name: "许愿管理",
          path: "",
          children: [
            { name: "心愿列表", path: "/Cherished_desire_list", children: [] },
            { name: "心愿订单管理", path: "/Order_management", children: [] },
            { name: "心愿盒管理", path: "/wishBox", children: [] },


            {name: "订单更新", path: "/updataOrder", children: [] },
          ],
        },
        {
          name: "产品管理",
          path: "",
          children: [
            { name: "产品列表", path: "/goods_list", children: [] },
            { name: "品牌列表", path: "/brandlist", children: [] },
            { name: "首页产品", path: "/homegoods", children: [] },
          ],
        },
        {
          name: "问卷管理",
          path: "",
          children: [
            { name: "调研列表", path: "/survey", children: [] },
            { name: "题目管理", path: "/Subject_management", children: [] },
            { name: "题目关联", path: "/survey/copy", children: [] },
            { name: "项目列表", path: "/project", children: [] },
          ],
        },
        {
          name: "GPT管理",
          path: "",
          children: [
            { name: "GPT", path: "/GPT", children: [] },
          ],
        },
        {
          name: "心愿广场管理",
          path: "",
          children: [
            {name: "心愿广场轮播图", path: "/square_banner", children: []},
            {name: "心愿广场话题", path: "/Topic", children: []},
            {name: "用户发帖", path: "/topic_list", children: []},
            {name: "海报", path: "/poster", children: []},
          ]
        },
        // 创建签到问卷
        {
          name: "签到问卷",
          path: "",
          children: [
            { name: "签到问卷", path: "/sign_in_survey", children: [] },
            { name: "签到设置", path: "/set_sign_in", children: [] },
            { name: "用户签到", path: "/user_sign_in", children: [] },
          ],
        },
        {
          name: "人群库",
          path: "",
          children: [
            { name: "人群库列表", path: "/Crowd_library_list", children: [] },
          ],
        },
        {
          name: "品类分类管理",
          path: "",
          children: [

            { name: "品类管理列表", path: "/Category_management_list", children: [] },
          ],
        },
        {
          name: "小程序配置",
          path: "",
          children: [
            { name: "首页轮播图配置", path: "/Slide_show_setting", children: []},
            { name: "H5页面配置", path: "/H5_page_setting", children: []},
            { name: "信息配置", path: "/Prompt_message", children: []},
            { name: "图片配置", path: "/Image_setting", children: [] },
            { name: "订阅图片", path: "/Subscribe_image", children: [] },
            { name: "设置", path: "/switchset", children: [] }
          ]
        },
        {
          name: "标签管理",
          path: "",
          children: [
            { name: "标签管理", path: "/Tag_management", children: []},
            { name: "企微标签管理", path: "/weitag", children: []},
            // { name: "标签分类管理", path: "/Tag_classification_management", children: []},
          ]
        },
        {
          name: "主页内容管理",
          path: "",
          children: [
            { name: "主页内容管理", path: "/Main_content_manage", children: []},
          ]
        },
        {
          name: "杂货铺",
          path: "",
          children: [
            { name: "订单列表", path: "/Variety_shop_order", children: []},
            { name: "商品列表", path: "/Variety_good_things", children: []},
            { name: "详情录入", path: "/Variety_detail", children: []},
          ]
        },
        {
          name: "权限管理",
          path: "",
          children: [
            { name: "管理员", path: "/permission", children: []},
            { name: "角色", path: "/permission/role", children: []},
          ]
        },
        {
          name: "活动管理",
          path: "",
          children: [
            { name: "新年活动", path: "/newyear", children: []},
            { name: "拉新活动", path: "/laxin", children: []},
          ]
        },
        {
          name: 'CEM管理',
          path: "",
          children: [
            { name: "CEM报告", path: "/cemnotice", children: [] },
            { name: "CEM主账户", path: "/cemAccount", children: [] },
            { name: "CEM子账户", path: "/cemChildAccount", children: [] },
            { name: "CEM更新公告", path: "/cemUpdataNotice", children: [] },
            { name: "CEM常见问题", path: "/cemFQA", children: [] },
          ]
        },
        {
          name: 'AI管理',
          path: "",
          children: [
            { name: "品牌维护", path: "/aiBrandList", children: [] },
          ]
        }
      ],
    };
  },
  methods: {
    handleOpen() {
      // console.log(444);
    },
    handleClose() {
      // console.log(555);
    },
  },
};
</script>

<style lang="scss">
.el-menu {
  border-right: none !important;
}
</style>
