<template>
  <div class="address" v-loading="loading">
    <section>
      <el-form :inline="true">
        <el-form-item class="search_items">
          <el-button type="danger" :icon="Plus" @click="open_address">添加地址</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="tableData">
        <el-table-column fixed prop="id" label="id" width="80" />
        <el-table-column prop="tier" label="城市Tier" />
        <el-table-column prop="region" label="地理分区" />
        <el-table-column prop="province" label="省" />
        <el-table-column prop="city" label="市" />
        <el-table-column prop="district" label="区" />
        <el-table-column prop="createTime" label="创建时间" width="180" />
        <!--      <el-table-column fixed="right" prop="userId" label="操作" width="180">-->
        <!--        <template #default="scope">-->
        <!--            <el-tag class="ml-2" @click="set_address('edit',scope)">编辑</el-tag>-->
        <!--            <el-tag class="ml-2" type="success" @click="Is_delete(scope)">删除</el-tag>-->
        <!--          </template>-->
        <!--      </el-table-column>-->
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination v-model:currentPage="pageCurrent" v-model:page-size="pageSize" background layout="prev, pager, next"
        :total="total" @current-change="handleCurrentChange" />
    </section>

    <!-- 添加修改弹窗 -->
    <el-dialog v-model="dialogVisible" :title='dialogVisible_title' width="400px" draggable>
      <el-form>
        <el-form-item label="地理分区：">
          <el-select v-model="region">
            <el-option v-for="item in regionList" :key="item.rid" :label="item.fullname" :value="item.fullname" />
          </el-select>
        </el-form-item>
        <el-form-item label="城市Tier：">
          <el-select v-model="tier">
            <el-option v-for="item in tierList" :key="item.tid" :label="item.fullname" :value="item.fullname" />
          </el-select>
        </el-form-item>
        <el-form-item label="省：">
          <el-select v-model="province" @change="provinceChange">
            <el-option v-for="item in provinceList" :key="item.pid" :label="item.fullname" :value="item.fullname" />
          </el-select>
        </el-form-item>
        <el-form-item label="市：">
          <el-select v-model="city" @change="cityChange">
            <el-option v-for="item in cityList" :key="item.cid" :label="item.fullname" :value="item.fullname" />
          </el-select>
        </el-form-item>
        <el-form-item label="区：">
          <el-select v-model="district">
            <el-option v-for="item in districtList" :key="item.aid" :label="item.fullname" :value="item.fullname" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel_address">取消</el-button>
          <el-button type="primary" @click="AddAddress" v-loading.fullscreen.lock="fullscreenLoading">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { Plus } from '@element-plus/icons-vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { QueryAddressList, AddAddress } from '../../config/Api';
// 本地省市区json
import dataJson from '../../assets/json/code.json';
export default {
  data() {
    return {
      // 图标
      Plus,
      // 当前页码
      pageCurrent: 1,
      // 页面数据量
      pageSize: 15,
      // 总数据条数
      total: 1,
      // 表格数据
      tableData: [],
      // 添加/修改
      province: '',
      city: '',
      district: '',
      tier: '',
      region: '',
      // 弹窗
      dialogVisible: false,
      dialogVisible_title: '',
      provinceList: [],
      cityList: [],
      districtList: [],
      loading: false,
      fullscreenLoading: false,
    }
  },
  mounted() {
    let query = this.$route.query;
    this.pageCurrent = Number(query.page || 1);
    this.pageSize = Number(query.size || 15);
    this.getCommentList();
  },
  methods: {
    // 页码改变触发
    handleCurrentChange() {
      this.$router.push({
        path: "/Address",
        query: { page: this.pageCurrent, size: this.pageSize },
      });
      this.getCommentList();
    },
    // 获取数据
    getCommentList() {
      let param = {
        cunPage: this.pageCurrent,
        pageSize: this.pageSize
      }
      this.loading = true;
      QueryAddressList(param).then(res => {
        this.loading = false;
        this.tableData = res.result;
        this.total = res.data.totalCount;
      }, err => {
        this.loading = false;
        ElMessage.error(`获取地址列表失败：${err.msg}`)
      })
    },
    // 打开添加弹窗
    open_address() {
      this.dialogVisible = true;
      this.dialogVisible_title = '添加地址';
      this.province = '';
      this.city = '';
      this.district = '';
      this.region = '';
      this.tier = '';
      this.provinceList = dataJson;
      this.regionList = [
        { name: "华东地区", fullname: "华东地区", rid: 1 },
        { name: "华北地区", fullname: "华北地区", rid: 2 },
        { name: "华南地区", fullname: "华南地区", rid: 3 },
        { name: "华中地区", fullname: "华中地区", rid: 4 },
        { name: "华西地区", fullname: "华西地区", rid: 5 },
      ]
      this.tierList = [
        { name: "一线城市", fullname: "一线城市", tid: 1 },
        { name: "二线城市", fullname: "二线城市", tid: 2 },
        { name: "三线城市", fullname: "三线城市", tid: 3 },
        { name: "四线城市", fullname: "四线城市", tid: 4 },
        { name: "五线城市", fullname: "五线城市", tid: 5 },
        { name: "新一线城市", fullname: "新一线城市", tid: 6 },
      ]



    },
    // 省份变化
    provinceChange() {
      console.log(this.provinceList);
      let cityList = [{ cid: '0', name: '全选', fullname: '全选' }]

      for (let i in this.provinceList) {
        if (this.province == this.provinceList[i].fullname) {
          cityList = cityList.concat(this.provinceList[i].children);
        }
      }
      this.city = '';
      this.cityList = cityList;
      this.districtList = [];
      this.district = '';
    },
    // 城市变化
    cityChange() {
      let districtList = [{ aid: '0', name: '全选', fullname: '全选' }]
      for (let i in this.cityList) {
        if (this.city === this.cityList[i].fullname && !(this.city === '全选')) {
          console.log(this.cityList[i])
          console.log(this.city);
          districtList = districtList.concat(this.cityList[i].children);
        }
      }
      this.districtList = districtList
      console.log(this.districtList)
      this.district = '';
    },

    // 添加城市
    AddAddress() {
      console.log(this.province)
      console.log(this.city)
      console.log(this.district)
      console.log(this.districtList)
      if (!this.province || !this.city || !this.district) {
        ElMessage({
          message: '请完整地址信息',
          type: 'warning',
        });
        return;

      };

      let param = {}
      if (this.city == '全选') {
        let addressList = []
        for (let i = 0; i < this.cityList.length; i++) {
          if (this.cityList[i].fullname != this.city) {
            for (let j = 0; j < this.cityList[i].children.length; j++) {
              addressList.push({ province: this.province, city: this.cityList[i].fullname, district: this.cityList[i].children[j].fullname, tier: this.tier, region: this.region })
            }
          }
        }

        param = {
          addressList: JSON.stringify(addressList)
        }

      }
      else if (this.district == '全选') {
        let addressList = []
        for (let i = 0; i < this.districtList.length; i++) {
          if (this.districtList[i].fullname != this.district) {
            addressList.push({ province: this.province, city: this.city, district: this.districtList[i].fullname, tier: this.tier, region: this.region })
          }
        }
        param = {
          addressList: JSON.stringify(addressList)
        }
        // console.log(param)
      }
      else {
        param = {
          addressList: JSON.stringify([{
            province: this.province,
            city: this.city,
            district: this.district,
            tier: this.tier,
            region: this.region
          }])
        }
      }

      // console.log(this.districtList)
      this.fullscreenLoading = true;
      AddAddress(param).then(res => {
        this.fullscreenLoading = false;
        this.dialogVisible = false;
        this.getCommentList();
      }, err => {
        this.fullscreenLoading = false;
        ElMessage.error(`添加地址失败：${err.msg}`)
      })
    },

    // 删除
    Is_delete(scope) {
      ElMessageBox.confirm(
        '是否删除?',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        ElMessage({
          type: 'success',
          message: '已删除',
        })
      })
    },
    set_address(string, scope) {      //设置地址
      this.dialogVisible = true
      if (string == 'add') {
        this.dialogVisible_title = '添加地址'
      } else {
        this.dialogVisible_title = '修改地址'
      }
    },
    cancel_address() {       //取消
      this.dialogVisible = false
    },
    confirm_address() {     //确定
      this.dialogVisible = false
      ElMessage({
        type: 'success',
        message: '操作成功',
      })
    }
  },
}
</script>

<style scoped lang="scss">
.address {
  margin-top: 20px;

  .ml-2 {
    cursor: pointer;
  }
}
</style>
