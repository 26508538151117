<template>
  <div class="menber_detail" v-loading="loading">
    <section class="search_btn">
      <el-form :inline="true" :model="formData" size="small">
        <el-form-item label="会员ID:" class="search_items" size="small">
          <el-input
            class="vipId"
            v-model="formData.userId"
            placeholder="会员ID"
            @keyup.enter="search"
          />
        </el-form-item>
        <el-form-item label="UUID:" class="search_items" size="small">
          <el-input
              class="vipId"
              v-model="formData.uuid"
              placeholder="UUID"
              @keyup.enter="search"
          />
        </el-form-item>
        <el-form-item label="会员昵称:" class="search_items">
          <el-input v-model="formData.nickname" placeholder="会员昵称" @keyup.enter="search"/>
        </el-form-item>
        <el-form-item label="会员手机号:" class="search_items">
          <el-input v-model="formData.userPhone" placeholder="会员手机号" @keyup.enter="search"/>
        </el-form-item>
        <el-form-item label="会员性别:" class="search_items">
          <el-select
            v-model="formData.userGender"
            class="m-2"
            placeholder="Select"
          >
            <el-option label="未选择" value="" />
            <el-option label="男" value="1" />
            <el-option label="女" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="最小年龄:" class="search_items">
          <el-input-number v-model="formData.minAge" :min="0" :max="100" @keyup.enter="search"/>
        </el-form-item>
        <el-form-item label="最大年龄:" class="search_items">
          <el-input-number v-model="formData.maxAge" :min="0" :max="100" @keyup.enter="search"/>
        </el-form-item>
        <el-form-item class="search_items">
          <el-button type="primary" :icon="Search" @click="search"
            >搜索</el-button
          >
          <el-button type="primary" @click="clearSearch">清空</el-button>
          <el-button type="primary" @click="openFile">批量修改心愿币</el-button>
          <el-button type="primary" @click="openDialog">批量封禁用户</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="user_info_list">
      <el-table :data="tableData">
        <el-table-column fixed prop="id" label="会员ID" width="120" />
        <el-table-column fixed prop="uuid" label="UUID" width="120" />
        <el-table-column prop="phone" label="手机号码" width="180">
          <template #default="scope">
            <div>{{ scope.row.phone }}</div>
            <el-tag
              class="ml-2"
              type="warning"
              @click="update_phone_btn(scope.row)"
              >修改</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="会员昵称" width="120" />
        <el-table-column prop="city" label="会员头像" width="120">
          <template #default="scope">
            <div v-if="scope.row.userImage">
              <img
                style="width: 40px; height: 40px"
                :src="scope.row.userImage"
                alt=""
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="会员类型" width="150">
          <template #default="scope">
            {{
              (scope.row.identityType == 1 && "普通用户") ||
              (scope.row.identityType == 2 && "官方用户") ||
              (scope.row.identityType == 3 && "品牌方用户")
            }}
            <el-tag class="ml-2" @click="openUserType(scope.row)">修改</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="rankId" label="会员等级" width="80">
          <template #default="scope">
            {{(scope.row.rankId==4&&'心愿大使')||(scope.row.rankId==3&&'心愿官')||('新人')}}
          </template>
        </el-table-column>
        <el-table-column prop="age" label="年龄" width="80" />
        <el-table-column prop="occupation.name" label="职业" width="100" />
        <el-table-column prop="gender" label="性别" width="80">
          <template #default="scope">
            {{ scope.row.gender == 1 ? "男" : "女" }}
          </template>
        </el-table-column>
        <el-table-column label="心愿币" prop="coin" width="100">
          <template #default="scope">
            {{ scope.row.coin }}
            <el-tag class="ml-2" type="warning" @click="gotoCoin(scope.row)"
              >查看</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          label="完成数/总领取数"
          prop="totalActivityCount"
          width="100"
        >
          <template #default="scope">
            {{ scope.row.finishActivityCount || 0 }}/{{scope.row.totalActivityCount || 0}}
          </template>
        </el-table-column>
        <el-table-column
          label="上期评价分"
          prop="lastUserWishScore"
          width="100"
        ></el-table-column>
        <el-table-column prop="userAddressList" label="地域" width="100">
          <template #default="scope">
            <div
              v-for="(item, index) in scope.row.userAddressList"
              :key="index"
            >
              {{ item.isDefault == 1 ? item.city : "" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="userAddressList" label="收货地址" width="150">
          <template #default="scope">
            <span class="address_text">{{
              scope.row.userAddressList.length
                ? scope.row.userAddressList.length + "条地址"
                : "无地址"
            }}</span>
            <el-tag class="ml-2" @click="Look_up_address(scope.row)"
              >查看</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="surveyTime"
          label="回答初始问卷时间"
          width="150"
        />
        <el-table-column prop="device" label="设备信息" />
        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-tag class="ml-2" v-if="scope.row.isActive" @click="banUser(scope.row)">禁用</el-tag>
            <el-tag class="ml-2" v-if="!scope.row.isActive" @click="Is_forbidden(scope.row)">已禁用</el-tag>
            <el-tag class="ml-2" @click="examine(scope.row)">查看</el-tag>
            <el-tag class="ml-2" v-if="scope.row.isDeleted" type="success">已删除</el-tag>
            <el-tag
              class="ml-2"
              v-else
              type="success"
              @click="Is_delete(scope.row)"
              >删除</el-tag
            >
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="cunPage"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </section>

    <el-dialog
      v-model="update_phone_dialog"
      title="修改手机号码"
      width="30%"
      draggable
    >
      <div class="search_items no_float">
        <span class="title">手机号码：</span>
        <div>
          <el-input
            v-model="this.phone"
            class="w-50 m-2"
            size="large"
            placeholder="Please Input"
          ></el-input>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="update_phone_dialog = false">取消</el-button>
          <el-button type="primary" @click="update_phone_confirm"
            >确认</el-button
          >
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="userTypeDialog" title="修改身份" width="500px">
      <el-form>
        <el-form-item label="用户身份">
          <el-select v-model="userTypeObj.identityType">
            <el-option label="普通用户" :value="1"></el-option>
            <el-option label="官方用户" :value="2"></el-option>
            <el-option label="品牌方用户" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定品牌" v-if="userTypeObj.identityType == 3">
          <el-select v-model="userTypeObj.linkTo">
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in brandList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="userTypeDialog = false">取消</el-button>
        <el-button type="primary" @click="modifyUserIdentityTypeByUserId"
          >确认</el-button
        >
      </template>
    </el-dialog>

    <el-dialog v-model="fileDialog" title="批量修改" width="500px">
      <el-form>
        <el-form-item label="消息标题">
          <el-input v-model="fileObj.title"></el-input>
        </el-form-item>
        <el-form-item label="消息内容">
          <el-input v-model="fileObj.mess"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="fileObj.type">
            <el-option label="增加" :value="0"></el-option>
            <el-option label="扣除" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文件">
          <fileTemplate sign="2" @file="onChange"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" @click="fileDialog = false">取消</el-button>
        <el-button type="primary" @click="updataFile">确定</el-button>
      </template>
    </el-dialog>

    <el-dialog v-model="updataDialog" title="批量修改结果" width="500px">
      <p>读取用户</p>
      <div>
        <el-tag class="ml-2" v-for="item in updataObj.read" :key="item">{{
          item
        }}</el-tag>
      </div>
      <template #footer>
        <el-button type="primary" @click="updataDialog = false">取消</el-button>
      </template>
    </el-dialog>

    <!-- 批量封禁用户 -->
    <el-dialog v-model="dialog" title="批量封禁用户" width="500px">
      <el-form>
        <el-form-item label="违规类型">
          <el-select v-model="formObj.banType" @change="formObj.remark=banTypeRemark[formObj.banType]">
            <el-option label="重复注册" value="1" ></el-option>
            <el-option label="胡乱评价/抄袭回答/反馈不真实" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="封禁提示">
          <el-input autosize type="textarea" v-model="formObj.remark"></el-input>
        </el-form-item>
        <el-form-item label="封禁类型">
          <el-select v-model="formObj.type">
            <el-option label="管理员封禁" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文件" v-if="fileShow">
          <fileTemplate sign="1" @fileData="getdata"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialog=false">取消</el-button>
        <el-button @click="submitBan" type="primary">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { Search, MessageBox } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  QueryUserListByUserSearchVo,
  ModifyUserActiveByUserId,
  RemoveUserByUserId,
  modifyUserIdentityTypeByUserId,
  modifyUserIntegral,
  modifyUserActiveByUserIds
} from "../../config/Api";
import global_api from "@/config/Api";
import { Get_url } from "../../config/Get_url";
import axios from "axios";
import fileTemplate from "@/components/fileTemplate.vue";
import {downfile} from '@/utils/downfile'
export default {
  name: "Member_detail",
  data() {
    return {
      Search, //图标
      MessageBox, //图标
      cunPage: 1, // 当前页
      pageSize: 5, // 页面数据量
      total: 1,
      formData: {
        userId: "",
        nickname: "",
        userPhone: "",
        userGender: "",
        minAge: 0,
        maxAge: 100,
      },
      tableData: [],
      loading: false,

      update_phone_dialog: false,
      userId: "",
      phone: "",

      userTypeDialog: false,
      userTypeObj: {},
      brandList: [],

      fileDialog: false,
      fileObj: {},
      fileName: "",

      updataDialog: false,
      updataObj: {},

      dialog: false,
      formObj: {},
      fileShow: true,
      banTypeRemark:{
        '1': '经查询，该账号与系统内已有账号微信名/收货人/收件地址高度近似，已被系统封禁。',
        '2': '经查询，您参与的往期评论或者调研问卷中，存在胡乱评价/抄袭回答/反馈不真实的情况。心愿盒希望用户可以反馈真实有效的个人评价，因此很抱歉无法继续为您服务，账号已被系统封禁。'
      }
    };
  },
  components: {
    fileTemplate
  },
  mounted() {
    let query = this.$route.query;
    this.cunPage = Number(query.page || 1);
    this.pageSize = Number(query.size || 5);
    this.QueryUserListByUserSearchVo();
  },
  methods: {
    downfile(href, name) {
      downfile(href, name)
    },
    // 打开批量封禁
    openDialog(){
      this.formObj = {
        banType: '1',
        remark: '经查询，该账号与系统内已有账号微信名/收货人/收件地址高度近似，已被系统封禁。',
        type: '1',
        userIds: '',
      };
      this.fileShow = true;
      this.dialog = true;
    },
    // 
    getdata(data){
      let userid = [];
      for(let i in data){
        if(data[i].用户ID){
          userid.push(data[i].用户ID)
        }
      }
      this.formObj.userIds = userid.join(',');
    },
    // 批量封禁
    submitBan(){
      if(!this.formObj.banType) return ElMessage.error('请选择违规类型');
      if(!this.formObj.remark) return ElMessage.error('请输入封禁提示');
      if(!this.formObj.type) return ElMessage.error('请选择封禁类型');
      if(!this.formObj.userIds) return ElMessage.error('没有用户ID');

      let param = {...this.formObj};
      this.loading = true;
      modifyUserActiveByUserIds(param).then(
        (res) => {
          this.loading = false;
          this.dialog = false;
          this.QueryUserListByUserSearchVo();
          ElMessage.success(res.msg)
        },
        (err) => {
          this.loading = false;
          ElMessage.error(err.msg)
        }
      )
    },
    // 打开批量修改弹窗
    openFile() {
      this.fileDialog = true;
      this.fileObj = {};
      this.fileName = "";
    },
    // 选择文件
    onChange(e) {
      this.fileObj.file = e;
    },

    // 上传批量修改
    updataFile() {
      if (!this.fileObj.title) return ElMessage.error("请填写标题");
      if (!this.fileObj.mess) return ElMessage.error("请填写内容");
      if (!this.fileObj.type && this.fileObj.type !== 0)
        return ElMessage.error("请选择类型");
      if (!this.fileObj.file) return ElMessage.error("请选择文件");

      this.loading = true;
      let fd = new FormData();
      fd.append("file", this.fileObj.file);
      fd.append("mess", this.fileObj.mess);
      fd.append("title", this.fileObj.title);
      fd.append("type", this.fileObj.type);
      axios({
        method: "POST",
        url: `${Get_url()}/admin/import/file/modifyUserIntegral`,
        data: fd,
        headers: {
          token: localStorage.getItem("token"),
          id: localStorage.getItem("id"),
        },
      })
        .then((res) => {
          console.log(res);
          this.loading = false;
          this.fileDialog = false;
          this.QueryUserListByUserSearchVo();
          // this.updataDialog = true;
          // this.updataObj = res.result;
        })
        .catch((err) => {
          this.loading = false;
          ElMessage.error("批量修改失败");
        });

      console.log(this.fileObj);
      // modifyUserIntegral(this.fileObj).then(res=>{
      //   this.loading = false;
      //   this.fileDialog = false;
      //   this.QueryUserListByUserSearchVo();
      // },err=>{
      //   this.loading = false;
      //   ElMessage.error('批量修改失败')
      // })
    },

    // 打开修改身份弹窗
    openUserType(row) {
      this.userTypeObj = {
        identityType: row.identityType,
        linkTo: row.identityType == 3 ? row.linkTo : "",
        userId: row.id,
      };
      this.userTypeDialog = true;
      this.GetBrandList();
    },
    // 获取品牌方
    GetBrandList() {
      global_api.GetBrandList().then(
        (res) => {
          this.brandList = res.result;
        },
        (err) => {
          this.brandList = [];
        }
      );
    },
    // 修改用户身份
    modifyUserIdentityTypeByUserId() {
      if (!this.userTypeObj.identityType)
        return ElMessage.error("请选择用户身份");
      if (this.userTypeObj.identityType == 3 && !this.userTypeObj.linkTo)
        return ElMessage.error("请选择绑定品牌");
      let param = {
        identityType: this.userTypeObj.identityType,
        linkTo: this.userTypeObj.linkTo || 0,
        userId: this.userTypeObj.userId,
      };
      this.loading = true;
      modifyUserIdentityTypeByUserId(param).then(
        (res) => {
          this.loading = false;
          this.userTypeDialog = false;
          this.QueryUserListByUserSearchVo();
        },
        (err) => {
          this.loading = false;
          ElMessage.error("修改用户身份失败");
        }
      );
    },

    update_phone_btn(row) {
      //修改手机号按钮
      this.update_phone_dialog = true;
      this.userId = row.id;
      this.phone = row.phone;
    },

    update_phone_confirm() {
      //修改手机号确认
      let param = {
        userId: this.userId,
        phone: this.phone,
      };
      global_api.ModifyUserPhoneByUserId(param).then(
        (res) => {
          ElMessage({
            type: "success",
            message: "修改成功",
          });
          this.QueryUserListByUserSearchVo();
          this.update_phone_dialog = false;
        },
        (err) => {
          this.update_phone_dialog = false;
          ElMessage.error(`修改失败：${err.msg}`);
        }
      );
    },

    examine(row) {
      //查看
      // console.log(row)
      this.$router.push({
        path: "/Member_detail/Questionnaire",
        query: { userId: row.id },
      });
    },
    // 获取会员列表
    QueryUserListByUserSearchVo() {
      let param = {
        cunPage: this.cunPage,
        pageSize: this.pageSize,
      };
      this.formData.userId = this.formData.userId || "";
      param = { ...param, ...this.formData };
      this.loading = true;
      QueryUserListByUserSearchVo(param).then(
        (res) => {
          this.loading = false;
          this.tableData = res.result;
          this.total = res.data.totalCount;
        },
        (err) => {
          this.loading = false;
          ElMessage(`获取会员列表失败：${err.msg}`);
        }
      );
    },
    // 上一页下一页
    handleCurrentChange() {
      this.$router.push({
        path: "/Member_detail",
        query: { page: this.cunPage, size: this.pageSize },
      });
      this.QueryUserListByUserSearchVo();
    },
    // 搜索
    search() {
      this.cunPage = 1;
      this.$router.push({
        path: "/Member_detail",
        query: { page: this.cunPage, size: this.pageSize },
      });
      this.QueryUserListByUserSearchVo();
    },
    // 清空
    clearSearch() {
      this.cunPage = 1;
      this.formData = {
        userId: "",
        nickname: "",
        userPhone: "",
        userGender: "",
        minAge: 0,
        maxAge: 100,
      };
      this.$router.push({
        path: "/Member_detail",
        query: { page: this.cunPage, size: this.pageSize },
      });
      this.QueryUserListByUserSearchVo();
    },
    // 禁用
    banUser(row){
      this.formObj = {
        banType: '1',
        remark: '经查询，该账号与系统内已有账号微信名/收货人/收件地址高度近似，已被系统封禁。',
        type: '1',
        userIds: row.id,
      };
      this.fileShow = false;
      this.dialog = true;
    },
    // 解禁
    Is_forbidden(row) {
      //是否禁用
      ElMessageBox.confirm("是否解禁该会员", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        ModifyUserActiveByUserId({ userId: row.id }).then(
          (res) => {
            this.QueryUserListByUserSearchVo();
            ElMessage.success('解禁成功')
          },
          (err) => {}
        );
      });
    },
    // 删除
    Is_delete(row) {
      //是否删除
      ElMessageBox.confirm("是否删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        RemoveUserByUserId({ userId: row.id }).then(
          (res) => {
            this.QueryUserListByUserSearchVo();
            ElMessage({
              type: "success",
              message: "已删除",
            });
          },
          (err) => {
            ElMessage({
              type: "error",
              message: err.msg,
            });
          }
        );
      });
    },
    Look_up_address(row) {
      this.$router.push({
        path: "/Member_detail/Member_address",
        query: { id: row.id, b: `${this.cunPage}_${this.pageSize}` },
      });
    },
    gotoCoin(row) {
      this.$router.push({
        path: "/Member_detail/coin",
        query: { userId: row.id },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.menber_detail {
  margin-top: 20px;
  .address_text {
    display: inline-block;
    width: 60px;
  }
}
.user_info_list {
  margin-top: 20px;
  min-width: 800px;
  .btn {
    margin-top: 5px !important;
  }
}
.pagination {
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.no_float {
  float: none !important;
}
</style>
<style>
.vipId.el-input-number span {
  display: none !important;
}
</style>
