<template>
  <div class="Main_content_manage_edit">
    <div class="main">
      <section class="search_btn">
        <div class="selected_items">
          <span class="title"><span class="red">*</span>排序：</span>
          <div>
            <el-input
                v-model="this.item_obj.sort"
                class="w-50 m-2"
                size="large"
                placeholder="请输入排序数字"
            ></el-input>
          </div>
        </div>
        <div class="selected_items">
          <span class="title">类型：</span>
          <div>
            <el-select v-model="this.item_obj.pathType" filterable placeholder="请选择" size="large" @change="init_associatedId">
              <el-option
                  v-for="item in this.pathType_options"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="selected_items">
          <span class="title" v-show="this.item_obj.pathType=='' || this.item_obj.pathType==4 || this.item_obj.pathType==5 || this.item_obj.pathType==6">视频号ID：</span>
          <span class="title" v-show="this.item_obj.pathType==7">支线问卷ID：</span>
          <span class="title" v-show="this.item_obj.pathType==1">公众号链接：</span>
          <span class="title" v-show="this.item_obj.pathType==8">产品链接链接：</span>
          <div>
            <el-input v-if="item_obj.pathType!=8"
                v-model="this.item_obj.pathContent"
                class="w-50 m-2"
                size="large"
                placeholder="请选择"
            ></el-input>
            <el-select  v-if="item_obj.pathType==8" v-model="item_obj.pathContent">
              <el-option
                  v-for="item in this.Variety_shop_options"
                  :key="item.path"
                  :label="item.title"
                  :value="item.path">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="selected_items" v-show="this.item_obj.pathType==5 || this.item_obj.pathType==6">
          <span class="title" v-show="this.item_obj.pathType==5">视频ID：</span>
          <span class="title" v-show="this.item_obj.pathType==6">首页文字：</span>
          <div>
            <el-input
                v-model="this.item_obj.associatedId"
                class="w-50 m-2"
                size="large"
                placeholder="请选择"
            ></el-input>
          </div>
        </div>
        <div class="selected_items">
          <span class="title">按钮文字：</span>
          <div>
            <el-input
                v-model="this.item_obj.btnName"
                class="w-50 m-2"
                size="large"
                placeholder="请选择"
            ></el-input>
          </div>
        </div>
        <div class="selected_items">
          <span class="title">按钮颜色：</span>
          <div style="display:flex;align-items:center;">
            <el-input
                v-model="this.item_obj.btnColor"
                class="w-50 m-2"
                size="large"
                placeholder="请选择"
            ></el-input>
            <el-color-picker v-model="this.item_obj.btnColor" style="margin-left:10px"/>
          </div>
        </div>
        <div class="start_items">
          <span class="title"><span class="red">*</span>图片上传：</span>
          <div>
            <el-upload
                class="avatar-uploader"
                :action="Get_url+'admin/file/uploadFile'"
                :headers="{token:this.token,id:this.id}"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
            >
              <img v-if="this.item_obj.image" :src="this.item_obj.image" class="avatar" />
              <img v-else src="../../assets/bannerDefualt.png" class="avatar"/>
            </el-upload>
            <div class="sub">{{'图片尺寸：2.5:1 大小<=1M'}}</div>
          </div>
        </div>
      </section>
      <section>
        <div class="selected_items">
          <span class="title"></span>
          <div>
            <el-button type="primary" @click="cancel">取消</el-button>
            <el-button type="danger" @click="confirm">确认</el-button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>

import {Search,Plus} from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import global_api from "@/config/Api";
import {Get_url} from "../../config/Get_url";
export default {
  data() {
    return {
      Search:Search,    //图标
      Plus:Plus,
      // options:[],
      pathType_options:[{text:'公众号',value:1},{text:'视频号主页',value:4},{text:'视频号视频',value:5},{text:'视频号直播',value:6},{text:'支线问卷',value:7},{text:'杂货铺产品详情',value:8}],
      Get_url:Get_url(),
      item_obj:{},
      token:localStorage.getItem('token'),
      id:localStorage.getItem('id'),
      Variety_shop_options:[],
    }
  },
  mounted() {
    // this.GetProductList()
    this.Init_date()
    this.GueryVarietyShopList();
  },
  methods: {
    init_associatedId(){                 //清空数据
      this.item_obj.pathContent = ''
      this.item_obj.associatedId = ''
      this.item_obj.btnName = ''
      this.item_obj.btnColor = ''
    },
    Init_date(){    //初始化数据
      if(this.$route.query.type=='edit'){
        this.item_obj = JSON.parse(sessionStorage.getItem('main_item'))
        this.item_obj.btnName = this.item_obj.content?this.item_obj.content.split(',')[0]:'';
        this.item_obj.btnColor = this.item_obj.content?this.item_obj.content.split(',')[1]:'';
        this.item_obj.pathContent = this.item_obj.pathContent==''?this.item_obj.pathContent:this.item_obj.pathContent;         //字符串转成number 为了匹配列表集合字段
      }else{
        this.item_obj = {
          id:'-1',
          associatedId:'',
          image:'',
          pathContent:'',
          pathType:'',      //跳转类型（ -1不跳转 0产品 1公众号 2小程序 3 小程序内部连接 4 视频号主页 5. 视频号视频 6. 视频号直播 7 支线问卷）
          sort:'',
          type:5
        }
      }
    },
    // 获取杂货铺商品列表
    GueryVarietyShopList(){
      let param = {
        cunPage:1,
        pageSize:10000,
        needEditSpu:0,
        status:5
      }
      global_api.GueryVarietyShopList(param).then((res)=>{
        this.Variety_shop_options = res.result
        // console.log(res.result)
      },err =>{
        this.loading = false;
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },

    // GetProductList(){          //获取产品列表(下拉框)
    //   global_api.GetProductList().then((res)=>{
    //     if(res.code!=0){
    //       ElMessage.error('获取产品列表错误')
    //     }else{
    //       this.options = res.result
    //       this.options.unshift({id:'',title:'不跳转'})
    //     }
    //     console.log(res)
    //   })
    // },

    handleAvatarSuccess(response,uploadFile){
      this.item_obj.image = uploadFile.response.msg
    },
    beforeAvatarUpload(rawFile){
      if (rawFile.size / 1024 / 1024 > 1) {
        ElMessage.error('上传的图片超过1MB了！')
        return false
      }
      return true
    },
    cancel(){
      this.$router.push('/Main_content_manage')
    },
    confirm(){
      let obj = {
        bannerId:this.item_obj.id,
        // id:1,
        image:this.item_obj.image,
        associatedId:this.item_obj.associatedId,
        pathContent:String(this.item_obj.pathContent),
        pathType:String(this.item_obj.pathType),
        sort:this.item_obj.sort,
        type:this.item_obj.type,
        content:`${this.item_obj.btnName},${this.item_obj.btnColor}`,
      }
      if(!this.validate(obj))  return false
      global_api.InsertOrUpdateBanner(obj).then((res)=>{
        if(res.code!=0){
          ElMessage.error('新增/修改轮播图错误')
        }else{
          ElMessage({
            message: '保存成功',
            type: 'success',
          })
          this.$router.push('/Main_content_manage')
        }
      })
    },

    validate(obj){
      if(!obj.sort){
        ElMessage.error('排序必须填写')
        return false
      }
      if(!obj.image){
        ElMessage.error('图片必须上传')
        return false
      }
      return true
    }
  },
}
</script>
<style scoped lang="scss">
.Main_content_manage_edit{
  margin-top: 20px;
  .start_items{
    img{
      width: 200px;
      height: 200px;
      border-radius: 10px;
    }
    .sub{
      margin-top: 10px;
      font-size: 12px;
      color: red;
    }
  }
}

.el-input__inner,.el-input-number,.el-textarea{
  width: 400px !important;
}

</style>