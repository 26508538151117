<template>
  <div class="menber_coomment">
    <section class="">
      <el-form inline :model="form">
        <el-form-item label="用户id">
          <el-input v-model="form.id" />
        </el-form-item>
        <el-form-item label="用户id">
          <el-input v-model="form.id" />
        </el-form-item>
        <el-form-item label="用户id">
          <el-input v-model="form.id" />
        </el-form-item>
        <el-form-item label="用户id">
          <el-select>
            <el-option label="Zone one" value="shanghai" />
            <el-option label="Zone two" value="beijing" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">查询</el-button>
<!--          <el-button type="primary">导出</el-button>-->
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column fixed prop="id" label="id" width="80" />
        <el-table-column prop="userId" label="用户id" width="80" />
        <el-table-column label="用户信息" width="180">
          <template #default="scope">
            <div class="user">
              {{ scope.row.name }}
              <img src="../../assets/autor.jpeg" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="userId" label="话题" />
        <el-table-column prop="userId" label="标题" />
        <el-table-column prop="userId" label="内容" width="150">
          <template #default>
            <el-button type="text" @click="dialogVisible=true">
              <p class="com-p">sa舒服的功夫电饭锅快捷酒店副驾驶水电费客服价格多少发生的风格的身份</p>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="图片/视频" width="250">
          <template #default>
            <div class="imgs">
              <img src="../../assets/autor.jpeg" alt="" />
              <img src="../../assets/autor.jpeg" alt="" />
              <img src="../../assets/autor.jpeg" alt="" />
              <img src="../../assets/autor.jpeg" alt="" />
              <img src="../../assets/autor.jpeg" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="关联产品" width="100">
          <template #default> </template>
        </el-table-column>
        <el-table-column prop="userId" label="点赞数量" />
        <el-table-column prop="userId" label="评论数量" />
        <el-table-column prop="userId" label="收藏数量" />
        <el-table-column prop="userId" label="点击数量" />
        <el-table-column prop="userId" label="分类" />
        <el-table-column prop="userId" label="创建时间" />
        <el-table-column label="专属推荐" width="100">
          <template #default="scope">
            <el-switch v-model="scope.row.val" />
          </template>
        </el-table-column>
        <el-table-column label="审核状态" width="100">
          <template #default="scope">
            {{ scope.row.type == 1 ? "已审核" : "未审核" }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template #default>
            <el-button size="small" type="primary" @click="dialogExamineVisible=true">审核</el-button>
            <el-button size="small" type="primary" @click="dialogEditVisible=true">编辑</el-button>
            <el-button size="small" type="danger" @click="deleteRow">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="pageCurrent"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </section>

    <!-- 内容弹窗 -->
    <el-dialog v-model="dialogVisible" title="内容" width="600px" draggable>
      <p>sa舒服的功夫电饭锅快捷酒店副驾驶水电费客服价格多少发生的风格的身份</p>
      <template #footer>
        <el-button @click="dialogVisible=false">取消</el-button>
        <el-button type="primary" @click="dialogVisible=false">确定</el-button>
      </template>
    </el-dialog>
    <!-- 审核弹窗 -->
    <el-dialog v-model="dialogExamineVisible" title="审核" width="400px" draggable>
      <div>
        <p>奖励心愿币数量</p>
        <el-input-number v-model="coinNumber" :min="0" :max="10"/>
      </div>
      <template #footer>
        <el-button @click="dialogExamineVisible=false">取消</el-button>
        <el-button type="primary" @click="dialogExamineVisible=false">通过</el-button>
      </template>
    </el-dialog>
    <!-- 编辑弹窗 -->
    <el-dialog v-model="dialogEditVisible" title="编辑" width="400px" draggable>
      <el-form :label-width="50">
        <el-form-item label="名称">
          <el-input type="text" />
        </el-form-item>
        <el-form-item label="内容">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" />
        </el-form-item>
        <el-form-item label="话题">
          <el-select  placeholder="please select your zone">
            <el-option label="Zone one" value="shanghai" />
            <el-option label="Zone two" value="beijing" />
          </el-select>
        </el-form-item>
        <el-form-item label="分类">
          <el-select  placeholder="please select your zone">
            <el-option label="Zone one" value="shanghai" />
            <el-option label="Zone two" value="beijing" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialogEditVisible=false">取消</el-button>
        <el-button type="primary" @click="dialogEditVisible=false">通过</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessageBox } from 'element-plus'
export default {
  name: "Member_detail",
  data() {
    return {
      // 当前页码
      pageCurrent: 1,
      // 页面数据量
      pageSize: 10,
      // 总数据条数
      total: 1000,
      // 表格数据
      tableData: [
        { id: 1462, userId: 85426, name: "哈哈哈哈", val: false, type: 1 },
        { id: 1546, userId: 934165, name: "哈哈哈哈", val: false, type: 2 },
      ],
      // 表单数据
      form: {},
      // 获奖心愿币数量
      coinNumber: 0,
      // 内容弹窗
      dialogVisible: false,
      dialogExamineVisible: false,
      dialogEditVisible: false,
    };
  },
  mounted() {
    let query = this.$route.query;
    this.pageCurrent = Number(query.page || 1);
    this.pageSize = Number(query.size || 10);
    this.getCommentList();
  },
  methods: {
    // 页码改变触发
    handleCurrentChange() {
      this.$router.push({
        path: "/Member_comment",
        query: { page: this.pageCurrent, size: this.pageSize },
      });
      this.getCommentList();
    },
    // 获取数据
    getCommentList() {},
    // 删除
    deleteRow() {
      ElMessageBox.alert('确认删除','提示',)
    }
  },
};
</script>
<style scoped lang="scss">

.com-p {
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user {
  display: flex;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
  }
}
.imgs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 40px;
    height: 40px;
    margin: 5px 0;
  }
}
</style>
