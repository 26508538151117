<template>
  <div class="goods_resource_list" v-loading="loading">
    <section class="searchForm">
      <el-button size="small" type="primary" @click="openDialog('add')">新增</el-button>
    </section>
    <section class="tableList">
      <el-table :data="tableData">
        <el-table-column label="ID" prop="id" width="120"></el-table-column>
        <el-table-column label="按钮类型" prop="purpose" width="150">
          <template #default="scope">
            {{(scope.row.purpose==0&&'默认')||(scope.row.purpose==1&&'购买')||(scope.row.purpose==2&&'导流')}}
          </template>
        </el-table-column>
        <el-table-column label="类型" prop="type" width="150">
          <template #default="scope">
            {{(scope.row.type==2&&'淘口令')||(scope.row.type==3&&'公众号/外部链接')||(scope.row.type==4&&'小程序')||(scope.row.type==5&&'PPT')||(scope.row.type==6&&'二维码')}}
          </template>
        </el-table-column>
        <el-table-column label="链接" prop="url"></el-table-column>
        <el-table-column label="操作" width="160">
          <template #default="scope">
            <el-button size="small" type="primary" @click="openDialog('edit',scope.row)">修改</el-button>
            <el-button size="small" type="danger" @click="remove(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="pageNo"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="totalCount"
        @current-change="Current_change"
      />
    </section>
    <!-- 新增 -->
    <el-dialog :title="type=='add'?'新增':'修改'" v-model="dialogShow" width="500px">
      <el-form label-width="80px">
        <el-form-item label="按钮类型">
          <el-select v-model="formData.purpose" @change="formData.type=''">
            <el-option label="默认" :value="0"/>
            <el-option label="购买" :value="1"/>
            <el-option label="导流" :value="2"/>
          </el-select>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="formData.type" @change="formData.url = ''">
            <el-option label="淘口令" :value="2"/>
            <el-option label="公众号/外部链接" :value="3"/>
            <el-option label="小程序" :value="4"/>
            <el-option label="PPT" :value="5"/>
            <el-option label="二维码" :value="6"/>
            <el-option v-if="formData.purpose != 2" label="商铺产品" :value="10"/>
          </el-select>
        </el-form-item>
        <el-form-item label="小程序ID" v-if="formData.type==4">
          <el-input v-model="formData.appId" placeholder="小程序ID"></el-input>
        </el-form-item>
        <el-form-item label="链接" v-if="formData.type != 10">
          <el-input v-model="formData.url" placeholder="链接"></el-input>
        </el-form-item>
        <el-form-item label="商品" v-if="formData.type == 10">
          <el-select v-model="formData.url">
            <el-option v-for="item in Variety_shop_options" :key="item.spuId" :value="item.path" :label="item.title"/>
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input-number v-model="formData.sort" placeholder="排序"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="dialogShow=false">取消</el-button>
        <el-button type="primary" @click="addResource">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import global_api, {
  AddProductResouce, ModifyProductResourceByProductResourceId,
  QueryProductResourceListByProductResourceSearchVo, RemoveProductResourceByProductResourceId
} from "@/config/Api";
import { ElMessage,ElMessageBox } from 'element-plus'
export default {
  name: '',
  data(){
    return {
      pageNo:1,       //页码
      pageSize:10,      //每页数量
      totalCount:0,         //总数
      loading: false,
      productId:'',
      tableData:[],
      dialogShow: false,
      formData: {},
      type: 'add',
      Variety_shop_options: [],
    }
  },
  mounted() {
    this.productId = this.$route.query.productId
    this.QueryProductResourceListByProductResourceSearchVo()
    this.GueryVarietyShopList();
  },
  methods: {
    QueryProductResourceListByProductResourceSearchVo(){                            //获取资源
      let obj = {
        productId:this.productId,
        pageSize:this.pageSize,
        cunPage:this.pageNo,
        type:1
      }
      this.loading = true;
      QueryProductResourceListByProductResourceSearchVo(obj).then(res => {
        this.loading = false;
        this.tableData = res.result
        console.log(res)
      },err => {

      })
    },
    // 上一页下一页
    Current_change() {
      this.$router.push({
        path: '/goods_list/resource',
        query: {page: this.pageCurrent, size: this.pageSize}
      });
      this.QueryProductResourceListByProductResourceSearchVo();
    },
    // 删除
    remove(row){
      ElMessageBox.confirm(
        '是否删除?',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(()=>{
        let param = {
          productResourceId:row.id,
        }
        this.loading = true;
        RemoveProductResourceByProductResourceId(param).then(res => {
          this.loading = false;
          this.QueryProductResourceListByProductResourceSearchVo();
        },err=>{
          ElMessage.error(`删除失败：${err.msg}`)
        })
      })
    },
    // 打开弹窗
    openDialog(type,row){
      if(type=='add'){
        this.formData = {};
        this.dialogShow = true;
        this.type = 'add'
      } else {
        this.formData = {...row};
        if(this.formData.type == 4){
          this.formData.appId = this.formData.url.split('#')[0];
          this.formData.url = this.formData.url.split('#')[1];
        }
        this.dialogShow = true;
        this.type = 'edit'
      }
    },
    // 添加资源
    addResource(){
      if(!this.formData.purpose&&this.formData.purpose!=0){
        ElMessage.error('请选择按钮类型');
        return ;
      }
      if(!this.formData.type){
        ElMessage.error('请选择类型');
        return ;
      }
      if(this.formData.type==4&&!this.formData.appId){
        ElMessage.error('请填写小程序ID');
        return ;
      }
      if(!this.formData.url){
        ElMessage.error('请填写链接');
        return ;
      }
      if(!this.formData.sort&&this.formData.sort!=0){
        ElMessage.error('请填写排序');
        return ;
      }
      if(this.formData.purpose!=0){
        for(let i in this.tableData){
          if(this.tableData[i].purpose==this.formData.purpose&&(this.formData.id!=this.tableData[i].id)){
            ElMessage.error('已存在此类型的按钮');
            return ;
          }
        }
      }
      let param = {
        productId: this.productId,
        purpose: this.formData.purpose,
        sort: this.formData.sort,
        type: this.formData.type,
        url: this.formData.type==4?this.formData.appId+'#'+this.formData.url:this.formData.url,
      }
      this.loading = true;
      if(this.type=='add'){
        AddProductResouce(param).then(res => {
          this.loading = false;
          this.dialogShow = false;
          this.QueryProductResourceListByProductResourceSearchVo();
        },err=>{
          this.loading = false;
          ElMessage.error(`添加失败:${err.msg}`)
        })
      } else {
        param.productResourceId = this.formData.id;
        ModifyProductResourceByProductResourceId(param).then(res => {
          this.loading = false;
          this.dialogShow = false;
          this.QueryProductResourceListByProductResourceSearchVo();
        },err=>{
          this.loading = false;
          ElMessage.error(`添加失败:${err.msg}`)
        })
      }
      
    },
    // 获取杂货铺商品列表
    GueryVarietyShopList(){
      let param = {
        cunPage:1,
        pageSize:10000,
        needEditSpu:0,
        status:5
      }
      global_api.GueryVarietyShopList(param).then((res)=>{
        this.Variety_shop_options = res.result
        // console.log(res.result)
      },err =>{
        this.loading = false;
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },
  },
}
</script>
<style scoped lang="scss">
</style>
