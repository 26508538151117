<template>
  <div class="Cherished_desire_add" v-loading="loading">
    <div class="main">
      <section class="search_btn">
        <div class="selected_items">
          <span class="title"><span class="red">*</span>心愿名称：</span>
          <div>
            <el-input
                v-model="wish.name"
            ></el-input>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>上线时间：</span>
          <div>
            <el-date-picker
                type="datetime"
                v-model="wish.startTime"
                value-format="YYYY-MM-DD HH:mm:ss"
                format="YYYY-MM-DD HH:mm:ss"
                placeholder="请选择"
                @change="validateDates"
            />
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>结束时间：</span>
          <div>
            <el-date-picker
                type="datetime"
                v-model="wish.endTime"
                value-format="YYYY-MM-DD HH:mm:ss"
                format="YYYY-MM-DD HH:mm:ss"
                placeholder="请选择"
                @change="validateDates"
            />
          </div>
        </div>
        <div class="selected_items">
          <span class="title">是否付费：</span>
          <div>
            <el-select v-model="wish.currency">
              <el-option label="免费" :value="0"/>
              <el-option label="心愿币" :value="1"/>
              <el-option label="人民币" :value="2"/>
            </el-select>
          </div>
        </div>
        <div class="selected_items">
          <span class="title">价格：</span>
          <div>
            <el-input v-model="wish.price"></el-input>
          </div>
        </div>
        <div class="selected_items">
          <span class="title">按钮颜色：</span>
          <div style="display:flex;">
            <el-input v-model="wish.btnColor"></el-input>
            <el-color-picker v-model="wish.btnColor" />
          </div>
        </div>
        <div class="selected_items">
          <span class="title">按钮文字颜色：</span>
          <div style="display:flex;">
            <el-input v-model="wish.btnFontColor"></el-input>
            <el-color-picker v-model="wish.btnFontColor" />
          </div>
        </div>
        <div class="selected_items">
          <span class="title">标题颜色：</span>
          <div style="display:flex;">
            <el-input v-model="wish.titleColor"></el-input>
            <el-color-picker v-model="wish.titleColor" />
          </div>
        </div>
        <div class="selected_items">
          <span class="title">内容颜色：</span>
          <div style="display:flex;">
            <el-input v-model="wish.contentColor"></el-input>
            <el-color-picker v-model="wish.contentColor" />
          </div>
        </div>
        <div class="start_items">
          <span class="title"><span class="red">*</span>心愿描述：</span>
          <div class="textarea">
            <el-input v-model="wish.content" type="textarea" :rows="10"/>
          </div>
        </div>
        <div class="start_items">
          <span class="title"><span class="red">*</span>图片上传：</span>
          <div>
            <el-upload
                :action="Get_url+'admin/file/uploadFile'"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :headers="headers"
            >
              <img v-if="wish.cover" :src="wish.cover" class="avatar" />
              <img v-else src="../../assets/bannerDefualt.png" class="avatar"/>
            </el-upload>
            <div class="sub">{{'690*760 大小<=1M'}}</div>
          </div>
        </div>
      </section>
      <section>
        <div class="selected_items">
          <span class="title"></span>
          <div>
            <el-button type="primary" @click="confirm">取消</el-button>
            <el-button type="danger" v-if="type == 'add'" @click="addWish">确认</el-button>
            <el-button type="danger" v-if="type == 'edit'" @click="modifyWish">确认</el-button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import {AddWish,ModifyWishByWishId} from '../../config/Api'
import { ElMessage, ElMessageBox } from 'element-plus'
import {Get_url} from "../../config/Get_url";
export default {
  data() {
    return {
      wish: {},
      Get_url:Get_url(),
      headers:{
        id:localStorage.getItem('id'),
        token: localStorage.getItem('token')
      },
      type: '',
    }
  },
  mounted() {
    this.type = this.$route.query.type;
    let wish = sessionStorage.getItem('wish');
    if(wish){
      this.wish = JSON.parse(wish);
    } else {
      this.wish = {status:0,price:0};
    }
  },
  methods: {
    handleAvatarSuccess(e){
      this.wish.cover = e.msg
    },
    validateDates() {
      if (new Date(this.wish.endTime) <= new Date(this.wish.startTime)) {
        ElMessage.error("结束时间必须大于开始时间");
        return false;
      }
      return true;
    },
    addWish(){
      if (!this.validateDates()) return;

      this.loading = true;
      AddWish(this.wish).then(res => {
        this.loading = false;
        this.confirm();
      },err =>{
        this.loading = false;
        ElMessage.error(`添加失败：${err.msg}`)
      })
    },
    modifyWish(){
      if (!this.validateDates()) return;

      let param = {
        content: this.wish.content,
        cover: this.wish.cover,
        currency: this.wish.currency,
        endTime: this.wish.endTime,
        name: this.wish.name,
        price: this.wish.price,
        startTime: this.wish.startTime,
        status: this.wish.status,
        wishId: this.wish.id,
        btnColor: this.wish.btnColor,
        btnFontColor: this.wish.btnFontColor,
        titleColor: this.wish.titleColor,
        contentColor: this.wish.contentColor
      }
      console.log(param)
      this.loading = true;
      ModifyWishByWishId(param).then(res => {
        this.loading = false;
        this.confirm();
      },err =>{
        this.loading = false;
        ElMessage.error(`修改失败：${err.msg}`)
      })
    },
    confirm(){
      this.$router.push('/Cherished_desire_list')
    }
  },
}
</script>
<style scoped lang="scss">
.Cherished_desire_add{
  margin-top: 20px;
  .start_items{
    img{
      width: 200px;
      border-radius: 10px;
    }
    .sub{
      margin-top: 10px;
      font-size: 12px;
      color: red;
    }
  }
}

.el-input__inner,.el-input-number,.el-textarea{
  width: 400px !important;
}

</style>
