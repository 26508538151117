import axios from 'axios'
import qs from 'qs'
import {Get_url} from '../config/Get_url'
// 创建axios实例
let baseUrl = Get_url();

let instance = axios.create({
	baseURL: baseUrl,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
		'Access-Control-Allow-Origin': '*',
	},
	// 请求最大时间
	timeout: 1000*600,
})

// axios.defaults.withCredentials = false

instance.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token')
		const id = localStorage.getItem('id')
		if (token && id) {
			// console.log(token)
			// console.log(id)
			config.headers.token = token
			config.headers.id = id
		}else{

		}
		config.data = qs.stringify(config.data) // 转为formdata数据格式
		return config
	},
	err => {
		return Promise.reject(err)
	})

instance.interceptors.response.use(
	response => {
		let code = response.data.code ? response.data.code:0
		// console.log(code)
		if(code == 403){
			location.href='/Login'
		}
		// console.log(response)
		switch (code) {
		  case 0: return response.data;
		  case 401: return Promise.reject(response.data);
		  case 406: return Promise.reject(response.data);
		  // case 200: return response.data;
			default : return Promise.reject(response.data);
		}
		
	},
	error => {
		//return Promise.reject(error) // 返回接口返回的错误信息
	})

export default instance;