<template>
    <div class="main" v-loading.fullscreen.lock="loading">
        <div class="step">
            <el-steps :active="active" finish-status="success" align-center>
                <el-step :title="`解析文件:${stepData1.length}`"/>
                <el-step :title="`确认订单:${stepData2.length}`"/>
                <el-step :title="`移除无效商品:${stepData3.length}`"/>
                <el-step :title="`产品项目对应:${stepData4.length}`"/>
            </el-steps>
        </div>
        <div class="container">
            <!-- step1 -->
            <div class="stepMain" v-if="active===0">
                <div style="height:40px;display:flex;align-items:center;">
                    <fileTemplate sign="5" @fileData="getStep1Data"/>
                </div>
                <div style="height:calc(100% - 80px)">
                    <el-table height="100%" :data="tableData1">
                        <el-table-column label="用户Id" prop="user_id"></el-table-column>
                        <el-table-column label="收件人" prop="收件人"></el-table-column>
                        <el-table-column label="心愿订单号" prop="order_number"></el-table-column>
                        <el-table-column label="pid" prop="pid"></el-table-column>
                        <el-table-column label="快递公司" prop="order_carrier"></el-table-column>
                        <el-table-column label="快递单号" prop="order_logistics"></el-table-column>
                        <el-table-column label="时间">
                            <template #default="scope">
                                {{ formatDate(parseDate(scope.row.ship_time), 'yyyy-MM-dd hh:mm:ss') }}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div style="height:40px;text-align:center">
                    <el-pagination background layout="prev, pager, next" :total="stepData1.length" :page-size="20"
                                   v-model:current-page="currentPage" @current-change="getTableData('stepData1')"/>
                </div>
            </div>
            <!-- step2 -->
            <div class="stepMain" v-if="active===1">
                <div></div>
                <div style="height:calc(100% - 40px);">
                    <el-table :data="tableData1" height="100%">
                        <el-table-column label="用户Id" prop="user_id"></el-table-column>
                        <el-table-column label="收件人" prop="收件人"></el-table-column>
                        <el-table-column label="心愿订单号" prop="order_number"></el-table-column>
                        <el-table-column label="pid" prop="pid"></el-table-column>
                        <el-table-column label="快递公司" prop="order_carrier"></el-table-column>
                        <el-table-column label="快递单号" prop="order_logistics"></el-table-column>
                        <el-table-column label="时间">
                            <template #default="scope">
                                {{ formatDate(parseDate(scope.row.ship_time), 'yyyy-MM-dd hh:mm:ss') }}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div style="height:40px;text-align:center">
                    <el-pagination background layout="prev, pager, next" :total="stepData2.length" :page-size="20"
                                   v-model:current-page="currentPage" @current-change="getTableData('stepData2')"/>
                </div>
            </div>
            <!-- step3 -->
            <div class="stepMain" v-if="active===2">
                <div style="height:40px">
                    <el-button type="primary" @click="openDialog">筛选PId</el-button>
                </div>
                <div style="height:calc(100% - 80px)">
                    <el-table :data="tableData1" height="100%">
                        <el-table-column label="用户Id" prop="user_id"></el-table-column>
                        <el-table-column label="收件人" prop="收件人"></el-table-column>
                        <el-table-column label="心愿订单号" prop="order_number"></el-table-column>
                        <el-table-column label="pid" prop="pid"></el-table-column>
                        <el-table-column label="快递公司" prop="order_carrier"></el-table-column>
                        <el-table-column label="快递单号" prop="order_logistics"></el-table-column>
                        <el-table-column label="时间">
                            <template #default="scope">
                                {{ formatDate(parseDate(scope.row.ship_time), 'yyyy-MM-dd hh:mm:ss') }}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div style="height:40px;text-align:center">
                    <el-pagination background layout="prev, pager, next" :total="stepData3.length" :page-size="20"
                                   v-model:current-page="currentPage" @current-change="getTableData('stepData3')"/>
                </div>
            </div>
            <!-- step4 -->
            <div class="stepMain" v-if="active===3">
                <div style="height:40px">
                    <el-button type="primary" @click="openDialog2">对应项目</el-button>
                </div>
                <div style="height:calc(100% - 80px);">
                    <el-table :data="tableData1" height="100%">
                        <el-table-column label="用户Id" prop="user_id"></el-table-column>
                        <el-table-column label="收件人" prop="收件人"></el-table-column>
                        <el-table-column label="心愿订单号" prop="order_number"></el-table-column>
                        <el-table-column label="pid" prop="pid"></el-table-column>
                        <el-table-column label="快递公司" prop="order_carrier"></el-table-column>
                        <el-table-column label="快递单号" prop="order_logistics"></el-table-column>
                        <el-table-column label="时间">
                            <template #default="scope">
                                {{ formatDate(parseDate(scope.row.ship_time), 'yyyy-MM-dd hh:mm:ss') }}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div style="height:40px;text-align:center">
                    <el-pagination background layout="prev, pager, next" :total="stepData4.length" :page-size="20"
                                   v-model:current-page="currentPage" @current-change="getTableData('stepData4')"/>
                </div>
            </div>
        </div>
        <div class="stepBtn">
            <el-button v-if="active" type="primary" @click="active--">上一步</el-button>
            <el-button v-if="active<3" type="primary" @click="next1">下一步</el-button>
            <el-button v-else type="primary" @click="updateUserWishShipBatch">确认</el-button>
        </div>

        <!-- 删选PID -->
        <el-dialog v-model="dialog" title="筛选Pid" width="1000px">
            <el-checkbox-group v-model="pids">
                <div style="display:grid;grid-template-columns: auto auto auto;">
                    <el-checkbox v-for="item in allPids" :key="item" :label="item">
                        {{ productObj[item]?.title || item }}
                        <!-- <el-button type="success" plain size="small" v-if="!productObj[item]">{{tihuan[item]?.title || '替换'}}</el-button> -->
                        <el-select v-model="tihuan[item]" v-if="!productObj[item]" size="small"
                                   @change="changeSelect(item)">
                            <el-option label="替换" value="-1"></el-option>
                            <el-option v-for="p in product" :key="p.pid" :label="p.title" :value="p.pid"></el-option>
                        </el-select>
                    </el-checkbox>
                </div>
            </el-checkbox-group>
            <template #footer>
                <el-button @click="dialog=false">取消</el-button>
                <el-button type="primary" @click="getTableData3">确定</el-button>
            </template>
        </el-dialog>
        <!-- 对应项目 -->
        <el-dialog v-model="dialog2" title="产品项目对应">
            <el-table :data="tableData2" :row-class-name="tableRowClassName">
                <el-table-column label="PID" prop="pid"></el-table-column>
                <el-table-column label="产品id" prop="productId"></el-table-column>
                <el-table-column label="产品名" prop="title"></el-table-column>
                <el-table-column label="项目">
                    <template #default="scoped">
                        <el-select v-model="scoped.row.xid" @change="changeX(scoped)">
                            <el-option v-for="item in scoped.row.projectRepDTOS" :key="item.id" :label="item.name"
                                       :value="item.id"></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="题目数量" prop="num"></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>
<script>
import {
    queryProductInfoAndProjectByPids,
    queryUserWishShipByOrderNum,
    queryUserWishShipByOrderNumAndShip,
    updateUserWishShipBatch
} from '@/config/jsonapi'
import {ElMessage} from 'element-plus';
import {formatDate, parseDate} from '@/utils/date'
import fileTemplate from "@/components/fileTemplate.vue";

export default {
    data() {
        return {
            active: 0,
            stepData1: [],
            stepData2: [],
            stepData3: [],
            stepData4: [],
            tableData1: [],
            currentPage: 1,
            loading: false,

            dialog: false,
            pids: [],
            allPids: [],

            productObj: {},
            tihuan: {},
            product: [],

            dialog2: false,
            tableData2: [],
        }
    },
    components: {fileTemplate},
    methods: {
        formatDate,
        parseDate,
        getStep1Data(data) {
            this.currentPage = 1;
            // 对原始数据去重
            let map = new Map();
            data = data.filter(v => !map.has(`${v.order_number}#${v.pid}`) && map.set(`${v.order_number}#${v.pid}`, 1));

            this.stepData1 = data;
            this.getTableData('stepData1');
        },
        // 分页获取数据
        getTableData(str) {
            this.tableData1 = [];
            for (let i = this.currentPage * 20 - 20; i < this.currentPage * 20; i++) {
                if (this[str]?.[i]) {
                    this.tableData1.push(this[str][i]);
                }
            }
        },

        // 去除无效订单（非XY开头)
        removeOrderNoXY() {
            this.loading = true;
            let stepData1 = [...this.stepData1];
            let stepData2 = [];
            let list = [];
            let noXYdata = [];
            let stopOrder = [];

            let orderNum = [];
            for (let i in stepData1) {
                // 去除 快递单为空、已删除、停发;
                if (!stepData1[i].order_logistics || stepData1[i].order_logistics === '已删除' || stepData1[i].order_logistics === '停发') {
                    stopOrder.push(stepData1[i]);
                } else if (stepData1[i]?.order_number?.indexOf('xy') === -1) {
                    noXYdata.push(stepData1[i])
                } else {
                    list.push(stepData1[i])
                    orderNum.push(stepData1[i].order_number);
                }
            }

            let param = {
                orderNum: [...new Set(orderNum)]
            }
            queryUserWishShipByOrderNumAndShip(param).then(res => {
                let orderNumber = [];
                let orderNum = [];
                for (let i in res.result) {
                    orderNumber.push(res.result[i].orderNumber)
                }
                for (let i = 0; i < list.length; i++) {
                    if (orderNumber.indexOf(list[i].order_number) == -1) {
                        stepData2.push(list[i]);
                        orderNum.push(list[i].order_number)
                    }
                }
                orderNum = [...new Set(orderNum)];
                if (orderNum?.length) {
                    queryUserWishShipByOrderNum({orderNum}).then(r => {
                        orderNumber = [];
                        for (let i in r.result) {
                            orderNumber.push(r.result[i].orderNumber)
                        }
                        for (let i = 0; i < stepData2.length; i++) {
                            console.log(i);
                            if (orderNumber.indexOf(stepData2[i].order_number) == -1) {
                                stepData2.splice(i, 1);
                                --i
                            }
                        }
                        console.log(stepData2);
                        this.stepData2 = stepData2;
                        this.currentPage = 1;
                        this.getTableData('stepData2');
                        this.loading = false;
                    }, e => {
                        this.loading = false;
                        ElMessage.error('数据去重失败');
                        this.active = 0;
                    })
                } else {
                    this.loading = false;
                    ElMessage.error('没有需要更新的订单');
                }
            }, err => {
                this.loading = false;
                ElMessage.error('数据去重失败');
                this.active = 0;
            })
        },

        // 删除无效商品
        getAllPid() {
            let stepData2 = JSON.parse(JSON.stringify(this.stepData2));
            let arr = [];
            let arr1 = [];
            for (let i in stepData2) {
                arr.push(stepData2[i].pid);
                if (!(stepData2[i].pid.indexOf('DZ') != -1 || stepData2[i].pid.indexOf('HZ') != -1 || stepData2[i].pid.indexOf('XC') != -1)) {
                    arr1.push(stepData2[i].pid);
                }
            }
            this.allPids = [...new Set(arr)];
            this.pids = [...new Set(arr)];
            this.queryProductInfoAndProjectByPids();
            this.currentPage = 1;
            this.getTableData3();
        },
        // 根据pid获取产品信息
        queryProductInfoAndProjectByPids() {
            let productObj = {};
            let pids = [];
            queryProductInfoAndProjectByPids({pids: this.allPids}).then(res => {
                for (let i in res.result) {
                    productObj[res.result[i].pid] = res.result[i];
                    pids.push(res.result[i].pid)
                }
                this.product = res.result;
                this.productObj = productObj;
                this.pids = pids;
                this.currentPage = 1;
                this.getTableData3();

                let tihuan = {};
                for (let i in this.allPids) {
                    if (pids.indexOf(this.allPids[i]) == -1) {
                        tihuan[this.allPids[i]] = '-1'
                    }
                }
                this.tihuan = tihuan;
            })
        },
        // 替换
        changeSelect(item) {
            console.log(item);
            if (this.tihuan[item] != '-1') {
                if (this.pids.indexOf(item) == -1) {
                    this.pids.push(item)
                }
            } else {
                if (this.pids.indexOf(item) != -1) {
                    this.pids.splice(this.pids.indexOf(item), 1)
                }
            }
        },
        // 打开筛选pid弹窗
        openDialog() {
            this.dialog = true;
        },
        // 确定stepData3
        getTableData3() {
            this.loading = true;
            let stepData2 = JSON.parse(JSON.stringify(this.stepData2));
            let list = [];
            for (let i in stepData2) {
                if (this.pids.indexOf(stepData2[i].pid) != -1) {
                    list.push(stepData2[i])
                }
            }
            this.stepData3 = list;
            this.currentPage = 1;
            this.getTableData('stepData3')
            this.loading = false;
            this.dialog = false;
        },

        //产品与项目对应
        openDialog2() {
            this.dialog2 = true;
            let tableData2 = [];
            let stepData4 = JSON.parse(JSON.stringify(this.stepData4));

            let arr = [];
            for (let i in stepData4) {
                arr.push(stepData4[i].pid)
            }
            arr = [...new Set(arr)];

            console.log(arr);
            console.log(this.productObj);

            for (let i in arr) {
                if (this.productObj[arr[i]]) {
                    tableData2.push({
                        pid: arr[i],
                        productId: this.productObj[arr[i]].id,
                        title: this.productObj[arr[i]].title,
                        projectRepDTOS: this.productObj[arr[i]].projectRepDTOS,
                        xid: this.productObj[arr[i]].projectRepDTOS[0]?.id,
                        num: this.productObj[arr[i]].projectRepDTOS[0]?.questionCount,
                    })
                } else {
                    return ElMessage.error(`缺失pid为“${arr[i]}”的产品信息`)
                }
            }
            this.tableData2 = tableData2;
        },
        // 更改项目
        changeX(scoped) {
            let item = scoped.row;
            let projectRepDTOS = this.productObj[item.pid].projectRepDTOS;
            for (let i in projectRepDTOS) {
                if (projectRepDTOS[i].id === item.xid) {
                    item.num = projectRepDTOS[i].questionCount
                }
            }
            this.tableData2.splice(scoped.$index, 1, item)
        },

        // 确认最终数据
        updateUserWishShipBatch() {
            let productProjectMapperVOS = [];
            let userWishShipRequestVOS = [];
            let stepData4 = this.stepData4;
            let tableData2 = this.tableData2;
            if (!tableData2?.length) return ElMessage.error('请确认产品与项目对应关系');
            this.loading = true;
            for (let i in tableData2) {
                if (tableData2[i].productId && tableData2[i].xid) {
                    productProjectMapperVOS.push({
                        productId: tableData2[i].productId,
                        projectId: tableData2[i].xid,
                    })
                } else {
                    return ElMessage.error('数据缺失产品id或项目id')
                }
            }
            for (let i in stepData4) {
                userWishShipRequestVOS.push({
                    orderCarrier: stepData4[i].order_carrier,
                    orderLogistics: stepData4[i].order_logistics,
                    orderNumber: stepData4[i].order_number,
                    pid: stepData4[i].pid,
                    productId: this.productObj[stepData4[i].pid].id,
                    shipTime: formatDate(parseDate(stepData4[i].ship_time), 'yyyy-MM-dd hh:mm:ss'),
                    userId: stepData4[i].user_id
                })
            }
            updateUserWishShipBatch({productProjectMapperVOS, userWishShipRequestVOS}).then(res => {
                ElMessage.success('更新成功')
                this.stepData1 = [];
                this.tableData1 = [];
                this.active = 0;
                this.loading = false;
            }, err => {
                ElMessage.error(err.msg);
                this.loading = false;
            })
        },

        // 解析文件下一步
        next1() {
            let active = this.active;
            if (active === 0) {
                if (!this.stepData1?.length) return ElMessage.error('没有订单数据');
                this.removeOrderNoXY();
                this.active = 1;
            }
            if (active === 1) {
                if (!this.stepData2?.length) return ElMessage.error('没有订单数据');
                this.getAllPid();
                this.active = 2;
            }
            if (active === 2) {
                let stepData3 = JSON.parse(JSON.stringify(this.stepData3));
                for (let i in stepData3) {
                    if (this.tihuan[stepData3[i].pid] != -1 && this.tihuan[stepData3[i].pid]) {
                        stepData3[i].pid = this.tihuan[stepData3[i].pid];
                    }
                }
                this.stepData4 = stepData3;
                this.currentPage = 1;
                this.getTableData('stepData4')
                this.active = 3;
            }
        },

        // 表格样式
        tableRowClassName(row) {
            if (row.row.num == 0) {
                return 'rowRed'
            } else {
                return ''
            }
        },
    }
}
</script>
<style>
.el-table .rowRed {
    --el-table-tr-bg-color: rgba(255, 0, 0, 0.66);
}
</style>
<style lang="scss" scoped>
.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  height: calc(100% - 97px - 65px);

  .stepMain {
    height: 100%;
  }
}

.step {
  padding: 20px 0;
  width: 700px;
}

.stepBtn {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
