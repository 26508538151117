<template>
  <div class="surveyList" v-loading="loading">
    <section class="searchForm" style="display: flex">
      <el-input
          v-model="name"
          style="width: 200px"
          @change="searchName"
      ></el-input>
      <el-button type="primary" @click="searchName">搜索</el-button>
      <el-button type="primary" @click="clearSearch">取消</el-button>
      <el-button type="primary" @click="openAdd">新建调研</el-button>
    </section>
    <section class="tableList">
      <el-table :data="tableList">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="image" label="图片">
          <template #default="scope">
            <div class="tableImg">
              <img :src="scope.row.image" alt=""/>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="content"
            label="描述"
            width="280"
        >
          <template #default="scope">
            <div v-html="scope.row.content"></div>
          </template>
        </el-table-column>
        <el-table-column prop="stock" label="上限">
          <template #default="scope">
            {{ scope.row.stock }}
            <el-tag class="ml-2" @click="gotoSotck(scope.row)">修改</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="stockUsed" label="累计次数"></el-table-column>
        <el-table-column prop="questionCount" label="题目" width="80">
          <template #default="scope">
            {{ scope.row.questionCount }}道题目
            <el-tag class="ml-2" @click="gotoQuestion(scope.row)">查看</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="分类">
          <template #default="scope">
            {{
              (scope.row.type == 0 && "常规") ||
              (scope.row.type == 1 && "耐消品") ||
              (scope.row.type == 2 && "打卡") ||
              (scope.row.type == 3 && "需求")

            }}
          </template>
        </el-table-column>
        <el-table-column label="显示规则" width="130">
          <template #default="scope">
            <p>{{ scope.row.targetAudienceUseCount }}条显示规则</p>
            <el-tag class="ml-2" @click="getShowRule(scope.row)">查看</el-tag>
            <el-tag class="ml-2" @click="openShow(scope.row)">添加</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="发奖规则" width="130">
          <template #default="scope">
            <p>{{ scope.row.rewardCount }}条发奖规则</p>
            <el-tag class="ml-2" @click="gotoPrize(scope.row)">查看</el-tag>
            <el-tag class="ml-2" @click="gotoAddPrize(scope.row)">添加</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="绑定产品" width="130">
          <template #default="scope">
            <p>{{ scope.row.productId.split(",").length - 1 }}个产品</p>
            <el-tag @click="lookGoods(scope.row)">查看</el-tag>
            <el-tag class="ml-2" @click="openGoods(scope.row)">添加</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="绑定品牌">
          <template #default="scope">
            <p>{{ scope.row.brandId ? "已绑定" : "未绑定" }}</p>
            <el-tag class="ml-2" @click="openPin(scope.row)">修改</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template #default="scope">
            {{ scope.row.status == 0 ? "下架" : "上架" }}
          </template>
        </el-table-column>
        <el-table-column label="审核状态" prop="auditProgress">
          <template #default="scope">
            {{ (scope.row.auditProgress === 0 && '未审核') || (scope.row.auditProgress === 1 && '未审核') || (scope.row.auditProgress === 2 && '审核通过') || (scope.row.auditProgress === 3 && '审核不通过') || (scope.row.auditProgress === 4 && '发布') }}
          </template>
        </el-table-column>
        <el-table-column label="报告进度">
          <template #default="scope">
            {{ (scope.row.isReport === 0 && '未完成') || (scope.row.isReport === 1 && '完成') }}
          </template>
        </el-table-column>
        <el-table-column label="置顶">
          <template #default="scope">
            {{ scope.row.isRecommend == 0 ? "" : "置顶" }}
            <el-tag class="ml-2" @click="stickie(scope.row)">修改</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="startTime"
            label="上线时间"
            width="100"
        ></el-table-column>
        <el-table-column
            prop="endTime"
            label="结束时间"
            width="100"
        ></el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            width="100"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="300px">
          <template #default="scope">
            <el-button
                v-if="(scope.row.auditProgress === 1||scope.row.auditProgress === 2) && scope.row.survey?.creatorType === 2"
                type="primary" size="small" @click="openExamine(scope.row)">审核
            </el-button>
            <el-button
                size="small"
                type="primary"
                @click="changeLine(scope.row)"
            >{{ scope.row.status == 0 ? "上架" : "下架" }}
            </el-button
            >
            <el-button
                size="small"
                type="success"
                @click="openModify(scope.row)"
            >编辑
            </el-button
            >
            <el-button size="small" type="danger" @click="remove(scope.row)"
            >删除
            </el-button
            >
            <el-popconfirm title="报告是否完成" confirm-button-text="完成" cancel-button-text="未完成"
                           @confirm="updateReport(scope.row,1)" @cancel="updateReport(scope.row,0)">
              <template #reference>
                <el-button type="success" size="small">报告进度</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
          v-model:currentPage="pageCurrent"
          v-model:page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChangePage"
      />
    </section>

    <!-- 选择人群 -->
    <el-dialog
        v-model="crowdDialog"
        :title="isShow ? '添加显示规则' : '人群列表'"
        draggable
        width="600px"
    >
      <section class="search_btn">
        <div class="search_items">
          <span class="title">人群名称：</span>
          <div>
            <el-input
                v-model="this.targetName"
                class="w-50 m-2"
                size="large"
                placeholder="请输入人群名称"
            ></el-input>
          </div>
        </div>
        <div class="search_items">
          <el-button
              type="primary"
              :icon="Search"
              @click="this.GetTargetAudienceList"
          >查询
          </el-button
          >
        </div>
      </section>
      <el-table
          :data="tableData"
          highlight-current-row
          @current-change="handleCurrentChange"
      >
        <el-table-column prop="id" label="id"/>
        <el-table-column prop="name" label="人群名称"/>
        <el-table-column prop="content" label="人群描述"/>
      </el-table>
      <el-pagination
          style="margin-top: 10px"
          v-model:currentPage="pageNo"
          :page-size="10"
          background
          layout="prev, pager, next"
          :total="total1"
          @current-change="GetTargetAudienceList"
      />
      <template #footer>
        <el-button @click="crowdDialog = false">取消</el-button>
        <el-button v-if="!isShow" type="primary" @click="sureCrowd"
        >确定
        </el-button
        >
        <el-button v-if="isShow" type="primary" @click="sureShow"
        >确定
        </el-button
        >
      </template>
    </el-dialog>
    <!-- 绑定产品 -->
    <el-dialog v-model="goodsDialog" title="绑定产品" width="400px">
      <el-form>
        <el-form-item label="绑定产品">
          <el-select v-model="goodsId" filterable>
            <el-option
                v-for="item in goodsList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="goodsDialog = false">取消</el-button>
        <el-button type="primary" @click="sureGoodsId">确定</el-button>
      </template>
    </el-dialog>
    <!-- 绑定品牌 -->
    <el-dialog v-model="pinDialog" title="绑定品牌" width="400px">
      <el-form>
        <el-form-item label="绑定品牌">
          <el-select v-model="pinId" filterable>
            <el-option
                v-for="item in pinList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="pinDialog = false">取消</el-button>
        <el-button type="primary" @click="surePin">确定</el-button>
      </template>
    </el-dialog>
    <!-- 查看绑定产品 -->
    <el-dialog v-model="loodGoodsDialog" title="查看绑定产品" width="500px">
      <el-table :data="bangGoods">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="title" label="产品名称"></el-table-column>
      </el-table>
      <template #footer>
        <el-button @click="removeGoods()">删除</el-button>
        <el-button @click="loodGoodsDialog = false">确定</el-button>
      </template>
    </el-dialog>
    <!-- 查看显示规则 -->
    <el-dialog v-model="loodShowDialog" title="查看显示规则" width="500px">
      <el-table :data="showRules">
        <el-table-column prop="taId" label="ID" width="120"></el-table-column>
        <el-table-column
            prop="targetAudience.name"
            label="显示人群"
        ></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button
                type="danger"
                size="small"
                @click="removeShowRule(scope.row)"
            >删除
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <el-button @click="loodShowDialog = false">确定</el-button>
      </template>
    </el-dialog>
    <!-- 审核调研 -->
    <el-dialog v-model="examineDialog" title="审核调研" width="500px">
      <el-form>
        <el-form-item label="调研:">{{ editExamine.name }}</el-form-item>
        <el-form-item label="审核:">
          <el-radio-group v-model="auditProgress">
            <el-radio label="1">待审核</el-radio>
            <el-radio label="2">通过</el-radio>
            <el-radio label="3">不通过</el-radio>
            <el-radio label="4">发布</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="examineDialog = false">取消</el-button>
        <el-button type="primary" @click="examine">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {
  DeleteTargetAudienceUse,
  GetTargetAudienceUseBySpecialEventId,
  InsertTargetAudienceUseBySpecialEventId,
  BindingProductOrBrand,
  GetProductList,
  GetSpecialList,
  UpdateSpecialEventShelfOrDelete,
  InsertOrUpdateSpecialEvent,
  GetTargetAudienceList,
} from "../../config/Api";
import {ElMessage, ElMessageBox} from "element-plus";
import {Get_url} from "../../config/Get_url";
import global_api from "@/config/Api";
import {Search} from "@element-plus/icons-vue";

export default {
  data() {
    return {
      Search: Search,
      pageCurrent: 1,
      pageSize: 7,
      total: 1,
      loading: false,
      tableList: [],
      headers: {
        id: localStorage.getItem("id"),
        token: localStorage.getItem("token"),
      },
      Get_url: Get_url(),
      crowdDialog: false,
      tableData: [],
      pageNo: 1,
      total1: 1,
      temCrowd: "",
      crowds: [],
      goodsDialog: false,
      goodsList: [],
      goodsId: "",
      pinDialog: false,
      pinList: [],
      pinId: "",
      isShow: false,
      loodGoodsDialog: false,
      bangGoods: [],
      loodShowDialog: false,
      showRules: [],
      lookRuleDialog: false,
      targetName: "",
      name: "",
      examineDialog: false,
      editExamine: {},
      auditProgress: '',

      // 表格
    };
  },
  mounted() {
    let quety = this.$route.query;
    this.pageCurrent = Number(quety.page || 1);
    this.pageSize = Number(quety.size || 7);
    this.getList();
  },
  methods: {
    // 上一页下一页
    handleCurrentChangePage() {
      this.$router.push({
        path: "/survey",
        query: {page: this.pageCurrent, size: this.pageSize},
      });
      this.getList();
    },
    // 获取列表
    getList() {
      let param = {
        id: 1,
        pageNo: this.pageCurrent,
        pageSize: this.pageSize,
        name: this.name,
      };
      this.loading = true;
      GetSpecialList(param).then(
          (res) => {
            this.loading = false;
            this.tableList = res.result;
            this.total = res.data.totalCount;
          },
          (err) => {
            this.loading = false;
            ElMessage.error(`获取调研列表失败：${err.msg}`);
          }
      );
    },
    // 搜索
    searchName() {
      this.pageCurrent = 1;
      this.getList();
    },
    // 清除
    clearSearch() {
      this.pageCurrent = 1;
      this.name = "";
      this.getList();
    },
    // 上下架
    changeLine(row) {
      if (row.status == 0) {
        let nowTimeStep = new Date().getTime();
        let minTimeStep = new Date(row.startTime).getTime();
        let maxTimeStep = new Date(row.endTime).getTime();
        console.log(nowTimeStep);
        console.log(minTimeStep);
        console.log(maxTimeStep);
        if (minTimeStep > nowTimeStep || nowTimeStep > maxTimeStep) {
          ElMessage.error("现在不在活动时间内,无法上架");
          return;
        }
      }
      let param = {
        specialEventId: row.id,
        type: row.status == 0 ? 2 : 0,
        auditProgress: row.auditProgress,
        isReport: row.isReport || 0,
      };
      this.loading = true;
      UpdateSpecialEventShelfOrDelete(param).then(
          (res) => {
            this.loading = false;
            this.getList();
          },
          (err) => {
            this.loading = false;
            ElMessage.error(`上下架失败：${err.msg}`);
          }
      );
    },
    // 删除
    remove(row) {
      ElMessageBox.confirm("是否删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          specialEventId: row.id,
          type: 3,
          auditProgress: row.auditProgress,
          isReport: row.isReport,
        };
        this.loading = true;
        UpdateSpecialEventShelfOrDelete(param).then(
            (res) => {
              this.loading = false;
              ElMessage({
                type: "success",
                message: "已删除",
              });
              this.getList();
            },
            (err) => {
              this.loading = false;
              ElMessage.error(`删除失败:${err.msg}`);
            }
        );
      });
    },
    // 打开审核
    openExamine(row) {
      this.examineDialog = true;
      this.editExamine = row;
      this.auditProgress = '';
    },
    // 审核
    examine() {
      if (!this.auditProgress) return ElMessage.error('请选择审核结果');
      let param = {
        specialEventId: this.editExamine.id,
        type: this.editExamine.type,
        auditProgress: this.auditProgress,
        isReport: this.editExamine.isReport,
      };
      this.loading = true;
      UpdateSpecialEventShelfOrDelete(param).then(res => {
        this.loading = false;
        this.examineDialog = false;
        this.getList();
      }, err => {
        this.loading = false;
        ElMessage.error(err.msg)
      })
    },
    // 修改报告进度
    updateReport(row, report) {
      let param = {
        specialEventId: row.id,
        type: row.status == 0 ? 0 : 2,
        auditProgress: row.auditProgress,
        isReport: report,
      };
      this.loading = true;
      UpdateSpecialEventShelfOrDelete(param).then(
          (res) => {
            this.loading = false;
            this.getList();
          },
          (err) => {
            this.loading = false;
          }
      );
    },

    // 打开添加弹窗
    openAdd() {
      this.$router.push({
        path: "/survey/add",
        query: {type: "add"},
      });
    },
    // 打开编辑
    openModify(row) {
      sessionStorage.setItem("editPrizeData", JSON.stringify(row));
      this.$router.push({
        path: "/survey/add",
        query: {type: "edit"},
      });
    },
    // 查看题目列表
    gotoQuestion(row) {
      this.$router.push({
        path: "/survey/question",
        query: {
          id: row.id,
          b: `${this.pageCurrent}_${this.pageSize}`,
          name: row.name,
        },
      });
    },
    // 查看发奖规则
    gotoPrize(row) {
      this.$router.push({
        path: "/survey/prize",
        query: {id: row.id, name: row.name},
      });
    },
    // 打开发奖
    gotoAddPrize(row) {
      this.$router.push({
        path: "/survey/addprize",
        query: {id: row.id, name: row.name},
      });
    },
    // 打开人群选择弹窗
    openCrowd() {
      this.crowdDialog = true;
      this.GetTargetAudienceList();
    },
    // 获取人群列表
    GetTargetAudienceList() {
      this.loading = true;
      let param = {
        pageNo: this.pageNo,
        pageSize: 10,
        targetName: this.targetName,
      };
      GetTargetAudienceList({params: param}).then(
          (res) => {
            this.loading = false;
            this.tableData = res.result;
            this.total1 = res.data.totalCount;
          },
          (err) => {
            this.loading = false;
            ElMessage.error(`获取人群列表失败：${err.msg}`);
          }
      );
    },
    // 选择人群
    handleCurrentChange(row) {
      this.temCrowd = row;
    },
    // 确定选择的人群
    sureCrowd() {
      if (!this.temCrowd?.id) {
        ElMessage.error("请选择");
        return;
      }
      let crowds = this.crowds;
      let f = false;
      for (let i in crowds) {
        if (crowds[i].id == this.temCrowd.id) {
          f = true;
          break;
        }
      }
      if (!f) {
        this.crowds.push({...this.temCrowd});
      }
      this.crowdDialog = false;
    },
    // 打开绑定产品
    openGoods(row) {
      this.loading = true;
      GetProductList().then(
          (res) => {
            this.loading = false;
            this.goodsList = res.result;
            this.goodsDialog = true;
            this.specialEventId = row.id;
          },
          (err) => {
            this.loading = false;
            ElMessage.error(`获取产品失败：${err.msg}`);
          }
      );
    },
    // 绑定产品
    sureGoodsId() {
      this.loading = true;
      let param = {
        specialEventId: this.specialEventId,
        operationId: this.goodsId,
        type: 1,
      };
      BindingProductOrBrand(param).then(
          (res) => {
            this.loading = false;
            this.goodsDialog = false;
            this.getList();
          },
          (err) => {
            this.loading = false;
            ElMessage.error(`绑定产品失败：${err.msg}`);
          }
      );
    },
    // 打开绑定品牌
    openPin(row) {
      this.loading = true;
      global_api.GetBrandList().then(
          (res) => {
            this.loading = false;
            this.pinList = res.result;
            this.pinDialog = true;
            this.specialEventId = row.id - 0;
            this.pinId = row.id - 0;
          },
          (err) => {
            this.loading = false;
            ElMessage.error(`获取产品失败：${err.msg}`);
          }
      );
    },
    // 绑定品牌
    surePin() {
      this.loading = true;
      let param = {
        specialEventId: this.specialEventId,
        operationId: this.pinId,
        type: 2,
      };
      BindingProductOrBrand(param).then(
          (res) => {
            this.loading = false;
            this.pinDialog = false;
            this.getList();
          },
          (err) => {
            this.loading = false;
            ElMessage.error(`绑定产品失败：${err.msg}`);
          }
      );
    },
    // 打开添加显示规则弹窗
    openShow(row) {
      this.surveyId = row.id;
      this.isShow = true;
      this.openCrowd();
    },
    // 确定显示条件
    sureShow() {
      let param = {
        specialEventId: this.surveyId,
        type: 1,
        targetAudienceId: this.temCrowd.id,
      };
      this.loading = true;
      InsertTargetAudienceUseBySpecialEventId(param).then(
          (res) => {
            this.loading = false;
            this.crowdDialog = false;
            this.getList();
          },
          (err) => {
            this.loading = false;
            ElMessage.error(`添加显示规则失败：${err.msg}`);
          }
      );
    },
    // 查看绑定产品
    lookGoods(row) {
      this.loading = true;
      GetProductList().then(
          (res) => {
            this.loading = false;
            let result = res.result;
            let ids = row.productId.split(",");
            let bangGoods = [];
            for (let i in result) {
              for (let n in ids) {
                if (ids[n] != -1 && ids[n] == result[i].id) {
                  bangGoods.push(result[i]);
                }
              }
            }
            this.bangGoods = bangGoods;
            this.loodGoodsDialog = true;
            this.specialEventId = row.id;
          },
          (err) => {
            this.loading = false;
            ElMessage.error(`获取产品失败：${err.msg}`);
          }
      );
    },
    // 删除绑定产品
    removeGoods(row) {
      this.loading = true;
      let param = {
        specialEventId: this.specialEventId,
        operationId: 0,
        type: 3,
      };
      BindingProductOrBrand(param).then(
          (res) => {
            this.loading = false;
            this.loodGoodsDialog = false;
            this.getList();
          },
          (err) => {
            this.loading = false;
            ElMessage.error(`删除绑定产品失败：${err.msg}`);
          }
      );
    },
    // 查看显示规则
    getShowRule(row) {
      let param = {
        specialEventId: row.id,
      };
      this.loading = true;
      GetTargetAudienceUseBySpecialEventId({params: param}).then(
          (res) => {
            this.loading = false;
            this.loodShowDialog = true;
            this.showRules = res.result;
            console.log(this.showRules);
            this.specialEventId = row.id;
          },
          (err) => {
            this.loading = false;
            ElMessage.error(`获取显示规则失败:${err.msg}`);
          }
      );
    },
    // 删除显示规则
    removeShowRule(row) {
      let param = {
        specialEventId: this.specialEventId,
        targetAudienceId: row.taId,
      };
      this.loading = true;
      DeleteTargetAudienceUse(param).then(
          (res) => {
            this.loading = false;
            this.getList();
            this.loodShowDialog = false;
          },
          (err) => {
            this.loading = false;
          }
      );
    },
    // 修改调研问卷库存
    gotoSotck(row) {
      this.$router.push({
        path: "/survey/stock",
        query: {id: row.id},
      });
    },
    // 调研置顶
    stickie(row) {
      console.log(row);
      let param = {
        id: row.id,
        currency: 1,
        sort: row.sort,
        price: row.price,
        name: row.name,
        content: row.content,
        startTime: row.startTime.replace(/\-/g, "/"),
        endTime: row.endTime.replace(/\-/g, "/"),
        role: row.role,
        type: row.type,
        image: row.image,
        status: row.status,
        isPassword: row.isPassword,
        password: row.password,
        isRecommend: row.isRecommend ? 0 : 1,
        timesPerTask: row.timesPerTask,
        taskPeriod: row.taskPeriod,
      };
      this.loading = true;
      InsertOrUpdateSpecialEvent(param).then(
          (res) => {
            this.loading = false;
            this.getList();
          },
          (err) => {
            this.loading = false;
          }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.rowBoxList {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;

  .rowBoxRow {
    width: 100%;
    margin-bottom: 10px;
    display: flex;

    span {
      width: 70px;
      margin-left: 30px;
    }

    .rowInput {
      width: 200px;
    }

    .select {
      width: 500px;
    }
  }

  .scoreList {
    width: 500px;
    margin: 10px 0 10px 100px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 5px;

    .scoreRow {
      display: flex;
      margin-bottom: 10px;
    }
  }
}

.tableImg {
  img {
    width: 60px;
  }
}

.lookPrizeTitle {
  font-weight: bold;
  color: rgb(241, 183, 183);
}
</style>
<style>
.surveyList .el-date-editor {
  width: 200px !important;
}

.surveyList .el-checkbox {
  white-space: initial !important;
  height: auto !important;
  align-items: baseline !important;
}

.surveyList .el-checkbox__label {
  line-height: 25px;
}

.select .el-input__inner {
  width: 100% !important;
}
</style>
