<template>
  <div class="poster" v-loading.fullscreen.lock="loading">
    <div class="aside">
      <ul>
        <li :class="{'check': index === 1}" @click="changeIndex(1)">海报背景</li>
        <li :class="{'check': index === 2}" @click="changeIndex(2)">内容区设置</li>
        <li :class="{'check': index === 3}" @click="changeIndex(3)">评论设置</li>
        <div class="comments" v-if="index === 3">
          <div>样式一<span @click="selectPosterType(0)">添加</span></div>
          <div>单行文本<span @click="selectPosterType(1)">添加</span></div>
          
        </div>
        <li :class="{'check': index === 5}" @click="changeIndex(5)">下载海报</li>
      </ul>
    </div>
    <div class="main">
      <div class="page" ref="page" :style="{width:posterBGData[BGindex].width,height:posterBGData[BGindex].height}">
        <!-- 海报背景 -->
        <div class="pageBG" :style="posterBGData[BGindex]"></div>
        <div class="pageContent" :class="{checked:index===2||index===3}" :style="pageContentStyle[BGindex]">
          <section v-for="(item,index) in posterData" :key="index">
            <!-- 纯评论 -->
            <div class="item type1" :class="{checked:index===itemData.sort}" :style="item.paddingStyle" v-if="item.type===0" @click="openItemSet(item)">
              <div class="title">
                <img :src="item.comment?.url||'/images/autor.jpeg'" alt="">
                {{item.comment?.title||'请选择评论'}}
              </div>
              <div class="comment" v-html="item.comment?.content||'请选择评论'"></div>
            </div>
            <!-- 单行文字 -->
            <div class="item type2" :class="{checked:index===itemData.sort}" :style="item.paddingStyle" v-if="item.type===1" @click="openItemSet(item)">
              {{item.value}}
            </div>
          </section>
        </div>
        <div class="BG1Foot" v-if="BGindex === 0" @click="changeIndex(4)">
          <div>
            <img v-if="ma1" :src="ma1" alt="">
            {{ma1text}}
          </div>
          <div>
            <img v-if="ma2" :src="ma2" alt="">
            {{ma2text}}
          </div>
        </div>
      </div>
    </div>
    <div class="setting">
      <div v-if="index===1" class="BGset">
        <section v-for="(item,index) in BG" :key="index">
          <div class="item" :class="{checked: BGindex === index}" @click="BGindex=index">
            <img :src="item" alt="">
          </div>
        </section>
      </div>
      <div v-if="index===2" class="comment">
        <el-form label-suffix=":" label-width="100px">
          <el-form-item label="顶部距离">
            <el-input-number v-model="pageContentStyleObj.top" @change="contentStyleChange"></el-input-number>
          </el-form-item>
          <el-form-item label="左部距离">
            <el-input-number v-model="pageContentStyleObj.left" @change="contentStyleChange"></el-input-number>
          </el-form-item>
          <el-form-item label="底部距离">
            <el-input-number v-model="pageContentStyleObj.bottom" @change="contentStyleChange"></el-input-number>
          </el-form-item>
          <el-form-item label="右部距离">
            <el-input-number v-model="pageContentStyleObj.right" @change="contentStyleChange"></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="index===3" class="itemSet">
        <el-form v-if="itemData.type||itemData.type===0" label-suffix=":" label-width="100px">
          <el-form-item label="顺序">
            <el-input-number v-model="itemDataSort" :min="0" :max="posterData.length - 1" @change="itemDataChange2"></el-input-number>
          </el-form-item>
          <el-form-item label="顶部距离">
            <el-input-number v-model="itemData.paddingStyle.marginTop" @change="itemDataChange3"></el-input-number>
          </el-form-item>
          <el-form-item label="左部距离">
            <el-input-number v-model="itemData.paddingStyle.marginLeft" @change="itemDataChange3"></el-input-number>
          </el-form-item>
          <el-form-item label="底部距离">
            <el-input-number v-model="itemData.paddingStyle.marginBottom" @change="itemDataChange3"></el-input-number>
          </el-form-item>
          <el-form-item label="右部距离">
            <el-input-number v-model="itemData.paddingStyle.marginRight" @change="itemDataChange3"></el-input-number>
          </el-form-item>
          <el-form-item label="评论" v-if="itemData.type===0">
            <el-button @click="openDialog">选择</el-button>
          </el-form-item>
          <el-form-item label="编辑内容" v-if="itemData.type===0">
            <el-button @click="openDialog1">选择</el-button>
          </el-form-item>
          <el-form-item label="文本" v-if="itemData.type===1">
            <el-input v-model="itemData.value" @change="itemDataChange4"></el-input>
          </el-form-item>
          <el-form-item label="文本位置" v-if="itemData.type===1">
            <el-select v-model="itemData.paddingStyle.textAlign" @change="itemDataChange4">
              <el-option label="左对齐" value="left"></el-option>
              <el-option label="居中" value="center"></el-option>
              <el-option label="右对齐" value="right"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="字号" v-if="itemData.type===1">
            <el-input-number v-model="itemData.paddingStyle.fontSize" @change="itemDataChange3"></el-input-number>
          </el-form-item>
          <el-form-item label="颜色" v-if="itemData.type===1">
            <el-color-picker v-model="itemData.paddingStyle.color" @change="itemDataChange4"/>
          </el-form-item>
          <el-form-item label="操作">
            <el-button type="primary" @click="removePosterData">删除</el-button>
          </el-form-item>
          <div v-if="itemData.type === 1" class="">
          </div>
        </el-form>
      </div>
      <div v-if="index===4" class="itemSet">
        <el-form label-width="100px">
          <el-form-item label="心愿盒码">
            <el-button type="primary" @click="selectImg('ma1')">选择图片</el-button>
          </el-form-item>
          <el-form-item label="心愿盒文本">
            <el-input v-model="ma1text"></el-input>
          </el-form-item>
          <el-form-item label="品牌方码">
            <el-button type="primary" @click="selectImg('ma2')">选择图片</el-button>
          </el-form-item>
          <el-form-item label="品牌方文本">
            <el-input v-model="ma2text"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 选择用户评论 -->
    <el-dialog v-model="dialogFlag" title="用户评论" width="1000px">
      <el-form inline>
        <el-form-item label="选择产品">
          <el-select v-model="productId">
            <el-option v-for="item in product_options" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户昵称">
          <el-input v-model="form.userName" placeholder="用户昵称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="commentList" @current-change="handleCurrentChange" highlight-current-row>
        <el-table-column label="id" prop="id" width="80"></el-table-column>
        <el-table-column label="评论" prop="content"></el-table-column>
        <el-table-column label="昵称" prop="user.nickname" width="100"></el-table-column>
      </el-table>
      <el-pagination
        v-model:currentPage="pageCurrent"
        page-size="20"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="changePages"
      />
      <template #footer>
        <el-button @click="dialogFlag = false">取消</el-button>
        <el-button type="primary" @click="submitBtn">确定</el-button>
      </template>
    </el-dialog>
    <!-- 编辑内容 -->
    <el-dialog v-model="dialogFlag1" title="编辑内容">
      <div ref="Editor"></div>
      <template #footer>
        <el-button @click="dialogFlag1 = false">取消</el-button>
        <el-button type="primary" @click="closeDialog1">确定</el-button>
      </template>
    </el-dialog>

  </div>
</template>
<script>
import html2canvas from 'html2canvas'
import { ElMessage } from 'element-plus';
import global_api,{QueryUserCommentListByUserCommentSearchVo} from '../../config/Api';
import {queryUserIconByUserId} from '../../config/jsonapi';
import E from "wangeditor";
export default {
  data(){
    return {
      index: 1,
      dialogFlag: false,
      dialogFlag1: false,
      product_options: [],
      productId: '',
      pageCurrent: 1,
      total: 1,
      commentList: [],
      loading: false,
      form:{},
      selectData: '',
      selectComment: [],
      itemData: {},
      itemDataSort: 0,
      // 海报数据
      posterData: [],

      // 海报设置
      BG: ['/images/poster/posterBG1.jpg','/images/poster/posterBG2.jpg'],
      BGindex: 0,
      posterBGData:[
        {
          backgroundImage:'url("/images/poster/posterBG1.jpg")',
          width:'750px',
          height:'768px',
        },
        {
          backgroundImage:'url("/images/poster/posterBG2.jpg")',
          width:'',
          height:'',
        }
      ],

      // 样式数据
      pageContentStyle:[
        {
          top: '162px',
          left: '52px',
          bottom: '249px',
          right: '52px'
        }
      ] ,
      pageContentStyleObj: {},

      // 默认评论区样式
      paddingStyleArr:[
        {
          marginTop: '0px',
          marginLeft: '0px',
          marginBottom: '0px',
          marginRight: '0px',
        },
        {
          marginTop: '0px',
          marginLeft: '0px',
          marginBottom: '0px',
          marginRight: '0px',
        }
      ],

      // 图片
      ma1: '',
      ma2: '',
      ma1text: '心动体验 共创新品',
      ma2text: '品牌官方商城',

    }
  },
  methods:{
    // 选择模块
    changeIndex(index){
      console.log(index);
      if(index === 2){
        for(let k in this.pageContentStyle[this.BGindex]){
          this.pageContentStyleObj[k] = parseInt(this.pageContentStyle[this.BGindex][k])
        }
        console.log(this.pageContentStyleObj);
      }
      if(index != 3){
        this.itemData = '';
      }
      this.index = index;
      if(index === 5){
        this.downPoster();
      }
    },
    // 设置内容区样式
    contentStyleChange(){
      for(let k in this.pageContentStyleObj){
        this.pageContentStyle[this.BGindex][k] = this.pageContentStyleObj[k] + 'px';
      }
    },
    // 选择内容模板
    selectPosterType(type){
      let itemData = {
        type: type,
        sort: this.posterData.length,
        paddingStyle: {...this.paddingStyleArr[type]}
      }
      if(type===1){
        itemData.value = '请输入如文本';
        itemData.paddingStyle.textAlign = 'left'
        itemData.paddingStyle.fontSize = '16px'
        itemData.paddingStyle.color = '#333'
      }
      console.log(itemData);
      this.posterData.push(itemData)
      this.setInit(itemData);
    },
    // 初始化设置
    setInit(data){
      let item = JSON.parse(JSON.stringify(data))
      for(let k in item.paddingStyle){
        if(k=='marginTop'||k=='marginLeft'||k=='marginBottom'||k=='marginRight'||k=='fontSize'){
          item.paddingStyle[k] = parseInt(item.paddingStyle[k])
        }
        
      }
      this.itemData = item;
      this.itemDataSort = item.sort;
    },
    // 开启设置
    openItemSet(data){
      this.setInit(data);
      this.index = 3;
    },
    // 修改设置
    itemDataChange2(){
      let posterData = JSON.parse(JSON.stringify(this.posterData));
      let itemDataSort = this.itemDataSort;
      let itemData = JSON.parse(JSON.stringify(this.itemData));
      posterData.splice(itemDataSort,0,...posterData.splice(itemData.sort,1));
      for(let i in posterData){
        posterData[i].sort = Number(i);
      }
      this.posterData = posterData;
      this.setInit(posterData[itemDataSort])
    },
    itemDataChange3(){
      let posterData = JSON.parse(JSON.stringify(this.posterData));
      let itemData = JSON.parse(JSON.stringify(this.itemData));
      for(let k in itemData.paddingStyle){
        if(k=='marginTop'||k=='marginLeft'||k=='marginBottom'||k=='marginRight'||k=='fontSize'){
          itemData.paddingStyle[k] = itemData.paddingStyle[k]+'px'
        }
      }
      posterData.splice(itemData.sort,1,itemData);
      this.posterData = posterData;
    },
    removePosterData(){
      let posterData = JSON.parse(JSON.stringify(this.posterData));
      let itemData = this.itemData;
      posterData.splice(itemData.sort,1);
      for(let i in posterData){
        posterData[i].sort = Number(i);
      }
      itemData = posterData[itemData.sort] || posterData[0];
      if(itemData){
        this.setInit(itemData)
      }else{
        this.itemData = '';
      }
      this.posterData = posterData;
    },
    itemDataChange4(){
      let posterData = JSON.parse(JSON.stringify(this.posterData));
      let itemData = JSON.parse(JSON.stringify(this.itemData));
      posterData.splice(itemData.sort,1,itemData);
      this.posterData = posterData;
    },



    // 打开选择评论弹窗
    openDialog() {
      this.dialogFlag = true;
      this.pageCurrent = 1;
      this.GetProductList();
    },
    GetProductList(){
      global_api.GetProductList().then((res)=>{
        this.product_options = res.result
      },err =>{
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },
    // 获取评论
    getList(){
      this.loading = true;
      let param = {
        cunPage: this.pageCurrent,
        pageSize: 10,
        productId: this.productId,
      }
      QueryUserCommentListByUserCommentSearchVo(param).then(res=>{
        let list = res.result;
        this.commentList = list;
        this.total = res.data.totalCount;
        this.loading = false;
      },err=>{
        this.commentList = [];
        this.loading = false;
      })
    },
    changePages(){
      this.getList();
    },
    search(){
      this.pageCurrent = 1;
      this.getList();
    },
    handleCurrentChange(e){
      console.log(e);
      this.selectData = e;
    },
    // 确定
    submitBtn(){
      if(this.selectData){
        let posterData = JSON.parse(JSON.stringify(this.posterData));
        let itemData = JSON.parse(JSON.stringify(this.itemData));
        itemData.comment = this.selectData;
        itemData.comment.title = `${this.selectData.user.nickname}(${this.selectData.user.age}岁、${this.selectData.user.gender===1?'男':'女'}、${this.selectData.user.occupationName})`
        posterData[itemData.sort] = itemData;
        this.itemData = itemData;
        this.posterData = posterData;
        this.dialogFlag = false;
        this.queryUserIconByUserId(itemData)
      } else {
        ElMessage.error('请选择评论')
      }
    },
    // 获取用户头像
    queryUserIconByUserId(data){
      let param = {
        userId:data.comment.userId,
      };
      queryUserIconByUserId(param).then(res=>{
        this.posterData[data.sort].comment.url = res.result.url;
      },err=>{})
    },
    // 选择图片
    selectImg(n){
      let inputObj=document.createElement('input')
      inputObj.setAttribute('type','file');
      inputObj.onchange = () => {
        console.log(inputObj.files[0]);
        const base = new FileReader();
        base.readAsDataURL(inputObj.files[0]);
        base.onload = () => {
          this[n] = base.result
        }
      }
      inputObj.click();
    },
    // 打开编辑内容
    openDialog1(){
      let itemData = JSON.parse(JSON.stringify(this.itemData));
      this.dialogFlag1 = true;
      let editotConfig = [
        'bold',
        'head',
        'fontSize',
        'foreColor',
        'backColor',
        'italic',
        'underline',
        'undo',
        'redo',
      ];
      setTimeout(()=>{
        if(this.editor){
          this.editor.txt.html(itemData?.comment?.content)
        }else{
          this.editor = new E(this.$refs.Editor);
          this.editor.create();
          this.editor.config.onchange = (text)=>{
            this.content = text;
          }
          this.editor.txt.html(itemData?.comment?.content)
        }
      },0)
    },
    // 确定编辑内容
    closeDialog1(){
      this.dialogFlag1 = false;
      
      this.itemData.comment.content = this.content;
      console.log(this.content);
    },
    
    // 下载海报
    downPoster(){
      html2canvas(this.$refs.page,{width:750,height:768,allowTaint: true,useCORS:true}).then(canvas=>{
        console.log(canvas);
        var a = document.createElement("a");
        a.href = canvas.toDataURL("image/jpg");
        a.download = '图片';
        a.click();
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.poster{
  height: 100%;
  border: 1px solid #ddd;
  display: flex;
}
.poster .aside{
  width: 200px;
  height: 100%;
  overflow: auto;
  border-right: 1px solid #ddd;
  background-color: #f1f1f1;
}
.poster .aside ul{
  padding: 0;
}
.poster .aside ul li {
  padding: 0 10px;
  height: 40px;
  font-size: 18px;
  line-height: 40px;
  color: #333;
  cursor: pointer;
}
.poster .aside ul li:hover{
  color: #125bfa;
  background-color: #125bfa27;
}
.poster .aside ul li.check{
  color: #125bfa;
  background-color: #125bfa27;
}
.poster .aside .comments{
  div{
    font-size: 14px;
    padding: 0 20px;
    height: 32px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    span{
      cursor: pointer;
      display: none;
    }
    &:hover{
      color: #125bfa;
      span{
        display: block;
      }
    }
  }
}
.poster .main{
  width: 100%;
  flex: 1;
}
.poster .setting{
  width: 400px;
  height: 100%;
  background-color: rgba(80, 146, 148, 0.329);
  overflow: auto;
}
.poster .setting .BGset{
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}
.poster .setting .BGset .item{
  width: 100px;
  height: 130px;
  background-color: #568afc31;
  margin: 10px;
  border: 2px solid #fff;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
}
.poster .setting .BGset .item.checked{
  border: 2px solid #66ccff;
}
.poster .setting .BGset .item img{
  width: 100%;
}
.poster .setting .comment{
  padding: 20px;
}
.poster .setting .checkItem {
  margin-bottom: 10px;
}
.poster .setting .checkItem span{
  color: #333;
  font-size: 16px;
  line-height: 30px;
  margin-right: 10px;
}
.poster .comment .item{
  padding: 5px 0;
  border-bottom: 1px solid #fff;
}
.poster .comment .item .remove{
  display: flex;
  justify-content: end;
}
.poster .comment .item .remove span{
  color: #000;
  cursor: pointer;
  font-size: 16px;
}
.poster .comment .item .remove span:hover{
  color: #125bfa;
}
.poster .setting .itemSet{
  padding: 20px 0;
}
.page{
  width: 500px;
  margin: 50px auto 0;
  height: 800px;
  position: relative;
}
.page::after{
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  width: 100%;
  height: 100%;
  border: 2px solid #ccc;
}
.page:hover::after{
  border: 2px solid #66ccff;
}
.page .pageBG{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.page .pageContent{
  position: absolute;
  z-index: 2;
  box-sizing: border-box;
}
.page .pageContent.checked{
  border: 2px dashed #125bfa;
}
.page .pageContent .item{
  border: 1px solid rgba($color: #000000, $alpha: 0);
  box-sizing: border-box;
  cursor: pointer;
  &:hover{
    border: 1px dashed red;
  }
  &.checked{
    border: 1px dashed red;
  }
}
.page .BG1Foot{
  position: absolute;
  bottom: 0;
  left: 0;
  height: 220px;
  width: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
  z-index: 3;
  div{
    width: 230px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 19px;
    img{
      width: 120px;
      height: 120px;
      margin-top: 20px;
      margin-bottom: 17px;
    }
  }
}
// 
.type1{
  .title{
    display: flex;
    align-items: center;
    padding: 10px 28px;
    color: #ec774c;
    font-size: 20px;
    font-weight: bold;
    img{
      width: 40px;
      height: 40px;
      border-radius: 40px;
      margin-right: 18px;
    }
  }
  .comment {
    padding-left: 77px;
    padding-right: 35px;
    font-size: 18px;
    line-height: 30px;
  }
} 
</style>