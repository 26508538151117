<template>
  <div v-loading="loading">
    <el-table :data="list" stripe highlight-current-row @current-change="handleCurrentChange">
      <el-table-column prop="id" label="ID"></el-table-column>
      <el-table-column prop="sort" label="排序"></el-table-column>
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="image" label="图片">
        <template #default="scope">
          <div class="tableImg">
            <img :src="scope.row.image" alt="" style="width:50px" />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="content"
        label="描述"
        width="280"
      ></el-table-column>
    </el-table>
    <el-pagination
      v-model:currentPage="cunPage"
      :page-size="10"
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="getList"
    />
  </div>
</template>
<script>
import {GetSpecialList} from '../config/Api'
export default {
  data() {
    return {
      cunPage: 1,
      total: 0,
      list: [],
      loading: false,
      name: '',
    }
  },
  mounted() {
    this.name = '';
    this.cunPage = 1;
    this.getList();
  },
  methods: {
    // 获取列表
    getList() {
      let param = {
        id: 1,
        pageNo: this.cunPage,
        pageSize: 10,
        name: this.name,
      };
      this.loading = true;
      GetSpecialList(param).then(
        (res) => {
          this.loading = false;
          this.list = res.result;
          this.total = res.data.totalCount;
        },
        (err) => {
          this.loading = false;
          this.list = [];
          ElMessage.error(`获取调研列表失败：${err.msg}`);
        }
      );
    },
    handleCurrentChange(e){
      this.$emit('select',e)
    },
  }
}
</script>