import instance from "@/plugins/axios";

// 请求会员列表
export function QueryUserListByUserSearchVo(data) {
    return instance.post('/admin/user/queryUserListByUserSearchVo', data);
}

// 根据用户id禁用用户
export function ModifyUserActiveByUserId(data) {
    return instance.post('/admin/user/modifyUserActiveByUserId', data);
}

// 根据用户id删除用户（伪删除）
export function RemoveUserByUserId(data) {
    return instance.post('/admin/user/removeUserByUserId', data);
}

// 查询人群库列表
export function GetTargetAudienceList(data) {
    return instance.get('/admin/target/getTargetAudienceList', data);
}

// 删除人群库信息
export function DeleteTargetAudienceInfo(data) {
    return instance.post('/admin/target/deleteTargetAudienceInfo', data);
}

// 根据用户id获取所有收货地址列表
export function QueryAllAddressByUserId(data) {
    return instance.post('/admin/rawAddress/queryAllAddressByUserId', data);
}

// 获取地址列表
export function QueryAddressList(data) {
    return instance.post('/admin/address/queryAddressList', data);
}

// 添加收货地址
export function AddAddress(data) {
    return instance.post('/admin/address/addAddress', data);
}

// 获取心愿列表
export function QueryWishListByWishSearchVo(data) {
    return instance.post('/admin/wish/queryWishListByWishSearchVo', data);
}

// 获取任务列表
export function QueryTaskList(data) {
    return instance.post('/admin/task/queryTaskList', data);
}

// 根据任务id上下架任务
export function ModifyTaskActiveByTaskId(data) {
    return instance.post('/admin/task/modifyTaskActiveByTaskId', data);
}

// 根据任务id修改任务
export function ModifyTaskByTaskId(data) {
    return instance.post('/admin/task/modifyTaskByTaskId', data);
}

// 操作人群库信息（新增/修改）
export function HandleTargetAudienceInfo(data) {
    return instance.post('/admin/target/handleTargetAudienceInfo', data);
}

// 获取反馈留言列表
export function QueryFeedBackListByFeedBackSearchVo(data) {
    return instance.post('/admin/feedBack/queryFeedBackListByFeedBackSearchVo', data);
}

// 获取小程序二维码
export function QueySpecialEventQrCodeByPath(data) {
    return instance.post('/admin/qrcode/queySpecialEventQrCodeByPath', data);
}

// 获取心愿订单列表
export function QueryUserWishListByUserWishSearchVo(data) {
    return instance.post('/admin/userWish/queryUserWishListByUserWishSearchVo', data);
}

// 通过心愿订单id删除心愿订单
export function RemoveUserWishByUserWishId(data) {
    return instance.post('/admin/userWish/removeUserWishByUserWishId', data);
}

// 通过心愿订单id获取心愿订单详情
export function QueryUserWishInfoByUserWishId(data) {
    return instance.post('/admin/userWish/queryUserWishInfoByUserWishId', data);
}

// 获取心愿库存列表
export function QueryWishStockListByWishId(data) {
    return instance.post('/admin/wishStock/queryWishStockListByWishId', data);
}

// 获取标签接口
export function getTargetAudienceInfo(data) {
    return instance.get('/admin/tag/getTargetAudienceInfo', data);
}

// 添加心愿库存
export function AddWishStockByWishId(data) {
    return instance.post('/admin/wishStock/addWishStockByWishId', data);
}

// 通过心愿库存id删除
export function RemoveWishStockByWishStockId(data) {
    return instance.post('/admin/wishStock/removeWishStockByWishStockId', data);
}

// 获取首页/心愿广场轮播图列表
export function getBannerList(data) {
    return instance.get('/admin/banner/getBannerList', data);
}

// 新增/修改轮播图
export function InsertOrUpdateBanner(data) {
    return instance.post('/admin/banner/insertOrUpdateBanner', data);
}

// 获取动态列表
export function QueryUserMomentListByUserMomentSearchVo(data) {
    return instance.post('/admin/userMoment/queryUserMomentListByUserMomentSearchVo', data);
}

// 获取话题列表
export function GetTopicList(data) {
    return instance.get('/admin/topic/getTopicList', data);
}

// 根据动态id审核
export function ModifyUserMomentStatusByUserMomentId(data) {
    return instance.post('/admin/userMoment/modifyUserMomentStatusByUserMomentId', data);
}

// 根据动态ids批量审核
export function ModifyUserMomentStatusByUserMomentIds(data) {
    return instance.post('/admin/userMoment/modifyUserMomentStatusByUserMomentIds', data);
}

// 通过动态id修改动态
export function ModifyUserMomentByUserMomentId(data) {
    return instance.post('/admin/userMoment/modifyUserMomentByUserMomentId', data);
}

// 获取产品列表
export function QueryProductListByProductSearchVo(data) {
    return instance.post('/admin/product/queryProductListByProductSearchVo', data);
}

// 根据产品id修改产品
export function ModifyProductByProductId(data) {
    return instance.post('/admin/product/modifyProductByProductId', data);
}

// 通过产品id删除产品
export function RemoveProductByProductId(data) {
    return instance.post('/admin/product/removeProductByProductId', data);
}

// 通过产品id获取产品资源列表
export function QueryProductResourceListByProductResourceSearchVo(data) {
    return instance.post('/admin/product_resource/queryProductResourceListByProductResourceSearchVo', data);
}

// 添加产品资源
export function AddProductResouce(data) {
    return instance.post('/admin/product_resource/addProductResouce', data);
}

// 根据产品资源id修改产品资源
export function ModifyProductResourceByProductResourceId(data) {
    return instance.post('/admin/product_resource/modifyProductResourceByProductResourceId', data);
}

// 通过产品资源id删除产品资源
export function RemoveProductResourceByProductResourceId(data) {
    return instance.post('/admin/product_resource/removeProductResourceByProductResourceId', data);
}

// 通过type和id获取问卷信息
export function QuerySurveyInfoBySurveyTypeAndOriginId(data) {
    return instance.post('/admin/survey/querySurveyInfoBySurveyTypeAndOriginId', data);
}

// 通过问卷的type和来源id查询问卷及用户答案
export function QuerySurveyWithQuestionAndOptionInfoByTypeAndOriginId(data) {
    return instance.post('/admin/survey/querySurveyWithQuestionAndOptionInfoByTypeAndOriginId', data);
}

// 题目上下架/删除接口
export function UpdateQuestionShelfOrDelete(data) {
    return instance.post('/admin/question/updateQuestionShelfOrDelete', data);
}

// 获取调研管理列表
export function GetSpecialList(data) {
    return instance.post('/admin/specialEvent/getSpecialList', data);
}

// 调研信息上下架/删除接口
export function UpdateSpecialEventShelfOrDelete(data) {
    return instance.post('/admin/specialEvent/updateSpecialEventShelfOrDelete', data);
}

// 新增/修改调研信息接口
export function InsertOrUpdateSpecialEvent(data) {
    return instance.post('/admin/specialEvent/insertOrUpdateSpecialEvent', data);
}

// 根据心愿id上下架
export function ModifyWishStatusByWishId(data) {
    return instance.post('/admin/wish/modifyWishStatusByWishId', data);
}

// 添加心愿
export function AddWish(data) {
    return instance.post('/admin/wish/addWish', data);
}

// 通过心愿id修改心愿
export function ModifyWishByWishId(data) {
    return instance.post('/admin/wish/modifyWishByWishId', data);
}

// 通过心愿库存id编辑上限人数
export function ModifyWishStockByWishStockId(data) {
    return instance.post('/admin/wishStock/modifyWishStockByWishStockId', data);
}

// 通过心愿订单id修改心愿订单
export function ModifyUserWishByUserWishId(data) {
    return instance.post('/admin/userWish/modifyUserWishByUserWishId', data);
}

// 通过调研ID查看发奖规则
export function GetRewardListBySpecialEventId(data) {
    return instance.get('/admin/reward/getRewardListBySpecialEventId', data);
}

// 添加任务
export function AddTaskByTaskId(data) {
    return instance.post('/admin/task/addTaskByTaskId', data);
}

// 根据心愿id删除
export function removeWishByWishId(data) {
    return instance.post('/admin/wish/removeWishByWishId', data);
}

// 根据心愿id通知本期未许愿的用户
export function notifyWishUserByWishId(data) {
    return instance.post('/admin/wish/notifyWishUserByWishId', data);
}

// 获取产品评价列表
export function QueryUserCommentListByUserCommentSearchVo(data) {
    return instance.post('/admin/user/comment/queryUserCommentListByUserCommentSearchVo', data);
}

// 根据评论id修改评论显示状态
export function ModifyUserCommentIsVisibleByUserCommentId(data) {
    return instance.post('/admin/user/comment/modifyUserCommentIsVisibleByUserCommentId', data);
}

// 根据评论id处理为优质好评还是优质差评
export function ModifyUserCommentSentimentByUserCommentId(data) {
    return instance.post('/admin/user/comment/modifyUserCommentSentimentByUserCommentId', data);
}

// 新增/修改发奖规则
export function InsertOrUpdateReward(data) {
    return instance.post('/admin/reward/insertOrUpdateReward', data);
}

// 获取产品列表(下拉框)
export function GetProductList(data) {
    return instance.post('/admin/product/getProductList', data);
}

// 添加绑定产品/品牌接口
export function BindingProductOrBrand(data) {
    return instance.post('/admin/specialEvent/bindingProductOrBrand', data);
}

// 添加显示规则
export function InsertTargetAudienceUseBySpecialEventId(data) {
    return instance.post('/admin/targetAudienceUse/insertTargetAudienceUseBySpecialEventId', data);
}

// 根据动态id获取产品数据带产品资源
export function QueryUserMomentProductList(data) {
    return instance.post('/admin/userMoment/queryUserMomentProductList', data);
}

// 根据调研ID查询显示规则
export function GetTargetAudienceUseBySpecialEventId(data) {
    return instance.get('/admin/targetAudienceUse/getTargetAudienceUseBySpecialEventId', data);
}

// 删除显示规则
export function DeleteTargetAudienceUse(data) {
    return instance.post('/admin/targetAudienceUse/deleteTargetAudienceUse', data);
}

// 删除发奖规则
export function DeleteRewardListByRewardId(data) {
    return instance.post('/admin/reward/deleteRewardListByRewardId', data);
}

// 获取盒子列表
export function QueryBoxListByBoxSearchVo(data) {
    return instance.post('/admin/box/queryBoxListByBoxSearchVo', data);
}

// 获取心愿盒对应产品列表
export function getBoxProductList(data) {
    return instance.get('/admin/box/getBoxProductList', data);
}

// 添加或修改心愿盒
export function UpdateBoxInfo(data) {
    return instance.post('/admin/box/updateBoxInfo', data);
}

// 获取调研库存列表
export function querySpecialEventStockListBySpecialEventId(data) {
    return instance.post('/admin/specialEventStock/querySpecialEventStockListBySpecialEventId', data);
}

// 根据调研库存ID修改库存接口
export function modifySpecialEventStockBySpecialEventStockId(data) {
    return instance.post('/admin/specialEventStock/modifySpecialEventStockBySpecialEventStockId', data);
}

// 添加调研库存
export function addSpecialEventStockBySpecialEventId(data) {
    return instance.post('/admin/specialEventStock/addSpecialEventStockBySpecialEventId', data);
}

// 通过调研库存id删除
export function removeSpecialEventStockBySpecialEventStockId(data) {
    return instance.post('/admin/specialEventStock/removeSpecialEventStockBySpecialEventStockId', data);
}

// 根据ID查询人群库信息（支持多个ID）
export function getTargetAudienceById(data) {
    return instance.get('/admin/target/getTargetAudienceById', data);
}

// 心愿订单批量导出
export function userWishDownload(data) {
    return instance.get('/admin/userWish/userWishDownload', data);
}

// 心愿订单批量导出
export function modifyGradeTypeBySurveyId(data) {
    return instance.post('/admin/survey/modifyGradeTypeBySurveyId', data);
}

// 根据题目id添加显示规则
export function addQuestionShowByQuestionId(data) {
    return instance.post('/admin/questionShow/addQuestionShowByQuestionId', data);
}

// 根据题目id查看显示规则
export function queryQuestionShowByQuestionId(data) {
    return instance.post('/admin/questionShow/queryQuestionShowByQuestionId', data);
}

// 根据显示规则id修改
export function modifyQuestionShowByShowId(data) {
    return instance.post('/admin/questionShow/modifyQuestionShowByShowId', data);
}

// 根据显示规则id删除
export function removeQuestionShowByShowId(data) {
    return instance.post('/admin/questionShow/removeQuestionShowByShowId', data);
}

// 同步杂货铺商品列表
export function syncProductShopCommodityList(data) {
    return instance.post('/admin/product/commodity/syncProductShopCommodityList', data);
}

// 根据杂货铺商品id修改序号
export function modifyProductShopCommoditySortBySpuId(data) {
    return instance.post('/admin/product/commodity/modifyProductShopCommoditySortBySpuId', data);
}

// 根据动态id获取动态评论列表
export function queryDynamicCommentListByDynamicId(data) {
    return instance.post('/admin/moment/comment/queryDynamicCommentListByDynamicId', data);
}

// 根据用户id修改用户身份
export function modifyUserIdentityTypeByUserId(data) {
    return instance.post('/admin/user/modifyUserIdentityTypeByUserId', data);
}

// 根据文件操作用户心愿币
export function modifyUserIntegral(data) {
    return instance.post('/admin/import/file/modifyUserIntegral', data);
}

// 获取心愿币记录列表
export function queryUserCoinLogListByUserCoinLogSearchVo(data) {
    return instance.post('/admin/user/coin/log/queryUserCoinLogListByUserCoinLogSearchVo', data);
}

// 通过产品id和高频词查询符合评论数量
export function queryTagNumberByProductIdAndTagName(data) {
    return instance.post('/admin/product/queryTagNumberByProductIdAndTagName', data);
}

// 动态评论
export function userCommentDynamic(data) {
    return instance.post('/admin/moment/comment/userCommentDynamic', data);
}

// 根据用户身份获取会员列表
export function queryUserListByiIdentityType(data) {
    return instance.post('/admin/user/queryUserListByiIdentityType', data);
}

// 品牌页面：获取品牌列表
export function queryBrandListByBrandSearchVo(data) {
    return instance.post('/admin/brand/queryBrandListByBrandSearchVo', data);
}

// 根据品牌id上下架
export function modifyBrandActiveByBrandId(data) {
    return instance.post('/admin/brand/modifyBrandActiveByBrandId', data);
}

// 通过品牌id删除品牌
export function removeBrandByBrandId(data) {
    return instance.post('/admin/brand/removeBrandByBrandId', data);
}

// 添加品牌
export function addBrand(data) {
    return instance.post('/admin/brand/addBrand', data);
}

// 通过产品id获取项目列表
export function queryProjectListByProductId(data) {
    return instance.post('/admin/project/queryProjectListByProductId', data);
}

// 通过产品项目对应ID删除产品项目对应关系
export function removeBoxProjectByBoxProjectId(data) {
    return instance.post('/admin/boxProject/removeBoxProjectByBoxProjectId', data);
}

// 通过标签ids批量删除标签
export function removeTagsByTagIds(data) {
    return instance.post('/admin/tag/removeTagsByTagIds', data);
}

// 获取品牌列表
export function getBrandList(data) {
    return instance.get('/admin/brand/getBrandList', data);
}

// 获取品牌列表
export function modifyBrandByBrandId(data) {
    return instance.post('/admin/brand/modifyBrandByBrandId', data);
}

// 获取品牌列表
export function addTagCategoryBatch(data) {
    return instance.post('/admin/tag/category/addTagCategoryBatch', data);
}

// 获取品牌列表
export function queryQuotaByPath(data) {
    return instance.post('/admin/wx/queryQuotaByPath', data);
}

// 重置access_token
export function resetAccessToken(data) {
    return instance.post('/admin/wx/resetAccessToken', data);
}

// 回复反馈留言
export function replyFeedBack(data) {
    return instance.post('/admin/feedBack/replyFeedBack', data);
}

// 回复反馈留言
export function addBoxProductBatch(data) {
    return instance.post('/admin/import/file/addBoxProductBatch', data);
}

// 获取反馈留言的回复列表
export function queryFeedBackReplyList(data) {
    return instance.post('/admin/feedBack/queryFeedBackReplyList', data);
}

// 获取用户标签列表
export function queryUserTagListByUserTagSearchVo(data) {
    return instance.post('/admin/user/tag/queryUserTagListByUserTagSearchVo', data);
}

// 添加用户标签
export function addUserTag(data) {
    return instance.post('/admin/user/tag/addUserTag', data);
}

// 修改用户标签
export function modifyUserTagByUserTagId(data) {
    return instance.post('/admin/user/tag/modifyUserTagByUserTagId', data);
}

// 通过用户标签id删除用户标签
export function removeUserTagByUserTagId(data) {
    return instance.post('/admin/user/tag/removeUserTagByUserTagId', data);
}

// 获取所有标签
export function queryAllTagListByTagSearchVo(data) {
    return instance.post('/admin/user/tag/queryAllTagListByTagSearchVo', data);
}

// 通过心愿id获取用户列表
export function queryUserListByWishId(data) {
    return instance.post('/admin/userWish/queryUserListByWishId', data);
}

// 通过调研id获取用户列表
export function queryUserListBySpecialEventId(data) {
    return instance.post('/admin/specialEvent/queryUserListBySpecialEventId', data);
}

// 根据用户ids导出标签详情
export function downloadUserTagExcelByUserIds(data) {
    return instance.post('/admin/download/file/downloadUserTagExcelByUserIds', data);
}

// 新增/修改题目时同步修改user_tag
export function insertOrUpdateQuestionSyncUpdateUserTag(data) {
    return instance.post('/admin/question/insertOrUpdateQuestionSyncUpdateUserTag', data);
}

// 导出心愿订单
export function downloadUserMomentExcelByUserMomentSearchVo(data) {
    return instance.post('/admin/download/file/downloadUserMomentExcelByUserMomentSearchVo', data);
}

// 查询已完成的发送
export function queryFinishSend(data) {
    return instance.post('/admin/notification/record/queryFinishSend', data);
}

// 查询进行中的发送
export function queryOnGoingSend(data) {
    return instance.post('/admin/notification/record/queryOnGoingSend', data);
}

// 获取私有模板列表
export function queryTemplateList(data) {
    return instance.post('/admin/notification/record/queryTemplateList', data);
}

// 发送模板消息
export function sendTmplPush(data) {
    return instance.post('/admin/notification/record/sendTmplPushByMQ', data);
}

// 根据发送事件ID查询发送详情
export function queryNotificationRecordDetailById(data) {
    return instance.post('/admin/notification/record/detail/queryNotificationRecordDetailById', data);
}

// 根据题目ID添加显示规则
export function insertTargetAudienceUseByQuestionId(data) {
    return instance.post('/admin/targetAudienceUse/insertTargetAudienceUseByQuestionId', data);
}

// 根据题目ID查询显示规则
export function getTargetAudienceUseByQuestionId(data) {
    return instance.get('/admin/targetAudienceUse/getTargetAudienceUseByQuestionId', data);
}

// 根据题目ID删除显示规则
export function deleteTargetAudienceUseByQuestionId(data) {
    return instance.post('/admin/targetAudienceUse/deleteTargetAudienceUseByQuestionId', data);
}

// 根据产品id修改产品类别l
export function modifyProductTypeByProductId(data) {
    return instance.post('/admin/product/modifyProductTypeByProductId', data);
}

// 根据文件userid，tag id 批量增加用户标签功能
export function addUserTagBatch(data) {
    return instance.post('/admin/user/tag/addUserTagBatch', data);
}

// 根据用户id禁用用户(批量)
export function modifyUserActiveByUserIds(data) {
    return instance.post('/admin/user/modifyUserActiveByUserIds', data);
}

// 导出模板发送结果列表excel
export function downloadNotificationRecordDetailIdExcelByNotificationRecordId(data) {
    return instance.post('/admin/download/file/downloadNotificationRecordDetailIdExcelByNotificationRecordId', data);
}

// 获取管理员列表
export function queryAdminListByAdminSearchVo(data) {
    return instance.post('/admin/queryAdminListByAdminSearchVo', data);
}

// 根据管理员id删除管理员
export function removeAdminByAdminId(data) {
    return instance.post('/admin/removeAdminByAdminId', data);
}

// 根据管理员id禁用/解禁管理员
export function modifyAdminActiveByAdminId(data) {
    return instance.post('/admin/modifyAdminActiveByAdminId', data);
}

// 根据管理员id修改管理员
export function modifyAdminByAdminId(data) {
    return instance.post('/admin/modifyAdminByAdminId', data);
}

// 添加管理员
export function addAdmin(data) {
    return instance.post('/admin/addAdmin', data);
}

// 获取角色列表
export function queryRoleList(data) {
    return instance.post('/admin/role/queryRoleList', data);
}

// 根据角色id删除角色
export function removeRoleByRoleId(data) {
    return instance.post('/admin/role/removeRoleByRoleId', data);
}

// 修改角色
export function modifyRoleByRoleId(data) {
    return instance.post('/admin/role/modifyRoleByRoleId', data);
}

// 添加角色
export function addRole(data) {
    return instance.post('/admin/role/addRole', data);
}

// 通过标签ids获取拥有标签数量的人数
export function queryUserCountByTagIds(data) {
    return instance.post('/admin/tag/queryUserCountByTagIds', data);
}

// 通过标签ids获取题目及选项及选择这个选项的人
export function queryAnswerCountByTagIds(data) {
    return instance.post('/admin/tag/queryAnswerCountByTagIds', data);
}

// 通过角色IDS获取权限资源列表
export function queryApiListByRoleId(data) {
    return instance.post('/admin/api/queryApiListByRoleId', data);
}

// 通过角色ID修改角色资源对应
export function modifyApiLogByRoleId(data) {
    return instance.post('/admin/role/api/modifyApiLogByRoleId', data);
}

// 添加管理员角色对应关系
export function addAdminRole(data) {
    return instance.post('/admin/admin/role/addAdminRole', data);
}

// 删除管理员角色对应关系
export function removeAdminRoleByAdminRoleId(data) {
    return instance.post('/admin/admin/role/removeAdminRoleByAdminRoleId', data);
}

// 获取敏感词列表
export function querySensitiveWordListBySensitiveWordSearchVo(data) {
    return instance.post('/admin/sensitive/word/querySensitiveWordListBySensitiveWordSearchVo', data);
}

// 修改敏感词风险级别
export function modifySensitiveWordTypeBySensitiveWordId(data) {
    return instance.post('/admin/sensitive/word/modifySensitiveWordTypeBySensitiveWordId', data);
}

// 查询自动增加心愿库存事件列表
export function queryAutoWishStockRecordList(data) {
    return instance.post('/admin/notification/record/queryAutoWishStockRecordList', data);
}

// 通过id获取系统设置 （数据字典）
export function getKvMapById(data) {
    return instance.post('/admin/kv_map/getKvMapById', data);
}

// 通过标签ids获取标签在人群库的使用次数
export function queryTargetCountByTagIds(data) {
    return instance.post('/admin/tag/queryTargetCountByTagIds', data);
}

// 获取企微标签列表
export function queryCorpTagList(data) {
    return instance.post('/admin/enterprise/wechat/tag/queryCorpTagList', data);
}

// 自有标签与企业标签页面：获取自有标签与企业标签映射列表
export function queryTagListByTagSearchVo(data) {
    return instance.post('/admin/tag/wechat/map/queryTagListByTagSearchVo', data);
}

// 添加自有标签与企业标签映射
export function addTagWeChatMap(data) {
    return instance.post('/admin/tag/wechat/map/addTagWeChatMap', data);
}

// 通过自有标签与企业标签映射id删除自有标签与企业标签映射关系
export function removeTagWechatMapByTagWechatMapId(data) {
    return instance.post('/admin/tag/wechat/map/removeTagWechatMapByTagWechatMapId', data);
}

// 添加CEM公告
export function addResearchReport(data) {
    return instance.post('/admin/research/report/addResearchReport', data);
}

// 获取CEM公告列表
export function queryResearchReportListByResearchReportSearchVo(data) {
    return instance.post('/admin/research/report/queryResearchReportListByResearchReportSearchVo', data);
}

// 根据CEM公告ID修改CEM公告
export function modifyResearchReportByResearchReportId(data) {
    return instance.post('/admin/research/report/modifyResearchReportByResearchReportId', data);
}

// 通过CEM公告ID删除CEM公告
export function removeResearchReportByResearchReportId(data) {
    return instance.post('/admin/research/report/removeResearchReportByResearchReportId', data);
}

// 批量修改标签分类排序
export function modifyTagCategorySortBatch(data) {
    return instance.post('/admin/tag/category/modifyTagCategorySortBatch', data);
}

// 批量给添加产品和快递单号
export function queryResearchReportCategoryByReportId(data) {
    return instance.post('/admin/research/report/queryResearchReportCategoryByReportId', data);
}

// 根据CEM公告类别ID删除CEM公告类别
export function deleteResearchReportCategoryById(data) {
    return instance.post('/admin/research/report/deleteResearchReportCategoryById', data);
}

// 审核提现申请
export function queryMchTransferStatus(data) {
    return instance.post('/admin/wxpay/queryMchTransferStatus', data);
}

// 审核提现申请
export function tryAgainMerchantTransferByTransferId(data) {
    return instance.post('/admin/wxpay/tryAgainMerchantTransferByTransferId', data);
}

// 根据动态id刪除动态详情
export function deleteUserMomentDynamicInfo(data) {
    return instance.post('/admin/userMoment/deleteUserMomentDynamicInfo', data);
}

// 根据动态id刪除动态详情
export function queryQuestionAnswerByQuestionId(data) {
    return instance.post('/admin/questionShow/queryQuestionAnswerByQuestionId', data);
}

// 根据动态id刪除动态详情
export function queryWarehouseLogistic(data) {
    return instance.post('/admin/warehouse/queryWarehouseLogistic', data);
}

// 查询暂停中的发送
export function querySuspendSend(data) {
    return instance.post('/admin/notification/record/querySuspendSend', data);
}

// 暂停进行中的发送
export function suspendOnGoingSend(data) {
    return instance.post('/admin/notification/record/suspendOnGoingSend', data);
}

// 结束进行中的发送
export function stopOnGoingSend(data) {
    return instance.post('/admin/notification/record/stopOnGoingSend', data);
}

// 继续暂停中的发送
export function restartOnGoingSend(data) {
    return instance.post('/admin/notification/record/restartOnGoingSend', data);
}

// 更改该答案是否展示
export function updateUserAnswerIsVisible(data) {
    return instance.post('/admin/user/answer/updateUserAnswerIsVisible', data);
}

export function updateUserAnswerIsVisibleBatch(data) {
    return instance.post('/admin/user/answer/updateUserAnswerIsVisibleBatch', data);
}

// 根据userId和surveyId更改该答案是否展示
export function updateUserAnswerIsVisibleByUserIdAndSurveyId(data) {
    return instance.post('/admin/user/answer/updateUserAnswerIsVisibleByUserIdAndSurveyId', data);
}

// 根据品牌授权id修改品牌授权记录状态
export function modifyStatusByBrandId(data) {
    return instance.post('/admin/brand/auth/modifyStatusByBrandId', data);
}

// 批量删除用户的与指定标签互斥的所有标签
export function removeInconsistentUserTags(data) {
    return instance.post('/admin/user/tag/removeInconsistentUserTags', data);
}

// 根据品牌授权Id分页查询产品信息
export function queryBrandAuthProductListByBrandAuthId(data) {
    return instance.post('/admin/brand/auth/product/queryBrandAuthProductListByBrandAuthAdminId', data);
}

// 新增品牌授权产品关系
export function addBrandAuthProduct(data) {
    return instance.post('/admin/brand/auth/product/addBrandAuthProduct', data);
}

// 删除品牌授权产品关系
export function removeBrandAuthProduct(data) {
    return instance.post('/admin/brand/auth/product/removeBrandAuthProduct', data);
}

// 根据品牌授权Id分页查询问卷信息
export function queryBrandAuthSpecialEventListByBrandAuthId(data) {
    return instance.post('/admin/brand/auth/special/event/queryBrandAuthSpecialEventListByBrandAuthAdminId', data);
}

// 新增品牌授权问卷关系
export function addBrandAuthSpecialEvent(data) {
    return instance.post('/admin/brand/auth/special/event/addBrandAuthSpecialEvent', data);
}

// 删除品牌授权问卷关系
export function removeBrandAuthSpecialEvent(data) {
    return instance.post('/admin/brand/auth/special/event/removeBrandAuthSpecialEvent', data);
}

// 删除调研问卷产品问卷答案
export function deleteUserAnswerByUserIdAndSurveyId(data) {
    return instance.post('/admin/user/answer/deleteUserAnswerByUserIdAndSurveyId', data);
}

// 删除心愿订单
export function removeUserWishByUserIdAndWishId(data) {
    return instance.post('/admin/userWish/removeUserWishByUserIdAndWishId', data);
}

// 根据userId和productId查询答案和问卷
export function selectSurveyWithQuestionAndOptionInfoByUserIdAndProductId(data) {
    return instance.post('/admin/user/comment/selectSurveyWithQuestionAndOptionInfoByUserIdAndProductId', data);
}

// 查询CEM邀请码
export function searchInvitationCode(data) {
    return instance.post('/admin/cem/searchInvitationCode', data);
}

// 新建CEM邀请码
export function addInvitationCode(data) {
    return instance.post('/admin/cem/addInvitationCode', data);
}

// 分页查询品牌主账号
export function queryIBrandAuthAdminByBrandAuthAdminId(data) {
    return instance.post('/admin/brand/auth/admin/queryIBrandAuthAdminByBrandAuthAdminId', data);
}

// 新建主账户邀请码
export function addMasterInvitationCode(data) {
    return instance.post('/admin/brand/auth/admin/addMasterInvitationCode', data);
}

// 根据id修改邀请码
export function updateInvitationCodeByBrandAuthAdminId(data) {
    return instance.post('/admin/brand/auth/admin/updateInvitationCodeByBrandAuthAdminId', data);
}

// 根据id修改邀请码
export function downloadInvitationExcel(data) {
    return instance.post('/admin/user/downloadInvitationExcel', data);
}

// 添加或修改虚拟问卷
export function updateSurveyQuestionMapperInfo(data) {
    return instance.post('/admin/survey/quesiton/mapper/updateSurveyQuestionMapperInfo', data);
}

// 查询虚拟问卷
export function querySurveyQuestionMapperBySurveyId(data) {
    return instance.post('/admin/survey/quesiton/mapper/querySurveyQuestionMapperBySurveyId', data);
}

// 删除虚拟问卷
export function deleteSurveyQuestionMapperInfo(data) {
    return instance.post('/admin/survey/quesiton/mapper/deleteSurveyQuestionMapperInfo', data);
}

// 查询项目列表
export function queryProjectListByProjectVo(data) {
    return instance.post('/admin/project/queryProjectListByProjectVo', data);
}

// 修改项目
export function updateProjectByProjectId(data) {
    return instance.post('/admin/project/updateProjectByProjectId', data);
}

// 复制答案
export function copyUserAnswer(data) {
    return instance.post('/admin/user/answer/copyUserAnswer', data);
}

// 查询虚拟问卷
export function querySurveyQuestionMapperByPage(data) {
    return instance.post('/admin/survey/quesiton/mapper/querySurveyQuestionMapperByPage', data);
}

// 扣除奖励
export function deductReward(data) {
    return instance.post('/admin/user/decreaseMoneyByUserIdAndNumber', data);
}

// 查询资金流水
export function getCapitalFlow(data) {
    return instance.post('/admin/user/money/queryUserMoneyFlowListByUserId', data);
}

// 查询签到问卷
export function querySurveyListByName(data) {
    return instance.post('/admin/survey/querySurveyListByName', data);
}

// 分页查询签到信息
export function querySignInByYearMonthAndDay(data) {
    return instance.post('/admin/sign/in/querySignInByYearMonthAndDay', data);
}

// 删除签到问卷
export function deleteSignInById(data) {
    return instance.post('/admin/sign/in/deleteSignInById', data);
}

// 导出GPT答案结果列表excel
export function downloadUserAnswerFollowUpExcelByUserIdAndQuestionId(data) {
    return instance.post('/admin/download/file/downloadUserAnswerFollowUpExcelByUserIdAndQuestionId', data);
}

// 导出GPT答案结果列表excel
export function queryUserAnswerFollowUpByUserIdAndQuestionIdAndUserSurveyId(data) {
    return instance.post('/admin/user/answer/follow/up/queryUserAnswerFollowUpByUserIdAndQuestionIdAndUserSurveyId', data);
}

// 根据userId、questionId、userSurveyId查询详细信息
export function queryUserAnswerFollowUpDetailByUserIdAndQuestionIdAndUserSurveyId(data) {
    return instance.post('/admin/user/answer/follow/up/queryUserAnswerFollowUpDetailByUserIdAndQuestionIdAndUserSurveyId', data);
}

// 根据时间段分页查询签到信息
export function queryUserSignInByTime(data) {
    return instance.post('/admin/user/sign/in/queryUserSignInByTime', data);
}

// 查询所有签到活动信息
export function querySignInNameList(data) {
    return instance.post('/admin/sign/in/querySignInNameList', data);
}

// 刷新标签体系中所有互斥标签
export function flashUserMutexTags(data) {
    return instance.post('/admin/user/tag/flashUserMutexTags', data);
}

// 修改主账户信息
export function updateBrandAuthAdmin(data) {
    return instance.post('/admin/brand/auth/admin/updateBrandAuthAdmin', data);
}

// 修改子账户信息
export function updateBrandAuth(data) {
    return instance.post('/admin/brand/auth/updateBrandAuth', data);
}

// 根据产品id删除产品问卷缓存
export function deleteProductSurveyCacheByProductId(data) {
    return instance.post('/admin/survey/deleteProductSurveyCacheByProductId', data);
}


export default {
    //根据用户id修改用户手机号
    ModifyUserPhoneByUserId(data) {
        return instance.post(`admin/user/modifyUserPhoneByUserId`, data)
    },
    //获取题目类型列表
    GetQuestionTypeList(data) {
        return instance.get(`admin/question/getQuestionTypeList`)
    },
    //获取品牌列表
    GetBrandList(data) {
        return instance.get(`admin/brand/getBrandList`)
    },
    //获取大类列表
    GetCategoryList_one(data) {
        return instance.get(`admin/category/getCategoryList?level=1`)
    },
    //获取小类列表
    GetCategoryList_two(data) {
        return instance.get(`admin/category/getCategoryList?pid=${data.pid}&level=${data.level}`)
    },
    //获取题目列表
    GetQuestionList(data) {
        return instance.post(`admin/question/getQuestionList`, data)
    },
    // 复制问卷
    CopySurvey(data) {
        return instance.post(`/admin/user/answer/copyUserAnswerBySurveyId`, data)
    },
    //题目上下架/删除接口  上架 = 1 / 下架 = 0 / 删除 = 2
    UpdateQuestionShelfOrDelete(data) {
        return instance.post(`admin/question/updateQuestionShelfOrDelete`, data)
    },
    //新增/修改题目
    InsertOrUpdateQuestion(data) {
        return instance.post(`admin/question/insertOrUpdateQuestion`, data)
    },
    //获取分类列表
    GetCategoryList(data) {
        return instance.get(`admin/category/getCategoryList?categoryName=${data.categoryName}&pageNo=${data.pageNo}&pageSize=${data.pageSize}`)
    },
    //修改分类上级
    UpdateParentCategory(data) {
        return instance.post(`admin/category/updateParentCategory`, data)
    },
    // 添加或修改分类
    InsertOrUpdateCategory(data) {
        return instance.post(`admin/category/insertOrUpdateCategory`, data)
    },
    //删除分类
    DeleteCategory(data) {
        return instance.post(`admin/category/deleteCategory`, data)
    },
    //获取首页/心愿广场轮播图列表
    GetBannerList(data) {
        return instance.get(`admin/banner/getBannerList?type=${data.type}&pageNo=${data.pageNo}&pageSize=${data.pageSize}`)
    },
    //根据type获取数据字典列表
    QueryKvMapListByType(data) {
        return instance.post(`admin/kv_map/queryKvMapListByType`, data)
    },
    //添加数据字典内容
    AddKvMap(data) {
        return instance.post(`admin/kv_map/addKvMap`, data)
    },
    //通过数据字典id修改数据字典
    modifyKvMapByKvMapId(data) {
        return instance.post(`admin/kv_map/modifyKvMapByKvMapId`, data)
    },
    //根据数据字典id删除
    RemoveKvMapByKvMapId(data) {
        return instance.post(`admin/kv_map/removeKvMapByKvMapId`, data)
    },
    //获取产品列表(下拉框)
    GetProductList(data) {
        return instance.get(`admin/product/getProductList`)
    },
    //新增/修改轮播图(下拉框) 首页
    InsertOrUpdateBanner(data) {
        return instance.post(`admin/banner/insertOrUpdateBanner`, data)
    },
    //删除轮播图
    DeleteBannerImage(data) {
        return instance.post(`admin/banner/deleteBannerImage`, data)
    },
    //获取话题列表
    GetTopicList(data) {
        return instance.get(`admin/topic/getTopicList?topicName=${data.topicName}&pageNo=${data.pageNo}&pageSize=${data.pageSize}`)
    },
    //新增/修改关联链接
    OperationTopicResource(data) {
        return instance.post(`admin/topic/operationTopicResource`, data)
    },
    //新增/修改话题
    InsertOrUpdateTopic(data) {
        return instance.post(`admin/topic/insertOrUpdateTopic`, data)
    },
    //删除/上下架话题
    OperationTopic(data) {
        return instance.post(`admin/topic/operationTopic`, data)
    },
    //管理员登录
    Login(data) {
        return instance.post(`admin/login`, data)
    },
    //添加产品
    AddProduct(data) {
        return instance.post(`admin/product/addProduct`, data)
    },
    //根据题目id查看跳题规则
    queryQuestionSkipByQuestionId(data) {
        return instance.post(`admin/questionSkip/queryQuestionSkipByQuestionId`, data)
    },
    //获取序号大于当前题的题目列表
    QueryQuestionListBySurveyIdAndGreaterThanOrEqualToSort(data) {
        return instance.post(`admin/question/queryQuestionListBySurveyIdAndGreaterThanOrEqualToSort`, data)
    },
    //根据题目id添加跳题规则
    AddQuestionSkipByQuestionId(data) {
        return instance.post(`admin/questionSkip/addQuestionSkipByQuestionId`, data)
    },
    //根据跳题规则id修改
    ModifyQuestionSkipByShowId(data) {
        return instance.post(`admin/questionSkip/modifyQuestionSkipByShowId`, data)
    },
    //根据跳题规则id删除
    RemoveQuestionSkipByQuestionSkipId(data) {
        return instance.post(`admin/questionSkip/removeQuestionSkipByQuestionSkipId`, data)
    },
    //查询导入题目库列表接口
    GetImportQuestionList(data) {
        return instance.get(`admin/question/getImportQuestionList?brandId=${data.brandId}&surveyId=${data.surveyId}&classIds=${data.classIds}`)
    },
    //导入题目接口
    ImportQuestionInfo(data) {
        return instance.post(`admin/question/importQuestionInfo`, data)
    },
    //通过题目id修改序号
    ModifyQuestionSortByQuestionId(data) {
        return instance.post(`admin/question/modifyQuestionSortByQuestionId`, data)
    },
    //标签页面：获取标签列表
    QueryTagListByTagSearchVo(data) {
        return instance.post(`admin/tag/queryTagListByTagSearchVo`, data)
    },
    //下拉框：获取标签分类列表
    QueryTagCategoryList(data) {
        return instance.post(`admin/tag/category/queryTagCategoryList`, data)
    },
    //添加标签
    AddTag(data) {
        return instance.post(`admin/tag/addTag`, data)
    },
    //根据标签id修改标签
    ModifyTagByTagId(data) {
        return instance.post(`admin/tag/modifyTagByTagId`, data)
    },
    //通过标签id删除标签
    RemoveTagByTagId(data) {
        return instance.post(`admin/tag/removeTagByTagId`, data)
    },
    //标签分类页面：获取标签分类列表
    QueryTagCategoryListByTagCategorySearchVo(data) {
        return instance.post(`admin/tag/category/queryTagCategoryListByTagCategorySearchVo`, data)
    },
    //通过用户地址id修改用户地址
    ModifyUserAddressByAddressId(data) {
        return instance.post(`admin/rawAddress/modifyUserAddressByAddressId`, data)
    },
    //添加标签分类
    AddTagCategory(data) {
        return instance.post(`admin/tag/category/addTagCategory`, data)
    },
    //根据标签分类id修改标签分类
    ModifyTagCategoryByTagCategoryId(data) {
        return instance.post(`admin/tag/category/modifyTagCategoryByTagCategoryId`, data)
    },
    //通过标签分类id删除标签分类
    RemoveTagCategoryByTagCategoryId(data) {
        return instance.post(`admin/tag/category/removeTagCategoryByTagCategoryId`, data)
    },
    //获取杂货铺商品列表
    GueryVarietyShopList(data) {
        return instance.post(`/admin/product/commodity/queryProductShopCommodityList`, data)
    },
    //新增/修改杂货铺关联产品接口
    InsertOrUpdateProductSpu(data) {
        return instance.post(`admin/productSpu/insertOrUpdateProductSpu`, data)
    },
    //删除杂货铺关联产品接口
    DeleteProductSpu(data) {
        return instance.post(`admin/productSpu/deleteProductSpu`, data)
    },
    //通过产品id查询高频词列表
    GetProductTagByProductId(data) {
        return instance.post(`admin/product/getProductTagByProductId`, data)
    },


    //获取杂货铺商品详情列表
    QueryProductShopList(data) {
        return instance.post(`admin/product/shop/queryProductShopList`, data)
    },
    //添加杂货铺商品详情
    AddProductShop(data) {
        return instance.post(`admin/product/shop/addProductShop`, data)
    },
    //根据杂货铺商品详情id修改杂货铺商品详情
    ModifyProductShopByProductShopId(data) {
        return instance.post(`admin/product/shop/modifyProductShopByProductShopId`, data)
    },
    //通过杂货铺商品详情id删除杂货铺商品详情
    RemoveProductShopByProductShopId(data) {
        return instance.post(`admin/product/shop/removeProductShopByProductShopId`, data)
    },
    //获取杂货铺订单列表
    QueryVarietyShopOrderList(data) {
        return instance.post(`admin/varietyShop/queryVarietyShopOrderList`, data)
    },
    //同步数据
    SyncVarietyShopOrderList(data) {
        return instance.post(`admin/varietyShop/syncVarietyShopOrderList `, data)
    },
    //根据杂货铺商品详情id上下架
    ModifyProductShopActiveByProductShopId(data) {
        return instance.post(`admin/product/shop/modifyProductShopActiveByProductShopId`, data)
    },

    //根据题目id批量添加跳题规则
    AddQuestionSkipByQuestionIdBatch(data) {
        console.log(data)
        return instance.post(`admin/questionSkip/addQuestionSkipByQuestionIdBatch`, data)
    },

    //根据跳题规则id批量修改
    ModifyQuestionSkipByShowIdBatch(data) {
        console.log(data)
        return instance.post(`admin/questionSkip/modifyQuestionSkipByShowIdBatch`, data)
    },

    //心愿订单批量导出
    // UserWishDownload(data){
    //     return instance.get(`admin/userWish/userWishDownload?wishId=${data.wishId}&shipState=${data.shipState}`)
    // },
    // UserWishDownload(data){
    //     return instance.get(`admin/userWish/userWishDownload`,{params:data,headers: {responseType: 'blob'}})
    // },

    //获取AI品牌列表接口
    QueryAiBrandListByLabel(data) {
        return instance.get(`admin/ai/brand/brands/list`, data)
    },
    //添加AI品牌接口
    AddAiBrand(data) {
        return instance.post(`admin/ai/brand/brands/add`, data)
    },
    //更新AI品牌接口
    UpdateAiBrand(data) {
        return instance.post(`admin/ai/brand/brands/update`, data)
    },
    //删除AI品牌接口
    DeleteAiBrand(data) {
        return instance.post(`admin/ai/brand/brands/delete`, data)
    },

}
