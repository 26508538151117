<!-- @format -->

<template>
	<div class="goodsQuestion" v-loading="loading">
		<section class="searchForm">
			<el-row>
				<el-col :span="6">
					<el-button type="primary" @click="addNew">新建题目</el-button>
					<el-button type="primary" @click="leading_in_btn">导入题目</el-button>
				</el-col>
				<el-col :span="6">
					<el-form>
						<el-form-item label="问卷显示样式">
							<el-radio-group v-model="goods.showStyleType" @change="modifyGradeTypeBySurveyId">
								<el-radio-button :label="1">卡片</el-radio-button>
								<el-radio-button :label="2">列表</el-radio-button>
							</el-radio-group>
						</el-form-item>
					</el-form>
				</el-col>
				<el-col :span="12"> 心愿名称：{{ goods.originId }}-{{ goods.name }} </el-col>
			</el-row>
		</section>
		<section class="tableList">
			<el-table :data="tableList">
				<el-table-column prop="question.id" label="ID"></el-table-column>
				<el-table-column prop="question.name" label="题目名称"></el-table-column>
				<el-table-column label="排序">
					<template #default="scope">
						{{ scope.row.question.sort }}
						<el-button size="small" @click="code_update(scope)">修改</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="optionCount" label="选项数量"></el-table-column>
				<el-table-column prop="questionType.name" label="题目类型"></el-table-column>
				<el-table-column prop="id" label="上架/下架">
					<template #default="scope">
						{{ scope.row.question.isActive ? "上架" : "下架" }}
					</template>
				</el-table-column>
				<el-table-column prop="id" label="题目必回答">
					<template #default="scope">
						{{ scope.row.question.isRequired ? "是" : "否" }}
					</template>
				</el-table-column>
				<el-table-column label="特定人群显示设置" width="200rpx">
					<template #default="scope">
						<el-button typeof="primary" size="small" @click="openUserShow(scope.row)">设置</el-button>
						<el-button size="small" @click="getTargetAudienceUseByQuestionId(scope.row)">查看</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="questionSkipCount" label="选项跳题设置" width="120">
					<template #default="scope">
						<el-button size="small" type="primary" @click="question_edit(scope)">编辑</el-button>
					</template>
				</el-table-column>
				<el-table-column label="用户回答" width="100">
					<template #default="scope">
						<el-button size="small" type="success" @click="gotoUserAnswer(scope.row)">查看</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="question.createTime" label="添加时间"></el-table-column>
				<el-table-column prop="id" label="操作" width="200">
					<template #default="scope">
						<el-button size="small" type="primary" @click="changeLine(scope.row)">{{ scope.row.question.isActive
							? "下架" : "上架" }}</el-button>
						<el-button size="small" type="success" @click="modifyQuestion(scope.row)">编辑</el-button>
						<el-button size="small" type="danger" @click="remove(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</section>
		<el-dialog v-model="this.leading_in_dialog" title="导入题目" width="40%" draggable>
			<div style="height: 180px">
				<div class="search_items no_float">
					<span class="title">大类：</span>
					<div>
						<el-select v-model="this.leading_in_categoryId_one" placeholder="请选择大类" size="large"
							@change="category_change">
							<el-option v-for="item in leading_in_category_one_options" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="search_items no_float">
					<span class="title">小类：</span>
					<div>
						<el-select v-model="this.leading_in_categoryId_two" placeholder="请选择小类" size="large">
							<el-option v-for="item in leading_in_category_two_options" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="search_items no_float">
					<span class="title">品牌：</span>
					<div>
						<el-select v-model="this.leading_in_brandId" placeholder="请选择品牌" size="large">
							<el-option v-for="item in leading_in_brand_options" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
			</div>
			<div>
				<el-button type="primary" @click="this.serach_leading_in_question">查询题目</el-button>
			</div>
			<div style="margin-top: 20px; height: 50px">
				<div class="search_items no_float">
					<span class="title">选择题目：</span>
					<div>
						<el-select v-model="this.leading_in_question" placeholder="请选择题目" size="large">
							<el-option v-for="item in leading_in_question_options" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="this.question_canel">取消</el-button>
					<el-button type="primary" @click="this.question_confirm">确认</el-button>
				</span>
			</template>
		</el-dialog>

		<el-dialog v-model="code_update_dialog" title="题目编号修改" width="40%" draggable>
			<div style="margin-top: 20px; height: 50px">
				<div class="search_items no_float">
					<span class="title">题目编号：</span>
					<div>
						<el-input v-model="code_sort" class="w-50 m-2" size="large" placeholder="请输入题目编号"></el-input>
					</div>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="this.code_canel">取消</el-button>
					<el-button type="primary" @click="this.code_confirm">确认</el-button>
				</span>
			</template>
		</el-dialog>

		<!-- 设置用户标签 -->
		<el-dialog v-model="tagDialog" title="特定人群显示设置" width="600px">
			<el-form>
				<el-form-item label="选择人群库">
					<el-tag v-if="peo.name">{{ peo.name }}</el-tag>
					<el-tag class="ml-2" @click="openPeoTypeDialog">选择</el-tag>
				</el-form-item>
				<el-form-item label="是否显示：">
					<el-select v-model="taType">
						<el-option label="显示" value="1"></el-option>
						<el-option label="不显示" value="2"></el-option>
					</el-select>
				</el-form-item>
				<!--                <el-form-item label="与跳题冲突时：">-->
				<!--                    <el-select v-model="ruleType">-->
				<!--                        <el-option label="无操作" value="6"></el-option>-->
				<!--                        <el-option label="向下顺延" value="7"></el-option>-->
				<!--                        <el-option label="跳转" value="1"></el-option>-->
				<!--                    </el-select>-->
				<!--                </el-form-item>-->
				<!--                <el-form-item label="跳转到：" v-if="ruleType==1">-->
				<!--                    <el-select v-model="ruleQuestionId">-->
				<!--                        <template v-for="(item,index) in options">-->
				<!--                            <el-option :label="item.question.name" :value="item.question.id"></el-option>-->
				<!--                        </template>-->

				<!--                    </el-select>-->
				<!--                </el-form-item>-->
			</el-form>
			<template #footer>
				<el-button @click="tagDialog = false">取消</el-button>
				<el-button type="primary" @click="submit">确定</el-button>
			</template>
		</el-dialog>
		<!-- 人群库 -->
		<el-dialog title="选择人群" v-model="PeoTypeDialog">
			<div style="display: flex; justify-content: flex-start">
				<el-input v-model="searchText" style="width: 200px">
				</el-input>
				<el-button @click="searchT">搜索</el-button>
				<el-button @click="clearSearch">清除</el-button>
			</div>

			<el-table :data="tableData" @current-change="handleCurrentChange" highlight-current-row>
				<el-table-column prop="name" label="人群名称" />
				<el-table-column prop="content" label="人群描述" />
			</el-table>
			<el-pagination v-model:currentPage="pageCurrent" v-model:page-size="pageSize" background
				layout="prev, pager, next" :total="total" @current-change="GetTargetAudienceList" />
		</el-dialog>
		<!--人群显示列表-->
		<el-dialog title="人群显示规则" v-model="PeoRuleDialog">
			<el-table :data="ruleList">
				<el-table-column prop="id" label="id" />
				<el-table-column prop="targetAudience.name" label="人群类型" />
				<el-table-column label="是否显示">
					<template #default="scope">
						{{ (scope.row.taType === 2 && "不显示") || (scope.row.tatype !== 2 && "显示") }}
					</template>
				</el-table-column>
				<!--                <el-table-column label="跳题冲突时">-->
				<!--                    <template #default="scope">-->
				<!--                        {{(scope.row.ruleType==1&&'跳转')||(scope.row.ruleType==6&&'无操作')||(scope.row.ruleType==7&&'顺延')||(!scope.row.ruleType&&'无')}}-->
				<!--                    </template>-->
				<!--                </el-table-column>-->
				<el-table-column label="操作">
					<template #default="scope">
						<el-button @click="removeData(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>
<script>
import {
	UpdateQuestionShelfOrDelete,
	GetTargetAudienceList,
	QuerySurveyInfoBySurveyTypeAndOriginId,
	insertTargetAudienceUseByQuestionId,
	getTargetAudienceUseByQuestionId,
	deleteTargetAudienceUseByQuestionId,
	ModifyQuestionSkipByShowIdBatch,
	modifyGradeTypeBySurveyId,
} from "../../config/Api";
import global_api from "@/config/Api";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
	data() {
		return {
			id: "",
			b: "",
			name: "",
			loading: false,
			tableList: [],
			goods: {},

			leading_in_dialog: false, //导入
			leading_in_categoryId_one: "", //大类id
			leading_in_categoryId_two: "", //小类id
			leading_in_brandId: "", //品牌id
			leading_in_question: "", // 导入的题目id
			leading_in_category_one_options: [],
			leading_in_category_two_options: [],
			leading_in_brand_options: [], //品牌
			leading_in_question_options: [], //题目

			code_update_dialog: false,
			code_sort: 0,
			code_questionId: "",
			code_surveyId: "",

			tagDialog: false,
			pageCurrent: 1,
			pageSize: 10,
			total: 1,
			PeoTypeDialog: false,
			tableData: [],
			peo: {},
			ruleType: "6",
			ruleQuestionId: "",
			options: [],
			taType: "2",

			PeoRuleDialog: false,
			ruleList: [],
			surveyQuestionSkipDTOS: [],
			searchText: "",
		};
	},
	mounted() {
		let quety = this.$route.query;
		this.id = quety.id;
		this.b = quety.b;
		this.name = quety.name;
		this.getData();
		this.GetBrandList();
		this.GetCategoryList_one();
	},
	methods: {
		// 打开特定用户显示设置弹窗
		openUserShow(row) {
			this.tagDialog = true;
			let optionsList = this.tableList;
			let options = [];
			for (let i in optionsList) {
				if (optionsList[i].question.sort > row.question.sort) {
					options.push(optionsList[i]);
				}
			}
			this.questionId = row.question.id;

			this.surveyQuestionSkipDTOS = row.surveyQuestionSkipDTOS;
			let DTOs = row.surveyQuestionSkipDTOS;
			let rule = "";
			for (let i in DTOs) {
				let options = DTOs[i].surveyQuestionSkipOptions;
				let skips = DTOs[i].surveyQuestionSkipResults;
				for (let n in options) {
					if (options[n].condition == 8) {
						rule = skips[0];
					}
				}
			}
			if (rule) {
				console.log(rule);
				this.ruleType = rule.type + "";
				this.ruleQuestionId = Number(rule.value);
			} else {
				this.ruleType = "6";
				this.ruleQuestionId = "";
			}

			this.options = options;
			this.peo = {};
		},
		// 打开人群库选择界面
		openPeoTypeDialog(row) {
			this.PeoTypeDialog = true;
			this.pageCurrent = 1;
			this.tableData = [];
			this.GetTargetAudienceList();
		},
		// 获取人群库信息
		GetTargetAudienceList() {
			let param = {
				pageSize: this.pageSize,
				pageNo: this.pageCurrent,
				targetName: this.searchText,
			};
			this.loading = true;
			GetTargetAudienceList({ params: param }).then(
				res => {
					this.loading = false;
					this.tableData = res.result;
					this.total = res.data.totalCount;
				},
				err => {
					this.loading = false;
					ElMessage(`获取人群库列表失败：${err.msg}`);
				}
			);
		},
		// 添加规则
		submit() {
			if (!this.peo || !this.peo.id) return ElMessage.error("请选择人群");
			if (!this.ruleType) return ElMessage.error("请选择跳题冲突时的处理方案");
			if (this.ruleType == "1" && !this.ruleQuestionId) return ElMessage.error("请选择要跳转的题目");

			this.insertTargetAudienceUseByQuestionId();
		},
		// 添加人群显示条件
		insertTargetAudienceUseByQuestionId() {
			insertTargetAudienceUseByQuestionId({
				questionId: this.questionId,
				type: this.taType,
				targetAudienceId: this.peo.id,
			}).then(
				res => {
					// 判断是添加还是修改
					let DTOs = this.surveyQuestionSkipDTOS;
					let flag = false;
					for (let i in DTOs) {
						let options = DTOs[i].surveyQuestionSkipOptions;
						for (let n in options) {
							if (options[n].condition == 8) {
								flag = true;
							}
						}
					}
					if (flag) {
						// this.ModifyQuestionSkipByShowIdBatch()
					} else {
						// this.AddQuestionSkipByQuestionIdBatch();
					}

					this.tagDialog = false;
				},
				err => {
					ElMessage.error(err.msg);
				}
			);
		},
		// 添加规则
		AddQuestionSkipByQuestionIdBatch() {
			let items_array = [
				{
					surveyId: this.goods.id,
					questionId: this.questionId,
					logic: 0,
					type: 0,
					surveyQuestionSkipOptions: [
						{
							optionId: 0,
							value: "",
							condition: 8,
						},
					],
					surveyQuestionSkipResults: [
						{
							value: this.ruleQuestionId,
							type: this.ruleType,
						},
					],
				},
			];

			global_api.AddQuestionSkipByQuestionIdBatch({ surveyQuestionSkip: JSON.stringify(items_array) }).then(
				res => {
					this.getData();
				},
				err => {
					this.getData();
				}
			);
		},
		// 修改规则
		ModifyQuestionSkipByShowIdBatch() {
			let items_array = this.surveyQuestionSkipDTOS;
			for (let i in items_array) {
				items_array[i]["questionSkipId"] = items_array[i]["id"];
				let options = items_array[i].surveyQuestionSkipOptions;

				if (options[0].condition == 8) {
					items_array[i].surveyQuestionSkipResults[0].value = this.ruleQuestionId;
					items_array[i].surveyQuestionSkipResults[0].type = this.ruleType;
				}
			}

			global_api.ModifyQuestionSkipByShowIdBatch({ surveyQuestionSkip: JSON.stringify(items_array) }).then(
				res => {
					this.getData();
				},
				err => {
					this.getData();
				}
			);
		},
		// 查看人群显示规则
		getTargetAudienceUseByQuestionId(row) {
			getTargetAudienceUseByQuestionId({ params: { questionId: row.question.id } }).then(
				res => {
					this.PeoRuleDialog = true;

					let DTOs = row.surveyQuestionSkipDTOS || this.surveyQuestionSkipDTOS;
					this.surveyQuestionSkipDTOS = DTOs;
					let rule = "";
					for (let i in DTOs) {
						let options = DTOs[i].surveyQuestionSkipOptions;
						let skips = DTOs[i].surveyQuestionSkipResults;
						for (let n in options) {
							if (options[n].condition == 8) {
								rule = skips[0];
							}
						}
					}
					for (let i in res.result) {
						res.result[i].ruleType = rule.type;
						res.result[i].ruleQuestionId = rule.value;
					}
					console.log(res.result);
					this.ruleList = res.result;
				},
				err => {
					this.ruleList = [];
					ElMessage.error(err.msg);
				}
			);
		},
		// 删除人群显示规则
		removeData(row) {
			deleteTargetAudienceUseByQuestionId({ targetAudienceUseId: row.id }).then(
				res => {
					this.getTargetAudienceUseByQuestionId({ question: { id: row.originId } });
				},
				err => {
					ElMessage.error(err.msg);
				}
			);
		},
		// 选择人群
		handleCurrentChange(e) {
			if (e) {
				this.peo = e;
				this.PeoTypeDialog = false;
			}
		},
		// 搜索
		searchT() {
			this.pageCurrent = 1;
			this.GetTargetAudienceList();
		},
		// 清除搜索
		clearSearch() {
			this.searchText = "";
			this.pageCurrent = 1;
			this.GetTargetAudienceList();
		},

		code_update(scope) {
			//修改题目编号按钮
			this.code_sort = scope.row.question.sort;
			this.code_questionId = scope.row.question.id;
			this.code_surveyId = scope.row.question.surveyId;
			this.code_update_dialog = true;
		},
		code_canel() {
			this.code_update_dialog = false;
		},
		code_confirm() {
			let param = {
				questionId: this.code_questionId,
				sort: this.code_sort,
				surveyId: this.code_surveyId,
			};
			console.log(param);
			global_api.ModifyQuestionSortByQuestionId(param).then(res => {
				if (res.code != 0) {
					ElMessage.error("通过题目id修改序号错误");
				}
				this.code_update_dialog = false;
				ElMessage({
					type: "success",
					message: "修改成功",
				});
				this.getData();
			});
		},

		question_edit(scope) {
			sessionStorage.setItem("question_item", JSON.stringify(scope.row));
			this.$router.push("/Cherished_desire_list/Subject_list/jump");
		},

		leading_in_btn() {
			this.leading_in_dialog = true;
			this.leading_in_categoryId_one = "";
			this.leading_in_categoryId_two = "";
			this.leading_in_brandId = "";
			this.leading_in_question = "";
		},

		question_canel() {
			this.leading_in_dialog = false;
			this.leading_in_categoryId_one = "";
			this.leading_in_categoryId_two = "";
			this.leading_in_brandId = "";
			this.leading_in_question = "";
		},

		question_confirm() {
			let param = {
				questionId: this.leading_in_question,
				surveyId: this.goods.id,
			};
			global_api.ImportQuestionInfo(param).then(res => {
				if (res.code != 0) {
					ElMessage.error("查询导入题目库列表错误");
				}
				this.leading_in_dialog = false;
				ElMessage({
					type: "success",
					message: "导入成功",
				});
				this.getData();
				// console.log(res)
			});
		},

		GetBrandList() {
			//获取品牌列表
			global_api.GetBrandList().then(res => {
				if (res.code != 0) {
					ElMessage.error("获取品牌列表错误");
				}
				this.leading_in_brand_options = res.result;
				this.leading_in_brand_options.unshift({ id: 0, name: "不选择" });
			});
		},

		GetCategoryList_one() {
			//获取大类列表
			global_api.GetCategoryList_one().then(res => {
				if (res.code != 0) {
					ElMessage.error("获取大类列表错误");
				}
				this.leading_in_category_one_options = res.result;
			});
		},

		GetCategoryList_two(pid, level) {
			//获取小类列表
			global_api.GetCategoryList_two({ pid, level: level }).then(res => {
				if (res.code != 0) {
					ElMessage.error("获取小类列表错误");
				}
				this.leading_in_category_two_options = res.result;
				console.log(res);
			});
		},

		category_change() {
			this.leading_in_categoryId_two = "";
			this.GetCategoryList_two(this.leading_in_categoryId_one, 2);
		},

		serach_leading_in_question() {
			//查询题目
			console.log(this.surveyId);
			let param = {
				brandId: this.leading_in_brandId,
				surveyId: this.goods.id,
			};
			if (!this.leading_in_categoryId_two) {
				if (this.leading_in_category_two_options.length > 0) {
					let leading_in_categoryId_two = [];
					for (let i = 0; i < this.leading_in_category_two_options.length; i++) {
						leading_in_categoryId_two.push(this.leading_in_category_two_options[i].id);
					}
					param.classIds = leading_in_categoryId_two.join(",");
				} else {
					param.classIds = this.leading_in_categoryId_one;
				}
			} else {
				param.classIds = this.leading_in_categoryId_two;
			}
			global_api.GetImportQuestionList(param).then(res => {
				if (res.code != 0) {
					ElMessage.error("查询导入题目库列表错误");
				}
				let leading_in_question_options = res.result;
				let arr = [
					"",
					"单选",
					"多选",
					"简答",
					"排序",
					"打分",
					"比重",
					"滑条",
					"矩阵单选",
					"矩阵多选",
					"图片",
					"NPS",
					"种草",
					"购买意愿",
				];
				for (let i = 0; i < leading_in_question_options.length; i++) {
					leading_in_question_options[i].name =
						leading_in_question_options[i].name + "(" + arr[leading_in_question_options[i].type] + ")";
				}
				this.leading_in_question_options = leading_in_question_options;
			});
		},
		// 获取问卷信息
		getData() {
			let param = {
				originId: this.id,
				surveyName: this.name,
				surveyType: 1,
			};
			this.loading = true;
			QuerySurveyInfoBySurveyTypeAndOriginId(param).then(res => {
				this.loading = false;
				this.goods = res.result;
				this.getList();
			});
		},
		// 获取题目
		getList() {
			let param = {
				surveyId: this.goods.id,
				pageNo: 1,
				pageSize: 500,
			};
			this.loading = true;
			global_api.GetQuestionList(param).then(
				res => {
					this.loading = false;
					this.tableList = res.result;
				},
				err => {
					this.loading = false;
					ElMessage.error(`获取失败:${err.msg}`);
				}
			);
		},
		// 删除
		remove(row) {
			let param = {
				questionId: row.question.id,
				type: 2,
				surveyId: row.question.surveyId,
			};
			this.loading = true;
			UpdateQuestionShelfOrDelete(param).then(
				res => {
					this.loading = false;
					this.getList();
				},
				err => {
					this.loading = false;
					ElMessage.error(`删除失败:${err.msg}`);
				}
			);
		},
		// 上下架
		changeLine(row) {
			let param = {
				questionId: row.question.id,
				type: row.question.isActive ? 0 : 1,
				surveyId: row.question.surveyId,
			};
			this.loading = true;
			UpdateQuestionShelfOrDelete(param).then(
				res => {
					this.loading = false;
					this.getList();
				},
				err => {
					this.loading = false;
					ElMessage.error(`上下架失败:${err.msg}`);
				}
			);
		},
		// 新建题目
		addNew() {
			this.$router.push({
				path: "/edit/question",
				query: { type: "add", surveyId: this.goods.id, bt: 3, bid: this.id, b: this.b, name: this.name },
			});
		},
		// 编辑题目
		modifyQuestion(row) {
			sessionStorage.setItem("subject_item", JSON.stringify(row));
			this.$router.push({
				path: "/edit/question",
				query: { type: "edit", surveyId: this.goods.id, bt: 3, bid: this.id, b: this.b, name: this.name },
			});
		},
		// 查看用户回答
		gotoUserAnswer(row) {
			console.log(row);
			localStorage.setItem("question", JSON.stringify(row));
			this.$router.push({ path: "/useranswer" });
		},
		// 修改问卷显示样式
		modifyGradeTypeBySurveyId(type) {
			console.log(type);
			this.loading = true;
			let param = {
				surveyId: this.goods.id,
				type: 1,
				showStyleType: type
			};
			modifyGradeTypeBySurveyId(param).then(
				res => {
					this.loading = false;
					this.getData();
				},
				err => {
					this.loading = false;
					ElMessage.error(err.msg);
				}
			);
		},
	},
};
</script>
