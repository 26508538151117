<template>
  <div>
    <section class="searchForm">
      <el-form>
        <el-form-item>
          <el-button type="primary" @click="openDialog">添加</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section>
      <el-table :data="tableData">
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="产品名" prop="title"></el-table-column>
        <el-table-column label="高频词">
          <template #default="scope">
            {{scope.row.highFrequency}}
            <el-button type="primary" size="small" @click="GetProductTagByProductId(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="denger" size="small" @click="remove(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="cunPage"
        :page-size="10"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="getList"
      />
    </section>

    <!-- 添加 -->
    <el-dialog v-model="dialog" title="编辑">
      <selecegoods @select="select"/>
      <template #footer>
        <el-button @click="dialog = false">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </template>
    </el-dialog>
    <!-- 选择 -->
    <el-dialog v-model="dialog1" title="选择高频词">
      <el-form label-width="100px" label-suffix=":">
        <el-form-item label="高频词">
          <el-checkbox-group v-model="tag" :max="4">
            <el-checkbox v-for="item in tags" :key="item.name" :label="item.name" :value="item.name" />
          </el-checkbox-group>
          <el-input style="width:150px" v-model="addValue">
            <template #append>
              <el-button @click="addValueC">添加</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="commentArr.push({})">添加</el-button>
        </el-form-item>
        <template v-for="(item,index) in commentArr" :key="index">
          <el-form-item :label="`评论${index+1}`">
            <!-- <el-input v-model="item.Id"></el-input> -->
            <el-button @click="openDialog2(index)">选择评论</el-button>
            <div>{{item.Id}}</div>
          </el-form-item>
          <el-form-item label="额外心愿币">
            <el-input v-model="item.coin"></el-input>
            <el-button @click="commentArr.splice(index,1)">删除</el-button>
          </el-form-item>
        </template>
      </el-form>
      <template #footer>
        <el-button @click="dialog1 = false">取消</el-button>
        <el-button type="primary" @click="submit1">确定</el-button>
      </template>
    </el-dialog>
    <!-- 评论选择 -->
    <el-dialog v-model="dialog2" title="选择评论">
      <el-form inline>
        <el-form-item label="用户ID">
          <el-input v-model="formData.userId"></el-input>
        </el-form-item>
        <el-form-item label="用户昵称">
          <el-input v-model="formData.userNickName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getComList">搜索</el-button>
        </el-form-item>
      </el-form>
      <el-table v-loading="loading" :data="comData" highlight-current-row @current-change="handleCurrentChange">
        <el-table-column label="ID" prop="id" width="120"></el-table-column>
        <el-table-column label="用户id'" prop="userId" width="120"></el-table-column>
        <el-table-column label="用户昵称" prop="user.nickname" width="120"></el-table-column>
        <el-table-column label="评论" prop="content"></el-table-column>
      </el-table>
      <el-pagination
        v-model:currentPage="cunPage1"
        page-size="10"
        background
        layout="prev, pager, next"
        :total="total1"
        @current-change="getComList"
      />
      <template #footer>
        <el-button @click="dialog2=false">取消</el-button>
        <el-button type="primary" @click="submit2">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import global_api,{QueryProductListByProductSearchVo,ModifyProductByProductId,QueryUserCommentListByUserCommentSearchVo} from '@/config/Api'
import { ElMessage, ElSubMenu } from 'element-plus'
import selecegoods from '@/components/selectGoods.vue'
export default {
  data(){
    return{
      dialog: false,
      tableData: [],
      cunPage: 1,
      total: 0,

      data: [],
      productId: '',
      tags: [],
      tag: [],
      addValue: '',

      dialog1: false,
      commentArr: [],

      dialog2: false,
      saveIndex: '',
      cunPage1: 1,
      total1: 1,
      comData: [],
      tempItem: {},
      formData:{},
      goods:"",
    }
  },
  components: {selecegoods},
  mounted(){
    this.getList();
  },
  methods:{
    // 获取首页显示产品
    getList(){
      this.loading = true;
      let param = {
        cunPage: this.cunPage,
        pageSize: 10,
        isIndexShow: 1,
      }
      QueryProductListByProductSearchVo(param).then(res=>{
        this.tableData = res.result;
        this.total = res.data.totalCount;
        this.loading = false;
      },err=>{
        ElMessage.error('获取产品失败');
        this.loading = false;
      })
    },
    // 打开编辑弹窗
    openDialog(){
      this.dialog = true;
      this.productId = '';
      this.tags = [];
      this.tag = [];
    },
    select(e){
      console.log(e);
      this.goods = e;
    },
    // 确定编辑
    submit(){
      let item = this.goods;
      console.log(item);
      if(!item) {
        return ElMessage.error('请选择产品');
      }
      let param = {
        allowCommentTime: item.allowCommentTime,
        amount: item.projectRepDTOS[0]?.amount || 0,
        brandId: item.brandId,
        brandName: item.brand.name,
        description: item.description,
        isIndexShow: 1,
        isVisiable: item.isVisiable,
        pid: item.pid,
        price: item.price,
        productId: item.id,
        requireComment: item.requireComment,
        source: item.source,
        subtitle: item.subtitle,
        tagCategoryId: item.tagCategoryId,
        title: item.title,
        highFrequency: '',
      }
      ModifyProductByProductId(param).then(res=>{
        this.getList();
        this.dialog = false;
        this.goods = '';
      },err=>{

      })
    },
    // 删除
    remove(row){
      let item = row;
      let param = {
        allowCommentTime: item.allowCommentTime,
        amount: item.projectRepDTOS[0]?.amount || 0,
        brandId: item.brandId,
        brandName: item.brand.name,
        description: item.description,
        isIndexShow: 0,
        isVisiable: item.isVisiable,
        pid: item.pid,
        price: item.price,
        productId: item.id,
        requireComment: item.requireComment,
        source: item.source,
        subtitle: item.subtitle,
        tagCategoryId: item.tagCategoryId,
        title: item.title,
      }
      ModifyProductByProductId(param).then(res=>{
        this.getList();
        this.loading = false;
      },err=>{

      })
    },
    // 打开
    // 获取高频词
    GetProductTagByProductId(row){
      this.item = row;
      let highFrequency = row.highFrequency?JSON.parse(row.highFrequency):{};
      global_api.GetProductTagByProductId({productId:row.id}).then(res=>{
        if(res.result){
          this.dialog1 = true;
          this.tags = JSON.parse(res.result);
          this.tag = highFrequency.highFrequency;
          for(let i in this.tag){
            let flag = true;
            for(let n in this.tags){
              if(this.tag[i] === this.tags[n].name) flag = false;
            }
            if(flag){
              this.tags.push({name:this.tag[i],selected:true});
            }
          }

          this.addValue = '';
          this.commentArr = highFrequency.comments?highFrequency.comments:[];
          console.log(this.commentArr);
        }else{
          this.dialog1 = true;
          this.tags = [];
          this.addValue = '';
        }
      },err=>{
        this.tags = [];
      })
    },
    // 添加高频词
    addValueC(){
      if(!this.addValue&&this.addValue!==0) return;
      this.tags.push({name:this.addValue,selected:false});
      this.addValue='';
    },
    // 确定编辑
    submit1(){
      let item = this.item;
      let highFrequency = {
        highFrequency: this.tag,
        comments: this.commentArr,
      }
      let param = {
        allowCommentTime: item.allowCommentTime,
        amount: item.projectRepDTOS[0]?.amount || 0,
        brandId: item.brandId,
        brandName: item.brand.name,
        description: item.description,
        isIndexShow: 1,
        isVisiable: item.isVisiable,
        pid: item.pid,
        price: item.price,
        productId: item.id,
        requireComment: item.requireComment,
        source: item.source,
        subtitle: item.subtitle,
        tagCategoryId: item.tagCategoryId,
        title: item.title,
        highFrequency: JSON.stringify(highFrequency),
      }
      ModifyProductByProductId(param).then(res=>{
        this.getList();
        this.dialog1 = false;
      },err=>{

      })
    },

    // 打开评论选择弹窗
    openDialog2(index){
      this.saveIndex = index;
      this.dialog2 = true;
      this.cunPage1 = 1,
      this.total1 = 1;
      this.comData = [];
      this.tempItem = {};
      this.formData = {};
      this.getComList();
    },
    // 获取列表
    getComList(){
      let param = {
        productId: this.item.id,
        cunPage: this.cunPage1,
        pageSize: 10,
        ...this.formData,
      }
      this.loading = true;
      QueryUserCommentListByUserCommentSearchVo(param).then(res=>{
        this.loading = false;
        this.total1 = res.data.totalCount;
        this.comData = res.result;
      },err=>{
        this.loading = false;
      })
    },
    // 选择
    handleCurrentChange(e){
      console.log(e);
      this.tempItem = e;
    },
    // 
    submit2(){
      let commentArr = this.commentArr;
      if(this.tempItem.id){
        commentArr[this.saveIndex].Id = this.tempItem.id;
        this.dialog2 = false;
      }else{
        ElMessage.error('请选择评论')
      }
    }

  }
}
</script>