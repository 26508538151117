import instance from "@/plugins/ajax";

// 批量添加产品
export function addProductBatch(data) {
	return instance.post('/admin/product/addProductBatch',data);
}
// 通过心愿订单id修改地址合规状态为未提醒
export function modifyUserWishAddrNormStatusByUserWishIds(data) {
	return instance.post('/admin/userWish/modifyUserWishAddrNormStatusByUserWishIds',data);
}
// 通过心愿订单id修改地址合规状态为未提醒
export function queryUserIconByUserId(data) {
	return instance.post('/admin/user/queryUserIconByUserId',data);
}
// 通过心愿订单号和物流单号查询已经发货的订单
export function queryUserWishShipByOrderNumAndShip(data) {
	return instance.post('/admin/userWish/queryUserWishShipByOrderNumAndShip',data);
}
// 通过心愿订单号和物流单号查询已经发货的订单
export function queryProductInfoAndProjectByPids(data) {
	return instance.post('/admin/product/queryProductInfoAndProjectByPids',data);
}
// 批量发货
export function updateUserWishShipBatch(data) {
	return instance.post('/admin/userWish/updateUserWishShipBatch',data);
}
// 通过心愿订单号查询订单
export function queryUserWishShipByOrderNum(data) {
	return instance.post('/admin/userWish/queryUserWishShipByOrderNum',data);
}
// 根据品牌名，获取品牌id
export function queryBrandIdByName(data) {
	return instance.post('/admin/brand/queryBrandIdByName',data);
}
// 批量给添加产品和快递单号
export function addBoxProductAndLogisticBatch(data) {
	return instance.post('/admin/import/file/addBoxProductAndLogisticBatch',data);
}
// 给cem公告添加标签类别信息
export function addResearchReportCategory(data) {
	return instance.post('/admin/research/report/addResearchReportCategory',data);
}
// 获取用户申请解封列表
export function queryUserUnbannedListBySearchVo(data) {
	return instance.post('/admin/user/banned/queryUserUnbannedListBySearchVo',data);
}
// 通过申请解封id修改申请解封记录
export function modifyUserUnbannedRequestById(data) {
	return instance.post('/admin/user/banned/modifyUserUnbannedRequestById',data);
}
// 通过申请解封id修改申请解封记录
export function insertUserObtainMoneyList(data) {
	return instance.post('/admin/user/money/insertUserObtainMoneyList',data);
}
// 用户获得奖励展示页面：获取用户获得奖励列表
export function queryUserObtainMoneyListBySearchVo(data) {
	return instance.post('/admin/user/money/queryUserObtainMoneyListBySearchVo',data);
}
// 根据用户获得奖励id修改用户获得奖励记录
export function operatorReviewMoneyByMoneyId(data) {
	return instance.post('/admin/user/money/operatorReviewMoneyByMoneyId',data);
}
// 终审用户获得奖励接口, 根据用户获得奖励id修改用户获得奖励记录
export function reviewerReviewMoneyByMoneyId(data) {
	return instance.post('/admin/user/money/reviewerReviewMoneyByMoneyId',data);
}
// 查看用户申请提现列表（后台）
export function queryUserMoneyWithdrawalListBySearchVo(data) {
	return instance.post('/admin/user/money/queryUserMoneyWithdrawalListBySearchVo',data);
}
// 审核提现申请
export function reviewerReviewMoneyWithdrawal(data) {
	return instance.post('/admin/user/money/reviewerReviewMoneyWithdrawal',data);
}
// 根据用户获得奖励id修改用户获得奖励记录(批量)
export function operatorReviewMoneyByMoneyIdBatch(data) {
	return instance.post('/admin/user/money/operatorReviewMoneyByMoneyIdBatch',data);
}
// 终审用户获得奖励接口(批量)
export function reviewerReviewMoneyByMoneyIdBatch(data) {
	return instance.post('/admin/user/money/reviewerReviewMoneyByMoneyIdBatch',data);
}
// 终审用户获得奖励接口(批量)
export function reviewerReviewMoneyWithdrawalBatch(data) {
	return instance.post('/admin/user/money/reviewerReviewMoneyWithdrawalBatch',data);
}
// 终审用户获得奖励接口(批量)
export function downloadUserTagExcelByUserIdsPost(data) {
	return instance.post('/admin/download/file/downloadUserTagExcelByUserIdsPost',data);
}
// 终审用户获得奖励接口(批量)
export function queryNewYearResolutionListBySearchVo(data) {
	return instance.post('/admin/activity/queryNewYearResolutionListBySearchVo',data);
}
// 管理员修改仓库物流信息
export function modifyWarehouseLogisticByAdmin(data) {
	return instance.post('/admin/warehouse/modifyWarehouseLogisticByAdmin',data);
}
// 管理员修改仓库物流信息
export function queryGoodsOrderList(data) {
	return instance.post('/admin/wxpay/queryGoodsOrderList',data);
}
// 获取品牌账户列表
export function queryBrandAuthListBySearchVo(data) {
	return instance.post('/admin/brand/auth/queryBrandAuthListBySearchVo',data);
}
// 新增问卷
export function addSurvey(data) {
	return instance.post('/admin/survey/addSurvey',data);
}
// 批量增加签到信息
export function addSignInList(data) {
	return instance.post('/admin/sign/in/addSignInList',data);
}
// 批量修改签到信息
export function modifySignInList(data) {
	return instance.post('/admin/sign/in/modifySignInList',data);
}
// 添加GPT字典内容
export function addGptMap(data) {
	return instance.post('/admin/kv_map/addGptMap',data);
}
// 分页查看公告列表
export function announcementInfo(data) {
	return instance.get('/admin/announcementInfo/page',data);
}
// 新增公告
export function announcementInfoInsert(data) {
	return instance.post('/admin/announcementInfo/insert',data);
}
// 修改公告
export function announcementInfoUpdate(data) {
	return instance.put('/admin/announcementInfo/update',data);
}
// 分页查看常见问题列表
export function commonproblems(data) {
	return instance.get('/admin/common/problems/page',data);
}
// 新增常见问题
export function commonproblemsinsert(data) {
	return instance.post('/admin/common/problems/insert',data);
}
// 修改常见问题
export function commonproblemsupdate(data) {
	return instance.put('/admin/common/problems/update',data);
}