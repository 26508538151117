<template>
  <div class="Variety_detail_edit" v-loading="this.loading">
    <div class="main">
      <section class="search_btn">
        <div class="selected_items">
          <span class="title"><span class="red">*</span>产品名称：</span>
          <div>
            <el-select v-model="item_obj.productId" filterable multiple  @change="selectChange">
              <el-option
                  v-for="item in this.product_options"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id+''">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="selected_items" style="height: auto!important;">
          <span class="title">高频词：</span>
          <div style="width: 800px">
            <el-checkbox v-model="item.isSelected" :label="item.name" size="large" v-for="(item,index) in this.GPC_options">
              <div>
                <span v-show="item.name">{{item.name}}</span>
              </div>
            </el-checkbox>
            <el-input
              v-if="inputVisible"
              v-model="inputValue"
              @blur="handleInputConfirm"
            />
            <el-button v-else class="button-new-tag ml-1" size="small" @click="showInput">
              + New Tag
            </el-button>
          </div>
        </div>
        <div class="selected_items">
          <span class="title">杂货铺商品名称：</span>
          <div>
            <el-select v-model="item_obj.spuId">
              <el-option
                  v-for="item in this.Variety_shop_options"
                  :key="item.spuId"
                  :label="item.title"
                  :value="item.spuId">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="selected_items">
          <span class="title">评测发放名额：</span>
          <div>
            <el-input v-model="item_obj.sentCount" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="selected_items">
          <span class="title">发货：</span>
          <div>
            <el-input v-model="item_obj.logistics" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="selected_items">
          <span class="title">保障：</span>
          <div>
            <el-input v-model="item_obj.guarantee" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="selected_items">
          <span class="title">好评：</span>
          <div>
            <el-input v-model="item_obj.goodRate" placeholder="请输入"></el-input>
          </div>
          <span class="red" style="margin-left: 10px;font-size: 14px">(好中差评，累加等于10)</span>
        </div>
        <div class="selected_items">
          <span class="title">中评：</span>
          <div>
            <el-input v-model="item_obj.mediumRate" placeholder="请输入"></el-input>
          </div>
          <span class="red" style="margin-left: 10px;font-size: 14px">(好中差评，累加等于10)</span>
        </div>
        <div class="selected_items">
          <span class="title">差评：</span>
          <div>
            <el-input v-model="item_obj.badRate" placeholder="请输入"></el-input>
          </div>
          <span class="red" style="margin-left: 10px;font-size: 14px">(好中差评，累加等于10)</span>
        </div>
        <div class="selected_items" v-for="(item,index) in item_obj.weight" :key="index">
          <span class="title" v-show="index==0">净含量：</span>
          <span class="title" v-show="index!=0">&nbsp;</span>
          <img src="../../assets/cur_icon_delete.png" class="btn" @click="delete_weight(index)">
          <div>
            <el-input v-model="item.weight" placeholder="请输入"></el-input>
          </div>
          <img src="../../assets/cur_icon_add.png" class="btn" @click="add_weight(index)">
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>标题：</span>
          <div>
            <el-input v-model="item_obj.title" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="start_items">
          <span class="title"><span class="red">*</span>副标题：</span>
          <div class="textarea">
            <el-input v-model="item_obj.subtitle" type="textarea" :rows="10"/>
          </div>
        </div>
        <div class="start_items">
          <span class="title"><span class="red">*</span>推荐理由：</span>
          <div class="textarea">
            <el-input v-model="item_obj.advisoryReasons" type="textarea" :rows="10"/>
          </div>
        </div>
        <div class="start_items">
          <span class="title"><span class="red">*</span>显示类型：</span>
          <div class="textarea">
            <el-select v-model="item_obj.type">
              <el-option label="着重显示好评率" :value="1"/>
              <el-option label="着重显示测评人数" :value="2"/>
            </el-select>
          </div>
        </div>
      </section>
      <section>
        <div style="font-weight: bold;font-size: 18px">参数设置</div>
        <div class="selected_items cs" v-for="(item,index) in item_obj.productParameter" :key="index">
          <img src="../../assets/cur_icon_delete.png" class="btn" @click="delete_productParameter(index)">
          <el-input v-model="item.key" placeholder="请输入"></el-input>
          <el-input v-model="item.value" placeholder="请输入"></el-input>
          <img src="../../assets/cur_icon_add.png" class="btn" @click="add_productParameter(index)">
        </div>
      </section>
      <section>
        <div class="selected_items">
          <span class="title"></span>
          <div>
            <el-button type="primary" @click="cancel">取消</el-button>
            <el-button type="danger" v-if="type == 'add'" @click="AddProductShop">确认</el-button>
            <el-button type="danger" v-if="type == 'edit'" @click="ModifyProductShopByProductShopId">确认</el-button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import {queryTagNumberByProductIdAndTagName} from '../../config/Api'
import { ElMessage, ElMessageBox } from 'element-plus'
import {Get_url} from "../../config/Get_url";
import { Plus } from '@element-plus/icons-vue'
import global_api from "@/config/Api";

let optionObj = {};
let optionArr = [];
export default {
  data() {
    return {
      Plus:Plus,
      Get_url:Get_url(),
      headers:{
        id:localStorage.getItem('id'),
        token: localStorage.getItem('token')
      },
      type: '',
      item_obj: {
        // productResources:[]
      },
      product_options:[],   //产品列表(下拉框)
      Variety_shop_options:[],   //杂货铺商品列表
      GPC_options:[],
      loading:false,
      fileList:[
        // {
        //   aa: 'food.jpeg',
        //   url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
        // },
      ],
      inputVisible: false,
      inputValue: '',
    }
  },
  mounted() {
    this.type = this.$route.query.type;
    // let item_obj = sessionStorage.getItem('variety_detail_item');
    if(this.type=='edit'){
      let item_obj = JSON.parse(sessionStorage.getItem('variety_detail_item'));
      item_obj.productParameter = JSON.parse(item_obj.productParameter)
      item_obj.productTag = JSON.parse(item_obj.productTag)
      item_obj.weight = JSON.parse(item_obj.weight)
      item_obj.productId = item_obj.productIds.split(',')
      this.item_obj = item_obj
      // console.log(item_obj)
      this.selectChange()
    } else {
      this.item_obj = {
        productId:[],
        spuId:'',
        logistics:'',
        guarantee:'',
        goodRate:7,
        mediumRate:2,
        badRate:1,
        weight:[{weight:''}],
        title:'',
        subtitle:'',
        sentCount:'',
        productParameter:[{key:'',value:''}],     //参数
        productTag:[],             //高频词
      };
    }
    this.GetProductList()
    this.GueryVarietyShopList()
  },
  methods: {
    //
    showInput(){
      this.inputValue = '';
      this.inputVisible = true;
    },
    //
    handleInputConfirm(){
      let param = {
        productId: this.item_obj.productId,
        tagName: this.inputValue
      };
      this.loading = true;
      queryTagNumberByProductIdAndTagName(param).then(res=>{
        this.loading = false;
        this.GPC_options.push({name:this.inputValue,isSelected:true})
        this.inputVisible = false;
      },err=>{
        this.loading = false;
        this.GPC_options.push({name:this.inputValue,isSelected:true})
        this.inputVisible = false;
      })
      
    },
    //根据杂货铺商品详情id修改杂货铺商品详情
    ModifyProductShopByProductShopId(){
      let param = {
        productShopId:this.item_obj.id,
        productIds:this.item_obj.productId.join(','),
        spuId:this.item_obj.spuId,
        logistics:this.item_obj.logistics,
        guarantee:this.item_obj.guarantee,
        goodRate:this.item_obj.goodRate,
        mediumRate:this.item_obj.mediumRate,
        badRate:this.item_obj.badRate,
        weight:JSON.stringify(this.item_obj.weight),
        title:this.item_obj.title,
        subtitle:this.item_obj.subtitle,
        sentCount:this.item_obj.sentCount,
        productParameter:JSON.stringify(this.item_obj.productParameter),
        type: this.item_obj.type,
        advisoryReasons:this.item_obj.advisoryReasons,
      }
      let gpc_list = []
      for (let i=0;i<this.GPC_options.length;i++){
        if(this.GPC_options[i].isSelected){
          gpc_list.push(this.GPC_options[i])
        }
      }
      param.productTag = JSON.stringify(gpc_list)
      global_api.ModifyProductShopByProductShopId(param).then((res)=>{
        if(res.code==0){
          ElMessage({
            type: 'success',
            message: '修改成功',
          })
          this.$router.push({
            path: "/Variety_detail",
          });
        }

      },err =>{
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },

    //添加杂货铺商品详情
    AddProductShop(){
      let param = {
        productIds:this.item_obj.productId.join(','),
        spuId:this.item_obj.spuId,
        logistics:this.item_obj.logistics,
        guarantee:this.item_obj.guarantee,
        goodRate:this.item_obj.goodRate,
        mediumRate:this.item_obj.mediumRate,
        badRate:this.item_obj.badRate,
        weight:JSON.stringify(this.item_obj.weight),
        title:this.item_obj.title,
        subtitle:this.item_obj.subtitle,
        sentCount:this.item_obj.sentCount,
        productParameter:JSON.stringify(this.item_obj.productParameter),
        type: this.item_obj.type,
        advisoryReasons:this.item_obj.advisoryReasons,
      }
      let gpc_list = []
      console.log(this.GPC_options)
      for (let i=0;i<this.GPC_options.length;i++){
        if(this.GPC_options[i].isSelected){
          gpc_list.push(this.GPC_options[i])
        }
      }
      console.log(gpc_list)
      param.productTag = JSON.stringify(gpc_list)
      global_api.AddProductShop(param).then((res)=>{
        if(res.code==0){
          ElMessage({
            type: 'success',
            message: '新建成功',
          })
          this.$router.push({
            path: "/Variety_detail",
          });
        }

      },err =>{
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },
    // 选择产品
    selectChange(){
      optionArr = [];
      let ids = this.item_obj.productId;
      for(let i in ids){
        console.log(ids[i])
        if(!optionObj[ids[i]]){
          this.GetProductTagByProductId(ids[i])
        }
      }
      this.getGPCoption();
    },

    //通过产品id查询高频词列表
    GetProductTagByProductId(id){
      let param = {
        productId: id
      }
      global_api.GetProductTagByProductId(param).then((res)=>{
        if(!res.result){
          return optionObj[id] = [];
        }
        optionObj[id] = JSON.parse(res.result);

        this.getGPCoption();
        // console.log(JSON.parse(res.result))
      },err =>{
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },
    // 确定标签数据
    getGPCoption(){
      let GPC_options = [];
      let optionObjCopy = JSON.parse(JSON.stringify(optionObj))
      let ids = this.item_obj.productId;
      for(let i in ids){
        if(optionObjCopy[ids[i]]){
          GPC_options.push(...optionObjCopy[ids[i]])
        }
      }

      let productTag = this.item_obj.productTag
      for(let i = 0; i < productTag.length; i ++){
        for(let n = 0; n < GPC_options.length; n ++){
          if(GPC_options[n].name == productTag[i].name){
            GPC_options[n].isSelected = true;
            break ;
          }
        }
      }
      this.GPC_options = GPC_options;
    },
    // 标签去重


    // 获取产品列表(下拉框)
    GetProductList(){
      global_api.GetProductList().then((res)=>{
        this.product_options = res.result
      },err =>{
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },

    // 获取杂货铺商品列表
    GueryVarietyShopList(){
      let param = {
        cunPage:1,
        pageSize:10000,
        needEditSpu:0,
        status:5
      }
      global_api.GueryVarietyShopList(param).then((res)=>{
        this.Variety_shop_options = res.result
        // console.log(res.result)
      },err =>{
        this.loading = false;
        ElMessage.error(`获取数据失败：${err.msg}`)
      })
    },

    //删除对应重量
    delete_weight(index){
      let item_obj = this.item_obj
      if(item_obj.weight.length<=1){
        return false;
      }
      item_obj.weight.splice(index,1)
      this.item_obj = item_obj
    },

    //取消
    cancel(){
      this.$router.push({
        path: "/Variety_detail",
      });
    },

    //添加参数
    add_weight(index){
      let item_obj = this.item_obj
      item_obj.weight.push({weight:''})
      this.item_obj = item_obj
    },

    //删除参数
    delete_productParameter(index){
      let item_obj = this.item_obj
      if(item_obj.productParameter.length<=1){
        return false;
      }
      item_obj.productParameter.splice(index,1)
      this.item_obj = item_obj
    },

    //添加参数
    add_productParameter(index){
      let item_obj = this.item_obj
      item_obj.productParameter.push({key:'',value:''})
      this.item_obj = item_obj
    },


    handleRemove(e){
      let fileList = this.fileList
      for(let i = 0;i<fileList.length;i++){
        if(fileList[i].url == e.response.msg){
          fileList.splice(i,1)
        }
      }
      // this.fileList = fileList
      // console.log(fileList)
      // console.log(e)
    },
    handleAvatarSuccess(e){
      this.fileList.push({url:e.msg})
      // console.log(this.fileList)
      // this.wish.cover = e.msg
    },
  },
}
</script>
<style scoped lang="scss">
.Variety_detail_edit{
  margin-top: 20px;
  .start_items{
    img{
      width: 200px;
      border-radius: 10px;
    }
    .sub{
      margin-top: 10px;
      font-size: 12px;
      color: red;
    }
  }
  .selected_items{
    .btn{
      width: 14px;
      margin: 0 5px;
      cursor: pointer;
    }
  }
}

.el-input__inner,.el-input-number,.el-textarea{
  width: 400px !important;
}

</style>