
// 选择文件
let fileSelect = null;

const ACCEPT = '.xls,.xlsx'
const FILE_MAX_SIZE = 10 * 1024 * 1024;
const FILE_TYPE = ['xls','xlsx'];

export function uploadFile({accept,fileTypes,fileSize}) {
  return new Promise((resolve,reject) => {
    // 创建input选择框
    if(!fileSelect){
      fileSelect = document.createElement('input');
      fileSelect.type = 'file';
      fileSelect.style.display = 'none';
      document.body.append(fileSelect);
    }
    // 获取文件类型，大小限制
    let mineType = accept || ACCEPT;
    let fileMaxSize = fileSize ? fileSize * 1024 * 1024 : FILE_MAX_SIZE;
    let fileType = FILE_TYPE;
    if(fileTypes) {
      if(!Array.isArray(fileTypes)){
        fileTypes = [fileTypes]
      }
      fileType = fileTypes;
    }

    fileSelect.accept = mineType;

    fileSelect.onchange = function(e) {
      let file = e.target.files[0];
      // 获取文件
      console.log(file);
      // 判断文件类型
      let currentFileType = file.name.slice((file.name.lastIndexOf('.'))+1);
      console.log(currentFileType);
      if(!fileType.includes(currentFileType)) {
        fileSelect.value = '';
        reject('文件类型错误');
        return ;
      }

      // 文件大小判断
      if(file.size > fileMaxSize){
        fileSelect.value = '';
        reject('文件大小超出限制');
        return ;
      }

      fileSelect.value = '';
      resolve(file);
    }
    fileSelect.click();
  })
}


// execl文件读取
import * as XLSX from "xlsx";

export function readExeclFile(file) {
  return new Promise((resolve,reject) => {
    let reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => {
      const { result } = event.target;
      // 以二进制流方式读取得到整份excel表格对象
      const workbook = XLSX.read(result, { type: "binary" });
      let data = []; // 存储获取到的数据
      // 遍历每张工作表进行读取（这里默认只读取第一张表）
      for (const sheet in workbook.Sheets) {
        if (Object.prototype.hasOwnProperty.call(workbook.Sheets, sheet)) {
          //这是关键的一步，hasOwnPropert要从Object的原型中调用
          data = data.concat(
            XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
          );
        }
      }
      // 检验数据是否正确
      if (data.length === 0) {
        reject('无数据');
        return ;
      }
      resolve(data)
    }
  })
}

// 下载文件
export function downfile(href,name) {
  let Oa = document.createElement('a');
  Oa.href = href;
  Oa.download = name;
  Oa.click();
}
