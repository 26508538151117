<template>
  <div class="Cherished_desire_update_line" v-loading="loading">
    <div class="main">
      <section class="block">
        <div class="block_title">
          人群分类：
          <el-button type="primary" @click="addStock">添加人群库存</el-button>
        </div>
        <div class="main">
          <div class="selected_items" v-for="(item) in stockList" :key="item.id">
            <span class="title"><span class="red"></span>{{item?.targetAudience?.name}}</span>
            <span>名额：</span>
            <div>
              <el-input-number v-model="item.stock" :min="0"/>
            </div>
            
            <div class="number">已许愿人数：<span>{{item.stockUsed}}</span></div>
            <span>自动增加：</span>
            <div>
              <el-input-number v-model="item.autoStock"></el-input-number>
            </div>
            <div class="number">
              <!-- <el-button type="primary" @click="saveStock(item)">保存</el-button> -->
              <el-button type="danger" @click="removeStock(item)">删除</el-button>
            </div>
          </div>
        </div>
        <div class="main" style="padding-left:200px;padding-bottom:20px;">
          <el-button type="primary" @click="saveStockAll">保存</el-button>
        </div>
      </section>
      <section class="block">
        <p>名额自增事件</p>
        <el-table :data="autoStockList">
          <el-table-column label="时间" prop="createTime"></el-table-column>
          <el-table-column label="结果" prop="remark"></el-table-column>
        </el-table>
        <el-pagination
          v-model:currentPage="cunPage"
          v-model:page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange1"
        />
      </section>
    </div>
    <!-- 添加库存 -->
    <el-dialog v-model="stockDialog" title="添加人群库存" draggable width="590px">
      <el-form>
        <el-form-item label="人群名称">
          <el-col :span="11">
            <el-input disabled v-model="crowd.name"></el-input>
          </el-col>
          <el-col :span="13">
            <el-button @click="openCrowd">选择</el-button>
          </el-col>
        </el-form-item>
        <el-form-item label="许愿上线">
          <el-input-number v-model="crowdUpLine" :min="0"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="stockDialog = false">取消</el-button>
        <el-button type="primary" @click="submitStock">确定</el-button>
      </template>
    </el-dialog>
    <!-- 选择人群 -->
    <el-dialog v-model="crowdDialog" title="人群列表" draggable width="600px">
      <section class="search_btn">
        <div class="search_items">
          <span class="title">人群名称：</span>
          <div>
            <el-input
                v-model="this.targetName"
                class="w-50 m-2"
                size="large"
                placeholder="请输入人群名称"
            ></el-input>
          </div>
        </div>
        <div class="search_items">
          <el-button type="primary" :icon="Search" @click="this.GetTargetAudienceList">查询</el-button>
        </div>
      </section>
      <el-table :data="tableData" highlight-current-row @current-change="handleCurrentChange">
        <el-table-column prop="id" label="id"/>
        <el-table-column prop="name" label="人群名称"/>
        <el-table-column prop="content" label="人群描述"/>
      </el-table>
      <el-pagination
        style="margin-top:10px"
        v-model:currentPage="pageNo"
        :page-size="10"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="GetTargetAudienceList"
      />
      <template #footer>
        <el-button @click="crowdDialog = false">取消</el-button>
        <el-button type="primary" @click="sureCrowd">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>

import { ElMessage, ElMessageBox } from 'element-plus';
import {QueryWishStockListByWishId,GetTargetAudienceList,AddWishStockByWishId,RemoveWishStockByWishStockId,ModifyWishStockByWishStockId,queryAutoWishStockRecordList} from '../../config/Api';
import {Search} from "@element-plus/icons-vue";
export default {
  data() {
    return {
      Search:Search,    //图标
      line_old:1,
      line_new:1000,
      id: '',
      loading: false,
      stockDialog: false,
      crowdDialog: false,
      pageNo: 1,
      total: 1,
      tableData: [],
      temCrowd: {},
      crowd: {},
      crowdUpLine: 0,
      stockList: [],
      targetName:'',
      cunPage:1,
      pageSize: 10,
      total: 1,
      autoStockList:[],
    }
  },
  mounted() {
    let query = this.$route.query;
    this.id = query.id;
    this.getData();
    this.queryAutoWishStockRecordList();
  },
  methods: {
    // 获取数据
    getData() {
      let param = {
        wishId: this.id,
        cunPage: 1,
        pageSize: 200,
      };
      this.loading = true;
      QueryWishStockListByWishId(param).then(res => {
        this.loading = false;
        this.stockList = res.result;
      },err => {
        this.loading = false;
        ElMessage.error(`获取数据失败:${err.msg}`)
      })
    },
    // 打开添加库存
    addStock(){
      this.crowd = {};
      this.crowdUpLine = 0;
      this.stockDialog = true;
    },
    // 打开人群选择弹窗
    openCrowd() {
      this.crowdDialog = true;
      this.GetTargetAudienceList();
    },
    // 获取人群列表
    GetTargetAudienceList() {
      this.loading = true;
      let param = {
        pageNo: this.pageNo,
        pageSize: 10,
        targetName:this.targetName
      }
      GetTargetAudienceList({params:param}).then(res => {
        this.loading = false;
        this.tableData = res.result;
        this.total = res.data.totalCount;
      },err => {
        this.loading = false;
        ElMessage.error(`获取人群列表失败：${err.msg}`)
      })
    },
    // 选择人群
    handleCurrentChange(row) {
      this.temCrowd = row;
    },
    // 确定选择的人群
    sureCrowd() {
      if(!this.temCrowd?.id) {
        ElMessage.error('请选择')
        return ;
      }
      this.crowd = {...this.temCrowd};
      this.crowdDialog = false;
    },
    // 添加人群库存
    submitStock(){
      if(!this.crowd.id){
        ElMessage('请选择人群');
        return ;
      }
      let param = {
        stock: this.crowdUpLine,
        taId: this.crowd.id,
        wishId: this.id,
        autoStock: 0,
      };
      this.loading = true;
      AddWishStockByWishId(param).then(res => {
        this.loading = false;
        this.stockDialog = false;
        this.getData();
      }, err => {
        this.loading = false;
        ElMessage.error(`添加库存失败：${err.msg}`)
      })
    },
    // 删除人群库存
    removeStock(item) {
      ElMessageBox.confirm(
        '是否删除?',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        let param = {
          wishStockId: item.id
        }
        this.loading = true;
        RemoveWishStockByWishStockId(param).then( res => {
          this.loading = false;
          this.getData();
          ElMessage({
            type: 'success',
            message: '已删除',
          })
        },err => {
          this.loading = false;
          ElMessage.error(`删除失败：${err.msg}`)
        })
        
      })
    },
    // 批量保存
    saveStockAll(){
      let list = this.stockList;
      for(let i in list){
        this.saveStock(list[i])
      }
    },
    saveStock(item){
      let param = {
        stock: item.stock,
        taId: item.taId,
        wishId: item.wishId,
        wishStockId: item.id,
        autoStock: item.autoStock
      }
      this.loading = false;
      ModifyWishStockByWishStockId(param).then(res => {
        this.loading = false;
        this.getData();
      },err=>{
        this.loading = false;
        ElMessage.error(`保存失败：${err.msg}`)
      })
    },
    confirm(){
      this.$router.push('/Cherished_desire_list')
    },
    // 查询自增事件
    queryAutoWishStockRecordList(){
      let param = {
        cunPage: this.cunPage,
        pageSize: this.pageSize,
        wishId: this.id
      }
      queryAutoWishStockRecordList(param).then(res => {
        this.total = res.data.totalCount;
        this.autoStockList = res.result;
      }, err => {

      })
    },
    handleCurrentChange1(){
      this.queryAutoWishStockRecordList();
    }
  },
}
</script>
<style scoped lang="scss">
.Cherished_desire_update_line{
  margin-top: 20px;
  .block{
    border: 1px dotted #eee;
    margin-top: 20px;
    box-sizing: border-box;
    .block_title{
      font-size: 16px;
      font-weight: bold;
      margin: 20px;
    }
    .main{
      margin-top: 20px;
    }
    .selected_items span{
      font-size: 14px;
      margin: 0 10px;
    }
    .number{
      font-size: 14px;
      margin-left: 20px;
    }
  }
}
</style>
<style>
.Cherished_desire_update_line .el-table__row{
  cursor: pointer;
}
</style>