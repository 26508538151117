<template>
  <!--  选择标签  -->
  <div v-loading.fullscreen.lock="loading">
    <el-dialog v-model="tagDialog" title="选择标签" width="800px" >
      <div style="display: flex">
        <div style="width: 250px;height: 500px;overflow: auto">
          <el-input v-model="filterText" @change="inputChange"></el-input>
          <el-tree ref="treeRef" :data="groupList" :props="setKesLabel" accordion @node-click="handleNodeClick" :filter-node-method="filterNode"></el-tree>
        </div>
        <div style="width: calc(100% - 250px);height: 500px;overflow: auto">
          <el-table :data="tags" highlight-current-row @current-change="handleCurrentChange">
            <el-table-column label="标签ID" prop="id"></el-table-column>
            <el-table-column label="标签名" prop="value"></el-table-column>
            <el-table-column label="标签时间">
              <template #default="scope">
                {{scope.row.isLongTerm?'长期标签':'短期标签'}}
              </template>
            </el-table-column>
            <el-table-column label="标签时间">
              <template #default="scope">
                {{scope.row.isObvious?'隐性标签':'显性标签'}}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <template #footer>
        <el-button @click="tagDialog = false">取消</el-button>
        <el-button type="primary" @click="sureBtn">确定</el-button>
      </template>
    </el-dialog>
  </div>
  
</template>
<script>
import global_api from "@/config/Api";
import {ElMessage} from "element-plus";

export default {
  data() {
    return {
      tagDialog: false,
      loading: false,
      groupList: [],
      setKesLabel:{
        children:'tagRespDTOS',
        value:'id',
        label:'name',
      },
      tags: [],
      selectTagId: '',
      selectTagName: '',
      filterText: '',
    }
  },
  methods: {
    // 打开弹窗
    openTag(){
      this.tagDialog = true;
      this.getTargetAudienceInfo();
    },
    // 获取标签
    getTargetAudienceInfo(){
      this.loading = true;
      global_api.QueryTagListByTagSearchVo({cunPage:0,pageSize:0}).then((res)=>{     //获取标签列表
        this.loading = false;
        let list = res.result;
        this.groupList = list;
      },err=>{
        this.loading = false;
        ElMessage.error('获取标签失败')
      })
    },
    // 过滤词change
    inputChange(){
      this.$refs.treeRef.filter(this.filterText)
    },
    // 标签树过滤方法
    filterNode(value, data){
      console.log(data)
      if (!value) return true
      if(data.name.includes(value)){
        return true;
      } else {
        for(let i in data.tags){
          if(data.tags[i].value.includes(value)){
            return true;
          }
        }
        return false;
      }
    },
    // 递归
    getTagName(data,allTags,parentName){
      for(let i in data){
        let parentTags = JSON.parse(JSON.stringify(parentName));
        parentTags.push({
          id: data[i].id,
          name: data[i].name,
          parentId: data[i].parentId
        })
        if(data[i].tagRespDTOS.length){
          allTags = this.getTagName(data[i].tagRespDTOS,allTags,parentTags)
        }
        if(data[i].tags.length){
          for(let n in data[i].tags){
            data[i].tags[n].parentTags = JSON.parse(JSON.stringify(parentTags));
          }
          allTags.push(...data[i].tags)
        }
      }
      return allTags;
    },
    // 点击标签
    handleNodeClick(e){
      this.tags = e.tags || [];
      this.selectTagId = '';
      this.selectTagName = '';
    },
    // 选择
    handleCurrentChange(e){
      if(e){
        this.selectTagId = e.id;
        this.selectTagName = e.value;
      }
    },
    // 确认选择
    sureBtn(){
      if(!this.selectTagId) return ElMessage.error('请选择标签');
      let allTags = this.getTagName(this.groupList,[],[]);
      let tag = {};
      for(let n in allTags){
        if(this.selectTagId === allTags[n].id){
          tag.tagId = this.selectTagId;
          tag.tagName = this.selectTagName;
          tag.parentTags = allTags[n].parentTags;
          break ;
        }
      }
      this.tagDialog = false;
      this.$emit('setTag',tag)
    },
  }
}
</script>