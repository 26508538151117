<template>
  <div class="goodsCommentList" v-loading="loading">
    <section class="searchForm">
      <el-form inline>
        <el-form-item label="产品ID">
          <el-input v-model="form.productId"></el-input>
        </el-form-item>
        <el-form-item label="用户ID">
          <el-input v-model="form.userId"></el-input>
        </el-form-item>
        <el-form-item label="用户昵称">
          <el-input v-model="form.userNickName"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
          <el-button type="primary" @click="clearSearch">清除</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="tableList">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="userId" label="用户ID"></el-table-column>
        <el-table-column prop="user.nickname" label="评论用户"></el-table-column>
        <el-table-column label="评论图片">
          <template #default="scope">
            <div class="tableImgs">
              <img v-for="item in scope.row.userCommentResources" :key="item.id" :src="item.url" alt="">
            </div>
          </template>
        </el-table-column>
        <el-table-column label="评论预设">
          <template #default="scope">
            <el-tag class="ml-2" @click="openCom(scope.row)">查看</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="评论内容"></el-table-column>
        <el-table-column label="评论字数">
          <template #default="scope">
            {{scope.row.content.length}}
          </template>
        </el-table-column>
        <el-table-column label="评论质量">
          <template #default="scope">
            {{(scope.row.sentiment==-1&&'-')||(scope.row.sentiment==0&&'差评')||(scope.row.sentiment== 1&&'中性')||(scope.row.sentiment==2&&'优评')}}
          </template>
        </el-table-column>
        <el-table-column label="评论分数" prop="score"></el-table-column>
        <el-table-column label="显示状态">
          <template #default="scope">
            {{scope.row.isVisible?'显示':'不显示'}}
            <el-tag class="ml-2" @click="changeIsVisible(scope.row)">修改</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="primary" size="small" @click="changeSent(scope.row)">处理</el-button>
            <el-button type="success" size="small">对比</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="pageCurrent"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      />
    </section>
    <!-- 查看评论详情 -->
    <el-dialog title="评论详情" v-model="comDialog" draggable>
      <div v-for="(item,index) in question" :key="index">
        <div v-if="item.type!==10">
          <p class="title">{{index+1}}.{{item.name}}【{{item.questionType.name}}】</p>
          <span style="display: block" v-for="(a,n) in item.ans" :key="n">{{a}}</span>
        </div>
        <div v-if="item.type==10">
          <p class="title">{{index+1}}.{{item.name}}【{{item.questionType.name}}】</p>
          <img style="width: 300px" :src="item.ans[0]" alt="">
        </div>
      </div>
      <template #footer>
        <el-button type="primary" @click="comDialog=false">确定</el-button>
      </template>
    </el-dialog>
    <!-- 评论审批 -->
    <el-dialog title="评价" v-model="setDialog" draggable>
      <div>
        平台认证优质评论
      </div>
      <template #footer>
        <el-button type="primary" @click="setPL(1)">差评</el-button>
        <el-button type="primary" @click="setPL(2)">优评</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {ElMessage, ElMessageBox} from 'element-plus';
import {QueryUserCommentListByUserCommentSearchVo,ModifyUserCommentIsVisibleByUserCommentId,ModifyUserCommentSentimentByUserCommentId,selectSurveyWithQuestionAndOptionInfoByUserIdAndProductId} from '../../config/Api'
export default {
  data() {
    return {
      pageCurrent: 1,
      pageSize: 15,
      total: 1,
      id: '',
      b: '',
      form: {},
      tableList: [],
      loading: false,
      comDialog: false,
      question: [],
      setDialog:false,
      pl_row:''
    }
  },
  mounted() {
    let query = this.$route.query;
    this.id = query.id;
    this.b = query.b;
    this.pageCurrent = Number(query.page || 1);
    this.pageSize = Number(query.size || 15);
    this.getList();
  },
  methods:{
    setPL(number){
      if(number==1){
          this.loading = true;
          ModifyUserCommentSentimentByUserCommentId({userCommentId:this.pl_row.id,sentiment:0}).then(res=>{
            this.loading = false;
            this.setDialog = false
            this.getList()
          },err=>{
            this.loading = false;
            this.setDialog = false
            ElMessage.error(`修改失败：${err.msg}`)
          })
      }else{
            this.loading = true;
            ModifyUserCommentSentimentByUserCommentId({userCommentId:this.pl_row.id,sentiment:2}).then(res=>{
              this.loading = false;
              this.setDialog = false
              this.getList()
            },err=>{
              this.loading = false;
              this.setDialog = false
              ElMessage.error(`修改失败：${err.msg}`)
            })
      }
    },
    // 评价分类
    changeSent(row){
      this.setDialog = true
      this.pl_row = row
    },
    // 上一页下一页
    handleCurrentChange() {
      this.$router.push({
        path: '/goods_list/comment',
        query: {page: this.pageCurrent, size: this.pageSize, id:this.id, b:this.b}
      });
      this.getList();
    },
    // 获取数据
    getList() {
      let param = {
        productId: this.id,
        cunPage: this.pageCurrent,
        pageSize: this.pageSize,
        ...this.form,
      };
      this.loading = true;
      QueryUserCommentListByUserCommentSearchVo(param).then(res => {
        this.loading = false;
        this.tableList = res.result;
        this.total = res.data.totalCount;
      },err => {
        this.loading = false;
        ElMessage.error(`获取产品列表失败：${err.msg}`)
      })
    },
    // 搜索
    search(){
      this.pageCurrent = 1;
      this.getList();
    },
    // 清除
    clearSearch(){
      this.form = {};
      this.pageCurrent = 1;
      this.getList()
    },
    // 改变现实状态
    changeIsVisible(row){
      this.loading = true;
      ModifyUserCommentIsVisibleByUserCommentId({userCommentId:row.id}).then(res => {
        this.loading = false;
        this.getList()
      },err => {
        this.loading = false;
        ElMessage.error(`修改失败：${err.msg}`)
      })
    },
    // 查看评论
    openCom(row){
      this.loading = true;
      let param = {
        productId: row.productId,
        userId: row.userId,
      }
      selectSurveyWithQuestionAndOptionInfoByUserIdAndProductId(param).then(res => {
        this.loading = false;
        let question = res.result[0].questionWithTypeWithAnswerDTOS;
        for(let i in question) {
          let item = question[i];
          if (!item["answers"]?.length) {
            continue;
          }
          console.log(item);
          if(item.type === 1||item.type===12||item.type===13||item.type===2) {
            let ans = [];
            for(let n in item["answers"]) {
              for(let k in item.options) {
                (item.options[k].id === item["answers"][n].optionId)&&(ans.push(item.options[k].name));
                (item["answers"][n]["independentAnswer"])&&(ans.push(`其他：${item["answers"][0]["independentAnswer"]}`));
              }
            }
            item.ans = ans;
          }
          if(item.type===3){
            item.ans = [item.answers[0].independentAnswer]
          }
          if(item.type===4){
            let ans = [];
            for(let i in item.answers){
              for(let n in item.options){
                if(item.answers[i].optionId === item.options[n].id){
                  ans[item.answers[i].value]=item.options[n].name
                }
              }
              if(item.answers[i].independentAnswer){
                ans[item.answers[i].value] = `其他：${item.answers[i].independentAnswer}`;
              }
            }
            item.ans = ans;
          }
          // 打分题
          if(item.type===5){
            item.ans = [`打分：${item.answers[0].value}分`];
          }
          // 比重题
          if(item.type===6){
            let ans = [];
            for(let i in item.answers){
              for(let n in item.options){
                if(item.answers[i].optionId===item.options[n].id){
                  ans.push(`${item.options[n].name}:${item.answers[i].value||0}%`)
                }
              }
            }
            item.ans = ans;
          }
          // 滑条题
          if(item.type===7||item.type===11){
            item.ans = [`评价：${item.answers[0].value}`];
          }
          // 矩阵单选
          if(item.type===8){
            let ans = [];
            for(let i in item.answers){
              for(let n in item.options){
                if(item.answers[i].optionId===item.options[n].id){
                  ans.push(`${item.options[n].name}:${item.answers[i].value}`)
                }
              }
            }
            item.ans = ans;
          }
          // 矩阵多选
          if(item.type===9){
            let ans = [];
            for(let i in item.answers){
              for(let n in item.options){
                if(item.answers[i].optionId===item.options[n].id){
                  ans.push(`${item.options[n].name}:${item.answers[i].value}`)
                }
              }
            }
            item.ans = ans;
          }
          // 图片题
          if(item.type===10){
            item.ans = [item.answers[0].independentAnswer]
          }
          if(item.type===14||item.type === 15||item.type === 16) {
            let ans = [];
            for(let n in item.answers) {
              for(let k in item.options) {
                if(item.answers[n].optionId === item.options[k].id) {
                  ans.push(`${item.options[n].name}:${item.answers[n].value}`)
                }
              }
            }
            item.ans = ans;
          }
          if(item.type === 18) {
            let ans = [];
            ans.push(...item.answers[0].independentAnswer.split(','))
            item.ans = ans;
          }
        }
        this.question = question;
      },err => {
        ElMessage.error('查询用户评价失败');
        this.loading = false;
      })
      this.comDialog = true;
    }
  }
}
</script>
<style lang="scss" scoped>
.goodsCommentList .title{
  font-weight: bold;
}
.tableImgs {
  img {
    width: 40px;
    float: left;
    margin: 0 10px 10px 0;
  }
}
</style>