import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import {Get_url} from "@/config/Get_url";
import store from './store'
import global_api from "./config/Api";
// console.log(util)
const app = createApp(App)
import installElementPlus from './plugins/element'
import { formatDate } from './utils/date';
app.mixin({
    data(){
        return {
            // Get_url: Get_url(),
            global_api:global_api,
            formatDate
        }
    },
    // methods:{
    //
    // }
})
app.config.warnHandler = () => null;
installElementPlus(app)
app.use(store).use(router).mount('#app')
