<template>
  <div class="Order_management_update" v-loading="loading">
    <div class="main">
      <section class="search_btn">
        <div class="selected_items">
          <span class="title"><span class="red">*</span>评论状态：</span>
          <div>
            <el-select v-model="order.isCommented" class="m-2" size="large">
              <el-option label="未评论" :value="0"/>
              <el-option label="已评论" :value="1"/>
            </el-select>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>发货状态：</span>
          <div>
            <el-select v-model="order.shipState" @change="selectChange" class="m-2" size="large">
              <el-option label="未发货" :value="0"/>
              <el-option label="已发货" :value="1"/>
              <el-option label="已送达" :value="2"/>
            </el-select>
          </div>
        </div>
        <div class="selected_items" v-if="order.shipState!=0">
          <span class="title"><span class="red">*</span>心愿盒：</span>
          <div style="display:flex;">
            <el-input v-model="wishBox.name" :disabled="true"></el-input>
            <el-button style="margin-left:10px" @click="QueryBoxListByBoxSearchVo">修改</el-button>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>允许修改：</span>
          <div>
            <el-select v-model="order.isLocked" class="m-2" size="large">
              <el-option label="不允许" :value="0"/>
              <el-option label="允许" :value="1"/>
            </el-select>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>快递公司：</span>
          <div>
            <el-input
                v-model="order.orderCarrier"
            ></el-input>
          </div>
        </div>
        <div class="selected_items">
          <span class="title"><span class="red">*</span>快递单运号：</span>
          <div>
            <el-input
                v-model="order.orderLogistics"
            ></el-input>
          </div>
        </div>
      </section>
      <section>
        <div class="selected_items">
          <span class="title"></span>
          <div>
            <el-button type="primary" @click="confirm">取消</el-button>
            <el-button type="danger" @click="modifyOrder">确认</el-button>
          </div>
        </div>
      </section>
    </div>
    <!-- 选择心愿盒 -->
    <el-dialog title="选择心愿盒" v-model="wishBoxShow">
      <section class="search_btn">
        <div class="search_items">
          <span class="title">名称：</span>
          <div>
            <el-input
                v-model="this.name"
                class="w-50 m-2"
                size="large"
                placeholder="请输入名称"
            ></el-input>
          </div>
        </div>
        <div class="search_items">
          <el-button type="primary" :icon="Search" @click="this.QueryBoxListByBoxSearchVo">查询</el-button>
        </div>
      </section>
      <el-table :data="wishBoxList" highlight-current-row @current-change="handleCurrentChange">
        <el-table-column label="id" prop="id" width="120"></el-table-column>
        <el-table-column label="名称" prop="name" width="200"></el-table-column>
        <el-table-column label="描述" prop="content"></el-table-column>
      </el-table>
      <el-pagination
        v-model:currentPage="pageNo"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="Current_change"
      />
      <template #footer>
        <el-button @click="wishBoxShow=false">取消</el-button>
        <el-button @click="sureWishBox">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import {ModifyUserWishByUserWishId,QueryBoxListByBoxSearchVo} from '../../config/Api';
import {Search} from "@element-plus/icons-vue";
export default {
  data() {
    return {
      Search:Search,    //图标
      order: {},
      wishBox: {},
      wishBoxTmp: {},
      wishBoxList: [],
      pageNo: 1,
      pageSize: 10,
      total: 1,
      name:'',
      wishBoxShow: false,
    }
  },
  mounted() {
    let order = JSON.parse(sessionStorage.getItem('order'));
    this.order = {
      isCommented: order.userWish.isCommented,
      shipState: order.userWish.shipState,
      orderCarrier: order.userWish.orderCarrier,
      orderLogistics: order.userWish.orderLogistics,
      userWishId: order.userWish.id,
      isLocked: order.userWish.isLocked,
      boxId: order.userWish.boxId,
      isOpen: order.userWish.isOpen,
      addressId: order.userWish.addressId,
    }
    this.wishBox.id = order.userWish.boxId;
    this.wishBox.name = order.box?.name;
    this.address = order.userAddress
  },
  methods: {
    // 修改
    modifyOrder(){
      if(this.order.shipState!=0&&!this.wishBox.id){
        ElMessage.error('请选择心愿盒');
        return ;
      }
      this.order.boxId = this.wishBox.id;
      this.loading = true;
      ModifyUserWishByUserWishId(this.order).then(res => {
        this.loading = false;
        this.confirm()
      },err => {
        this.loading = false;
        ElMessage.error(`修改失败：${err.msg}`)
      })
    },
    confirm(){
      this.$router.push('/Order_management')
    },
    // 获取心愿盒列表
    QueryBoxListByBoxSearchVo(){
      let param = {
        name:this.name,
        cunPage: this.pageNo,
        pageSize: this.pageSize,
      }
      this.loading = true;
      QueryBoxListByBoxSearchVo(param).then(res => {
        this.loading = false;
        this.wishBoxShow = true;
        this.wishBoxList = res.result;
        this.total = res.data.totalCount
        this.wishBoxTmp = {};
      },err=>{
        this.loading = false;
        ElMessage.error('获取心愿盒失败')
      })
    },
    Current_change(){
      this.QueryBoxListByBoxSearchVo();
    },
    handleCurrentChange (val){
      console.log(val);
      this.wishBoxTmp = val;
    },
    // 确定心愿盒
    sureWishBox(){
      if(this.wishBoxTmp&&this.wishBoxTmp.id){
        this.wishBox = {...this.wishBoxTmp};
        this.wishBoxShow = false;
      }else{
        ElMessage.error('请选择心愿盒')
      }
    },
    // 
    selectChange(val){
      console.log(val)
      if(val != 0 && !this.address){
        ElMessage.error('没有用户地址信息，暂不能修改发货状态');
        this.order.shipState = 0;
      }
    }
  },
}
</script>
<style scoped lang="scss">
.Order_management_update{
  margin-top: 20px;

}

.el-input__inner,.el-input-number,.el-textarea{
  width: 400px !important;
}

</style>