<template>
  <section class="searchForm">
    <el-button type="primary" @click="backUrl">返回</el-button>
  </section>
  <section class="tableList" v-loading="loading">
    <el-table :data="tableData">
      <el-table-column fixed prop="userId" label="用户ID" width="120" />
      <el-table-column prop="name" label="收货人" width="120" />
      <el-table-column prop="phone" label="手机号码" width="120"/>
      <el-table-column prop="province" label="省份" width="120" />
      <el-table-column prop="city" label="城市"/>
      <el-table-column prop="district" label="地区"/>
      <el-table-column prop="extInfo" label="收货地址"/>
      <el-table-column prop="createTime" label="创建时间" width="200" />
<!--      <el-table-column fixed="right" label="操作" width="200">-->
<!--        <template #default>-->
<!--          <el-tag class="ml-2" type="danger">修改</el-tag>-->
<!--          <el-tag class="ml-2" type="success">删除</el-tag>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
  </section>
</template>

<script>
import {QueryAllAddressByUserId} from '../../config/Api';
export default {
  name: 'Member_address',
  data(){
    return {
      id: '',
      backurl: null,
      tableData:[],
      loading: false,
    }
  },
  mounted() {
    let {id, b} = this.$route.query;
    this.id = id;
    this.backurl = b;
    this.QueryAllAddressByUserId();
  },
  methods: {
    // 获取用户收货地址列表
    QueryAllAddressByUserId() {
      let param = {
        userId: this.id
      }
      this.loading = true;
      QueryAllAddressByUserId(param).then(res => {
        this.loading = false;
        this.tableData = res.result;
      },err => {
        this.loading = false;
      })
    },
    // 返回
    backUrl(){
      let query = {};
      if(this.backurl){
        query = {
          page: this.backurl.split('_')[0],
          size: this.backurl.split('_')[1]
        }
      }
      this.$router.push({
        path: '/Member_detail',
        query: query
      })
    }
  },
}
</script>
<style scoped lang="scss">

</style>
