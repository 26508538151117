<template>
  <div class="H5_edit">
    <div class="main">
      <section class="search_btn">
        <div class="selected_items">
          <span class="title"><span class="red"></span>文章标题：</span>
          <div>
            <el-input
                v-model="H5_item.kvKey"
                class="w-50 m-2"
                size="large"
                placeholder="请输入标题"
            ></el-input>
          </div>
        </div>
        <div class="start_items">
          <span class="title"><span class="red"></span>文章标题：</span>
          <div>
            <div className='Editor' id='Editor' style="width: 700px" ref="Editor"></div>
          </div>
        </div>
      </section>
      <section>
        <div class="selected_items">
          <span class="title"></span>
          <div>
            <el-button type="primary" @click="cancel">取消</el-button>
            <el-button type="danger" @click="confirm">确认</el-button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {Search,MessageBox} from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import E from "wangeditor";
import global_api from "@/config/Api";
export default {
  name: 'H5_edit',
  data(){
    return {
      Search:Search,    //图标
      MessageBox:MessageBox,//图标
      H5_item:{},
      flag:''
    }
  },
  mounted() {
      if(this.editor){
        this.editor.destroy()
      }
      console.log(this.editor)
      this.editor = new E(this.$refs.Editor)
      this.editor.config.menus = [
        'bold',
        'head',
        'fontSize',
        'foreColor',
        'backColor',
        'italic',
        'underline',
        'undo',
        'redo',
      ]
      this.editor.config.onchange = (text)=>{
        this.H5_item.kvValue = text
      }
      this.editor.create()
      console.log(this.editor)
      console.log(this.editor.id)
      this.H5_item_obj()

  },
  methods: {
    H5_item_obj(){    //初始化数据
      this.flag = this.$route.query.type
      if(this.flag=='edit'){
        this.H5_item = JSON.parse(sessionStorage.getItem('H5_item'))
        this.editor.txt.html(this.H5_item.kvValue)
      }else{
        this.H5_item = {
            kvKey: "",
            kvValue:''
        }
      }
    },

    confirm(){
      if(this.flag=='edit'){
        this.modifyKvMapByKvMapId()
      }else{
        this.AddKvMap()
      }
    },
    cancel(){
      this.$router.push('/H5_page_setting');
    },

    modifyKvMapByKvMapId(){   //通过数据字典id修改数据字典
      let obj={
        id:1,
        kvKey:this.H5_item.kvKey,
        kvValue:this.H5_item.kvValue,
        type:2,
        kvMapId:this.H5_item.id
      }
      global_api.modifyKvMapByKvMapId(obj).then((res)=>{
        if(res.code!=0){
          ElMessage.error('添加数据字典内容接口错误')
        }else{
          ElMessage({
            message: '修改成功',
            type: 'success',
          })
          this.editor.destroy()
          this.$router.push('/H5_page_setting');
        }
      })
    },

    AddKvMap(){      //添加数据字典内容
      let obj={
        id:1,
        kvKey:this.H5_item.kvKey,
        kvValue:this.H5_item.kvValue,
        type:2
      }
      global_api.AddKvMap(obj).then((res)=>{
        if(res.code!=0){
          ElMessage.error('添加数据字典内容接口错误')
        }else{
          ElMessage({
            message: '添加成功',
            type: 'success',
          })
          this.editor.destroy()
          this.$router.push('/H5_page_setting');
        }

      })
    },

  },
}
</script>
<style scoped lang="scss">
.H5_edit{
  margin-top: 20px;
}


</style>
