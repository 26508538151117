<template>
  <div class="Subject_management_edit" v-loading.fullscreen.lock="loading">
    <section class="search_btn">
      <div class="search_items">
        <span class="title"><span class="red">*</span>题目类型：</span>
        <div class="main">
          <el-select v-model="this.question.type" filterable placeholder="请选择题目类型" disabled size="large" v-show="this.$route.query.type=='edit'">
            <el-option
                v-for="item in this.questionType_options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="this.question.type" filterable placeholder="请选择题目类型" size="large" v-show="this.$route.query.type!='edit'" @change="change_init">
            <el-option
                v-for="item in this.questionType_options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search_items">
        <span class="title"><span class="red">*</span>题目名称：</span>
        <div>
          <el-input
              v-model="this.question.name"
              class="w-50 m-2"
              size="large"
              placeholder="请输入题目名称"
          ></el-input>
        </div>
        <div>
          <el-button size="small" @click="openEditdialog">编辑</el-button>
        </div>
      </div>
      <div class="search_items">
        <span class="title">标签组：</span>
        <div v-if="groupList.length">
          <el-cascader
              v-model="this.question.categoryIds"
              :show-all-levels="false"
              :options="groupList"
              :props="{ checkStrictly: true,children:'tagRespDTOS',label:'name',value:'id' }"
              clearable
          ></el-cascader>
        </div>
        <div v-else>
          <!-- 刷新按钮 -->
          <el-button size="small" @click="getTargetAudienceInfo">刷新</el-button>
        </div>
      </div>
      <div class="search_items">
        <span class="title"><span class="red">*</span>必须回答：</span>
        <div>
          <el-select v-model="this.question.isRequired" filterable placeholder="请选择" size="large">
            <el-option
                v-for="item in this.isRequired_options"
                :key="item.value"
                :label="item.text"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search_items">
        <span class="title"><span class="red">*</span>是否模板：</span>
        <div>
          <el-select v-model="this.question.isTemplate" filterable placeholder="请选择" size="large">
            <el-option
                v-for="item in this.isTemplate_options"
                :key="item.value"
                :label="item.text"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search_items" v-show="this.question.type==1 || this.question.type==2 || this.question.type==4 || this.question.type==6 || this.question.type==12 || this.question.type==13||this.question.type==14||this.question.type==15">
        <span class="title"><span class="red">*</span>开启选项随机：</span>
        <div>
          <el-select v-model="this.question.isRandom" filterable placeholder="请选择" size="large">
            <el-option
                v-for="item in this.isRandom_options"
                :key="item.value"
                :label="item.text"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search_items" v-show="this.question.type==1 || this.question.type==2 || this.question.type==4 || this.question.type==12 || this.question.type==13">
        <span class="title"><span class="red">*</span>其它选项：</span>
        <div class="main">
          <el-select v-model="this.question.isOther" placeholder="请选择" size="large" @change="other_item_change">
            <el-option
                v-for="item in this.isOther_options"
                :key="item.value"
                :label="item.text"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search_items" v-show="this.question.type==2 || question.type==9 || this.question.type == 18">
        <span class="title"><span class="red">*</span>最小选项：</span>
        <div>
          <el-input
              v-model="this.question.optionCountMin"
              class="w-50 m-2"
              size="large"
              placeholder="请输入"
          ></el-input>
        </div>
      </div>
      <div class="search_items" v-show="this.question.type==2|| question.type==9||this.question.type==18">
        <span class="title"><span class="red">*</span>最大选项：</span>
        <div>
          <el-input
              v-model="this.question.optionCountMax"
              class="w-50 m-2"
              size="large"
              placeholder="请输入"
          ></el-input>
        </div>
      </div>
      <div class="search_items" v-show="this.question.type==5 || this.question.type==14">
        <span class="title"><span class="red">*</span>分数设置：</span>
        <div>
          <el-select v-model="this.question.optionCountMax" filterable placeholder="请输入" size="large">
            <el-option
                v-for="item in score_setting_options"
                :key="item.value"
                :label="item.value"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search_items" v-show="this.question.type==7 || this.question.type==11 || this.question.type==15">
        <span class="title"><span class="red">*</span>最小值：</span>
        <div>
          <el-input
              v-model="this.question.optionCountMin"
              class="w-50 m-2"
              size="large"
              placeholder="请输入"
          ></el-input>
        </div>
      </div>
      <div class="search_items" v-show="this.question.type==7 || this.question.type==11|| this.question.type==15">
        <span class="title"><span class="red">*</span>最大值：</span>
        <div>
          <el-input
              v-model="this.question.optionCountMax"
              class="w-50 m-2"
              size="large"
              placeholder="请输入"
          ></el-input>
        </div>
      </div>
      <div class="search_items" v-show="this.question.type==7 ||this.question.type==5 || this.question.type==11||this.question.type==14||this.question.type==15">
        <span class="title"><span class="red">*</span>最小值文本：</span>
        <div>
          <el-input
              v-model="this.question.minName"
              class="w-50 m-2"
              size="large"
              placeholder="请输入"
          ></el-input>
        </div>
      </div>
      <div class="search_items" v-show="this.question.type==7||this.question.type==5 || this.question.type==11||this.question.type==14||this.question.type==15">
        <span class="title"><span class="red">*</span>最大值文本：</span>
        <div>
          <el-input
              v-model="this.question.maxName"
              class="w-50 m-2"
              size="large"
              placeholder="请输入"
          ></el-input>
        </div>
      </div>
      <div class="search_items" >
        <span class="title"><span class="red">*</span>答题时间：</span>
        <div>
          <el-input-number v-model="question.minTime" :min="0" />
        </div>
      </div>
      <div class="search_items" >
        <span class="title"><span class="red">*</span>题目组别：</span>
        <div>
          <!-- <el-select v-model="question.isQuestionRandom" placeholder="请输入">
            <el-option label="否" :value="0"/>
            <el-option label="是" :value="1"/>
          </el-select> -->
          <el-input-number v-model="question.questionGroup" :min="0" />
        </div>
      </div>
      <div class="search_items" >
        <span class="title"><span class="red">*</span>是否组别乱序：</span>
        <div>
          <el-select v-model="question.isGroupRandom" placeholder="请输入">
            <el-option label="否" :value="0"/>
            <el-option label="是" :value="1"/>
          </el-select>

        </div>
      </div>
      <div class="search_items" >
        <span class="title"><span class="red">*</span>是否组别内乱序：</span>
        <div>
          <el-select v-model="question.isWithinGroupRandom" placeholder="请输入">
            <el-option label="否" :value="0"/>
            <el-option label="是" :value="1"/>
          </el-select>

        </div>
      </div>
      <div class="search_items">
        <span class="title"><span class="red">*</span>是否开启GPT：</span>
        <div>
          <el-select v-model="question.isFollowUp" placeholder="请输入">
            <el-option label="否" :value="0"/>
            <el-option label="是" :value="1"/>
          </el-select>
        </div>
      </div>
      <div class="search_items" v-if="question.isFollowUp">
        <span class="title"><span class="red">*</span>GPT问题数量：</span>
        <div>
          <el-input-number v-model="question.followUpCount" :min="1" />
        </div>
      </div>
      <div class="search_items" v-if="question.type==2||question.type==1">
        <span class="title"><span class="red">*</span>选项关联：</span>
        <div>
<!--          <el-input v-model="question.associatedId"></el-input>-->
          <el-button v-if="!question.associatedId" type="primary" @click="openOptionLinkSet">选项关联</el-button>
          <el-button v-else type="danger" @click="question.associatedId = ''">选项关联删除</el-button>
          {{`关联题目ID：${question.associatedId}`}}
        </div>
      </div>
      <div class="search_items" v-show="this.question.type==1 || this.question.type==2 || this.question.type==4 || this.question.type==12 || this.question.type==13 || this.question.type==14">
        <span class="title">快速生成标签:</span>
        <div>
          <el-button type="primary" @click="addTags">生成标签</el-button>
        </div>
      </div>
    </section>

      <section>
        <div class="start_items">
          <span class="title">顶部图片：</span>
          <div>
            <el-upload
                class="avatar-uploader"
                :action="Get_url+'admin/file/uploadFile'"
                :headers="{token: this.token, id: this.id}"
                :show-file-list="false"
                :on-success="AvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :on-error="uploadError"
            >
              <img v-if="this.question.img && !this.question.img.includes('.mp4')" :src="this.question.img" class="top_avatar" />
              <img v-else-if="!this.question.img" src="../../assets/bannerDefualt.png" class="top_avatar"/>
              <video v-else-if="this.question.img && this.question.img.includes('.mp4')" controls width="250">
                <source :src="this.question.img" type="video/mp4">
                Sorry, your browser doesn't support embedded videos.
              </video>
            </el-upload>
            <div class="red">图片尺寸小于1MB</div>
          </div>
        </div>
      </section>

    <section v-show="this.question.type==1 || this.question.type==2 || this.question.type==4 || this.question.type==12 || this.question.type==13 || this.question.type==14">
      <div class="search_items">
        <el-button type="warning" @click="add_item">添加选项</el-button>
      </div>
      <el-table :data="this.optionList">
        <el-table-column prop="id" label="id"></el-table-column>
        <el-table-column  prop="sort" label="选项排序" width="100"/>
        <el-table-column  prop="name" label="选项答案文本" width="250">
          <template #default="scope">
            <div>
              <el-input
                  v-model="scope.row.name"
                  class="w-50 m-2"
                  size="large"
                  placeholder="请选择选项答案文本"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="iconDefault" label="选项答案图片(默认-选中) 图片比例1：1" width="200" >
          <template #default="scope">
            <div class="upload_img">
              <el-upload
                  class="avatar-uploader"
                  :action="Get_url+'admin/file/uploadFile'"
                  :headers="{token:this.token,id:this.id}"
                  :show-file-list="false"
                  :on-success="dxt_AvatarSuccess"
                  :before-upload="dxt_beforeAvatarUpload"
                  @click="upload(scope,'iconDefault')"
              >
                <img v-if="scope.row.iconDefault" :src="scope.row.iconDefault" class="avatar" />
                <img v-else src="../../assets/bannerDefualt.png" class="avatar"/>
              </el-upload>
            </div>
            <div class="upload_img">
              <el-upload
                  class="avatar-uploader"
                  :action="Get_url+'admin/file/uploadFile'"
                  :headers="{token:this.token,id:this.id}"
                  :show-file-list="false"
                  :on-success="dxt_AvatarSuccess"
                  :before-upload="dxt_beforeAvatarUpload"
                  @click="upload(scope,'iconSelect')"
              >
                <img v-if="scope.row.iconSelect" :src="scope.row.iconSelect" class="avatar" />
                <img v-else src="../../assets/bannerDefualt.png" class="avatar"/>
              </el-upload>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="img" label="使用图片/视频作为选项" width="200">
          <template #default="scope">
            <div class="upload_img">
              <el-upload
                  class="avatar-uploader"
                  :action="Get_url+'admin/file/uploadFile'"
                  :headers="{token:this.token,id:this.id}"
                  :show-file-list="false"
                  :on-success="dxt_AvatarSuccess"
                  :before-upload="dxt_beforeAvatarUpload"
                  @click="upload(scope,'img')"
              >
                <img v-if="scope.row.img&&!scope.row.img?.includes('.mp4')" :src="scope.row.img" class="avatar" />
                <img v-else-if="!scope.row.img" src="../../assets/bannerDefualt.png" class="avatar"/>
                <video v-else-if="scope.row.img?.includes('.mp4')" controls width="250">
                  <source :src="scope.row.img" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                </video>
              </el-upload>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="选项描述" width="220px">
          <template #default="scope">
            <div>
              <el-input
                  v-model="scope.row.content"
                  class="w-50 m-2"
                  size="large"
                  placeholder="请选择选项描述"
                  :disabled="scope.row.content==='其他'"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="互斥设置" width="300px" v-if="this.question.type==2">
          <template #default="scope">
            <el-select v-model="scope.row.mutex" multiple @change="setMutex(scope.row)">
              <el-option
                v-for="item in optionList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :disabled="scope.row.id==item.id"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="选项标签" width="220px">
          <template #default="scope">
            <span v-for="(item,index) in scope.row.parentTags" :key="index">{{item.name}}》</span>{{scope.row.tagName}}
            <el-button type="primary" @click="openTag(scope)">选择标签</el-button>
<!--            <el-cascader v-model="scope.row.tagId" :options="groupList" :show-all-levels="false" :props="this.setKesLabel"/>-->
          </template>
        </el-table-column>
        <el-table-column prop="isActive" label="上下架" width="200">
            <template #default="scope">
              <el-switch
                v-model="scope.row.isActive"
                active-text="上架"
                inactive-text="下架"
              />
            </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
            <template #default="scope">
              <el-tag class="ml-2" type="success" @click="Is_delete(scope)">删除</el-tag>
            </template>
        </el-table-column>
      </el-table>
    </section>
    <section v-show="this.question.type==6||this.question.type==15">
      <div class="search_items">
        <el-button type="warning" @click="add_item">添加选项</el-button>
      </div>
      <el-table :data="this.optionList">
        <el-table-column prop="id" label="id"></el-table-column>
        <el-table-column  prop="sort" label="选项排序" width="100"/>
        <el-table-column  prop="name" label="选项答案文本" width="250">
          <template #default="scope">
            <div>
              <el-input
                  v-model="scope.row.name"
                  class="w-50 m-2"
                  size="large"
                  placeholder="请选择选项答案文本"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="选项描述" width="250">
          <template #default="scope">
            <div>
              <el-input
                  v-model="scope.row.content"
                  class="w-50 m-2"
                  size="large"
                  placeholder="请选择选项描述"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="content" label="选项标签" width="250">
          <template #default="scope">
            <span v-for="(item,index) in scope.row.parentTags" :key="index">{{item.name}}》</span>{{scope.row.tagName}}
            <el-button type="primary" @click="openTag(scope)">选择标签</el-button>
          </template>
        </el-table-column> -->
        <el-table-column prop="isActive" label="上下架" width="200">
            <template #default="scope">
              <el-switch
                v-model="scope.row.isActive"
                active-text="上架"
                inactive-text="下架"
              />
            </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-tag class="ml-2" type="success" @click="Is_delete(scope)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section v-show="this.question.type==8 || this.question.type==9 || this.question.type==16 || this.question.type==22">
      <div class="search_items">
        <el-button type="warning" @click="add_item">添加选项</el-button>
      </div>
      <el-table :data="this.optionList">
        <el-table-column prop="id" label="id"></el-table-column>
        <el-table-column  prop="sort" label="选项排序" width="100"/>
        <el-table-column  prop="name" label="选项位置" width="250">
          <template #default="scope">
            <div>
              <el-select v-model="scope.row.optionPosition" filterable placeholder="请选择选项位置" size="large">
                <el-option
                    v-for="item in this.optionPosition_list"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
          </template>
        </el-table-column>
        <el-table-column  prop="name" label="选项答案文本" width="250">
          <template #default="scope">
            <div>
              <el-input
                  v-model="scope.row.name"
                  class="w-50 m-2"
                  size="large"
                  placeholder="请选择选项答案文本"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="选项描述" width="250">
          <template #default="scope">
            <div>
              <el-input
                  v-model="scope.row.content"
                  class="w-50 m-2"
                  size="large"
                  placeholder="请选择选项描述"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="content" label="选项标签" width="250">
          <template #default="scope">
            <span v-for="(item,index) in scope.row.parentTags" :key="index">{{item.name}}》</span>{{scope.row.tagName}}
            <el-button type="primary" @click="openTag(scope)">选择标签</el-button>
          </template>
        </el-table-column> -->
        <el-table-column prop="isActive" label="上下架" width="200">
            <template #default="scope">
              <el-switch
                v-model="scope.row.isActive"
                active-text="上架"
                inactive-text="下架"
              />
            </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-tag class="ml-2" type="success" @click="Is_delete(scope)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section v-show="this.question.type==10">
      <el-table :data="this.optionList">
        <el-table-column prop="id" label="id"></el-table-column>
        <el-table-column  prop="sort" label="选项排序"/>
        <el-table-column  prop="name" label="按钮文案(同意-拒绝)">
          <template #default="scope">
            <div>
              <el-input
                  v-model="scope.row.name"
                  class="w-50 m-2"
                  size="large"
                  placeholder="请选择选项答案文本"
              ></el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="选项标签" width="250">
          <template #default="scope">
            {{scope.row.tagName}}
            <el-button type="primary" @click="openTag(scope)">选择标签</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="isActive" label="上下架" width="200">
            <template #default="scope">
              <el-switch
                v-model="scope.row.isActive"
                active-text="上架"
                inactive-text="下架"
              />
            </template>
        </el-table-column>
        <el-table-column prop="createTime" label="时间"/>
      </el-table>
    </section>
    <section class="submit_btn">
      <el-button type="primary" @click="revert">返回</el-button>
       <el-button type="danger" @click="submit">提交</el-button>
    </section>

    <!--  选择标签  -->
    <el-dialog v-model="tagDialog" title="选择标签" width="800px">
      <div style="display: flex">
        <div style="width: 250px;height: 500px;overflow: auto">
          <el-tree :data="groupList" :props="setKesLabel" accordion @node-click="handleNodeClick"></el-tree>
        </div>
        <div style="width: calc(100% - 250px);height: 500px;overflow: auto">
          <el-table :data="tags" highlight-current-row @current-change="handleCurrentChange">
            <el-table-column label="标签ID" prop="id"></el-table-column>
            <el-table-column label="标签名" prop="value"></el-table-column>
            <el-table-column label="标签时间">
              <template #default="scope">
                {{scope.row.isLongTerm?'长期标签':'短期标签'}}
              </template>
            </el-table-column>
            <el-table-column label="标签时间">
              <template #default="scope">
                {{scope.row.isObvious?'隐性标签':'显性标签'}}
              </template>
            </el-table-column>
          </el-table>
          <div>
            <el-button @click="openAddTag">添加</el-button>
          </div>
        </div>
      </div>
      <template #footer>
        <el-button @click="tagDialog = false">取消</el-button>
        <el-button type="primary" @click="sureBtn">确定</el-button>
      </template>
    </el-dialog>
    <!-- 简单富文本 -->
    <el-dialog v-model="editDialog" title="文本编辑" width="800px">
      <div>
        <editor :text="question.name" @change="getContent"></editor>
        <div class="foot">
          <el-button @click="editDialog=false">取消</el-button>
          <el-button type="primary" @click="closeEditDialog">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog v-model="tag_dialog" title="编辑标签" width="500px" draggable>
      <addtag type="add" :value="item_obj" @updata="updataTag" @close="tag_dialog = false"></addtag>
    </el-dialog>
    <optionLink ref="optionLink" :questionList="questionList" :optionData="optionData" @option="optionR"/>
  </div>
</template>

<script>

import {ElMessage, ElMessageBox} from "element-plus";
import {Get_url} from "@/config/Get_url";
import global_api, {getKvMapById, insertOrUpdateQuestionSyncUpdateUserTag} from "@/config/Api";
import simpleEditor from '../../components/simpleEditor';
import addtag from '@/components/addtag';
import editor from '@/components/editor.vue'
import optionLink from "@/components/optionLink.vue";
let that
export default {
  name: 'Subject_management_edit',
  data(){
    return {
      questionType_options:[],               //题目类型
      isRequired_options:[{text:'是',value:1},{text:'否',value:0}],     //必须回答
      isRandom_options:[{text:'是',value:1},{text:'否',value:0}],
      isTemplate_options:[{text:'是',value:1},{text:'否',value:0}],
      categoryId_one:'',  //大类id
      categoryId_two:'',  //小类id
      category_one_options:[],
      category_two_options:[],
      isOther_options:[{text:'开启',value:1},{text:'不开启',value:0}],   //其他选项
      score_setting_options:[      //打分题-分数设置
        {value:1},{value:2},{value:3},{value:4},{value:5},{value:6},{value:7},{value:8},{value:9},{value:10},{value:11}
      ],
      optionPosition_list:[                //选项位置的集合
        {text:'左侧',value:'left'},{text:'上侧',value:'up'}
      ],
      index:0,      //记录第几个item
      image:'',     //记录那张图片的字段

     // object_data:{},           //总数据
      Get_url:Get_url(),
      optionList:[],
      question:{},
      groupList:[],        //标签的集合
      setKesLabel:{
        children:'tagRespDTOS',
        value:'id',
        label:'name'||'value',
      },
      token:localStorage.getItem('token'),
      id:localStorage.getItem('id'),
      tagDialog: false,
      tags:[],
      selectTagId: '',
      selectTagName: '',
      scopeIndex: '',
      loading: false,
      editDialog: false,
      editor: null,
      color: '#ff0000',
      tagId: '',
      tag_dialog: false,
      item_obj: {},

      questionList:[],
      optionData: [],
    }
  },
  components: {simpleEditor,addtag,editor,optionLink},
  mounted() {
    that = this;
    // console.log(Get_url())
    this.Init_date()
    this.GetQuestionTypeList()
    this.GetCategoryList_one()
    this.GetCategoryList_two(this.categoryId_one,2)
    this.getTargetAudienceInfo()
  },
  methods: {
    Init_date(){             //初始化数据
      //如果是编辑 获取sessionStorage的值 否则questionId默认为1
      if(this.$route.query.type=='edit'){
        let object = JSON.parse(sessionStorage.getItem('subject_item'))
        let optionList = object.optionList
        let question = object.question;
        console.log(question);
        question.categoryIds = [];
        this.question = question;
        this.categoryId_one = object.parentCategory?object.parentCategory.id:''
        this.categoryId_two = object.childrenCategory?object.childrenCategory.id:''
        for(let i in optionList){
          optionList[i].mutex = optionList[i].inconsistentOptionIds?optionList[i].inconsistentOptionIds.split(','):[];
          for(let n in optionList[i].mutex){
            optionList[i].mutex[n] = Number(optionList[i].mutex[n])
          }
          optionList[i].isActive = optionList[i].isActive == 1;
        }
        this.optionList = optionList

      }else{
        this.optionList=[]
        this.question={
          type:1,
          name:'',
          id:'-1',
          isRequired:1,
          isTemplate:0,
          surveyId:0,
          isOther:0,
          isRandom:1,
          optionCountMax:'',
          optionCountMin:'',
          img:'',
          minTime: 0,
          questionGroup: 0,
          isGroupRandom: 0,
          isWithinGroupRandom: 0,
          associatedId: 0,
          categoryIds: [],
          isFollowUp: 0,
          followUpCount: 0,
        }
        this.categoryId_one = ''
        this.categoryId_two = ''
      }
    },
    // 选项间互斥设置
    setMutex(row) {
      console.log(row)
      let optionList = this.optionList;
      for(let i in optionList){
        if(row.id === optionList[i].id) continue;
        if(row.mutex.indexOf(optionList[i].id)!=-1){
          if(optionList[i].mutex.indexOf(row.id)==-1){
            optionList[i].mutex.push(row.id)
          }
        }else{
          if(optionList[i].mutex.indexOf(row.id)!=-1){
            optionList[i].mutex.splice(optionList[i].mutex.indexOf(row.id),1)
          }
        }
      }
    },

    getTargetAudienceInfo(){                    // 获取标签接口
      this.loading = true;
      global_api.QueryTagListByTagSearchVo({cunPage:0,pageSize:0}).then((res)=>{     //获取标签列表
        this.loading = false;
        let list = res.result;
        if(this.question.categoryId){
          let o = {};
          d(list,[]);
          this.question.categoryIds=o[this.question.categoryId];
          function d (list,arr){
            for(let i in list){
              if(o[list[i].id]){
                o[list[i].id].push(...arr,list[i].id);
              }else{
                o[list[i].id] = [...arr,list[i].id];
              }
              if(list[i].tagRespDTOS){
                d(list[i].tagRespDTOS,o[list[i].id]);
              }
            }

          }
        }
        this.groupList = list;
        let allTags = this.getTagName(list,[],[]);
        for(let i in this.optionList){
          for(let n in allTags){
            if(this.optionList[i].tagId === allTags[n].id){
              this.optionList[i].tagName = allTags[n].value;
              this.optionList[i].parentTags = allTags[n].parentTags;
              break ;
            }
          }
        }
      },err=>{
        this.loading = false;
        ElMessage.error('获取标签失败')
      })
    },
    // 递归
    getTagName(data,allTags,parentName){

      for(let i in data){
        let parentTags = JSON.parse(JSON.stringify(parentName));
        parentTags.push({
          id: data[i].id,
          name: data[i].name,
          parentId: data[i].parentId
        })
        if(data[i].tagRespDTOS.length){
          allTags = this.getTagName(data[i].tagRespDTOS,allTags,parentTags)
        }
        if(data[i].tags.length){
          for(let n in data[i].tags){
            data[i].tags[n].parentTags = JSON.parse(JSON.stringify(parentTags));
          }
          allTags.push(...data[i].tags)
        }
      }
      return allTags;
    },
    openTag(scope){
      this.tagDialog = true;
      this.scopeIndex = scope.$index;
      this.tagId = '';
    },
    // 点击标签
    handleNodeClick(e){
      console.log(e)
      this.tags = e.tags || [];
      this.selectTagId = '';
      this.selectTagName = '';
      this.tagId = e.id;
    },
    // 选择
    handleCurrentChange(e){
      console.log(e)
      if(e){
        this.selectTagId = e.id;
        this.selectTagName = e.value;
      }
    },
    // 确认选择
    sureBtn(){
      if(!this.selectTagId) return ElMessage.error('请选择标签');
      let allTags = this.getTagName(this.groupList,[],[]);
      console.log(allTags)
      for(let n in allTags){
        if(this.selectTagId === allTags[n].id){
          this.optionList[this.scopeIndex].tagId = this.selectTagId;
          this.optionList[this.scopeIndex].tagName = this.selectTagName;
          this.optionList[this.scopeIndex].parentTags = allTags[n].parentTags;
          break ;
        }
      }
      this.tagDialog = false;
    },
    // 打开编辑
    openAddTag(){
      if(!this.tagId) return ElMessage.error('请选择标签分类');
      this.tag_dialog=true;
      this.item_obj = {
        isLongTerm: 0,
        isObvious: 0,
        value: this.optionList[this.scopeIndex].name,
        parentId: this.tagId
      };
    },
    updataTag(){
      this.getTargetAudienceInfo();
      this.tag_dialog = false;
      this.tags = [];
    },

    change_init(){
      this.optionList=[]
      this.categoryId_one = ''
      this.categoryId_two = ''
      this.question={
        type:this.question.type,
        name:'',
        id:'-1',
        isRequired:1,
        isTemplate:0,
        surveyId:0,
        isOther:0,
        isRandom:1,
        optionCountMax:'',
        optionCountMin:'',
        img:'',
        minTime: 0,
        questionGroup: 0,
        isGroupRandom: 0,
        isWithinGroupRandom:0,
        categoryIds: [],
        isFollowUp: 0,
        followUpCount: 0,
      }
      if(this.question.type==2){
        this.question.optionCountMin = 1
        this.question.optionCountMax = 100
      }
      if(this.question.type==7){
        this.question.optionCountMin = 0
        this.question.optionCountMax = 10
        this.question.minName = '不满意'
        this.question.maxName = '满意'
      }
      // console.log(this.question.type)
      if(this.question.type==10){
        this.optionList=[{name:'',
          iconDefault:'',
          iconSelect:'',
          img:'',
          content:'',
          optionPosition:'',
          isActive:1
          },{name:'',
          iconDefault:'',
          iconSelect:'',
          img:'',
          content:'',
          optionPosition:'',
          isActive:1}]
      }
    },

    add_item(){      //添加选项
      let optionList = this.optionList;
      let obj = {
        name:'',
        iconDefault:'',
        iconSelect:'',
        img:'',
        content:'',
        optionPosition:'',
        isActive: true,
      }
      if(this.question.type==8 || this.question.type==9 || this.question.type==22){
        obj.optionPosition = 'left'
      }
      optionList.push(obj);
      for(let i in optionList){
        console.log(optionList[i])
        if(optionList[i].content === '其他'){
          optionList.push(...optionList.splice(i,1));
        }
      }
      this.optionList = optionList;
    },

    // 其他选项开启切换
    other_item_change(e){
      console.log(e)
      if(e){
        this.add_other_item();
      } else {
        this.remove_other_item();
      }
    },

    // 添加一个实体其他选项
    add_other_item: async () => {
      that.loading = true;
      let iconDefault = await getKvMapById({kvId:2});
      let iconSelect = await getKvMapById({kvId:3});
      that.loading = false;
      let obj = {
        name:'其他',
        iconDefault:iconDefault.result.kvValue,
        iconSelect:iconSelect.result.kvValue,
        img:'',
        content:'其他',
        optionPosition:'',
        isActive: true,
      }
      that.optionList.push(obj)
    },
    // 删除实体其他选项
    remove_other_item(){
      let optionList = JSON.parse(JSON.stringify(this.optionList));
      this.optionList = optionList.filter(item => item.name !== '其他');
    },

    Is_delete(scope){           //是否删除
      ElMessageBox.confirm(
          '是否删除?',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        if(scope.row.name==='其他'){
          this.question.isOther = 0;
          this.remove_other_item();
        }else{
          let optionList = this.optionList
          optionList.splice(scope.$index,1);
          this.optionList = optionList
        }

      })
    },
    submit(){        //提交
      let surveyId = this.$route.query.surveyId;
      console.log(this.question);
      let obj = {
        id:1,               //管理员ID
        type:this.question.type,
        questionName:this.question.name,
        questionId:this.question.id?this.question.id:'-1',
        isRequired:this.question.isRequired,
        isTemplate:this.question.isTemplate,
        img:this.question.img,
        insertType:surveyId?1:0,
        categoryId:this.question.categoryIds?.length?this.question.categoryIds[this.question.categoryIds.length - 1]:0,
        surveyId:surveyId?surveyId:(this.question.surveyId?this.question.surveyId:0),
        optionList:[],
        questionGroup:this.question.questionGroup,
        isGroupRandom:this.question.isGroupRandom,
        isWithinGroupRandom:this.question.isWithinGroupRandom,
        minTime:this.question.minTime,
        associatedId:this.question.associatedId||0,
        isFollowUp:this.question.isFollowUp,
        followUpCount:this.question.followUpCount,
      }
      // console.log(obj);
      if(this.question.type==1){
        obj.isOther = this.question.isOther
        obj.isRandom = this.question.isRandom
      }
      if(this.question.type==2){
        obj.isOther = this.question.isOther
        obj.isRandom = this.question.isRandom
        obj.optionCountMax = this.question.optionCountMax
        obj.optionCountMin = this.question.optionCountMin
      }
      if(this.question.type==3){
        obj.optionList = this.optionList.length>=1?[]:[{name:'默认选项',iconDefault:'',iconSelect:'',img:'',content:'',tagId:0}]
      }
      if(this.question.type==4){
        obj.isRandom = this.question.isRandom
        obj.isOther = this.question.isOther
        obj.optionList = this.optionList.length>=1?[]:[{name:'默认选项',iconDefault:'',iconSelect:'',img:'',content:'',tagId:0}]
      }
      if(this.question.type==5){
        obj.optionCountMax = this.question.optionCountMax
        obj.optionCountMin = 1
        obj.minName = this.question.minName
        obj.maxName = this.question.maxName
        obj.optionList = this.optionList.length>=1?[]:[{name:'默认选项',iconDefault:'',iconSelect:'',img:'',content:'',tagId:0}]
      }
      if(this.question.type==6){
        obj.optionList = this.optionList.length>=1?[]:[{name:'默认选项',iconDefault:'',iconSelect:'',img:'',content:'',tagId:0}]
      }
      if(this.question.type==7){
        obj.optionCountMin = this.question.optionCountMin
        obj.optionCountMax = this.question.optionCountMax
        obj.minName = this.question.minName
        obj.maxName = this.question.maxName
        obj.optionList = this.optionList.length>=1?[]:[{name:'默认选项',iconDefault:'',iconSelect:'',img:'',content:'',tagId:0}]
      }
      if(this.question.type==8 || this.question.type==22){
        obj.optionList = this.optionList.length>=1?[]:[{name:'默认选项',iconDefault:'',iconSelect:'',img:'',content:'',tagId:0}]
      }
      if(this.question.type==9){
        obj.optionList = this.optionList.length>=1?[]:[{name:'默认选项',iconDefault:'',iconSelect:'',img:'',content:'',tagId:0}]
        obj.optionCountMax = this.question.optionCountMax
        obj.optionCountMin = this.question.optionCountMin
      }
      if(this.question.type==10){
        obj.img = this.question.img
      }
      if(this.question.type==11){
        obj.optionCountMin = this.question.optionCountMin
        obj.optionCountMax = this.question.optionCountMax
        obj.minName = this.question.minName
        obj.maxName = this.question.maxName
        obj.optionList = this.optionList.length>=1?[]:[{name:'默认选项',iconDefault:'',iconSelect:'',img:'',content:'',tagId:0}]
      }
      if(this.question.type==12){
        obj.isOther = this.question.isOther
        obj.isRandom = this.question.isRandom
      }
      if(this.question.type==13){
        obj.isOther = this.question.isOther
        obj.isRandom = this.question.isRandom
      }
      if(this.question.type==14){
        obj.optionCountMax = this.question.optionCountMax
        obj.isOther = this.question.isOther
        obj.isRandom = this.question.isRandom
        obj.minName = this.question.minName
        obj.maxName = this.question.maxName
      }
      if(this.question.type==15){
        obj.optionCountMin = this.question.optionCountMin
        obj.optionCountMax = this.question.optionCountMax
        obj.isRandom = this.question.isRandom
        obj.minName = this.question.minName
        obj.maxName = this.question.maxName
      }
      if(this.question.type==18){
        obj.optionList = this.optionList.length>=1?[]:[{name:'默认选项',iconDefault:'',iconSelect:'',img:'',content:'',tagId:0}];
        obj.optionCountMax = this.question.optionCountMax;
        obj.optionCountMin = this.question.optionCountMin;
      }
      for(let i=0;i<this.optionList.length;i++){
        if(Array.isArray(this.optionList[i].tagId)){
          if(this.optionList[i].tagId.length==2){
            this.optionList[i].tagId = this.optionList[i].tagId[1]
          }else{
            this.optionList[i].tagId = this.optionList[i].tagId[0]
          }
        }
        this.optionList[i].inconsistentOptionIds  = this.optionList[i].mutex?this.optionList[i].mutex.join(','):'';
        this.optionList[i].isActive = this.optionList[i].isActive?1:0;
        obj.optionList.push({...this.optionList[i]})
      }
      // console.log(obj)
      // return false
      if(this.validate(obj)){
        obj.optionList = JSON.stringify(obj.optionList)
        this.InsertOrUpdateQuestion(obj)
      }

    },

    validate(obj){                       //验证
      if(obj.questionName.length<=0){
        ElMessage.error('题目名称必填')
        return false
      }
      if(obj.isRequired.length<=0){
        ElMessage.error('必须回答必填')
        return false
      }
      if(obj.optionList.length<=0){
        ElMessage.error('添加选项内容必填')
        return false
      }
      if(obj.type==1 && obj.isFollowUp && obj.followUpCount <= 0){
        ElMessage.error('GPT题目数量必填')
        return false
      }
      if(obj.type==2 && !obj.optionCountMin){
        ElMessage.error('最小选项必填')
        return false
      }
      if(obj.type==2 && !obj.optionCountMax){
        ElMessage.error('最大选项必填')
        return false
      }
      if(obj.type==3 && obj.isFollowUp && obj.followUpCount <= 0){
        ElMessage.error('GPT题目数量必填')
        return false
      }
      if(obj.type==5 && !obj.optionCountMin){
        ElMessage.error('最小选项必填')
        return false
      }
      if(obj.type==5 && !obj.optionCountMax){
        ElMessage.error('最大选项必填')
        return false
      }
      if(obj.type==7 && !obj.optionCountMin){
        ElMessage.error('最小选项必填')
        return false
      }
      if(obj.type==7 && !obj.optionCountMax){
        ElMessage.error('最大选项必填')
        return false
      }
      if(obj.type==7 && !obj.minName){
        ElMessage.error('最小文本必填')
        return false
      }
      if(obj.type==7 && !obj.maxName){
        ElMessage.error('最大文本必填')
        return false
      }
      if(obj.type==9 && !obj.optionCountMin){
        ElMessage.error('最小选项必填')
        return false
      }
      if(obj.type==9 && !obj.optionCountMax){
        ElMessage.error('最大选项必填')
        return false
      }
      if(obj.type==10 && !obj.img){
        ElMessage.error('图片必须上传')
        return false
      }
      if(obj.type==11 && !obj.optionCountMin){
        ElMessage.error('最小选项必填')
        return false
      }
      if(obj.type==11 && !obj.optionCountMax){
        ElMessage.error('最大选项必填')
        return false
      }
      if(obj.type==11 && !obj.minName){
        ElMessage.error('最小文本必填')
        return false
      }
      if(obj.type==11 && !obj.maxName){
        ElMessage.error('最大文本必填')
        return false
      }
      if(!obj.minTime&&obj.minTime!==0){
        ElMessage.error('答题时长必填')
        return false
      }
      // if(!obj.questionGroup&&obj.questionGroup!==0){
      //   ElMessage.error('题目乱序必填')
      //   return false
      // }
      if(obj.type==15 && !obj.optionCountMin){
        ElMessage.error('最小选项必填')
        return false
      }
      if(obj.type==15 && !obj.optionCountMax){
        ElMessage.error('最大选项必填')
        return false
      }
      if(obj.type==16) {
        let optionList = obj.optionList;
        if(!optionList.length) {
          ElMessage.error('不能没有选项')
          return false
        }
        let topNum = 0, leftNum = 0;
        for(let i in optionList){
          if(optionList[i].optionPosition == 'up') topNum += 1;
          if(optionList[i].optionPosition == 'left') leftNum += 1;
        }
        if(topNum<1||top>2) {
          ElMessage.error('请设置1~2个上方选项')
          return false
        }
        if(leftNum<1) {
          ElMessage.error('没有左侧选项')
          return false
        }
      }
      return true
    },

    InsertOrUpdateQuestion(obj){     //新增/修改题目
      this.loading = true;
      this.global_api.InsertOrUpdateQuestion(obj).then((res)=>{
        ElMessage({
          message: '操作成功',
          type: 'success',
        })
        // 找到改变的标签
        if(this.$route.query.type=='edit'){
          let object = JSON.parse(sessionStorage.getItem('subject_item'))
          let oldOptionList = object.optionList;
          let newOptionList = JSON.parse(obj.optionList);
          let tagIdsChangeObj = {};
          console.log(oldOptionList)
          for(let i in oldOptionList){
            if(oldOptionList[i].tagId||oldOptionList[i].tagId===0){
              tagIdsChangeObj[oldOptionList[i].id] = {oldTagId:oldOptionList[i].tagId}
            }
          }
          for(let i in newOptionList){
            if(newOptionList[i].tagId&&tagIdsChangeObj[newOptionList[i].id]){
              tagIdsChangeObj[newOptionList[i].id].newTagId = newOptionList[i].tagId;
            }
          }
          let param = {
            optionList: []
          }
          console.log(tagIdsChangeObj)
          for(let i in tagIdsChangeObj){
            if(tagIdsChangeObj[i].newTagId&&tagIdsChangeObj[i].newTagId !== tagIdsChangeObj[i].oldTagId){
              param.optionList.push({
                optionId: i,
                oldTagId: tagIdsChangeObj[i].oldTagId,
                newTagId: tagIdsChangeObj[i].newTagId
              })
            }
          }
          if(param.optionList.length){
            param.optionList = JSON.stringify(param.optionList)
            this.loading = true;
            insertOrUpdateQuestionSyncUpdateUserTag(param).then(()=>{
              this.loading = false;
              this.revert();
            },err=>{
              this.loading = false;
              ElMessage.error('标签更新失败')
              this.revert();
            })
          }else{
            this.loading = false;
            this.revert();
          }
        } else {
          this.loading = false;
          this.revert();
        }
      },err=>{
        this.loading = false;
        ElMessage.error('新增/修改题目错误')
      })
    },

    revert(){              //返回
      let bt = this.$route.query.bt;  // 无:返回题目库;1:返回产品题目;2:返回调研题目;3:返回心愿题目;4:返回初始问卷;6:返回签到题目
      console.log(bt)
      if(bt == 1){
        this.$router.push({
          path: '/goods_list/question',
          query: {id:this.$route.query.bid,b:this.$route.query.b,name:this.$route.query.name}
        })
      } else if(bt == 2){
        this.$router.push({
          path: '/survey/question',
          query: {id:this.$route.query.bid,b:this.$route.query.b,name:this.$route.query.name}
        })
      } else if(bt == 3){
        this.$router.push({
          path: '/Cherished_desire_list/Subject_list',
          query: {id:this.$route.query.bid,b:this.$route.query.b,name:this.$route.query.name}
        })
      } else if(bt == 4){
        this.$router.push({
          path: '/Member_detail/initial',
        })
      } else if(bt == 6){
        this.$router.push({
          path: '/sign_in_survey/question',
          query: {id:this.$route.query.surveyId,b:this.$route.query.b,name:this.$route.query.name}
        })
      } else {
        this.$router.push('/Subject_management');
      }
    },

    GetQuestionTypeList(){                 //获取题目类型列表
      this.global_api.GetQuestionTypeList().then((res)=>{
        if(res.code!=0){
          ElMessage.error('获取题目类型列表错误')
        }
        this.questionType_options = res.result
        // console.log(res)
      })
    },
    category_change(){
      for(let i=0;i<this.category_one_options.length;i++){
        if(this.categoryId_one==this.category_one_options[i].id){
          // this.level = this.category_one_options[i].level
          this.categoryId_two = ''
        }
      }
      this.GetCategoryList_two(this.categoryId_one,2)
    },

    GetCategoryList_one(){               //获取大类列表
      this.global_api.GetCategoryList_one().then((res)=>{
        if(res.code!=0){
          ElMessage.error('获取大类列表错误')
        }
        this.category_one_options = res.result
      })
    },

    GetCategoryList_two(pid,level){                //获取小类列表
      return new Promise((resolve, reject)=>{
        this.global_api.GetCategoryList_two({pid,level:level}).then((res)=>{
          if(res.code!=0){
            ElMessage.error('获取小类列表错误')
          }else{
            this.category_two_options = res.result
            resolve(true)
          }
        })
      })
    },
    upload(scope,string){
      this.index = scope.$index
      this.image = string
      // console.log(scope.$index)
    },
    dxt_AvatarSuccess(response,uploadFile){
      this.optionList[this.index][this.image] = uploadFile.response.msg

    },
    dxt_beforeAvatarUpload(rawFile){
      if(rawFile.type == 'video/mp4'){
        if (rawFile.size / 20 / 1024 / 1024 > 1) {
          ElMessage.error('上传的视频超过20MB了！')
          return false
        }
      }else if(/\.(jpg|jpeg|png|JPG|PNG|gif)$/.test(rawFile.type)){
        if (rawFile.size / 1024 / 1024 > 1) {
          ElMessage.error('上传的图片超过1MB了！')
          return false
        }
      }
      return true
    },
    tpt_AvatarSuccess(response,uploadFile){
      this.question.img = uploadFile.response.msg
    },
    tpt_beforeAvatarUpload(rawFile){
      if (rawFile.size / 1024 / 1024 > 1) {
        ElMessage.error('上传的图片超过1MB了！')
        return false
      }
      return true
    },
    uploadError(err) {
      console.error(err);
      ElMessage.error('上传失败，请检查网络或重试');
    },
    AvatarSuccess(response,uploadFile){
      console.log(uploadFile)
      this.question.img = uploadFile.response.msg
    },
    beforeAvatarUpload(rawFile){
      ElMessage.error(rawFile);
      console.log(rawFile);
      if (rawFile.type === 'video/mp4') {
        if (rawFile.size / 1024 / 1024 > 20) {
          ElMessage.error('上传的视频超过20MB了！');
          return false;
        }
      } else if (/\.(jpg|jpeg|png|JPG|PNG|gif)$/.test(rawFile.type)) {
        if (rawFile.size / 1024 / 1024 > 100) {
          ElMessage.error('上传的图片超过1MB了！');
          return false;
        }
      }

      return true
    },
    openEditdialog(){
      this.editDialog = true;
    },
    getContent(data){
      this.editorTxt = data;
    },
    closeEditDialog(){
      this.question.name = this.editorTxt;
      this.editDialog = false;
    },


    // 根据题目选项批量
    addTags(){
      let list = this.optionList;
      console.log(list);
      // return ;
      for(let i in list){
        console.log(i);
        if(list[i]?.tagId==0&&list[i]?.name!='其他'){
          let param = {
            isLongTerm: 1,
            isObvious: 1,
            value: `${this.question.name.replace(/<.+?>/g,'')}-${list[i].name}`,
            parentId: 1315,
            isMutex: 0,
            extras: ""
          }
          global_api.AddTag(param).then(res=>{
            ElMessage.success('添加成功');
            list[i].tagId = res.data.id;
            this.getTargetAudienceInfo();
            this.optionList = list;
          },err=>{
            ElMessage.error('添加失败')
          })
        }
      }
    },

    // 打开选项关联弹窗
    openOptionLinkSet(){
      let questionList = JSON.parse(sessionStorage.getItem('question_list'));
      let optionData = [...this.optionList];
      this.$refs.optionLink.open();
      this.questionList = questionList;
      this.optionData = optionData;
    },
    // 接收同步过的数据
    optionR(e){
      this.optionList = [...e.data];
      this.question.associatedId = e.id;
    }
  },
}
</script>
<style scoped lang="scss">
  .Subject_management_edit{
    margin-top: 20px;
  }
  .submit_btn{
    text-align: right;
    width: 100%;
    margin-top: 20px;
  }
  .upload_img{
    display: inline-block;
    margin-left: 20px;
  }
  .avatar{
    width: 40px;
    height: 40px;
  }
  .top_avatar{
    width: 100px;
    height: 100px;
  }
  .tpt_avatar{
    width: 150px;
    height: 150px;
  }
  .upload_img_txt{
    color: red;
    margin-top: 10px;
    font-size: 14px;
  }
  .start_items{
    .title{
      width: auto!important;
    }
  }
  .videoBox{
    width: 200px;
    height: 200px;
  }
  .tool {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  background-color: #f1f1f1;
  span {
    height: 32px;
    padding: 0px 8px 0 6px;
    font-size: 16px;
    position: relative;
    line-height: 32px;
    color: #666;
    cursor: pointer;
    &:hover{
      color: rgb(17, 145, 219);
    }
    &::after{
      content: '';
      width: 2px;
      height: 16px;
      background-color: #666;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
    &:last-child::after{
      content: "";
      width: 0;
    }
  }
}
.editor {
  height: 200px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  div {
    font-size: 18px;
    &:focus{outline: none;}
  }
}
.foot {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 50px;
}
</style>
