<template>
  <div v-loading="loading">
    <section class="searchForm">
      <el-form inline>
        <el-form-item>
          <el-button type="primary" @click="openDialog">添加</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="list">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="content" label="描述"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="primary" @click="removeBrandAuthSpecialEvent(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="cunPage"
        :page-size="10"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="getList"
      />
    </section>

    <!-- 选择问卷弹窗 -->
    <el-dialog v-model="dialog" title="选择调研问卷">
      <selectsurvey @select="select"/>
      <template #footer>
        <el-button @click="dialog = false;">取消</el-button>
        <el-button type="primary" @click="addBrandAuthSpecialEvent">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {queryBrandAuthSpecialEventListByBrandAuthId,addBrandAuthSpecialEvent,removeBrandAuthSpecialEvent} from '@/config/Api'
import selectsurvey from '@/components/selectSurvey.vue';
import { ElMessage } from 'element-plus';
export default {
  data() {
    return {
      cunPage: 1,
      total: 0,
      list: [],
      loading: false,
      accountId: null,
      dialog: false,
      survey: {},
    }
  },
  components: {
    selectsurvey
  },
  mounted() {
    let {accountId} = this.$route.query;
    this.accountId = accountId;
    this.cunPage = 1;
    this.getList();
  },
  methods: {
    // 获取数据
    getList() {
      this.loading = true;
      let param = {
        brandAuthAdminId: this.accountId,
        cunPage: this.cunPage,
        pageSize: 10,
      }
      queryBrandAuthSpecialEventListByBrandAuthId(param).then(res => {
        this.loading = false;
        this.list = res.result;
        this.total = res.data.totalCount;
      },err => {
        this.loading = false;
        this.list = [];
      })
    },
    // 添加
    openDialog() {
      this.dialog = true;
    },
    // 选择问卷
    select(e){
      console.log(e);
      this.survey = e;
    },
    // 添加问卷
    addBrandAuthSpecialEvent() {
      this.loading = true;
      let param = {
        brandAuthAdminId: this.accountId,
        specialEventId: this.survey.id,
      }
      addBrandAuthSpecialEvent(param).then(res => {
        this.loading = false;
        this.getList();
        this.dialog = false;
      },err => {
        this.loading = false;
        ElMessage.error('添加调研问卷失败')
      })
    },
    // 删除问卷
    removeBrandAuthSpecialEvent(row) {
      this.loading = true;
      let param = {
        brandAuthAdminId: this.accountId,
        specialEventId: row.id,
      }
      removeBrandAuthSpecialEvent(param).then(res => {
        this.loading = false;
        this.getList();
      },err => {
        this.loading = false;
        ElMessage.error('删除失败')
      })
    }
  }
}
</script>