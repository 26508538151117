<template>
  <div class="Crowd_library_list" v-loading="loading">
    <section class="searchForm">
      <el-form :inline="true" :model="formData">
        <el-form-item label="人群名称:">
          <el-input v-model="formData.targetName" placeholder="用户类别"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :icon="Search" @click="search">查询</el-button>
          <el-button type="primary" @click="clearSearch">清空</el-button>
          <el-button type="danger" @click="addCrowd">新增人群</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="tableList">
      <el-table :data="tableData">
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column  prop="name" label="人群名称" />
        <el-table-column  prop="content" label="人群描述" />
        <el-table-column  prop="createTime" label="创建时间"/>
        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-tag class="ml-2" @click="edit_throng(scope.row)">编辑</el-tag>
            <el-tag class="ml-2" type="success" @click="Is_delete(scope.row)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination 
        v-model:page-size="pageSize" 
        v-model:currentPage="pageNo" 
        background 
        layout="prev, pager, next" 
        :total="total" 
        @current-change="handleCurrentChange"
      />
    </section>
  </div>
</template>

<script>
import {Search,Plus} from '@element-plus/icons-vue'
import {ElMessage, ElMessageBox} from "element-plus";
import {GetTargetAudienceList, DeleteTargetAudienceInfo} from "../../config/Api";
export default {
  name: 'Crowd_library_list',
  data(){
    return {
      Search:Search,    //图标
      pageNo: 1,
      pageSize: 15,
      total: 1,
      formData:{
      },
      tableData:[],
      loading: false,
    }
  },
  mounted() {
    let query = this.$route.query;
    this.pageNo = Number(query.page || 1);
    this.pageSize = Number(query.size || 15);
    this.GetTargetAudienceList();
  },
  methods: {
    // 获取人群库列表
    GetTargetAudienceList(){
      let param = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        ...this.formData,
      }
      this.loading = true;
      GetTargetAudienceList({params:param}).then(res => {
        this.loading = false;
        this.tableData = res.result;
        this.total = res.data.totalCount
      },err => {
        this.loading = false;
        ElMessage(`获取人群库列表失败：${err.msg}`)
      })
    },
    // 上一页下一页
    handleCurrentChange(){
      this.$router.push({
        path: "/Crowd_library_list",
        query: { page: this.pageNo, size: this.pageSize },
      });
      this.GetTargetAudienceList();
    },
    // 搜索
    search(){
      this.pageNo = 1;
      this.$router.push({
        path: "/Crowd_library_list",
        query: { page: this.pageNo, size: this.pageSize },
      });
      this.GetTargetAudienceList();
    },
    // 清除搜索
    clearSearch(){
      this.formData = {};
      this.pageNo = 1;
      this.$router.push({
        path: "/Crowd_library_list",
        query: { page: this.pageNo, size: this.pageSize },
      });
      this.GetTargetAudienceList();
    },
    // 删除
    Is_delete(row) {                          // 是否删除
      ElMessageBox.confirm(
          '是否删除?',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        DeleteTargetAudienceInfo({targetAudienceId:row.id}).then(res => {
          this.GetTargetAudienceList();
          ElMessage({
            type: 'success',
            message: '已删除',
          })
        })
      })
    },
    // 新增
    addCrowd(){
      this.$router.push({
        path: '/Crowd_library_list/edit',
        query: {b: `${this.pageNo}_${this.pageSize}`,type:'add'}
      })
    },
    // 新增人群
    edit_throng(row){
      sessionStorage.setItem('crowd',JSON.stringify(row))
      this.$router.push({
        path: '/Crowd_library_list/edit',
        query: {b: `${this.pageNo}_${this.pageSize}`,type:'edit'}
      })
    },

    
  },
}
</script>
<style scoped lang="scss">

</style>
