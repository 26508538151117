<template>
  <div v-loading.fullscreen.lock='loading'>
    <section class="searchForm">
      <el-form inline>
        <el-form-item label="敏感词">
          <el-input v-model="formData.word"></el-input>
        </el-form-item>
        <el-form-item label="敏感词">
          <el-select v-model="formData.type">
            <el-option label="高风险" :value="1"></el-option>
            <el-option label="中风险" :value="2"></el-option>
            <el-option label="低风险" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="formData.remark"></el-input>
        </el-form-item>
        <el-form-item label="分类">
          <el-input v-model="formData.trade"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="search">搜索</el-button>
          <el-button size="small" @click="clearSearch">清除</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="searchForm">
      <el-table :data="tableData">
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="敏感词" prop="word"></el-table-column>
        <el-table-column label="风险等级" prop="type">
          <template #default="scope">
            <el-select size="small" v-model="scope.row.type" style="width:100px">
              <el-option label="高风险" :value="1"></el-option>
              <el-option label="中风险" :value="2"></el-option>
              <el-option label="低风险" :value="3"></el-option>
            </el-select> &nbsp;
            <el-button size="small" type="primary" v-if="scope.row.type !== scope.row.oldType" @click="modifyType(scope)">保存</el-button>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="分类" prop="trade"></el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="pageCurrent"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChangePage"
      />
    </section>
  </div>
</template>
<script>
import {querySensitiveWordListBySensitiveWordSearchVo,modifySensitiveWordTypeBySensitiveWordId} from '../../config/Api'
export default {
  data() {
    return {
      pageCurrent: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      tableData: [],
      formData: {},
    }
  },
  mounted() {
    let quety = this.$route.query;
    this.pageCurrent = Number(quety.page || 1);
    this.pageSize = Number(quety.size || 20);
    this.getList();
  },
  methods: {
    // 上下页
    handleCurrentChangePage(){
      this.$router.push({
        path: "/sensitive",
        query: { page: this.pageCurrent, size: this.pageSize },
      });
      this.getList();
    },
    // 获取数据
    getList() {
      this.loading = true;
      let param = {
        cunPage: this.pageCurrent,
        pageSize: this.pageSize,
        ...this.formData
      }
      querySensitiveWordListBySensitiveWordSearchVo(param).then(res=>{
        this.loading = false;
        res.result.forEach(item => {
          console.log(item.trade);
          item.trade = JSON.parse(item.trade).join(',');
          item.oldType = item.type;
        })
        this.tableData = res.result;
        this.total = res.data.totalCount;
      },err=>{
        this.loading = false;
      })
    },
    // 搜索
    search(){
      this.pageCurrent = 1;
      this.getList();
    },
    // 清除搜索
    clearSearch(){
      this.formData = {};
      this.pageCurrent = 1;
      this.getList();
    },
    // 修改风险等级
    modifyType(scope){
      console.log(scope)
      this.loading = true;
      let param = {
        sensitiveWordId: scope.row.id,
        type: scope.row.type
      }
      modifySensitiveWordTypeBySensitiveWordId(param).then(res=>{
        this.loading = false;
        this.tableData[scope.$index].oldType = scope.row.type;
      },err=>{
        this.loading = false;
      })
    }
  }
}
</script>