<!-- @format -->

<template>
	<div class="Tag_management" v-loading.fullscreen.lock="loading">
		<section class="tableList">
			<div class="treeBox">
				<el-button type="primary" @click="selectFile">导入</el-button>
				<el-button @click="exportFile">导出</el-button>
				<el-link style="margin-left: 4px" @click="downfile('/xlsx/标签分类模板.xlsx', '标签分类模板')"
					>下载模板</el-link
				>
				<el-input v-model="filterText" placeholder="查询标签分类" @change="inputChange"></el-input>
				<el-tree
					ref="treeRef"
					:data="treeData"
					:props="props"
					draggable
					@node-click="handleNodeClick"
					@node-drop="nodeDrop"
					node-key="id"
					:default-expanded-keys="currentNode"
					:filter-node-method="filterNode">
					<template #default="{ node, data }">
						<div class="treeItem">
							<span :class="data.id === tagId ? 'check' : ''">{{ node.label }}</span>
							<div class="treeItemBtn">
								<el-link type="primary" @click.stop="addClass(data)">添加</el-link>
								<el-link class="ml-2" type="primary" @click.stop="modifyClass(data)">修改</el-link>
								<el-link class="ml-2" type="danger" @click.stop="removeClass(data)">删除</el-link>
							</div>
						</div>
					</template>
				</el-tree>
			</div>
			<div class="tagsBox">
				<p>{{ `标签组ID：${tagId},标签组名：${tagName}` }}</p>
				<el-button v-if="tagName" type="primary" @click="addTag">添加</el-button>
				<el-button v-if="tagName && multipleSelection.length" type="primary" @click="removeTagsByTagIds">
          批量删除
        </el-button>
        <el-button v-if="tagName && multipleSelection.length" type="primary" @click="openEditDialog">
          批量修改
        </el-button>
				<el-table :data="tags" @selection-change="handleSelectionChange">
					<el-table-column label="标签ID" prop="id"></el-table-column>
					<el-table-column label="标签名" prop="value"></el-table-column>
					<el-table-column label="标签备注" prop="extras"></el-table-column>
					<el-table-column label="使用人群数">
						<template #default="scope">
							{{ tagPeoLoading || tagPeoObj[Number(scope.row.id)] || 0 }}
						</template>
					</el-table-column>
					<el-table-column label="拥有人数">
						<template #default="scope">
							{{ tagNumberLoading || tagNumberObj[Number(scope.row.id)] || 0 }}
						</template>
					</el-table-column>
					<el-table-column label="活跃人数">
						<template #default="scope">
							{{ tagNumberLoading || activeUserNumObj[Number(scope.row.id)] || 0 }}
						</template>
					</el-table-column>
					<el-table-column label="关联题目数">
						<template #default="scope">
							{{ tagQuesLoading || tagQuesObj[scope.row.id]?.length || 0 }}
							<el-button v-if="tagQuesObj[scope.row.id]" @click="openTagQues(tagQuesObj[scope.row.id])">查看</el-button>
						</template>
					</el-table-column>
					<el-table-column label="创建时间" prop="createTime"></el-table-column>
					<el-table-column label="批量删除" type="selection"></el-table-column>
					<el-table-column label="操作" width="200px">
						<template #default="scope">
							<el-button size="small" @click="modifyTag(scope.row)">修改</el-button>
							<el-button size="small" type="primary" @click="removeTag(scope)">删除</el-button>
							<el-button size="small" type="success" @click="openMoveDialog(scope)">移动</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</section>

		<el-dialog v-model="this.tag_dialog" title="编辑标签" width="500px" draggable>
			<addtag :type="addtagType" :value="item_obj" @updata="updataTag" @close="tag_dialog = false"></addtag>
		</el-dialog>
		<el-dialog v-model="classDialog" title="编辑分类" width="500px">
			<el-form>
				<el-form-item label="名称">
					<el-input v-model="classObj.name"></el-input>
				</el-form-item>
				<el-form-item label="CEM类别">
					<el-select v-model="classObj.flag">
						<el-option label="未选择" :value="0"></el-option>
						<el-option label="（人群筛选）必填标签类" :value="1"></el-option>
						<el-option label="（人群筛选）选填标签类" :value="2"></el-option>
						<!-- <el-option label="用户选择" :value="3"></el-option> -->
						<el-option label="（产品分类）产品分类" :value="4"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="CEM 是否显示">
					<el-select v-model="classObj.isShow">
						<el-option label="不显示" :value="0"></el-option>
						<el-option label="显示" :value="1"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<template #footer>
				<el-button @click="classDialog = false">取消</el-button>
				<el-button v-if="!classObj.tagCategoryId" type="primary" @click="submitAddClass">确定</el-button>
				<el-button v-else type="primary" @click="submitModifyClass">修改</el-button>
			</template>
		</el-dialog>
		<!--  标签移动  -->
		<el-dialog v-model="moveDialog" title="标签移动" width="1900px">
			<div class="moveBox">
				<div class="moveBoxLeft">
					<el-transfer
						v-model="rightData"
						:props="{
							key: 'id',
							label: 'value',
						}"
						:data="leftData"
						:titles="titles" />
				</div>
				<div class="moveBoxRight">
					<el-tree :data="treeData" :props="props" accordion @node-click="moveNodeClick"></el-tree>
				</div>
			</div>

			<template #footer>
				<el-button @click="moveDialog = false">取消</el-button>
				<el-button type="primary" @click="submitMove">确定</el-button>
			</template>
		</el-dialog>
		<!--  显示结果  -->
		<el-dialog v-model="resDialog" title="批量结果" width="700px">
			<el-tabs v-model="activeName">
				<el-tab-pane label="成功" name="first">
					<el-table :data="successData">
						<el-table-column label="level0" prop="level0"></el-table-column>
						<el-table-column label="level1" prop="level1"></el-table-column>
						<el-table-column label="level2" prop="level2"></el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="失败" name="second">
					<el-table :data="failData">
						<el-table-column label="level0" prop="level0"></el-table-column>
						<el-table-column label="level1" prop="level1"></el-table-column>
						<el-table-column label="level2" prop="level2"></el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>

			<template #footer>
				<el-button type="primary" @click="resDialog = false">确定</el-button>
			</template>
		</el-dialog>
		<!-- 查看关联题目信息 -->
		<el-dialog v-model="tagQuesDialog" title="关联题目" width="700px">
			<el-table :data="tagQuesData">
				<el-table-column label="问题" prop="question.name"></el-table-column>
				<el-table-column label="问题id" prop="questionId"></el-table-column>
				<el-table-column label="选项" prop="questionOption.name"></el-table-column>
				<el-table-column label="选项id" prop="optionId"></el-table-column>
				<el-table-column label="题目使用数" prop="qnum"></el-table-column>
				<el-table-column label="选项使用数" prop="onum"></el-table-column>
				<el-table-column label="占比" prop="r">
					<template #default="scope">
						{{ scope.row.r * 100 || 0 }}
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
    <!-- 批量修改标签唯一性 -->
    <el-dialog v-model="editDialog" title="批量修改" width="500px">
      <el-form>
				<el-form-item label="标签时间">
					<el-select v-model="editData.isLongTerm">
						<el-option label="短期标签" :value="0"></el-option>
						<el-option label="长期标签" :value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="标签状态">
					<el-select v-model="editData.isObvious">
						<el-option label="显性标签" :value="0"></el-option>
						<el-option label="隐形标签" :value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="唯一性">
					<el-select v-model="editData.isMutex">
						<el-option label="唯一标签" :value="0"></el-option>
						<el-option label="非唯一标签" :value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="extras">
					<el-input style="width:210px" v-model="editData.extras"></el-input>
				</el-form-item>
    	</el-form>
      <template #footer>
        <el-button @click="editDialog = false">取消</el-button>
        <el-button type="primary" @click="editTags">确定</el-button>
      </template>
    </el-dialog>


	</div>
</template>

<script>
	let leftData = [];
	let moveParam = {};
	import * as XLSX from "xlsx";
	import { ElMessage, ElMessageBox } from "element-plus";
	import global_api, {
		modifyTagCategorySortBatch,
		addTagCategoryBatch,
		removeTagsByTagIds,
		queryUserCountByTagIds,
		queryAnswerCountByTagIds,
		queryTargetCountByTagIds,
	} from "@/config/Api";
	import { downfile } from "@/utils/downfile";
	import exportExcel from "@/utils/excel";
	import addtag from "@/components/addtag";

	let DP = {};
	export default {
		name: "Tag_management",
		data() {
			return {
				loading: false,
				treeData: [],
				props: {
					label: "name",
					children: "tagRespDTOS",
				},
				tags: [],
				tagNumberObj: {},
				tagQuesObj: {},
				activeUserNumObj: {},
				tagNumberLoading: "",
				tagQuesLoading: "",
				activeUserNumLoading: "",
				tagName: "",
				tagId: "",
				item_obj: {},
				tag_dialog: false,
				classDialog: false,
				classObj: {},
				multipleSelection: [],
				filterText: "",
				moveDialog: false,
				leftData: [],
				rightData: [],
				titles: [],
				moveDialogTree: [],
				defaultObj: [],
				currentNode: [],

				cur_status: "", //当前状态
				level0_options: [],
				level1_options: [],
				level2_options: [],
				lisLongTerm_options: [
					{ name: "短期标签", id: 0 },
					{ name: "长期标签", id: 1 },
				],
				isObvious_options: [
					{ name: "显性标签", id: 0 },
					{ name: "隐性标签", id: 1 },
				],
				tagLevel: "",

				resDialog: false,
				failData: [],
				successData: [],
				activeName: "first",

				tagQuesDialog: false,
				tagQuesData: [],

				tagPeoLoading: "",
				tagPeoObj: {},

				addtagType: "add",

        editDialog: false,
				editData: {},
			};
		},
		components: {
			addtag,
		},
		mounted() {
			this.QueryTagListByTagSearchVo();
		},
		methods: {
			downfile(href, name) {
				downfile(href, name);
			},
			// 获取标签
			QueryTagListByTagSearchVo() {
				this.loading = true;
				global_api.QueryTagListByTagSearchVo({ cunPage: 0, pageSize: 0 }).then(
					res => {
						//获取标签列表
						this.loading = false;
						let list = res.result;
						list = this.buildParInfo(list, []);
						if (this.defaultObj.length) {
							let currentNode = [];
							for (let i in this.defaultObj) {
								currentNode.push(this.defaultObj[i].id);
							}
							this.currentNode = currentNode;
						}
						console.log(list);
						list = sort(list);
						this.treeData = list;
						console.log(this.currentNode);

						function sort(data) {
							for (let i = 0; i < data.length - 1; i++) {
								for (let n = i + 1; n < data.length; n++) {
									if (data[i].sort > data[n].sort) {
										let tmp = data[i];
										data[i] = data[n];
										data[n] = tmp;
									}
								}
								if (data[i].tagRespDTOS?.length) {
									data[i].tagRespDTOS = sort(data[i].tagRespDTOS);
								}
							}
							return data;
						}
					},
					err => {
						this.loading = false;
						ElMessage.error("获取标签失败");
					}
				);
			},
			// 递归生成每个节点父级层次信息
			buildParInfo(list, arr) {
				for (let i in list) {
					let parentInfo = JSON.parse(JSON.stringify(arr));
					parentInfo.push({
						id: list[i].id,
						tags: list[i].tags,
					});
					list[i].parentInfo = parentInfo;
					if (list[i].tagRespDTOS.length) {
						list[i].tagRespDTOS = this.buildParInfo(list[i].tagRespDTOS, parentInfo);
					}
				}
				return list;
			},
			// 点击标签
			handleNodeClick(e) {
				console.log(e);
				this.tagName = e.name;
				this.tagId = e.id;
				this.tags = e.tags || [];
				this.multipleSelection = [];
				this.defaultObj = e.parentInfo;
				if (this.tags.length) {
					this.queryUserCountByTagIds();
					this.queryAnswerCountByTagIds();
					this.queryTargetCountByTagIds();
				}
			},
			// 获取标签使用数量
			queryUserCountByTagIds() {
				let tags = this.tags;
				let tagIds = [];
				for (let i in tags) {
					tagIds.push(tags[i].id);
				}
				this.tagNumberLoading = "loading...";
				this.activeUserNumLoading = "loading...";
				queryUserCountByTagIds({ tagIds: tagIds.join(",") }).then(
					res => {
						let tagNumberObj = {};
						let activeUserNumObj = {};
						for (let i in res.result) {
							tagNumberObj[res.result[i].tagId] = res.result[i].userNum;
							activeUserNumObj[res.result[i].tagId] = res.result[i].activeUserNum;
						}
						this.tagNumberObj = tagNumberObj;
						this.tagNumberLoading = "";
						this.activeUserNumObj = activeUserNumObj;
						this.activeUserNumLoading = "";
					},
					err => {
						this.tagNumberObj = {};
						this.tagNumberLoading = "";
						this.activeUserNumObj = {};
						this.activeUserNumLoading = "";
					}
				);
			},
			queryAnswerCountByTagIds() {
				let tags = this.tags;
				let tagIds = [];
				for (let i in tags) {
					tagIds.push(tags[i].id);
				}
				this.tagQuesLoading = "loading...";
				queryAnswerCountByTagIds({ tagIds: tagIds.join(",") }).then(
					res => {
						let tagQuesObj = {};
						for (let i in res.result) {
							if (tagQuesObj[res.result[i].tagId]) {
								tagQuesObj[res.result[i].tagId].push(res.result[i]);
							} else {
								tagQuesObj[res.result[i].tagId] = [res.result[i]];
							}
						}
						this.tagQuesObj = tagQuesObj;
						this.tagQuesLoading = "";
						console.log(tagQuesObj);
					},
					err => {
						this.tagQuesObj = {};
						this.tagQuesLoading = "";
					}
				);
			},
			queryTargetCountByTagIds() {
				let tags = this.tags;
				let tagIds = [];
				for (let i in tags) {
					tagIds.push(tags[i].id);
				}
				this.tagPeoLoading = "loading...";
				queryTargetCountByTagIds({ tagIds: tagIds.join(",") }).then(
					res => {
						let tagPeoObj = {};
						for (let i in res.result) {
							tagPeoObj[res.result[i].tagId] = res.result[i].count;
						}
						this.tagPeoObj = tagPeoObj;
						this.tagPeoLoading = "";
						console.log(tagPeoObj);
					},
					err => {
						this.tagPeoLoading = "loading...";
					}
				);
			},
			// 删除标签
			removeTag(scope) {
				console.log(scope);
				ElMessageBox.confirm("是否删除?", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					let param = {
						tagId: scope.row.id,
					};
					this.loading = true;
					global_api.RemoveTagByTagId(param).then(
						res => {
							this.tags.splice(scope.$index, 1);
							ElMessage.success("删除成功");
							this.loading = false;
						},
						err => {
							this.loading = false;
							ElMessage.error("删除失败");
						}
					);
				});
			},
			// 批量选择
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
			},
			// 批量删除标签
			removeTagsByTagIds() {
				let tagIds = [];
				for (let i in this.multipleSelection) {
					tagIds.push(this.multipleSelection[i].id);
				}
				let param = {
					tagIds: tagIds.join(","),
				};
				this.loading = true;
				removeTagsByTagIds(param).then(
					res => {
						this.loading = false;
						this.QueryTagListByTagSearchVo();
					},
					err => {
						this.loading = false;
						ElMessage.error("批量删除失败");
					}
				);
			},
			// 添加标签
			addTag() {
				this.tag_dialog = true;
				this.addtagType = "add";
				this.item_obj = {
					isLongTerm: "",
					isObvious: "",
					value: "",
					parentId: this.tagId,
					isMutex: 0,
					extras: "",
				};
			},
			// 修改标签
			modifyTag(row) {
				this.tag_dialog = true;
				this.addtagType = "edit";
				this.item_obj = {
					isLongTerm: row.isLongTerm,
					isObvious: row.isObvious,
					value: row.value,
					parentId: this.tagId,
					tagId: row.id,
					isMutex: row.isMutex,
					extras: row.extras,
				};
			},
			// 更新
			updataTag() {
				this.QueryTagListByTagSearchVo();
				this.tag_dialog = false;
			},

			// 添加分类
			addClass(data) {
				console.log(data);
				this.classObj = {
					name: "",
					level: data.level + 1,
					parentId: data.id,
					flag: 0,
					isShow: 0,
				};
				this.classDialog = true;
			},
			// 添加分类
			submitAddClass() {
				if (!this.classObj.name) return ElMessage.error("请输入名称");
				// 检查兄弟标签分类是否有相同的名字
				if (!this.classObj.parentId && this.classObj.parentId != 0) return ElMessage.error("没有父节点ID");
				let parentIdAndchildNameObj = this.parentIdRecursion(this.treeData, {});
				console.log(parentIdAndchildNameObj);
				if (
					parentIdAndchildNameObj[this.classObj.parentId] &&
					parentIdAndchildNameObj[this.classObj.parentId].indexOf(this.classObj.name) !== -1
				)
					return ElMessage.error("标签分类名称已存在");
				this.loading = true;
				global_api.AddTagCategory(this.classObj).then(
					res => {
						this.loading = false;
						this.classDialog = false;
						this.QueryTagListByTagSearchVo();
					},
					err => {
						this.loading = false;
						ElMessage.error("添加失败");
					}
				);
			},
			// 删除分类
			removeClass(data) {
				ElMessageBox.confirm("是否删除?", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					let param = {
						tagCategoryId: data.id,
					};
					this.loading = true;
					global_api.RemoveTagCategoryByTagCategoryId(param).then(
						res => {
							this.loading = false;
							this.QueryTagListByTagSearchVo();
						},
						err => {
							this.loading = false;
							ElMessage.error("删除失败");
						}
					);
				});
			},
			// 修改分类
			modifyClass(data) {
				console.log(data);
				this.classObj = {
					name: data.name,
					level: data.level,
					parentId: data.parentId,
					tagCategoryId: data.id,
					flag: data.flag,
					isShow: data.isShow,
					tagRespDTOS: data.tagRespDTOS,
				};
				this.classDialog = true;
			},
			// 修改分类
			submitModifyClass() {
				if (!this.classObj.name) return ElMessage.error("请输入名称");
				let that = this;
				let paramList = [];

				paramList = d(this.classObj);
				paramList.push({
					name: that.classObj.name,
					level: that.classObj.level,
					parentId: that.classObj.parentId,
					tagCategoryId: that.classObj.tagCategoryId,
					flag: that.classObj.flag,
					isShow: that.classObj.isShow,
				});

				console.log(paramList);

				function d(data) {
					let a = [];
					if (data.flag != that.classObj.flag || data.isShow != that.classObj.isShow) {
						a.push({
							name: data.name,
							level: data.level,
							parentId: data.parentId,
							tagCategoryId: data.tagCategoryId || data.id,
							flag: that.classObj.flag,
							isShow: that.classObj.isShow,
						});
					}

					if (data.tagRespDTOS?.length) {
						for (let i in data.tagRespDTOS) {
							a.push(...d(data.tagRespDTOS[i]));
						}
					}
					return a;
				}
				let n = paramList.length;
				this.loading = true;
				for (let i in paramList) {
					global_api.ModifyTagCategoryByTagCategoryId(paramList[i]).then(
						res => {
							n -= 1;
							if (n === 0) {
								this.loading = false;
								this.classDialog = false;
								this.QueryTagListByTagSearchVo();
							}
						},
						err => {
							n -= 1;
							if (n === 0) {
								this.loading = false;
								this.QueryTagListByTagSearchVo();
							}
						}
					);
				}
			},

			// 拖拽
			nodeDrop(node1, node2, type) {
				let that = this;
				let node = node1.data;
				let parentId, level;
				if (type == "before" || type == "after") {
					parentId = node2.data.parentId;
					level = node2.level - 1;
				} else {
					parentId = node2.data.id;
					level = node2.level;
				}
				// 递归确定需要修改的数据
				let paramList = d(node, parentId, level);
				console.log(paramList);
				let index = 0;
				if (paramList.length) {
					this.loading = true;
					p();
				} else {
					that.modifyTagCategorySortBatch();
				}

				function d(item, id, lv) {
					let list = [];
					if (item.parentId != id || item.level != lv) {
						list.push({
							name: item.name,
							tagCategoryId: item.id,
							flag: item.flag,
							isShow: item.isShow,
							parentId: id,
							level: lv,
						});
					}
					if (item.tagRespDTOS.length) {
						for (let i in item.tagRespDTOS) {
							list.push(...d(item.tagRespDTOS[i], item.id, lv + 1));
						}
					}
					return list;
				}

				// p();
				function p() {
					global_api.ModifyTagCategoryByTagCategoryId(paramList[index]).then(
						res => {
							index += 1;
							if (index < paramList.length) {
								p();
							} else {
								that.loading = false;
								that.modifyTagCategorySortBatch();
							}
						},
						err => {
							p();
						}
					);
				}
			},
			// 修改标签分类排序
			modifyTagCategorySortBatch() {
				let list = this.treeData;
				let index = 0;
				console.log(list);
				let sortList = d(list);
				console.log(sortList);
				let tagCategories = [];
				for (let i in sortList) {
					if (sortList[i].sort !== sortList[i].oldSort) {
						tagCategories.push(sortList[i]);
					}
				}
				this.loading = true;
				modifyTagCategorySortBatch({ tagCategories: JSON.stringify(tagCategories) }).then(
					res => {
						this.loading = false;
						this.QueryTagListByTagSearchVo();
					},
					err => {
						this.loading = false;
						this.QueryTagListByTagSearchVo();
					}
				);

				function d(data) {
					let a = [];
					for (let i in data) {
						a.push({
							tagCategoryId: data[i].id,
							sort: index++,
							oldSort: data[i].sort,
						});
						if (data[i].tagRespDTOS?.length) {
							a.push(...d(data[i].tagRespDTOS));
						}
					}
					return a;
				}
			},
			// 过滤词change
			inputChange() {
				this.$refs.treeRef.filter(this.filterText);
			},
			// 标签树过滤方法
			filterNode(value, data) {
				if (!value) return true;
				// console.log(data);

				if (DP[data.parentId]) {
					DP[data.id] = true;
					return true;
				}
				if (data.name.includes(value)) {
					DP[data.id] = true;
					return true;
				} else {
					for (let i in data.tags) {
						if (data.tags[i].value.includes(value)) {
							DP[data.id] = true;
							return true;
						}
					}
					DP[data.id] = false;
					return false;
				}
			},
			// 递归
			parentIdRecursion(arr, parentIdAndchildNameObj) {
				for (let i in arr) {
					if (arr[i].tagRespDTOS && arr[i].tagRespDTOS.length) {
						parentIdAndchildNameObj = this.parentIdRecursion(arr[i].tagRespDTOS, parentIdAndchildNameObj);
					}
					if (parentIdAndchildNameObj[arr[i].parentId]) {
						parentIdAndchildNameObj[arr[i].parentId].push(arr[i].name);
					} else {
						parentIdAndchildNameObj[arr[i].parentId] = [arr[i].name];
					}
				}
				return parentIdAndchildNameObj;
			},
			// 打开移动弹窗
			openMoveDialog() {
				this.moveDialog = true;
				this.leftData = JSON.parse(JSON.stringify(this.tags));
				leftData = JSON.parse(JSON.stringify(this.tags));
				this.rightData = [];
				this.titles = [this.tagName, "标签分类2"];
				this.moveDialogTree = JSON.parse(JSON.stringify(this.treeData));
				moveParam = null;
			},
			// 移动弹窗中树点击
			moveNodeClick(e) {
				console.log(e);
				if (e.id === this.tagId) return ElMessage.error("不能选择同一个标签分类");
				this.titles = [this.tagName, e.name];
				moveParam = e;
				let tags = JSON.parse(JSON.stringify(e.tags));
				let rightData = [];
				tags.map(item => {
					item.disabled = true;
					rightData.push(item.id);
				});
				this.leftData = [...leftData, ...tags];
				this.rightData = rightData;
			},
			// 确定移动
			submitMove() {
				let right = this.rightData;
				let left = this.leftData;
				let list = [];
				for (let i in left) {
					if (right.indexOf(left[i].id) !== -1 && !left[i].disabled) {
						list.push(left[i]);
					}
				}
				if (list.length === 0) return ElMessage.error("没有要移动的数据");
				if (!moveParam || !moveParam.id) return ElMessage.error("请选择要移动到那个分类");
				let num = 0;
				let errnum = 0;
				for (let i in list) {
					this.loading = true;
					let param = {
						isLongTerm: list[i].isLongTerm,
						isObvious: list[i].isObvious,
						value: list[i].value,
						parentId: moveParam.id,
						tagId: list[i].id,
						isMutex: list[i].isMutex,
						extras: list[i].extras
					};
					global_api.ModifyTagByTagId(param).then(
						res => {
							num += 1;
							if (num === list.length) {
								this.loading = false;
								this.moveDialog = false;
								if (!errnum) {
									ElMessage.success("标签移动成功");
									this.QueryTagListByTagSearchVo();
								} else if (errnum === num) {
									ElMessage.error("标签移动失败");
								} else {
									ElMessage("标签部分移动成功");
									this.QueryTagListByTagSearchVo();
								}
							}
						},
						err => {
							num += 1;
							errnum += 1;
							if (num === list.length) {
								this.loading = false;
								this.moveDialog = false;
								if (errnum === num) {
									ElMessage.error("标签移动失败");
								} else {
									ElMessage("标签部分移动成功");
									this.QueryTagListByTagSearchVo();
								}
							}
						}
					);
				}
			},
			// 选择文件
			selectFile() {
				let input = document.createElement("input");
				input.type = "file";
				input.accept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
				input.click();
				input.addEventListener("change", e => {
					this.handleExceed(input.files[0]);
				});
			},
			handleExceed(file) {
				let reader = new FileReader();
				reader.readAsBinaryString(file);
				reader.onload = event => {
					const { result } = event.target;
					// 以二进制流方式读取得到整份excel表格对象
					const workbook = XLSX.read(result, { type: "binary" });
					let data = []; // 存储获取到的数据
					// 遍历每张工作表进行读取（这里默认只读取第一张表）
					for (const sheet in workbook.Sheets) {
						if (Object.prototype.hasOwnProperty.call(workbook.Sheets, sheet)) {
							//这是关键的一步，hasOwnPropert要从Object的原型中调用
							data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
						}
					}
					// 检验数据是否正确
					if (data.length === 0) {
						return ElMessage.error("没有上传数据");
					}
					console.log(data[0]);
					for (let i in data[0]) {
						console.log(i);
						if (!/^level\d*$/.test(i)) {
							return ElMessage.error("文件数据格式不正确");
						}
					}
					console.log(data);
					let list = this.listToTree(data);
					this.loading = true;
					addTagCategoryBatch({ tagCategories: JSON.stringify(list) }).then(
						res => {
							this.successData = res.result.success;
							this.failData = res.result.fail;
							this.loading = false;
							this.resDialog = true;
						},
						err => {
							this.loading = false;
							ElMessage.error("上传失败");
						}
					);
				};
			},
			// 列表结构转树
			listToTree(data) {
				let list = data;
				// 去重
				for (let i = 0; i < list.length; i++) {
					for (let n = i + 1; n < list.length; n++) {
						if (JSON.stringify(list[i]) === JSON.stringify(list[n])) {
							list.splice(n, 1);
							n--;
						}
					}
				}
				console.log(list);
				return list;
			},
			// 打开关联题目弹窗
			openTagQues(data) {
				this.tagQuesData = data;
				this.tagQuesDialog = true;
			},
			// 导出文件
			exportFile() {
				let data = this.treeData;
				let list = recursion(data);
				exportExcel.exportExcel(list, "标签");

				function recursion(
					data,
					level = 0,
					obj = {
						标签分类_0级: "",
						标签分类_1级: "",
						标签分类_2级: "",
						标签分类_3级: "",
						标签分类_4级: "",
						标签分类_5级: "",
						标签分类_6级: "",
						标签分类_7级: "",
						标签分类_8级: "",
						标签名: "",
					}
				) {
					let list = [];
					for (let i in data) {
						obj[`标签分类_${level}级`] = data[i].name;
						if (data[i].tags.length) {
							for (let n in data[i].tags) {
								obj.标签名 = data[i].tags[n].value;
								list.push({ ...obj });
							}
						} else {
							obj.标签名 = "";
							list.push({ ...obj });
						}

						if (data[i].tagRespDTOS.length) {
							list.push(...recursion(data[i].tagRespDTOS, level + 1, JSON.parse(JSON.stringify(obj))));
						}
					}
					return list;
				}
			},
      // 打开批量修改标签
      openEditDialog(){
        console.log(this.multipleSelection);
        let ids = {};
        for(let i in this.multipleSelection){
          ids[this.multipleSelection[i].id] = this.multipleSelection[i].value
        }
        this.editData = {
          ids,
					isLongTerm: this.multipleSelection[0].isLongTerm,
					isObvious: this.multipleSelection[0].isObvious,
					isMutex: this.multipleSelection[0].isMutex,
					extras: this.multipleSelection[0].extras,
					parentId: this.multipleSelection[0].parentId,
        }
				this.editDialog = true;
      },
			// 批量修改
			editTags(){
				this.editDialog = false;
				let ids = this.editData.ids;
				let length = Object.keys(ids).length;
				for(let k in ids){
					let param = {
						isLongTerm: this.editData.isLongTerm,
						isObvious: this.editData.isObvious,
						value: ids[k],
						parentId: this.editData.parentId,
						tagId: k,
						isMutex: this.editData.isMutex,
						extras: this.editData.extras
					}
					global_api.ModifyTagByTagId(param).then(res=>{
						length -= 1;
						if(length===0){
							this.updataTag();
						}
					},err=>{
						length -= 1;
						if(length===0){
							this.updataTag();
						}
					})
				}

				
			}
		},
	};
</script>
<style scoped lang="scss">
	.treeBox {
		width: 350px;
		.treeItem {
			display: flex;
			align-items: center;
			justify-content: space-between;
			span {
				width: 150px;
				overflow: hidden;
			}
			.treeItemBtn {
				display: none;
				width: 100px;
			}
		}
	}
	.treeBox .treeItem:hover .treeItemBtn {
		display: block;
	}
	.tableList {
		display: flex;
		align-items: flex-start;
		.tagsBox {
			width: calc(100% - 350px);
			padding: 0 10px;
		}
	}
	.check {
		color: #409eff;
	}
	.moveBox {
		display: flex;
		.moveBoxRight {
			height: 500px;
			width: 590px;
			overflow: auto;
		}
	}
</style>
<style>
	.el-transfer-panel {
		width: 550px !important;
	}
</style>
