<template>
  <div class="Category_management_list" v-loading="loading">
    <section class="search_btn">
      <div class="search_items">
        <span class="title">分类名称：</span>
        <div>
          <el-input
              v-model="this.categoryName"
              class="w-50 m-2"
              size="large"
              placeholder="请输入分类名称"
          ></el-input>
        </div>
      </div>
      <div class="search_items">
        <el-button type="primary" :icon="Search" @click="this.GetCategoryList">查询</el-button>
        <el-button type="danger" @click="edit_category('add')">添加分类</el-button>
      </div>
    </section>
    <section class="user_info_list">
      <el-table :data="GetCategory_options">
        <el-table-column prop="id" label="ID" width="120" />
<!--        <el-table-column prop="sort" label="排序" width="120" />-->
        <el-table-column prop="level" label="等级" width="120" />
        <el-table-column prop="name" label="分类名称"/>
        <el-table-column prop="parentId" label="上级分类" width="250">
          <template #default="scope">
            <el-select v-model="scope.row.parentId" filterable placeholder="请选择" size="large" v-show="scope.row.level!=1" @change="UpdateParentCategory(scope.row)">
              <el-option
                  v-for="item in this.one_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="200" />
        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-tag class="ml-2" @click="edit_category('edit',scope.row)">编辑</el-tag>
            <el-tag class="ml-2" type="success" @click="Is_delete(scope.row)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </section>

    <section class="pagination">
      <el-pagination :page-size="this.pageSize" :currentPage="this.pageNo" :total="this.totalCount" @current-change="Current_change" pager-count="10" background layout="prev, pager, next"></el-pagination>
    </section>

    <el-dialog v-model="this.edit_dialog" title="编辑" width="30%" draggable>
      <div class="search_items no_float">
        <span class="title"><span class="red">*</span>分类名称：</span>
        <div>
          <el-input
              v-model="row_item.name"
              class="w-50 m-2"
              size="large"
              placeholder="请输入分类名称"
          ></el-input>
        </div>
      </div>
<!--      <div class="search_items no_float">-->
<!--        <span class="title"><span class="red">*</span>分类排序：</span>-->
<!--        <div>-->
<!--          <el-input-->
<!--              v-model="row_item.sort"-->
<!--              class="w-50 m-2"-->
<!--              size="large"-->
<!--              placeholder="请输入分类排序"-->
<!--          ></el-input>-->
<!--        </div>-->
<!--      </div>-->
      <div class="search_items no_float">
        <span class="title"><span class="red">*</span>分类等级：</span>
        <div>
          <el-input
              v-model="row_item.level"
              class="w-50 m-2"
              size="large"
              placeholder="请输入分类等级"
          ></el-input>
        </div>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="InsertOrUpdateCategory">确认</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {Search,MessageBox} from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import global_api from "@/config/Api";
export default {
  name: 'Category_management_list',
  data(){
    return {
      Search:Search,    //图标
      MessageBox:MessageBox,//图标
      edit_dialog:false,
      categoryName:'',  //分类名称
      pageNo:1,       //页码
      pageSize:10,      //每页数量
      totalCount:0,         //总数
      loading: false,
      GetCategory_options:[],
      one_list:[],
      row_item:{
        id:'-1',
        level: '',
        name: "",
        // sort:''
      }
    }
  },
  mounted() {
    this.GetCategoryList()
    this.GetCategoryList_one()
    // this.global_api.test({a:1,b:2}).then((res)=>{
    //   console.log(res)
    // })
    // console.log(this.util.login())
  },
  methods: {
    UpdateParentCategory(row){   //修改分类上级
      this.global_api.UpdateParentCategory({pid:row.parentId,categoryId:row.id}).then((res)=>{
        if(res.code!=0){
          ElMessage.error('修改分类上级错误')
        }
      })
    },
    Current_change(number){
      this.pageNo = number
      this.GetCategoryList()
    },

    GetCategoryList(){        //获取分类列表
      let obj = {
        categoryName:this.categoryName,
        pageNo:this.pageNo,
        pageSize:this.pageSize
      }
      this.loading = true;
      this.global_api.GetCategoryList(obj).then((res)=>{
        if(res.code!=0){
          ElMessage.error('获取品牌列表错误')
        }else{
          this.totalCount = res.data.totalCount
          this.GetCategory_options = res.result
          for(let i = 0;i<this.GetCategory_options.length;i++){       //parentId=0的字段换成空字符串 显示用
            if(this.GetCategory_options[i].parentId==0){
              this.GetCategory_options[i].parentId = ''
            }
          }
          this.loading = false
        }
        // console.log(res)
      })
    },
    GetCategoryList_one(){    //一级分类列表
      this.global_api.GetCategoryList_one().then((res)=>{
        if(res.code!=0){
          ElMessage.error('获取品牌列表错误')
        }else{
          this.one_list = res.result
        }
      })
    },

    Is_delete(row){           //是否删除
      ElMessageBox.confirm(
          '是否删除?',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        this.global_api.DeleteCategory({categoryId:row.id}).then((res)=>{  //删除分类
          if(res.code!=0){
            ElMessage.error('删除分类错误')
          }else{
            this.GetCategoryList()
            ElMessage({
              type: 'success',
              message: '已删除',
            })
          }
        })

      })
    },

    edit_category(string ,row){       //编辑 新增 题目
       if(string=='edit'){
         this.row_item = row
       }else{
         this.row_item = {
           id:'-1',
           level: '',
           name: "",
           // sort:''
         }
       }
       this.edit_dialog = true
    },

    InsertOrUpdateCategory(){    //添加或修改分类
      let obj = {
        categoryId:this.row_item.id,
        categoryName:this.row_item.name,
        level:this.row_item.level,
        // sort:this.row_item.sort
      }
      if(!this.validate(obj))  return false
      this.global_api.InsertOrUpdateCategory(obj).then((res)=>{
        if(res.code!=0){
          ElMessage.error('获取品牌列表错误')
        }else{
          this.GetCategoryList()
          this.edit_dialog = false
        }
      })
    },

    validate(obj){
      if(!obj.categoryName){
        ElMessage.error('分类名称必须填写')
        return false
      }
      if(!obj.level){
        ElMessage.error('分类等级必须填写')
        return false
      }
      return true
    },

    cancel(){
      this.GetCategoryList()
      this.edit_dialog = false
    }

  },
}
</script>
<style scoped lang="scss">
.Category_management_list{
  margin-top: 20px;
}
.search_btn{
  //border-radius: 5px;
}
.user_info_list{
  margin-top: 20px;
  min-width: 800px;
  .btn{
    margin-top: 5px !important;
  }
}
.pagination{
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.no_float{
  float: none !important;
}

</style>
