<template>
  <div class="main">
    <div class="search_btn">
      <div class="selected_items">
          <span class="title"><span class="red">*</span>心愿盒名称：</span>
          <div>
            <el-input
                v-model="wishBox.name"
                class="w-50 m-2"
                size="large"
                placeholder="请输入产品名称"
            ></el-input>
          </div>
        </div>
    </div>
    <div class="search_btn">
      <div class="selected_items">
          <span class="title"><span class="red">*</span>心愿盒描述：</span>
          <div>
            <el-input
                v-model="wishBox.content"
                class="w-50 m-2"
                size="large"
                placeholder="请输入产品名称"
                type="textarea"
            ></el-input>
          </div>
        </div>
    </div>
    <div class="search_btn">
      <div class="selected_items" style="height:auto">
          <span class="title"><span class="red">*</span>心愿盒图片：</span>
          <div>
<!--            <img :src="wishBox.image" style="width:200px" alt="">-->
            <el-upload 
              :action="Get_url+'admin/file/uploadFile'"
              :show-file-list="false"
              :on-success="uploadSuccess"
              :headers="headers"
            >
              <img v-if="this.wishBox.image" :src="this.wishBox.image" class="avatar" style="width:200px"/>
              <img v-else src="../../assets/bannerDefualt.png" class="avatar" style="width:200px"/>
            </el-upload>
            <div class="red">图片尺寸小于1MB</div>
          </div>
        </div>
    </div>
    <div>
      <el-button type="primary" @click="openAdd">添加产品</el-button>
    </div>
    <el-table :data="tableList">
      <el-table-column label="id" prop="id"></el-table-column>
      <el-table-column label="图片" prop="id">
        <template #default='scope'>
          <img style="width:50px" :src="scope.row.img" alt="">
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="title"></el-table-column>
      <el-table-column label="产品描述" prop="subtitle"></el-table-column>
      <el-table-column label="是否评论">
        <template #default='scope'>
          <p v-if="scope.row.requireComment!==null">
            {{scope.row.requireComment==1?'需要评论':'不需要评论'}}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="项目" prop="id">
        <template #default='scope'>
          {{allProjectList[projectObj[scope.row.id]]?allProjectList[projectObj[scope.row.id]].name:''}}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default='scope'>
          <el-button @click="removeProduct(scope)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top:20px">
      <el-button @click="backUrl()">取消</el-button>
      <el-button type="primary" @click="modify">确定</el-button>
    </div>
  </div>
  <!-- 添加产品 -->
  <el-dialog v-model="productShow" title="添加产品" width="500px">
    <el-form>
      <el-form-item label="产品：">
        <el-select v-model="productId" filterable @change="gitProjectList">
          <el-option v-for="(item,index) in productList" :key="item.id" :value="index" :label="item.title"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="项目：">
        <el-select v-model="projectId">
          <el-option v-for="(item,index) in projectList" :key="item.id" :value="index" :label="item.name"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    
    <template #footer>
      <el-button @click="productShow=false">取消</el-button>
      <el-button type="primary" @click="submitAdd">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import {Get_url} from "../../config/Get_url";
import {getBoxProductList,UpdateBoxInfo,GetProductList,queryProjectListByProductId,removeBoxProjectByBoxProjectId} from '../../config/Api'
import { ElMessage } from 'element-plus';
export default {
  data() {
    return {
      headers:{
        id:localStorage.getItem('id'),
        token: localStorage.getItem('token')
      },
      Get_url:Get_url(),
      wishBox: {},
      tableList: [],
      productShow: false,
      productId: '',
      productList: [],
      projectObj:{},
      allProjectList:{},
      projectList:[],
      projectId:''
    }
  },
  mounted(){
    if(this.$route.query.type=='edit'){
      this.wishBox = JSON.parse(sessionStorage.getItem('wishBox'));
      this.getBoxProductList();
    } else {
      this.wishBox = {};
    }
  },
  methods:{
    // 上传图片
    uploadSuccess(e) {
      this.wishBox.image = e.msg;
    },
    // 获取数据
    getBoxProductList(){
      let param = {boxId: this.wishBox.id}
      getBoxProductList({params:param}).then(res=>{
        let tableList = res.result.productWithResourcesList;
        let projectList = res.result.boxProjects;
        for(let i in tableList) {
          for(let n in tableList[i].productResources){
            if(tableList[i].productResources[n].type==0){
              tableList[i].img = tableList[i].productResources[n].url;
            }
          }
        }
        this.projectRedata(tableList,projectList)
        
      })
    },
    // 数据重组
    projectRedata(list,projectList){
      let projectObj = {};
      let boxProjectId = {};
      for(let i in projectList){
        projectObj[projectList[i].productId] = projectList[i].projectId;
        boxProjectId[projectList[i].productId] = projectList[i].id;
      }
      this.projectObj = projectObj;
      for(let i in list){
        this.queryProjectListByProductId(list[i].id)
        list[i].boxProjectId = boxProjectId[list[i].id];
      }
      this.tableList = list;
    },
    // 获取项目列表
    queryProjectListByProductId(id){
      let param = {
        productId: id
      };
      queryProjectListByProductId(param).then(res=>{
        for(let i in res.result){
          this.allProjectList[res.result[i].id] = res.result[i];
        }
      })
    },
    gitProjectList(){
      let param = {
        productId: this.productList[this.productId].id
      };
      queryProjectListByProductId(param).then(res=>{
        this.projectList = res.result;
      })
    },
    // 删除
    removeProduct(scope){
      console.log(scope)
      if(scope.row.boxProjectId){
        removeBoxProjectByBoxProjectId({boxProjectId:scope.row.boxProjectId}).then(res=>{
          this.tableList.splice(scope.$index,1)
        },err=>{
          ElMessage.error('删除失败')
        })
      }else{
        this.tableList.splice(scope.$index,1)
      }
    },
    // 返回
    backUrl(){
      this.$router.back()
    },
    // 修改
    modify(){
      let type = this.$route.query.type;
      if(!this.wishBox.id&&type=='edit'){
        ElMessage.error('没有id');
        return ;
      }
      if(!this.wishBox.image){
        ElMessage.error('没有图片');
        return ;
      }
      if(!this.wishBox.name){
        ElMessage.error('没有名称');
        return ;
      }
      if(!this.wishBox.content){
        ElMessage.error('没有描述');
        return ;
      }
      if(!this.tableList.length){
        ElMessage.error('没有产品');
        return ;
      }
      let productIds = [];
      let boxProjectReqDTOS = [];
      for(let i in this.tableList){
        productIds.push(this.tableList[i].id)
        if(this.tableList[i].boxProjectId){
          boxProjectReqDTOS.push({
            boxProjectId: this.tableList[i].boxProjectId,
            productId: this.tableList[i].id,
            projectId: this.projectObj[this.tableList[i].id]
          })
        }else{
          boxProjectReqDTOS.push({
            boxProjectId: this.tableList[i].boxProjectId,
            productId: this.tableList[i].id,
            projectId: this.projectObj[this.tableList[i].id]
          })
        }
      }
      let param = {
        boxImage: this.wishBox.image,
        boxName: this.wishBox.name,
        content: this.wishBox.content,
        productIds: productIds.join(','),
        boxProjectReqDTOS:JSON.stringify(boxProjectReqDTOS),
      }
      if(type=='edit'){
        param.boxId = this.wishBox.id
      } else {
        param.boxId = -1
      }
      UpdateBoxInfo(param).then(res => {
        this.backUrl();
      },err => {
        ElMessage.error('修改失败')
      })
    },
    // 打开添加产品
    openAdd(){
      GetProductList().then(res=>{
        this.productList = res.result;
        this.productId = '';
        this.productShow = true;
      },err=>{
        ElMessageBox.error('获取产品列表失败')
      })
    },
    // 确定添加
    submitAdd(){
      
      this.projectObj[this.productList[this.productId].id] = this.projectList[this.projectId].id
      this.allProjectList[this.projectList[this.projectId].id] = this.projectList[this.projectId];
      console.log(this.projectObj)
      this.tableList.push(this.productList[this.productId]);
      this.productShow = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.main{
  margin-top: 20px;
  padding-bottom: 50px;
  .start_items{
    img{
      width: 200px;
      height: 200px;
      border-radius: 10px;
    }
    .sub{
      margin-top: 10px;
      font-size: 12px;
      color: red;
    }
  }
}
</style>
