<template>
<el-button type="primary" @click="openDialog">删除用户答案</el-button>
<el-dialog v-model="dialogFlag" title="删除用户问卷答案" v-loading="loading">
  <el-form label-suffix="：" label-width="100px">
<!--    <el-form-item label="导入用户">-->
<!--      <readfile />-->
<!--    </el-form-item>-->
    <el-form-item label="用户id">
      <el-input v-model="userId"></el-input>
    </el-form-item>
    <el-form-item label="问卷名称">
      {{survey.name}}
    </el-form-item>
    <el-form-item label="surveyId">
      {{survey.id}}
    </el-form-item>
  </el-form>
  <template #footer>
    <el-button @click="closeDialog">取消</el-button>
    <el-button type="primary" @click="deleteUserAnswerByUserIdAndSurveyId">确定</el-button>
  </template>
</el-dialog>
</template>

<script>
import {ElMessage} from "element-plus";
import {deleteUserAnswerByUserIdAndSurveyId} from '../config/Api'
import readfile from "@/components/readfile.vue";

export default {
  name: "answerDel.vue",
  props: ["survey"],
  data() {
    return {
      dialogFlag: false,
      userId: null,
      loading: false,
    }
  },
  components: {
    readfile
  },
  methods: {
    // 打开弹窗
    openDialog() {
      this.userId = '';
      if(this.survey?.id){
        this.dialogFlag = true;
      }else{
        ElMessage.error('没有问卷信息')
      }
    },
    // 关闭弹窗
    closeDialog() {
      this.dialogFlag = false;
    },
    //
    // 删除调研问卷，产品问卷答案
    deleteUserAnswerByUserIdAndSurveyId() {
      let userId = this.userId.replace(/\s/g,'')
      if(!userId) return ElMessage.error('请输入用户id');

      this.loading = true;
      let param = {
        surveyId: this.survey.id,
        userId: this.userId
      }
      deleteUserAnswerByUserIdAndSurveyId(param).then(res => {
        this.loading = false;
        ElMessage.success('删除成功');
        this.dialogFlag = false;
      },err => {
        this.loading = false;
        ElMessage.error('删除失败')
      })
    }
  }
}
</script>

<style scoped>

</style>