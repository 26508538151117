<template>
  <div class="goodsList" v-loading.fullscreen.lock="loading">
    <section class="search_btn">
      <div class="search_items">
        <span class="title">商品名称：</span>
        <div>
          <el-input
            v-model="this.productTitle"
            class="w-50 m-2"
            size="large"
            placeholder="请输入商品名称"
          ></el-input>
        </div>
      </div>
      <div class="search_items">
        <span class="title">品牌：</span>
        <div>
          <el-select v-model="brandId" filterable>
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="search_items">
        <span class="title">批量添加产品：</span>
        <div>
          <fileTemplate sign="4" @fileData="addProductBatch"/>
        </div>
      </div>
      <div class="search_items">
        <el-button type="primary" :icon="Search" @click="this.getList"
          >查询</el-button
        >
        <el-button type="success" @click="clearSearch">清除</el-button>
        <el-button type="danger" @click="edit_goods_show('add')"
          >添加产品</el-button
        >
      </div>
    </section>
    <section class="tableList">
      <el-table :data="tableList">
        <el-table-column prop="id" label="ID" width="50" />
        <el-table-column prop="pid" label="SKU ID" width="90" />
        <el-table-column prop="brand.name" label="品牌名称" width="100" />
        <el-table-column prop="title" label="产品名称" width="100" />
        <el-table-column prop="img" label="产品封面" width="80">
          <template #default="scope">
            <div class="tableImg">
              <img :src="scope.row.img" alt="" />
            </div>
            <el-button type="text" @click="gotoImgList(scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="descriptionCopy" label="产品简述" width="150">
          <template #default="scope">
            <div v-html="scope.row.descriptionCopy"></div>
            <el-button
              v-if="scope.row.descriptionCopyFlag"
              type="text"
              @click="
                description = scope.row.description;
                descriptionDialog = true;
              "
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="购买链接" width="80">
          <template #default="scope">
            <el-tag class="ml-2" @click="resource(scope.row)">查看</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="点击量(买/礼)" width="100">
          <template #default="scope">
            {{ scope.row.shopCount }}/{{ scope.row.giftCount }}
          </template>
        </el-table-column>
        <el-table-column label="点击量(买/礼)UV" width="100">
          <template #default="scope">
            {{ scope.row.shopCountUV }}/{{ scope.row.giftCountUV }}
          </template>
        </el-table-column>
        <el-table-column label="标签" width="250px">
          <template #default="scope">
            {{ tagNameObj[scope.row.tagCategoryId] }}
          </template>
        </el-table-column>
        <el-table-column label="评论引用" width="100">
          <template #default="scope">
            {{
              scope.row.sourceProduct ? scope.row.sourceProduct.title : "无引用"
            }}
          </template>
        </el-table-column>
        <el-table-column label="产品评论" width="150">
          <template #default="scope">
            {{ scope.row.userCommentCount }}条评论
            <el-tag class="ml-2" @click="gotoComment(scope.row)">查看</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="description" label="产品详情" width="100">
          <template #default="scope">
            {{ scope.row.description ? "有" : "无" }}
            <el-tag class="ml-2" @click="edit_goods_show('edit', scope)"
              >修改</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="是否评论">
          <template #default="scope">
            {{ scope.row.requireComment ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column label="评论显示状态" width="110">
          <template #default="scope">
            {{
              (scope.row.isVisiable == 1 && "显示") ||
              (scope.row.isVisiable != 1 && "不显示")
            }}
          </template>
        </el-table-column>
        <el-table-column prop="id" label="可评论时间" width="100">
          <template #default="scope">
            {{
              scope.row.allowCommentTime
                ? `${scope.row.allowCommentTime}天后评论`
                : `立即评论`
            }}
          </template>
        </el-table-column>
        <el-table-column prop="id" label="题目总数" width="100">
          <template #default="scope">
            {{ addQuestionNUmber(scope.row.projectRepDTOS) }}
          </template>
        </el-table-column>
        <el-table-column prop="id" label="产品项目" width="150">
          <template #default="scope">
            {{ scope.row.projectRepDTOS?.length || 0 }}个项目
            <el-tag class="ml-2" @click="openPlan(scope.row)">查看</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="产品类别">
          <template #default="scope">
            {{
              (scope.row.type == 1 && "自采") ||
              (scope.row.type == 2 && "合作方") ||
              "未知"
            }}
            <el-button size="small" type="primary" @click="openFrom(scope.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="160" />
        <el-table-column prop="id" label="操作" width="240">
          <template #default="scope">
            <!-- <el-tag class="ml-2">查看</el-tag> -->
            <el-tag
              class="ml-2"
              type="success"
              @click="edit_goods_show('edit', scope)"
              >编辑</el-tag
            >
            <el-tag class="ml-2" type="danger" @click="removeGoods(scope.row)"
              >删除</el-tag
            >
            <el-tag class="ml-2" @click="clearReddis(scope.row)"
              >清除缓存</el-tag
            >
          </template>
        </el-table-column>
      </el-table>
    </section>
    <section class="pagination">
      <el-pagination
        v-model:currentPage="pageCurrent"
        v-model:page-size="pageSize"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="Current_change"
      />
    </section>

    <!-- 产品描述 -->
    <el-dialog v-model="descriptionDialog" title="内容" width="400px">
      {{ description }}
      <template #footer>
        <el-button type="primary" @click="descriptionDialog = false"
          >确定</el-button
        >
      </template>
    </el-dialog>

    <!-- 产品项目 -->
    <el-dialog v-model="palnDialog" title="项目" width="700px">
      <el-table :data="palnList">
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column
          label="题目数量"
          prop="questionCount"
        ></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button
              type="primary"
              size="small"
              @click="gotoQuestion(scope.row)"
              >查看</el-button
            >
            <el-button type="success" size="small" @click="queryWarehouseLogistic(scope.row.id)">仓库</el-button>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <el-button type="primary" @click="descriptionDialog = false"
          >取消</el-button
        >
      </template>
    </el-dialog>

    <!-- 编辑产品来源 -->
    <el-dialog v-model="fromDialog" title="编辑产品来源">
      <el-form>
        <el-form-item label="类别">
          <el-select v-model="type">
            <el-option label="自采" :value="1"></el-option>
            <el-option label="合作方" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="fromDialog = false">取消</el-button>
        <el-button type="primary" @click="submitform">确定</el-button>
      </template>
    </el-dialog>

    <!-- 仓库信息弹窗 -->
    <el-dialog v-model="wareFlag" title="仓库收货列表" width="100%">
      <el-table :data="wareList">
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="仓库名" prop="warehouseName"></el-table-column>
        <el-table-column label="仓库地址" prop="warehouseAddress"></el-table-column>
        <el-table-column label="仓库收货人" prop="warehouseReceiver"></el-table-column>
        <el-table-column label="仓库收货人电话" prop="warehousePhone"></el-table-column>
        <el-table-column label="发货人" prop="senderName"></el-table-column>
        <el-table-column label="发货人电话" prop="senderPhone"></el-table-column>
        <el-table-column label="发货单号" prop="logisticNumber"></el-table-column>
        <el-table-column label="发货数量" prop="sentCount"></el-table-column>
        <el-table-column label="派送状态" prop="logisticStatus">
          <template #default="scope">
            {{(scope.row.logisticStatus==1&&'未发货')||(scope.row.logisticStatus==2&&'派送中')||(scope.row.logisticStatus==3&&'已完成')||(scope.row.logisticStatus==4&&'已退回')||(scope.row.logisticStatus==5&&'停发')}}
          </template>
        </el-table-column>
        <el-table-column label="pid_raw" prop="pidRaw"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="success" size="small" @click="openModify(scope.row)">修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-model:currentPage="pageCurrent"
        :page-size="10"
        background
        layout="prev, pager, next"
        :total="wareTotal"
        @current-change="queryWarehouseLogistic(wareList[0].originId)"
      />
    </el-dialog>
    
    <!-- 修改仓库信息弹窗 -->
    <el-dialog v-model="modifyWareFlag" title="修改仓库收货信息" width="700px">
      <el-form>
        <el-form-item label="仓库名">{{modifyWareData.warehouseName}}</el-form-item>
        <el-form-item label="仓库地址">{{modifyWareData.warehouseAddress}}</el-form-item>
        <el-form-item label="仓库收货人">{{modifyWareData.warehouseReceiver}}</el-form-item>
        <el-form-item label="仓库收货人电话">{{modifyWareData.warehousePhone}}</el-form-item>
        <el-form-item label="发货人">{{modifyWareData.senderName}}</el-form-item>
        <el-form-item label="发货人电话">{{modifyWareData.senderPhone}}</el-form-item>
        <el-form-item label="发货单号">{{modifyWareData.logisticNumber}}</el-form-item>
        <el-form-item label="发货数量">{{modifyWareData.sentCount}}</el-form-item>
        <el-form-item label="派送状态">
          <el-select v-model="modifyWareData.logisticStatus">
            <el-option label="未发货" :value="1"></el-option>
            <el-option label="派送中" :value="2"></el-option>
            <el-option label="已完成" :value="3"></el-option>
            <el-option label="已退回" :value="4"></el-option>
            <el-option label="停发" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="pid_raw">
          <el-input v-model="modifyWareData.pidRaw"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="modifyWareFlag=false">取消</el-button>
        <el-button type="primary" @click="modifyWarehouseLogisticByAdmin">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import global_api, {
  QueryProductListByProductSearchVo,
  RemoveProductByProductId,
  getBrandList,
  modifyProductTypeByProductId,
  queryWarehouseLogistic,
  deleteProductSurveyCacheByProductId
} from "../../config/Api";
import { addProductBatch,queryBrandIdByName,modifyWarehouseLogisticByAdmin } from "../../config/jsonapi";
import { ElMessage, ElMessageBox } from "element-plus";
import { Search } from "@element-plus/icons-vue";
import fileTemplate from "@/components/fileTemplate.vue";
export default {
  data() {
    return {
      Search: Search, //图标
      productTitle: "",
      pageCurrent: 1,
      pageSize: 10,
      total: 1,
      loading: false,
      tableList: [],
      descriptionDialog: false,
      description: "",
      palnDialog: false,
      palnList: [],
      originId: "",
      surveyName: "",
      brandList: [],
      brandId: "",
      fromDialog: false,
      type: 1,
      tagNameObj: {},

      warePage: 1,
      wareList: [],
      wareFlag: false,
      wareTotal: 1,
      modifyWareFlag: false,
      modifyWareData: {},
    };
  },
  components: {
    fileTemplate
  },
  mounted(){
    let quety = this.$route.query;
    this.pageCurrent = Number(quety.page || 1);
    this.pageSize = Number(quety.size || 10);
    this.getList();
    this.getBrandList();
    this.getTargetAudienceInfo();
  },
  methods: {
    // 获取标签分类
    getTargetAudienceInfo() {
      global_api.QueryTagListByTagSearchVo({ cunPage: 0, pageSize: 0 }).then(
        (res) => {
          let list = res.result;
          list = d(list, [], "");
          let tagNameObj = {};
          for (let i in list) {
            tagNameObj[list[i].id] = list[i].parentName + ">>" + list[i].name;
          }
          this.tagNameObj = tagNameObj;

          function d(list, a, name) {
            for (let i in list) {
              list[i].parentName = name;
              a.push(list[i]);
            }
            for (let i in list) {
              if (list[i].tagRespDTOS.length) {
                name = list[i].parentName
                  ? list[i].parentName + "》" + list[i].name
                  : list[i].name;
                a = d(list[i].tagRespDTOS, a, name);
              }
            }
            return a;
          }
        },
        () => {
          ElMessage.error("获取分类失败");
        }
      );
    },
    // 获取产品列表
    getList() {
      let param = {
        cunPage: this.pageCurrent,
        pageSize: this.pageSize,
        productTitle: this.productTitle,
        brandId: this.brandId,
      };
      this.loading = true;
      QueryProductListByProductSearchVo(param).then(
        (res) => {
          this.loading = false;
          if (res.result?.length) {
            res.result.map((item) => {
              for (let i in item.productResources) {
                if (item.productResources[i].type == 0) {
                  item.img = item.productResources[i].url;
                  break;
                }
              }
              if (item.description.length > 20) {
                item.descriptionCopy =
                  item.description.substring(0, 20) + "...";
                item.descriptionCopyFlag = true;
              } else {
                item.descriptionCopy = item.description;
              }
            });
          }
          this.tableList = res.result;
          this.total = res.data.totalCount;
        },
        (err) => {
          this.loading = false;
          ElMessage.error(`获取产品列表失败：${err.msg}`);
        }
      );
    },
    // 上一页下一页
    Current_change() {
      this.$router.push({
        path: "/goods_list",
        query: { page: this.pageCurrent, size: this.pageSize },
      });
      this.getList();
    },

    edit_goods_show(string, scope) {
      //编辑 新增 题目
      if (string == "edit") {
        sessionStorage.setItem("goods_item", JSON.stringify(scope.row));
      }
      this.$router.push({
        path: "/goods_list/edit",
        query: { type: string },
      });
    },

    resource(row) {
      //淘口令/公众号/小程序/PPT
      this.$router.push({
        path: "/goods_list/resource",
        query: { productId: row.id },
      });
    },

    // 删除
    removeGoods(row) {
      ElMessageBox.confirm("是否删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          productId: row.id,
        };
        this.loading = true;
        RemoveProductByProductId(param).then(
          (res) => {
            this.loading = true;
            ElMessage({
              type: "success",
              message: "已删除",
            });
            this.getList();
          },
          (err) => {
            this.loading = false;
            ElMessage.error(`删除失败:${err.msg}`);
          }
        );
      });
    },

    // 查看图片列表
    gotoImgList(row) {
      this.$router.push({
        path: "/goods_list/image",
        query: { id: row.id, b: `${this.pageCurrent}_${this.pageSize}` },
      });
    },
    // 查看题目列表
    gotoQuestion(row) {
      this.$router.push({
        path: "/goods_list/question",
        query: {
          id: this.originId,
          b: `${this.pageCurrent}_${this.pageSize}`,
          name: this.surveyName,
          projectId: row.id,
        },
      });
    },
    // 查看评论
    gotoComment(row) {
      this.$router.push({
        path: "/goods_list/comment",
        query: { id: row.id, b: `${this.pageCurrent}_${this.pageSize}` },
      });
    },
    // 打开项目弹窗
    openPlan(row) {
      this.palnDialog = true;
      this.palnList = row.projectRepDTOS || [];
      this.originId = row.id;
      this.surveyName = row.title;
    },
    // 计算题目总数
    addQuestionNUmber(list) {
      let num = 0;
      for (let i in list) {
        if (list[i].questionCount) {
          num += list[i].questionCount;
        }
      }
      return `${num}道题目`;
    },
    // 获取品牌列表
    getBrandList() {
      getBrandList().then(
        (res) => {
          console.log(res);
          this.brandList = res.result;
        },
        (err) => {
          this.brandList = [];
        }
      );
    },
    // 清除搜索
    clearSearch() {
      this.productTitle = "";
      this.brandId = "";
      this.pageCurrent = 1;
      this.getList();
    },
    // 打开设置产品来源
    openFrom(row) {
      this.type = row.type;
      this.productId = row.id;
      this.fromDialog = true;
    },
    // 修改产品来源
    submitform() {
      let param = {
        productId: this.productId,
        type: this.type,
      };
      this.loading = true;
      modifyProductTypeByProductId(param).then(
        (res) => {
          this.loading = false;
          this.fromDialog = false;
          this.getList();
        },
        (err) => {
          this.loading = false;
          ElMessage.error(err.msg);
        }
      );
    },
    // 批量添加产品
    addProductBatch(e) {
      console.log(e);
      // 根据品牌名称获取品牌id
      let brandNames = [];
      for(let i in e){
        if(brandNames.indexOf(e[i].品牌名称) == -1){
          brandNames.push(e[i].品牌名称);
        }
      }
      queryBrandIdByName({brandNames}).then(res=>{
        let result = res.result;
        let brandObj = {};
        for(let i in result){
          brandObj[result[i].name] = result[i].id;
        }
        console.log(brandObj);
        let param = [];
        for(let  i in e){
          param.push({
            allowCommentTime: e[i].派发后允许评论时间,
            amount:e[i].派送数量,
            brandId:brandObj[e[i].品牌名称],
            brandName:e[i].品牌名称,
            description:'<p>'+e[i].描述+'</p>',
            highFrequency:'',
            isIndexShow:0,
            isVisiable:e[i].是否可见,
            pid:e[i].仓库id,
            price:e[i].价格*100,
            requireComment:e[i].是否需要评论,
            source:0,
            subtitle:e[i].副标题,
            tagCategoryId:e[i].标签分类id,
            title:e[i].产品名称,
          })
        }
        addProductBatch(param).then(res=>{
          this.getList();
        })
      })
    },

    // 打开仓库弹窗
    queryWarehouseLogistic(id){
      let param = {
        cunPage: this.warePage,
        pageSize: 10,
        originId: id,
        type: 1
      }
      this.loading = true;
      queryWarehouseLogistic(param).then(res=>{
        this.loading = false;
        let wareList = res.result;
        if(wareList.length){
          this.wareList = wareList;
          this.wareTotal = res.data.totalCount;
          this.wareFlag = true;
        }else{
          ElMessage.error('没有仓库信息')
        }
      },err=>{
        ElMessage.error(err.msg)
      })
    },
    // 打开修改仓库信息
    openModify(data){
      this.modifyWareData = JSON.parse(JSON.stringify(data));
      this.modifyWareFlag = true;
    },
    // 修改仓库信息
    modifyWarehouseLogisticByAdmin(){
      let param = {
        logisticStatus: this.modifyWareData.logisticStatus,
        pidRaw: this.modifyWareData.pidRaw,
        warehouseLogisticId: this.modifyWareData.id
      }
      this.loading = true;
      modifyWarehouseLogisticByAdmin(param).then(res=>{
        this.loading = false;
        this.modifyWareFlag = false;
        this.queryWarehouseLogistic(this.modifyWareData.originId)
      },err=>{
        this.loading = false;
        ElMessage.error(err.msg)
      })
    },
    // 清除产品缓存
    clearReddis(row){
      let param = {
        productId: row.id
      }
      this.loading = true;
      deleteProductSurveyCacheByProductId(param).then(
        res => {
          this.loading = false;
          ElMessage.success('缓存清除成功')
        },
        err => {
          this.loading = false;
          ElMessage.error('缓存清除失败')
        }
      )
    }
  },
};
</script>
<style lang="scss" scoped>
.goodsList {
  margin-top: 20px;
}
.tableImg {
  img {
    width: 40px;
  }
}
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
