<template>
  <div class="H5_page_setting" v-loading="loading">
    <section class="search_btn">
      <div class="search_items">
        <span class="title">文章名称：</span>
        <div>
          <el-input
              v-model="this.obj_item.kvKey"
              class="w-50 m-2"
              size="large"
              placeholder="请输入文章名称"
          ></el-input>
        </div>
      </div>
      <div class="search_items">
        <el-button type="primary" :icon="Search">查询</el-button>
        <el-button type="danger" @click="edit_text('add')">添加文章</el-button>
      </div>
    </section>
    <section class="user_info_list">
      <el-table :data="tableData">
        <el-table-column prop="id" label="ID"/>
        <el-table-column prop="kvKey" label="文章名称"/>
        <el-table-column prop="createTime" label="创建时间"/>
        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-tag class="ml-2" @click="edit_text('edit',scope)">编辑</el-tag>
<!--            <el-tag class="ml-2" type="success" @click="Is_delete(scope)">删除</el-tag>-->
          </template>
        </el-table-column>
      </el-table>
    </section>

  </div>
</template>

<script>
import {Search,MessageBox} from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import E from "wangeditor";
import global_api from "@/config/Api";
export default {
  name: 'H5_page_setting',
  data(){
    return {
      Search:Search,    //图标
      MessageBox:MessageBox,//图标
      loading:false,
      editor_dialog:true,
      tableData:[],
      obj_item:{},
      flag:'',     //新增 或 编辑
      dialog_style_flag:true
    }
  },
  mounted() {
   this.QueryKvMapListByType()
  },
  methods: {
    QueryKvMapListByType(){              //根据type获取数据字典列表
      let obj = {
        cunPage:1,
        pageSize:100,
        type:2,
        id:1
      }
      this.loading = true
      global_api.QueryKvMapListByType(obj).then((res)=>{
        if(res.code!=0){
          ElMessage.error('根据type获取数据字典列表接口错误')
        }else{
          this.tableData = res.result
          this.loading = false
        }
      })
    },

    edit_text(string ,scope){       //编辑 新增 文章
      if(string=='edit'){
        sessionStorage.setItem('H5_item',JSON.stringify(scope.row))
      }
      this.$router.push({
        path: "/H5_page_setting/edit",
        query: { type: string, },
      });
    },




    Is_delete(scope){           //是否删除
      ElMessageBox.confirm(
          '是否删除?',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        let obj={
          kvMapId:scope.row.id,
          id:1
        }
        global_api.RemoveKvMapByKvMapId(obj).then((res)=>{
          if(res.code!=0){
            ElMessage.error('添加数据字典内容接口错误')
          }else{
            ElMessage({
              type: 'success',
              message: '已删除',
            })
            this.QueryKvMapListByType()
          }
        })
      })
    },

  },
}
</script>
<style scoped lang="scss">
.H5_page_setting{
  margin-top: 20px;
}
.search_btn{
  //border-radius: 5px;
}
.user_info_list{
  margin-top: 20px;
  min-width: 800px;
  .btn{
    margin-top: 5px !important;
  }
}
.pagination{
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.no_float{
  float: none !important;
}


</style>
